import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12SearchIcon( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <g id='Base/Icons/UI/search'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path d='M 2.453125 0.65625 C 3.972656 -0.21875 5.839844 -0.21875 7.359375 0.65625 C 8.878906 1.535156 9.8125 3.152344 9.8125 4.90625 C 9.8125 6.125 9.367188 7.242188 8.632812 8.101562 L 11.890625 11.359375 C 12.035156 11.507812 12.035156 11.746094 11.890625 11.890625 C 11.757812 12.023438 11.546875 12.035156 11.402344 11.925781 L 11.359375 11.890625 L 8.101562 8.632812 C 7.242188 9.367188 6.125 9.8125 4.90625 9.8125 C 2.195312 9.8125 0 7.617188 0 4.90625 C 0 3.152344 0.933594 1.535156 2.453125 0.65625 Z M 6.984375 1.308594 C 5.699219 0.5625 4.113281 0.5625 2.828125 1.308594 C 1.542969 2.050781 0.75 3.421875 0.75 4.90625 C 0.75 7.203125 2.609375 9.0625 4.90625 9.0625 C 6 9.0625 6.992188 8.640625 7.734375 7.953125 L 7.851562 7.839844 C 8.601562 7.089844 9.0625 6.050781 9.0625 4.90625 C 9.0625 3.421875 8.269531 2.050781 6.984375 1.308594 Z M 6.984375 1.308594 '
          fill='#000000'
          fillRule='nonzero'
        />
      </g>
    </SvgContainer>
  );
}

export default Svg12SearchIcon;
