import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24Share( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <g
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        stroke='currentColor'
      >
        <path d='M19 12.5h4.5v11H.5v-11h4.7' />
        <g strokeLinejoin='round'>
          <path d='M12.167 1.167v14M19.167 7.667l-7.023-7-6.977 7' />
        </g>
      </g>
    </SvgContainer>
  );
}

export default Svg24Share;
