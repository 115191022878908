import { getTopBarHeight } from '@ulta/modules/TopBar/TopBar';

import { isSafeNumber } from '../types/types';
import * as utils from './scrollToElement';

/**
   * Scroll to element handler
   * @method scrollToElement
   * @param {object} data - Arguments
   * @param {HTMLElement} data.el - DOM element we want to scroll to
   * @param {number} data.offset - Positive or negative integer to modify scrollTop
   * @param {boolean} data.autoFocus - Toggles the auto focus of tabindex=-1 if needed
   * @param {boolean} data.animate - Animates when true
   * @returns {Promise} Returns false if we didn't scroll, true if we did
   */
export const scrollToElement = async( data ) => {
  const { el, offset = 0, autoFocus = true, animate = true } = data || {};
  if( !el ){
    return false;
  }

  const elScrollTop = utils.getElScrollTopWithHeaderOffset( { el } );
  const scrollTop = utils.offsetAndScrollTop( { elScrollTop, offset } );

  if( scrollTop === 0 ){
    return false;
  }

  const options = {
    top: scrollTop,
    left: 0
  };

  if( animate ){
    options.behavior = 'smooth';
  }

  try {
    await utils.smoothScrollTo( options );
  }
  catch {
    global.scrollTo( options.left, options.top );
  }

  // If a caller is handling focus, let's skip the rest
  if( autoFocus === false ){
    return true;
  }

  const x = window.scrollX;
  const y = window.scrollY;

  // If element is focusable, invoke focus and leave
  if( utils.isFocusableScrollTarget( { el } ) ){
    el.focus();

    // We need to reset focus to the original location
    global.scrollTo( x, y );
    return true;
  }

  // Add focus to element, then remove on tab out
  el.setAttribute( 'tabindex', '-1' );
  el.addEventListener( 'blur', () => {
    el.removeAttribute( 'tabindex' );
  }, { once: true } );

  // Focus after animation/scroll
  setTimeout( () => {
    el.focus();

    // We need to reset focus to the original location
    global.scrollTo( x, y );
  }, 150 );

  return true;
};

export const ON_SCROLL_STARTED_EVENT = 'DSOTF_SCROLL_START';

export const ON_SCROLL_FINISHED_EVENT = 'DSOTF_SCROLL_COMPLETE';

/**
 *
 * @param {number} elScrollTop element's scroll top
 * @param {number} offset user provided offset
 * @returns number scroll top in pixels we want to scroll to
 */
export const offsetAndScrollTop = ( data ) => {
  const { elScrollTop, offset } = data || {};
  const validatedOffset = isSafeNumber( offset ) ? offset : 0;
  const validatedScrollTop = isSafeNumber( elScrollTop ) ? elScrollTop : 0;
  return validatedScrollTop + validatedOffset;
};

/**
 * @constant {string} ATG_DESKTOP_HEADER_SELECTOR
 */
export const ATG_DESKTOP_HEADER_SELECTOR = '.DesktopHeader__StickyHeader';

/**
 * @constant {string} ATG_MOBILE_HEADER_SELECTOR
 */
export const ATG_MOBILE_HEADER_SELECTOR = '#MobileHeader';

/**
 * Determines the offset to scroll to taking into account the desktop header
 * @param {object} el DOM element to scroll to
 * @returns {number} Element's desired scrollTop
 */
export const getElScrollTopWithHeaderOffset = ( data ) => {
  const { el } = data || {};

  if( typeof ( el?.getBoundingClientRect ) !== 'function' ){
    return 0;
  }

  const { top } = el.getBoundingClientRect();

  let headerOffset = 0;
  const atgDesktopHeader = document.querySelector( ATG_DESKTOP_HEADER_SELECTOR );
  const atgMobileHeader = document.querySelector( ATG_MOBILE_HEADER_SELECTOR );

  const isATG = !!atgDesktopHeader?.offsetHeight || !!atgMobileHeader?.offsetHeight;

  // When on ATG and in desktop mode, the header is always there so we can
  // set the offset here
  if( atgDesktopHeader?.offsetHeight ){
    headerOffset = atgDesktopHeader.offsetHeight;
  }

  // Determine scroll direction
  const isScrollingUp = top < 0;

  // When we're scrolling up and ATG header is disabled, account for DSOTF header that will animate down
  if( isScrollingUp && !isATG ){
    headerOffset = getTopBarHeight();
  }

  // When we're scrolling up and ATG mobile header is enabled, it will animate down
  if( isScrollingUp && atgMobileHeader?.offsetHeight ){
    headerOffset = atgMobileHeader.offsetHeight;
  }

  return top + global.scrollY - headerOffset;
};

/**
 * Utility to determine if an element should receive focus programatically, used for scroll targets
 * @param el {object} DOM element to check if it's focusable
 * @returns {boolean} Is this element focusable?
 */
export const isFocusableScrollTarget = ( data ) => {
  const { el } = data || {};

  if( !el || !el.tagName ){
    return false;
  }

  const tabindex = el.getAttribute( 'tabindex' );
  const tagName = el.tagName.toUpperCase();

  if( el.hasAttribute( 'disabled' ) && FOCUSABLE_FORM_ELEMENTS.includes( tagName ) ){
    return false;
  }
  return !!(
    [...FOCUSABLE_FORM_ELEMENTS, ...FOCUSABLE_HTML_ELEMENTS].includes( tagName ) ||
    el.hasAttribute( 'href' ) ||
    ( tabindex !== null && tabindex > -1 )
  );
};

/**
 * @constant {array}
 * @default
 */
export const FOCUSABLE_FORM_ELEMENTS = ['BUTTON', 'INPUT', 'SELECT', 'TEXTAREA'];
/**
 * @constant {array}
 * @default
 */
export const FOCUSABLE_HTML_ELEMENTS = ['SUMMARY', 'DETAILS'];


/**
 * Taken from https://stackoverflow.com/a/60001032
 * Promised based window.scrollTo( { behavior: 'smooth' } )
 *
 * @param {object} data - global.scrollTo options
 * @return {Promise} (void)
 */
export const smoothScrollTo = ( data ) => {
  return new Promise( ( resolve, reject ) => {
    const elem = document.scrollingElement;
    let ticks = 0; // a counter

    if( !document.scrollingElement ){
      return reject();
    }

    // last known scroll positions
    let lastPositionTop = elem.scrollTop;
    let lastPositionLeft = elem.scrollLeft;

    // pass the user defined options along with our default
    const scrollOptions = Object.assign( {
      top: lastPositionTop,
      left: lastPositionLeft
    }, data );

    // expected final position
    const maxScrollTop = elem.scrollHeight - elem.clientHeight;
    const maxScrollLeft = elem.scrollWidth - elem.clientWidth;
    const targetPositionTop = Math.max( 0, Math.min( maxScrollTop, scrollOptions.top ) );
    const targetPositionLeft = Math.max( 0, Math.min( maxScrollLeft, scrollOptions.left ) );

    // let's begin
    global.scrollTo( scrollOptions );

    global.requestAnimationFrame(
      utils.composeScrollCheckTick(
        { elem, targetPositionTop, targetPositionLeft, lastPositionTop, lastPositionLeft, ticks },
        { resolve, reject }
      )
    );
  } );
};

/**
 * Recursive helper function for the Promise-based smooth scroll, compares scroll positions to determine
 * if we're at our final destination
 *
 * @param {object} data - Arguments
 * @param {object} methods - Methods
 */
export const composeScrollCheckTick = ( data, methods ) => () => {
  const { elem, targetPositionTop, targetPositionLeft } = data || {};
  const { resolve, reject } = methods || {};

  if( !resolve || !reject || !elem ){
    return;
  }

  // check our current position
  const newPositionTop = elem.scrollTop;
  const newPositionLeft = elem.scrollLeft;

  // we add a 1px margin to be safe
  // (can happen with floating values + when reaching one end)
  const at_destination =
    Math.abs( newPositionTop - targetPositionTop ) <= 1 && Math.abs( newPositionLeft - targetPositionLeft ) <= 1;

  // same as previous
  if( newPositionTop === data.lastPositionTop && newPositionLeft === data.lastPositionLeft ){
    // eslint-disable-next-line no-param-reassign
    if( data.ticks++ > 2 ){
      // if it's more than two frames
      if( at_destination ){
        return resolve();
      }

      return reject();
    }
  }
  else {
    // reset our counter
    data.ticks = 0; // eslint-disable-line no-param-reassign

    // remember our current position
    data.lastPositionTop = newPositionTop; // eslint-disable-line no-param-reassign
    data.lastPositionLeft = newPositionLeft; // eslint-disable-line no-param-reassign
  }

  // check again next painting frame
  global.requestAnimationFrame(
    utils.composeScrollCheckTick(
      {
        elem,
        targetPositionTop,
        targetPositionLeft,
        lastPositionTop: data.lastPositionTop,
        lastPositionLeft: data.lastPositionLeft,
        ticks: data.ticks
      },
      { resolve, reject }
    )
  );
};