import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16Vegan( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <g id='Vegan'>
        <path d='M1 1s1.2.3 1.9.8c1.2.7 2.9 2 4.1 3.9.4.5.6 1 .8 1.5.6-1 1.6-1.8 2.1-2.2 2.4-1.7 4.5-1.3 4.6-1.2h.5l-.3.5s-1.4 1.7-2 4.1a11.6 11.6 0 0 1-5 6.6h-.3c0-.1-5.6-2.2-5.4-8 0-2.2-.3-3.7-.6-4.5L1 1Zm12.8 3.4c-.7 0-2 .1-3.5 1.2C9.8 6 8.6 7 8.1 8l.3 1.8C9 9.2 9.7 8.1 11 7c0 0-1.8 2.8-2.6 4.4 0 1.1-.2 2-.3 2.6 1-.7 3-2.6 3.9-5.7.5-1.8 1.3-3.2 1.8-3.8zM2.1 2c.3.9.7 2.4.7 4.8-.2 4 2.6 6 4 6.9a20.5 20.5 0 0 0-1.7-6.3L5 7.2l-.8-1.7s1.5 1.7 2.2 3.3c.7 2 1 3.8 1.1 4.8.3-1.6.7-5-1-7.6A14.7 14.7 0 0 0 2 2Z'/>
      </g>
    </SvgContainer>
  );
}

export default Svg16Vegan;
