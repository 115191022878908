import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg40Twitter( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 40 }
      height={ 40 }
    >
      <path
        d='M20,0 C8.955,0 0,8.955 0,20 C0,31.045 8.955,40 20,40 C31.045,40 40,31.045 40,20 C40,8.955 31.045,0 20,0 Z M30.11,16.075 C30.415,22.8083333 25.3933333,30.315 16.5033333,30.315 C13.8,30.315 11.285,29.5216667 9.16666667,28.1633333 C11.7066667,28.4633333 14.2416667,27.7566667 16.2533333,26.1816667 C14.16,26.1433333 12.3916667,24.7583333 11.78,22.8566667 C12.5316667,23 13.2716667,22.9583333 13.9433333,22.775 C11.6416667,22.3116667 10.0516667,20.2383333 10.1033333,18.02 C10.75,18.3783333 11.4866667,18.5933333 12.2716667,18.6183333 C10.14,17.1933333 9.53666667,14.3783333 10.79,12.2266667 C13.15,15.1233333 16.6783333,17.0283333 20.6566667,17.2283333 C19.9583333,14.235 22.23,11.35 25.3216667,11.35 C26.6966667,11.35 27.9416667,11.9316667 28.815,12.8616667 C29.905,12.6483333 30.9316667,12.2483333 31.855,11.7 C31.4966667,12.8183333 30.7383333,13.755 29.75,14.3483333 C30.7183333,14.2316667 31.6416667,13.975 32.4983333,13.5933333 C31.8583333,14.5566667 31.0483333,15.4 30.11,16.075 L30.11,16.075 Z'
        fill='currentColor'
        fillRule='nonzero'
      />
    </SvgContainer>
  );
}

export default Svg40Twitter;