import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12Clean( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <g id='Clean'>
        <path d='m6.8 2.7-.1.5s-.4 1 0 2l.5.9c.4.7.6 1.2.6 2.1 0 .9-.3 1.7-.9 2.2A2.4 2.4 0 0 1 5 11c-.6 0-1.1-.4-1.6-1a3 3 0 0 1-.6-2.3 6.8 6.8 0 0 1 3.6-4.8zm-.6.8c-.8.5-2.6 1.9-3 4.3-.1.7 0 1.5.5 2 .4.5.9.8 1.4.8a2 2 0 0 0 1.6-.5c.5-.4.7-1.1.7-1.9 0-.8-.2-1.3-.6-2l-.4-.8a3 3 0 0 1-.2-1.9Zm0 3s1.2 1.8.2 3L6 9.2c.8-1 .2-2.7.2-2.7zm3.4-1.6.2.9 1 .2-1 .2-.2.8-.1-.8-1-.2 1-.2zM2.5 2.6l.2.9.9.1-1 .2-.1 1-.2-1-.9-.2.9-.1Zm6-1.6.2.9.9.2-1 .1-.1 1-.2-1-.9-.1 1-.2Z'/>
      </g>
    </SvgContainer>
  );
}

export default Svg12Clean;
