import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12Trending( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <path
        stroke='currentColor'
        d='M5.21.5h4.867L7.643 4.64h2.434L5.21 11.5V6H3.308V.5z'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg12Trending;
