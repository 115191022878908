import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12Vegan( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <g id='Vegan'>
        <path d='M1 1s.9.2 1.3.5c1 .6 2.1 1.5 3 2.8l.6 1.1c.4-.6 1-1.2 1.5-1.5 1.7-1.3 3.2-1 3.2-1l.4.2-.2.3s-1 1.2-1.5 2.9A8.3 8.3 0 0 1 5.9 11l-.3-.1s-4-1.6-3.8-5.7c0-1.6-.2-2.6-.4-3.2L1 1Zm9.2 2.4a4 4 0 0 0-2.5.9c-.4.3-1.3 1-1.6 1.7l.2 1.4c.3-.6 1-1.4 1.9-2.2L6.3 8.4l-.2 1.8c.7-.5 2.1-1.8 2.7-4a9 9 0 0 1 1.4-2.8zM1.8 1.8c.2.6.5 1.7.5 3.4-.1 2.9 1.8 4.3 2.9 5a14.6 14.6 0 0 0-1.3-4.6v-.2l-.6-1.2a9 9 0 0 0 1.5 2.3c.6 1.4.8 2.8.8 3.5.3-1.1.5-3.5-.7-5.4a10 10 0 0 0-3.1-2.8Z'/>
      </g>
    </SvgContainer>
  );
}

export default Svg12Vegan;
