import React, { Suspense, useRef } from "react";

import PropTypes, { object } from 'prop-types';
import Loader from "../Loader/Loader";
import { useIntersectionObserver } from "@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver";

/**
 * Represents a RenderOnViewportEntry component
 *
 * @method
 * @param { RenderOnViewportEntryProps } props - React properties passed from composition
 * @returns RenderOnViewportEntry
 */
export const RenderOnViewportEntry = function(props) {

    const { threshold, root, rootMargin } = props;
    const triggerOnce = true;
    const ref = useRef();
    const { hasIntersected } = useIntersectionObserver( ref, { threshold, root, rootMargin, triggerOnce } );

    return (
        <div className={'RenderOnViewportEntry'} ref={ref}>
            {hasIntersected && <Suspense fallback={ <Loader/> }>{props.children}</Suspense>}
        </div>
    );
};

/**
 * Property type definitions
 * @typedef RenderOnViewportEntryProps
 * @type {object}
 * @property {number} threshold - Detection threshold
 * @property {object} root - Detection root
 * @property {string} rootMargin - Buffer around root for detection 
 * @property {object} children - Contained children
 */
export const propTypes = {
    threshold: PropTypes.number,
    root: PropTypes.object,
    rootMargin: PropTypes.string,
    children: object
}

/**
 * Default values for passed properties
 *
 * @type {object}
 * @property {string} rootMargin='0px 0px 0px 0px' - Margin around the root. If the image gets within 50px in the Y axis, start the download.
 * @property {object} root=null - The element that is used as the viewport for checking visiblity of the target.
 * @property {number} threshold=0.01 - Either a single number or an array of numbers which indicate at what percentage of the target's visibility the observer's callback should be executed.
 */
export const defaultProps = {
    threshold: 0.01,
    root: null,
    rootMargin: "0px 0px 0px 0px",
    children: null
}

RenderOnViewportEntry.propTypes = propTypes;
RenderOnViewportEntry.defaultProps = defaultProps;

export default RenderOnViewportEntry;