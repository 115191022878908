import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16ExclamationCirlce( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
      version='1.1'
      viewBox='0 0 16 16'
    >
      <g fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <g transform='translate(-48 -240)'
          stroke='currentColor'
        >
          <g transform='translate(24 100)'>
            <g transform='translate(0 116)'>
              <g transform='translate(24 24)'>
                <path d='m15.333 7.874c0.010262 4.0749-3.2588 7.4002-7.3334 7.4593-1.9315 0.029062-3.7942-0.7166-5.1721-2.0705-1.3779-1.3539-2.1563-3.2031-2.1612-5.1349-0.011354-4.0757 3.2581-7.4022 7.3334-7.4613 1.9318-0.028884 3.7947 0.71711 5.1726 2.0714s2.1561 3.204 2.1607 5.136z'/>
                <line x1='8'
                  x2='8'
                  y1='9.3367'
                  y2='4.67'
                />
                <path d='m7.994 11.167c-0.044034 6.821e-4 -0.085927 0.019117-0.11618 0.051124s-0.046292 0.074873-0.04449 0.11888c0.0032228 0.090911 0.077699 0.16303 0.16867 0.16333 0.043974-8.642e-4 0.085758-0.019355 0.11597-0.051321 0.030211-0.031966 0.046315-0.074726 0.044698-0.11868-0.002754-0.088784-0.073895-0.16022-0.16267-0.16333h-0.0033333'/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgContainer>

  );
}

export default Svg16ExclamationCirlce;
