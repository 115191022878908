import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function SVG( props ){

  return (
    <SvgContainer
      { ...props }
      width={ 50 }
      height={ 50 }
      viewBox='0 0 32 21'
    >
      <g fill='none'
        fillRule='evenodd'
        stroke='none'
        strokeWidth='1'
      >
        <g fillRule='nonzero'
          transform='translate(-411)'
        >
          <g transform='translate(261 .498)'>
            <g transform='translate(150.167 .1)'>
              <path
                fill='#FFF'
                d='M30 0c.92 0 1.667.746 1.667 1.667v16.458c0 .92-.747 1.667-1.667 1.667H1.667c-.92 0-1.667-.747-1.667-1.667V1.667C0 .747.746 0 1.667 0H30z'
              ></path>
              <path
                fill='silver'
                d='M30 0c.92 0 1.667.746 1.667 1.667v16.458c0 .92-.747 1.667-1.667 1.667H1.667c-.92 0-1.667-.747-1.667-1.667V1.667C0 .747.746 0 1.667 0H30zm0 .417H1.667a1.25 1.25 0 00-1.245 1.13l-.005.12v16.458c0 .65.495 1.184 1.13 1.244l.12.006H30a1.25 1.25 0 001.244-1.13l.006-.12V1.667A1.25 1.25 0 0030.12.422L30 .417z'
              ></path>
              <path
                fill='#F18A1B'
                d='M15.833 19.792h11.875a3.958 3.958 0 003.959-3.959V3.958c0 8.745-7.09 15.834-15.834 15.834z'
              ></path>
              <ellipse
                cx='16.908'
                cy='9.325'
                fill='#F18A1B'
                rx='1.917'
                ry='1.867'
              ></ellipse>
              <path
                fill='#000'
                d='M7.092 7.55H8.091999999999999V11.091999999999999H7.092z'
              ></path>
              <path
                fill='#000'
                d='M6.342 8.533a1.667 1.667 0 00-.417-.541 1.75 1.75 0 00-.592-.334 2.3 2.3 0 00-.691-.1H3.208V11.1h1.35c.23-.003.457-.04.675-.108.22-.074.422-.187.6-.334.191-.148.348-.335.459-.55.12-.243.181-.512.175-.783a1.95 1.95 0 00-.125-.792zM5.7 9.9a1.133 1.133 0 01-.792.575c-.187.048-.38.07-.575.067h-.475V8.117h.584c.185 0 .37.023.55.066.152.046.296.116.425.209a.908.908 0 01.283.375c.075.173.112.36.108.55.013.2-.024.4-.108.583z'
              ></path>
              <path
                fill='#000'
                d='M10.375 9.242c-.13-.08-.27-.141-.417-.184l-.416-.133a1.275 1.275 0 01-.317-.167.325.325 0 01-.125-.275.4.4 0 01.183-.35.6.6 0 01.192-.083.942.942 0 01.217 0 .983.983 0 01.366.067.592.592 0 01.275.216l.475-.483a1.3 1.3 0 00-.483-.283 1.893 1.893 0 00-1.05-.017c-.155.04-.302.108-.433.2a1.042 1.042 0 00-.3.333.933.933 0 00-.117.475c-.01.176.034.35.125.5a1 1 0 00.317.3c.13.076.27.135.416.175l.417.142c.114.041.221.1.317.175a.367.367 0 01.125.3.4.4 0 01-.059.217.475.475 0 01-.15.15.708.708 0 01-.208.091.883.883 0 01-.225 0 .892.892 0 01-.408-.1.833.833 0 01-.309-.275l-.45.5c.145.168.332.295.542.367.209.073.429.11.65.108.172.002.343-.02.508-.066.154-.042.298-.113.425-.209a1.017 1.017 0 00.4-.85.892.892 0 00-.125-.508 1.017 1.017 0 00-.358-.333z'
              ></path>
              <path
                fill='#000'
                d='M23.375 9.55833333L25.05 9.55833333 25.05 8.99166667 23.375 8.99166667 23.375 8.11666667 25.1416667 8.11666667 25.1416667 7.55 22.7333333 7.55 22.7333333 11.0916667 25.2333333 11.0916667 25.2333333 10.525 23.375 10.525z'
              ></path>
              <path
                fill='#000'
                d='M27.65 9.525a.833.833 0 00.625-.317c.245-.33.287-.77.108-1.141a.908.908 0 00-.308-.309 1.333 1.333 0 00-.433-.166 2.75 2.75 0 00-.509 0h-1.3v3.541h.65v-1.5h.475l.834 1.5h.783l-.925-1.608zm-.35-.458h-.783v-.975h.816c.08.008.16.027.234.058a.367.367 0 01.175.142c.05.076.073.167.066.258a.483.483 0 01-.066.267.45.45 0 01-.192.15 1.083 1.083 0 01-.25.1z'
              ></path>
              <path
                fill='#000'
                d='M13.658 10.508a.958.958 0 01-.433.1 1.267 1.267 0 01-.508-.1 1.183 1.183 0 01-.392-.275 1.225 1.225 0 01-.258-.416 1.492 1.492 0 01-.092-.525c0-.169.03-.335.092-.492.059-.149.147-.285.258-.4.11-.115.244-.206.392-.267.16-.067.334-.101.508-.1a.983.983 0 01.767.35l.5-.358a1.6 1.6 0 00-.592-.433 1.817 1.817 0 00-.683-.125 2.183 2.183 0 00-.775.133 1.75 1.75 0 00-.609.375c-.173.167-.309.369-.4.592a2 2 0 00-.141.775c-.005.26.043.517.141.758a1.725 1.725 0 001.009.958c.248.091.51.136.775.134.26.002.518-.05.758-.15a1.45 1.45 0 00.6-.484l-.533-.366c-.104.132-.235.24-.384.316z'
              ></path>
              <path
                fill='#000'
                d='M20.6166667 10.225L19.625 7.55 18.875 7.55 20.3083333 11.0916667 20.8666667 11.0916667 22.3416667 7.55 21.6416667 7.55z'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}

export default SVG;
