/**
 * The InputField component is used for textual user data input on forms. The type of field can be one of &#x27;text&#x27;, &#x27;number&#x27;, &#x27;password&#x27;, &#x27;email&#x27;, &#x27;tel&#x27; or &#x27;date&#x27;. It also accepts several properties to set values on tag attributes, enhance usability, and for ADA compliance.
 *
 * @module views/Atoms/InputField
 * @memberof -Common
 */
import './InputField.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { ErrorMessage, useField } from 'formik';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Icon from '@ulta/core/components/Icon/Icon';
import InlineMessage from '@ulta/core/components/InlineMessage/InlineMessage';
import Text from '@ulta/core/components/Text/Text';
import { useInputId } from '@ulta/core/hooks/useInputId/useInputId';
import {
  getHiddenContent,
  getMaskedContent,
  INVALID_INPUT_CHARACTERS,
  SECURE_CHARACTER
} from '@ulta/core/hooks/useInputMasker/useInputMasker';
import UseKeyPress from '@ulta/core/hooks/useKeyPress/UseKeyPress';
import useOutsideClick from '@ulta/core/hooks/useOutsideClick/useOutsideClick';
import { constants } from '@ulta/core/utils/constants/constants';
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';

import * as utils from './InputField';

const { TAB_KEY } = constants;

/**
 * Represents a InputField component
 *
 * @method
 * @param {InputFieldProps} props - React properties passed from composition
 * @returns InputField
 */
export const InputField = function( props ){

  const {
    ariaHidden,
    autoCapitalize,
    autoComplete,
    autoCorrect,
    clearAccessbilityLabel,
    displayCheck,
    enabled,
    helpText,
    hideAccessibilityLabel,
    formatter,
    icon,
    iconTitle,
    label,
    maxLength,
    name,
    onChange,
    placeholderLabel,
    prefersRawValue,
    required,
    showAccessibilityLabel,
    spellCheck,
    tabIndex,
    type,
    warningMessage
  } = props;

  // a ref to target the input element for focus()
  const inputEl = useRef( name );

  // ref to point previous type after changing the type
  const inputType = useRef( type );

  // formik form support
  const [field, meta, helpers] = useField( props );
  const [inputId] = useInputId( { name } );

  // state to manage masked input values if masking is enabled, ( setIsMaskedInput is not currently used, kept for future use )
  const [isMaskedInput, setIsMaskedInput] = useState( !!formatter );

  // state to manage hidden input values
  const [isHidden, setIsHidden] = useState( formatter?.hidden || type === 'password' );

  // State to manage the input value inputArray.
  const initialInput = ( isMaskedInput ) ? field?.value?.replace( INVALID_INPUT_CHARACTERS, '' ) : field.value;
  const [inputArray, setInputArray] = useState( initialInput?.split( '' ) || [] );

  useEffect( () => {
    // dynamically set the isMaskedInput and isHidden states
    setIsMaskedInput( !!formatter );
    setIsHidden( formatter?.hidden || type === 'password' );
    utils.handleFormatterChange( { inputArray, formatter, field, prefersRawValue }, { helpers } );
  }, [formatter, type] );

  useEffect( () => {
    // dynamically update the input value
    const initialInput = isMaskedInput ? field?.value?.replace( INVALID_INPUT_CHARACTERS, '' ) : field.value;
    const initialInputArray = initialInput?.split( '' ) || [];
    setInputArray( initialInputArray );
  }, [field.value, isMaskedInput] );

  // state to manage focus and visual display of errors, helper test and warning messages
  // TODO: investigate why we need 2 states for showing that a user is itneracting with the input.
  const [isActive, setIsActive] = useState( false );
  const [isFocused, setIsFocused] = useState( false );

  // Event handler for input change
  const handleInputChange = useCallback( utils.handleInputChange(
    { formatter, helpers, INVALID_INPUT_CHARACTERS, isMaskedInput, prefersRawValue },
    { getDisplayValue: utils.getDisplayValue, onChange, setIsActive, setInputArray, updateInputValue: utils.updateInputValue }
  ), [onChange, formatter, isMaskedInput, prefersRawValue] );

  // Event handler to toggle the isHidden state and set isCurrentlyMasked to false
  const handleToggleShowHide = useCallback( () => utils.handleToggleShowHide( { inputEl, inputType }, { setIsHidden } ), [] );

  // form configuration
  const errorId = `${field.name}-error`;
  const helperId = `${field.name}-helper`;
  const warningId = `${field.name}-warning`;
  const displayInputValue = utils.getDisplayValue(
    { content: inputArray.join( '' ), formatter, isMaskedInput, isHidden, type: inputType.current },
    { getMaskedContent, getHiddenContent } );

  // TODO: refactor with new variables, why field.value vs inputArray for example...
  const enableShowHide = field.value && ( type === 'password' || ( formatter?.hidden && field.name !== 'creditCardNumber' ) );
  const displayCheckIcon = field.value && !meta.error && !isFocused && displayCheck;
  const fieldRef = useRef( );
  const outerRef = useRef( );

  // Error, Warning, Helper which will be active according to Input We get that Id for aria-describe
  let ariaId = null;
  if( meta.touched && meta.error ){
    ariaId = errorId;
  }
  else if( warningMessage ){
    ariaId = warningId;
  }
  else if( helpText ){
    ariaId = helperId;
  }

  useOutsideClick( { watcher: isFocused, el: outerRef.current }, { handleClick: () => {
    utils.handleFocusAway( { setIsActive, setIsFocused } );
  } } );

  UseKeyPress( [TAB_KEY], fieldRef, () => utils.handleFocusAway( { setIsActive, setIsFocused } ), [TAB_KEY] );

  return (
    <div
      className={ classNames( 'InputField-ds', {
        'InputField-ds--error': !isActive && meta.touched && meta.error && enabled,
        'InputField-ds--disable': !enabled
      } ) }
      ref={ outerRef }
    >
      <div className='InputField-ds__label'>
        <label htmlFor={ inputId }
          className='InputField-ds__label--text'
        >
          { label }
        </label>
      </div>
      <div
        className={ classNames( 'InputField-ds__content', {
          'InputField-ds__content--active': isActive || ( !isActive && field.value !== '' ),
          'InputField-ds__content--focused': isFocused,
          'InputField-ds--error': !isFocused && meta.touched && meta.error && enabled
        } ) }
      >
        <div className='InputField-ds__formControls'>
          <input
            { ...field }
            className={ classNames(
              'InputField-ds__input',
              {
                'InputField-ds--capitalize': autoCapitalize
              },
              {
                'InputField-ds__input--active': isActive || ( !isActive && field.value !== '' )
              }
            ) }
            { ...( props.onClick && { onClick: props.onClick } ) }
            value={ displayInputValue }
            disabled={ !enabled }
            ref={ inputEl }
            id={ inputId }
            name={ field.name }
            autoComplete={ autoComplete }
            autoCorrect={ autoCorrect }
            spellCheck={ spellCheck }
            tabIndex={ tabIndex }
            onFocus={ () => utils.handleOnFocus( { setIsActive, setIsFocused } ) }
            onBlur={ ( e ) => utils.handleBlur( { setIsFocused }, { e, field, props } ) }
            onPaste={ ( e ) => utils.handlePaste( { e, props } ) }
            onChange={ handleInputChange }
            type={ type }
            maxLength={ isMaskedInput ? formatter?.pattern?.length : maxLength }
            aria-label={ label }
            placeholder={ placeholderLabel }
            { ...( required && { required: required } ) }
            { ...( meta.touched && meta.error && { 'aria-invalid': 'true' } ) }
            { ...( ariaId && { 'aria-describedby': ariaId } ) }
          />

          <div className={ classNames( 'InputField-ds__actions', {} ) }>
            { field.value && isFocused &&
              <Button
                type='button'
                ariaLabel={ clearAccessbilityLabel }
                iconImage={ 'X' }
                icon={ true }
                iconSize={ 'lg' }
                ariaHiddenIcon={ true }
                onClick={ () => utils.handleResetVal( { helpers, inputEl }, { onChange, setInputArray } ) }
                ref={ fieldRef }
                className='InputField-ds__clearInputButton'
              />
            }
            { displayCheckIcon && (
              <Icon
                aria-hidden={ ariaHidden }
                className={ 'InputField-ds__actions--valid' }
                clear='true'
                icon='true'
                name={ icon }
                title={ iconTitle }
                size={ 'lg' }
              />
            ) }
            { enableShowHide && (
              <Button
                type='button'
                ariaLabel={ isHidden ? hideAccessibilityLabel : showAccessibilityLabel }
                iconImage={ isHidden ? 'hide' : 'show' }
                icon={ true }
                iconSize={ 'lg' }
                onClick={ handleToggleShowHide }
                ariaHiddenIcon={ true }
                className='InputField-ds__showHideButton'
              />
            ) }
            { icon && (
              <div className='InputField-ds__icon'>
                <Icon name={ icon }
                  title={ iconTitle }
                />
              </div>
            ) }
          </div>
        </div>
      </div>
      { ( () => {
        if( warningMessage && !isFocused ){
          return (
            <ErrorMessage
              name={ field.name }
              render={ () => (
                <InlineMessage messageType='warning'
                  message={ warningMessage }
                  id={ warningId }
                  role='alert'
                />
              ) }
            />
          );
        }
        else if( !isFocused && meta.touched && meta.error && enabled ){
          return (
            <div className='InputField-ds__message'>
              <ErrorMessage
                name={ field.name }
                render={ ( msg ) => {
                  return (
                    <InlineMessage messageType='error'
                      message={ msg }
                      id={ errorId }
                      dataTestId={ errorId }
                      role='alert'
                    />
                  );
                } }
              />
            </div>
          );
        }
        else if( helpText ){
          return (
            <div id={ helperId }>
              <Text textStyle='body-3'
                htmlTag='p'
                color='neutral-600'
              >
                { helpText }
              </Text>
            </div>
          );
        }
      } )() }
    </div>
  );
};

/**
 * Sets the focus and active state of the input field
 * @param {object} methods
 * @param {function} methods.setIsActive
 * @param {function} methods.setIsFocused
 */
export const handleOnFocus = ( methods ) => {
  const { setIsActive, setIsFocused } = methods || {};

  if( !setIsActive || !setIsFocused ){
    return;
  }
  setIsActive( true );
  setIsFocused( true );
};

/**
 * Handles on blur of input field (when the user clicks outside of the input field)
 * @param {object} methods
 * @param {function} methods.setIsFocused
 * @param {object} data
 */
export const handleBlur = ( methods, data ) => {
  const { e, field, props } = data || {};
  const { setIsFocused } = methods || {};
  if( !e || !field || !props || !setIsFocused ){
    return;
  }
  if( field.value === '' ){
    setIsFocused( false );
  }
  field.onBlur( e );
  if( props.handleBlur ){
    props.handleBlur( e );
  }
  if( props?.onBlur?.handleChange ){
    const { handleChange, showLoader, values, user } = props?.onBlur;
    const data = { values: values, user: user, props: props.onBlur.props };
    handleChange( data, showLoader );
  }
};

/**
 * Disables pasting values into the input field
 * @param {object} data
 * @returns boolean
 */
export const handlePaste = ( data ) => {
  const { e, props } = data || {};

  if( !e || !props ){
    return;
  }

  if( props.onPaste ){
    props.onPaste( e );
  }

  if( props.disablePaste ){
    e.preventDefault();
    return false;
  }
};

/**
 * @method
 * @param {object} methods Methods
 * @param {function} methods.setIsActive
 * @param {function} methods.setIsFocused
 */
export const handleFocusAway = ( methods ) => {
  const { setIsFocused, setIsActive } = methods || {};

  if( !setIsActive || !setIsFocused ){
    return;
  }

  setIsFocused( false );
  setIsActive( false );
};

/**
* Clears user input
* @param {object} data
*/
export const handleResetVal = ( data, methods ) => {
  const { helpers, inputEl } = data || {};
  const { setInputArray, onChange } = methods || {};

  if( !helpers || !inputEl ){
    return;
  }
  helpers.setValue( '' );
  setInputArray( [] );
  inputEl.current.focus();
  onChange && onChange( '' );
};

/**
 * Utility to handle the dynamic formatter.
 * @param {string} inputArray - input array which holds raw value
 * @param {string } formatter - holds the formatter with patterns in constant.js
 * @param { string } field - field value that holds the raw value.
 * @method helpers - to set the formatted value.
 */
export const handleFormatterChange = ( data, methods ) =>{
  const { inputArray, formatter, field, prefersRawValue } =  handleEmptyObjects( data );
  const { helpers } = methods;
  const formatted = utils.getDisplayValue(
    { content: inputArray.join( '' ), formatter, isMaskedInput: !!formatter, isHidden: formatter?.hidden },
    { getMaskedContent, getHiddenContent }
  );
  if( !prefersRawValue && !!formatter && formatted !== field.value ){
    helpers.setValue( formatted );
  }
};
/**
* Property type definitions
* @typedef InputFieldProps
* @type {object}
* @property {string} type - Specifies the HTML5 input field type (currently one of 'text', 'number', 'password', 'email', 'tel', 'date')
* @property {string} label - The value used for the input field's label tag above the input field
* @property {string} value - The value entered int he input field
* @property {string} name - The value used for the input field's name
* @property {string} helpText - help text displayed below the input field
* @property {string} placeholderLabel - The input field placeholder attribute value
* @property {array} params - name and value of the input field
* @property {string} clearAccessbilityLabel - AriaLabel text for the input field's 'Clear' button (reset)
* @property {string} showAccessibilityLabel - AriaLabel text for the input field's 'show password' button
* @property {string} hideAccessibilityLabel - AriaLabel text for the input field's 'hide password' button
* @property {array} validations - Array of validation conditions
* @property {string} inputMask - Specifies the masking for the input (example: (999)999-9999 for phone number)
* @property {string} autoComplete - Sets the input field's autocomplete attribute value
* @property {string} autoCorrect - Sets the input field's autocorrect attribute value
* @property {string} autoCapitalize - Sets the input field's autocapitalize attribute value
* @property {boolean} spellCheck - Sets the spellcheck attribute, which defines whether the input may be checked for spelling errors.
* @property {boolean} disablePaste - Flag to disable pasting a value into the field
* @property {boolean} enabled - Flag to disable input field
* @property {number} maxLength - Sets the max length of input field
* @property {string} icon - Sets the credit card icon name
* @property {string} iconTitle - Sets the credit card icon title
* @property {string} ariaHidden - Flag to add credit card icon aria hidden on input field
*/
export const propTypes = {
  /** Specifies the HTML5 input field type (currently one of 'text', 'number', 'password', 'email', 'tel', 'date') to render */
  type: PropTypes.oneOf( ['text', 'number', 'password', 'email', 'tel', 'date'] ),
  /** Value of the inputbox */
  value: PropTypes.string,
  /** Name of the input to render over the inputbox */
  name: PropTypes.string,
  /** Label of the input to render over the inputbox */
  label: PropTypes.string,
  /** Helper text that is desplayed below the inputbox */
  helpText: PropTypes.string,
  /** The input field placeholder attribute value */
  placeholderLabel: PropTypes.string,
  /** Values of the input field [name, value] */
  params: PropTypes.array,
  /** AriaLabel text for the input field's 'Clear' button (reset) */
  clearAccessbilityLabel: PropTypes.string,
  /** AriaLabel text for the input field's 'Show' button (example: for Password) */
  showAccessibilityLabel: PropTypes.string,
  /** AriaLabel text for the input field's 'Hide' button (example: for Password) */
  hideAccessibilityLabel: PropTypes.string,
  /** Validations for the input field [regex, message] */
  validations: PropTypes.array,
  /** The tokenized string with the 'pattern' key and input's format value if needed of the input  */
  inputMask: PropTypes.string,
  /** Sets the input field's autocomplete attribute valuet*/
  autoComplete: PropTypes.string,
  /** Sets the input field's autocorrect attribute value */
  autoCorrect: PropTypes.string,
  /** Sets the input field's autocapitalize attribute value */
  autoCapitalize: PropTypes.string,
  /** Flag to sets the spellcheck attribute, which defines whether the input may be checked for spelling errors. */
  spellCheck: PropTypes.bool,
  /** Flag to disable/enable pasting the value for the input field  */
  disablePaste: PropTypes.bool,
  /** Flag to disable input field  */
  enabled: PropTypes.bool,
  /** Sets the max length of input field */
  maxLength: PropTypes.number,
  /** Sets the credit card icon of input field */
  icon: PropTypes.string,
  /** Sets the credit card icon title of input field */
  iconTitle:PropTypes.string,
  /** Flag to add credit card icon aria hidden on input field */
  ariaHidden:PropTypes.bool
};

/**
* Default values for passed properties
* @type {object}
* @property {boolean} enabled=true - enabled boolean, defaults to true
*/
export const defaultProps = {
  enabled: true,
  ariaHidden: true
};
InputField.propTypes = propTypes;
InputField.defaultProps = defaultProps;

export default InputField;

/**
 * Returns the display value based on the provided data and methods.
 *
 * @param {object} data - The data object containing content, formatter, isMaskedInput, and isHidden properties.
 * @param {object} methods - The methods object containing getMaskedContent and getHiddenContent functions.
 * @return {string} The display value based on the provided data and methods.
 */
export const getDisplayValue = ( data, methods ) => {
  const { content, formatter, isMaskedInput, isHidden, type } = data || {};
  const { getMaskedContent, getHiddenContent } = methods || {};
  const { pattern, hidden, displayFn } = formatter || {};

  // value output for display
  let displayInputValue;

  if( isHidden && type !== 'password' ){
    // If hidden, get the hidden content
    displayInputValue = getHiddenContent( content, pattern, hidden );
  }
  else if( displayFn ){
      displayInputValue = displayFn( content ); // eslint-disable-line
  }
  else if( isMaskedInput ){
    // If currently masked, get the masked content based on the pattern and inputArray
    displayInputValue = getMaskedContent( pattern, content );
  }
  else {
    // Otherwise, join the inputArray to get the original content
    displayInputValue = content;
  }

  return displayInputValue;
};
/**
 * Handles the input change event.
 *
 * @param {Object} data - The data object containing formatter, helpers, INVALID_INPUT_CHARACTERS, and isMaskedInput.
 * @param {Object} methods - The methods object containing getDisplayValue, onChange, setIsActive, and setInputArray.
 * @return {Function} The event handler function.
 */
export const handleInputChange = ( data, methods ) => {
  const {
    formatter,
    helpers,
    INVALID_INPUT_CHARACTERS,
    isMaskedInput,
    prefersRawValue
  } = data || {};
  const {
    getDisplayValue,
    onChange,
    setIsActive,
    setInputArray,
    updateInputValue
  } = methods || {};
  return ( event ) => {
    // Get the input value from the event
    const eValue = event.target.value;

    // If currently masked, remove invalid characters from the input
    const userInputValue = isMaskedInput ? eValue.replace( INVALID_INPUT_CHARACTERS, '' ) : eValue;

    // Setting the focused state
    setIsActive( true );

    // Update the inputArray with the raw input value ignoring the SECURE_CHARACTER
    setInputArray( updateInputValue(
      { userInputValue, formatter, isMaskedInput, prefersRawValue },
      { onChange, helpers, getDisplayValue }
    ) );
  };
};

/**
 * Handles the toggle of show/hide functionality.
 *
 * @param {any} data - The data parameter.
 * @param {object} methods - The methods parameter.
 * @param {function} methods.setIsHidden - The setIsHidden method.
 * @return {void} This function does not return anything.
 */
export const handleToggleShowHide = ( data, methods ) => {
  const { inputEl, inputType } = data || {};
  const { setIsHidden } = methods || {};

  if( !inputEl ){
    return;
  }
  setIsHidden( ( prevHiddenValue ) => {
    if( inputType.current === 'password' ){
      // switch between hidden (password) and visible (text)
      inputEl.current.type = prevHiddenValue ? 'text' : 'password';
      // ensure this input field stays 'password' type
      inputType.current = 'password';
    }
    return !prevHiddenValue;
  } );

};

/**
 * Handles the logic for modifying input data as the user types
 *
 * @param {*} data
 * @param {*} methods
 * @returns {array}
 */
export const updateInputValue = ( data, methods ) => {
  const { userInputValue, formatter, isMaskedInput, prefersRawValue } = data;
  const { onChange, helpers, getDisplayValue } = methods;

  return ( prevInputArray ) => {
    // Split the input string into an array of characters for ease of manuipulation
    const userInputValueArray = userInputValue.split( '' ) || [];

    // final output value
    let updatedValue = [];

    // This manipulation is required to save the non-hidden user inputs back to state and formik
    for ( let i = 0; i < userInputValueArray.length; i++ ){
      if( userInputValueArray[i] !== SECURE_CHARACTER ){
      // If the character is not SECURE_CHARACTER, use the userInputValueArray value
        updatedValue[i] = userInputValueArray[i];
      }
      else {
      // If the character is SECURE_CHARACTER, retain the previous inputArray value
        updatedValue[i] = prevInputArray[i];
      }
    }

    // get the flattend string from Array
    const content = updatedValue.join( '' );

    // get the masked, non-hidden value
    const updatedValueString = getDisplayValue(
      { content, formatter, isMaskedInput, isHidden: false },
      { getMaskedContent } );

    // formik support to save the raw value ( not sure if we need masked or just raw )
    if( prefersRawValue ){
      helpers.setValue( content );
    }
    else {
      helpers.setValue( updatedValueString );
    }

    // fire the onChange passed by parent component if it exists
    onChange && onChange( updatedValueString );
    return updatedValue;
  };
};