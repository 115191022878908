/**
 * Section component for rendering amplience content wrapped up using section schema.
 *
 * @module views/components/Section
 * @memberof -Common
 */
import './Section.scss';

import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import Markdown from '@ulta/core/components/Markdown/Markdown';
import Text from '@ulta/core/components/Text/Text';
import { scrollToElement } from '@ulta/core/utils/scrollToElement/scrollToElement';

/**
  * Represents a Section component
  *
  * @method
  * @param {SectionProps} props - React properties passed from composition
  * @returns Section
  */
export const Section = function( props ){
  const { title, sectionId, body, bodyStyle, alignment, bodyColor } = props;
  const id = getId( { title, sectionId } );
  const ref = useRef();
  const hasTitle = title && title.text;

  useEffect( () => {
    if( !ref.current || !id || global.location.hash !== `#${id}` ){
      return;
    }

    scrollToElement( { el: ref.current, animate: false } );
  }, [ref.current] );

  return (
    <div className='Section'
      id={ id }
      ref={ ref }
    >
      <div className='Section__wrapper'>
        <div className='Section__contentSection'>
          { hasTitle &&
          <Text
            htmlTag={ title.htmlTag }
            textStyle={ title.textStyle }
            color={ title.color }
            textAlign={ title.textAlign }
          >
            { title.text }
          </Text>
          }
          { body &&
          <div className={ `Section__markDown--${alignment}Align` }>
            <Markdown
              bodyStyle={ bodyStyle }
              cmsMarkdownContent={ body }
              bodyColor={ bodyColor }
            />
          </div>
          }
        </div>
      </div>
    </div>
  );
};

/**
  * Generates an ID based on available props
  * @param {object} data Accepted arguments
  * @param {string} data.sectionId Section ID definfed by CMS
  * @param {string} data.title Amplience Title component, we use this to generate a dynamic Id
  * @returns string
  */
export const getId = ( data ) => {
  if( data?.sectionId ){
    return data.sectionId;
  }
  else if( data?.title?.text ){
    return data?.title?.text.toLowerCase().replace( /\W/g, '-' );
  }

  return '';
};

/**
  * Default values for passed properties
  * @type {object}
  * @property {string} alignment='left' - The default value for the text alignment is left
  */
export const defaultProps =  {
  alignment: 'left'
};

/**
   * Property type definitions
   * @typedef SectionProps
   * @type {object}
   * @property {object} text - Sets the subtitle value with styling
   * @property {string} sectionId - Sets the anchorable div ID
   * @property {string} body - Sets the body value
   * @property {string} bodyStyle - Sets the body style tag
   * @property {string} bodyColor - Sets the body color
   */

export const propTypes = {
  /** Sets the text for display */
  title: PropTypes.object,
  /** Sets the anchorable div ID */
  sectionId: PropTypes.string,
  /** Sets the body for display */
  body: PropTypes.string,
  /** Sets the body styling tag for display */
  bodyStyle: PropTypes.string,
  /** Sets the body color for display */
  bodyColor: PropTypes.string
};

Section.propTypes = propTypes;
Section.defaultProps = defaultProps;

export default Section;
