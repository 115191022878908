/**
 * rendering the modles elements for any module's modules
 *
 * @module views/components/Modules
 * @memberof -Common
 */

import React from 'react';

import PropTypes from 'prop-types';

import AsyncComponent from '@ulta/core/components/AsyncComponent/AsyncComponent';
import { hasItems } from '@ulta/core/utils/array/array';


/**
 * Represents a modules component
 *
 * @method
 * @param { ModulesProps} props - React properties passed from composition
 * @returns Modules
 */
export const Modules = function( props ){
  if( !hasItems( props.modules ) ){
    return null;
  }

  // eslint-disable-next-line unused-imports/no-unused-vars
  let { modules, ...rest } = props;

  return props.modules?.map( ( component, index ) => (
    <AsyncComponent key={ `${component.id}:${index}` }
      /* this rest is being spread because the navigation link does weird stuff */
      { ...rest }
      { ...component }
    />
  ) );
};

/**
 * Property type definitions
 * @typedef ModulesProps
 * @type {object}
 * @property {array} modules - Array of modules (components) in this slot
 */
export const propTypes =  {
  modules: PropTypes.array
};


Modules.propTypes = propTypes;

export default Modules;

