/**
 * This component will display the curated product rail
 *
 * @module views/components/CuratedProductRail
 * @memberof -Common
 */
import React from 'react';

import PropTypes from 'prop-types';

import ProductRail from '@ulta/components/ProductRail/ProductRail';

/**
 * Represents a CuratedProductRail component
 *
 * @method
 * @param {CuratedProductRailProps} props - React properties passed from composition
 * @returns CuratedProductRail
 */
export const CuratedProductRail = function( props ){
  return (
    <ProductRail { ...props } />
  );
};


/**
 * Default values for passed properties
 * @type {object}
 * @property {string} deviceType='' - The default value of the deviceType is empty string.
 * @property {string} previousAccessibility='' - The default value of the previousAccessibility is empty string.
 * @property {string} nextAccessibility='' - The default value of the nextAccessibility is empty string.
 * @property {boolean} ssr=false - The default value for ssr is set to false.
 * @property {string} title='' - The default value of the title is empty string.
 * @property {string} countLabel='' - The default value of the count label is empty string.
 * @property {boolean} arrows=false - The default value for arrows is set to false.
 * @property {number} slidesToSlide=4 - The default value for slidesToSlide is set to 4.
 * @property {array} items=[] - The default value for items is set to empty array.
 */
export const defaultProps = {
  deviceType: '',
  previousAccessibility: '',
  nextAccessibility: '',
  ssr: false,
  title: '',
  countLabel: '',
  arrows: false,
  slidesToSlide: 4,
  items: []
};

/**
  * Property type definitions
  * @typedef CuratedProductRailProps
  * @type {object}
  * @property {string} deviceType - Sets the device type.
  * @property {string} previousAccessibility - Sets the aria label for previous button.
  * @property {string} nextAccessibility - Sets the aria label for next button.
  * @property {boolean} ssr - Sets server side rendering.
  * @property {string} title - Sets the title for productRail list.
  * @property {string} countLabel - Sets the count label for productRail list.
  * @property {boolean} arrows - Sets whether to show or hide default arrows on carousel.
  * @property {number} slidesToSlide - Sets the number of slides to slide when cliked on next or previous button.
  * @property {Array.<{promotionalMessage: string, reviewCount: Number, rating: Number, brandName: string, productName: string, variantType: string, variantCount: string, badge: string, imageUrl: string, availability: array, price: object}>} products - List of products to loop over and display in carousel.
  * @property {object} viewAllAction - This is the action object with action label and url.
  * @property {string} carouselAccessibility - Sets the Carousel role='Carousel' attribute value
  * @property {string} carouselDetailsAccessibility - Sets Carousel details screen reader information
  * @property {string} carouselCardAccessibility - Sets carousel item number of total count string
*/
export const propTypes = {
  /**
  * The prop that holds the device type.
  */
  deviceType: PropTypes.string,
  /**
  * The prop that holds the aria label for previous button.
  */
  previousAccessibility: PropTypes.string,
  /**
  * The prop that holds the aria label for next button.
  */
  nextAccessibility: PropTypes.string,
  /**
  * The prop that decides to render on server side or not.
  */
  ssr: PropTypes.bool,
  /**
  * The prop that holds title for the carousel.
  */
  title: PropTypes.string,
  /**
  * The prop that holds the count label for the product list.
  */
  countLabel: PropTypes.string,
  /**
  * The prop that decides to render default arrows on carousel.
  */
  arrows: PropTypes.bool,
  /**
  * The prop that decides number of slides to slide when cliked on next or previous button.
  */
  slidesToSlide: PropTypes.number,
  /**
  * The prop that holds the list of products to render.
  */
  items: PropTypes.arrayOf(
    PropTypes.shape( {
      promotionalMessage: PropTypes.string,
      reviewCount: PropTypes.number,
      rating: PropTypes.number,
      brandName: PropTypes.string,
      productName: PropTypes.string,
      variantType: PropTypes.string,
      variantCount: PropTypes.string,
      badge: PropTypes.string,
      imageUrl: PropTypes.string,
      availability: PropTypes.arrayOf( PropTypes.string ),
      price: PropTypes.shape( {
        listPrice: PropTypes.string
      } )
    } )
  ),
  /**
  * This is the action with label and url.
  */
  viewAllAction: PropTypes.shape( {
    label: PropTypes.string,
    url: PropTypes.string
  } ),
  /**
   * Sets the carouselAccessibility
   */
  carouselAccessibility: PropTypes.string,
  /**
   * Sets the carouselDetailsAccessibility
   */
  carouselDetailsAccessibility: PropTypes.string,
  /**
   * Sets the carouselCardAccessibility
   */
  carouselCardAccessibility: PropTypes.string
};

CuratedProductRail.propTypes = propTypes;
CuratedProductRail.defaultProps = defaultProps;

export default CuratedProductRail;