/**
 * React hook to Determine how a layerhost should invoke a dxl Query
 *
 * @module utils/useLayerHostMeta
 */

import { useEffect, useRef } from 'react';

import { useLayerHostContext } from '@ulta/core/providers/LayerHostProvider/LayerHostProvider';

import { PAGE_MODULE_NAME } from '../../components/Page/Page';
import { useAppConfigContext } from '../../providers/AppConfigProvider/AppConfigProvider';
import { usePageDataContext } from '../../providers/PageDataProvider/PageDataProvider';
import { useSnackBar } from '../../providers/SnackBarProvider/SnackBarProvider';
import { useUserContext } from '../../providers/UserContextProvider/UserContextProvider';
import { DATACAPTURE_UNRESOLVED_KEY, emitTypeAheadData } from '../../utils/datacapture/datacapture';
import { debugModuleName, devLogger, LOG_TOPIC } from '../../utils/devMode/devMode';
import { handleEmptyObjects } from '../../utils/handleEmptyObjects/handleEmptyObjects';
import * as utils from './useLayerHostMeta';

/**
 * Handles the meta object from the response
 * @param {object} data arguments
 * @param {object} data.meta meta object from the response, we look for initAction or snackBar
 */
export const useLayerHostMeta = ( data ) => {
  const { meta = {}, props = {}, loading } = handleEmptyObjects( data );
  const { ATG_STATUS, initAction, snackBar, searchData, sessionData, sessionAction, disableInitAction } = meta;
  const { showSnackBar } = useSnackBar();
  const { updateSession, processSessionAction, isInitialSessionPing, resetSession } = useUserContext();
  const { isDXLSessionManagement, updateAppConfig } = useAppConfigContext();
  const { pageLastUpdated } = usePageDataContext();
  const { killSwitchActivated } = useLayerHostContext();

  const hasProcessedSearchDataCapture = useRef( false );
  const hasProcessedSessionAction = useRef( false );
  const sessionActionLastProcessed = useRef( 0 );

  useEffect( () => {
    if( disableInitAction ){
      devLogger( `killSwitchActivated | ${debugModuleName( props )}`, 0, LOG_TOPIC.Session );
      killSwitchActivated.current = true;
    }
  }, [disableInitAction] );

  /*
   * Handles deriving DXL session mangement flag. We attempt to derive this flag from the initial SSR response
   * but for some end points like `/header` we don't load a page response before rendering the HTML
   *
   * -> If we have a session action at the page level we're using DXL session management
   * -> UserContextProvider will invoke a session management strategy absed on this flag
   */
  useEffect( () => {
    // Checks if we're at the page level with a session action and we haven't derived our session management strategy yet
    if( props.moduleName !== PAGE_MODULE_NAME || typeof isDXLSessionManagement === 'boolean' || !updateAppConfig ){
      return;
    }

    const hasSessionAction = props.moduleName === PAGE_MODULE_NAME && !!sessionAction?.graphql;
    devLogger( `[Session] setting isDXLSessionManagement dynamically to '${hasSessionAction}'`, 0, LOG_TOPIC.Session );

    updateAppConfig( { isDXLSessionManagement: hasSessionAction } );
  }, [isDXLSessionManagement, sessionAction, updateAppConfig] );

  /*
   * Handle resetting hasProcessedSessionAction
   * -> if this is the "initial session ping" to DXL from the root page's sessionAction,
   *    we never want to re-process it
   * -> If the layer's componentLastUpdated has changed and there is a new sessionAction,
   *    we reset the flag so that we can process it again
   */
  useEffect( () => {
    // Only process the Page sessionAction one time, otherwise compare to the layer's componentLastUpdated
    const hasProcessed =
      ( !isInitialSessionPing?.current && props.moduleName === PAGE_MODULE_NAME ) ||
      sessionActionLastProcessed.current === props.componentLastUpdated;

    if( hasProcessed || !sessionAction?.graphql ){
      return;
    }

    // first time session current will be pageLastUpdated when there is a pageModuleName otherwise componentLastUpdated
    const lastProcessed = props.moduleName === PAGE_MODULE_NAME ? pageLastUpdated : props.componentLastUpdated;
    sessionActionLastProcessed.current = lastProcessed;

    hasProcessedSessionAction.current = false;

    if( isInitialSessionPing && isInitialSessionPing?.current ){
      isInitialSessionPing.current = false;
    }

    devLogger( `reset processSessionAction | ${debugModuleName( props )}`, 0, LOG_TOPIC.Session );
  }, [props.componentLastUpdated, sessionAction] );

  /*
   * Processes incoming session action from DXL, sends it up to UserContextProvider
   */
  useEffect( () => {
    if( hasProcessedSessionAction.current || !sessionAction?.graphql || !processSessionAction ){
      return;
    }

    devLogger( `processSessionAction | ${debugModuleName( props )}`, 0, LOG_TOPIC.Session );
    hasProcessedSessionAction.current = true;

    processSessionAction( { sessionAction, props } );
  }, [processSessionAction, sessionAction] );


  /*
   * Listens for and merges incoming session data from DXL
   */
  useEffect( () => {
    if( !sessionData || loading || !updateSession ){
      return;
    }

    devLogger( {
      topic: LOG_TOPIC.Session,
      title: `session update | ${debugModuleName( props )}`,
      value: sessionData
    } );

    updateSession( { sessionData } );
  }, [updateSession, loading, sessionData] );


  /* STOP GAP - Handle ATG Session Status
   * Remove when ATG is sunset
   */
  useEffect( () => {
    utils.handleATGResponseStatus( { isDXLSessionManagement, responseStatus: ATG_STATUS }, { resetSession } );
  }, [isDXLSessionManagement, props.componentLastUpdated, pageLastUpdated, ATG_STATUS] );


  /*
   * Listens for snackbars
   */
  useEffect( () => {
    if( !snackBar ){
      return;
    }

    // Delay showing snackbars for a bit until the UI re-renders. When we update the user session
    // while trying to animate a snackbar, there's too much lag and the Snackbar will animate before the UI update
    setTimeout( () => {
      showSnackBar( snackBar );
    }, 300 );
  }, [snackBar] );


  /*
   * Listens for search redirect
   */
  useEffect( () => {
    const isSearchRedirect =
      !!initAction?.url && initAction.clientActionParams?.eventNames?.includes( SEARCH_REDIRECT_EVENT );


    if( !isSearchRedirect || hasProcessedSearchDataCapture.current ){
      return;
    }

    devLogger( { title: 'Typeahead DC > Saving Redirect', value: initAction.url, collapsed: true } );

    hasProcessedSearchDataCapture.current = true;

    emitTypeAheadData( { targetUrl: initAction.url } );
  }, [initAction] );


  /*
   * Listens for searchCount
   */
  useEffect( () => {
    // We need to check for null because the resultCount can be 0
    const hasResultCount = typeof searchData?.resultCount !== 'undefined' && searchData?.resultCount !== null;

    if( !hasResultCount || hasProcessedSearchDataCapture.current ){
      return;
    }

    devLogger( { title: 'Typeahead DC > Dispatching Result Count', value: searchData, collapsed: true } );

    hasProcessedSearchDataCapture.current = true;

    const typeAheadData = {
      [DATACAPTURE_UNRESOLVED_KEY.SearchResult]: searchData.resultCount.toString()
    };

    emitTypeAheadData( { fireEvent: true, typeAheadData } );
  }, [searchData] );
};


/**
 * @method handleATGResponseStatus
 * @summary this method takes the response status and checks if it falls
 * under specific states it dispatches the customEvent to refresh the session
 * @param  {String} responseStatus
 */
export const handleATGResponseStatus = ( data, methods ) => {
  const { isDXLSessionManagement, responseStatus } = data || {};
  const { resetSession } = methods || {};

  if( isDXLSessionManagement || !responseStatus || !resetSession || !ATG_SESSION_REFRESH_MAP.includes( responseStatus ) ){
    return;
  }

  resetSession();
};

/**
 * @const {object} ATG_SESSION_STATUS - ATG session status
 */
export const ATG_SESSION_STATUS = {
  SessionConflict: 'SESSION_CONFLICT',
  SessionInvalidate: 'SESSION_INVALIDATE',
  SessionUnauthorized: 'SESSION_UNAUTHORIZED',
  SessionUpdate: 'UPDATE_SESSION'
};

/**
 * @const {array} ATG_SESSION_REFRESH_MAP - ATG session status that require a session refresh
 */
export const ATG_SESSION_REFRESH_MAP = [
  ATG_SESSION_STATUS.SessionConflict,
  ATG_SESSION_STATUS.SessionInvalidate,
  ATG_SESSION_STATUS.SessionUnauthorized,
  ATG_SESSION_STATUS.SessionUpdate
];

/**
 * @const {string} SEARCH_REDIRECT_EVENT - Search redirect event name
 */
export const SEARCH_REDIRECT_EVENT = 'searchRedirect';
