const gQL = `
query Page($url: JSON, $stagingHost: String, $contentId:String, $previewOptions: JSON) {
  Page(url:$url, stagingHost:$stagingHost, contentId:$contentId, previewOptions:$previewOptions) {
    content
    previewOptions
    meta
  }
}
`;
export default gQL;
