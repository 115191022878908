import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12ArrowBack( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <g
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M.5 6h11M5.5 11l-5-5.06 5-5.027' />
      </g>
    </SvgContainer>
  );
}

export default Svg12ArrowBack;
