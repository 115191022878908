import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function SVG( props ){

  return (
    <SvgContainer
      { ...props }
      width={ 194 }
      height={ 120 }
    >
      <g id='Components'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='Credit-Card-Icon-1'
          transform='translate(0.000000, 0.130000)'
          fillRule='nonzero'
        >
          <g id='paypal'>
            <path d='M36,0 C37.1045695,0 38,0.8954305 38,2 L38,21.75 C38,22.8545695 37.1045695,23.75 36,23.75 L2,23.75 C0.8954305,23.75 0,22.8545695 0,21.75 L0,2 C0,0.8954305 0.8954305,0 2,0 L36,0 Z'
              id='Rectangle'
              fill='#FFFFFF'
            ></path>
            <path d='M36,0 C37.1045695,0 38,0.8954305 38,2 L38,21.75 C38,22.8545695 37.1045695,23.75 36,23.75 L2,23.75 C0.8954305,23.75 0,22.8545695 0,21.75 L0,2 C0,0.8954305 0.8954305,0 2,0 L36,0 Z M36,0.5 L2,0.5 C1.22030388,0.5 0.579551322,1.09488808 0.506866578,1.85553999 L0.5,2 L0.5,21.75 C0.5,22.5296961 1.09488808,23.1704487 1.85553999,23.2431334 L2,23.25 L36,23.25 C36.7796961,23.25 37.4204487,22.6551119 37.4931334,21.89446 L37.5,21.75 L37.5,2 C37.5,1.22030388 36.9051119,0.579551322 36.14446,0.506866578 L36,0.5 Z'
              id='Rectangle'
              fill='#C0C0C0'
            ></path>
            <path d='M10.42,8.87 C10.0207395,8.47723208 9.46715187,8.28292648 8.91,8.34 L6.86,8.34 C6.71804831,8.34354535 6.59954138,8.44935511 6.58,8.59 L5.73,13.87 C5.72428611,13.9210977 5.74069651,13.9721998 5.77509211,14.0104171 C5.8094877,14.0486344 5.85858483,14.0703185 5.91,14.0700035 L6.91,14.0700035 C7.03338755,14.0675746 7.14172375,13.9873256 7.18,13.87 L7.41,12.43 C7.42049159,12.2887454 7.5383568,12.1796109 7.68,12.18 L8.33,12.18 C8.91058354,12.2441179 9.49189773,12.0658492 9.93672301,11.6872745 C10.3815483,11.3086998 10.6504667,10.7633662 10.68,10.18 C10.7926417,9.72640079 10.6973345,9.24619935 10.42,8.87 Z M9.15,10.26 C9.04,11.01 8.47,11.01 7.93,11.01 L7.62,11.01 L7.83,9.62 C7.83971023,9.5321681 7.9117413,9.46437415 8,9.46 L8.14,9.46 C8.51,9.46 8.86,9.46 9.04,9.67 C9.16475656,9.83958623 9.20526349,10.0568507 9.15,10.26 L9.15,10.26 Z'
              id='Shape'
              fill='#253B80'
            ></path>
            <path d='M15.09,10.23 L14.09,10.23 C14.0055521,10.2314718 13.9322137,10.2885128 13.91,10.37 L13.91,10.65 L13.84,10.54 C13.5383466,10.2292939 13.1106473,10.0744373 12.68,10.12 C11.5492249,10.146202 10.6086009,10.9974455 10.47,12.12 C10.3636106,12.6595691 10.4988174,13.2186673 10.84,13.65 C11.1570107,14.0008799 11.6184543,14.1854574 12.09,14.15 C12.6055035,14.1558787 13.1008029,13.9498052 13.46,13.58 L13.46,13.87 C13.4572729,13.9177574 13.4736675,13.9646362 13.5055638,14.000285 C13.5374601,14.0359338 13.5822344,14.0574203 13.63,14.06 L14.52,14.06 C14.6602768,14.0614378 14.7799668,13.9588464 14.8,13.82 L15.33,10.41 C15.3396021,10.3479556 15.3142264,10.2856698 15.264,10.248 C15.2137736,10.2103302 15.146874,10.2034095 15.09,10.23 L15.09,10.23 Z M12.6,13.12 C12.3521802,13.136446 12.1100187,13.041049 11.94,12.86 C11.791868,12.659002 11.7372349,12.4040474 11.79,12.16 C11.8463533,11.5940948 12.3213006,11.1623245 12.89,11.16 C13.13918,11.1475191 13.3810139,11.2464511 13.55,11.43 C13.7102438,11.6253025 13.7728164,11.8829544 13.72,12.13 C13.6624252,12.7058841 13.1684543,13.1386551 12.59,13.12 L12.6,13.12 Z'
              id='Shape'
              fill='#253B80'
            ></path>
            <path d='M20.35,10.23 L19.35,10.23 C19.2533331,10.2306239 19.1633326,10.2793742 19.11,10.36 L17.74,12.36 L17.16,10.36 C17.1241896,10.2352199 17.0098158,10.1494395 16.88,10.1499973 L15.88,10.1499973 C15.8240191,10.14969 15.7714741,10.1769607 15.7395049,10.2229164 C15.7075357,10.2688722 15.7002409,10.3276212 15.72,10.38 L16.81,13.61 L15.81,15.07 C15.7721078,15.122078 15.7668987,15.1910975 15.7965452,15.2482728 C15.8261917,15.3054482 15.8856027,15.340961 15.95,15.3400436 L16.95,15.3400436 C17.0448073,15.341642 17.1344292,15.296831 17.19,15.22 L20.49,10.42 C20.5020592,10.3293135 20.4401885,10.2453461 20.35,10.23 Z'
              id='Path'
              fill='#253B80'
            ></path>
            <path d='M25.14,8.87 C24.7399856,8.47844332 24.1869834,8.28434322 23.63,8.34 L21.57,8.34 C21.43019,8.34419708 21.3149234,8.4509254 21.3,8.59 L20.45,13.87 C20.4442861,13.9210977 20.4606965,13.9721998 20.4950921,14.0104171 C20.5294877,14.0486344 20.5785848,14.0703185 20.63,14.0700035 L21.69,14.0700035 C21.7892504,14.0655203 21.8701659,13.9888635 21.88,13.89 L22.12,12.38 C22.1395414,12.2393551 22.2580483,12.1335453 22.4,12.13 L23.05,12.13 C23.6305835,12.1941179 24.2118977,12.0158492 24.656723,11.6372745 C25.1015483,11.2586998 25.3704667,10.7133662 25.4,10.13 C25.4922016,9.69211826 25.398003,9.23561731 25.14,8.87 Z M23.87,10.28 C23.76,11.03 23.19,11.03 22.65,11.03 L22.34,11.03 L22.55,9.64 C22.5604611,9.55003302 22.6396215,9.48406602 22.73,9.49 L22.87,9.49 C23.24,9.49 23.59,9.49 23.77,9.7 C23.8823095,9.8636021 23.9187445,10.0676382 23.87,10.26 L23.87,10.28 Z'
              id='Shape'
              fill='#179BD7'
            ></path>
            <path d='M29.81,10.23 L28.81,10.23 C28.7243217,10.2278631 28.6490189,10.2864319 28.63,10.37 L28.63,10.65 L28.56,10.54 C28.2583466,10.2292939 27.8306473,10.0744373 27.4,10.12 C26.2692249,10.146202 25.3286009,10.9974455 25.19,12.12 C25.0836106,12.6595691 25.2188174,13.2186673 25.56,13.65 C25.8770107,14.0008799 26.3384543,14.1854574 26.81,14.15 C27.3255035,14.1558787 27.8208029,13.9498052 28.18,13.58 L28.18,13.87 C28.1742603,13.9184383 28.1896036,13.9670085 28.2221279,14.0033592 C28.2546523,14.03971 28.301224,14.0603389 28.35,14.06 L29.24,14.06 C29.3802768,14.0614378 29.4999668,13.9588464 29.52,13.82 L30,10.43 C30.010146,10.3762925 29.9938498,10.3209823 29.9562045,10.2813557 C29.9185592,10.2417291 29.8641569,10.2226198 29.81,10.23 L29.81,10.23 Z M27.32,13.12 C27.071956,13.1378994 26.8291914,13.0422649 26.66,12.86 C26.511868,12.659002 26.4572349,12.4040474 26.51,12.16 C26.5618602,11.5918459 27.0394889,11.1576381 27.61,11.16 C27.85918,11.1475191 28.1010139,11.2464511 28.27,11.43 C28.4251404,11.627813 28.48713,11.8830642 28.44,12.13 C28.3826734,12.7019427 27.8946542,13.1333168 27.32,13.12 Z'
              id='Shape'
              fill='#179BD7'
            ></path>
            <path d='M32.1,8.30999584 L31.1,8.30999584 C31.013398,8.30939882 30.9401866,8.37399709 30.93,8.46 L30.13,13.87 C30.1210277,13.9200087 30.1348933,13.9714092 30.1677986,14.0101212 C30.2007038,14.0488332 30.2491991,14.0707985 30.3,14.070021 L31.15,14.070021 C31.288731,14.0672886 31.4061028,13.9666842 31.43,13.83 L32.27,8.5 C32.2757397,8.4515617 32.2603964,8.40299154 32.2278721,8.36664077 C32.1953477,8.33029 32.148776,8.30966113 32.1,8.30999584 Z'
              id='Path'
              fill='#179BD7'
            ></path>
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}

export default SVG;

