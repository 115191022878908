import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24Copy( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
      viewBox='0 0 24 24'
    >
      <path d='M20.25,8.249v-3a1.5,1.5,0,0,0-1.5-1.5h-4'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5px'
      ></path>
      <path d='M6.25,3.749h-4a1.5,1.5,0,0,0-1.5,1.5v16.5a1.5,1.5,0,0,0,1.5,1.5h6'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5px'
      ></path>
      <path d='M14.421,4.736a.751.751,0,0,1-.712.513H7.291a.751.751,0,0,1-.712-.513l-1-3A.749.749,0,0,1,6.291.749h8.418a.749.749,0,0,1,.712.987Z'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5px'
      ></path>
      <rect x='11.25'
        y='11.249'
        width='12'
        height='12'
        rx='1.5'
        ry='1.5'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5px'
      >
      </rect>
      <line x1='14.25'
        y1='14.249'
        x2='20.25'
        y2='14.249'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5px'
      >
      </line>
      <line x1='14.25'
        y1='17.249'
        x2='20.25'
        y2='17.249'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5px'
      >
      </line>
      <line x1='14.25'
        y1='20.249'
        x2='16.5'
        y2='20.249'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5px'
      ></line>
    </SvgContainer>
  );
}

export default Svg24Copy;
