/**
 * This component will be rendered if page has static error
 *
 * @module views/components/StaticError
 * @memberof -Common
 */
import './StaticError.scss';

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import GhostCards from '@ulta/core/components/GhostCards/GhostCards';
import GridContainer from '@ulta/core/components/GridContainer/GridContainer';
import Spacer from '@ulta/core/components/Spacer/Spacer';
import Text from '@ulta/core/components/Text/Text';
import { useAppConfigContext } from '@ulta/core/providers/AppConfigProvider/AppConfigProvider';

import Link from '@ulta/modules/Link/Link';

import { UltaSiteLogo } from '@ulta/icons';

/**
 * Represents a StaticError component
 *
 * @method
 * @param {object} props - React properties passed from composition
 * @returns StaticError
 */

export const StaticError = function( props ){
  const { errorImageUrl, errorText, errorSubText, ctaLink, isStaging } = props;

  if( isStaging ){
    return (
      <div className='StaticError'>
        <div className='StaticError__ultaLogo'>
          <UltaSiteLogo />
        </div>
        <StagingWaitingRoom />
      </div>
    );
  }

  return (
    <GridContainer>
      <div className='StaticError'>
        <div className='StaticError__ultaLogo'>
          <UltaSiteLogo />
        </div>
        <img src={ errorImageUrl }
          alt='error'
          className='StaticError__errorImage'
        />
        <div className='StaticError__errorText'>
          <Text textStyle='title-4'
            textAlign='center'
          >
            { errorText }
          </Text>
        </div>
        <div className='StaticError__errorSubText'>
          <Text textStyle='subtitle-2'
            textAlign='center'
          >
            { errorSubText }
          </Text>
        </div>
        <Link linkAction={ ctaLink }
          linkIcon='ArrowForward'
          linkStyle='title-5'
        />
      </div>
    </GridContainer>
  );
};

export const StagingWaitingRoom = () => {
  const { stagingWaitingRoomRetryInterval, stagingWaitingRoomRetryCount } = useAppConfigContext();
  const [isStaleContent, setIsStaleContent] = useState( false );

  useEffect( () => {
    if( window.location.href.includes( 'stale' ) ){
      setIsStaleContent( true );
    }

    setTimeout( () => {
      const url = new URL( global.location.href );
      const retries = parseInt( url.searchParams.get( 'stagingRetries' ), 10 ) || 0;

      if( retries >= stagingWaitingRoomRetryInterval ){
        setIsStaleContent( true );

        setTimeout( () => {
          url.pathname = '/';
          url.search = '?previewDate=';

          global.location.href = url.href;
        }, stagingWaitingRoomRetryCount );
      }
      else {
        url.searchParams.set( 'stagingRetries', retries + 1 );
        global.location.href = url.href;
      }
    }, stagingWaitingRoomRetryCount );
  }, [] );

  return (
    <div className='StaticError__stagingWaitingRoom'>
      { !isStaleContent && (
        <>
          <Text htmlTag='h1'
            textStyle='title-2'
            textAlign='center'
          >
            Please Wait
          </Text>

          <Spacer value='01' />

          <Text htmlTag='h2'
            textStyle='title-3'
            textAlign='center'
          >
            Staging is under heavy load
          </Text>

          <Spacer value='03' />

          <Text fontWeight='bold'
            htmlTag='p'
            textStyle='body-2'
            textAlign='center'
          >
            Waiting for staging to be ready...
          </Text>

          <Spacer value='01' />

          <Text fontWeight='bold'
            htmlTag='p'
            textStyle='body-2'
            textAlign='center'
          >
            This page will auto-refresh 5 times.
          </Text>

          <Spacer value='05' />
          <GhostCards />
        </>
      ) }

      { isStaleContent && (
        <>
          <Text htmlTag='h2'
            textStyle='title-3'
          >
            Staging is taking longer than expected
          </Text>

          <Spacer value='03' />

          <Text fontWeight='bold'
            htmlTag='p'
            textStyle='body-2'
            textAlign='center'
          >
            The content or preview may no longer exist
          </Text>

          <Spacer value='01' />

          <Text fontWeight='bold'
            htmlTag='p'
            textStyle='body-2'
            textAlign='center'
          >
            Please go back to the home page and add a previewDate
          </Text>

          <Spacer value='01' />

          <Text fontWeight='bold'
            htmlTag='p'
            textStyle='body-2'
            textAlign='center'
          >
            Redirecting to the home page in 5 seconds...
          </Text>
        </>
      ) }
    </div>
  );
};

/**
 * Property type definitions
 *
 * @type {StaticError}
 * @property {string} errorImageUrl - error image url path
 * @property {string} errorText - error text
 * @property {string} errorSubText - button text
 * @property {string} ctaLink -  action for redirection to home page
 */
export const propTypes = {
  errorImageUrl: PropTypes.string,
  errorText: PropTypes.string,
  errorSubText: PropTypes.string,
  ctaLink: PropTypes.shape( {
    label: PropTypes.string,
    url: PropTypes.string
  } )
};

/**
 * Default values for passed properties
 * @type {StaticError}
 * @property {string} errorImageUrl - error image url path
 * @property {string} errorText - error text
 * @property {string} errorSubText - button text
 * @property {string} ctaLink -  action for redirection to home page
 */
export const defaultProps = {
  errorImageUrl: '/ux/assets/static/media/ErrorPage.png',
  errorText: `We can't load this page right now. Please try again later.`,
  errorSubText: `For now, return to Ulta Beauty's home page and keep browsing.`,
  ctaLink: {
    label: 'Home',
    url: '/'
  }
};

StaticError.propTypes = propTypes;
StaticError.defaultProps = defaultProps;

export default StaticError;
