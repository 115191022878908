import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16Share( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <g
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
        stroke='currentColor'
      >
        <path d='M12 8.5h3.5v7H.5v-7h3.534M8 .5v11M13 5.5L7.94.5l-5.027 5' />
      </g>
    </SvgContainer>
  );
}

export default Svg16Share;
