import { isServer } from '@ulta/core/utils/device_detection/device_detection';

/**
 * @method isSafari
 * @summary Method to check the browser isSafari
 */
export const isSafari = () => {
  if( isServer() ){
    return false;
  }
  if( navigator.userAgent.includes( 'Safari' ) && !navigator.userAgent.includes( 'Chrome' ) ){
    return true;
  }
  return false;
};