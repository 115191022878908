import { useEffect, useState } from 'react';

import Cookies from 'js-cookie';

/**
 * Will retrieve the Retailer Visitor ID, and if not present listens and sets
 * the value
 *
 * @returns {object} Retailer Visitor ID response
 * @returns {object.retailerVisitorId} Retailer Visitor ID cookie value
 */
export const useRetailerVisitorId = () => {
  const [retailerVisitorId, setRetailerVisitorId] = useState( getRetailerIdFromCookie() );

  useEffect( () => {
    if( retailerVisitorId ){
      return;
    }

    const checkRetailerVisitorId = setInterval( () => {
      const ruid = getRetailerIdFromCookie();
      if( ruid ){
        clearInterval( checkRetailerVisitorId );
        setRetailerVisitorId( ruid );
      }
    }, 50 );

    return () => clearInterval( checkRetailerVisitorId );
  }, [] );

  return [retailerVisitorId];
};

/**
 * Retrieves the retailer visitor ID from a cookie
 * @returns {string} Cookie value for Retailer Visitor ID
 */
export const getRetailerIdFromCookie = () => {
  const cookie = Cookies.get( RETAILER_COOKIE_PARAM );

  if( !cookie && process.env.NODE_ENV === 'development' ){
    return `X_ULTA_VISITOR_ID_DEV`;
  }

  return cookie;
};

/**
 * @constant {string} Cookie key for Retailer Visitor ID
 */
export const RETAILER_COOKIE_PARAM = 'X_ULTA_VISITOR_ID';