import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24ArrowUp( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <g
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M12 1v22M1 11L12.036 1 23 11' />
      </g>
    </SvgContainer>
  );
}

export default Svg24ArrowUp;
