/**
 * Common Markdown component used by other modules as a reusable component which uses reactmarkdown component to render markdown markup.
 *
 * @module views/components/Markdown
 * @memberof -Common
 */

import './Markdown.scss';

import React from 'react';
import ReactMarkdown from 'react-markdown';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import gfm from 'remark-gfm';

/**
 * Represents an Markdown component
 * @method
 * @param {MarkdownProps} props - React properties passed from composition
 * @returns Markdown
 */

const Markdown = ( props ) => {
  const {
    bodyStyle,
    body,
    cmsMarkdownContent,
    alignment,
    role,
    bodyColor
  } = props;

  const cmsContent = !!body ? body : cmsMarkdownContent;

  return (
    <div className={
      classNames( 'Markdown', {
        [`Markdown--${bodyStyle}`]: bodyStyle,
        [`Markdown--${alignment}`]: alignment,
        [`Text-ds--${bodyColor}`]: bodyColor
      } )
    }
    data-test='markdown'
    { ...( role && { 'role':role } ) }
    >
      <ReactMarkdown plugins={ [gfm] }
        source={ cmsContent }
      />
    </div>
  );
};

/**
  * Property type definitions
  * @typedef MarkdownProps
  * @type {object}
  * @property {string} body - Sets the body message
  * @property {string} cmsMarkdownContent - Sets the body message - alt
  * @property {string} bodyStyle - Sets the Markdown body style
  * @property {string} alignment - Sets the Markdown grid alignment
  * @property {string} role - Sets the role of the element
  * @property {string} bodyColor - Sets the color of the element
  */

export const propTypes = {
  /** Sets the body for display */
  body: PropTypes.string,
  cmsMarkdownContent: PropTypes.string,
  /** Sets the Markdown body style for display */
  bodyStyle: PropTypes.string,
  alignment: PropTypes.string,
  role: PropTypes.string,
  bodyColor: PropTypes.string
};

/**
 * Default values for passed properties
 * @type object
 * @property {string} bodyStyle - Default bodyStyle is body-1
 * @property {string} alignment - Default alignment is center
 */
export const defaultProps = {
  bodyStyle: 'body-1',
  alignment: 'left'
};

Markdown.defaultProps = defaultProps;
Markdown.propTypes = propTypes;

export default Markdown;
