import { handleReplace } from '@ulta/core/utils/handleLocation/handleLocation';
import { resetIsPageEventViewTriggered } from '@ulta/core/utils/tealium/tealium';

/**
 * Apollo Middleware to get and set page configuration
 * @see {@link https://www.apollographql.com/docs/react/networking/advanced-http-networking/#customizing-response-logic|apollo}
 *
 * @param { method } operation
 * @param { method } forward
 * @returns ApolloLink
 */
export const pageMiddleware = function( data ){
  const { previewDate } = data || {};
  return function( operation, forward ){
    return forward( operation ).map( ( response ) => {
      const { isContentReplace } = operation.getContext();

      const { meta } = response?.data?.Page || {};
      const initAction = meta?.initAction || {};

      const content = isContentReplace ? initAction.content : response?.data?.Page?.content;

      // If this is a page request or we're replacing content we need to let the next
      // middleware know that this is a page request so it can append the header/footer
      if( content?.moduleName === 'Page' || isContentReplace ){
        content?.web && operation.setContext( { web: content?.web } );
        operation.setContext( { enableGlobalHFNForDSOTF: content?.enableGlobalHFNForDSOTF } );
        operation.setContext( { isPage: true } );
      }

      // When we're replacing content we need to override from initAction
      // This happens when we want to refresh just the main wrapper in SPA like fashion
      if( isContentReplace ){
        if( initAction.url ){
          handleReplace( { url: initAction.url, previewDate } );
          resetIsPageEventViewTriggered();
        }

        const patched = { ...response };
        patched.data.Page.content = content;
        patched.data.Page.meta.initAction = null;
        return patched;
      }

      return response;
    } );
  };
};
