import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function AfterpayLogoRegular( props ){
  return (
    <SvgContainer
      { ...props }
      width='114px'
      height='22px'
      viewBox='0 0 114 22'
    >
      <g id='------------------Payment-Method-Selector-'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='Documentation_Cart-and-Checkout_Payment-Method-Selector_Afterpay-List-Item'
          transform='translate(-944.000000, -317.000000)'
          fill='#000000'
        >
          <g id='1.0-Default-/-unselected'
            transform='translate(554.000000, 67.000000)'
          >
            <g id='Small'
              transform='translate(156.000000, 147.000000)'
            >
              <g id='Checkout-Sections/Payment/Subsections/Payment-Method-Selector/xParts/Payment-List-Item'
                transform='translate(25.000000, 78.000000)'
              >
                <g id='Group-6'
                  transform='translate(209.000000, 25.000000)'
                >
                  <path d='M112.240689,4.44032015 L108.767372,2.46676553 L105.24471,0.465145228 C102.909904,-0.861505458 99.9995602,0.807705743 99.9995602,3.4735471 L99.9995602,3.922797 C99.9995602,4.1686205 100.132314,4.39563402 100.347575,4.51794862 L101.98183,5.4466041 C102.435264,5.70417139 103.00045,5.38002276 103.00045,4.86240009 L103.00045,3.7983924 C103.00045,3.27161355 103.575584,2.94169254 104.037059,3.20383792 L107.256525,5.03328225 L110.466345,6.85705373 C110.929929,7.12049292 110.929929,7.7827235 110.466345,8.04616269 L107.256525,9.86993416 L104.037059,11.699279 C103.575584,11.9615239 103.00045,11.6316029 103.00045,11.1047245 L103.00045,10.5761542 C103.00045,7.91041238 100.090106,6.24110166 97.7552996,7.56775234 L94.2326385,9.56937264 L90.7593206,11.5429273 C88.4135598,12.8758479 88.4135598,16.2268103 90.7593206,17.559731 L94.2326385,19.5332856 L97.7551991,21.5349059 C100.090106,22.8615566 103.00045,21.1923454 103.00045,18.5265041 L103.00045,18.0761594 C103.00045,17.8303359 102.867696,17.6033224 102.652334,17.4810078 L101.01808,16.5523523 C100.564746,16.294785 99.9995602,16.6189336 99.9995602,17.1365563 L99.9995602,18.2016588 C99.9995602,18.7285371 99.4244257,19.0583586 98.9629514,18.7962132 L95.7434846,16.9668684 L92.5336653,15.1429974 C92.0700806,14.8795582 92.0700806,14.2173277 92.5336653,13.9538885 L95.7434846,12.130117 L98.9629514,10.3007722 C99.4244257,10.0385273 99.9995602,10.3684483 99.9995602,10.8952271 L99.9995602,11.4238969 C99.9995602,14.0896388 102.909904,15.7589495 105.24471,14.4322988 L108.767372,12.4306785 L112.240689,10.4571239 C114.58645,9.12420321 114.58645,5.77324083 112.240689,4.44032015'
                    id='Fill-1'
                  />
                  <polygon id='Fill-4'
                    points='89 5.00005116 81.0181916 22.0000512 77.7065337 22.0000512 80.6885617 15.6474805 76 5.00005116 79.3971175 5.00005116 82.409273 12.130106 85.7004521 5.00005116'
                  />
                  <path d='M9.93679305,17.7988904 L9.93679305,16.1644197 C9.01511646,17.2959356 7.64538807,18.0000512 6.00190266,18.0000512 C2.58979176,18.0000512 0,15.2340644 0,11.5125377 C0,7.81651324 2.68956844,5.00005116 6.07652555,5.00005116 C7.67033224,5.00005116 9.01511646,5.70437831 9.93679305,6.81060369 L9.93679305,5.2265025 L13,5.2265025 L13,17.7988904 L9.93679305,17.7988904 Z M9.91184888,11.5125377 C9.91184888,9.40061444 8.3927699,7.91693489 6.52489177,7.91693489 C4.65701363,7.91693489 3.13782984,9.42569339 3.13782984,11.5125377 C3.13782984,13.5744089 4.65701363,15.1083522 6.52489177,15.1083522 C8.3927699,15.1083522 9.91184888,13.6248843 9.91184888,11.5125377 L9.91184888,11.5125377 Z'
                    id='Fill-6'
                  />
                  <path d='M26.424047,15.322471 C25.4334581,15.322471 25.1571635,14.9366241 25.1571635,13.923535 L25.1571635,8.56847617 L26.97683,8.56847617 L26.97683,5.93931716 L25.1571635,5.93931716 L25.1571635,2.99668932 L22.2545192,2.99668932 L22.2545192,5.93931716 L18.5152354,5.93931716 L18.5152354,4.73914161 C18.5152354,3.72605254 18.8837251,3.33979964 19.8974839,3.33979964 L20.5349973,3.33979964 L20.5349973,1.00005116 L19.1372375,1.00005116 C16.741521,1.00005116 15.612591,1.82026756 15.612591,4.32883039 L15.612591,5.93931716 L14,5.93931716 L14,8.56847617 L15.612591,8.56847617 L15.612591,18.0000512 L18.5152354,18.0000512 L18.5152354,8.56847617 L22.2545192,8.56847617 L22.2545192,14.4781962 C22.2545192,16.9387439 23.1528159,18.0000512 25.5025802,18.0000512 L27,18.0000512 L27,15.322471 L26.424047,15.322471 Z'
                    id='Fill-8'
                  />
                  <path d='M30.2939146,12.3425771 C30.520366,14.2030759 31.852831,15.259355 33.5377772,15.259355 C34.8703481,15.259355 35.9013366,14.6304764 36.504713,13.6248843 L39.7483639,13.6248843 C38.9941963,16.2903435 36.6052405,18.0000512 33.4623287,18.0000512 C29.6654593,18.0000512 27,15.3348035 27,11.5379341 C27,7.74095891 29.8164621,5.00005116 33.5377772,5.00005116 C37.284277,5.00005116 40,7.76603787 40,11.5379341 C40,11.8144376 39.9746036,12.0911526 39.9243399,12.3425771 L30.2939146,12.3425771 Z M36.6052405,10.3810218 C36.3790007,8.74676265 35.046324,7.76603787 33.4873018,7.76603787 C31.928597,7.76603787 30.6460782,8.72157787 30.3190994,10.3810218 L36.6052405,10.3810218 Z'
                    id='Fill-10'
                  />
                  <path d='M49,22.0000512 L49,5.21474322 L52.0632624,5.21474322 L52.0632624,6.76413774 C52.9847145,5.66760303 54.3545256,5.00005116 55.9980893,5.00005116 C59.3601529,5.00005116 62,7.64628136 62,11.1506779 C62,14.6548738 59.3104749,17.3249809 55.9234676,17.3249809 C54.3545256,17.3249809 53.059441,16.72906 52.1628278,15.7278325 L52.1628278,22.0000512 L49,22.0000512 Z M58.8621159,11.1743542 C58.8621159,9.24353013 57.3428518,7.76536523 55.4752134,7.76536523 C53.6073654,7.76536523 52.0881013,9.19587652 52.0881013,11.1743542 C52.0881013,13.1291556 53.6073654,14.5834435 55.4752134,14.5834435 C57.3428518,14.5834435 58.8621159,13.1054792 58.8621159,11.1743542 L58.8621159,11.1743542 Z'
                    id='Fill-12'
                  />
                  <path d='M72.9367129,17.7988904 L72.9367129,16.1644197 C72.0152534,17.2959356 70.645536,18.0000512 69.0018543,18.0000512 C65.5899805,18.0000512 63,15.2340644 63,11.5125377 C63,7.81651324 65.6895468,5.00005116 69.0764766,5.00005116 C70.6702704,5.00005116 72.0152534,5.70437831 72.9367129,6.81060369 L72.9367129,5.2265025 L76,5.2265025 L76,17.7988904 L72.9367129,17.7988904 Z M72.911769,11.5125377 C72.911769,9.40061444 71.392807,7.91693489 69.5247344,7.91693489 C67.6570809,7.91693489 66.1378045,9.42569339 66.1378045,11.5125377 C66.1378045,13.5744089 67.6570809,15.1083522 69.5247344,15.1083522 C71.392807,15.1083522 72.911769,13.6248843 72.911769,11.5125377 L72.911769,11.5125377 Z'
                    id='Fill-14'
                  />
                  <path d='M44.5893627,6.4814815 C44.5893627,6.4814815 45.2481543,5.00005116 46.8628846,5.00005116 C47.5533046,5.00005116 48,5.28831903 48,5.28831903 L48,8.54568158 C48,8.54568158 47.025722,7.81566091 46.1311795,7.96291184 C45.2369027,8.10994781 44.6707814,9.10630994 44.6735278,10.4400594 L44.6735278,18.0000512 L42,18.0000512 L42,5.22984859 L44.5893627,5.22984859 L44.5893627,6.4814815 Z'
                    id='Fill-16'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}
export default AfterpayLogoRegular;