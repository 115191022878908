import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24Community( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <path fillRule='evenodd'
        clipRule='evenodd'
        d='M9.34835 3.09835C10.0516 2.39509 11.0054 2 12 2C12.9946 2 13.9484 2.39509 14.6517 3.09835C15.3549 3.80161 15.75 4.75544 15.75 5.75C15.75 6.74456 15.3549 7.69839 14.6517 8.40165C13.9484 9.10491 12.9946 9.5 12 9.5C11.0054 9.5 10.0516 9.10491 9.34835 8.40165C8.64509 7.69839 8.25 6.74456 8.25 5.75C8.25 4.75544 8.64509 3.80161 9.34835 3.09835ZM12 3.5C11.4033 3.5 10.831 3.73705 10.409 4.15901C9.98705 4.58097 9.75 5.15326 9.75 5.75C9.75 6.34674 9.98705 6.91903 10.409 7.34099C10.831 7.76295 11.4033 8 12 8C12.5967 8 13.169 7.76295 13.591 7.34099C14.0129 6.91903 14.25 6.34674 14.25 5.75C14.25 5.15326 14.0129 4.58097 13.591 4.15901C13.169 3.73705 12.5967 3.5 12 3.5Z'
        fill='black'
      />
      <path fillRule='evenodd'
        clipRule='evenodd'
        d='M3.05546 5.05546C3.57118 4.53973 4.27065 4.25 5 4.25C5.72935 4.25 6.42882 4.53973 6.94454 5.05546C7.46027 5.57118 7.75 6.27065 7.75 7C7.75 7.72935 7.46027 8.42882 6.94454 8.94454C6.42882 9.46027 5.72935 9.75 5 9.75C4.27065 9.75 3.57118 9.46027 3.05546 8.94454C2.53973 8.42882 2.25 7.72935 2.25 7C2.25 6.27065 2.53973 5.57118 3.05546 5.05546ZM5 5.75C4.66848 5.75 4.35054 5.8817 4.11612 6.11612C3.8817 6.35054 3.75 6.66848 3.75 7C3.75 7.33152 3.8817 7.64946 4.11612 7.88388C4.35054 8.1183 4.66848 8.25 5 8.25C5.33152 8.25 5.64946 8.1183 5.88388 7.88388C6.1183 7.64946 6.25 7.33152 6.25 7C6.25 6.66848 6.1183 6.35054 5.88388 6.11612C5.64946 5.8817 5.33152 5.75 5 5.75Z'
        fill='black'
      />
      <path fillRule='evenodd'
        clipRule='evenodd'
        d='M17.0555 5.05546C17.5712 4.53973 18.2707 4.25 19 4.25C19.7293 4.25 20.4288 4.53973 20.9445 5.05546C21.4603 5.57118 21.75 6.27065 21.75 7C21.75 7.72935 21.4603 8.42882 20.9445 8.94454C20.4288 9.46027 19.7293 9.75 19 9.75C18.2707 9.75 17.5712 9.46027 17.0555 8.94454C16.5397 8.42882 16.25 7.72935 16.25 7C16.25 6.27065 16.5397 5.57118 17.0555 5.05546ZM19 5.75C18.6685 5.75 18.3505 5.8817 18.1161 6.11612C17.8817 6.35054 17.75 6.66848 17.75 7C17.75 7.33152 17.8817 7.64946 18.1161 7.88388C18.3505 8.1183 18.6685 8.25 19 8.25C19.3315 8.25 19.6495 8.1183 19.8839 7.88388C20.1183 7.64946 20.25 7.33152 20.25 7C20.25 6.66848 20.1183 6.35054 19.8839 6.11612C19.6495 5.8817 19.3315 5.75 19 5.75Z'
        fill='black'
      />
      <path fillRule='evenodd'
        clipRule='evenodd'
        d='M5 11.75C4.40028 11.75 3.82697 11.9854 3.40571 12.4014C2.98476 12.8172 2.75 13.3791 2.75 13.963V17.9673C2.75 18.3617 2.94712 18.73 3.27529 18.9488C4.31969 19.6451 5.68031 19.6451 6.72471 18.9488L7.25 18.5986V13.963C7.25 13.3791 7.01524 12.8172 6.5943 12.4014C6.17303 11.9854 5.59972 11.75 5 11.75ZM2.35165 11.3342C3.05561 10.639 4.00842 10.25 5 10.25C5.99158 10.25 6.94439 10.639 7.64835 11.3342C8.35262 12.0298 8.41603 12.9752 8.41603 13.963V18.9488C8.41603 19.1996 8.20865 19.8609 8 20L7.55676 20.1969C6.00851 21.229 3.99149 21.229 2.44324 20.1969C1.69777 19.6999 1.25 18.8632 1.25 17.9673V13.963C1.25 12.9752 1.64738 12.0298 2.35165 11.3342Z'
        fill='black'
      />
      <path fillRule='evenodd'
        clipRule='evenodd'
        d='M19 11.539C18.3603 11.539 17.7487 11.7805 17.2994 12.2074C16.8504 12.634 16.6 13.2105 16.6 13.8096V18.4286C16.6 18.5144 16.6445 18.5944 16.7187 18.642L17.1603 18.9252C18.2743 19.6396 19.7256 19.6396 20.8397 18.9252C21.1897 18.7007 21.4 18.3228 21.4 17.9181V13.8096C21.4 13.2105 21.1496 12.634 20.7006 12.2074C20.2512 11.7805 19.6397 11.539 19 11.539ZM16.1751 11.1124C16.926 10.3991 17.9423 10 19 10C20.0577 10 21.074 10.3991 21.8249 11.1124C22.5761 11.8261 23 12.7961 23 13.8096V17.9181C23 18.8374 22.5224 19.6958 21.7272 20.2057C20.0757 21.2648 17.9242 21.2648 16.2728 20.2057L15.8312 19.9226C15.3119 19.5896 15.5 19.1003 15.5 18.5L15.5 14C15.5 12.9865 15.4239 11.8261 16.1751 11.1124Z'
        fill='black'
      />
      <path fillRule='evenodd'
        clipRule='evenodd'
        d='M10.0246 10.3633C10.6506 10.1228 11.323 10 12 10C12.677 10 13.3494 10.1228 13.9754 10.3633C14.6012 10.6038 15.172 10.9589 15.6473 11.4129C16.1229 11.8672 16.4941 12.4126 16.7269 13.0189C16.9541 13.6108 17.042 14.242 16.9814 14.8713V19.5833C16.9814 20.5698 16.4772 21.4815 15.6584 21.9756C13.3953 23.3415 10.6047 23.3415 8.34156 21.9756C7.5228 21.4815 7.01863 20.5698 7.01863 19.5833V14.8713C6.95803 14.242 7.04588 13.6108 7.27312 13.0189C7.50587 12.4126 7.87709 11.8672 8.35269 11.4129C8.82797 10.9589 9.39878 10.6038 10.0246 10.3633ZM12 11.6465C11.5044 11.6465 11.0172 11.7367 10.5706 11.9083C10.124 12.0799 9.73319 12.3271 9.41924 12.627C9.10562 12.9266 8.87742 13.2702 8.73896 13.6308C8.60087 13.9905 8.55246 14.3656 8.59257 14.735C8.59591 14.7657 8.59758 14.7967 8.59758 14.8276V19.5833C8.59758 19.9821 8.80142 20.3508 9.13247 20.5505C10.9063 21.6211 13.0937 21.6211 14.8675 20.5505C15.1986 20.3508 15.4024 19.9821 15.4024 19.5833V14.8276C15.4024 14.7967 15.4041 14.7657 15.4074 14.735C15.4475 14.3656 15.3991 13.9905 15.261 13.6308C15.1226 13.2702 14.8944 12.9266 14.5808 12.627C14.2668 12.3271 13.876 12.0799 13.4294 11.9083C12.9828 11.7367 12.4956 11.6465 12 11.6465Z'
        fill='black'
      />
    </SvgContainer>
  );
}

export default Svg24Community;