/**
 * Accordion component will expand and collapse on click which shows and hides details under a heading.
 *
 * @module views/components/Accordion
 * @memberof -Common
 */
import './Accordion.scss';

import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Text from '@ulta/core/components/Text/Text';
import constants from '@ulta/core/utils/constants/constants';
import datacapture from '@ulta/core/utils/datacapture/datacapture';
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';

import Tag from '../Tag/Tag';
import * as utils from './Accordion';
/**
  * Represents an Accordion component
  * @method
  * @param {AccordionProps} props - React properties passed from composition
  * @returns Accordion
  */
const Accordion = ( props ) => {
  const {
    children,
    collapseAction,
    disabled,
    expandAction,
    hasBackground,
    label,
    labelElement,
    labelTag,
    messageType,
    open,
    showBorder,
    showTag,
    subheadline,
    textStyle
  } = props;

  const [isOpen, setIsOpen] = useState( false );
  let isClicked = useRef();

  const LabelTag = labelTag;
  let customId = label ? label.replace( /\W+/g, '_' ) : false;
  const derivedTextStyle = hasBackground ? 'title-6' : ( textStyle || 'body-2' );

  useEffect( () => {
    utils.composeOnClick( { isOpen, isClicked, expandAction, collapseAction } );
  }, [isOpen] );

  if( !labelElement && !label ){
    return null;
  }

  const accordionLabel = labelElement ? labelElement : (
    <>
      { showTag &&
        <Tag messageText={ label }
          messageType={ messageType }
        />
      }

      { !showTag &&
        <Text
          textStyle={ derivedTextStyle }
          htmlTag='span'
        >
          { label }
        </Text>
      }
    </>
  );

  return (
    <details
      className={ classNames( 'Accordion_Huge', {
        'Accordion_Huge--background': hasBackground,
        'Accordion_Huge--noBorder': !showBorder
      } ) }
      { ...( disabled && { disabled } ) }
      { ...( open && { open } ) }
      { ...( customId && { 'aria-controls': customId } ) }
    >
      { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
        <summary
          onClick={ () => {
            setIsOpen( !isOpen );
          } }
          className={ classNames( 'Accordion_Huge__summary', {
            'Accordion_Huge__NoBorder': !showBorder
          } ) }
          { ...( customId && { id: customId } ) }
        >
          <LabelTag className='Accordion_Huge__summary__header'
            id={ props.id }
          >
            { accordionLabel }
          </LabelTag>
          { subheadline && (
            <div className='Accordion_Huge__summary__subHeadline'>
              <Text htmlTag='p'
                textStyle='body-3'
                textAlign='left'
              >
                { subheadline }
              </Text>
            </div>
          ) }
        </summary> }
      { children && (
        <div className='Accordion_Huge__content'>{ children }</div>
      ) }
    </details>
  );
};

/**
 * Method to handle composeOnClick
 * @param {object} data args
 * @param {object} data.isClicked - click Ref
 * @param {object} data.isOpen- value to set open state in accordion
 * @param {function} data.expandAction action on expand
 * @param {function} data.collapseAction action on collapse
 */
export const composeOnClick = ( data ) => {
  const { isOpen = {}, isClicked, expandAction, collapseAction } = handleEmptyObjects( data );

  if( !expandAction && !collapseAction ){
    return;
  }
  if( isOpen ){
    isClicked.current = isOpen;
    datacapture.processEvents( { dataCapture: { ...expandAction.dataCaptureData } }, constants.DATACAPTURE_EVENT_TYPE.click );
  }
  else if( isClicked.current ){
    isClicked.current = false;
    datacapture.processEvents( { dataCapture: { ...collapseAction.dataCaptureData } }, constants.DATACAPTURE_EVENT_TYPE.click );
  }
};

/**
  * Property type definitions
  * @typedef AccordionProps
  * @type {object}
  * @property {node} children - Nodes to be rendered Component content (JSX or string literals)
  * @property {string} label - Text to render as header for Accordion
  * @property {Element} labelElement - Element to render as header for Accordion
  * @property {boolean} hasBackground - Flag to render an accordion with a background color
  * @property {string} labelTag - Html tag for label
  * @property {boolean} showBorder - sets the border for Accordion
  * @property {string} textStyle - style for label
  * @property {string} subheadline -Subheadline for label
  * @property {object} collapseAction - action to perform on click to collapse accordion
  * @property {object} expandAction - action to perform on click to expand accordion
  */
Accordion.propTypes = {
  /** Component content (JSX or string literals) */
  children: PropTypes.node,
  collapseAction: PropTypes.object,
  expandAction: PropTypes.object,
  /** Text to render as label for the accordion */
  label: PropTypes.string,
  /** Element to render as label for the accordion */
  labelElement: PropTypes.element,
  /** Flag to render a background for the accordion when it's open */
  hasBackground: PropTypes.bool,
  /** html tag for header for the accordion's heading section */
  labelTag: PropTypes.oneOf( ['p', 'h2', 'h3', 'h4', 'h5', 'h6'] ),
  showBorder: PropTypes.bool
};

/**
  * Default values for passed properties
  * @type {object}
  * @property {boolean} hasBackground=false - Set the default boolean value for hasBackground
  * @property {object} children=null - Set the default object value for children
  * @property {string} labelTag='p' - Sets the default value of labelTag
  * @property {boolean} showBorder=true - Set the default boolean value for border
  */
Accordion.defaultProps = {
  hasBackground: false,
  children: null,
  labelTag: 'p',
  showBorder: true
};

export default Accordion;