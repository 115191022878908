import ReactDOM from 'react-dom';

export const renderComponent = function(){
  // if __APOLLO_STATE__ is present it indicates that the application is server side rendered.
  // for Server Side Rendered App we will use 'hydrate' method, and for Client Side Rendered App it will be 'render' method
  const is_ssr_rendered = !!global.__APOLLO_STATE__;

  if( is_ssr_rendered ){
    // eslint-disable-next-line react/no-deprecated
    return ReactDOM.hydrate;
  }
  else {
    // eslint-disable-next-line react/no-deprecated
    return ReactDOM.render;
  }
};

export const getRenderer = function(){
  return renderComponent();
};