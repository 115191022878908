import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16Clean( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <g id='Clean' >
        <path d='M9.1 3.4 9 4s-.6 1.6 0 2.9L9.6 8c.5 1 1 1.7 1 3a4 4 0 0 1-1.3 3 3.4 3.4 0 0 1-2.7.9c-.9 0-1.7-.5-2.3-1.3-.7-1-1-2.1-.8-3.3a9.6 9.6 0 0 1 5-6.7zm-1 1.1a9 9 0 0 0-4 6c-.2 1 0 2 .7 2.9.5.6 1.1 1 1.8 1a3 3 0 0 0 2.3-.7c.7-.6 1-1.5 1-2.6 0-1.2-.3-1.8-.8-2.7L8.4 7c-.4-1-.3-2-.2-2.6Zm.2 4.2s1.6 2.6.2 4.2l-.6-.4c1.1-1.3.4-3.8.4-3.8zM13 6.5l.3 1.2 1.2.3-1.2.2-.3 1.3-.2-1.3-1.3-.2 1.3-.3zM3 3.2l.3 1.2 1.2.3-1.2.3L3 6.2 2.8 5l-1.3-.3 1.3-.3ZM11.4 1l.3 1.2 1.2.3-1.2.2-.3 1.3-.2-1.3-1.3-.2 1.3-.3Z'/>
      </g>
    </SvgContainer>
  );
}

export default Svg16Clean;
