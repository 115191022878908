import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12CaretBack( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <path
        d='M9 11.063L3.375 6 9 .937'
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg12CaretBack;
