/**
 * This method takes the tokenizedString, values as a parameter,
 * return the updated tokenized string
 * @param {String} tokenizedString - gets slide %x of %totalLength from CMS
 * @param {Array} values - [array index count starts with 1, total length of array]
 */
const interpolateString = ( tokenizedString, values = [] ) => {
  if( !tokenizedString ){
    return null;
  }
  if( !values || tokenizedString && !tokenizedString.includes( '%' ) ){
    return tokenizedString;
  }
  const tokenizedValue = values.reduce( ( accumulator, val, index ) => {
    const token = `%${ index + 1 }`;
    return accumulator.replace( token, val );
  }, tokenizedString );
  return tokenizedValue;
};

export default interpolateString;