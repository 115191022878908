import { useCallback, useEffect, useRef } from 'react';

import { isFunction, isSafeNumber } from '../../utils/types/types';

/**
 * This hook is used primarily for ADA keyboard users. The goals
 * is to listen for consecutive keypresses (usually tabs on the document)
 * and fire a callback so we can display keyboard controls.
 *
 * This can be used for other things, as it accepts an array of keycodes
 * and can listen to any dom element
 *
 * @param {object} data data
 * @param {array} data.keyCodes array of keycodes to listen for
 * @param {number} data.threshold number of hits before firing onThreshold callback
 * @param {boolean} data.runOnce if this is true we will remove listeners after threshold is hit
 * @param {object} data.target dom element to listen to, defaults to document
 * @param {array} data.forPreventDefault array of keycodes to fire e.preventDefault() on
 * @param {boolean} data.useCapture sets the useCapture flag on the addEventListener
 * @param {object} methods methods
 * @param {function} methods.onThreshold onThreshold callback
 */
export const useKeyPressCounter = ( data, methods ) => {
  const {
    keyCodes,
    threshold,
    runOnce = true,
    target = global.document,
    forPreventDefault = []
  } = data || {};

  const { onThreshold } = methods || {};

  const hits = useRef( 0 );

  const cleanup = () => {
    target.removeEventListener( 'keydown', onKeyPress );
  };

  const derivedData = {
    keyCodes,
    threshold,
    runOnce,
    target,
    forPreventDefault,
    hits
  };

  const derivedMethods = { onThreshold, cleanup };

  const onKeyPress = useCallback( composeKeyPressFn( derivedData, derivedMethods ), [] );

  useEffect( () => {
    if( !hasValidArgs( derivedData, derivedMethods ) ){
      return;
    }

    target.addEventListener( 'keydown', onKeyPress );

    return () => {
      cleanup();
    };
  }, [] );
};

/**
 * Validates arguments passed to the hook are valid
 * @param {object} data data
 * @param {array} data.keyCodes array of keycodes to listen for
 * @param {number} data.threshold number of hits before firing onThreshold callback
 * @param {object} data.target dom element to listen to, defaults to document
 * @param {object} methods methods
 * @param {function} methods.onThreshold onThreshold callback
 * @returns {boolean} if arguments are valid for useEffect setups
 */
export const hasValidArgs = ( data, methods ) => {
  const { keyCodes, threshold, target } = data || {};

  const { onThreshold } = methods || {};

  if(
    !Array.isArray( keyCodes ) ||
    keyCodes.length === 0 ||
    !isSafeNumber( threshold ) ||
    threshold < 1 ||
    !isFunction( onThreshold ) ||
    typeof target?.addEventListener !== 'function'
  ){
    return false;
  }

  return true;
};

/**
 * Handles key presses
 *
 * @param {object} data data
 * @param {array} data.keyCodes array of keycodes to listen for
 * @param {number} data.threshold number of hits before firing onThreshold callback
 * @param {array} data.forPreventDefault array of keycodes to fire e.preventDefault() on
 * @param {object} data.hits ref for number of current keycode hits
 * @param {boolean} data.runOnce if this is true we will remove listeners after threshold is hit
 * @param {object} methods methods
 * @param {function} methods.onThreshold onThreshold callback
 * @param {function} methods.setHits react state setter for hits
 * @param {function} methods.cleanup removes keypress listener
 */
export const composeKeyPressFn = ( data, methods ) => ( event ) => {
  const { keyCodes = [], threshold, forPreventDefault = [], hits, runOnce } = data || {};
  const { onThreshold, cleanup } = methods || {};

  if( !hasValidArgs( data, methods ) ){
    return;
  }

  const isKeyCode = keyCodes.includes( event?.keyCode );

  if( Array.isArray( forPreventDefault ) && forPreventDefault.includes( event?.keyCode ) ){
    event.preventDefault();
  }

  if( !isKeyCode ){
    hits.current = 0;
    return;
  }

  const thresholdHit = isKeyCode && hits.current + 1 === threshold;

  if( isKeyCode && !thresholdHit ){
    hits.current = hits.current + 1;
    return;
  }

  onThreshold();
  hits.current = 0;

  if( runOnce ){
    cleanup();
  }

};
