import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16Available( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
      viewBox='0 0 16 16'
    >
      <g id='Modal'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='PDP_StoreAvailability_Search'
          transform='translate(-24.000000, -680.000000)'
          fillRule='nonzero'
        >
          <g id='Validation'
            transform='translate(24.000000, 680.000000)'
          >
            <circle id='Background'
              fill='#B3FFC4'
              cx='8'
              cy='8'
              r='8'
            />
            <g id='Check'
              transform='translate(4.000000, 4.000000)'
              fill='#000000'
            >
              <path d='M7.25313812,1.24896143 C7.33609582,1.15166362 7.48370254,1.13879941 7.58282695,1.22022841 C7.67294006,1.29425477 7.69196333,1.42072551 7.63235002,1.51620968 L7.61209939,1.5438419 L3.17235398,6.75104378 C3.08850519,6.8493867 2.94110941,6.85993788 2.84383326,6.78079008 L2.81863144,6.75697894 L0.393135882,4.10211569 C0.306838746,4.00765784 0.314891854,3.86241611 0.411123002,3.77770922 C0.498605864,3.70070295 0.628846794,3.7002352 0.71664028,3.77175415 L0.741619697,3.79536488 L2.98643906,6.25288318 L7.25313812,1.24896143 Z' />
            </g>
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}

export default Svg16Available;