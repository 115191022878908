import { createContext, useContext } from 'react';

/**
 * SearchHeaderContext
 * @constant {object}
 */

export const SearchHeaderContext = createContext();


export const SearchHeaderProvider = ( props ) => {
  const { children, context } = props;
  return (
    <SearchHeaderContext.Provider value={ context }>
      { children }
    </SearchHeaderContext.Provider>
  );
};

/**
 *
 * SearchHeaderContext provider
 * @type object
 */

export const useSearchHeaderContext = () => useContext( SearchHeaderContext );

export default SearchHeaderProvider;