import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16StarFilled( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <path
        d='M7.998 1a.51.51 0 01.454.277l2.082 4.125 4.007.397a.5.5 0 01.31.855l-3.297 3.268 1.223 4.44a.506.506 0 01-.435.634l-.071.002a.505.505 0 01-.21-.053l-4.063-2.012-4.056 2.01a.505.505 0 01-.139.045l-.071.008a.506.506 0 01-.506-.636l1.222-4.44-3.3-3.269a.5.5 0 01.311-.855L5.466 5.4l2.079-4.122A.51.51 0 017.998 1z'
        fill='currentColor'
        fillRule='nonzero'
      />
    </SvgContainer>
  );
}

export default Svg16StarFilled;
