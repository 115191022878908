import './AppContainer.scss';

import React from 'react';

import whatInput from 'what-input'; // eslint-disable-line

import { Router } from '@ulta/core/components/Router/Router';
import ContextContainer from '@ulta/core/containers/ContextContainer/ContextContainer';
import { AppConfigProvider } from '@ulta/core/providers/AppConfigProvider/AppConfigProvider';
import { isServer } from '@ulta/core/utils/device_detection/device_detection';
import { getApplicationLocale } from '@ulta/core/utils/locale/locale';

function AppContainer( props ){
  const { appConfig, client, baseInflection, context, children, locale } = props;
  const location = props.isServer ? props.location : global.location.href;

  return (
    <AppConfigProvider context={ appConfig }>
      <div className={ APP_CONTAINER_CLASS }>
        <ContextContainer
          baseInflection={ baseInflection }
          client={ client }
          location={ location }
          locale={ getApplicationLocale( locale, isServer() ) }
          context={ context }
        >
          <Router
            isServer={ props.isServer }
            { ...( location && { location: location } ) }
          >
            { children }
          </Router>
        </ContextContainer>
      </div>
    </AppConfigProvider>
  );
}

/**
 * @const {string} APP_CONTAINER_CLASS - class name for the AppContainer
 */
export const APP_CONTAINER_CLASS = 'AppContainer';

export default AppContainer;
