import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24SearchIcon( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <g id='Base/Icons/UI/search'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path d='M4.90649996,1.31469274 C7.94265275,-0.438230912 11.6833473,-0.438230912 14.7195,1.31469274 C17.7556528,3.06761637 19.626,6.30715284 19.626,9.81300014 C19.626,12.2526311 18.7357289,14.4843281 17.2623423,16.2009357 L23.7803678,22.7207078 C24.0732402,23.0136218 24.0732064,23.4884956 23.7802924,23.7813679 C23.5140069,24.0476156 23.0973415,24.071792 22.8037455,23.8539166 L22.7196322,23.7812925 L16.2009356,17.2623424 C14.484328,18.7357291 12.252631,19.6260001 9.813,19.6260001 C4.39342975,19.6260001 1.77635684e-15,15.2325704 1.77635684e-15,9.81300014 C1.77635684e-15,6.30715284 1.8703472,3.06761637 4.90649996,1.31469274 Z M13.9695,2.61373084 C11.3974489,1.12875639 8.22855113,1.12875639 5.65649996,2.61373084 C3.08444882,4.09870528 1.5,6.84305122 1.5,9.81300014 C1.5,14.4041433 5.22185687,18.1260001 9.813,18.1260001 C11.9989761,18.1260001 13.9878861,17.2822598 15.4718296,15.9026794 L15.7010029,15.6813382 C17.1995857,14.1777651 18.126,12.1035705 18.126,9.81300014 C18.126,6.84305122 16.5415512,4.09870528 13.9695,2.61373084 Z'
          id='-Icon-Color'
          fill='#000000'
          fillRule='nonzero'
        />
      </g>
    </SvgContainer>
  );
}

export default Svg24SearchIcon;
