
import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function SVG( props ){

  return (
    <SvgContainer
      { ...props }
      width='100%'
      height='8px'
      viewBox='0 0 289 8'
      preserveAspectRatio='none'
    >
      <defs>
        <rect id='path-1'
          x='0'
          y='0'
          width='1000'
          height='4'
        ></rect>
      </defs>
      <g id='ColorBarThick-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='ColorBarThick-2'
          transform='translate(-14.000000, -1354.000000)'
        >
          <g id='ColorBarThick-3'
            transform='translate(0.000000, 375.000000)'
          >
            <g id='ColorBarThick-4'
              transform='translate(-50.000000, 923.000000)'
            >
              <g id='ColorBarThick-5'
                transform='translate(65.000000, 56.000000)'
              >
                <g id='ColorBarThick-6'
                  transform='translate(-0.125000, 0.000000)'
                >
                  <image x='0'
                    y='0'
                    width='287.35905'
                    height='7.96447076'
                    xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAwQAAAAQCAMAAACfikjAAAAABGdBTUEAALGPC/xhBQAAAiVQTFRF70Jt7mQx7mYy8nQ37Vsu4x4Y7Dtk7Txn8muM9Y+p+MbT+c3Y97298Gw083s59H069px39rTE6TRc6zlj8GiK84N17mcy7VYs7Dlf8nSU97ax83o59pt1+LvK6zpk8W2O9IN672gy7FEq6zda83+c+MPR9q+k9Hk59phv+MPQ7Dpk8nST9IWA72kz60so6zVU9Imk+MLP9aeY83g49pZr+cvW7Dpl83uZ9IeH8Goz6kcm6jNP7EBq9aCM8ng49ZRn6Ddg7Dtl9IGd9IiM8Gs06UIk6jFK7Uly+MHO9JmB8nc49ZFh6j5k7Ttm9Iei9IqT6Dwj6S9F71R6+L7N9JJ08nY49ZBf60Vr9Yym9IyZ8G416Dch6C1A8F2B84tp8nY39YxX605y9Yyf8G815zIg5ys78GeI8oVd8nU39YtX7VZ36TVd7kZu9Y6k8HA15i0d5yo28nGQ8n1Q8nM39YdN7Vx86TVe7k108XM48XE25Cgb5icx97rJ8XZF7WOD6TZe71J38XY88XI24yMZ5SUs9IWg8G869IJF722K6jZf71h98ndF8XM34h4Y5SMn7D1m9Y2n97jI+crS9IJD73OP8F+C83hJ8XA25CEi7kVu97bH+MPG9H488HmU6jdg8WOH83pR7WUx8Ws04h8d709197u68YCb6jdh8WqL8ntW72Yy8GYy4h0Y71h+97bG97St8W81+crT8oqi6jhh8nCQ8n1bAAAAIvpbegAAAx5JREFUaAXlmNVzFEEYxC9AWGA5DoJLcHd3CO7u7i7BJTjB3Qnu7vr/sXtPV5uaj+mu6uOBeZ2urpru/tVeXSqVewpyTpWqeTjVCtlTPYBOjZp/ObVC3amdlpw6GeLU1T0zcq5XlDz1oZogcQOr0obAenMRSKVyGChoBLjQ0sYsA4VNoLyCplZg8Z0SgmYSBtLNCQYyLaQQFCcZKGqJ9YSoW1mdtgY26YagDeBCS9vSELRD4gqC9lZg8V0H4Tg6aiDoxEDQWfjOMOxSCYKuWE+IupvVaXdgk24IegAutLQnDUEvJK4g6G0FFt/1EY6jrwaCfgwE/YXvDMMBlSAYiPWEqAdZnQ4GNumGYAjgQkuH0hAMQ+IKhlt5Ze+UEIzQQDCSgaBECkE4KknBaKgnSDzGKnUssEk3BOMAF1o6noZgApTXRCuv7N0k4TgmayCYwkAwVfjOyHpaEoLpUE+QeIZV6kxgk24I0oALLZ1FQzAbymuOlVf2bq5wHPM0EMxnIFggfGdkvTAJAVQTJl5klboY2KQbgiWACy1dSkOwDApsuZWXHIIVGghWMhCs0kKwOn8Q2D9x1wCbdEOwFnChpetoCNZDEGz4pxBs1ECwiYFgsxaCLQkItkI1QeJtZqfbgU26ISgFXGjpDhqCnVBgu8zA4sti4Th2ayDYw0CwV/jOyHpfAoL9UE2Q+IDd6UH/UbohKPM34ZWHaAgOQ4GZ/6ZlwzwiHMfR/weCYwkIjkM1QeITNgQn/VfphqDc34RXnqIhOA0FdsYOLLpVQnBWA0E58yU4J4Q9sj6fgOACVBMkvmh3esl/lW4ILvub8MorNARXkcCuXbcDi25v6MZxU8NAuoyB4JbunbHz7fxBcMfu9K7/Kt0Q+HvwygqagcJ7CAT37bzi2we6cTwUQVDBQPBI987Y+XECgidITZj2qV3qM/9ZOiF47u/BK1/wELxEEntl5yWG4LUIglIGgowWgjcJCN4iNWHad3ap7/1n6YTgg78Hr/zIQ/AJSeyznVd8+0U3jq8iCL5REHzXPTRy/pGA4CdSE6b9ZZf623+WfwDQ7tjWQtGSFAAAAABJRU5ErkJggg=='
                  ></image>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgContainer>
  );

}

export default SVG;

