import React from 'react';

import { loadableReady } from '@loadable/component';
import parse from 'html-react-parser';

import AppContainer from '@ulta/core/containers/AppContainer/AppContainer';

import { getApolloClient } from '../apollo_client/apollo_client';
import { isMobileDisplay, isServer } from '../device_detection/device_detection';
import { devLogger } from '../devMode/devMode';
import { handleEmptyObjects } from '../handleEmptyObjects/handleEmptyObjects';

const server = isServer();


/**
 * Parses a string into valid HTML
 *
 * @method
 * @param { String } string - String to parse
 * @returns { String } - String as HTML
 */
export const HTMLparser = ( string = '' ) => {
  const content = parse( string ?? '' );
  return content && !!content.length ? content : null;
};

export const renderApplication = function( config ){
  const baseInflection = isMobileDisplay() ? 'Mobile' : 'Desktop';

  // Keep this alpha sorted for easier diffs
  const {
    amityIframeUrl,
    amityMessageOrigin,
    allowRootQuerySkip,
    applePaySessionValidationEndPoint,
    enableGetForQueries,
    enablePersistentSearch,
    findationApiKey,
    findationTestingMode,
    googleMapsApiKey,
    graphqlDomain,
    graphqlGetUri,
    graphqlServerUri,
    graphqlURI,
    isATG,
    isBot,
    isDXLSessionManagement,
    isNative,
    isStaging,
    isThirdParty,
    locationPath,
    ModuleRoutes,
    paypalEnvironment,
    powerReviewsSdk,
    previewDate,
    recentlyViewedLimit,
    renderer,
    rewardsPointsLimit,
    roktAccountId,
    salsifyClientId,
    serverRouteUtility,
    stagingHost,
    stagingWaitingRoomRetryCount,
    stagingWaitingRoomRetryInterval,
    targetElementId,
    tealiumEnvironment = 'qa',
    typeaheadMinInputLength
  } = handleEmptyObjects( config );

  // Keep this alpha sorted for easier diffs
  const appConfig = {
    amityIframeUrl,
    amityMessageOrigin,
    allowRootQuerySkip,
    applePaySessionValidationEndPoint,
    enableGetForQueries,
    enablePersistentSearch,
    findationApiKey,
    findationTestingMode,
    googleMapsApiKey,
    graphqlDomain,
    graphqlGetUri,
    graphqlServerUri,
    graphqlURI,
    isATG,
    isBot,
    isDXLSessionManagement,
    isNative,
    isStaging,
    isThirdParty,
    paypalEnvironment,
    powerReviewsSdk,
    previewDate,
    recentlyViewedLimit,
    rewardsPointsLimit,
    roktAccountId,
    salsifyClientId,
    serverRouteUtility,
    stagingHost,
    stagingWaitingRoomRetryCount,
    stagingWaitingRoomRetryInterval,
    tealiumEnvironment,
    typeaheadMinInputLength
  };

  devLogger( { appConfig } );

  const origin = global.location.origin;
  const composeGraphqlURI = enableGetForQueries ? graphqlGetUri : graphqlURI;

  renderer(
    <AppContainer
      client={ getApolloClient( { graphqlDomain, graphqlGetUri: composeGraphqlURI, stagingHost, previewDate, isStaging, origin, isNative } ) }
      isServer={ server }
      baseInflection={ baseInflection }
      appConfig={ appConfig }
    >
      <ModuleRoutes { ...( locationPath && { location: { pathname: locationPath } } ) } />
    </AppContainer>,
    document.getElementById( targetElementId )
  );
};

export const renderContainer = function( config ){
  return loadableReady( () => {
    renderApplication( config );
  } );
};