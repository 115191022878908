import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24ConsciousBeauty( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <g id='ConsciousBeauty'>
        <path style={ { stroke: 'none', fillRule: 'evenodd', fill: 'rgb(29.019608%,58.039216%,38.431373%)', fillOpacity: 1 } }
          d='M 8.738281 17.769531 C 11.066406 13.726562 14.921875 14.710938 15.402344 14.757812 C 15.40625 14.757812 15.414062 14.761719 15.421875 14.761719 C 11.957031 15.449219 10.34375 19.160156 10.78125 18.558594 C 12.550781 16.144531 14.953125 15.03125 17.757812 14.738281 C 17.765625 14.738281 17.765625 14.738281 17.769531 14.738281 C 18.257812 14.703125 18.714844 14.726562 19.1875 14.675781 C 19.109375 14.8125 19.03125 14.945312 18.957031 15.085938 C 17.375 14.789062 16.277344 15.402344 15.605469 17.027344 C 14.933594 18.660156 13.894531 20 12.46875 20.988281 C 10.4375 22.394531 8.175781 22.449219 5.800781 21.867188 C 7.445312 20.945312 7.90625 19.21875 8.738281 17.769531 '
        />
        <path style={ { stroke: 'none', fillRule: 'evenodd', fill: 'rgb(64.705882%,83.529412%,61.960784%)', fillOpacity: 1 } }
          d='M 13.605469 9.515625 C 11.925781 5.839844 8.464844 6.683594 6.066406 6.21875 C 6.0625 6.21875 6.058594 6.21875 6.054688 6.21875 C 5.636719 6.152344 5.238281 6.140625 4.835938 6.058594 C 4.894531 6.183594 4.953125 6.304688 5 6.433594 C 6.394531 6.292969 7.289062 6.902344 7.742188 8.363281 C 8.1875 9.824219 8.976562 11.0625 10.128906 12.023438 C 11.765625 13.390625 13.714844 13.605469 15.8125 13.277344 C 14.46875 12.359375 14.207031 10.828125 13.605469 9.515625 '
        />
        <path style={ { stroke: 'none', fillRule: 'evenodd', fill: 'rgb(98.039216%,78.431373%,81.176471%)', fillOpacity: 1 } }
          d='M 18.808594 10.015625 C 19.6875 6.597656 18.109375 4.777344 17.253906 2.808594 C 17.253906 2.804688 17.25 2.800781 17.25 2.800781 C 17.09375 2.460938 16.914062 2.171875 16.773438 1.835938 C 16.722656 1.945312 16.675781 2.054688 16.621094 2.160156 C 17.367188 3.082031 17.191406 4.003906 16.734375 5.179688 C 16.261719 6.402344 15.859375 7.472656 15.8125 8.824219 C 15.75 10.746094 16.664062 12.175781 17.542969 13.65625 C 17.472656 12.1875 18.414062 11.546875 18.808594 10.015625 '
        />
      </g>
    </SvgContainer>
  );
}

export default Svg24ConsciousBeauty;
