import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16BagCount( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <path
        d='M10.238 4a2.368 2.368 0 00.262-1.08C10.5 1.583 9.38.5 8 .5S5.5 1.583 5.5 2.92c0 .33.069.646.193.934M2.667 4h10.666L14.5 15.5h-13z'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg16BagCount;
