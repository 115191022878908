import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';


function Svg12ArrowForward( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <g
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M11.5 6H.5M6.5 11l5-5.06-5-5.027' />
      </g>
    </SvgContainer>
  );
}

export default Svg12ArrowForward;
