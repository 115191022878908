import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16Location( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <g
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M13.625 6.696C13.625 11.26 8 15.5 8 15.5s-5.625-4.24-5.625-8.804C2.375 3.274 4.719.5 8 .5s5.625 2.774 5.625 6.196h0z' />
        <path d='M11 6a3 3 0 11-6.001-.001A3 3 0 0111 6h0z' />
      </g>
    </SvgContainer>
  );
}

export default Svg16Location;
