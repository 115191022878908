/**
 * Call the Medallia JS Interface to display the Feedback Modal Form.
 * @returns {boolean} Strict true for success, else for any exception.
 */
export const medalliaOpenFeedbackModal = () => {
  if( !global.KAMPYLE_ONSITE_SDK || !global.__MEDALLIA_FORM_ID__ ){
    return;
  }
  const neb_status = global.KAMPYLE_ONSITE_SDK.loadForm( global.__MEDALLIA_FORM_ID__ );
  if( neb_status !== true ){
    return neb_status;
  }
  global.KAMPYLE_ONSITE_SDK.showForm( global.__MEDALLIA_FORM_ID__ );
  return true;
};