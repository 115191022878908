import React from 'react';

import ResponseMessages from '@ulta/core/components/ResponseMessages/ResponseMessages';
import { devLogger } from '@ulta/core/utils/devMode/devMode';

/**
 * ErrorBoundary component
 *
 * Handles errors in the component tree
 */
class ErrorBoundary extends React.Component{
  constructor( props ){
    super( props );
    this.state = { displayError: false, hasError: false, message: '' };
  }

  static getDerivedStateFromError( error ){
    const displayError = !!error && global.location.hostname !== process.env.ULTA_PROD_HOSTNAME;
    return { hasError: !!error, displayError, message: error?.message };
  }

  componentDidCatch( error, errorInfo ){
    const { id, moduleName } = this.props.children.props;

    devLogger( {
      title: `Fatal rendering error in ${moduleName} w/ id ${id}`,
      value: { error, errorInfo }
    } );
  }

  render(){
    const { message, hasError, displayError } = this.state;

    // If we want to display the error component
    if( displayError ){
      return (
        <ResponseMessages messageType='error'
          message={ message }
        />
      );
    }

    // When there is an error but we don't want to display it, just return null
    if( hasError ){
      return null;
    }

    // Otherwise render children
    return this.props.children;
  }
}

export default ErrorBoundary;