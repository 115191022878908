import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12X( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <g
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M.5.5l11 11M11.5.5l-11 11' />
      </g>
    </SvgContainer>
  );
}

export default Svg12X;
