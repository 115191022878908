import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24EmailOpen( props ){

  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <title>01 Token/Icon/Interface/Email Open</title>
      <defs>
        <filter id='filter-1'>
          <feColorMatrix in='SourceGraphic'
            type='matrix'
            values='0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0'
          ></feColorMatrix>
        </filter>
      </defs>
      <g id='01-Token/Icon/Interface/Email-Open'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g filter='url(#filter-1)'
          id='email-action-read-document'
        >
          <g>
            <path d='M3,24.002 C1.759,24.002 0.75,22.993 0.75,21.752 L0.75,12.052 C0.751,11.225 1.424,10.553 2.25,10.553 L2.279,10.553 C2.592,10.553 2.922,10.672 3.182,10.878 L3.75,11.329 L3.75,2.25 C3.75,1.009 4.759,0 6,0 L18,0 C19.241,0 20.25,1.009 20.25,2.25 L20.25,11.328 L20.818,10.878 C21.082,10.669 21.413,10.554 21.749,10.554 C22.211,10.554 22.639,10.761 22.926,11.122 C23.135,11.386 23.25,11.716 23.251,12.052 L23.251,21.753 C23.251,22.994 22.242,24.003 21.001,24.003 L3,24.003 L3,24.002 Z M2.25,21.752 C2.25,22.166 2.586,22.502 3,22.502 L21,22.502 C21.414,22.502 21.75,22.166 21.75,21.752 L21.75,12.052 L14.319,17.946 C13.657,18.466 12.834,18.751 11.999,18.751 C11.165,18.751 10.343,18.466 9.683,17.948 L2.25,12.053 L2.25,21.752 Z M10.613,16.771 C11.013,17.084 11.493,17.251 11.999,17.251 C12.507,17.251 12.988,17.084 13.389,16.769 L18.749,12.518 L18.749,2.25 C18.749,1.836 18.413,1.5 17.999,1.5 L6,1.5 C5.586,1.5 5.25,1.836 5.25,2.25 L5.25,12.518 L10.613,16.771 Z'
              id='Shape'
              fill='#000000'
              fillRule='nonzero'
            ></path>
            <path d='M8.25,6 C7.836,6 7.5,5.664 7.5,5.25 C7.5,4.836 7.836,4.5 8.25,4.5 L11.25,4.5 C11.664,4.5 12,4.836 12,5.25 C12,5.664 11.664,6 11.25,6 L8.25,6 Z'
              id='Path'
              fill='#000000'
              fillRule='nonzero'
            ></path>
            <path d='M8.25,9 C7.836,9 7.5,8.664 7.5,8.25 C7.5,7.836 7.836,7.5 8.25,7.5 L15.75,7.5 C16.164,7.5 16.5,7.836 16.5,8.25 C16.5,8.664 16.164,9 15.75,9 L8.25,9 Z'
              id='Path'
              fill='#000000'
              fillRule='nonzero'
            ></path>
            <path d='M8.25,12 C7.836,12 7.5,11.664 7.5,11.25 C7.5,10.836 7.836,10.5 8.25,10.5 L15.75,10.5 C16.164,10.5 16.5,10.836 16.5,11.25 C16.5,11.664 16.164,12 15.75,12 L8.25,12 Z'
              id='Path'
              fill='#000000'
              fillRule='nonzero'
            ></path>
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}

export default Svg24EmailOpen;
