/**
 * The data capture event is the integration point for clients to call our 3rd party providers (Tealium, Criteo, Quazi, .etc).  This component is the owner of the data layer integration touchpoints as well as repsonsible for resolving client specific requersts which can&#x27;t be sent from the server.
 *
 * @module views/components/DataCaptureEvent
 * @memberof -Common
 */
import React from 'react';

import PropTypes from 'prop-types';

import datacapture from '@ulta/core/utils/datacapture/datacapture';

/**
 * Represents a DataCaptureEvent component
 *
 * @method
 * @param {DataCaptureEventProps} props - React properties passed from composition
 * @returns DataCaptureEvent
 */
export const DataCaptureEvent = React.forwardRef( ( props, _ ) => {
  React.useEffect( () => {
    datacapture.processDataCaptureEvent( props );
  }, [props.id] );

  return null;
} );

/**
 * Property type definitions
 * @typedef DataCaptureEventProps
 * @type {object}
 * @property {string} example - refactor or delete
 */
export const propTypes =  {
  category: PropTypes.string,
  name: PropTypes.string,
  payload: PropTypes.object
};

DataCaptureEvent.propTypes = propTypes;
DataCaptureEvent.displayName = 'DataCaptureEvent';

export default DataCaptureEvent;
