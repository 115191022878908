/**
 * Represents a compact card component to create banner
 *
 * @module views/components/CompactCard
 * @memberof -Common
 */
import './CompactCard.scss';

import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import { useIntersectionObserver } from '@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver';
import { handleIntersection } from '@ulta/core/utils/intersectionProcessor/intersectionProcessor';

import { constants } from '@ulta/utils/constants/constants';


/**
 * Represents a CompactCard component
 *
 * @method
 * @param {CompactCardProps} props - React properties passed from composition
 * @returns CompactCard
 */
export const CompactCard = function( props ){

  const compactCardRef = useRef( null );
  const [currentCompactCardRef, setCurrentCompactCardRef] = useState( '' );
  const isIntersecting = useIntersectionObserver( compactCardRef, {
    root: props.root,
    rootMargin: props.rootMargin,
    threshold: props.threshold
  }, handleIntersection( props ) );

  useEffect( () => {
    // set compactCardRef
    if( compactCardRef.current ){
      setCurrentCompactCardRef( compactCardRef.current );
    }
  }, [] );

  return (
    <div className='CompactCard'
      ref={ compactCardRef }
    >
      <div className='CompactCard__Wrapper'>
        <div className={ classNames( 'CompactCard__Content', {
          [`CompactCard__Content--${props.backgroundColor}`]: props.backgroundColor
        } )
        }
        >
          {
            props.title && props.title.text &&
            <div className='CompactCard__Title'>
              <Text
                htmlTag={ props.title.htmlTag }
                textStyle={ props.title.textStyle }
                color={ props.title.color }
                textAlign={ props.title.textAlign }
              >
                { props.title.text }
              </Text>
            </div>
          }
          {
            props.subtitle &&
            <div className='CompactCard__Subtitle'>
              <Text
                htmlTag='p'
                textStyle='body-2'
                textAlign='left'
              >
                { props.subtitle }
              </Text>
            </div>
          }
          {
            props.linkAction && props.linkAction.label &&
            <div className='CompactCard__Action'>
              <Link_Huge
                compact
                action={ props.linkAction }
                withHover
              >
                { props.linkAction.label }
              </Link_Huge>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export const defaultProps = {
  ...constants.INTERSECTION_OBSERVER_OPTIONS
};

/**
 * Property type definitions
 * @typedef CompactCardProps
 * @type {object}
 * @property {object} title - Sets the title properties
 * @property {string} backgroundColor - Sets the backgroundColor
 * @property {string} subtitle - Sets the subtitle
 * @property {object} linkAction - Sets the linkAction properties
 */
export const propTypes =  {
  title: PropTypes.object,
  backgroundColor: PropTypes.string,
  subtitle: PropTypes.string,
  linkAction:PropTypes.object
};

CompactCard.propTypes = propTypes;
CompactCard.defaultProps = defaultProps;

export default CompactCard;
