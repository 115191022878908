import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12PositiveImpact( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <g id='PositiveImpact'>
        <path d='M8.3 5.7c1.4 0 2.6 1.2 2.6 1.2v.3h-.3c0-.1-1.1-1.2-2.3-1.1-.8 0-1.1.3-1.5.6l-.5.3h-.6c-.6 0-1 0-1.3.5h.4l.8.2c.8.3 1.4 0 2-.2l.3.1-.1.3h-.1c-.4.2-.8.4-1.4.4-.3 0-.6 0-.9-.2L4.7 8c-.4 0-.6-.1-.7-.3v-.3c-.6-.5-1.8-1.2-2.3-1h-.3c0 .2.5.5.7.6l.4.3.4.3 2.8 2c.3 0 1.2-.4 1.8-.7a6 6 0 0 1 1.1-.4c.6 0 1.4.5 1.4.5v.3c0 .1-.1.1-.2 0-.2 0-.8-.4-1.1-.4l-1 .4c-1 .4-1.5.6-2 .6S4 9 2.8 8.1L2.7 8a49.5 49.5 0 0 0-.8-.6c-.5-.4-1-.6-.9-1 0-.2.3-.3.5-.4.5-.2 1 0 1.9.4l.8.6c.5-.4 1.1-.4 1.5-.4h.5l.4-.2a2 2 0 0 1 1.7-.7zM5.3 3c.3-.3 1-.9 1.5-.6.5.2.7.8.4 1.3-.4 1-1.7 1.8-1.8 1.8a.2.2 0 0 1-.2 0s-1.4-1-1.9-1.8a1 1 0 0 1 0-.8c0-.2.2-.4.5-.5.6-.3 1.2.3 1.5.6zm1.3-.3c-.4-.1-1 .5-1.1.7-.1.1-.3.1-.4 0-.1-.2-.6-.7-1-.7H4c-.2.1-.3.2-.3.4v.4C4 4.3 5 5 5.3 5.2c.3-.2 1.3-.9 1.6-1.6.1-.3 0-.6-.3-.8z'/>
      </g>
    </SvgContainer>
  );
}

export default Svg12PositiveImpact;
