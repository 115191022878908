/**
 * The google analytics utillity file contains support for connecting to the TEALIUM client.
 *
 * @module utils/tealium
 */

import deepmerge from 'deepmerge';

import { devLogger } from '../devMode/devMode';
import { handleEmptyObjects } from '../handleEmptyObjects/handleEmptyObjects';
import { getStorage, removeStorage } from '../storage/storage';
import { STORAGE_KEY } from '../storageKeys/storageKeys';
import * as utils from './tealium';

/**
 * This method holds the logic to trigger the event when utag object is ready and
 * queue events if it is not ready
 * @param  {Object} data
 */
export const triggerEvent = ( data ) => {
  const { category = '' } = handleEmptyObjects( data );

  const eventCategory = category.toLowerCase();

  if( !Object.values( TEALIUM_EVENT_CATEGORY ).includes( eventCategory ) ){
    utils.errorHandler( TRIGGER_EVENT_UNDEFINED_ERROR );
    return;
  }

  // Wait for utag object to be ready before triggering the view event
  if( eventCategory === TEALIUM_EVENT_CATEGORY.View ){
    utils.dispatchPageViewEvent( data );
  }
  else {
    utils.queueAndProcessEvents( data );
  }
};

/**
 * Triggers page view event when ready
 * @param {object} data
 */
export const dispatchPageViewEvent = ( data ) => {
  const { dataLayer } = handleEmptyObjects( data );

  const pageViewInterval = setInterval( () => {
    if( !utils.isEventReady( data ) ){
      return;
    }

    clearInterval( pageViewInterval );
    utils.triggerViewEvent( dataLayer );
  }, TEALIUM_EVENT_DELAY );
};


/**
 * Event handler for tealium integration for "view" events
 *
 * @method
 * @property { object } eventData - the analytics data object that should be formatted
 */
export const triggerViewEvent = ( eventData = {}, tealium = global.utag ) => {
  if( !tealium?.view ){
    return;
  }

  const sessionPipeline = getStorage( { secure: false, key: STORAGE_KEY.layerhostDatacapturePostProcess } ) || {};
  global.utag_data = { ...global.utag_data, ...deepmerge( sessionPipeline, eventData ) };

  tealium.view( global.utag_data, utils.handlePageViewEvents );

  removeStorage( { secure: false, key: STORAGE_KEY.layerhostDatacapturePostProcess } );
};

/*
 * This call back method has triggered once the page view event is triggered.
 * It sets a global variable to indicate the page view event is triggered
 */
export const handlePageViewEvents = () => {
  global.isPageEventViewTriggered = true;
  setTimeout( utils.processQueuedEvents, TEALIUM_EVENT_DELAY );
};

/*
* resetIsPageEventViewTriggered - used to reset global variable, isPageEventViewTriggered, so that view events will be fired again
*/
export const resetIsPageEventViewTriggered = () => {
  global.isPageEventViewTriggered = false;
};

/**
 * This method is to queue and process the events from the
 * queue based on the presence of utag object
 * @param  {Object} data
 */
export const queueAndProcessEvents = ( data ) => {
  if( !data ){
    return;
  }

  utils.tealiumQueue.push( data );

  utils.processQueuedEvents();
};

/**
 * This method is to process all the events in the queue
 */
let triggerResolution;
export const processQueuedEvents = async() => {
  const remaining = [];
  const promises = [];

  let event = utils.tealiumQueue.shift();
  while ( event ){
    if( utils.isEventReady( event ) ){
      promises.push( utils.dispatchEvent( event ) );
    }
    else {
      remaining.push( event );
    }

    event = utils.tealiumQueue.shift();
  }

  await Promise.all[promises];

  if( remaining.length === 0 ){
    return;
  }

  // Add remaining events back to the queue, in case interaction events have flowed through
  tealiumQueue = [...remaining, ...utils.tealiumQueue];

  // Retry processing the remaining events
  clearTimeout( triggerResolution );
  triggerResolution = setTimeout( utils.processQueuedEvents, TEALIUM_EVENT_DELAY );
};

/**
 * Handles dispatching events to Tealium
 * @param {object} data
 */
export const dispatchEvent = ( data ) => {
  devLogger( { title: '[Tealium] Dispatching event', value: data } );

  const { category = '', dataLayer, handleInteractionEvents } = handleEmptyObjects( data );


  switch ( category.toLowerCase() ){
    case TEALIUM_EVENT_CATEGORY.Interaction:
      return utils.triggerInteractionEvent( { dataLayer }, { handleInteractionEvents } );
    default:
      utils.errorHandler( TRIGGER_EVENT_UNDEFINED_ERROR );
      return Promise.resolve();
  }
};

/**
 * Event handler for tealium integration for "link" events
 *
 * @method
 * @proeprty { object } eventData - tye analytics data object that should be formatted
 * @proeprty { function } handleInteractionEvents - call back method that needs to be invoked after triggering the events to tealium
 */
export const triggerInteractionEvent = ( data, methods, tealium = global.utag ) => {
  const { dataLayer } = data || {};
  const { handleInteractionEvents } = methods || {};

  return new Promise( ( resolve, reject ) => {
    try {
      if( handleInteractionEvents ){
        tealium.link( dataLayer, handleInteractionEvents );
      }
      else {
        tealium.link( dataLayer );
      }

      // We need to give Tealium some time in between events
      setTimeout( () => resolve( true ), TEALIUM_EVENT_DELAY );
    }
    catch ( error ){
      reject( error );
    }
  } );
};

/** *
 * This method has the eligibility criteria based on which Tealium events needs to be triggered
 * This checks both for utag object to be present and onetrust is loaded (a valid string needs to be
 * present in the OnetrustActiveGroups variable)
 *
 */
export const isTealiumReady = () => {
  return Boolean( global.utag && global.OnetrustActiveGroups && /[A-Za-z0-9]/.test( global.OnetrustActiveGroups ) );
};

/**
 * This method has the eligibility criteria based on which Tealium events needs to be triggered
 * This checks if the page view event is triggered before the other datacapture events are triggered and
 * invoke isTealirmReady method to check both for utag object to be present and onetrust is loaded (a valid string needs to be
 * present in the OnetrustActiveGroups variable)
 * @param  {Object} data
 */
export const isEventReady = ( data ) => {
  const { category = '' } = handleEmptyObjects( data );
  const shouldTrigger = category.toLowerCase() === TEALIUM_EVENT_CATEGORY.View || global.isPageEventViewTriggered;
  return utils.isTealiumReady() && shouldTrigger;
};

/**
 * Error handler for tealium integration
 * @param  {Object} data
 */
export const errorHandler = ( data ) => {
  devLogger( { title: 'Tealium Error', value: data } );
};

/**
 * @const {object} TEALIUM_EVENT_CATEGORY - Tealium event category
 */
export const TEALIUM_EVENT_CATEGORY = {
  View: 'view',
  Interaction: 'interaction'
};

/**
 * @const {number} TEALIUM_EVENT_DELAY - Delay between events
 */
export const TEALIUM_EVENT_DELAY = 100;

/**
 * @const {string} TRIGGER_EVENT_UNDEFINED_ERROR - Error message for undefined event type
 */
export const TRIGGER_EVENT_UNDEFINED_ERROR = 'triggerEvent: an event type was not defined';

export let tealiumQueue = [];

const tealiumProcessor = {
  triggerEvent,
  tealiumQueue: []
};

export default tealiumProcessor;
