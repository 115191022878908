import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function SVG( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <g id='DSOTF-Navigation-–-Walk'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='W_S_Nav_Menu_NeedHelp'
          transform='translate(-24.000000, -537.000000)'
          fill='#000000'
          fillRule='nonzero'
        >
          <g id='Components/Navigation/Overlay/M---down/Icon-Nav-Flyout'
            transform='translate(0.000000, 64.000000)'
          >
            <g id='Links'
              transform='translate(24.000000, 193.000000)'
            >
              <g id='Email'
                transform='translate(0.750000, 276.000000)'
              >
                <g id='Base/Icons/UI/envelope-closed'
                  transform='translate(0.000000, 4.750000)'
                >
                  <path d='M20.25,0 C21.4407117,0 22.4156322,0.924881563 22.4948084,2.09591618 L22.5,2.25 L22.5,13.25 C22.5,14.4404547 21.5744461,15.4156108 20.4039998,15.4948071 L20.25,15.5 L2.25,15.5 C1.05954534,15.5 0.0843891947,14.5744461 0.00519294446,13.4039998 L0,13.25 L0,2.25 C0,1.0588438 0.925307579,0.0843307337 2.09596944,0.00518929065 L2.25,0 L20.25,0 Z M21,2.409 L11.7185213,9.83565161 C11.4750422,10.0304349 11.1404614,10.0520775 10.8767374,9.90057937 L10.7814787,9.83565161 L1.5,2.41 L1.5,13.25 C1.5,13.6293042 1.78251279,13.9434257 2.14831917,13.9931452 L2.25,14 L20.25,14 C20.6293042,14 20.9434257,13.7174872 20.9931452,13.3516808 L21,13.25 L21,2.409 Z M19.736,1.5 L2.763,1.5 L11.25,8.289 L19.736,1.5 Z'
                    id='-Icon-Color'
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}

export default SVG;
