import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg40TikTok( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 40 }
      height={ 40 }
    >
      <path
        d='M20,0 C8.955,0 0,8.955 0,20 C0,31.045 8.955,40 20,40 C31.045,40 40,31.045 40,20 C40,8.955 31.045,0 20,0 Z M31.6666667,17.6020902 C29.3730273,17.6075762 27.1356367,16.8923082 25.2705682,15.5573311 L25.2705682,24.8678875 C25.2692715,28.3990875 23.076792,31.5588026 19.7693002,32.7960983 C16.4618084,34.033394 12.7336982,33.0885053 10.4148989,30.4252273 C8.0960995,27.7619494 7.67343305,23.9394261 9.3543638,20.833929 C11.0352946,17.7284319 14.4669182,15.991947 17.9648349,16.4768215 L17.9648349,21.1597105 C16.3614486,20.6553935 14.615525,21.2385934 13.6371844,22.605298 C12.6588439,23.9720026 12.6696859,25.8126457 13.6640587,27.1677315 C14.6584315,28.5228173 16.4111041,29.0854113 18.0084382,28.5622428 C19.6057723,28.0390742 20.6859629,26.5486438 20.6859249,24.8678875 L20.6859249,6.66666667 L25.2705682,6.66666667 C25.2673837,7.0538112 25.2998213,7.44042791 25.3674709,7.82162993 L25.3674709,7.82162993 C25.6894445,9.54147628 26.7052392,11.0529439 28.176086,12.0007528 C29.2114805,12.6853392 30.4253994,13.0502224 31.6666667,13.0499616 L31.6666667,17.6020902 Z'
        fill='currentColor'
        fillRule='nonzero'
      />
    </SvgContainer>
  );
}

export default Svg40TikTok;