/**
* The Icon component returns an SVG Icon image
* @module views/Atoms/Icon
* @memberof -Common
*/


import React from 'react';

import PropTypes from 'prop-types';

/*
 1. Import icons as module object
    Example: icons ={ Svg12Add: ƒ Svg12Add(props) }
   * ----------------------------
*/
import * as icons from '@ulta/icons';

const iconSizes = {
  s: '12',
  m: '16',
  l: '24',
  customSocialMedium: '24',
  customSocialLarge: '40',
  customMedium:'custommedium',
  customLarge:'customlarge'
};

/*
   * 2- Iterating through the icons module to make an object(iconList) with a key name in lowercase
   *    Example:  icons ={ Svg12Add: ƒ Svg12Add(props) } ==> iconList = { svg12add: ƒ Svg12Add(props) }
   * ----------------------
*/
const iconList = Object.entries( icons ).reduce( ( acc, [name, value] ) => ( {
  ...acc,
  [ name.toLowerCase() ]: value
} ), {} );

/**
 * Component to display icons
 *
 * @method
 * @param {object} size - Sets the size of the icon
 * @param {object} name - Sets the icon's figure
 * @param {IconProps} props - Additional React properties passed from composition
 * @returns Icon
 */
const Icon = ( props ) => {
  const { size, name, ...restProps } = props;
  if( !name ){
    return null;
  }
  const lowerCaseName = name.toLowerCase();
  /*
    * 3. If size='s', name='Add' provided
    * ----------------------
    *  3.a iconSize['s'] = 12
    *  3.b lowercaseName = add
    *  3.c iconList[`svg${ iconSizes[size] }${ lowerCaseName }`] = iconList['svg12add']
    *  3.d IconName = Svg12Add(props) component
  */
  const IconName = size ? iconList[`svg${ iconSizes[size] }${ lowerCaseName }`] : iconList[ lowerCaseName ];
  /* 4.Make sure IconName is defined*/
  const isValidIcon = !/undefined/g.test( IconName );

  return isValidIcon && (
    <IconName { ...restProps } ></IconName>
  );
};

/**
  * Property type definitions
  * @typedef IconProps
  * @type {object}
  * @property {string} name - Name of the icon (from the first column in the Storybook table)
*/

export const propTypes = {
  /**
   * Name of the icon (from the first column in the Storybook table)
   */
  name: PropTypes.string
};

Icon.propTypes = propTypes;

export default Icon;
