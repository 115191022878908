import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24BagCount( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <path
        d='M15.094 6a3.438 3.438 0 00.363-1.543A3.457 3.457 0 108.81 5.79M3.5 6h17L22 23.5H2z'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg24BagCount;
