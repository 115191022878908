/**
 * The Js script tag is a way to dynamically add a script to a page's composition from amplience.
 *
 * @module views/components/JSscriptTag
 * @memberof -Common
 */
// Start: Removal https://jira.ulta.com/browse/DSOTF2-71273 - once the Signal/Tealium migration is complete
import React, { useEffect, useState } from 'react';

import Cookies from 'js-cookie';
// End: Removal
import PropTypes from 'prop-types';

import { useResourceLoaderContext } from '@ulta/core/providers/ResourceLoaderProvider/ResourceLoaderProvider';
import { useUserContext } from '@ulta/core/providers/UserContextProvider/UserContextProvider';
import { hasItems } from '@ulta/core/utils/array/array';
import { isServer } from '@ulta/core/utils/device_detection/device_detection';
import { setOneTrustClass, setOneTrustType } from '@ulta/core/utils/oneTrust/oneTrust';

import * as utils from './JSscriptTag';

/**
 * Represents a JSscriptTag component
 *
 * @method
 * @param {JSscriptTagProps} props - React properties passed from composition
 * @returns JSscriptTag
 */
export const JSscriptTag = React.forwardRef( ( props, _ ) => {
  const {
    printImmediate,
    async,
    body,
    dataSet,
    defer,
    groupName,
    src,
    tagId,
    type,
    divId
  } = props;

  // `printImmediate` is for rendering a script tag on the server
  const shouldPrint = isServer() && printImmediate && utils.allowedTag( { tagId } );

  const dataSetProps = {};
  if( hasItems( dataSet ) && shouldPrint ){
    dataSet.forEach( ( attribute ) => {
      const [attributeName, attributeValue] = Object.entries( attribute )[0];
      dataSetProps[attributeName] = attributeValue;
    } );
  }

  useClientScriptTag( props );

  return (
    <>
      { !!divId &&
        <div className='JSscriptTag__divContainer'
          id={ divId }
        ></div>
      }
      { shouldPrint &&
        <script
          id={ tagId }
          { ...( src && { src } ) }
          { ...( async && { async } ) }
          { ...( defer && { defer } ) }
          { ...( type && { type: setOneTrustType( { groupName, type } ) } ) }
          { ...( groupName && { className: setOneTrustClass( { groupName: groupName } ) } ) }
          { ...dataSetProps }
        >{ body }</script>
      }
    </>
  );
} );
/**
 *  method useClientScriptTag
 * @param {object} data - data
 * @param {boolean} data.printImmediate - returns true/false
 * @param {boolean} data.immediate - returns true/false
 * @param {string} data.type - returns data type
 * @param {string} data.groupName - returns groupName
 * @param {string} data.tagId - returns tagId
 * @param {string} data.src - returns src
 * @param {boolean} async - load asynchronously boolean value
 * @param {boolean} defer - defer loading boolean value
 * @param {string} body - script body
 * @param {array} dataSet - an array with attribute, multi-word data attributes should be passed as camelCase keynames
 */
export const useClientScriptTag = ( data ) => {
  const { user } = useUserContext();
  const { queueScript } = useResourceLoaderContext();

  const {
    printImmediate,
    type,
    groupName,
    tagId,
    src,
    async,
    defer,
    body,
    dataSet,
    immediate,
    forceReload = false
  } = data || {};

  // `immediate` is passed by the ResourceProvider because that handles queuing on its own
  const [canLoad, setCanLoad] = useState( immediate );

  // Setup resource loader
  useEffect( () => {
    if( printImmediate || immediate ){
      return;
    }

    queueScript( {
      tagId,
      callback: () => setCanLoad( true )
    } );
  }, [setCanLoad, printImmediate, immediate] );

  // Append the script tag to the body
  useEffect( () => {
    const allowed = tagId && utils.allowedTag( { tagId, params: global.location?.search } );
    const hasPlaced = document.getElementById( tagId ) !== null;
    const shouldAppend = allowed && canLoad && !hasPlaced && !printImmediate;

    if( !shouldAppend && !forceReload ){
      return;
    }

    const s = utils.getClientScriptTag( {
      type,
      groupName,
      tagId,
      src,
      async,
      defer,
      body,
      dataSet
    } );

    document.body.appendChild( s );
  }, [tagId, canLoad, user, printImmediate] );
};

/**
 *  method useClientScriptTag
 * @param {object} data - data
 * @param {boolean} data.printImmediate - returns true/false
 * @param {string} data.type - returns data type
 * @param {string} data.groupName - returns groupName
 * @param {string} data.tagId - returns tagId
 * @param {string} data.src - returns src
 * @param {boolean} async - load asynchronously boolean value
 * @param {boolean} defer - defer loading boolean value
 * @param {string} body - script body
 * @param {array} dataSet - an array with attribute, multi-word data attributes should be passed as camelCase keynames
 */
export const getClientScriptTag = ( data ) => {
  const { type, groupName, tagId, src, async, defer, body, dataSet } = data || {};

  const scriptTag = document.createElement( 'script' );

  // set the type for the tag
  if( type ){
    scriptTag.setAttribute( 'type', setOneTrustType( { groupName, type } ) );
  }

  // set the id for the tag
  scriptTag.setAttribute( 'id', tagId );

  // if a src is passed set it
  if( src ){
    scriptTag.setAttribute( 'src', src );
  }

  // if the async attribute is set use it
  if( async ){
    scriptTag.setAttribute( 'async', async );
  }

  // if the defer attribute is set use it
  if( defer ){
    scriptTag.setAttribute( 'defer', defer );
  }

  // set the oneTrust class name
  if( groupName ){
    scriptTag.setAttribute( 'class', setOneTrustClass( { groupName: groupName } ) );
  }

  // if the props have a body set the content of the tag to the body
  if( body ){
    scriptTag.innerHTML = body;
  }

  // if props have dataSet, custom data attributes are created from the key/value pairs. Multi-word data attributes should be passed as camelCase keynames.
  if( dataSet && dataSet.length > 0 ){
    dataSet.forEach( ( attribute ) => {
      const [attributeName, attributeValue] = Object.entries( attribute )[0];
      scriptTag.dataset[attributeName] = attributeValue;
    } );
  }

  return scriptTag;
};

export const allowedTag = function allowedTag( data ){
  const { params = '', tagId = '' } = data || {};

  if( !params && !Cookies.get( 'ulta_tms' ) ){
    return true;
  }
  const searchParams = new URLSearchParams( params );

  // Start: Removal https://jira.ulta.com/browse/DSOTF2-71273 - once the Signal/Tealium migration is complete
  if( ['signal', 'tealium'].includes( tagId.toLowerCase() ) ){
    if( searchParams.has( 'ulta_tms' ) ){
      return searchParams.get( 'ulta_tms' ).toLowerCase() === tagId.toLowerCase();
    }
    else if( Cookies.get( 'ulta_tms' ) ){
      return Cookies.get( 'ulta_tms' ).toLowerCase() === tagId.toLowerCase();
    }
  }
  // End: Removal

  if( searchParams.has( 'debug-first-party' ) ){
    return searchParams.has( tagId );
  }

  return true;
};

/**
 * Property type definitions
 * @typedef JSscriptTagProps
 * @type {object}
 * @property {string} type - script MIME type
 * @property {string} tagId - id attribute for the script
 * @property {string} src - URL for the script file
 * @property {boolean} async - load asynchronously boolean value
 * @property {boolean} defer - defer loading boolean value
 * @property {string} body - script body
 * @property {array} dataSet - an array with attribute, multi-word data attributes should be passed as camelCase keynames
 * @property {string} groupName - a name for setting the OneTrust group level
 */
export const propTypes = {
  type: PropTypes.string,
  tagId: PropTypes.string,
  src: PropTypes.string,
  async: PropTypes.bool,
  defer: PropTypes.bool,
  body: PropTypes.string,
  dataSet: PropTypes.array,
  groupName: PropTypes.string
};

/**
 * Default values for passed properties
 *
 * @type {object}
 * @property {boolean} defer=false - defer loading default is false
 * @property {boolean} async=false - load asynchronously default is false
 */
export const defaultProps = {
  immediate: false,
  printImmediate: false,
  defer: false,
  async: false
};

JSscriptTag.propTypes = propTypes;
JSscriptTag.defaultProps = defaultProps;
JSscriptTag.displayName = 'JSscriptTag';

export default JSscriptTag;
