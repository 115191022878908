import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12Minus( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <path
        stroke='currentColor'
        d='M.5 6.007h10.843'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg12Minus;
