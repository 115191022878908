/**
 * existing GhostCard component does the CSS animations and gets the right color variable
 *
 * @module views/components/LoaderGhostCard
 * @memberof -Common
 */
import './LoaderGhostCard.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import palette_variables from 'web-styles/src/_exports.module.scss';

import { LOADER_CHILD_CLASS } from '@ulta/core/components/Loader/Loader';


/**
 * Represents a LoaderGhostCard component
 *
 * @method
 * @param { LoaderGhostCardProps} props - React properties passed from composition
 * @returns LoaderGhostCard
 */
export const LoaderGhostCard = function( props ){
  const { color } = props;

  return (
    <div className={ classNames( 'LoaderGhostCard__card', LOADER_CHILD_CLASS ) }
      data-test='ghost-card'
      style={ { backgroundColor: palette_variables[color] } }
    />

  );
};

/**
 * Property type definitions
 * @typedef LoaderGhostCardProps
 * @type {object}
 * @property {string} color - color of the ghost card
 */
export const propTypes =  {
  color: PropTypes.string
};

LoaderGhostCard.propTypes = propTypes;

export default LoaderGhostCard;
