import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16Bag( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <g
        transform='translate(2 1)'
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M6.182 3.248a1.899 1.899 0 00.2-.852 1.91 1.91 0 10-3.67.737M6.224 12.24H.019l.557-8.908H8.37l.213 3.402' />
        <circle cx={ 8.741 }
          cy={ 10.182 }
          r={ 3.182 }
        />
        <path d='M8.741 8.909v2.546M10.013 10.182H7.468' />
      </g>
    </SvgContainer>
  );
}

export default Svg16Bag;
