/**
 * The ServerRequestContextProvider component is used to provide the ssr request context details to any component which needs it.
 *
 * @module core/providers/ServerRequestContextProvider/ServerRequestContextProvider
 */
import React, { createContext, useContext } from 'react';


/**
 * Represents a ServerRequestContextProvider component
 *
 * @method
 * @param { Object } props - React properties passed from composition
 * @returns ServerRequestContextProvider
 */
export const ServerRequestContextProvider = function( props ){
  const { context, children } = props;
  return (
    <ServerRequestContext.Provider value={ context } >
      { children }
    </ServerRequestContext.Provider>
  );
};



export default ServerRequestContextProvider;


/**
 * Context provider for react reuse
 * @type object
 */
export const ServerRequestContext = createContext();

/**
 * contxt provider
 * @type object
 */
export const useServerRequestContext = () => useContext( ServerRequestContext );
