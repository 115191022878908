import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function SVG( props ){

  return (
    <SvgContainer
      { ...props }
      width='38px'
      height='34px'
      viewBox='0 0 38 34'
      version='1.1'
    >
      <g id='------I.-CART-/-Guest-(Ship)'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='1.A-XL_Empty-Bag-'
          transform='translate(-820.000000, -571.000000)'
          fill='#000000'
          fillRule='nonzero'
        >
          <g id='Sign-In-/-Create-Account'
            transform='translate(40.000000, 404.000000)'
          >
            <g id='Create-Account'
              transform='translate(451.000000, 48.000000)'
            >
              <g id='Icons'
                transform='translate(48.000000, 115.583333)'
              >
                <g id='3'
                  transform='translate(247.000000, 0.416667)'
                >
                  <g id='Base/Icons/UI/gem'
                    transform='translate(34.666667, 3.333333)'
                  >
                    <path
                      d='M19.7887998,2.98516543 L18.331299,4.48549652 L16.8749011,2.98492011 C14.6291961,0.66980568 11.4333553,-0.393844888 8.28935427,0.132076053 C5.14968406,0.657272544 2.4516146,2.70231832 1.0352441,5.62207806 L0.854356723,6.01619526 C-0.746493948,9.72739485 -0.0565736478,14.0267343 2.57936693,16.9917828 L17.3962529,32.9254151 C17.9020817,33.4693423 18.7619953,33.4693003 19.2677711,32.9253236 L33.8041791,17.2909933 C36.7789561,14.2234397 37.5189207,9.51951488 35.628743,5.62217737 L35.4214614,5.21854286 C33.9390786,2.49660994 31.3516322,0.630069423 28.3745847,0.132076053 C25.2305836,-0.393844888 22.0347428,0.66980568 19.7887998,2.98516543 Z M27.9534345,2.66119371 C30.1500938,3.02864561 32.0677114,4.41198287 33.1618874,6.42054389 L33.3414405,6.77028534 L33.50087,7.12145737 C34.6511642,9.83545159 34.1486512,13.0268869 32.2118047,15.2403961 L18.331299,30.1692337 L4.73279401,15.5475098 C2.55675008,13.3053179 1.95203732,9.93262763 3.19098338,7.05981286 L3.34810004,6.71760735 C4.39253683,4.56528327 6.39323579,3.04882084 8.7105044,2.66119371 C11.0234422,2.27429104 13.3774995,3.05777636 15.0408563,4.77254356 L17.4150661,7.21882776 C17.9173071,7.7363154 18.7466319,7.7363154 19.2488729,7.21882776 L21.6228446,4.77278887 C23.2864394,3.05777636 25.6404967,2.27429104 27.9534345,2.66119371 Z'
                      id='Path'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}

export default SVG;
