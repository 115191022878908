/**
 * This is a Search  Mobile Component.This Component is used to render the Search and the Suggestion Component. This Component will open the Search Suggestion on the Focus of the Input.
 *
 * @module views/components/SearchMobile
 * @memberof -Common
 */
import './SearchMobile.scss';

import React, { useCallback, useEffect, useRef } from 'react';

import classNames from 'classnames';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import InputFieldUnderline from '@ulta/core/components/InputFieldUnderline/InputFieldUnderline';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { useOverlay } from '@ulta/core/providers/OverlayProvider/OverlayProvider';
import { isServer } from '@ulta/core/utils/device_detection/device_detection';

import { useSearchHeaderContext } from '@ulta/providers/SearchHeaderProvider/SearchHeaderProvider';

import RecentSearchList from '../RecentSearchList/RecentSearchList';
import SuggestionList from '../SuggestionList/SuggestionList';
import * as utils from './SearchMobile';

/**
 * Represents a SearchMobile component
 *
 * @method
 * @returns SearchMobile
 */
export const SearchMobile = function(){
  const { openOverlay, updateContent, displayOverlay, closeOverlay } = useOverlay();
  const { breakpoint } = useDeviceInflection();
  const buttonRef = useRef();
  const modalRef = useRef( null );
  const isOpening = useRef( false );
  const modalOpen = useRef( false );

  const searchHeaderContext = useSearchHeaderContext();
  const { suggestions, clearRecentLabel, recentLabel, recentSearches, maxTrendingToDisplay, suggestionsLabel, loading, ariaLabel, componentKey, mediumDeviceSearchAction, smallDeviceSearchAction } = searchHeaderContext;
  useEffect( () => {

    if( !displayOverlay ){
      modalOpen.current = false;
    }
  }, [displayOverlay] );

  useEffect( () => {
    utils.displayHandler( { modalRef, searchHeaderContext, isOpening, modalOpen }, { updateContent, closeOverlay } );
  }, [suggestions, suggestionsLabel, clearRecentLabel, recentLabel, recentSearches, maxTrendingToDisplay, loading, modalOpen.current, displayOverlay, componentKey] );


  const onClick = useCallback( utils.composeOnClick( { buttonRef, modalRef, isOpening, modalOpen }, { openOverlay } ), [
    buttonRef,
    modalRef,
    isOpening,
    openOverlay,
    modalOpen
  ] );

  let searchAction = smallDeviceSearchAction;
  // added server check to ensure that search action for mobile device is considered during SSR rendering
  if( !isServer() && breakpoint.CURRENT_BREAKPOINT === 'MD' ){
    searchAction = mediumDeviceSearchAction ;
  }

  return (
    <div className='SearchMobile'
      role='region'
      aria-describedby='searchDescription'
    >
      <div className='SearchMobile__button'>
        <Button
          iconLabel='Search'
          label={ searchAction?.label }
          iconRight={ true }
          iconImage='search'
          iconSize='default'
          ariaLabel={ ariaLabel }
          ariaHiddenIcon={ true }
          ref={ buttonRef }
          onClick={ onClick }
          action={ searchAction }
        />
      </div>
    </div>
  );
};

/**
 * Method to handle displayHandler
 * @param {object} data args
 * @param {object} data.modalRef - Ref to the searchModal
 * @param {object} data.isOpening - isOpening Ref
 * @param {object} data.searchHeaderContext - searchHeaderContext
 * @param {object} methods args
 * @param {function} methods.updateContent updateContent
 */
export const displayHandler = ( data, methods ) => {
  const { modalRef, searchHeaderContext, isOpening, modalOpen } = data || {};
  const { updateContent, closeOverlay } = methods || {};

  if( !searchHeaderContext || !modalOpen.current ){
    return;
  }
  const {
    suggestions,
    suggestionsLabel,
    trendingLabel,
    searchString,
    isKeyBoardUser,
    inputChangeHandler,
    searchInputLabel,
    clearRecentLabel,
    recentLabel,
    recentSearches,
    maxTrendingToDisplay,
    handleAddRecentSearch,
    handleRemoveRecentSearch,
    handleClearAllRecentSearches,
    handleSaveSearchTerm,
    savedSearchTerm,
    moveCursorToEndOfInput,
    invokeTypeAheadAction,
    resetAction,
    handleSubmit,
    loading,
    dataCaptureHandler,
    clearAccessibilityLabel,
    submitSearchLabel,
    componentKey
  } = searchHeaderContext;

  let currentSuggestions = suggestions;

  // When we close the overlay we want to invalidate results on the next render,
  // prevents a flash of old results if the user re-opens

  modalRef.current = (
    <SearchModal
      suggestions={ currentSuggestions }
      suggestionsLabel={ suggestionsLabel }
      trendingLabel={ trendingLabel }
      searchString={ searchString }
      isKeyBoardUser={ isKeyBoardUser }
      inputChangeHandler={ inputChangeHandler }
      searchInputLabel={ searchInputLabel }
      clearRecentLabel={ clearRecentLabel }
      recentLabel={ recentLabel }
      recentSearches={ recentSearches }
      maxTrendingToDisplay={ maxTrendingToDisplay }
      handleAddRecentSearch={ handleAddRecentSearch }
      handleRemoveRecentSearch={ handleRemoveRecentSearch }
      handleClearAllRecentSearches={ handleClearAllRecentSearches }
      handleSaveSearchTerm={ handleSaveSearchTerm }
      savedSearchTerm={ savedSearchTerm }
      moveCursorToEndOfInput={ moveCursorToEndOfInput }
      invokeTypeAheadAction={ invokeTypeAheadAction }
      resetAction={ resetAction }
      handleSubmit={ handleSubmit }
      loading={ loading }
      dataCaptureHandler={ dataCaptureHandler }
      clearAccessibilityLabel={ clearAccessibilityLabel }
      submitSearchLabel={ submitSearchLabel }
      isOpening={ isOpening }
      closeOverlay={ closeOverlay }
      componentKey={ componentKey }
    />
  );

  updateContent( modalRef.current );
};

/**
 * Method to handle composeOnClick
 * @param {object} data args
 * @param {object} data.buttonRef - buttonRef
 * @param {object} data.modalRef - Ref to the searchModal
 * @param {object} data.isOpening - isOpening Ref
 * @param {object} methods args
 * @param {function} methods.openOverlay openOverlay
 */
export const composeOnClick = ( data, methods ) => () => {
  const { buttonRef, modalRef, isOpening, modalOpen } = data || {};
  const { openOverlay } = methods || {};

  if( !openOverlay ){
    return;
  }

  isOpening.current = true;
  modalOpen.current = true;
  openOverlay( {
    type: 'modal',
    crossButtonVisibility: true,
    opener: buttonRef,
    customClassName: 'SearchMobile',
    content: modalRef.current,
    alignment: ''
  } );
};

/**
 * Represents a SearchModal component.
 *
 * @returns SearchModal
 **/
export const SearchModal = ( props ) => {
  const wrapperRef = useRef();
  const inputRef = useRef();
  const {
    clearAccessibilityLabel,
    dataCaptureHandler,
    handleSubmit,
    inputChangeHandler,
    invokeTypeAheadAction,
    isKeyBoardUser,
    loading,
    resetAction,
    searchInputLabel,
    clearRecentLabel,
    recentLabel,
    recentSearches,
    maxTrendingToDisplay,
    handleAddRecentSearch,
    handleRemoveRecentSearch,
    handleClearAllRecentSearches,
    handleSaveSearchTerm,
    moveCursorToEndOfInput,
    savedSearchTerm,
    searchString,
    submitSearchLabel,
    suggestions,
    suggestionsLabel,
    trendingLabel,
    closeOverlay,
    componentKey
  } = props;

  useEffect( () => {
    invokeTypeAheadAction( savedSearchTerm );

    return () => {
      invokeTypeAheadAction( savedSearchTerm );
    };
  }, [] );

  return (
    <Formik initialValues={ { search: savedSearchTerm } }
      onSubmit={ ( event )=>{
        handleSubmit( event );
        closeOverlay( event );
      } }
      as={ Formik }
    >
      { () => (
        <Form
          className={ classNames( 'SearchMobile__Form', {
            'SearchMobile__Form--loading': loading
          } ) }
          role='search'
        >
          <InputFieldUnderline
            ref={ inputRef }
            type='search'
            name='search'
            autoComplete='off'
            placeholder={ searchInputLabel }
            onChange={ inputChangeHandler }
            handleFocus={ () => {
              moveCursorToEndOfInput( inputRef );
            } }
            handleResetVal={ resetAction }
            leftIcon='Search'
            displayGoButton={ true }
            clearValueText={ clearAccessibilityLabel }
            submitText={ submitSearchLabel }
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={ true }
          />

          <div className='SearchMobile__results'>
            <RecentSearchList
              clearRecentLabel={ clearRecentLabel }
              recentLabel={ recentLabel }
              recentSearches={ recentSearches }
              handleAddRecentSearch={ handleAddRecentSearch }
              handleRemoveRecentSearch={ handleRemoveRecentSearch }
              handleClearAllRecentSearches={ handleClearAllRecentSearches }
              handleSaveSearchTerm={ handleSaveSearchTerm }
              searchString={ searchString }
              dataCaptureHandler={ dataCaptureHandler }
              handleSubmit={ closeOverlay }
              componentKey={ componentKey }
            />

            <SuggestionList
              suggestions={ suggestions }
              trendingLabel={ trendingLabel }
              suggestionsLabel={ suggestionsLabel }
              searchString={ searchString }
              isKeyBoardUser={ isKeyBoardUser }
              dataCaptureHandler={ dataCaptureHandler }
              handleSubmit={ closeOverlay }
              handleAddRecentSearch={ handleAddRecentSearch }
              maxTrendingToDisplay={ maxTrendingToDisplay }
              componentKey={ componentKey }
            />
          </div>
        </Form>
      ) }
    </Formik>
  );
};

/**
 * Property type definitions
 * @typedef SearchModal
 * @type {object}
 * @property {string} clearAccessibilityLabel - This is clearAccessibilityLabel for X button
 * @property { function } dataCaptureHandler - Function will handle/capture datacapture events and store in local storage.
 * @property {function} handleSubmit - Function will handle submit
 * @property {function} inputChangeHandler - Function will handle on inputChangeHandler
 * @property {function} invokeTypeAheadAction - Function will handle on invokeTypeAheadAction
 * @property {function} resetAction - Function will handle on resetAction
 * @property { boolean } isKeyBoardUser - Denote user is a keyboard user
 * @property {boolean} loading - sets true or false
 * @property {string} searchInputLabel - sets the placeholder for search
 * @property {string} submitSearchLabel - sets the placeholder for search
 * @property {string} searchString - This is searchString value i.e input
 * @property {string} clearRecentLabel - This is recent searches clear all label
 * @property {string} recentLabel - This is recent searches label for current recent searches
 * @property {array} recentSearches - Array contains recent search strings
 * @property {number} maxTrendingToDisplay - Max number of trending suggestions to display
 * @property {function} handleAddRecentSearch - Function will handle on handleAddRecentSearch
 * @property {function} handleRemoveRecentSearch - Function will handle on handleRemoveRecentSearch
 * @property {function} handleClearAllRecentSearches - Function will handle on handleClearAllRecentSearches
 * @property {function} handleSaveSearchTerm - Function will handle on handleSaveSearchTerm
 * @property {function} moveCursorToEndOfInput - Function will handle moving cursor to end of input
 * @property {string} savedSearchTerm - This is the saved search term from local storage
 * @property {array} suggestions - Object contains trending and suggestion items
 * @property {string} suggestionsLabel - This is suggestion label for current suggestions
 * @property {string} trendingLabel - This is trending label for current trending
 * @property {string} componentKey - Provided by LayerHost, timestamp of the last time the component was updated
 */
export const propTypes = {
  clearAccessibilityLabel: PropTypes.string,
  dataCaptureHandler: PropTypes.func,
  handleSubmit: PropTypes.func,
  invokeTypeAheadAction: PropTypes.func,
  inputChangeHandler: PropTypes.func,
  resetAction: PropTypes.func,
  isKeyBoardUser: PropTypes.bool,
  loading: PropTypes.bool,
  searchInputLabel: PropTypes.string,
  submitSearchLabel: PropTypes.string,
  searchString: PropTypes.string,
  clearRecentLabel: PropTypes.string,
  recentLabel: PropTypes.string,
  recentSearches: PropTypes.array,
  maxTrendingToDisplay: PropTypes.number,
  handleAddRecentSearch: PropTypes.func,
  handleRemoveRecentSearch: PropTypes.func,
  handleClearAllRecentSearches: PropTypes.func,
  handleSaveSearchTerm: PropTypes.func,
  moveCursorToEndOfInput: PropTypes.func,
  savedSearchTerm: PropTypes.string,
  suggestions: PropTypes.array,
  suggestionsLabel: PropTypes.string,
  trendingLabel: PropTypes.string,
  componentKey: PropTypes.string
};

SearchModal.propTypes = propTypes;

export default SearchMobile;
