
import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function SVG( props ){

  return (
    <SvgContainer
      { ...props }
      viewBox='0 0 194 121'
    >
      <g>
        <g>
          <rect
            style={ { fill: '#ccc' } }
            width='194'
            height='121'
            rxb='5'
            ry='5'
          />
          <rect
            style={ { fill: '#999a9d' } }
            y='19.47'
            width='194'
            height='25.32'
          />
          <rect
            style={ { fill: '#fff' } }
            y='76.46'
            width='114'
            height='18.32'
          />
          <rect
            style={ { fill: '#fff' } }
            x='149.99'
            y='76.46'
            width='24.01'
            height='18.32'
          />
          <ellipse
            style={ {
              fill: 'none',
              stroke: '#cb2026',
              strokeMiterlimit: '10',
              strokeWidth: '4px'
            } }
            cx='162.85'
            cy='85.86'
            rx='27.8'
            ry='17.67'
          />
        </g>
      </g>
    </SvgContainer>
  );
}

export default SVG;

