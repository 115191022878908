import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24ArrowForward( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <g
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M23 12H1M13 23l10-11.036L13 1' />
      </g>
    </SvgContainer>
  );
}

export default Svg24ArrowForward;
