import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function SVG( props ){

  return (
    <SvgContainer
      { ...props }
      width={ 50 }
      height={ 50 }
      viewBox='0 0 32 21'
    >
      <g fill='none'
        fillRule='evenodd'
        stroke='none'
        strokeWidth='1'
      >
        <g fillRule='nonzero'
          transform='translate(-336)'
        >
          <g transform='translate(261 .498)'>
            <g transform='translate(75.5 .1)'>
              <path
                fill='#FFF'
                d='M30 0c.92 0 1.667.746 1.667 1.667v16.458c0 .92-.747 1.667-1.667 1.667H1.667c-.92 0-1.667-.747-1.667-1.667V1.667C0 .747.746 0 1.667 0H30z'
              ></path>
              <path
                fill='silver'
                d='M30 0c.92 0 1.667.746 1.667 1.667v16.458c0 .92-.747 1.667-1.667 1.667H1.667c-.92 0-1.667-.747-1.667-1.667V1.667C0 .747.746 0 1.667 0H30zm0 .417H1.667a1.25 1.25 0 00-1.245 1.13l-.005.12v16.458c0 .65.495 1.184 1.13 1.244l.12.006H30a1.25 1.25 0 001.244-1.13l.006-.12V1.667A1.25 1.25 0 0030.12.422L30 .417z'
              ></path>
              <path
                fill='#F79E1B'
                d='M22.758 9.9a3.958 3.958 0 01-6.925 2.617 3.942 3.942 0 000-5.225A3.958 3.958 0 0122.758 9.9z'
              ></path>
              <path
                fill='#FF5F00'
                d='M16.825 9.9c0 .964-.354 1.894-.992 2.617a3.942 3.942 0 010-5.225c.637.72.989 1.647.992 2.608z'
              ></path>
              <path
                fill='#EB001B'
                d='M14.842 9.9c0 .964.353 1.894.991 2.617a3.958 3.958 0 110-5.225 3.958 3.958 0 00-.991 2.608z'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}


export default SVG;
