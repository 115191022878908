import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24ArrowBack( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <g
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M1 12h22M11 23L1 11.964 11 1' />
      </g>
    </SvgContainer>
  );
}

export default Svg24ArrowBack;
