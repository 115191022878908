import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function GiftCard( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 38 }
      height={ 24 }
      viewBox='0 0 38 24'
    >
      <g fill='none'>
        <path d='M36 .13a2 2 0 0 1 2 2v19.75a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2.13a2 2 0 0 1 2-2h34Zm0 .5H2A1.5 1.5 0 0 0 .507 1.986L.5 2.13v19.75a1.5 1.5 0 0 0 1.356 1.493L2 23.38h34a1.5 1.5 0 0 0 1.493-1.356l.007-.144V2.13A1.5 1.5 0 0 0 36.144.637L36 .63Z'
          fill='silver'
        />
        <rect width={ 38 }
          height={ 24 }
          rx={ 2 }
          fill='#E90B5A'
        />
        <path d='M22.378 6.347c1.054-1.138 2.684-1.37 3.975-.566 1.291.805 1.92 2.445 1.534 4-.385 1.554-1.69 2.635-3.18 2.635l-3.464-.001 3.06 3.304a1.06 1.06 0 0 1 0 1.418.879.879 0 0 1-1.21.098l-.104-.098L19 12.831l-3.989 4.306a.882.882 0 0 1-1.314 0 1.064 1.064 0 0 1-.09-1.306l.09-.112 3.059-3.304h-3.462c-1.429 0-2.686-.991-3.128-2.442l-.053-.191c-.386-1.555.243-3.195 1.534-4l.206-.118c1.255-.66 2.77-.394 3.769.684l3.377 3.645 3.379-3.645Zm3.046 1.172a1.356 1.356 0 0 0-1.732.246l-2.45 2.644h3.464c.596 0 1.123-.395 1.336-.983l.05-.165c.169-.677-.106-1.392-.668-1.742Zm-11.116.246a1.356 1.356 0 0 0-1.732-.246c-.562.35-.837 1.065-.668 1.742.168.677.736 1.148 1.386 1.148h3.463l-2.45-2.644Z'
          fill='#FFF'
        />
      </g>
    </SvgContainer>
  );
}

export default GiftCard;