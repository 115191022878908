/**
 * The InputField_New component is used for textual user data input on forms. The type of field can be one of &#x27;text&#x27;, &#x27;number&#x27;, &#x27;password&#x27;, &#x27;email&#x27;, &#x27;tel&#x27; or &#x27;date&#x27;. It also accepts several properties to set values on tag attributes, enhance usability, and for ADA compliance.
 *
 * @module views/Atoms/InputField_New
 * @memberof -Common
 */
import './InputField_New.scss';

import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { ErrorMessage, useField } from 'formik';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Icon from '@ulta/core/components/Icon/Icon';
import InlineMessage from '@ulta/core/components/InlineMessage/InlineMessage';
import Text from '@ulta/core/components/Text/Text';
import { useInputId } from '@ulta/core/hooks/useInputId/useInputId';
import { UseInputMasker } from '@ulta/core/hooks/useInputMasker/useInputMasker';
import UseKeyPress from '@ulta/core/hooks/useKeyPress/UseKeyPress';
import useOutsideClick from '@ulta/core/hooks/useOutsideClick/useOutsideClick';
import { constants } from '@ulta/core/utils/constants/constants';

import * as utils from './InputField_New';

const { TAB_KEY } = constants;

/**
   * Represents a InputField_New component
   *
   * @method
   * @param {InputField_NewProps} props - React properties passed from composition
   * @returns InputField_New
   */
export const InputField_New = function( props ){
  const {
    showValueText,
    hideValueText,
    warningMessage,
    helpText,
    showValidationMessage,
    formatter,
    disabled,
    label,
    newValue,
    placeholder,
    autoComplete,
    autoCapitalize,
    autoCorrect,
    spellCheck,
    tabIndex,
    type,
    maxLength,
    clearValueText,
    required,
    displayCheck,
    autoFocus
  } = props;

  const [field, meta, helpers] = useField( props );
  const [inputId] = useInputId( { name: field?.name } );
  const inputEl = useRef( );
  const [isActive, setIsActive] = useState( false );
  const [isWarning, setIsWarning] = useState( false );
  const [showMaskedValue, setShowMaskedValue] = useState( false );
  const [showLabelText, setShowLabelText] = useState( showValueText );
  const [isFocused, setIsFocused] = useState( false );
  const fieldRef = useRef( );
  const outerRef = useRef( );
  const errorId = `${field.name}-error`;
  const helperId = `${field.name}-helper`;
  const warningId = `${field.name}-warning`;
  const displayCheckIcon = field.value && !meta.error && !isFocused && displayCheck;

  let ariaId = null;
  if( meta.touched && meta.error ){
    ariaId = errorId;
  }
  else if( warningMessage ){
    ariaId = warningId;
  }
  else if( helpText ){
    ariaId = helperId;
  }

  useEffect( () => {
    if( showValidationMessage ){
      if( warningMessage && !isActive ){
        setIsWarning( true );
      }
    }
  }, [warningMessage, showValidationMessage, isActive] );

  useOutsideClick( { watcher: isFocused, el: outerRef.current }, { handleClick: () => {
    utils.handleFocusAway( { setIsActive, setIsFocused } );
  } } );

  const handleFocus = () => {
    setIsActive( true );
    setIsFocused( true );
    if( props.handleFocus ){
      props.handleFocus();
    }
  };

  const handleChange = event => {
    setIsActive( true );
    if( isWarning ){
      setIsWarning( false );
    }

    if( formatter ){
      let maskedValue = UseInputMasker( event.target.value, formatter );
      helpers.setValue( maskedValue );
      event.target.value = maskedValue; // eslint-disable-line
    }

    field.onChange( event );
    props.onChange && props.onChange( event.target.value );
  };

  const handleBlur = ( e ) => {
    if( field.value === '' ){
      setIsFocused( false );
    }
    field.onBlur( e );
    if( props.handleBlur ){
      props.handleBlur( e );
    }
  };

  const handlePaste = ( e ) => {
    if( props.disablePaste ){
      e.preventDefault();
      return false;
    }
  };

  const handleResetVal = () => {
    helpers.setValue( '' );
    inputEl.current.focus();
  };

  UseKeyPress( [TAB_KEY], fieldRef, () => utils.handleFocusAway( { setIsActive, setIsFocused } ), [TAB_KEY] );
  return (
    <div
      ref={ outerRef }
      className={
        classNames(
          'InputField_New', {
            'InputField_New--error': !isActive && meta.touched && meta.error && !disabled,
            'InputField_New--disable': disabled
          }
        )
      }
    >
      <div
        className={ 'InputField_New__label' }
      >
        <label
          htmlFor={ inputId }
          className='InputField_New__label--text'
        >
          { props.label }
        </label>
      </div>
      <div
        className={
          classNames(
            'InputField_New__content', {
              'InputField_New__content--active': isActive || ( !isActive && ( field.value !== '' ) ),
              'InputField_New__content--focused': isFocused,
              'InputField_New--error': ( !isFocused && meta.touched && meta.error && !disabled )
            }
          )
        }
      >

        <div className='InputField_New__formControls'
          ref={ outerRef }
        >

          <input
            { ...field }
            className={
              classNames(
                'InputField_New__input', {
                  'InputField_New--capitalize': autoCapitalize
                },
                {
                  'InputField_New__input--active': isActive || ( !isActive && ( field.value !== '' ) )
                }
              )
            }
            { ...( props.onClick && { onClick: props.onClick } ) }
            value={ disabled ? ( newValue ? newValue : field.value ) : field.value }
            placeholder={ placeholder }
            ref={ inputEl }
            id={ inputId }
            name={ field.name }
            autoComplete={ autoComplete }
            autoCorrect={ autoCorrect }
            aria-label={ label }
            spellCheck={ spellCheck }
            tabIndex={ tabIndex }
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={ ( autoFocus && { autoFocus } ) }
            onFocus={ handleFocus }
            onBlur={ handleBlur }
            onPaste={ handlePaste }
            onChange={ handleChange }
            type={ type }
            { ...( maxLength && { maxLength: maxLength } ) }
            { ...( required && { 'required': required } ) }
            { ...( meta.touched && meta.error && { 'aria-invalid': 'true' } ) }
            { ...( ariaId && { 'aria-describedby':ariaId } ) }
          />

          <div
            className={
              classNames( 'InputField_New__actions', {
              } )
            }

          >
            { field.value && isFocused &&
              <Button
                type='button'
                ariaLabel={ clearValueText }
                iconImage={ 'X' }
                icon={ true }
                iconSize={ 'xl' }
                ariaHiddenIcon={ true }
                onClick={ handleResetVal }
                ref={ fieldRef }
                className='InputField-ds__clearInputButton'
              />
            }
            { displayCheck &&
              <Icon
                className={ 'InputField_New__Action--valid' }
                size={ 'xl' }
                name={ 'Check' }
                title={ 'Check' }
              />
            }

            { field.value && isFocused && type === 'password' &&
              <span className='InputField_New__Divider' />
            }

            { field.value && type === 'password' &&
              <Button
                type='button'
                ariaLabel={ showLabelText }
                iconImage={ !showMaskedValue ? 'show' : 'hide' } // TODO - update once proper icons are available
                icon={ true }
                iconSize={ 'lg' }
                onClick={ ( e ) => utils.handleShowHideClick( { setShowLabelText, setShowMaskedValue }, { inputEl, hideValueText, showValueText } )( { e } ) }
              />
            }

          </div>

        </div>
      </div>

      { ( () => {
        if( warningMessage ){
          return (
            <ErrorMessage
              name={ field.name }
              render={ () => (
                <InlineMessage messageType='warning'
                  message={ warningMessage }
                  id={ warningId }
                  role='alert'
                />
              ) }
            />
          );
        }
        else if( meta.touched && meta.error && !disabled ){
          return (
            <ErrorMessage
              name={ field.name }
              render={ msg => {
                return (
                  <InlineMessage messageType='error'
                    message={ msg }
                    id={ errorId }
                    dataTestId={ errorId }
                    role='alert'
                  />
                );
              } }
            />

          );
        }
        else if( helpText ){
          return (
            <div id={ helperId }
              className='InputField_New__helpText'
            >
              <Text
                textStyle='body-3'
                htmlTag='span'
                color='neutral-600'
              >
                { helpText }
              </Text>
            </div>
          );

        }
      } )() }
    </div>
  );
};

/**
 * Shows and Hides the Password
 * @param {object} methods
 * @param {function} methods.setShowLabelText
 * @param {function} methods.setShowMaskedValue
 * @param {object} data
 * @param {string} data.showValueText
 * @param {string} data.hideValueText
 */
export const handleShowHideClick = ( methods, data ) => ( eventData )=>{
  const { inputEl, hideValueText, showValueText } = data || {};
  const { setShowLabelText, setShowMaskedValue } = methods || {};
  const { e } = eventData || {};

  if( !e || !inputEl || !setShowLabelText || !setShowMaskedValue ){
    return;
  }
  e.preventDefault();

  if( inputEl.current.type === 'password' ){
    inputEl.current.type = 'text';
    setShowMaskedValue( true );
    setShowLabelText( hideValueText );
  }
  else {
    inputEl.current.type = 'password';
    setShowMaskedValue( false );
    setShowLabelText( showValueText );
  }
};

/**
 * @method
 * @param {object} methods Methods
 * @param {function} methods.setIsActive
 * @param {function} methods.setIsFocused
 */
export const handleFocusAway = ( methods ) => {
  const { setIsFocused, setIsActive } = methods || {};

  if( !setIsActive || !setIsFocused ){
    return;
  }

  setIsFocused( false );
  setIsActive( false );
};

/**
 * Property type definitions
 * @typedef InputField_NewProps
 * @type {object}
 * @property {string} type - Specifies the HTML5 input field type (currently one of 'text', 'number', 'password', 'email', 'tel', 'date')
 * @property {string} label - The value used for the input field's label tag above the input field
 * @property {number} tabIndex - The tabindex attribute value for the input field
 * @property {string} placeholder - The input field placeholder attribute value
 * @property {number} maxLength - Sets the maximum length attribute value for the input field
 * @property {string} autoComplete - Sets the input field's autocomplete attribute value
 * @property {string} autoCorrect - Sets the input field's autocorrect attribute value
 * @property {string} autoCapitalize - Sets the input field's autocapitalize attribute value
 * @property {boolean} spellCheck - Sets the spellcheck attribute, which defines whether the input may be checked for spelling errors.
 * @property {object} formatter - Object with the 'pattern' key and input's format value
 * @property {boolean} disablePaste - Flag to disable pasting a value into the field
 * @property {boolean} showValidationMessage - Flag to show or hide the InlineMessage component
 * @property {string} warningMessage - Field-level warning messaging (used with the InlineMessage component)
 * @property {string} helpText - Help text displayed below the input field
 * @property {string} showValueText - Text for 'SHOW' to display masked input
 * @property {string} hideValueText - Text for 'HIDE' to mask input
 * @property {string} clearValueText - AriaLabel text for the input field's 'Clear' button (reset)
 * @property {boolean} disabled - Flag to disable input field
 * @property {boolean} required - Flag to mark a field as required
 * @property {boolean} displayCheck - Flag to display check icon
 * @property {boolean} autoFocus - Flag to focus the input by default
 */
export const propTypes = {
  /** Specifies the HTML5 input field type (currently one of 'text', 'number', 'password', 'email', 'tel', 'date') to render */
  type: PropTypes.oneOf( ['text', 'number', 'password', 'email', 'tel', 'date'] ),
  /** Label of the input to render over the inputbox */
  label: PropTypes.string,
  /** The tabindex attribute value for the input field */
  tabIndex: PropTypes.number,
  /** The input field placeholder attribute value */
  placeholder: PropTypes.string,
  /** Sets the maximum length attribute value for the input field  */
  maxLength: PropTypes.number,
  /** Sets the input field's autocomplete attribute valuet*/
  autoComplete: PropTypes.string,
  /** Sets the input field's autocorrect attribute value */
  autoCorrect: PropTypes.string,
  /** Sets the input field's autocapitalize attribute value */
  autoCapitalize: PropTypes.string,
  /** Flag to sets the spellcheck attribute, which defines whether the input may be checked for spelling errors. */
  spellCheck: PropTypes.bool,
  /** The object with the 'pattern' key and input's format value if needed of the input  */
  formatter: PropTypes.object,
  /** Flag to disable pasting a value into the field */
  disablePaste: PropTypes.bool,
  /** Flag to show or hide the InlineMessage component */
  showValidationMessage: PropTypes.bool,
  /** Field-level warning messaging (used with the InlineMessage component) */
  warningMessage: PropTypes.string,
  /** Instructional text displayed below the input field */
  helpText: PropTypes.string,
  /** Text for 'SHOW' to display masked input */
  showValueText: PropTypes.string,
  /** Text for 'HIDE' to mask input */
  hideValueText: PropTypes.string,
  /** AriaLabel text for the input field's 'Clear' button (reset) */
  clearValueText: PropTypes.string,
  /** Flag to disable input field  */
  disabled: PropTypes.bool,
  /** Flag to mark a field as required */
  required: PropTypes.bool,
  /** Flag to display check icon */
  displayCheck: PropTypes.bool,
  /** Flag to focus the input by default */
  autoFocus: PropTypes.bool
};

/**
 * Default values for passed properties
 * @type {object}
 * @property {string} type='text' - The input field type, defaults to 'text'
 * @property {string} autoComplete='on' - The input field autocomplete attribute, default is 'on'
 * @property {string} autoCorrect='on' - The input field autocorrect attribute, default is 'on'
 * @property {boolean} spellCheck=true - Flag to trigger spellcheck in the field, default is 'true'
 * @property {boolean} showValidationMessage=true - Flag to show or hide the InlineMessage component, default is 'true'
 * @property {string} showValueText='' - Text to display in a masked input field, default is empty string
 * @property {string} hideValueText='' - Text to display in a masked input field when unmasked, default is empty string
 * @property {string} clearValueText='' - Tooltip text to display for an input field's reset button, default is empty string
 * @property {string} autoFocus=false - Flag to focus the inputfield by default on mount, default is false
 */
export const defaultProps = {
  name: 'name',
  type: 'text',
  autoComplete: 'on',
  autoCorrect: 'on',
  spellCheck: true,
  showValidationMessage: true,
  showValueText: 'show',
  hideValueText: 'hide',
  clearValueText: 'Clear',
  displayCheck: false,
  autoFocus: false
};

InputField_New.propTypes = propTypes;
InputField_New.defaultProps = defaultProps;

export default InputField_New;