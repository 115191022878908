import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16FsaHsaEligible(props) {
  return (
    <SvgContainer {...props} width={16} height={16} fill="none">
      <g id="FsaHsaEligible">
        <path
          d="M17.25 9C17.25 8.80109 17.171 8.61032 17.0303 8.46967C16.8897 8.32902 16.6989 8.25 16.5 8.25H14.25V6C14.25 5.80109 14.171 5.61032 14.0303 5.46967C13.8897 5.32902 13.6989 5.25 13.5 5.25H10.5C10.3011 5.25 10.1103 5.32902 9.96967 5.46967C9.82902 5.61032 9.75 5.80109 9.75 6V8.25H7.5C7.30109 8.25 7.11032 8.32902 6.96967 8.46967C6.82902 8.61032 6.75 8.80109 6.75 9V12C6.75 12.1989 6.82902 12.3897 6.96967 12.5303C7.11032 12.671 7.30109 12.75 7.5 12.75H9.75V15C9.75 15.1989 9.82902 15.3897 9.96967 15.5303C10.1103 15.671 10.3011 15.75 10.5 15.75H13.5C13.6989 15.75 13.8897 15.671 14.0303 15.5303C14.171 15.3897 14.25 15.1989 14.25 15V12.75H16.5C16.6989 12.75 16.8897 12.671 17.0303 12.5303C17.171 12.3897 17.25 12.1989 17.25 12V9Z"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.25004 3.92296V11.537C2.25006 13.9436 2.97936 16.2937 4.3418 18.2776C5.70424 20.2615 7.63586 21.7859 9.88204 22.65L10.923 23.05C11.6163 23.3166 12.3838 23.3166 13.077 23.05L14.118 22.65C16.3642 21.7859 18.2958 20.2615 19.6583 18.2776C21.0207 16.2937 21.75 13.9436 21.75 11.537V3.92296C21.7521 3.63641 21.6712 3.35539 21.5172 3.11374C21.3632 2.87209 21.1426 2.68012 20.882 2.56096C18.0819 1.33875 15.0552 0.721617 12 0.749957C8.94489 0.721617 5.9182 1.33875 3.11804 2.56096C2.85744 2.68012 2.63685 2.87209 2.48285 3.11374C2.32885 3.35539 2.248 3.63641 2.25004 3.92296Z"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />{' '}
      </g>
    </SvgContainer>
  );
}

export default Svg16FsaHsaEligible;
