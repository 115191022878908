import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function AfterpayButton( props ){
  return (
    <SvgContainer enableBackground='new 0 0 1200 180'
      viewBox='0 0 1200 180'
      width='328'
      height='56'
      { ...props }
    >
      <path d='m1169.8 180h-1139.6c-16.6 0-30.2-13.6-30.2-30.2v-119.6c0-16.6 13.6-30.2 30.2-30.2h1139.6c16.6 0 30.2 13.6 30.2 30.2v119.6c0 16.6-13.6 30.2-30.2 30.2z'
        fill='#b2fce4'
      />
      <g enableBackground='new'>
        <path d='m161.2 107v-43.6h19.6c9.5 0 15.6 5 15.6 13.6 0 8.7-6.8 14-15.9 14h-10.8v16zm19.1-22.9c4.5 0 7.5-3.1 7.5-7 0-3.7-3-6.5-7.4-6.5h-10.7v13.6h10.6z'/>
        <path d='m223.6 107v-4.2c-2.4 2.9-5.9 4.7-10.1 4.7-8.8 0-15.4-7-15.4-16.5 0-9.4 6.9-16.6 15.6-16.6 4.1 0 7.6 1.8 9.9 4.6v-4h7.9v32zm0-16c0-5.4-3.9-9.2-8.7-9.2s-8.7 3.8-8.7 9.2c0 5.2 3.9 9.2 8.7 9.2s8.7-3.9 8.7-9.2z'/>
        <path d='m239.1 118.7v-7h2.6c2.2 0 4.3-1.1 4.9-4.4.2-1 .1-1.5-.3-2.5l-11.8-29.8h8.3l8.5 22.1 8.3-22.1h8.1l-11.9 30c-3.9 9.9-5 13.8-14.3 13.8h-2.4z'/>
        <path d='m301.9 107v-19.9c0-3.5-2.4-5.4-5.6-5.4-3.3 0-5.9 2.1-5.9 5.4v20h-8.1v-32.1h7.9v4c1.2-1.9 4.1-4.6 8.9-4.6 6.8 0 10.9 4.4 10.9 11.2v21.4z'/>
        <path d='m331.7 107.5c-9.5 0-16.8-7.1-16.8-16.5s7.2-16.6 16.8-16.6c9.5 0 16.8 7.2 16.8 16.6-.1 9.4-7.4 16.5-16.8 16.5zm8.6-16.5c0-5.2-3.9-9.1-8.6-9.1s-8.7 3.9-8.7 9.1 4 9.1 8.7 9.1 8.6-4 8.6-9.1z'/>
        <path d='m380.1 107-6.6-19.8h-.2l-6.8 19.8h-7.4l-9.5-32h8.4l6 20.7h.1l6.8-20.7h5.7l6.5 20.9h.1l6.2-20.9h8.2l-10 32z'/>
        <path d='m440.6 107-6.6-19.8h-.2l-6.8 19.8h-7.4l-9.5-32h8.4l6 20.7h.1l6.8-20.7h5.7l6.5 20.9h.1l6.2-20.9h8.2l-10 32z'/>
        <path d='m461.5 66.4c0-2.4 2.1-4.6 4.6-4.6s4.6 2.2 4.6 4.6c0 2.5-2.1 4.6-4.6 4.6s-4.6-2.1-4.6-4.6zm.5 40.6v-32h8.1v32z'/>
        <path d='m487.4 107c-6.5 0-9-2.8-9-9.3v-15.8h-4.2v-7h4.2v-7.8h8.1v7.9h5.1v7h-5.1v14.2c0 2.7.8 3.7 3.5 3.7h1.6v7.1z'/>
        <path d='m515.7 107v-19.9c0-3.5-2.4-5.4-5.6-5.4-3.3 0-5.9 2.1-5.9 5.4v20h-8.1v-43.7h8.1v15.2c1.3-1.9 4.1-4.2 8.6-4.2 6.8 0 10.9 4.4 10.9 11.2v21.4z'/>
      </g>
      <path d='m1035.2 61-14.9-8.5-15.1-8.6c-10-5.7-22.4 1.5-22.4 13v1.9c0 1.1.6 2 1.5 2.6l7 4c1.9 1.1 4.4-.3 4.4-2.5v-4.6c0-2.3 2.5-3.7 4.4-2.6l13.8 7.9 13.7 7.9c2 1.1 2 4 0 5.1l-13.7 7.9-13.8 7.9c-2 1.1-4.4-.3-4.4-2.6v-2.3c0-11.5-12.5-18.7-22.4-13l-15.1 8.6-14.9 8.5c-10 5.8-10 20.2 0 26l14.9 8.5 15.1 8.6c10 5.7 22.4-1.5 22.4-13v-1.9c0-1.1-.6-2-1.5-2.6l-7-4c-1.9-1.1-4.4.3-4.4 2.5v4.6c0 2.3-2.5 3.7-4.4 2.6l-13.8-7.9-13.7-7.9c-2-1.1-2-4 0-5.1l13.7-7.9 13.8-7.9c2-1.1 4.4.3 4.4 2.6v2.3c0 11.5 12.5 18.7 22.4 13l15.1-8.6 14.9-8.5c10-5.8 10-20.2 0-26'/>
      <path d='m937.6 63.8-34.9 72h-14.5l13.1-26.9-20.5-45.1h14.8l13.2 30.2 14.4-30.2z'/>
      <path d='m589.3 89.4c0-8.6-6.2-14.6-13.9-14.6s-13.9 6.1-13.9 14.6c0 8.4 6.2 14.6 13.9 14.6s13.9-6 13.9-14.6m.1 25.5v-6.6c-3.8 4.6-9.4 7.5-16.1 7.5-14 0-24.6-11.2-24.6-26.4 0-15 11-26.5 24.9-26.5 6.5 0 12.1 2.9 15.8 7.4v-6.4h12.6v51.1z'/>
      <path d='m663 103.6c-4.4 0-5.6-1.6-5.6-5.9v-22.7h8.1v-11.2h-8.1v-12.4h-12.9v12.5h-16.6v-5.1c0-4.3 1.6-5.9 6.1-5.9h2.8v-9.9h-6.2c-10.6 0-15.6 3.5-15.6 14.1v6.8h-7.2v11.1h7.2v40h12.9v-40h16.6v25c0 10.4 4 14.9 14.4 14.9h6.6v-11.3z'/>
      <path d='m709.2 84.8c-.9-6.6-6.3-10.6-12.7-10.6-6.3 0-11.5 3.9-12.9 10.6zm-25.6 8c.9 7.6 6.3 11.9 13.2 11.9 5.4 0 9.6-2.6 12.1-6.6h13.2c-3.1 10.8-12.8 17.8-25.5 17.8-15.4 0-26.3-10.8-26.3-26.3 0-15.4 11.4-26.6 26.6-26.6s26.3 11.2 26.3 26.6c0 1.1-.1 2.2-.3 3.3h-39.3z'/>
      <path d='m804.9 89.4c0-8.3-6.2-14.6-13.9-14.6s-13.9 6.1-13.9 14.6c0 8.4 6.2 14.6 13.9 14.6 7.6 0 13.9-6.3 13.9-14.6m-40.5 46.4v-72h12.6v6.6c3.8-4.7 9.4-7.6 16.1-7.6 13.8 0 24.6 11.3 24.6 26.4 0 15-11 26.5-24.9 26.5-6.4 0-11.8-2.6-15.4-6.8v26.9z'/>
      <path d='m863 89.4c0-8.6-6.2-14.6-13.9-14.6s-13.9 6.1-13.9 14.6c0 8.4 6.2 14.6 13.9 14.6s13.9-6 13.9-14.6m.2 25.5v-6.6c-3.8 4.6-9.4 7.5-16.1 7.5-14 0-24.6-11.2-24.6-26.4 0-15 11-26.5 24.9-26.5 6.5 0 12.1 2.9 15.8 7.4v-6.4h12.6v51.1z'/>
      <path d='m741.8 68.8s3.2-5.9 11-5.9c3.4 0 5.5 1.2 5.5 1.2v13s-4.7-2.9-9.1-2.3c-4.3.6-7.1 4.6-7.1 9.9v30.2h-13v-51.1h12.6z'/>
    </SvgContainer>
  );
}
export default AfterpayButton;