import React, { createContext, useContext, useEffect, useState } from 'react';

import { handleScrollToTop } from '@ulta/core/utils/animation/animation';

export const initialState = {
  viewType: 'default'
};

/**
 * Represents a ConditionalViewProvider component
 *
 * @method
 * @param {object} props - React properties passed from composition
 * @returns ConditionalViewProvider
 */
export const ConditionalViewProvider = ( { children, setConditonalViewType } ) => {
  const [viewType, setViewType] = useState( initialState.viewType );

  useEffect( ()=> {
    setConditonalViewType( viewType );
    handleScrollToTop();
  }, [viewType] );

  return (
    <ConditionalViewContext.Provider value={ { viewType, setViewType } }>
      { children }
    </ConditionalViewContext.Provider>
  );
};

/**
 * context provider
 * @type object
 */
export const ConditionalViewContext = createContext( initialState );

export const useConditionalView = () => useContext( ConditionalViewContext );