import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12StarFilled( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <path
        d='M5.999 1c.136 0 .262.076.324.198L7.81 4.144l2.862.284a.358.358 0 01.222.61L8.54 7.373l.873 3.172a.361.361 0 01-.31.452l-.051.002a.36.36 0 01-.15-.039L5.998 9.524 3.1 10.959a.361.361 0 01-.099.033l-.05.005a.361.361 0 01-.362-.454l.873-3.172-2.357-2.335a.358.358 0 01.222-.61l2.862-.284 1.485-2.944A.364.364 0 015.999 1z'
        fill='currentColor'
        fillRule='nonzero'
      />
    </SvgContainer>
  );
}

export default Svg12StarFilled;
