/**
 * React hook to track the visibility of a functional component based on IntersectionVisible Observer. This is used for lazy loading of the image component.
 *
 * @module utils/IntersectionProcessor
 */

import { constants } from '../constants/constants';
import datacapture from '../datacapture/datacapture';

/**
 * Call back method to handle the intersection events
 * @param  {Object} data
 */
export const handleIntersection = ( data ) => {
  return ( elem )=>{
    intersectionProcessor.processIntersection( data, elem );
  };
};

/**
 * Call back method to handle the intersection events for sticky components
 * @param  {Function} handleIntersection
 * @param  {Object} data
 */
export const handleIntersectionForStickyComponents = ( handleIntersection, elem ) => {
  handleIntersection( elem );
};

/**
 * Call back method to set the dataCaptureEventTriggered in the element node to true, once the tealium event is triggered
 * @param  { Object } elem
 * @param  { Array } eventTriggeredItemIndexList
 */
export const postIntersectionEvent = ( elem, eventTriggeredItemIndexList ) => {
  return ( )=>{
    if( eventTriggeredItemIndexList ){
      elem.eventTriggeredItemIndexList = eventTriggeredItemIndexList ; // list of item index whose impression event is triggered
      elem.railImpressionEventTriggered = true ; // flag to indicate the rail impression event is triggered
    }
    else {
      elem.dataCaptureEventTriggered = true;
    }

  };
};


/**
 * Method invokes processEvents to trigger the datacatpure event to tealium
 * @param  {Object} data
 * @param  {Object} elem
 * @param  {Array} eventTriggeredItemIndexList
 *@param  {Object} additionalEventAttributes
 */
export const processIntersection = ( data, elem, eventTriggeredItemIndexList, additionalEventAttributes = {} ) => {

  const isViewPort = data.dataCapture?.clientEvent?.toLowerCase() === constants.DATACAPTURE_EVENT_TYPE.viewport ;
  // datacapture impression event needs to be triggered only once if impression eventype is viewport
  // eventTriggeredItemIndexList is passed in rail interaction event to ensure that item level impression event is triggered only once
  const callbackfunction = ( data.dataCapture && isViewPort ) || ( !isViewPort && eventTriggeredItemIndexList ) ? intersectionProcessor.postIntersectionEvent( elem, eventTriggeredItemIndexList ) : null;
  if( !callbackfunction || ( callbackfunction && !elem.dataCaptureEventTriggered ) ){
    datacapture.processEvents( data, constants.DATACAPTURE_EVENT_TYPE.viewport, callbackfunction, additionalEventAttributes ) ;
  }
};

const intersectionProcessor = {
  processIntersection,
  handleIntersection,
  postIntersectionEvent,
  handleIntersectionForStickyComponents
};

export default intersectionProcessor;