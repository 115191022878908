import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function SvgAmericanExpress( props ){

  return (
    <SvgContainer
      { ...props }
      width={ 50 }
      height={ 50 }
      viewBox='0 0 32 20'
    >
      <g fill='none'
        fillRule='evenodd'
        stroke='none'
        strokeWidth='1'
      >
        <g fill='#0071CE'
          fillRule='nonzero'
          transform='translate(-373)'
        >
          <g transform='translate(261 .498)'>
            <g transform='translate(112.833)'>
              <g>
                <g>
                  <path d='M29.019 17.813l-1.405-1.546-1.46 1.546h-9.023v-7.2h-2.865l3.567-8.053h3.458l1.243 2.773V2.56h4.323l.757 2.08.756-2.08h3.297V1.333A1.33 1.33 0 0030.316 0H1.35A1.33 1.33 0 000 1.333v17.334A1.33 1.33 0 001.351 20h28.965a1.33 1.33 0 001.35-1.333v-.854H29.02z'></path>
                  <g transform='translate(15.671 3.52)'>
                    <path d='M13.7258248 13.44L15.9954494 13.44 13.023322 10.2933333 15.9954494 7.2 13.7798635 7.2 11.8885097 9.22666667 10.0511945 7.2 7.78156997 7.2 10.8077361 10.3466667 7.78156997 13.44 9.99715586 13.44 11.8885097 11.4133333z'></path>
                    <path d='M4.16097838 12L4.16097838 11.04 7.72753129 11.04 7.72753129 9.6 4.16097838 9.6 4.16097838 8.64 7.78156997 8.64 7.78156997 7.2 2.43174061 7.2 2.43174061 13.44 7.78156997 13.44 7.78156997 12z'></path>
                    <path d='M14.3202503 6.29333333L15.9414107 6.29333333 15.9414107 0 13.347554 0.0533333333 11.9425484 3.94666667 10.483504 0.0533333333 7.78156997 0.0533333333 7.78156997 6.29333333 9.51080774 6.29333333 9.51080774 1.92 11.1319681 6.29333333 12.6450512 6.29333333 14.3202503 1.92z'></path>
                    <path d='M5.026.053H2.81L0 6.293h1.891l.54-1.226H5.35l.54 1.226h1.946L5.026.053zm-2 3.574L3.891 1.6l.864 2.027H3.026z'></path>
                  </g>
                </g>
                <path d='M30 13.3333333L31.6666667 15 31.6666667 11.6666667z'></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}

export default SvgAmericanExpress;
