import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16DotActive( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <circle
        stroke='currentColor'
        fill='currentColor'
        fillRule='nonzero'
        cx={ 8 }
        cy={ 8 }
        r={ 6 }
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg16DotActive;
