/**
 * The Router component exists to handle the switching between the ReactRouter Browser Router and Static Router based on the environment being eithe a server side rendering environment or browser based.
 *
 * @module views/Navigation/Router
 */
import React from 'react';
import { BrowserRouter, StaticRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

/**
 * Represents a Router component
 *
 * @method
 * @param { Object } props - React properties passed from composition
 * @returns Router
 */
export const Router = function( props ){
  const { isServer, children, location } = props;
  const RouterComponent = isServer ? StaticRouter : BrowserRouter;
  return <RouterComponent { ...( isServer && location && { location } ) }>{ children }</RouterComponent>;
};

/**
 * property type definitions
 * @type object
 * @property { Boolean } isServer - server side rendering flag for setting a path for rout rendering
 */
export const propTypes = {
  isServer: PropTypes.bool
};

Router.propTypes = propTypes;

export default Router;