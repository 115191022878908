import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24Wallet( props ){

  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <defs>
        <filter id='filter-1'>
          <feColorMatrix in='SourceGraphic'
            type='matrix'
            values='0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0'
          />
        </filter>
      </defs>
      <g id='01-Token/Icon/Interface/Wallet'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g filter='url(#filter-1)'
          id='money-wallet-open'
        >
          <g transform='translate(1.000000, 1.000000)'>
            <path d='M13.455,21.884 C13.198,21.884 12.943,21.849 12.696,21.779 L3.655,19.32 C1.803,18.745 0.541,17.072 0.502,15.155 L0.502,3.687 C0.482,2.824 0.79,1.982 1.37,1.321 C1.399,1.282 1.432,1.246 1.468,1.214 C2.11,0.539 2.973,0.153 3.899,0.125 L17.749,0.125 C19.817,0.125 21.499,1.807 21.499,3.875 L21.499,12.828 C21.49,13.855 21.084,14.812 20.355,15.528 C19.636,16.235 18.684,16.624 17.676,16.624 L16.25,16.624 L16.25,18.857 C16.309,19.589 16.084,20.302 15.615,20.869 C15.138,21.445 14.465,21.801 13.719,21.871 C13.631,21.88 13.543,21.884 13.455,21.884 Z M2.265,2.634 C2.084,2.949 1.993,3.303 2.001,3.671 L2.001,15.14 C2.027,16.399 2.86,17.503 4.074,17.88 L13.096,20.333 C13.216,20.367 13.336,20.384 13.456,20.384 C13.497,20.384 13.538,20.382 13.579,20.378 C13.925,20.346 14.237,20.18 14.459,19.912 C14.681,19.644 14.785,19.306 14.753,18.96 C14.751,18.939 14.75,18.915 14.75,18.89 L14.75,8.38 C14.723,7.115 13.889,5.992 12.674,5.593 L2.265,2.634 Z M17.64,15.125 C18.277,15.125 18.868,14.888 19.305,14.459 C19.748,14.024 19.994,13.443 20,12.822 L20,9.125 L16.25,9.125 L16.25,15.125 L17.64,15.125 Z M20,7.625 L20,3.875 C20,2.634 18.991,1.625 17.75,1.625 L4.199,1.625 L13.112,4.159 C14.701,4.68 15.866,6.019 16.17,7.625 L20,7.625 Z'
              id='Shape'
              fill='#000000'
              fillRule='nonzero'
            />
            <circle id='Oval'
              fill='#000000'
              fillRule='nonzero'
              cx='11.375'
              cy='12.5'
              r='1.125'
            />
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}

export default Svg24Wallet;
