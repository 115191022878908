import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24Instagram( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <path
        d='M14.829 6.302c-.738-.034-.96-.04-2.829-.04-1.869 0-2.09.007-2.828.04-1.899.087-2.783.986-2.87 2.87-.033.738-.041.959-.041 2.828 0 1.869.008 2.09.041 2.829.087 1.879.967 2.783 2.87 2.87.737.033.959.041 2.828.041 1.87 0 2.091-.007 2.829-.041 1.899-.086 2.782-.988 2.87-2.87.033-.738.04-.96.04-2.829 0-1.869-.007-2.09-.04-2.828-.088-1.883-.973-2.783-2.87-2.87zM12 15.595a3.595 3.595 0 110-7.19 3.595 3.595 0 010 7.19zm3.737-6.491a.84.84 0 110-1.68.84.84 0 010 1.68zM14.333 12a2.333 2.333 0 11-4.666 0 2.333 2.333 0 014.666 0zM12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm6.958 14.886c-.115 2.545-1.532 3.955-4.071 4.072-.747.034-.986.042-2.887.042s-2.139-.008-2.886-.042c-2.544-.117-3.955-1.529-4.072-4.072C5.008 14.14 5 13.901 5 12c0-1.901.008-2.139.042-2.886.117-2.544 1.529-3.955 4.072-4.071C9.861 5.008 10.099 5 12 5c1.901 0 2.14.008 2.887.043 2.545.117 3.957 1.532 4.071 4.071.034.747.042.985.042 2.886 0 1.901-.008 2.14-.042 2.886z'
        fill='currentColor'
        fillRule='nonzero'
      />
    </SvgContainer>
  );
}

export default Svg24Instagram;
