/**
 * A custom loading hook for showing loader
 *
 * @module utils/useLoader
 * @memberof -Common
 */
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import PropTypes from 'prop-types';

import { isServer } from '@ulta/core/utils/device_detection/device_detection';

import LoaderCircular from '../../components/LoaderCircular/LoaderCircular';

/**
 * Represents a useLoader component
 *
 * @method
 * @param {object} props - React properties passed from composition
 * @returns useLoader
 */
export const useLoader = ( data ) => {
  const { loading: componentLoadingState, isPageLoader, color, delay = 0 } = data || {};
  const loaderTimeout = useRef();

  const [localLoadingState, setLocalLoadingState] = useState( componentLoadingState );

  // TODO: This business logic needs to go in a helper and be tested
  useEffect( () => {
    handleLoadingStates( { componentLoadingState, localLoadingState, loaderTimeout, delay }, { setLocalLoadingState } );

    return () => {
      clearTimeout( loaderTimeout.current );
    };
  }, [componentLoadingState] );

  const component = !isServer() && isPageLoader ?
    createPortal(
      <LoaderCircular
        color={ color }
        isFixed={ true }
      />,
      document.body
    ) :
    <LoaderCircular color={ color }/>;

  return [
    localLoadingState ? component : null,
    () => setLocalLoadingState( true ),
    () => setLocalLoadingState( false )
  ];
};

/**
 * handleLoadingStates function for updating loading state based on componentLoadingState
 * @method
 * @param {Object} data - Passing states and timer as an argument
 * @param {Object} methods - Passing setState method as an argument
 */
export const handleLoadingStates = ( data, methods ) => {
  const { componentLoadingState, loaderTimeout, localLoadingState, delay } = data;
  const { setLocalLoadingState } = methods;

  // Set loading state back to false immediately
  if( !componentLoadingState ){
    setLocalLoadingState( false );
  }

  clearTimeout( loaderTimeout.current );

  // Delays showing the loader if the response is fast enough
  loaderTimeout.current = setTimeout( () => {
    if( !!localLoadingState === !!componentLoadingState ){
      return;
    }

    setLocalLoadingState( !!componentLoadingState );
  }, delay );
};

export const UX_LOADER_DISPLAY_DELAY = 350;

/**
 * Property type definitions
 *
 * @type {object}
 * @property {string} color - sets the color to the loader
 * @property {bool} loading - sets the loading state
 * @property {string} delay - sets the delay to update loading state
 */
export const propTypes =  {
  color: PropTypes.string,
  loading: PropTypes.bool,
  delay: PropTypes.number
};

/**
 * Default values for passed properties
 *
 * @type {object}
 * @property {string} [color='orange-400'] - sets the color to the loader
 */
export const defaultProps =  {
  color: 'orange-400'
};

useLoader.propTypes = propTypes;
useLoader.defaultProps = defaultProps;

export default useLoader;
