import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function SvgApplePayIconDefault( props ){

  return (
    <SvgContainer
      width='58.45px'
      height='24px'
      viewBox='0 0 58.45 24'
      { ...props }
    >
      <g id='ApplePay_Icon_Default'>
        <path
          d='M9.89,16.19a2,2,0,0,1-1.57.74,2.27,2.27,0,0,1,.56-1.63,2.35,2.35,0,0,1,1.56-.8,2.32,2.32,0,0,1-.55,1.69m.55.86c-.87-.05-1.61.49-2,.49s-1-.46-1.74-.45A2.58,2.58,0,0,0,4.5,18.41c-.93,1.61-.24,4,.67,5.31.44.64,1,1.36,1.67,1.33s.92-.43,1.72-.43,1,.43,1.73.42,1.18-.65,1.63-1.3a5.74,5.74,0,0,0,.72-1.49,2.34,2.34,0,0,1-1.41-2.14,2.4,2.4,0,0,1,1.14-2,2.5,2.5,0,0,0-1.93-1.05m5-1.81V25H17V21.64h2.09a3.08,3.08,0,0,0,3.25-3.2,3.06,3.06,0,0,0-3.2-3.2ZM17,16.52H18.7a1.81,1.81,0,0,1,2.06,1.92,1.83,1.83,0,0,1-2.07,1.94H17ZM25.06,25a2.5,2.5,0,0,0,2.22-1.24h0V25h1.4V20.13c0-1.41-1.12-2.31-2.85-2.31S23.07,18.74,23,20h1.36a1.33,1.33,0,0,1,1.43-1c.92,0,1.44.44,1.44,1.23v.53l-1.89.12c-1.75.1-2.7.82-2.7,2.07A2.14,2.14,0,0,0,25.06,25Zm.4-1.15c-.8,0-1.31-.39-1.31-1s.49-1,1.43-1l1.68-.11v.55A1.64,1.64,0,0,1,25.46,23.89Zm5.12,3.72c1.47,0,2.16-.56,2.77-2.26L36,17.91H34.47l-1.78,5.75h0l-1.78-5.75H29.3L31.86,25l-.14.43a1.21,1.21,0,0,1-1.27,1l-.45,0v1.17A5.35,5.35,0,0,0,30.58,27.61Z'
        />
      </g>
    </SvgContainer>
  );
}

export default SvgApplePayIconDefault;