/**
 * bag icon for topbar across the portal in header
 *
 * @module views/components/BagIcon
 * @memberof -Common
 */
import './BagIcon.scss';

import React from 'react';

import PropTypes from 'prop-types';

import Icon from '@ulta/core/components/Icon/Icon';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import { useUserContext } from '@ulta/core/providers/UserContextProvider/UserContextProvider';

/**
  * Represents a BagIcon component
  *
  * @method
  * @param {BagIconProps} props - React properties passed from composition
  * @returns BagIcon
  */
export const BagIcon = React.forwardRef( ( props, _ ) => {
  const { icon, clickAction, description } = props;
  const { cart } = useUserContext();

  const cartCount = Number( cart.itemCount ) > 0 ? `${cart.itemCount} ${description}` : `0 ${description}`;

  return (
    <div
      className='BagIcon'
    >
      { icon &&
      <Link_Huge
        primary
        size='compact'
        action={ clickAction }
        ariaLabel={ cartCount }
      >
        <Icon
          size={ 'l' }
          name={ icon }
          aria-hidden={ true }
        />
        {
          Number( cart.itemCount ) > 0 &&
          <>
            <span className='BagIcon__quantity'>{ cart.itemCount }</span>
            <span className='BagIcon__notificationDot'></span>
          </>
        }

      </Link_Huge>
      }
    </div>
  );
} );

/**
  * Property type definitions
  * @typedef BagIconProps
  * @type {object}
  * @property {string} icon - sets the icon
  * @property {object} clickAction - sets the action
  * @property {string} description - content for aria label on bag icon
  */
const propTypes = {
  icon: PropTypes.string.isRequired,
  clickAction: PropTypes.object,
  description: PropTypes.string
};

BagIcon.propTypes = propTypes;
BagIcon.displayName = 'BagIcon';

export default BagIcon;
