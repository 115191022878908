import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16Bookmarks( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <g
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
        stroke='currentColor'
      >
        <path d='M1 3.5h11v12l-5.5-3.969L1 15.5z' />
        <path d='M7 3V.5h8V10l-2.678-2.301' />
      </g>
    </SvgContainer>
  );
}

export default Svg16Bookmarks;
