import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16HeartFilled( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <g id='HeartFilled16'
        transform='matrix(0.66975, 0, 0, 0.66975, -0.058891, -0.290965)'
      >
        <g id='outer'
          transform='translate(-207.000000, -1233.000000)'
        >
          <g id='Path'
            transform='translate(206.000000, 1231.000000)'
          >
            <path d='M14,5l-1,1l-1-1c-1.5-1.5-3.6-2.2-5.6-1.9C4.4,3.4,2.6,4.8,1.7,6.7L1.6,7c-1,2.4-0.6,5.3,1.1,7.2l9.7,10.5 c0.3,0.4,0.9,0.4,1.2,0l9.5-10.3c1.9-2,2.4-5.1,1.2-7.7l-0.1-0.3c-1-1.8-2.7-3-4.6-3.4C17.5,2.7,15.4,3.4,14,5z' />
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}

export default Svg16HeartFilled;
