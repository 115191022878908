import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24LocationPin( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <g id='LocationPin'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path d='M20.8050186,1.12098529 L1.9789932,8.92715052 C1.33364758,9.19510027 0.939477143,9.8530297 1.0076196,10.5480822 L1.01624953,10.6195215 C1.11144336,11.2818528 1.61381635,11.8167238 2.2749329,11.9494247 L10.4209315,13.5787579 L12.0504823,21.7245638 C12.1880237,22.4097953 12.7568739,22.9242379 13.4519269,22.9923803 C14.14698,23.0605227 14.8049099,22.6663525 15.0727155,22.0213551 L22.8789765,3.19512724 C23.1248638,2.60182499 22.9891037,1.91897158 22.5350739,1.46494207 C22.081044,1.01091256 21.3981901,0.875152596 20.8050186,1.12098529 Z M13.6065509,21.412487 L11.8736169,12.7489191 L11.8606537,12.6942184 C11.7816488,12.4065267 11.5467465,12.1855371 11.2510884,12.1263911 L2.58751409,10.3931409 L21.4127156,2.58729951 L13.6065509,21.412487 Z'
          id='Path'
          fill='#000000'
          fillRule='nonzero'
        ></path>
      </g>
    </SvgContainer>
  );
}

export default Svg24LocationPin;
