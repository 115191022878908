import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24Location( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <g
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M20.625 10c0 7-8.625 13.5-8.625 13.5S3.375 17 3.375 10C3.375 4.754 6.969.5 12 .5c5.031 0 8.625 4.254 8.625 9.5h0z' />
        <path d='M17 9A5 5 0 017 9a5 5 0 0110 0h0z' />
      </g>
    </SvgContainer>
  );
}

export default Svg24Location;
