/**
 * The vertical slot is used to render scheduled content from the Amplience definitions. The content is stacked vertically thus the name vertical.
 *
 * @module views/components/VerticalSlot
 * @memberof -Common
 */
import React from 'react';

import PropTypes from 'prop-types';

import Modules from '@ulta/core/components/Modules/Modules';
import { hasItems } from '@ulta/core/utils/array/array';

/**
 * Represents a VerticalSlot component
 *
 * @method
 * @param {VerticalSlotProps} props - React properties passed from composition
 * @returns VerticalSlot
 */
export const VerticalSlot = function( props ){
  if( !hasItems( props.modules ) ){
    return null;
  }

  return (
    <div className='VerticalSlot'>
      <Modules
        modules={ props.modules }
      />
    </div>
  );
};

/**
 * Property type definitions
 * @typedef VerticalSlotProps
 * @type {object}
 * @property {array} modules - Array of modules (components) in this slot
 */
export const propTypes =  {
  modules: PropTypes.array
};


VerticalSlot.propTypes = propTypes;

export default VerticalSlot;
