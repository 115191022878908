/**
 * This is used to scroll through element to top or specified position.
 *
 * @module utils/animation
 */

import { handleEmptyObjects } from '../handleEmptyObjects/handleEmptyObjects';
import * as utils from './animation';

// easing equations from https://github.com/danro/easing-js/blob/master/easing.js
/**
 * easeOutSine easing technique
 *
 * @method
 * @param { number } pos - element position
 * @returns { number }
 */
export const easeOutSine = ( pos ) => {
  return Math.sin( pos * ( Math.PI / 2 ) );
};

/**
 * easeInOutSine easing technique
 *
 * @method
 * @param { number } pos - element position
 * @returns { number }
 */
export const easeInOutSine = ( pos ) => {
  return ( -0.5 * ( Math.cos( Math.PI * pos ) - 1 ) );
};

/**
 * easeInOutQuint easing technique
 *
 * @method
 * @param { number } pos - element position
 * @returns { number }
 */
export const easeInOutQuint = ( pos ) => {
  let q = pos / 0.5;
  if( q < 1 ){
    return 0.5 * Math.pow( q, 5 );
  }
  return 0.5 * ( Math.pow( ( q - 2 ), 5 ) + 2 );
};

export const easingEquations = {
  easeOutSine,
  easeInOutSine,
  easeInOutQuint
};

/**
 * Scrolls window to a specified position
 *
 * @method
 * @param { number } scrollTargetY - Target position where scroll to
 * @param { string } easing - easing equations defined for smooth scrolling
 * @param { number } speed - speed defined for scrolling
 * @returns { boolean }
 */
export const scrollWindowToPosition = ( props ) => {
  const { scrollTargetY = 0, easing = 'easeOutSine', speed = 1500, focusReset = false } = handleEmptyObjects( props );

  var scrollY = scrollY || document.documentElement.scrollTop,
      currentTime = 0;

  // min time .1, max time .8 seconds
  var time = Math.max( .1, Math.min( Math.abs( scrollY - scrollTargetY ) / speed, .8 ) );

  // call tick once to get started
  utils.tick( currentTime, time, easing, scrollY, scrollTargetY );

  // reset focus after scroll to top for keyboard users
  if( focusReset ){
    const focusableEl = document.querySelector( '.AppContainer' );
    const focusable = focusableEl?.querySelectorAll( 'button:not([disabled]), a[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])' );
    focusable?.[0]?.focus();
  }
  return 1;
};

/**
 * add animation loop
 *
 * @method
 * @param { number } currentTime - current time
 * @param { number } time - min time .1, max time .8 seconds
 * @param { object } easingEquations - different easing equations
 * @param { string } easing - type of easing
 * @param { number } scrollY - scrollY property of the window
 * @param { number } scrollTargetY - the target scrollY property of the window
 * @returns { void }
 */
export function tick( currentTime, time, easing, scrollY, scrollTargetY ){
  // eslint-disable-next-line no-param-reassign
  currentTime += 1 / 60;

  var p = currentTime / time;
  var t = easingEquations[easing]( p );


  if( p <= 1 ){
    global.requestAnimationFrame( () => utils.tick( currentTime, time, easing, scrollY, scrollTargetY ) );

    // eslint-disable-next-line no-restricted-globals
    global.scrollTo( 0, scrollY + ( ( scrollTargetY - scrollY ) * t ) );
  }
  else {
    // eslint-disable-next-line no-restricted-globals
    global.scrollTo( 0, scrollTargetY );
  }
}

/**
 * Navigate to top of the page and reset focus
 *
 * @method handleScrollToTop
 * @returns { void }
 */
export const handleScrollToTop = () => {
  utils.scrollWindowToPosition( {
    scrollTargetY: 0,
    easing: 'easeInOutQuint',
    speed: 200,
    focusReset: true
  } );
};