import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16Tryon( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <g fill='none'
        fillRule='nonzero'
      >
        <g fill='currentColor'>
          <path d='M4 0H1v1h3zM1 4V0H0v4z' />
        </g>
        <g fill='currentColor'>
          <path d='M12 0h3v1h-3zM15 4V0h1v4z' />
        </g>
        <g fill='currentColor'>
          <path d='M4 16H1v-1h3zM1 12v4H0v-4z' />
        </g>
        <g fill='currentColor'>
          <path d='M12 16h3v-1h-3zM15 12v4h1v-4z' />
        </g>
        <g
          transform='translate(3.5 3.5)'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        >
          <circle cx={ 4.5 }
            cy={ 2.5 }
            r={ 2.5 }
          />
          <path d='M9 8.909C8.29 7.204 6.55 6 4.518 6 2.45 6 .686 7.247 0 9' />
        </g>
      </g>
    </SvgContainer>
  );
}

export default Svg16Tryon;
