import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16CrueltyFree( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <g id='CrueltyFree'>
        <path d='M5.7 2c1-.7 1.5-.5 1.7-.3.2.1.4.4.4.7l.7-.1c.5 0 .8.3.9.7 0 1-1.5 2.7-3.3 3.2v.5h.2c.3.1 1 0 1.6-.2 1-.2 2-.4 2.7-.3 1.2.2 2.7 1.2 3 3.5l.1.1c.6.2 1.3.7 1.3 1.6 0 .5-.2.8-.6 1a1.6 1.6 0 0 1-1.1.3c-.3.8-.7 1.4-.8 1.5v.1H7.8v-.2c-.3-.6-.2-1 0-1.4l.8-.4a5 5 0 0 1-.9-.7 9.4 9.4 0 0 1-1.5 2.6l-.1.1H4.5c-.1-.1-.4-.4-.3-.8 0-.4.3-.7.8-1l.3-.3c0-.2-1-.7-1.2-.8l-.1-.1c-.8-.4-1-1.9-1-2.2a2 2 0 0 1-.4 0C1.1 8.7 1 7.6 1 7.6v-.2l.2-.1s.6-.3 1-1.3c.2-.4.8-.6 1.3-.7a7 7 0 0 1 2.2-3.4Zm1.4.2c-.2-.1-.5-.1-1 .2-1 .7-1.7 2.2-2 2.8l-.2.6s-.8 0-1.1.5c-.4.8-.9 1.2-1.1 1.4 0 .2.3.5 1 .8.7.2 2 0 2 0L3.5 9c.1.6.4 1.5.8 1.7.9.4 1.6.8 1.6 1.4 0 .3-.2.6-.5.8-.4.2-.6.4-.6.6h1A7 7 0 0 0 7.5 11c-.3-.6-.3-1-.1-1.5C8 8.2 11 9 11 9s-2.7 0-3 .8c-.3.4 0 1.5 1.7 2.5l.6.3s-1.3 0-1.8.6v.6H12c.3-.5 1.2-2.2 1-3.9-.3-2-1.6-2.9-2.6-3L8 7.1l-1.8.3a.9.9 0 0 1-.6-.4c-.3-.4-.1-1 0-1v-.3h.1c1.8-.4 3.1-2 3-2.6 0 0 0-.2-.3-.2-1 0-3 1.8-3 1.8s.8-1.3 1.8-2L7 2.2zm6.6 8.3c0 .6 0 1.1-.2 1.6.2 0 .4 0 .6-.2.2 0 .3-.3.3-.5 0-.5-.4-.7-.7-.9zm-10.4-4c.2 0 .3.1.3.3 0 .2-.1.3-.3.3a.3.3 0 0 1-.3-.3c0-.2.1-.3.3-.3z'/>
      </g>
    </SvgContainer>
  );
}

export default Svg16CrueltyFree;
