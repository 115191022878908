/**
  * Validations utility functions
  * Holds the client validations for the payment and checkout related fields for client and yup validations to handle on the form. this utility will hold methods like validatecreditcard etc
  * @module utils/Validations
  * @memberof -Common
 */
import { addMethod, number, object, string } from 'yup';

import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';

import * as utils from './Validations';

/**
 * constant which defines the regular expressions for different credit card types and also the credit card type value
 */
export const ccConstants = {
  CCNUMBER: {
    DISCOVERREGEX: /^6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65/,
    ULTAMATEREGEX: /^5780|9710[5-9]/,
    AMEXREGEX: /^3[47]/,
    ULTAREWARDSREGEX: /^536817/,
    VISAREGEX: /^4[0-9]/,
    MASTERCARDREGEX:
      /^(5([1-6][0-9][0-9][0-9][0-9])|22([2-9][1-9][0-9][0-9])|23([0-9][0-9][0-9][0-9])|24([0-9][0-9][0-9][0-9])|25([0-9][0-9][0-9][0-9])|26([0-9][0-9][0-9][0-9])|27([0-2]0[0-9][0-9]))/,
    EXPDATEREGEX: /^(?:(0[1-9]|1[012])[\/.][1-9]{1}[0-9]{3})$/,
    CC_EXPDATE_REGEX: /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/,
    AMEXREGEXVALIDATE: /([0-9]{4}\s[0-9]{6}\s[0-9]{5}\s*)$/,
    NONAMEXREGEXVALIDATE: /([0-9]{4}\s[0-9]{4}\s[0-9]{4}\s[0-9]{4}\s*)$/,
    AMEXCVVVALIDATE: /([0-9]{4})$/,
    NONAMEXCVVVALIDATE: /([0-9]{3})$/,
    DINERSCLUBREGX: /^36/,
    JCBREGX: /^(35[2-8][0-9])/,
    UNIONPAYREGX: /^(62[0-9]{14,17})$/,
    DISCOVER: 'Discover',
    ULTAMATE: ['Ultamate Rewards Credit Card', 'Ulta Beauty Rewards Credit Card'],
    AMEX: 'AmericanExpress',
    ULTAREWARDS: ['Ultamate Rewards MasterCard', 'Ulta Beauty Rewards MasterCard'],
    VISA: 'Visa',
    MASTERCARD: 'Mastercard',
    DINERSCLUB: 'DinersClub',
    JCB: 'JCB',
    UNIONPAY: 'UnionPay',
    DEFAULTCARD: 'defaultcreditcard'
  },
  creditCardTypeMapping: {
    'Visa': '001',
    'Ultamate Rewards Credit Card': '002',
    'Ultamate Rewards MasterCard': '002',
    'Ulta Beauty Rewards MasterCard': '002',
    'Ulta Beauty Rewards Credit Card':'002',
    'Mastercard': '002',
    'AmericanExpress': '003',
    'Discover': '004',
    'JCB':'007',
    'UnionPay':'062',
    'DinersClub':'005'
  },
  FORMATTER_PATTERNS: {
    THREE_DIGIT_CVV: { pattern: '999' },
    FOUR_DIGIT_CVV: { pattern: '9999' },
    EXPDATE_FORMATTER: { pattern: '99/99' }
  }
};

/**
 * constant which defines the fields names for various input types to be used across form components and Yup validations
 * @constant {object}
 * @default
 */
export const FIELDNAME = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  USERNAME: 'username',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirmPassword',
  ADDRESS1: 'address1',
  ADDRESS2: 'address2',
  CITY: 'city',
  POSTAL_CODE: 'postalCode',
  COUNTRY: 'country',
  STATE: 'state',
  PRIMARY: 'primary',
  REWARDS_PROGRAM: 'rewardsProgram',
  REWARDS_MEMID: 'rewardsMemberId',
  CC_NUMBER: 'creditCardNumber',
  EXPIRATION_DATE: 'expirationDate',
  SECURITY_CODE: 'securityCode',
  DATE_OF_BIRTH: 'dateOfBirth'
};

/**
 * Matches the cvv number with related regular expressions for credit card numbers
 * returns true if the cvv number matches with the regular expressions
 * @method
 * @returns {boolean}
 */
export const validateSecurityCode = ( ccNumber, val, validationMessage ) =>{
  var re;
  if( ccNumber === ccConstants.CCNUMBER.AMEX ){
    re = ccConstants.CCNUMBER.AMEXCVVVALIDATE;
  }
  else if( ccNumber.match( ccConstants.CCNUMBER.AMEXREGEX ) ){
    re = ccConstants.CCNUMBER.AMEXCVVVALIDATE;
  }
  else {
    re = ccConstants.CCNUMBER.NONAMEXCVVVALIDATE;
  }

  return re.test( val );

};

/**
 * validates the expiry date value for the year month and date and length
 * @method
 * @returns {boolean}
 */
export const validateCreditCardExpiry = ( val, lessThanMsg, validationMessage ) =>{
  const returnMessage = '';
  if( val ){
    var re = ccConstants.CCNUMBER.EXPDATEREGEX;
    let currentYear = parseInt( new Date().getFullYear().toString(), 10 );
    let currentMonth = parseInt( new Date().getMonth().toString(), 10 );
    let givenYear = parseInt( val.toString().substr( -4 ), 10 );
    let givenMonth = parseInt( val.toString().substring(), 10 );
    if( val.length < 7 ){
      return lessThanMsg;
    }
    let testdate = ( givenYear !== currentYear ) ? givenYear > currentYear : ( currentMonth < givenMonth );
    if( ! ( val.match( re ) && testdate ) ){
      return validationMessage;
    }
    else {
      return returnMessage;
    }
  }
  else {
    return returnMessage;
  }

};



/**
 * Matches the credit card number with related regular expressions
 * returns true if the creditcard number matches with the regular expressions
 * @method
 * @returns {boolean}
 */
export const validateCreditCard = ( val ) =>{
  var re;
  if( val.match( ccConstants.CCNUMBER.AMEXREGEX ) ){
    re = ccConstants.CCNUMBER.AMEXREGEXVALIDATE;
  }
  else {
    re = ccConstants.CCNUMBER.NONAMEXREGEXVALIDATE;
  }

  return val.match( re );

};

/**
 * Matches URCC cards
 * @param {object} data - arguments
 * @returns {boolean} if the card type matches URCC
 */
export const isURCC = ( data ) => {
  const { type = '' } = handleEmptyObjects( data );
  return ccConstants.CCNUMBER.ULTAMATE.includes( type );
};

/**
 * Matches URMC cards
 * @param {object} data - arguments
 * @returns {boolean} if the card type matches URMC
 */
export const isURMC = ( data ) => {
  const { type = '' } = handleEmptyObjects( data );
  return ccConstants.CCNUMBER.ULTAREWARDS.includes( type );
};

/**
 * Matches URCC or URMC cards
 * @param {object} data - arguments
 * @returns {boolean} if the card type matches URCC or URMC
 */
export const isUltaCard = ( data ) => {
  const { type = '' } = handleEmptyObjects( data );
  return isURCC( { type } ) || isURMC( { type } );
};


/**
 * Matches the input text for credit card number for length
 * @method
 * @returns {boolean}
 */
export const isValidCreditCard = val =>{
  return val !== '' && val.length > 11;
};

/**
 * validates the userNameFormGroup fields firstName and lastName
 * @method
 * @returns {object}
 */
export const userNameFormGroupValidationSchema = ( validationText )=> object().shape( {
  [FIELDNAME.FIRST_NAME]: string().required( validationText ),
  [FIELDNAME.LAST_NAME]: string().required( validationText )
} );

/**
 * validates the user detail fields firstName. lastName, email and phonenumber
 * @method
 * @returns {object}
 */
export const userValidationSchema = ( requiredText, invalidEmailText ) => (
  userNameFormGroupValidationSchema( requiredText ).concat(
    object().shape( {
      [FIELDNAME.EMAIL]:
        string()
          .required( requiredText )
          .email( invalidEmailText ),
      [FIELDNAME.PHONE_NUMBER]: string().required( requiredText )
    } )
  ) );

/**
 * validates the email field
 * @method
 * @returns {object}
 */
export const emailValidationSchema = ( requiredText, invalidEmailText ) => (
  object().shape( {
    [FIELDNAME.EMAIL]:
      string()
        .required( requiredText )
        .email( invalidEmailText )
  } ) );
/**
 * validates the addressFormGroup fields address,  city, state, postalCode, country and phoneNumber
 * @method
 * @returns {object}
 */
export const addressFormGroupValidationSchema = ( requiredText ) => object().shape( {
  [FIELDNAME.ADDRESS1]: string().required( requiredText ),
  [FIELDNAME.CITY]: string().required( requiredText ),
  [FIELDNAME.POSTAL_CODE]: number().required( requiredText ),
  [FIELDNAME.COUNTRY]: string().required( requiredText ),
  [FIELDNAME.STATE]: string().required( requiredText ),
  [FIELDNAME.PHONE_NUMBER]: string().required( requiredText )
} );

/**
 * validates the shippingAddressForm fields
 * @method
 * @returns {object}
 */
export const shippingAddFormValidationSchema = ( requiredText ) => (
  userValidationSchema( requiredText ).concat( addressFormGroupValidationSchema( requiredText ) )
);

/**
 * validates the credit card payment fields
 * @method
 * @returns {object}
 */
export const creditCardFormValidationSchema = ( requiredText ) => object().shape( {
  [FIELDNAME.CC_NUMBER]: string().required( requiredText ),
  [FIELDNAME.EXPIRATION_DATE]: string().required( requiredText ),
  [FIELDNAME.SECURITY_CODE]: number().required( requiredText )
} );

/**
 * validates the Bill And Payment Form fields
 * @method
 * @returns {object}
 */
export const BillAndPaymentFormValidationSchema = ( requiredText ) => (
  shippingAddFormValidationSchema( requiredText ).concat( creditCardFormValidationSchema( requiredText ) )
);

/**
 * validates the loginForm for fields username and password
 * @method
 * @returns {object}
 */
export const loginValidationSchema = ( requiredText, passwordValidation ) => object( {
  [FIELDNAME.USERNAME]: string().required( requiredText ),
  [FIELDNAME.PASSWORD]:
    string()
      .required( requiredText )
      .min( passwordValidation.minLength, passwordValidation.tooShortText )
      .max( passwordValidation.maxLength, passwordValidation.tooLongText )
} );

/**
 * validates the rewardsForm fields rewardsProgram and rewardsMemberId
 * @method
 * @returns {object}
 */
export const rewardsFormValidationSchema = ( requiredText ) => object().shape( {
  [FIELDNAME.REWARDS_PROGRAM]: string().required( requiredText ),
  [FIELDNAME.REWARDS_MEMID]: string().when( [FIELDNAME.REWARDS_PROGRAM], {
    is: 'exists',
    then: string().required( requiredText )
  } )
} );

/**
 * validates the addNewAddressForm fields
 * @method
 * @returns {object}
 */
export const addNewAddressFormValidationSchema = ( requiredText ) => (
  userNameFormGroupValidationSchema( requiredText ).concat( addressFormGroupValidationSchema( requiredText ) )
);

export const hasItems = v => Array.isArray( v ) && v.length > 0;

/**
 * Checks if a number is a non-infante number
 * @param {number} v value to check against
 * @returns {boolean} if the number is safe to use
 */
export const isSafeNumber = ( v ) => {
  return Number( v ) === v && isFinite( v );
};

/**
 * Checks if a number is a non-infante number
 * @param {number} v value to check against
 * @returns {boolean} if the number is safe to use
 */
export const isFunction = ( v ) => {
  return typeof v === 'function';
};

/**
 * Checks the credit card type is valid or not
 * returns true if the credit card type is not valid
 * @method
 * @returns {string}
 */
export const validateCreditCardType = ( cardType ) =>{
  if( cardType === ccConstants.CCNUMBER.DEFAULTCARD ){
    return true;
  }
};

/**
 * formatPhoneForDisplay method accepts data object which has values
 * And return the phoneNumber in (999) 999-9999 format
 * @method
 * @param {object} data holds the values object
 * @return {string} phoneNumber
 */
export const formatPhoneForDisplay  = ( data ) => {
  const { phoneNumber } = data || {};
  if( !phoneNumber || phoneNumber[0] === '(' ){
    return phoneNumber;
  }

  return `(${ phoneNumber.replace( '-', ') ' ) }`;
};

/**
 * formatPhoneForDXL method accepts data object which has values
 * And return the phoneNumber in 999-999-9999 format
 * @method
 * @param {object} data holds the values object
 * @return {string} phoneNumber
 */
export const formatPhoneForDXL = ( data ) => {
  const { phoneNumber } = data || {};

  if( !phoneNumber ){
    return '';
  }

  return phoneNumber.replace( '(', '' ).replace( ') ', '-' );
};

/**
 * formatDateOfBirthForDXL method accepts data object which has values
 * And return the dateOfBirth in 12-12 format
 * @method
 * @param {object} data holds the values object
 * @return {string} dateOfBirth
 */
export const formatDateOfBirthForDXL = ( data ) => {
  const { dateOfBirth } = data || {};

  if( !dateOfBirth ){
    return '';
  }

  return dateOfBirth.replaceAll( '/', '-' );
};


/**
 * getIsButtonDisabled method return not of dirty value
 * @param {object} data
 * @returns {boolean} dirty
 */
export const getIsButtonDisabled = ( data ) => {
  const { dirty, isValid } = data || {};
  return !isValid || !dirty;
};


/**
 * formatModuleParamsForDxl method accepts data object which has values
 * And return the moduleParams
 * @method
 * @param {object} data holds the values object
 * @return {object} moduleParams
 */
export const formatModuleParamsForDXL = ( data ) => {
  const { values = {} } = data || {};

  const inputs = Object.keys( values ).map( key => ( {
    [key]: { name: key, value: values[key] }
  } ) );

  const moduleParams = Object.assign( {}, ...inputs );

  if( moduleParams.phoneNumber?.value ){
    moduleParams.phoneNumber.value = formatPhoneForDXL( { phoneNumber: moduleParams.phoneNumber.value } );
  }
  if( moduleParams.mobileNumber?.value ){
    moduleParams.mobileNumber.value = formatPhoneForDXL( { phoneNumber: moduleParams.mobileNumber.value } );
  }
  if( moduleParams.dateOfBirth?.value ){
    moduleParams.dateOfBirth.value = formatDateOfBirthForDXL( { dateOfBirth: moduleParams.dateOfBirth.value } );
  }


  return moduleParams;
};

/**
 * isValidDXLRegex will look through an array of DXL provided regex values and
 * return true if the input value passes all of them
 *
 * @param {object} data - arguments
 * @param {array} data.validations - array of DXL provided regex values
 * @param {string} data.inputValue - the value to test against the regex
 * @return {boolean} if the DXL provided array of regex passes
 */
export const isValidDXLRegex = ( data ) => {
  const { validations = [], inputValue = '' } = handleEmptyObjects( data );

  if( !hasItems( validations ) ){
    return { valid: true };
  }

  let message, valid = true;
  for ( const v of validations ){
    if( !new RegExp( v.regex ).test( inputValue ) ){
      valid = false;
      message = v.message;
      break;
    }
  }

  return { valid, message };
};

/**
 * Adds custom Yup validations from DXL
 *
 * @param {object} data - arguments
 * @param {function} data.schemaType - Yup schema type
 * @param {string} data.name - name of the custom method
 * @param {array} data.validations - array of DXL provided regex values
 */
export const addYupCustomRegex = ( data ) => {
  const { schemaType = string, name = 'custom-test', validations = [] } = handleEmptyObjects( data );

  addMethod( schemaType, name, function( errorMessage ){
    return this.test( `test-custom-${name}`, errorMessage, function( inputValue ){
      const { path, createError } = this;

      const { valid, message } = utils.isValidDXLRegex( { inputValue, validations } );

      return valid || createError( { path, message } );
    } );
  } );
};

// method to enable required check as per value
// to use in schema call it as  Yup.addMethod( Yup.string, 'isRequiredField', isRequiredValidation );
const REQUIRED_MESSAGE = 'Required';
export function isRequiredValidation( val ){
  if( !val?.required || typeof this?.required !== 'function' ){
    return this;
  }
  return this.required( REQUIRED_MESSAGE );
}