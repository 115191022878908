import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12Bookmark( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <path
        stroke='currentColor'
        d='M1.5.5h9v11L6 7.875 1.5 11.5z'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg12Bookmark;
