/**
 * This is used to scroll through element to top or specified position.
 *
 * @module utils/video
 */
import { isSafari } from '@ulta/utils/isSafari/isSafari';

/**
 * @const {Array} VIDEO_FORMATS  - Video Formats array
 */
export const VIDEO_FORMATS = {
  VP9720P: 'vp9720p',
  HEVC720P: 'hevc720p',
  MP4720P: 'mp4_720p'
};

/**
 * @method getVideoUrl
 * @summary Returns the URL of a video from the videos array based on the specified format.
 * @param {Array} videoProfiles - An array of video objects.
 * @param {string} format - The video format to search for.
 * @param {string} defaultUrl - The default video url.
 * @returns {string|null} - The URL of the video if found, otherwise defaultUrl.
 */
export const getVideoUrl = ( data ) => {
  const { videoProfiles, format, defaultUrl } = data || {};

  if( !videoProfiles || !format || isSafari() ){
    return defaultUrl;
  }

  for ( const video of videoProfiles ){
    if( video.name.includes( format ) ){
      return video.url;
    }
  }
  return defaultUrl;
};