/**
 * The SnackBar provider manages snackbar display based on the meta.snackBar response
 *
 * @module views/components/SnackBarProvider
 * @memberof -Common
 */
import React, {
  createContext,
  useContext,
  useRef,
  useState } from 'react';

import SnackBar from '../../components/SnackBar/SnackBar';
import * as snackBarUtils from '../../components/SnackBar/SnackBar';

/**
 * Represents a SnackBarProvider component
 *
 * @method
 * @param {object} props - React properties passed from composition
 * @returns SnackBarProvider
 */
export const SnackBarProvider = function( props ){
  const [snackBarProps, setSnackBarProps] = useState( null );
  const snackBarTimer = useRef();
  const [snackBarId, setSnackBarId] = useState( null );
  const [display, setDisplay] = useState( false );

  const showSnackBar = ( snackBarData ) => {
    handleSnackBar( { display, snackBarData, snackBarTimer }, { setDisplay, setSnackBarProps, setSnackBarId } );
  };

  return (
    <SnackBarContext.Provider
      value={ {
        showSnackBar
      } }
    >
      { props.children }
      { display && (
        <SnackBar { ...snackBarProps }
          snackBarId={ snackBarId }
          onClose={ () => onCloseSnackBar( { snackBarTimer }, { setDisplay } ) }
        />
      ) }
    </SnackBarContext.Provider>
  );
};

/**
* handles the snackbar display
*
* @method
* @param {object} methods
* @param {object} data
*/
export const handleSnackBar = ( data, methods ) => {
  const { display, snackBarData, snackBarTimer } = data;
  const { setSnackBarId, setSnackBarProps, setDisplay } = methods;
  if( !snackBarData ){
    return;
  }
  setSnackBarProps( snackBarData );
  const randomKey = new Date().getTime();
  setSnackBarId( randomKey );
  if( display ){
    clearTimeout( snackBarTimer.current );
  }
  setDisplay( true );
};

/**
* handles the snackbar hiding
*
* @method
* @param {object} methods
* @param {object} data
*/
export const onCloseSnackBar = ( data, methods ) => {
  const { snackBarTimer } = data;
  const { setDisplay } = methods;
  const SnackbarExitDuration = snackBarUtils.getSnackbarAnimationValues( 'SnackbarExitDuration' );
  snackBarTimer.current = setTimeout( () => {
    setDisplay( false );
  }, SnackbarExitDuration );
};

export const SnackBarContext = createContext( {} );

export const useSnackBar = () => useContext( SnackBarContext );

export default SnackBarProvider;