import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16PositiveImpact( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <g id='PositiveImpact'>
        <path d='M11.2 7.3c2-.1 3.6 1.7 3.7 1.7v.5h-.4c-.2-.3-1.7-1.7-3.3-1.6-1.1 0-1.6.4-2 .8-.2.2-.4.4-.7.4h-.9c-.8 0-1.5 0-1.8.7l.5.1c.3 0 .7.1 1 .3a4 4 0 0 0 2.8-.3h.1c.2-.1.4 0 .4.1l-.1.4h-.2c-.4.2-1.1.5-2 .5a4 4 0 0 1-1-.2l-1-.2c-.6 0-1-.1-1.1-.4 0 0-.1-.2 0-.4C4.4 9 2.7 8 2 8.2l-.3.1c0 .2.6.6 1 .8l.5.4a30.4 30.4 0 0 0 4.5 3c.3.2 1.7-.4 2.5-.7.8-.4 1.3-.6 1.6-.6.8-.2 1.8.6 2 .7 0 0 .1.2 0 .4 0 .1-.3.1-.4 0-.3-.1-1-.6-1.5-.6-.3 0-1 .3-1.5.6-1.2.5-2 .9-2.6.9h-.3c-.5-.2-2-1.4-3.7-2.6l-.3-.2a69.3 69.3 0 0 0-1.2-.8C1.5 9.2 1 8.8 1 8.3c0-.3.4-.5.7-.6.6-.2 1.5 0 2.6.7l1.2.8c.6-.7 1.5-.6 2.1-.6h.7c.2 0 .3-.2.5-.4.5-.3 1-.8 2.4-.9zM7 3.7c.4-.5 1.3-1.3 2.1-.9.7.3 1 1.1.6 1.9a7.8 7.8 0 0 1-2.5 2.5.3.3 0 0 1-.3 0 8 8 0 0 1-2.6-2.5c-.2-.4-.2-.8-.1-1.1.1-.4.4-.6.7-.8.8-.4 1.7.4 2.1.9zm2-.4c-.6-.2-1.4.7-1.7 1 0 .1-.3.1-.4 0-.2-.3-1-1-1.5-1h-.2l-.4.5.1.6c.4 1 1.8 1.9 2.2 2.2.5-.3 1.8-1.2 2.2-2.2.2-.4 0-.9-.3-1z'/>
      </g>
    </SvgContainer>
  );
}

export default Svg16PositiveImpact;
