/**
 * This is a SearchXl Component.This Component is used to render the Search and the Auto Suggest Component on Xl Screen. This Component will open the Search Suggestion on the Focus of the Input.
 *
 * @module views/components/SearchXl
 * @memberof -Common
 */
import './SearchXl.scss';

import React, { useCallback, useRef, useState } from 'react';

import { FocusScope } from '@react-aria/focus';
import classNames from 'classnames';
import { Form, Formik } from 'formik';

import Button from '@ulta/core/components/Button/Button';
import InputFieldUnderline from '@ulta/core/components/InputFieldUnderline/InputFieldUnderline';
import UseKeyPress from '@ulta/core/hooks/useKeyPress/UseKeyPress';
import { useKeyPressCounter } from '@ulta/core/hooks/useKeyPressCounter/useKeyPressCounter';
import useOutsideClick from '@ulta/core/hooks/useOutsideClick/useOutsideClick';

import { useSearchHeaderContext } from '@ulta/providers/SearchHeaderProvider/SearchHeaderProvider';

import { nextFocusableElement } from '@ulta/utils/accessibility/accessibility';
import { constants } from '@ulta/utils/constants/constants';

import AutoSuggestDesktop from '../AutoSuggestDesktop/AutoSuggestDesktop';
import * as utils from './SearchXl';

const { ESC_KEY, TAB_KEY } = constants;
/**
 * Represents a SearchXl component
 *
 * @method
 * @returns SearchXl
*/
export const SearchXl = function(){
  const [showAutosuggest, setShowAutoSuggest] = useState( false );
  const ref = useRef();
  const inputRef = useRef();
  const {
    ariaLabel,
    screenReaderText,
    clearAccessibilityLabel,
    closePanel,
    handleSubmit,
    inputChangeHandler,
    invokeTypeAheadAction,
    loading,
    moveCursorToEndOfInput,
    savedSearchTerm,
    resetAction,
    searchInputLabel,
    searchString,
    setKeyBoardUser,
    submitSearchLabel,
    suggestions
  } = useSearchHeaderContext();

  // Close panel on outside click
  useOutsideClick( { watcher: showAutosuggest, el: ref?.current }, { handleClick: () => {
    setShowAutoSuggest( false );
  } } );

  // Close panel on ESC key or activate ADA mode on tab from input
  UseKeyPress( [ESC_KEY, TAB_KEY], ref, ( { keyCode, shiftKey } ) => {
    const activeEl = document.activeElement;
    if( keyCode === TAB_KEY ){
      shiftKey && utils.composeReturnFocus( { activeEl, inputRef }, { setShowAutoSuggest } );
    }
    else {
      setShowAutoSuggest( false );
    }
  }, [ESC_KEY, TAB_KEY], false );

  // Activate ADA mode after 3 tabs detected
  useKeyPressCounter( { keyCodes: [TAB_KEY], threshold: 3 }, { onThreshold: () => {
    setKeyBoardUser( true );
  } } );

  // Search Panel close Handler
  const onClose = useCallback( utils.composePanelClose( { searchString }, { setShowAutoSuggest, invokeTypeAheadAction } ), [
    searchString
  ] );

  return (
    <FocusScope
      contain={ showAutosuggest }
    >
      <div className='SearchXl'
        ref={ ref }
        group={ 'typeAhead' }
        role='region'
        aria-label={ ariaLabel }
      >
        <Formik
          initialValues={ { search: savedSearchTerm } }
          enableReinitialize={ true }
          onSubmit={ handleSubmit }
        >
          { () => (
            <Form
              className={ classNames( 'SearchXl__form', {
                'SearchXl__form--loading': loading
              } ) }
              role='search'
            >
              <p className='sr-only'
                id='searchDescription'
              >{ screenReaderText }</p>
              <InputFieldUnderline
                ariaDescribedBy='searchDescription'
                ref={ inputRef }
                type='search'
                name='search'
                autoComplete='off'
                onChange={ ( val ) => {
                  setShowAutoSuggest( true );
                  inputChangeHandler( val );
                } }
                handleFocus={ () => {
                  invokeTypeAheadAction( savedSearchTerm );
                  setShowAutoSuggest( true );
                  moveCursorToEndOfInput( inputRef );
                } }
                handleResetVal={ resetAction }
                leftIcon='Search'
                placeholder={ searchInputLabel }
                displayGoButton={ true }
                clearValueText={ clearAccessibilityLabel }
                submitText={ submitSearchLabel }
                handleSubmit={ () => {
                  setShowAutoSuggest( false );
                } }
              />
            </Form>
          ) }
        </Formik>
        { showAutosuggest && ( suggestions?.length > 0 ) && (

          <div open={ showAutosuggest }
            className='SearchXl__panel'
          >
            <form>
              <Button
                variant='unstyled'
                icon
                iconImage='X'
                iconSize='lg'
                ariaLabel={ closePanel }
                className='SearchXl__panelClose'
                onClick={ onClose }
              />
            </form>
            <AutoSuggestDesktop />

          </div>
        ) }
      </div>
    </FocusScope>
  );
};

/**
 * Method to handle searchPanel close event
 * @param {object} data args
 * @param {string} data.searchString input value
 * @param {object} methods args
 * @param {function} methods.invokeTypeAheadAction DXL call to get updated data
 * @param {function} methods.setShowAutoSuggest methods to show searchPanel
*/
export const composePanelClose = ( data, methods ) => () => {
  const { searchString } = data || {};
  const { setShowAutoSuggest, invokeTypeAheadAction } = methods || {};

  if( !setShowAutoSuggest || !invokeTypeAheadAction ){
    return;
  }

  invokeTypeAheadAction( searchString );
  setShowAutoSuggest( false );

  // We need to queue the focus or a re-render will reset the focus
  setTimeout( () => {
    nextFocusableElement( {
      selector: '.SearchXl__form input'
    } );
  }, 500 );
};

/**
 * Method to handle composeReturnFocus
 * @param {object} data args
 * @param {object} data.activeEl - active DOM element
 * @param {object} data.inputRef - inputRef
 * @param {object} methods args
 * @param {function} methods.setShowAutoSuggest methods to show searchPanel
*/
export const composeReturnFocus = ( data, methods ) => {
  const { inputRef, activeEl } = data || {};
  const { setShowAutoSuggest } = methods || {};

  if( !setShowAutoSuggest ){
    return;
  }
  setShowAutoSuggest( activeEl !== inputRef?.current );
};

export default SearchXl;
