/**
 * This is the component for showing the Breadcrumbs to user so that user can navigate to those product. It also helps us to setup for SEO.
 *
 * @module views/components/Breadcrumbs
 * @memberof -Common
 */
import './Breadcrumbs.scss';

import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';

import {
  fullyQualifyLink,
  host
} from '@ulta/utils/Formatters/Formatters';

/**
 * Represents a Breadcrumbs component
 *
 * @method
 * @param {BreadcrumbsProps} props - React properties passed from composition
 * @returns Breadcrumbs
 */
export const Breadcrumbs = function( props ){
  const [isScrollBarVisible, setScrollBarVisible] = useState( false );
  const breadCrumbData = props.breadcrumbs.map( ( breadCrumb, index ) => {
    return (
      {
        '@type': 'ListItem',
        'position': index + 1,
        'item': {
          '@id': breadCrumb.item.url ? fullyQualifyLink( host, breadCrumb.item.url ) : props.currentPageUrl,
          'name': breadCrumb.item.label
        }
      }
    );
  } );

  const seoBreadcrumbData = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': breadCrumbData
  };
  const { inflection } = useDeviceInflection();
  useEffect( () => {
    const isScrollBarAvailable = inflection.MOBILE && ( document.getElementById( 'Breadcrumbs__List' )?.offsetWidth > document.getElementById( 'breadcrumbs' )?.offsetWidth );
    setScrollBarVisible( isScrollBarAvailable );
  }, [inflection] );

  return (
    <div className='Breadcrumbs'>
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={ { __html:JSON.stringify( seoBreadcrumbData ) } }
      >
      </script>
      <nav aria-label='breadcrumbs'
        id='breadcrumbs'
        className={ classNames( { 'Breadcrumbs--DirectionRtl': isScrollBarVisible } ) }
      >
        <ul id='Breadcrumbs__List'
          className={ classNames( 'Breadcrumbs__List', { 'Breadcrumbs__List--DirectionLtr': isScrollBarVisible } ) }
        >
          { props.breadcrumbs.map( ( breadCrumb, index ) => {
            return (
              <li className='Breadcrumbs__List--item'
                key={ index + breadCrumb.item.label }
              >  {
                  breadCrumb.item.url ?
                    (
                      <>
                        <Link_Huge
                          secondary
                          compact
                          url={ fullyQualifyLink( host, breadCrumb.item.url ) }
                        >
                          { breadCrumb.item.label }
                        </Link_Huge>
                      </>
                    ) :
                    (
                      <Text htmlTag='span'
                        textStyle='body-3'
                        ariaCurrent='page'
                      >
                        { breadCrumb.item.label }
                      </Text>
                    )
                }
              </li>
            );
          }
          ) }
        </ul>
      </nav>

      { props.title &&
        <div className='Breadcrumbs__Title'>
          <Text htmlTag='h1'
            textStyle='title-6'
          >
            { props.title }
          </Text>
        </div>
      }
    </div>
  );
};

/**
 * Property type definitions
 * @typedef BreadcrumbsProps
 * @type {object}
 * @property {array<{ label: string, link: string }>} breadcrumbs - List of breadcumbs
 * @property {string} currentPageUrl - This is the current page url.
 */
export const propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape( {
      label: PropTypes.string,
      url: PropTypes.string
    } )
  ).isRequired,
  title: PropTypes.string,
  currentPageUrl: PropTypes.string
};

Breadcrumbs.propTypes = propTypes;

export default Breadcrumbs;
