import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16ZoomIn( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <g fill='none'
        fillRule='evenodd'
        stroke='currentColor'
      >
        <g fillRule='nonzero'>
          <circle cx={ 5.5 }
            cy={ 5.5 }
            r={ 5 }
          />
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M15 15.5L9.24 9.25'
          />
        </g>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M3 5.5h5M5.5 3v5'
        />
      </g>
    </SvgContainer>
  );
}

export default Svg16ZoomIn;
