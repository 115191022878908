import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function SVG( props ){

  return (
    <SvgContainer
      { ...props }
      width={ 50 }
      height={ 50 }
      viewBox='0 0 38 24'
    >
      <defs>
        <linearGradient
          id='linearGradient-1'
          x1='-58.357%'
          x2='240.287%'
          y1='49.887%'
          y2='49.887%'
        >
          <stop offset='0%'
            stopColor='#007940'
          ></stop>
          <stop offset='22.9%'
            stopColor='#00873F'
          ></stop>
          <stop offset='74.3%'
            stopColor='#40A737'
          ></stop>
          <stop offset='100%'
            stopColor='#5CB531'
          ></stop>
        </linearGradient>
        <linearGradient
          id='linearGradient-2'
          x1='1.061%'
          x2='100.528%'
          y1='49.991%'
          y2='49.991%'
        >
          <stop offset='0%'
            stopColor='#007940'
          ></stop>
          <stop offset='22.9%'
            stopColor='#00873F'
          ></stop>
          <stop offset='74.3%'
            stopColor='#40A737'
          ></stop>
          <stop offset='100%'
            stopColor='#5CB531'
          ></stop>
        </linearGradient>
        <linearGradient
          id='linearGradient-3'
          x1='-67.386%'
          x2='256.145%'
          y1='45.033%'
          y2='45.033%'
        >
          <stop offset='0%'
            stopColor='#007940'
          ></stop>
          <stop offset='22.9%'
            stopColor='#00873F'
          ></stop>
          <stop offset='74.3%'
            stopColor='#40A737'
          ></stop>
          <stop offset='100%'
            stopColor='#5CB531'
          ></stop>
        </linearGradient>
        <linearGradient
          id='linearGradient-4'
          x1='.009%'
          x2='102.395%'
          y1='49.944%'
          y2='49.944%'
        >
          <stop offset='0%'
            stopColor='#1F286F'
          ></stop>
          <stop offset='47.5%'
            stopColor='#004E94'
          ></stop>
          <stop offset='82.6%'
            stopColor='#0066B1'
          ></stop>
          <stop offset='100%'
            stopColor='#006FBC'
          ></stop>
        </linearGradient>
        <linearGradient
          id='linearGradient-5'
          x1='-.653%'
          x2='98.805%'
          y1='49.772%'
          y2='49.772%'
        >
          <stop offset='0%'
            stopColor='#6C2C2F'
          ></stop>
          <stop offset='17.3%'
            stopColor='#882730'
          ></stop>
          <stop offset='57.3%'
            stopColor='#BE1833'
          ></stop>
          <stop offset='85.9%'
            stopColor='#DC0436'
          ></stop>
          <stop offset='100%'
            stopColor='#E60039'
          ></stop>
        </linearGradient>
      </defs>
      <g fill='none'
        fillRule='evenodd'
        stroke='none'
        strokeWidth='1'
        width='31.667'
        height='19.792'
      >
        <g fillRule='nonzero'>
          <path
            fill='silver'
            d='M36 0c1.1 0 2 .9 2 2v19.8c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0h34zm0 .5H2C1.2.5.6 1.1.5 1.9V21.8c0 .8.6 1.4 1.4 1.5H36c.8 0 1.4-.6 1.5-1.4V2c0-.8-.6-1.4-1.4-1.5H36z'
          ></path>
          <g transform='translate(7 2.9)'>
            <path
              fill='url(#linearGradient-1)'
              d='M18.9 11.4h2c.3-.1.6-.4.6-.8s-.3-.7-.6-.8h-2v1.6z'
            ></path>
            <path
              fill='url(#linearGradient-2)'
              d='M20.5 0c-1.7 0-3.1 1.4-3.1 3.1v3.3h4.7c1 .1 1.7.6 1.7 1.5 0 .7-.5 1.3-1.4 1.4 1 .1 1.8.6 1.8 1.5 0 1-.9 1.6-2 1.6h-4.9v6.4H22c1.7 0 3.1-1.4 3.1-3.1V0h-4.6z'
            ></path>
            <path
              fill='url(#linearGradient-3)'
              d='M21.4 8.1c0-.4-.3-.7-.6-.7H19v1.5h1.8c.3-.1.6-.4.6-.8z'
            ></path>
            <path
              fill='url(#linearGradient-4)'
              d='M3.1 0C1.4 0 0 1.4 0 3.1v7.8c.9.4 1.8.7 2.7.7 1.1 0 1.7-.7 1.7-1.6V6.4h2.7V10c0 1.4-.9 2.6-3.9 2.6-1.8 0-3.2-.4-3.2-.4v6.6h4.6c1.7 0 3.1-1.4 3.1-3.1V0H3.1z'
            ></path>
            <path
              fill='url(#linearGradient-5)'
              d='M11.8 0c-1.7 0-3.1 1.4-3.1 3.1v4.1c.8-.7 2.2-1.1 4.4-1 1.2.1 2.5.4 2.5.4v1.3c-.6-.3-1.4-.6-2.4-.7-1.7-.1-2.7.7-2.7 2.2 0 1.5 1 2.3 2.7 2.2 1-.1 1.7-.4 2.4-.7v1.3s-1.3.3-2.5.4c-2.2.1-3.6-.3-4.4-1v7.3h4.6c1.7 0 3.1-1.4 3.1-3.1V0h-4.6z'
            ></path>
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}

export default SVG;

