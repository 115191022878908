import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12Share( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <g
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
        stroke='currentColor'
      >
        <path d='M9 6.5h2.5v5H.5v-5H3M6 .792v6.417M8.917 3.708L5.965.792 3.033 3.708' />
      </g>
    </SvgContainer>
  );
}

export default Svg12Share;
