import constants from '../constants/constants';

/**
 * Inserts header and footer modules into the page object
 * @method updatePageResponse
 * @param {object} data - Data argument
 * @returns object - Page Response
 */
export const updatePageResponse = ( d ) => {
  const { response, header, footer, pageModules } = d || {};

  if( !response || !header || !footer || !pageModules ){
    return {};
  }

  const { data = {} } = response;
  const { Page = {} } = data;
  const { content = {} } = Page;

  const [headerModule, ...restHeader] = header;
  const [footerModule, ...restFooter] = footer;

  const decorated = pageModules.map( ( module ) => {
    const m = { ...module };
    if( m?.meta?.initAction && m?.moduleName === constants.HFN.IncrementalModuleName ){
      m.meta.initAction.cachePath = constants.HFN.href;
    }
    return m;
  } );

  const mainModules = [...restHeader, ...decorated, ...restFooter];

  const main = {
    moduleName: constants.HFN.MainWrapperModuleName,
    modules: mainModules
  };

  const rModules = [
    headerModule,
    main,
    footerModule
  ];

  const rContent = {
    ...content,
    modules: rModules
  };

  const rPage = {
    ...Page,
    content: rContent
  };

  const rData = {
    ...data,
    Page: rPage
  };

  return {
    ...response,
    data: rData
  };
};