/**
 * Various types of inline mssage to display context to the user
 *
 * @module views/components/InlineMessage
 * @memberof -Common
 */
import './InlineMessage.scss';

import React from 'react';

import PropTypes from 'prop-types';

import Icon from '@ulta/core/components/Icon/Icon';
import Text from '@ulta/core/components/Text/Text';

/**
 * @function getIcon
 * @summary Function to get the proper icon
 *
 * @param {string} messageType - Type of message [error, warning, success, notavailable]
 * @returns {string} Return the icon name
 */
export const getIcon = ( messageType ) => {
  switch ( messageType ){
    case 'error':
      return 'ExclamationTriangle';
    case 'failure':
      return 'x';
    case 'warning':
      return 'ExclamationTriangle';
    case 'infocircle':
      return 'InfoCircle';
    case 'success':
      return 'Check';
    case 'info':
      return 'InfoCircle';
    default:
      return 'InfoCircle';
  }
};
/**
 * Represents a InlineMessage component
 *
 * @method
 * @param {InlineMessageProps} props - React properties passed from composition
 * @returns InlineMessage
 */
export const InlineMessage = function( props ){
  const { message, messageType, id, role, ariaAtomic, iconDisplay, iconSize } = props;

  if( !message ){
    return null;
  }

  const color = !!messageType && MESSAGE_COLORS[messageType] || 'black';

  return (
    <div className='InlineMessage'>
      { iconDisplay &&
        <div className='InlineMessage__icon'>
          <Icon
            aria-hidden={ true }
            size={ iconSize }
            name={ getIcon( messageType ) }
            className={ `InlineMessage__icon--${messageType}` }
          />
        </div>
      }
      <div className={ `InlineMessage__message InlineMessage__message--${messageType}` }
        { ...( id && { 'id':id } ) }
        { ...( role && { 'role':role } ) }
        { ...( ariaAtomic && { 'aria-atomic':ariaAtomic } ) }
      >
        <Text textStyle='body-2'
          color={ color }
        >
          { message }
        </Text>
      </div>
    </div>
  );
};

const MESSAGE_COLORS = {
  warning: 'alert-600',
  error: 'error',
  success: 'validate-600',
  info: 'neutral-500',
  infocircle: 'infocircle'
};

/**
 * Property type definitions
 * @typedef InlineMessageProps
 * @type {object}
 * @property {string} messageType - The type of message can be any one of ( 'error' | 'warning' | 'success' | 'info' )
 * @property {string} message - Detail of message can be passesd as string
 * @property {string} iconSize - size of icon 'm' | 's'
 * @property {boolean} iconDisplay - display icon true | false
 * @property {string} id - id for the message type class
 * @property {role} role - adds role attribute
 * @property {boolean} ariaAtomic - sets value for aria-atomic
 */
export const propTypes =  {
  messageType: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  id: PropTypes.string,
  iconSize: PropTypes.string,
  iconDisplay: PropTypes.bool,
  role: PropTypes.string,
  ariaAtomic: PropTypes.bool
};

export const defaultProps = {
  iconSize: 'm',
  iconDisplay: true
};

InlineMessage.propTypes = propTypes;
InlineMessage.defaultProps = defaultProps;

export default InlineMessage;
