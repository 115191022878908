/**
 * This component will display product rail from Criteo.
 *
 * @module views/components/CriteoProductRail
 * @memberof -Common
 */
import React from 'react';

import PropTypes from 'prop-types';

import ProductRail from '@ulta/components/ProductRail/ProductRail';

/**
 * Represents a CriteoProductRail component
 *
 * @method
 * @param {CriteoProductRailProps} props - React properties passed from composition
 * @returns CriteoProductRail
 */
export const CriteoProductRail = function( props ){

  const { items } = props;
  if( !Array.isArray( items ) || items.length === 0 ){
    return null;
  }

  return (
    <div className='CriteoProductRail'>
      <ProductRail { ...props } />
    </div>
  );
};


/**
 * Default values for passed properties
 * @type {object}
 * @property {string} deviceType='' - The default value of the deviceType is empty string.
 * @property {string} previousAccessibility='' - The default value of the previousAccessibility is empty string.
 * @property {string} nextAccessibility='' - The default value of the nextAccessibility is empty string.
 * @property {boolean} ssr=false - The default value for ssr is set to false.
 * @property {string} title='' - The default value of the title is empty string.
 * @property {string} countLabel='' - The default value of the count label is empty string.
 * @property {boolean} arrows=false - The default value for arrows is set to false.
 * @property {number} slidesToSlide=4 - The default value for slidesToSlide is set to 4.
 */
export const defaultProps = {
  deviceType: '',
  previousAccessibility: '',
  nextAccessibility: '',
  ssr: false,
  title: '',
  countLabel: '',
  arrows: false,
  slidesToSlide: 4
};

/**
 * Property type definitions
 * @typedef CriteoProductRailProps
 * @type {object}
 * @property {string} deviceType - Sets the device type.
 * @property {string} previousAccessibility - Sets the aria label for previous button.
 * @property {string} nextAccessibility - Sets the aria label for next button.
 * @property {boolean} ssr - Sets server side rendering.
 * @property {string} title - Sets the title for productRail list.
 * @property {string} countLabel - Sets the count label for productRail list.
 * @property {boolean} arrows - Sets whether to show or hide default arrows on carousel.
 * @property {number} slidesToSlide - Sets the number of slides to slide when cliked on next or previous button.
 * @property {object} viewAllAction - This is the action object with action label and url.
 * @property {string} carouselAccessibility - Sets the carousel role='Carousel' attribute value
 * @property {string} carouselDetailsAccessibility - Sets the Carousel details screen reader information
 * @property {string} carouselCardAccessibility - Sets the carousel item number of total count string
 */
export const propTypes = {
  /**
   * The prop that holds the device type.
   */
  deviceType: PropTypes.string,
  /**
   * The prop that holds the aria label for previous button.
   */
  previousAccessibility: PropTypes.string,
  /**
   * The prop that holds the aria label for next button.
   */
  nextAccessibility: PropTypes.string,
  /**
   * The prop that decides to render on server side or not.
   */
  ssr: PropTypes.bool,
  /**
   * The prop that holds title for the carousel.
   */
  title: PropTypes.string,
  /**
   * The prop that holds the count label for the product list.
   */
  countLabel: PropTypes.string,
  /**
   * The prop that decides to render default arrows on carousel.
   */
  arrows: PropTypes.bool,
  /**
   * The prop that decides number of slides to slide when cliked on next or previous button.
   */
  slidesToSlide: PropTypes.number,
  /**
   * This is the action with label and url.
   */
  viewAllAction: PropTypes.object,
  /**
   * The prop that holds the pageId type.
   */
  pageId: PropTypes.object,
  /**
   * The prop that holds the event type.
   */
  eventType: PropTypes.string,
  /**
   * The prop that holds the category type.
   */
  category: PropTypes.string,
  /**
   * The prop that holds the keywords type.
   */
  keywords: PropTypes.string,
  /**
   * Sets the carousel role='Carousel' attribute value
   */
  carouselAccessibility: PropTypes.string,
  /**
   * Sets the Carousel details screen reader information
   */
  carouselDetailsAccessibility: PropTypes.string,
  /**
   * Sets the carousel item number of total count string
   */
  carouselCardAccessibility: PropTypes.string
};

CriteoProductRail.propTypes = propTypes;
CriteoProductRail.defaultProps = defaultProps;

export default CriteoProductRail;
