import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24InfoCircle( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
      viewBox='0 0 24 24'
    >
      <path
        fill='#000'
        fillRule='nonzero'
        d='M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0Zm0 1.5C6.201 1.5 1.5 6.201 1.5 12S6.201 22.5 12 22.5 22.5 17.799 22.5 12 17.799 1.5 12 1.5Zm-.75 8.25a1.5 1.5 0 0 1 1.493 1.356l.007.144V15c0 .38.282.693.648.743l.102.007h.75a.75.75 0 0 1 .102 1.493l-.102.007h-.75a2.25 2.25 0 0 1-2.245-2.096L11.25 15v-3.75h-.75a.75.75 0 0 1-.102-1.493l.102-.007h.75ZM11.625 6l.123.007a1.125 1.125 0 1 1-.246 0L11.625 6Z'
      />
    </SvgContainer>
  );
}

export default Svg24InfoCircle;
