import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16StarHalf( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <path
        d='M3.397 14.884a.506.506 0 01-.17-.522l.542-1.973.677-2.468-3.298-3.268a.5.5 0 01.24-.843l.071-.012 4.007-.396 2.08-4.122a.51.51 0 01.187-.202.494.494 0 01.196-.073L8.001 1v.002l.069.006a.51.51 0 01.382.272l2.082 4.124 4.007.397a.5.5 0 01.31.855l-3.297 3.268 1.223 4.44a.506.506 0 01-.716.582L8 12.936l-4.055 2.008a.505.505 0 01-.548-.06zM8 2.458v9.544c.11 0 .22.02.324.058l.088.039 3.21 1.589-.968-3.516a.933.933 0 01.18-.844l.063-.067 2.644-2.621-3.1-.307a.933.933 0 01-.694-.426l-.047-.082z'
        fill='currentColor'
        fillRule='nonzero'
      />
    </SvgContainer>
  );
}

export default Svg16StarHalf;
