import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16ArrowDown( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <g
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M8.167 14.833v-14M15.167 8.333l-7.023 7-6.977-7' />
      </g>
    </SvgContainer>
  );
}

export default Svg16ArrowDown;
