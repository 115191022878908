import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12CrueltyFree( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <g id='CrueltyFree'>
        <path d='M4.4 1.8c.6-.5 1-.3 1.2-.2l.3.5h.4c.4 0 .6.2.7.5 0 .7-1 2-2.4 2.3v.3h.2l1.1-.1c.7-.2 1.4-.3 2-.2.8 0 1.9.8 2.2 2.5.4.2 1 .5.9 1.2 0 .3-.2.5-.4.7a1.2 1.2 0 0 1-.8.2l-.6 1v.1H5.9v-.1c-.2-.4-.1-.7 0-1l.6-.3-.6-.5c-.3.9-1 1.8-1.1 1.9H3.5s-.3-.2-.2-.5c0-.3.2-.5.6-.7l.2-.2c0-.2-.7-.6-.9-.7-.6-.3-.8-1.3-.8-1.5H2c-1-.3-1-1.1-1-1.2v-.1h.1s.4-.3.8-1c.1-.3.5-.4.9-.5 0-.1.6-1.7 1.6-2.4Zm1 .2-.8.2c-.7.5-1.2 1.5-1.3 2l-.2.4s-.6 0-.8.3a3 3 0 0 1-.8 1c0 .2.2.4.8.6.4.2 1.3 0 1.3 0l-.8.4c0 .4.3 1 .5 1.2h.1c.6.3 1.1.6 1.1 1 0 .3-.1.5-.4.6l-.4.4v.1h.8a5 5 0 0 0 1-2v-1c.4-1 2.6-.4 2.6-.4s-2 0-2.2.5c-.2.4 0 1.1 1.2 1.8l.5.3s-1 0-1.3.4v.4h2.6c.2-.3 1-1.5.7-2.7C9.4 6 8.5 5.4 7.8 5.3c-.4 0-1.1 0-1.8.2l-1.3.2a.6.6 0 0 1-.4-.3c-.2-.3 0-.7 0-.8v-.1C5.8 4.2 6.7 3 6.6 2.6l-.2-.1c-.7 0-2.1 1.3-2.1 1.3s.5-1 1.2-1.4V2zM10 8c0 .3 0 .7-.2 1h.5l.2-.4c0-.4-.3-.6-.5-.7zM2.7 5l.2.3-.2.2a.2.2 0 0 1-.3-.2c0-.1.1-.2.3-.2z'/>
      </g>
    </SvgContainer>
  );
}

export default Svg12CrueltyFree;
