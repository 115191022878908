import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16SearchIcon( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <g id='Base/Icons/UI/search'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M 3.269531 0.875 C 5.296875 -0.292969 7.789062 -0.292969 9.8125 0.875 C 11.835938 2.046875 13.085938 4.203125 13.085938 6.542969 C 13.085938 8.167969 12.492188 9.65625 11.507812 10.800781 L 15.855469 15.148438 C 16.046875 15.34375 16.046875 15.660156 15.855469 15.855469 C 15.675781 16.03125 15.398438 16.046875 15.203125 15.902344 L 15.144531 15.855469 L 10.800781 11.507812 C 9.65625 12.492188 8.167969 13.085938 6.542969 13.085938 C 2.929688 13.085938 0 10.15625 0 6.542969 C 0 4.203125 1.246094 2.046875 3.269531 0.875 Z M 9.3125 1.742188 C 7.597656 0.753906 5.484375 0.753906 3.769531 1.742188 C 2.054688 2.734375 1 4.5625 1 6.542969 C 1 9.601562 3.480469 12.085938 6.542969 12.085938 C 8 12.085938 9.324219 11.523438 10.316406 10.601562 L 10.46875 10.453125 C 11.464844 9.453125 12.085938 8.070312 12.085938 6.542969 C 12.085938 4.5625 11.027344 2.734375 9.3125 1.742188 Z M 9.3125 1.742188 '
          fill='#000000'
          fillRule='nonzero'
        />
      </g>
    </SvgContainer>
  );
}

export default Svg16SearchIcon;
