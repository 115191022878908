
import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function SVG( props ){

  return (
    <SvgContainer
      { ...props }
      width='100%'
      height='4px'
      viewBox='0 0 1000 4'
      preserveAspectRatio='none'
    >
      <defs>
        <rect id='path-1'
          x='0'
          y='0'
          width='1000'
          height='4'
        ></rect>
      </defs>
      <g id='ColorBar-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='ColorBar-2'
          transform='translate(-232.000000, -588.000000)'
        >
          <g id='ColorBar-3'
            transform='translate(-8.000000, 564.000000)'
          >
            <g id='ColorBar-4'
              transform='translate(240.000000, 24.000000)'
            >
              <g>
                <mask id='mask-2'
                  fill='white'
                >
                  <use xlinkHref='#path-1'></use>
                </mask>
                <use id='Rectangle'
                  fill='#F69DBD'
                  xlinkHref='#path-1'
                ></use>
                <g id='WildStreak'
                  mask='url(#mask-2)'
                  fillRule='nonzero'
                >
                  <g id='Shape'
                    transform='translate(1087.500000, -535.500000) rotate(-90.000000) translate(-1087.500000, 535.500000) translate(-191.500000, -1963.500000)'
                  >
                    <polygon fill='#F47D39'
                      points='2219.75886 622.152542 48.5443755 753 13.3586863 344.374294 2184.28711 174'
                    ></polygon>
                    <polygon fill='#F47D39'
                      points='13.3586863 1057 2557.40874 1215.62185 2557.40874 1324.60504 13.3586863 1387'
                    ></polygon>
                    <polygon fill='#FCDCE2'
                      points='174.954192 898.632867 744.954192 863 744.954192 1172.37063 174.954192 1258'
                    ></polygon>
                    <polygon fill='#EF426D'
                      points='2240.3129 1304 9.44434412 1045.04351 0 871 2254.05013 1000.05649'
                    ></polygon>
                    <polygon fill='#EF4B22'
                      points='2323.96361 532 145.94987 387.553279 174.049574 0 2352.35004 143.356557'
                    ></polygon>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgContainer>
  );

}

export default SVG;

