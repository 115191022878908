/**
 * List is used as a minimal layout framework for displaying a group of items. Content inside a list can take on different forms based on needs. The layout can flex as well based on needs. There are options for dividers, selection controls, and navigational properties.
 *
 * @module views/components/List
 * @memberof -Common
 */
import './List.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
  * Represents a List component
  *
  * @method
  * @param {ListProps} props - React properties passed from composition
  * @returns List
  */
export const List = function( props ){
  const listItem = props.children;
  return (
    <ul className={ classNames( 'List', {
      [props.className]: props.className,
      [`List--Display List--Display${props.display}`]: props?.display,
      [`List--Spacer List--Spacer${props.spacer}`]: props?.spacer,
      [`List--Separator List--Separator${props.separator}`]: props?.separator
    } ) }
    aria-label={ props.ariaLabel }
    >
      { listItem.map( ( listItem, index ) => (
        <li key={ `${listItem.id}:${index}` }
          className='List__Item'
        >
          { listItem }
        </li>
      )
      ) }
    </ul>
  );
};

/**
  * Property type definitions
  *
  * @typedef ListProps
  * @type {object}
  * @property {string} display - Value for the display
  * @property {string} spacer - Value for the spacer
  * @property {string} separator - Value for the separator
  * @property {string} className - Value for the className
  * @property {object} children - Component content, consisting various List scenarios
  */
export const propTypes =  {
  className: PropTypes.string,
  /* display values */
  display: PropTypes.oneOf( [
    'Block',
    'Flex',
    'Inline'
  ] ),
  spacer: PropTypes.oneOf( [
    '00', // none
    '01', // 8px
    '02', // 16px
    '03', // 24px
    '04' // 32px
  ] ),
  separator: PropTypes.oneOf( [
    'Default',
    'Muted'
  ] ),
  /** Component content, consisting various List scenarios */
  children: PropTypes.node
};

/**
  * Default values for passed properties
  *
  * @type {object}
  * @property {string} display='Block' - The default value
  * @property {string} spacer='00' - The default value
  * @property {string} separator='00' - The default value
  */
export const defaultProps =  {
  display: 'Block',
  spacer: '02'
};

List.propTypes = propTypes;
List.defaultProps = defaultProps;

export default List;