import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24StarHalf( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <path
        d='M4.766 22.818a.795.795 0 01-.267-.82l.852-3.101 1.063-3.878-5.181-5.135a.787.787 0 01.376-1.326l.113-.018 6.296-.623 3.268-6.477a.8.8 0 01.294-.318.777.777 0 01.309-.114L12.002 1v.004l.108.008a.8.8 0 01.601.428l3.271 6.48 6.296.624a.787.787 0 01.489 1.344l-5.181 5.135 1.92 6.978a.795.795 0 01-1.125.915L12 19.756l-6.372 3.156a.794.794 0 01-.861-.094zm7.235-19.527l.001 14.997c.172 0 .344.032.508.093l.14.06 5.042 2.497-1.52-5.526a1.467 1.467 0 01.284-1.325l.097-.105 4.156-4.12-4.871-.482a1.466 1.466 0 01-1.09-.669l-.075-.13z'
        fill='currentColor'
        fillRule='nonzero'
      />
    </SvgContainer>
  );
}

export default Svg24StarHalf;
