/**
 * Use large deal cards for featuring deals or deals, like Large Deal Rail, manual add gifts with purchase, bundle and save rail and others deals that requires actions to be taken.
 *
 * @module views/components/LargeDealRail
 * @memberof -Common
 */
import 'react-multi-carousel/lib/styles.css';
import './LargeDealRail.scss';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import React, { useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import SwiperCore, { A11y, Keyboard, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import palette_variables from 'web-styles/src/_exports.module.scss';

import Button from '@ulta/core/components/Button/Button';
import GridContainer from '@ulta/core/components/GridContainer/GridContainer';
import Text from '@ulta/core/components/Text/Text';
import { useIntersectionObserver } from '@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver';
import { handleIntersection } from '@ulta/core/utils/intersectionProcessor/intersectionProcessor';

import LargeDealCard from '@ulta/components/LargeDealCard/LargeDealCard';

import carouselUtils, { handleInactiveSwiperSlides } from '@ulta/utils/carouselUtils/carouselUtils';
import { constants } from '@ulta/utils/constants/constants';

import * as utils from './LargeDealRail';

SwiperCore.use( [Navigation, Keyboard, A11y] );

let swiperCounter = 1;

/**
  * Represents a LargeDealRail component
  *
  * @method
  * @param {LargeDealRailProps} props - React properties passed from composition
  * @returns LargeDealRail
  */
export const LargeDealRail = function( props ){
  const largeDealRailRef = useRef( null );
  const {
    rootMargin,
    root,
    threshold,
    deals,
    previousAccessibility,
    nextAccessibility,
    nextClickAction,
    previousClickAction,
    carouselAccessibility,
    carouselDetailsAccessibility,
    title
  } = props;

  const maxWidthAspectRatio = useMemo( () => utils.getMaxAspectRatio( { deals } ), [deals] );

  const [currentIndex, updateCurrentIndex] = useState( 1 );
  swiperCounter += 1;
  const isLastItem =  currentIndex === deals?.length;
  const isSingleItem = deals?.length === 1;

  useIntersectionObserver( largeDealRailRef, {
    root: root,
    rootMargin: rootMargin,
    threshold: threshold
  }, handleIntersection( props ) );

  return (
    <div className='LargeDealRail'
      ref={ largeDealRailRef }
    >
      <GridContainer >
        <div className={
          classNames( 'LargeDealRail__sectionTitle', {
            'LargeDealRail__sectionTitle--singleItem': isSingleItem
          } )
        }
        >
          <Text
            textStyle='title-6'
            htmlTag='h2'
          >
            { props.title }
          </Text>
        </div>
        <div className={
          classNames( 'LargeDealRail__container', {
            'LargeDealRail__container--singleItem': isSingleItem,
            'LargeDealRail__container--lastItem': !isSingleItem && isLastItem,
            'LargeDealRail__container--firstItem': !isSingleItem
          } )
        }
        role='region'
        aria-label={ carouselUtils.handleTokenizedString( carouselAccessibility, [title] ) }
        >
          { /* TODO : aria-label will be localized */ }
          { carouselDetailsAccessibility &&
            <p className='sr-only'>{ carouselUtils.handleTokenizedString( carouselDetailsAccessibility, [title] ) }</p>
          }
          { deals?.length >= 2 &&
            <div className={ `LargeDealRail LargeDealRail__Pagination LargeDealRail__Pagination-${swiperCounter}` }>
              <Button
                variant='navigation'
                iconImage='CaretBack'
                iconSize='lg'
                className='LargeDealRail__Pagination__button LargeDealRail__Pagination__button--back'
                ariaLabel={ previousAccessibility }
                ariaHiddenIcon={ true }
                action={ previousClickAction }
              />
              <span className='LargeDealRail__Pagination--compact LargeDealRail__Pagination__progress'>
                { currentIndex }/{ deals.length }
              </span>

              <Button
                variant='navigation'
                iconImage='CaretForward'
                iconSize='lg'
                className={ 'LargeDealRail__Pagination__button LargeDealRail__Pagination__button--forward' }
                ariaLabel={ nextAccessibility }
                ariaHiddenIcon={ true }
                action={ nextClickAction }
              />
            </div>
          }
          <Swiper
            onSwiper={ ( swiper ) => handleInactiveSwiperSlides( swiper ) }
            onSlideChange={ ( swiper ) => {
              updateCurrentIndex( swiper?.realIndex + 1 );
              handleInactiveSwiperSlides( swiper );
            } }
            watchOverflow={ true }
            watchSlidesProgress={ true }
            watchSlidesVisibility={ true }
            centeredSlides={ true }
            freeMode={ false }
            preloadImages={ true }
            spaceBetween={ isSingleItem ? 0 : 16 }
            navigation={ {
              prevEl: `.LargeDealRail__Pagination-${swiperCounter} .LargeDealRail__Pagination__button--back`,
              nextEl: `.LargeDealRail__Pagination-${swiperCounter} .LargeDealRail__Pagination__button--forward`,
              disabledClass: 'LargeDealRail__Pagination__button--disabled',
              hiddenClass: 'LargeDealRail__Pagination__button--hidden'
            } }
            breakpoints={ {
              [palette_variables.breakpointLG]: {
                slidesPerView:isSingleItem ? 1 : 1.2,
                slidesPerGroup: 1
              },
              [palette_variables.breakpointMD]: {
                slidesPerView: isSingleItem ? 1 : 1.5,
                slidesPerGroup: 1
              },
              [palette_variables.breakpointSM]: {
                slidesPerView: isSingleItem ? 1 : 1.100,
                slidesPerGroup: 1
              }
            } }
          >
            { deals?.length > 0 && deals.map( ( product, index ) => (
              <SwiperSlide key={ `LargeDealRail${ index + 1 } ` }>
                <div
                  className={
                    classNames( 'LargeDealRail__itemclass', {
                      'LargeDealRail__itemclass--lastItem': !isSingleItem && isLastItem,
                      'LargeDealRail__itemclass--singleItem': isSingleItem,
                      'LargeDealRail__itemclass--firstItem': currentIndex === 1
                    } ) }
                >
                  <LargeDealCard
                    item={ product.length }
                    image={ product.image }
                    description={ product.description }
                    tag={ product.tag }
                    eyebrow={ product.eyebrow }
                    headline={ product.headline }
                    action1={ product.actionGroup?.action1 }
                    action2={ product.actionGroup?.action2 }
                    action1Style={ product.actionGroup?.action1Style }
                    action2Style={ product.actionGroup?.action2Style }
                    maxWidthAspectRatio={ maxWidthAspectRatio }
                  />
                </div>
              </SwiperSlide>
            ) )
            }
          </Swiper>
        </div>
      </GridContainer>
    </div>
  );
};

/**
 * Provide aspectRatio of the Image which has max height
 */

export const getMaxAspectRatio = ( data ) => {
  const { deals } = data || {};
  if( !deals ){
    return 1;
  }

  let maxAspectRatio = deals[0].image?.metaData?.aspectRatio.w;
  for ( let i = 1; i < deals.length; i++ ){
    const aspectRatio = deals[i].image?.metaData?.aspectRatio.w;
    maxAspectRatio = Math.min( maxAspectRatio, aspectRatio );
  }
  return maxAspectRatio;
};


/**
  * Default values for passed properties
  * @type {object}
  */
export const defaultProps = {
  ...constants.INTERSECTION_OBSERVER_OPTIONS
};

/**
  * Property type definitions
  *
  * @typedef LargeDealRailProps
  * @type {object}
  * @property {string} title - Sets the title of the ultamate Rewards deals
  * @property {array} deals - Sets the array for the Large Deal
  * @property {string} previousAccessibility - Sets the aria label for previous button.
  * @property {string} nextAccessibility - Sets the aria label for next button.
  * @property {string} carouselAccessibility - Set the value for aria label for carousel
  * @property {string} carouselDetailsAccessibility - sets sr-only text for keyboard users.
  * @property {object} nextClickAction - Action for when the next slide button is pressed
  * @property {object} previousClickAction - Action for when the previous slide button is pressed
  */
export const propTypes = {
  title: PropTypes.string,
  deals: PropTypes.array,
  previousAccessibility: PropTypes.string,
  nextAccessibility: PropTypes.string,
  carouselAccessibility: PropTypes.string,
  carouselDetailsAccessibility:PropTypes.string,
  nextClickAction: PropTypes.object,
  previousClickAction: PropTypes.object
};

LargeDealRail.propTypes = propTypes;
LargeDealRail.defaultProps = defaultProps;

export default LargeDealRail;