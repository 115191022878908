import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24ArrowDown( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <g
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M12 23V1M23 13L11.964 23 1 13' />
      </g>
    </SvgContainer>
  );
}

export default Svg24ArrowDown;
