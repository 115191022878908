import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12Location( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <g
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M10.125 5.044C10.125 8.39 6 11.5 6 11.5S1.875 8.39 1.875 5.044C1.875 2.534 3.594.5 6 .5c2.406 0 4.125 2.034 4.125 4.544h0z' />
        <path d='M8 4.5a2 2 0 11-4 0 2 2 0 014 0h0z' />
      </g>
    </SvgContainer>
  );
}

export default Svg12Location;
