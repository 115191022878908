/**
 * The AsyncComponent is used to dynamically load components at run time
 *
 * @module components/AsyncComponent/AsyncComponent
 * @memberof -Common
 */
import React from 'react';

import PropTypes from 'prop-types';

import ErrorBoundary from '@ulta/core/components/ErrorBoundary/ErrorBoundary';

import LoadableComponent from '@ulta/modules/LoadableComponent/LoadableComponent.cjs';

import * as utils from './AsyncComponent';

/**
 * AsyncComponent
 * @method
 * @param {AsyncModuleProps} props
 * @returns AsyncComponent
 */
export const AsyncComponent = function( props ){
  const { moduleName, modules, meta } = props;

  if( !moduleName && modules ){
    return modules.map( ( component, index ) => {
      return (
        <ErrorBoundary key={ `${component.id}:${index}` }>
          <AsyncComponent { ...component }/>
        </ErrorBoundary>
      );
    } );
  }
  else if( ( moduleName && utils.isComponentValid( moduleName ) ) || meta?.initAction ){
    return (
      <ErrorBoundary>
        <LoadableComponent { ...props }/>
      </ErrorBoundary>
    );
  }

  /**
   * TODO- This should return the static error component
   */
  return null;
};

export const isComponentValid = ( componentName ) => {
  const exclusionList = ['DataCapturePageMetaData'];
  return exclusionList.filter( ( currentComponent ) => currentComponent === componentName ).length === 0;
};

/**
 * @typedef {object} Module
 * @property {string} id component's id
 * @property {?string} moduleName component's module name
 */

/**
 * @typedef {object} AsyncModuleProps
 * @property {string} id component's id
 * @property {?{ initAction: object }} meta component meta
 * @property {?Module[]} modules component's modules
 * @property {?string} moduleName component's module name
 */
export const propTypes = {
  meta: PropTypes.object,
  modules: PropTypes.array,
  moduleName: PropTypes.string,
  allowDefer: PropTypes.bool
};

AsyncComponent.propTypes = propTypes;

export default AsyncComponent;
