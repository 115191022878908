import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16SustainablePackaging( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <g id='SustainablePackaging'>
        <path d='m8.4 13.3-.2.8-.2.9-.2-1-.2-.7zM4 11.5l.5.5-.7.4-.2.2h-.2l-.3.3.5-.7.4-.7zm8 0a6.1 6.1 0 0 0 .6 1l.3.4-.3-.2-.2-.1-.2-.2-.7-.4.3-.2zm-4-8a4.5 4.5 0 1 1 0 9 4.5 4.5 0 0 1 0-9zM5.5 5A4 4 0 0 0 4 8.7c.4 0 .9-.3 1-.5h.4s1.1.3 1.4 1.1c.1.3.2.7 0 1.3-.1.2 0 .8.3 1.2a4 4 0 0 0 4.7-4.7c-.4 0-.7 0-1 .6.1.2.2.7-.1 1-.1.2-.4.3-.8.3h-.1c-.4 0-.7-.2-1-.4C8.4 8.4 8 8 7.7 8c-.8-.2-.7-.9-.6-1.3 0-.6 0-.8-.3-.8-.7-.3-1.2-.6-1.3-.9Zm-.1 3.8c-.2.2-.7.5-1.1.5a4 4 0 0 0 2 2.2c-.1-.3-.2-.8-.1-1.1a1 1 0 0 0 0-.9c0-.3-.5-.6-.8-.7ZM8 4.1a4 4 0 0 0-2 .5v.1s0 .3 1 .6c.7.2.7 1 .6 1.4 0 .6 0 .7.2.8.5 0 1 .3 1.3.6l.7.4.4-.1c.2-.2.1-.5 0-.7l.1-.2c.4-1 1-1 1.3-1A4 4 0 0 0 8 4.1Zm5.3 3.5h.2l.6.2.9.2-1 .2-.7.2zm-10.6 0v.8l-.8-.2L1 8h.5l.1-.1H2l.8-.3zm10.2-4.5-.5.7-.4.7-.2-.3-.3-.2.7-.4.7-.5zM3.1 3l.7.5.7.4-.5.5-.4-.7-.5-.7ZM8 1v.5l.1.1V2l.3.8h-.8v-.2l.2-.6.2-1z'/>
      </g>
    </SvgContainer>
  );
}

export default Svg16SustainablePackaging;
