/**
 * The GridContainer component is used to render components within the Pallete design systems grid system.  Users of the component are able to define hwere the content should start and end within the grid.
 *
 * @module views/components/GridContainer
 * @memberof -Common
 */
import './GridContainer.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import AsyncComponent from '@ulta/core/components/AsyncComponent/AsyncComponent';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { hasItems } from '@ulta/core/utils/array/array';

import * as utils from './GridContainer';

/**
 * Represents a GridContainer component
 *
 * @method
 * @param {GridContainerProps} props - React properties passed from composition
 * @returns GridContainer
 */
export const GridContainer = function( props ){
  const {
    modules,
    children,
    fullBleedContent,
    fullBleedContentWrapper,
    fullBleedContentBackgroundColor
  } = props;

  const { breakpoint } = useDeviceInflection();

  const gridSize = utils.getGridSize( breakpoint.CURRENT_BREAKPOINT );

  const start = `${props[gridSize]?.startColumn || defaultProps[gridSize].startColumn}`;
  const end = `${props[gridSize]?.endColumn || defaultProps[gridSize].endColumn}`;
  const backgroundColor = props[gridSize]?.backgroundColor || fullBleedContentBackgroundColor;

  return (
    <div
      className={ classNames( 'GridContainer', {
        [`GridContainer__fullBleedContent--bg${backgroundColor}`]: backgroundColor
      } ) }
    >
      { fullBleedContent && <div className='GridContainer__fullBleedContent'>{ fullBleedContent }</div> }
      <div
        className={ classNames( 'GridContainer__wrapper', {
          ['GridContainer__fullBleedContentWrapper']: fullBleedContentWrapper
        } ) }
      >
        <div className='GridContainer__content'>
          { hasItems( modules ) && (
            <div className={ `GridContainer__content__startColumn-${start} GridContainer__content__endColumn-${end}` }>
              { modules.map( ( component, index ) => (
                <AsyncComponent key={ `${component.id}:${index}` }
                  { ...component }
                />
              ) ) }
            </div>
          ) }
          { children }
        </div>
      </div>
    </div>
  );
};

/**
 * getGridSize method will fetch the gridSize based on the device inflection
 * @param { object } data : holds the current screen size
 * @returns gridConfig
 */

export const getGridSize = ( data ) => {
  let gridSize = '';

  switch ( data ){
    case 'XL':
    case 'LG':
      gridSize = 'large';
      break;
    case 'MD':
      gridSize = 'medium';
      break;
    case 'SM':
    case 'XS':
      gridSize = 'small';
      break;
    default:
      gridSize = 'large';
      break;
  }

  gridSize = `${gridSize}GridConfig`;

  return gridSize;
};

/**
 * Property type definitions
 * @typedef GridContainerProps
 * @type {object}
 * @property {object} mediumGridConfig - medium object for column
 * @property {object} largeGridConfig - large object for column
 * @property {array} modules - modules
 * @property {bool} fullBleedContentWrapper - set full bleed content wrapper flag
 */
export const propTypes = {
  smallGridConfig: PropTypes.object,
  mediumGridConfig: PropTypes.object,
  largeGridConfig: PropTypes.object,
  modules: PropTypes.array,
  fullBleedContentWrapper: PropTypes.bool
};

/**
 * Default values for passed properties
 *
 * @type {object}
 * @property {object} medium - default props for medium object
 * @property {object} large - default props for large object
 */
export const defaultProps = {
  smallGridConfig: {
    startColumn: '1',
    endColumn: '6'
  },
  mediumGridConfig: {
    startColumn: '1',
    endColumn: '6'
  },
  largeGridConfig: {
    startColumn: '1',
    endColumn: '12'
  }
};

GridContainer.propTypes = propTypes;
GridContainer.defaultProps = defaultProps;

export default GridContainer;
