import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24TikTok( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <path
        d='M12,0 C5.373,0 0,5.373 0,12 C0,18.627 5.373,24 12,24 C18.627,24 24,18.627 24,12 C24,5.373 18.627,0 12,0 Z M19,10.5612541 C17.6238164,10.5645457 16.281382,10.1353849 15.1623409,9.33439868 L15.1623409,14.9207325 C15.1615629,17.0394525 13.8460752,18.9352816 11.8615801,19.677659 C9.87708505,20.4200364 7.64021894,19.8531032 6.24893932,18.2551364 C4.8576597,16.6571696 4.60405983,14.3636556 5.61261828,12.5003574 C6.62117673,10.6370592 8.68015093,9.59516821 10.7789009,9.88609291 L10.7789009,12.6958263 C9.81686914,12.3932361 8.76931501,12.743156 8.18231067,13.5631788 C7.59530633,14.3832015 7.60181152,15.4875874 8.1984352,16.3006389 C8.79505887,17.1136904 9.84666246,17.4512468 10.8050629,17.1373457 C11.7634634,16.8234445 12.4115777,15.9291863 12.4115549,14.9207325 L12.4115549,4 L15.1623409,4 C15.1604302,4.23228672 15.1798928,4.46425675 15.2204826,4.69297796 L15.2204826,4.69297796 C15.4136667,5.72488577 16.0231435,6.63176634 16.9056516,7.20045166 C17.5268883,7.61120351 18.2552397,7.83013347 19,7.82997697 L19,10.5612541 Z'
        fill='currentColor'
        fillRule='nonzero'
      />
    </SvgContainer>
  );
}

export default Svg24TikTok;
