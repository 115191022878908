/**
 * PrimaryLinks component will be responsible to render primary links of header section.
 *
 * @module views/components/PrimaryLinks
 * @memberof -Common
 */
import './PrimaryLinks.scss';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import UseKeyPress from '@ulta/core/hooks/useKeyPress/UseKeyPress';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { useUserContext } from '@ulta/core/providers/UserContextProvider/UserContextProvider';
import { isServer } from '@ulta/core/utils/device_detection/device_detection';
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';

import { constants } from '@ulta/utils/constants/constants';

import NavigationLink from '../NavigationLink/NavigationLink';
import { useTopBar } from '../TopBar/TopBar';

const { ESC_KEY } = constants;

/**
 * Represents a PrimaryLinks component
 *
 * @method
 * @param {PrimaryLinksProps} props - React properties passed from composition
 * @returns PrimaryLinks
 */
export const PrimaryLinks = React.forwardRef( ( props, _ ) => {
  const { modules } = props;
  const navLinks = useMemo( () => modules?.map( i => getNavLinks( i ).flat() ).flat(), [modules] );
  const { user } = useUserContext();
  const {
    setShowBack,
    setMenuNavigation,
    displayPrimaryNavigationLinks,
    activePrimaryLinks,
    setActivePrimaryLinks,
    setActiveNavigationLinksGroup
  } = useTopBar();
  const { inflection } = useDeviceInflection();
  const isMobile = inflection.MOBILE;
  const divRef = useRef();
  const [index, setIndex] = useState( null );

  useEffect( () => {
    setShowBack( null );
    setMenuNavigation( { forward: false } );
  }, [isMobile] );

  // TODO: Make exported helper
  const onOpenLeftFlyout = useCallback( ( data ) => {
    const { linkItem = {} } = handleEmptyObjects( data );
    setActiveNavigationLinksGroup( null );
    setActivePrimaryLinks( linkItem.action?.label );
    setShowBack( 'Menu' );
    setMenuNavigation( { forward: true, backward: false } );
    setIndex( linkItem.id );
    document.body.classList.add( 'leftNavFlyoutOpen' );
  }, [setActiveNavigationLinksGroup, setActivePrimaryLinks, setShowBack, setMenuNavigation] );

  UseKeyPress( ESC_KEY, divRef, () => {
    setActivePrimaryLinks( null );
    setActiveNavigationLinksGroup( null );
    document.body.classList.remove( 'leftNavFlyoutOpen' );
  } );

  // TODO: Make exported helper
  useEffect( () => {
    const focusable = document.querySelector( `.PrimaryLinks__linkButton__${index}` );
    focusable?.focus();
    if( !activePrimaryLinks || isMobile ){
      return;
    }
    const closeFlyout = ( e )=> {
      if( activePrimaryLinks && divRef.current && !divRef.current.contains( e.target ) ){
        setActivePrimaryLinks( null );
        setActiveNavigationLinksGroup( null );
        document.body.classList.remove( 'leftNavFlyoutOpen' );
      }
    };
    document.addEventListener( 'click', closeFlyout );
    return () => {
      document.removeEventListener( 'click', closeFlyout );
    };
  }, [activePrimaryLinks] );

  return (
    <>
      { displayPrimaryNavigationLinks && (
        <ul
          className={ classNames( 'PrimaryLinks', {
            PrimaryLinks__mobileNav: isMobile,
            PrimaryLinks__SignedIn: user?.loginStatus && isMobile
          } ) }
          ref={ divRef }
        >
          {
            navLinks?.map( ( linkItem, index ) => (
              <PrimaryLinksItem
                key={ `${index}-${linkItem.id}` }
                linkItem={ linkItem }
                isMobile={ isMobile }
                activePrimaryLinks={ activePrimaryLinks }
                setShowBack={ setShowBack }
                setMenuNavigation={ setMenuNavigation }
                onOpenLeftFlyout={ onOpenLeftFlyout }
              />
            ) )
          }
        </ul>
      ) }
    </>
  );
} );

/**
 * Primary Links Item component
 * @param {object} props
 * @returns PrimaryLinksItem
 */
export const PrimaryLinksItem = ( props ) => {
  const { activePrimaryLinks, linkItem, isMobile, onOpenLeftFlyout } = props;

  const isActive = activePrimaryLinks === linkItem?.action?.label;
  const renderavigationOverlay = isActive || isServer();
  return (
    <li
      id={ linkItem.id }
      className={ classNames( 'PrimaryLinks__items', {
        PrimaryLinks__mobileNavItems: isMobile,
        'PrimaryLinks__items--active': isActive
      } ) }
    >
      { linkItem.modules && (
        <>
          <Button
            label={ linkItem.action?.label }
            tertiary
            onClick={ () => {
              onOpenLeftFlyout( { linkItem } );
            } }
            className={ classNames( `PrimaryLinks__linkButton`, {
              [`PrimaryLinks__linkButton__${linkItem.id}`]: linkItem.id
            } ) }
            { ...( isMobile && { iconImage: 'CaretForward' } ) }
            iconSize='m'
            iconRight
            action={ linkItem.action }
          />
          { renderavigationOverlay && (
            <NavigationLink
              modules={ linkItem.modules }
              action={ linkItem.action }
              className='PrimaryLinks__linkHuge'
              ariaLabelBy={ linkItem.id }
              isLeftNavigation={ true }
            />
          ) }
        </>
      ) }
      { !linkItem.modules && (
        <div className='PrimaryLinks__link'>
          <NavigationLink action={ linkItem.action }
            description={ linkItem.description }
          />
        </div>
      ) }
    </li>
  );
};

/**
 * Property type definitions
 *
 * @typedef PrimaryLinksProps
 * @type {object}
 * @property {array} modules - sets the primary link navigation menu
 */
const propTypes = {
  modules: PropTypes.array.isRequired
};

PrimaryLinks.propTypes = propTypes;
PrimaryLinks.displayName = 'PrimaryLinks';

export default PrimaryLinks;

export function getNavLinks( item ){
  if( item.moduleName === 'NavigationLink' ){
    return item;
  }
  else if( item.modules ){
    return item.modules.map( submodule => getNavLinks( submodule ) );
  }
  else {
    return [];
  }
}