import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16Search( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <g fill='none'
        fillRule='evenodd'
        stroke='currentColor'
      >
        <circle cx={ 5.5 }
          cy={ 5.5 }
          r={ 5 }
        />
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M15.01 15.5L9 9.5'
        />
      </g>
    </SvgContainer>
  );
}

export default Svg16Search;
