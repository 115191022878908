import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16X( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <g
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M.5.5l15 15M15.5.5l-15 15' />
      </g>
    </SvgContainer>
  );
}

export default Svg16X;
