import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24Search( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <g fill='none'
        fillRule='evenodd'
        stroke='currentColor'
      >
        <circle cx={ 8.5 }
          cy={ 8.5 }
          r={ 8 }
        />
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M23.5 23.5l-9-9'
        />
      </g>
    </SvgContainer>
  );
}

export default Svg24Search;
