import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24BagCountIcon( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
      viewBox='0 0 24 24'
      version='1.1'
    >
      <g id='Base/Icons/UI/bag'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path d='M9.469,5.395 L14.531,5.395 C14.608,5.146 14.647,4.885 14.647,4.618 C14.647,3.154 13.462,1.969 12,1.969 C10.538,1.969 9.353,3.154 9.353,4.618 C9.353,4.885 9.392,5.145 9.469,5.395 L9.469,5.395 Z M1,6.063 C1.031,5.719 1.295,5.448 1.629,5.401 L1.731,5.394 L7.959,5.395 C7.91,5.141 7.886,4.88 7.886,4.618 C7.886,2.344 9.728,0.5 12,0.5 C14.272,0.5 16.115,2.344 16.115,4.618 C16.115,4.881 16.089,5.141 16.04,5.395 L22.27,5.394 L22.371,5.401 C22.704,5.448 22.97,5.719 23,6.063 L21.535,22.8 C21.518,23.186 21.199,23.5 20.802,23.5 L3.198,23.5 C2.8,23.5 2.482,23.186 2.465,22.8 L1,6.063 Z M20.001,22.032 L21.475,6.862 L2.525,6.862 L3.998,22.032 L20.001,22.032 Z'
          id='Icon-Color'
          fill='#000000'
          fillRule='nonzero'
        ></path>
      </g>
    </SvgContainer>
  );
}

export default Svg24BagCountIcon;
