/**
 * Provides additional contect and details about in-page content.
 *
 * @module views/components/Tooltip
 * @memberof -Common
 */
import './Tooltip.scss';

import React, { createRef, useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Icon from '@ulta/core/components/Icon/Icon';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import { isMobileDisplay } from '@ulta/core/utils/device_detection/device_detection';

import * as utils from './Tooltip';

/**
 * Represents a Tooltip component
 *
 * @method
 * @param {TooltipProps} props - React properties passed from composition
 * @returns Tooltip
 */

export const Tooltip = ( props ) => {
  const [tooltipOpen, setTooltipOpen] = useState( false );
  const ref = useCallback( node => {
    if( node !== null ){
      ref.current = node;
    }
  }, [] );
  const caretRef = createRef();
  const closeRef = createRef();
  const focusRef = createRef();

  useEffect( () => {
    if( !global || !tooltipOpen ){
      if( isMobileDisplay() ){
        // Set initial position inside viewport on mobile devices
        utils.setInitialPos( { ref, focusRef } );
      }
      return;
    }

    const close = utils.closeTooltip( { ref, focusRef }, { setTooltipOpen } );
    global.addEventListener( 'click', close, { once: true } );
    global.addEventListener( 'resize', close );

    return () => {
      global.removeEventListener( 'click', close );
      global.removeEventListener( 'resize', close );
    };
  }, [tooltipOpen] );

  if( !props.message ){
    return null;
  }

  return (
    <div className='Tooltip'>
      <Button
        variant='unstyled'
        icon
        className='Tooltip__button'
        iconImage={ props.actionIcon }
        iconSize='m'
        isExpanded={ tooltipOpen }
        ariaLabel={ props.accessibilityLabel }
        ariaControls='TooltipContent'
        ariaHiddenIcon={ true }
        ref={ focusRef }
        onClick={ utils.openTooltip( { ref, caretRef, closeRef }, { setTooltipOpen } ) }
      />
      <div
        ref={ ref }
        className='Tooltip__container'
      >
        <div className='Tooltip__wrapper'>
          <div
            className='Tooltip__caret'
            ref={ caretRef }
          >
            <div className='Tooltip__caret--shape'></div>
          </div>
          <div className='Tooltip__content'
            id='TooltipContent'
            aria-live='polite'
            role='tooltip'
          >
            {
              props.icon &&
              <div className='Tooltip__icon'>
                <Icon className={ `Tooltip__icon--${props.icon}` }
                  size='m'
                  name={ props.icon }
                  aria-hidden={ true }
                />
              </div>
            }
            <div className='Tooltip__message'>
              <Text
                htmlTag='p'
                textStyle='body-3'
                textAlign='left'
              >
                { props.message }
              </Text>
            </div>
            <div className='Tooltip__close'>
              <Button
                variant='unstyled'
                icon
                ariaLabel={ props.dismissLabel }
                aria-hidden={ true }
                iconImage='X'
                type='button'
                ref={ closeRef }
                onClick={ utils.closeTooltip( { ref, focusRef }, { setTooltipOpen } ) }
              />
            </div>
          </div>
          {
            props.linkAction && props.linkAction.label &&
            <div className='Tooltip__action'>
              <Link_Huge
                compact
                action={ props.linkAction }
                withHover
              >
                { props.linkAction.label }
              </Link_Huge>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

/**
 * Sets the tooltip initial position
 *
 * @method setInitialPos
 * @param {object} data - Data passed as an argument
 * @param {object} data.ref - Tooltip ref passed in data
 * @param {object} data.focusRef - Tooltip open button ref
 * @returns left: calculatedPosition
 */
export const setInitialPos = ( data ) => {
  const { ref = {}, focusRef = {} } = data || {};
  const buttonLeft = focusRef.current?.getBoundingClientRect().left / 16; // px to rem
  if( ref.current ){
    ref.current.style.left = '-' + ( buttonLeft - 1.25 ) + 'rem';
  }
};

/**
 * Closes the tooltip
 *
 * @method closeTooltip
 * @param {object} data - Data passed as an argument
 * @param {object} data.ref - Tooltip ref passed in data
 * @param {object} data.focusRef - Tooltip open button ref
 * @param {object} methods - Methods passed as an argument
 * @param {function} methods.setTooltipOpen - setTooltipOpen local state method passed as an argument
 * @returns visibility: hidden
 */
export const closeTooltip = ( data, methods ) => {
  const { ref = {}, focusRef = {} } = data || {};
  const { setTooltipOpen } = methods || {};
  if( ref.current?.style.visibility === 'visible' ){
    return () => {
      ref.current.style.visibility = 'hidden';
      setTooltipOpen( false );
      focusRef.current?.focus();
    };
  }
};

/**
 * Opens the tooltip
 *
 * @method openTooltip
 * @param {object} data - Data passed as an argument
 * @param {object} data.caretRef - Tooltip caret ref passed in data
 * @param {object} data.ref - Tooltip ref passed in data
 * @param {object} data.closeRef - Tooltip close button ref
 * @param {object} methods - Methods passed as an argument
 * @param {function} methods.setTooltipOpen - setTooltipOpen local state method passed as an argument
 * @returns visibility: visible
 */
export const openTooltip = ( data, methods ) => {
  const { caretRef = {}, ref = {}, closeRef = {} } = data || {};
  const { setTooltipOpen } = methods || {};
  return ( e ) => {
    const y = e.clientY / 16; // click position on y axis
    const x = e.clientX / 16; // click position on x axis
    const windowHeight =  global.innerHeight / 16;
    const spaceFromBottom = windowHeight - y;
    const windowWidth = global.innerWidth / 16;
    const rect = e.target.getBoundingClientRect(); // tooltip dimensions
    const tooltipHeight = ref.current.clientHeight / 16;
    const tooltipWidth = ref.current.clientWidth / 16; // width in rems (330px)

    /*
     * set tooltip position on open
     */

    // set tooltip position on x axis and move caret accordingly
    if( x <= tooltipWidth ){
      if( windowWidth - x <= tooltipWidth ){
        ref.current.style.left = ( ( windowWidth - tooltipWidth ) - x ) + 'rem';
        ref.current.style.right = 'auto';
        caretRef.current.style.left = ( x - 3 ) + 'rem';
        caretRef.current.style.right = 'auto';
      }
      else {
        ref.current.style.left = 0;
        ref.current.style.right = 'auto';
        caretRef.current.style.left = 0;
        caretRef.current.style.right = 'auto';
      }
    }
    else {
      ref.current.style.left = 'auto';
      ref.current.style.right = '-0.25rem';
      caretRef.current.style.right = 0;
      caretRef.current.style.left = 'auto';
    }
    // set tooltip position on y axis and flip caret if tooltip too close to bottom
    if( spaceFromBottom < ( tooltipHeight + 6 ) ){
      ref.current.style.top = 'auto';
      ref.current.style.bottom = ( rect.height * 1.25 ) / 16 + 'rem';
      caretRef.current.classList.add( 'Tooltip__caret--flipped' );
    }
    else {
      ref.current.style.top = ( rect.height * 1.25 ) / 16 + 'rem';
      ref.current.style.bottom = 'auto';
      caretRef.current.classList.remove( 'Tooltip__caret--flipped' );
    }
    ref.current.style.visibility = 'visible';
    setTooltipOpen( true );
    closeRef.current.focus();
  };
};

/**
 * Property type definitions
 * @typedef TooltipProps
 * @type {object}
 * @property {string} message - Tooltip message
 * @property {string} icon - Optional icon inside Tooltip
 * @property {string} accessibilityLabel - ADA label for the icon button
 * @property {string} dismissLabel - ADA label to close the Tooltip
 * @property {object} linkAction - Sets the linkAction properties
 * @property {string} actionIcon - Sets icon for Tooltip button
 */
export const propTypes = {
  message: PropTypes.string.isRequired,
  icon: PropTypes.string,
  accessibilityLabel: PropTypes.string,
  dismissLabel: PropTypes.string,
  linkAction: PropTypes.object,
  actionIcon: PropTypes.string
};

/**
 * Default values for passed properties
 *
 * @type {object}
 * @property {string} actionIcon='QuestionCircle' - Sets a default icon to display for the button to open the Tooltip.
 */
export const defaultProps = {
  actionIcon: 'QuestionCircle'
};

Tooltip.propTypes = propTypes;
Tooltip.defaultProps = defaultProps;

export default Tooltip;