/**
 * Updated for Checkout 2.0: This version of Address form was created to accept the input field as an object
 * with attached validations and other attributes
 * The AddressForm component is a collection of form fields required to capture a user's address and phone, containing Address, Address 2, City, State, Country and Mobile Number.
 *
 * @module views/Molecules/AddressForm
 * @memberof -Common
 */
import './AddressForm.scss';

import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import InputField from '@ulta/core/components/InputField/InputField';
import SelectField_New from '@ulta/core/components/SelectField_New/SelectField_New';

import constants from '@ulta/utils/constants/constants';

/**
 * Represents a AddressForm component
 *
 * @method
 * @param {AddressFormProps} props - React properties passed from composition
 * @returns AddressForm
 */
export const AddressForm = function( props ){
  const {
    address1,
    address2,
    addressLine2Label,
    city,
    state,
    postalCode,
    showAddress2ByDefault
  } = props;

  const [showAddress2, setShowAddress2] = useState( showAddress2ByDefault );

  return (
    <div className='AddressForm'>
      { address1 &&
        <InputField { ...address1 }
          autoComplete='address-line1'
        />
      }
      { !showAddress2 &&
        <div className='AddressForm__button'>
          <Button
            label={ addressLine2Label }
            onClick={ () => setShowAddress2( true ) }
            withHover
            tertiary
            tiny
            iconImage={ 'Add' }
            ariaHiddenIcon={ true }
          />
        </div>
      }
      { showAddress2 &&
        <div className='AddressForm__field--address2'>
          <InputField { ...address2 }
            autoComplete='address-line2'
          />
        </div>
      }
      { city &&
        <div className='AddressForm__field'>
          <InputField { ...city }
            autoComplete='address-level2'
          />
        </div>
      }

      <div className='AddressForm__row'>
        { state &&
        <div className='AddressForm__row--child'>
          <SelectField_New
            { ...state }
            autoComplete='address-level1'
          />
        </div>
        }
        { postalCode &&
        <div className='AddressForm__row--child'>
          <InputField
            { ...postalCode }
            formatter={ constants.FORMATTER_PATTERNS.ZIPCODE_FORMATTER }
            autoComplete='postal-code'
          />
        </div>
        }
      </div>
    </div>
  );
};

/**
 * Property type definitions
 * @typedef AddressFormProps
 * @type {object}
 * @property {object} address1 - Sets the label value for address
 * @property {object} address2 - Sets the address2 button label
 * @property {string} address2Label - Sets the label value for address2
 * @property {object} city - Sets the label value for city
 * @property {object} state - Sets the label value for state
 * @property {object} postalCode - Sets the label value for postal code
 *
 */
export const propTypes = {
  /** Sets the label for address */
  address1: PropTypes.object,
  /** Sets the label for address line 2 */
  address2: PropTypes.object,
  /** Sets the label for address2 */
  address2Label: PropTypes.object,
  /** Sets the label for city */
  city: PropTypes.object,
  /** Sets the label for state */
  state: PropTypes.object,
  /** Sets the label for postalCode */
  postalCode: PropTypes.object
};

AddressForm.propTypes = propTypes;

export default AddressForm;