import constants from '@ulta/core/utils/constants/constants';

/**
 * Passes a specific configuration to the footer module
 * @method updatedFooterWithPageConfiguration
 * @param {object} data - Data argument
 * @returns object - Footer Module
 */
export const updatedFooterWithPageConfiguration = ( data ) => {
  const { footer = [], configuration = {} } = data || {};
  const [footerModule, ...rest] = footer;

  const decorated = rest.map( ( module ) => {
    const m = { ...module };
    if( m?.meta?.initAction && m?.moduleName === constants.HFN.IncrementalModuleName ){
      m.meta.initAction.cachePath = constants.HFN.footer;
    }
    return m;
  } );

  return [{ ...footerModule, ...configuration }, ...decorated];
};
