import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12VideoFilled( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <path
        stroke='currentColor'
        d='M11 6l-9 5V1z'
        fill='currentColor'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg12VideoFilled;
