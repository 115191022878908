import constants from '../constants/constants';
import { isServer } from '../device_detection/device_detection';
import { isStagingEnvironment } from '../domain/domain';
import { handleEmptyObjects } from '../handleEmptyObjects/handleEmptyObjects';
import * as utils from './queryProcessor';

/**
 * Function to process the action received from dxl reponse
 * it sets action to setQuery if there is a graphQl in the action
 * if pop is true in the action response then if it closes the ovelay
 * and then invokes handleDXLNavigationType
 *
 * @param {object} data arguments
 * @param {object} data.action DXL action
 * @param {object} data.config Additional apollo config
 */
export const queryProcessor = ( data ) => {
  const { action = {}, config = {} } = handleEmptyObjects( data );
  const { graphql } = action;

  if( !graphql ){
    return;
  }

  // Get query type
  const queryType = utils.getQueryType( { query: graphql } );
  const isMutation = queryType === DXL_QUERY_TYPE.Mutation;
  const isNonCachedQuery = queryType === DXL_QUERY_TYPE.NonCachedPage;
  const isPageQuery = queryType === DXL_QUERY_TYPE.Page;

  // Derive query config
  const queryID = utils.getQueryId( { query: graphql, config } );
  const fetchPolicy =  isNonCachedQuery ? 'no-cache' : config.fetchPolicy || 'cache-first';
  const headers = utils.getHeaders( { action, isPageQuery, queryID } );

  // Build config object
  action.config = {
    ...config,
    ...( !isMutation && { fetchPolicy } )
  };

  if( Object.keys( headers ).length ){
    action.context = {
      headers
    };
  }

  // Prepare variables
  if( isNonCachedQuery ){
    action.variables = action.variables || {};
    action.variables.cacheBust = Date.now();
  }

  return {
    ...action,
    isMutation,
    isNonCachedQuery,
    isPageQuery
  };
};

/**
 * Generate query ID
 * @param {object} data - Arguments
 * @param {string} data.query - Graph QL Query
 * @param {object} data.config - Query config object
 * @returns {string} - Query ID
 */
export const getQueryId = ( data ) => {
  const { query, config = {} } = handleEmptyObjects( data );

  if( !query ){
    return '';
  }

  const suffix = config.variables?.url?.path ? `::${config.variables.url.path }` : '';

  return `${query.split( '(' )[0].split( ' ' )[1] }${suffix}`;
};


/**
 * Determines type of query
 * @param {object} data - Arguments
 * @param {string} data.query - Graph QL query
 * @returns {DXL_QUERY_TYPE}
 */
export const getQueryType = ( data ) => {
  const { query } = data || {};

  if( !query ){
    return null;
  }

  if( query?.substring( 0, 10 ).includes( 'mutation' ) ){
    return DXL_QUERY_TYPE.Mutation;
  }
  else if( query.includes( 'query Page(' ) ){
    return DXL_QUERY_TYPE.Page;
  }if( query.includes( 'query NonCachedPage(' ) ){
    return DXL_QUERY_TYPE.NonCachedPage;
  }
  else {
    return null;
  }
};

/**
 * @const {object} DXL_QUERY_TYPE - DXL Query types
 */
export const DXL_QUERY_TYPE = {
  Mutation: 'Mutation',
  Page: 'Page',
  NonCachedPage: 'NonCachedPage'
};

/**
 * Gets the headers neccesary for the dxl tests based on the context of the call
 *
 * @method
 * @param { boolean } isPageQuery - if this is a page or a mutation call
 * @param { string } queryID - the dxl call identifier
 * @returns Object
 */
export const getHeaders = ( data ) => {
  const { action = {}, isPageQuery, queryID, stagingHost, previewDate } = handleEmptyObjects( data );
  const { cachePath, variables = {}, customHeaders = [], location = {}, stk } = action;

  const isPreviewMode = isStagingEnvironment( location.hostname );

  // Process action's custom headers
  const actionHeaders = {};
  customHeaders.forEach( ( header ) => {
    const { key, value } = header || {};

    if( key ){
      actionHeaders[key] = key === X_ULTA_GRAPH_PAGE_URL ?
        utils.addModuleParamsToUrl( { url: value, moduleParams: variables.moduleParams } ) : value;
    }
  } );

  const setCacheableHeader = ( isPageQuery && !isServer() && !isPreviewMode );

  let graphPageUrl;
  if( !!cachePath ){
    const url = new URL( location.href );

    // For the MainWrapper IncrementalModule cache headers we just leave it as is,
    // full path to the page we are currently on along w/ the existing search params
    if( cachePath === constants.HFN.href ){
      const urlParams = new URLSearchParams( url.search );
      const moduleParams  = new URLSearchParams( variables.moduleParams || {} );

      // Combine moduleParams and urlParams, moduleParams will overwrite
      url.search = new URLSearchParams( {
        ...Object.fromEntries( urlParams ),
        ...Object.fromEntries( moduleParams )
      } ).toString();
    }
    // All cacheable header entries besides the IncrementalModule for MainWrapper
    // have a `cachePath` property that should replace the `pathname` on the url.
    else {
      // We don't want to hang on to the current URLs search params, we just want
      // to serialize the moduleParams object and use that as the search params
      url.search = new URLSearchParams( variables.moduleParams || {} );
      url.pathname = cachePath;
    }

    graphPageUrl = url.href;
  }

  return {
    'X-ULTA-DXL-QUERY-ID': isPreviewMode ? PREVIEW_QUERYID : queryID,
    ...actionHeaders,
    ...( !isServer() && stk && { [X_ULTA_DYN_SESSION_CONFIG]: stk } ),
    ...( !!previewDate && { [CLIENT_PREVIEWDATE_HEADER]: previewDate } ),
    ...( !!stagingHost && { [CLIENT_STAGING_HOST_HEADER]: stagingHost } ),
    // setting the cacheable header for page query whem it is not server | preview mode | staging host
    ...( setCacheableHeader && { [X_ULTA_GRAPH_TYPE]: 'page' } ),
    ...( !!graphPageUrl && { [X_ULTA_GRAPH_PAGE_URL]: graphPageUrl } )
  };
};

/**
 * Adds module parameters to the URL if they are not already present.
 * @param {Object} data - An object containing the URL and module parameters.
 * @param {string} data.url - The URL to which module parameters should be added.
 * @param {Object} data.moduleParams - A key-value object representing the module parameters to add.
 */
export const addModuleParamsToUrl = ( data ) => {
  const { url, moduleParams } = handleEmptyObjects( data );
  const urlObj = new URL( url );
  const searchParams = new URLSearchParams( urlObj.search );
  Object.keys( moduleParams ).forEach( key => {
    if( !searchParams.has( key ) ){
      searchParams.set( key, moduleParams[key] );
    }
  } );
  urlObj.search = searchParams.toString();
  return urlObj.href;
};

export const PREVIEW_QUERYID = 'previewEnv';

export const X_ULTA_GRAPH_PAGE_URL = 'X-ULTA-GRAPH-PAGE-URL';
export const X_ULTA_DYN_SESSION_CONFIG = 'X-ULTA-DYN-SESSION-CONFIG';
export const X_ULTA_GRAPH_TYPE = 'X-ULTA-GRAPH-TYPE';

export const PREVIEWDATE_COOKIE = 'X-ULTA-PREVIEW-DATE';
export const CLIENT_PREVIEWDATE_HEADER = 'X-ULTA-CLIENT-PREVIEWDATETIME';
export const CLIENT_PREVIEWDATE_ID = 'previewDate';

export const STAGING_HOST_COOKIE = 'X-ULTA-STAGING-HOST';
export const CLIENT_STAGING_HOST_HEADER = 'X-ULTA-CLIENT-STAGINGHOST';
export const CLIENT_STAGING_HOST_ID = 'stagingHost';
