import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16Menu( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <g
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
        stroke='currentColor'
      >
        <path d='M2.5 8h13M4.5 15.5h11M.5.5h15' />
      </g>
    </SvgContainer>
  );
}

export default Svg16Menu;
