/**
 * The LayerHostProvider is used to provide context to the layerhosts about the context of the requests that are being broadcast over th eapplication
 *
 * @module views/components/LayerHostProvider
 * @memberof -Common
 */
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { debugModuleName, devLogger, LOG_TOPIC, logTopic } from '@ulta/core/utils/devMode/devMode';
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';
import { handleReload } from '@ulta/core/utils/handleLocation/handleLocation';

import { isFunction } from '@ulta/utils/Validations/Validations';

import * as utils from './LayerHostProvider';

/**
 * Represents a LayerHostProvider component
 *
 * @method
 * @param {object} props - React properties passed from composition
 * @returns LayerHostProvider
 */
export const LayerHostProvider = function( props ){
  const [requestStackResolving, setRequestStackResolving] = useState( false );
  const [isMutationInFlight, setIsMutationInFlight] = useState( false );

  const dxlRequestStack = useRef( [] );
  const sessionAttempts = useRef( 0 );
  const pageRefreshes = useRef( 0 );
  const killSwitchActivated = useRef( false );

  const broadcastCallbacks = useRef( {} );
  const broadcast = useCallback( utils.composeBroadcast( { broadcastCallbacks } ), [] );
  const onBroadcast = useCallback( utils.composeOnBroadcast( { broadcastCallbacks } ), [] );
  const getBroadcastCache = useCallback( utils.composeGetBroadcastCache(), [] );

  const [sessionRecursionTick] = useRequestRecursionKillSwitch( {
    sessionAttempts,
    pageRefreshes,
    killSwitchActivated
  } );

  const incrementSessionAttempts = useCallback(
    utils.composeIncrementKillSwitchVariable(
      { ref: sessionAttempts, title: 'sessionAttempts' },
      { sessionRecursionTick }
    ),
    [sessionRecursionTick]
  );

  const incrementPageRefreshes = useCallback(
    utils.composeIncrementKillSwitchVariable( { ref: pageRefreshes, title: 'pageRefreshes' }, { sessionRecursionTick } ),
    [sessionRecursionTick]
  );

  return (
    <LayerHostContext.Provider
      value={ {
        broadcast,
        onBroadcast,
        getBroadcastCache,
        isMutationInFlight,
        setIsMutationInFlight,
        // request stack / in flight request tracking
        dxlRequestStack,
        requestStackResolving,
        setRequestStackResolving,
        // kill switch / disaster recovery
        killSwitchActivated,
        sessionAttempts,
        incrementSessionAttempts,
        incrementPageRefreshes
      } }
    >
      { props.children }
    </LayerHostContext.Provider>
  );
};

/**
 * Dispatches a broadcast based on content ID
 * @param {object} data - data object
 * @param {object} data.id - id of the broadcast
 * @param {object} data.broadcastCallbacks - broadcastCallbacks ref
 */
export const composeBroadcast = ( data ) => ( eventData ) => {
  const { broadcastCallbacks = {} } = handleEmptyObjects( data );
  const { id, content } = handleEmptyObjects( eventData );

  if( eventData?.id ){
    utils.BROADCAST_DB[id] = eventData;
  }

  if( !isFunction( broadcastCallbacks?.current?.[id] ) ){
    devLogger( `[Layerhost Broadcast] No receiver callback found for "${debugModuleName( content )}" (${id})`, 1 );
    return;
  }

  broadcastCallbacks.current[id]( eventData );
};

/**
 * Registers a broadcast callback to the broadcastCallbacks ref
 * @param {object} data - data object
 * @param {object} data.id - id of the broadcast
 * @param {object} data.broadcastCallbacks - broadcastCallbacks ref
 * @param {object} methods - methods object
 * @param {object} methods.callback - callback function
 * @returns {function} - returns a function that removes the callback from the broadcastCallbacks ref
 */
export const composeOnBroadcast = ( data ) => ( eventData, methods ) => {
  const { broadcastCallbacks = {} } = handleEmptyObjects( data );
  const { id } = handleEmptyObjects( eventData );
  const { callback } = methods || {};

  broadcastCallbacks.current[id] = callback;

  return () => {
    delete broadcastCallbacks.current[id];
  };
};

/**
 * @const {object} BROADCAST_DB - global variable name for debugging
 */
export const BROADCAST_DB = {};

/**
 * Grabs module from cache and returns it to the callback
 * @param {object} _ - not used
 * @param {object} methods - callbacks
 * @returns {function}
 */
export const composeGetBroadcastCache = () => ( data, methods ) => {
  const { id } = data || {};
  const { callback } = methods || {};

  if( !id || !utils.BROADCAST_DB[id] ){
    return;
  }

  callback( utils.BROADCAST_DB[id] );
};

/**
 * Increases the a kill switch counter and triggers the sessionRecursionTick
 *
 * @param {object} data - data object
 * @param {object} data.ref - attempts ref
 * @param {object} methods - methods object
 * @param {object} methods.sessionRecursionTick - sessionRecursionTick method
 */
export const composeIncrementKillSwitchVariable = ( data, methods ) => ( value ) => {
  const { ref = {}, title } = handleEmptyObjects( data );
  const { sessionRecursionTick } = methods || {};

  if( !sessionRecursionTick ){
    return;
  }

  ref.current += 1;
  devLogger( { topic: LOG_TOPIC.Session, title: `[Session] ${title} #${ref.current}`, value } );
  sessionRecursionTick();
};

/**
 * Tracks the amount of page refreshes, session actions, and requests we've attempted
 * and exposes a status to the application so that we can show users some error
 * feedback in disaster scenarios
 */
export const useRequestRecursionKillSwitch = ( data ) => {
  const { sessionAttempts = {}, pageRefreshes = {}, killSwitchActivated = {} } = handleEmptyObjects( data );
  const { maxSessionAttempts, maxPageRefreshes, duration } = SESSION_KILLSWITCH_CONFIG;

  const disasterTimer = useRef();

  const sessionRecursionTick = useCallback( () => {
    if( disasterTimer.current ){
      clearTimeout( disasterTimer.current );
    }

    disasterTimer.current = setTimeout( () => {
      devLogger( {
        topic: LOG_TOPIC.Session,
        title: `[Session] Kill Switch Reset after ${duration}ms`,
        value: { sessionAttempts, pageRefreshes }
      } );

      sessionAttempts.current = 0;
      pageRefreshes.current = 0;
      killSwitchActivated.current = false;
    }, duration );

    if( sessionAttempts.current >= maxSessionAttempts || pageRefreshes.current >= maxPageRefreshes ){
      devLogger( {
        topic: LOG_TOPIC.Session,
        title: '[Session] Kill Switch Activated',
        value: { sessionAttempts, pageRefreshes }
      } );

      killSwitchActivated.current = true;
      clearTimeout( disasterTimer.current );
      handleReload();
    }
  }, [] );

  return [sessionRecursionTick];
};

export const SESSION_KILLSWITCH_CONFIG = {
  maxSessionAttempts: 5,
  maxPageRefreshes: 3,
  duration: 5000
};

/**
 * The request tracker manages the # of active requests by tracking the loading status of each request
 *
 * @param {object} data - arguments
 * @param {boolean} data.loading - request loading status
 */
export const useRequestTracking = ( data ) => {
  const { debugLabel, loading } = data || {};
  const { dxlRequestStack, setRequestStackResolving } = utils.useLayerHostContext();

  const prev = useRef( loading );

  useEffect( () => {
    // If debugging we can inspect/watch the window object for the request stack
    if( logTopic( { topic: LOG_TOPIC.RequestStack } ) ){
      global[__DXL_REQUEST_STACK__] = dxlRequestStack.current;
    }
  }, [] );

  useEffect( () => {
    if( prev.current === loading ){
      return;
    }

    const requestLabel = `${debugLabel}-${loading}-${Date.now()}`;

    if( loading ){
      dxlRequestStack.current.push( requestLabel );
    }
    else {
      dxlRequestStack.current.pop();
    }

    // If debugging we can inspect/watch the window object for the request stack
    if( logTopic( { topic: LOG_TOPIC.RequestStack } ) ){
      global[__DXL_REQUEST_HISTORY__] = global[__DXL_REQUEST_HISTORY__] || [];
      global[__DXL_REQUEST_HISTORY__].push( requestLabel );
    }

    setRequestStackResolving( dxlRequestStack.current.length !== 0 );
    prev.current = loading;
  }, [debugLabel, loading] );
};

/**
 * @const {string} __DXL_REQUEST_STACK__ - global variable name for debugging
 */
export const __DXL_REQUEST_HISTORY__ = '__DXL_REQUEST_HISTORY__';
export const __DXL_REQUEST_STACK__ = '__DXL_REQUEST_STACK__';

/**
 * Context context object for react reuse
 * @type object
 */
export const LayerHostContext = createContext( {
  dxlRequestStack: { current: [] },
  setRequestStackResolving:() => {},
  requestStackResolving:() =>{}
} );

/**
 * context provider
 * @type object
 */
export const useLayerHostContext = () => useContext( LayerHostContext );

export default LayerHostProvider;

