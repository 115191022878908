import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12StarOutlined( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <path
        d='M5.999 1c.136 0 .262.076.324.198L7.81 4.144l2.862.284a.358.358 0 01.222.61L8.54 7.373l.873 3.172a.361.361 0 01-.512.415L6 9.524 3.1 10.959a.361.361 0 01-.511-.416l.873-3.172-2.357-2.335a.358.358 0 01.222-.61l2.862-.284 1.485-2.944A.364.364 0 015.999 1zm0 1.036L4.785 4.442a.667.667 0 01-.462.354l-.067.01-2.215.218 1.891 1.873a.667.667 0 01.188.587l-.014.064-.692 2.512 2.289-1.134a.667.667 0 01.528-.027l.064.027 2.292 1.135-.691-2.511a.667.667 0 01.13-.603l.043-.048 1.89-1.872-2.215-.22a.667.667 0 01-.496-.304l-.033-.059z'
        fill='currentColor'
        fillRule='nonzero'
      />
    </SvgContainer>
  );
}

export default Svg12StarOutlined;
