import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12Tryon( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <g
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
        stroke='currentColor'
      >
        <circle cx={ 6 }
          cy={ 4 }
          r={ 2 }
        />
        <path d='M9 8.94C8.526 7.802 7.367 7 6.012 7 4.633 7 3.457 7.832 3 9M.5 3V.5H3M.5 9v2.5H3M11.5 3V.5H9M11.5 9v2.5H9' />
      </g>
    </SvgContainer>
  );
}

export default Svg12Tryon;
