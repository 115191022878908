import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24Tryon( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <g fill='none'
        fillRule='evenodd'
      >
        <g fill='currentColor'
          fillRule='nonzero'
        >
          <path d='M7 0H0v1h7z' />
          <path d='M0 0v7h1V0z' />
        </g>
        <g fill='currentColor'
          fillRule='nonzero'
        >
          <path d='M17 0h7v1h-7z' />
          <path d='M24 0v7h-1V0z' />
        </g>
        <g fill='currentColor'
          fillRule='nonzero'
        >
          <path d='M7 24H0v-1h7z' />
          <path d='M0 24v-7h1v7z' />
        </g>
        <g fill='currentColor'
          fillRule='nonzero'
        >
          <path d='M17 24h7v-1h-7z' />
          <path d='M24 24v-7h-1v7z' />
        </g>
        <ellipse
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          cx={ 12.146 }
          cy={ 9 }
          rx={ 3.88 }
          ry={ 4 }
        />
        <path
          d='M19.002 17.878C17.929 15.606 15.305 14 12.239 14c-3.122 0-5.784 1.663-6.819 4'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </SvgContainer>
  );
}

export default Svg24Tryon;
