import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16Trending( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <path
        stroke='currentColor'
        d='M6.53.5H13L9.765 6.145H13L6.53 15.5V8H4V.5z'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg16Trending;
