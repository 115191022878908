import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12Filters( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <g
        transform='translate(0 1)'
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M.5 2h5' />
        <circle cx={ 7.5 }
          cy={ 2 }
          r={ 2 }
        />
        <circle cx={ 5 }
          cy={ 8 }
          r={ 2 }
        />
        <path d='M10 2h1.5M.5 8h2M7 8h4.5' />
      </g>
    </SvgContainer>
  );
}

export default Svg12Filters;
