import constants from '../constants/constants';

/**
 * Passes a specific configuration to the header module
 * @method updatedHeaderWithPageConfiguration
 * @param {object} data - Data argument
 * @returns object - Footer Module
 */
export const updatedHeaderWithPageConfiguration = ( data ) => {
  const { header = [], configuration = {} } = data || {};
  const [headerModule, ...rest] = header;

  const decorated = rest.map( ( module ) => {
    const m = { ...module };
    if( m?.meta?.initAction && m?.moduleName === constants.HFN.IncrementalModuleName ){
      m.meta.initAction.cachePath = constants.HFN.header;
    }
    return m;
  } );

  return [{ ...headerModule, ...configuration }, ...decorated];
};