/**
 * The EventProvider component provides methods for subscribing, composing and publishing events with context
 *
 * @module views/__core/EventProvider
 * @memberof -Common
 */

import React, { createContext, useCallback, useContext, useRef } from 'react';

import { arrayDelete, hasItems } from '@ulta/core/utils/array/array';

import * as utils from './EventProvider';
/**
  * Represents a EventProvider component
  *
  * @method
  * @param { Object } props - React properties passed from composition
  * @returns EventContext
  */

export const EventProvider = ( props ) => {
  /*
   * Contains the list of of subscribers:
   * { eventName: callback[] }
   */
  const subscribers = useRef( {} );

  const events = useRef( {} );

  // Publish handler
  const publishEvent = useCallback( utils.composePublishEvent( { subscribers, events } ), [] );

  // Subscribe handler
  const subscribe = useCallback( utils.composeSubscribe( { subscribers, events } ), [] );

  // Unsubscribe handler
  const unsubscribe = useCallback( utils.composeUnsubscribe( { subscribers } ), [] );

  return (
    <EventContext.Provider
      value={ {
        subscribe,
        unsubscribe,
        hasEvent,
        publishEvent,
        composeOnEvent
      } }
    >
      { props.children }
    </EventContext.Provider>
  );

};

export const initialContextState = {
  subscribe: () => {},
  unsubscribe: () => {},
  hasEvent: () => {},
  publishEvent: () => {},
  composeOnEvent: () => {}
};

/**
 * Handles providing an publishEvent to child components, used in LayerHost.
 * @method composePublishEvent
 * @param {object} data - Arguments
 * @param {array} data.events - Events ref
 * @param {object} eventData - Events data
 * @param {string} eventData.moduleName - moduleName is baked in by LayerHost at inception
 * @param {object} eventData.payload - Payload is passed from the component itself
 * @param {string} eventData.event - Name the event needs to publish to
 */
export const composePublishEvent = ( data ) => ( eventData ) => {
  const { subscribers, events = {} } = data || {};
  const { event, payload, moduleName } = eventData || {};

  if( !subscribers || !subscribers.current || !event ){
    // eslint-disable-next-line no-console
    !event && console.error( `[EventProvider::pub] Missing event` );
    return;
  }

  const eventSubscribers = subscribers.current?.[event];

  // Save current state of event matrix
  events.current[event] = events.current[event] || {};
  events.current[event][moduleName] = eventData;

  if( !hasItems( eventSubscribers ) ){
    return;
  }

  eventSubscribers.forEach( utils.composeEventPublisherIterator( { payload } ) );
};

/**
 * @callback eventPublisherIterator
 * @param {{ callback: function }} subscriber Event Subscriber
 */

/**
 * Used to cycle through subscribers and fire the payload
 *
 * @param {object} data - Data arguments
 * @param {object} data.payload - Subscriber payload
 * @returns {eventPublisherIterator}
 */
export const composeEventPublisherIterator = ( data ) => ( subscriber ) => {
  const { payload } = data || {};
  const { callback } = subscriber || {};

  if( typeof callback !== 'function' ){
    return;
  }

  callback( payload );
};

/**
 * Recreate the event/state matrix for a new subscriber
 *
 * @method fireExistingEvents
 * @param {object} data - Data arguments
 * @param {object} data.events - Events ref
 * @param {string} data.event - Events name
 * @param {object} methods - Methods arguments
 * @param {function} methods.callback - New subscriber's callback
 */
export const fireExistingEvents = ( data, methods ) => {
  const { events, event } = data || {};
  const { callback } = methods || {};

  const moduleEvents = events?.current?.[event];

  if( !moduleEvents || !callback ){
    return;
  }

  // Key through each existing event profile and trigger the callback for the new subscriber
  Object.values( moduleEvents ).forEach( moduleEvent => {
    callback( moduleEvent.payload );
  } );
};

/**
 * Subscribe to events
 *
 * @method composeSubscribe
 * @param {object} data - Data arguments
 * @param {object} data.events - Events ref
 * @param {string} data.event - Events name
 * @param {object} subscribeData - subscribeData
 * @param {function} subscribeData.callback - Callback for event
 * @param {string} subscribeData.moduleName - Callback module name
 * @param {string} subscribeData.event - Callback event name
 */
export const composeSubscribe = ( data ) => ( subscribeData ) => {
  const { subscribers = {}, events = {} } = data || {};
  const { event, callback, firePrevious, moduleName } = subscribeData || {};

  subscribers.current = subscribers.current || {};

  // Check to see if there are pending events, these will fire and then any subsequent events
  // will be handled in real-teim
  if( firePrevious ){
    utils.fireExistingEvents( { events, event }, { callback } );
  }

  if( !event || typeof callback !== 'function' || !moduleName ){
    // eslint-disable-next-line no-console
    typeof callback !== 'function' && console.error( `[EventProvider::sub] ${event} is missing a callback` );

    // eslint-disable-next-line no-console
    !moduleName && console.error( `[EventProvider::sub] moduleName is empty` );

    // eslint-disable-next-line no-console
    !event && console.error( `[EventProvider::sub] event is empty` );

    return;
  }

  // Create subscriber model
  const subscriber = { event, callback, moduleName };

  // Add subscriber
  const current = subscribers.current[event] || [];
  subscribers.current[event] = [...current, subscriber];
};

/**
 * Handles providing an onEvent for subscribers, used in LayerHost. We want the implementation
 * to live here while LayerHost can wire up the composition and expose the coupling.
 * @method composeOnEvent
 * @param {object} data - Data arguments
 * @param {array} data.subscribedEvents - Event array from DXL
 * @param {object} data.eventStore - When passed, this object will be populated with callbacks used to unsubscribe on unmount
 * @param {string} data.moduleName - moduleName is baked in by LayerHost at inception
 * @param {object} methods - Methods arguments
 * @param {function} methods.subscribe - EventProvider sub method
 * @returns {false|function} - Returns false when no subscription was made, otherwise returns the callback
 */
export const composeOnEvent = ( data, methods ) => ( eventData ) => {
  const { subscribedEvents, eventStore = {}, moduleName } = data || {};
  const { subscribe } = methods || {};
  const { event, callback, firePrevious = true } = eventData || {};

  if( !subscribe || !event || !callback ){
    return;
  }

  if( !subscribedEvents?.includes( event ) ){
    return false;
  }

  eventStore.current = eventStore.current || [];
  eventStore.current.push( { event, callback, moduleName } );
  subscribe( { event, callback, firePrevious, moduleName } );
};

/**
 * Handles un-subscribing from each event in the subscribedEvents array on unmount
 *
 * @method composeUnsubscribe
 * @param {object} data - Data arguments
 * @param {object} data.subscribers - Subscribers ref
 * @param {array} data.callbacks - Array of callbacks to unsub
 */
export const composeUnsubscribe = ( data ) => ( unsubcribeData ) => {
  const { subscribers = {} } = data || {};
  const { events = [] } = unsubcribeData || {};

  if( !subscribers.current || !hasItems( events ) ){
    return;
  }

  Object.keys( subscribers.current ).forEach( utils.composeUnsubsribeIterator( { subscribers, events } ) );
};

/**
 * Handles un-subscribing iteration of each event in the subscribedEvents array
 *
 * @method composeUnsubsribeIterator
 * @param {object} data - Data arguments
 * @param {object} data.subscribers - Subscribers ref
 * @param {array} data.events - Array of events to unsub
 */
export const composeUnsubsribeIterator = ( data ) => ( subscribersKey ) => {
  const { subscribers = {}, events = [] } = data || {};

  // Do nothing if either subscriber callbacks or events to unsub are empty
  if( !subscribersKey || !hasItems( subscribers?.current?.[subscribersKey] ) || !hasItems( events ) ){
    return;
  }

  // Search subscribers for local event and remove
  for ( const search of events ){
    subscribers.current[subscribersKey] = arrayDelete( {
      target: subscribers?.current[subscribersKey],
      search: ( item ) => {
        return (
          item.event === search.event && item.moduleName === search.moduleName && item.callback === search.callback
        );
      }
    } );
  }
};

/** Find an event from array of events
 * @method hasEvent
 * @param {object} data - Data arguments
 * @param {string} data.event - Event name to be found
 * @param {array} data.eventNames - Array of events from DXL
*/
export const hasEvent = ( data ) => {
  const { eventNames, event } = data || {};
  return !!eventNames?.includes( event );
};

export const EventContext = createContext( initialContextState );

export const useEventContext = () => useContext( EventContext );