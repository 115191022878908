/**
 * SignIn Component
 * @module views/Organisms/SignIn
 * @memberof -Common
 */
import './SignIn.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import Button from '@ulta/core/components/Button/Button';
import InputField from '@ulta/core/components/InputField_New/InputField_New';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import ResponseMessages from '@ulta/core/components/ResponseMessages/ResponseMessages';
import useLoader from '@ulta/core/hooks/useLoader/useLoader';
import { useToggle } from '@ulta/core/hooks/useToggle/useToggle';
import { useAppConfigContext } from '@ulta/core/providers/AppConfigProvider/AppConfigProvider';
import { useOverlay } from '@ulta/core/providers/OverlayProvider/OverlayProvider';
import { usePageDataContext } from '@ulta/core/providers/PageDataProvider/PageDataProvider';
import { handleReload } from '@ulta/core/utils/handleLocation/handleLocation';
import CheckboxToggle from '@ulta/core/components/CheckboxToggle/CheckboxToggle';
import Text from '@ulta/core/components/Text/Text';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import * as utils from './SignIn';

/**
 * Represents a SignIn component
 *
 * @method
 * @param {SignInProps} props - React properties passed from composition
 * @returns SignIn
 */

const SignIn = ( props ) => {
  const {
    emailLabel,
    passwordLabel,
    signInAction,
    persistentLabel,
    persistentHint,
    forgotPasswordAction,
    invokeMutation,
    loading,
    signInErrorMessage,
    onSignInResponse,
    id
  } = props;

  // TODO: stop gap until sign in has either a refresh action or different flow
  const { refetchRef } = usePageDataContext();
  const { isATG } = useAppConfigContext();
  // client SHOULD NOT USE refetchRef normally
  const { inflection } = useDeviceInflection();
  const isMobile = inflection?.MOBILE;
  const { closeOverlay, displayOverlay } = useOverlay();
  const [staySignedIn, setStaySignedIn] = useToggle( isMobile ? true : false );
  const [hasSubmitted, setHasSubmitted] = useState( false );
  const [loader, showLoader] = useLoader( { loading } );
  const [errMsg, setErrMsg] = useState( '' );
  const formikRef = useRef();

  const validationSchema = Yup.object( {
    username: Yup.string().required( 'Required' ),
    password: Yup.string().required( 'Required' )
  } );

  /**
   * Compose change password handler
   */
  const onChangePassword = useCallback(
    utils.composeChangePassword( { setErrMsg } )
  );

  /**
   * Compose sign in form submit handler
   */
  const onSubmit = useCallback(
    composeOnSubmit(
      { displayOverlay, signInAction, staySignedInChecked: staySignedIn, id },
      { setHasSubmitted, invokeMutation, showLoader }
    ),
    [staySignedIn, signInAction, invokeMutation, showLoader]
  );

  /**
   * Compose stay signed in toggler
   */
  const onStayToggle = useCallback(
    utils.composeToggleClick( { staySignedIn }, { setStaySignedIn } )
  );

  /**
   * Compose response handler
   */
  const responseHandlerData = {
    isATG,
    loading,
    hasSubmitted,
    signInErrorMessage,
    props,
    formikRef,
    displayOverlay
  };
  const responseHandlerMethods = {
    closeOverlay,
    onSignInResponse,
    refetchRef,
    setErrMsg,
    setHasSubmitted
  };
  const responseHandler = useCallback(
    utils.composeResponseHandler( responseHandlerData, responseHandlerMethods ),
    [loading, signInErrorMessage]
  );

  useEffect( responseHandler, [responseHandler] );

  return (
    <Formik
      innerRef={ formikRef }
      initialValues={ {
        username: '',
        password: '',
        staySignedIn: false
      } }
      validationSchema={ validationSchema }
      validateOnChange={ true }
      validateOnBlur={ true }
      onSubmit={ onSubmit }
    >
      { ( fprops ) => (
        <div className='SignIn'>
          { loader }
          <Form>
            { errMsg && !fprops.values.password && (
              <ResponseMessages messageType='error'
                message={ errMsg }
              />
            ) }
            <div className='SignIn__field'>
              <InputField
                name='username'
                type='text'
                label={ emailLabel }
                autoComplete='email'
                autoCorrect='on'
                spellCheck={ true }
                required={ true }
              />
            </div>
            <div className='SignIn__field'>
              <InputField
                name='password'
                type='password'
                label={ passwordLabel }
                autoComplete='current-password'
                spellCheck={ true }
                onChange={ onChangePassword }
                required={ true }
              />
            </div>
            <div className='SignIn__staySignedIn SignIn__staySignedIn--checkbox'>
              <CheckboxToggle 
                name='staySignedIn'
                id='staySignedIn'
                toggleButton={ false }
                checked={ staySignedIn }
                onChange={ onStayToggle }
                ariaDescribedBy="signedInDescribedBy"
              >
                <Text htmlTag='span'
                  textStyle='body-2'
                >
                  { persistentLabel }
                </Text>
              </CheckboxToggle>
            </div>
            <div 
            className='SignIn__staySignedIn'
            id="signedInDescribedBy"
            >
              <Text htmlTag='span'
                textStyle='body-3'
                color='neutral-600'
              >
                { persistentHint }
              </Text>
            </div>
            { signInAction && (
              <div className='SignIn__submit'>
                <Button
                  type='submit'
                  btnStyle='primary'
                  fullWidth
                  label={ signInAction.label }
                  action={ signInAction }
                />
              </div>
            ) }
          </Form>
          { forgotPasswordAction && (
            <div className='SignIn__forgotCredentials'>
              { forgotPasswordAction.url && (
                <Link_Huge
                  withHover
                  compact
                  target='_self'
                  action={ forgotPasswordAction }
                >
                  { forgotPasswordAction.label }
                </Link_Huge>
              ) }
              { !forgotPasswordAction.url && (
                <Button
                  likeLink
                  compact
                  className='SignIn__forgotAction'
                  ariaLabel={ forgotPasswordAction.label }
                  action={ forgotPasswordAction }
                  label={ forgotPasswordAction.label }
                />
              ) }
            </div>
          ) }
        </div>
      ) }
    </Formik>
  );
};

/**
 * TODO: Write tests + JSdocs
 */
export const composeChangePassword = ( methods ) => ( val ) => {
  const { setErrMsg } = methods || {};

  /**
   * clears the error message when user starts typing
   */
  val && setErrMsg( '' );
};

/**
 * handleSubmit function for toggle button
 * @method
 * @param {Object} methods - Passing invokeMutation method as an argument
 */
export const composeOnSubmit = ( data, methods ) => ( values, actions ) => {
  const { username, password } = values || {};
  const { signInAction, staySignedInChecked, id, displayOverlay } = data || {};
  const { setHasSubmitted, invokeMutation } = methods || {};

  if(
    !signInAction?.graphql ||
    !invokeMutation ||
    !setHasSubmitted ||
    !username ||
    !password
  ){
    return;
  }

  /**
   * Handle Formik
   */
  actions.setValues( { ...values, staySignedInChecked } );
  actions.setSubmitting( false );
  actions.setErrors( {
    username: '',
    password: ''
  } );

  /**
   * Set clicked to true for response handler
   */
  setHasSubmitted( true );

  const variables = {
    moduleParams: {
      login: username,
      password: password,
      autoLogin: staySignedInChecked,
      sourcePage: 'default'
    }
  };

  if( displayOverlay ){
    variables.contentId = id;
  }
  else {
    variables.url = { path: global.location.pathname };
  }

  /**
   * SignIn mutation call
   */
  invokeMutation( {
    graphql: signInAction.graphql,
    customHeaders: signInAction.customHeaders,
    variables
  } );
};

/**
 * handleResponse for sign in responses
 * @method
 * @param {object} data - Arguments
 * @param {object} methods - Methods
 */
export const composeResponseHandler = ( data, methods ) => () => {
  const {
    isATG,
    displayOverlay,
    props,
    signInErrorMessage,
    formikRef,
    hasSubmitted,
    loading
  } = data || {};
  const { onSignInResponse, setErrMsg, setHasSubmitted, closeOverlay } =
    methods || {};
  const { userName } = props || {};

  if( !hasSubmitted || loading ){
    return;
  }

  if( signInErrorMessage ){
    setHasSubmitted( false );
    onSignInResponse && onSignInResponse( props );
    setErrMsg( signInErrorMessage );
    formikRef?.current?.setFieldValue( 'password', '' );
    formikRef?.current?.setFieldTouched( 'password', false );
    return;
  }

  if( isATG ){
    handleReload();
    return;
  }

  if( !displayOverlay ){
    return;
  }

  if( userName ){
    closeOverlay();
  }
};

/**
 * onToggleClick -handles toggle button
 * @param {object} data - data passed to function as args
 * @param {object} methods - methods passed to function as args
 */

export const composeToggleClick = ( data, methods ) => () => {
  const { setStaySignedIn } = methods;
  setStaySignedIn( !data.staySignedIn );
};

/**
 * Property type definitions
 * @typedef SignInProps
 * @type {object}
 * @property {string} emailLabel - Sets the email label
 * @property {string} passwordLabel - Sets the password label
 * @property {object} signInAction - Sets the signIn Action
 * @property {string} label - Sets the label
 * @property {string} graphql - Sets the graphql
 * @property {string} persistentLabel - Sets the persistent label
 * @property {string} persistentHint - Sets the persistent hint
 * @property {object} forgotPasswordAction - Sets the forgotPassword action
 * @property {string} url - Sets the url
 * @property {function} invokeMutation - Sets the invokeMutation
 * @property {bool} loading - Sets the loading
 * @property {function} onSignInResponse - Sets the onSignInResponse
 * @property {string} containerLayerHostContentId - Sets the containerLayerHostContentId
 * @property {string} id - Sets the id
 */
export const propTypes = {
  emailLabel: PropTypes.string.isRequired,
  passwordLabel: PropTypes.string.isRequired,
  signInAction: PropTypes.shape( {
    label: PropTypes.string.isRequired,
    graphql: PropTypes.string
  } ),
  persistentLabel: PropTypes.string.isRequired,
  persistentHint: PropTypes.string.isRequired,
  forgotPasswordAction: PropTypes.shape( {
    label: PropTypes.string.isRequired,
    graphql: PropTypes.string.isRequired
  } ),
  invokeMutation: PropTypes.func,
  loading: PropTypes.bool,
  signInErrorMessage: PropTypes.string,
  onSignInResponse: PropTypes.func,
  containerLayerHostContentId: PropTypes.string,
  id: PropTypes.string
};

SignIn.propTypes = propTypes;

export const SIGNIN_MODULE_NAME = 'SignIn';

export default SignIn;
