import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12Trash( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
      viewBox='0 0 12 12'
    >
      <g
        id='trash'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          transform='translate(0.375000, 0.375000)'
          fill='#000000'
          fillRule='nonzero'
          id='Shape'
        >
          <path d='M6.75,0 C7.3705,0 7.875,0.5045 7.875,1.125 L7.875,1.5 L10.875,1.5 C11.082,1.5 11.25,1.668 11.25,1.875 C11.25,2.082 11.082,2.25 10.875,2.25 L10.095,2.25 L9.431,10.2185 C9.383,10.797 8.8905,11.25 8.31,11.25 L2.9405,11.25 C2.36,11.25 1.8675,10.797 1.8195,10.218 L1.155,2.25 L0.375,2.25 C0.168,2.25 0,2.082 0,1.875 C0,1.668 0.168,1.5 0.375,1.5 L3.375,1.5 L3.375,1.125 C3.375,0.5045 3.8795,0 4.5,0 L6.75,0 Z M9.3425,2.25 L1.9075,2.25 L2.5665,10.156 C2.5825,10.349 2.7465,10.5 2.9405,10.5 L8.31,10.5 C8.5035,10.5 8.668,10.349 8.6835,10.156 L9.3425,2.25 Z M4.5,4.125 C4.707,4.125 4.875,4.293 4.875,4.5 L4.875,8.25 C4.875,8.457 4.707,8.625 4.5,8.625 C4.293,8.625 4.125,8.457 4.125,8.25 L4.125,4.5 C4.125,4.293 4.293,4.125 4.5,4.125 Z M6.75,4.125 C6.957,4.125 7.125,4.293 7.125,4.5 L7.125,8.25 C7.125,8.457 6.957,8.625 6.75,8.625 C6.543,8.625 6.375,8.457 6.375,8.25 L6.375,4.5 C6.375,4.293 6.543,4.125 6.75,4.125 Z M6.75,0.75 L4.5,0.75 C4.293,0.75 4.125,0.918 4.125,1.125 L4.125,1.5 L7.125,1.5 L7.125,1.125 C7.125,0.918 6.957,0.75 6.75,0.75 Z'></path>
        </g>
      </g>
    </SvgContainer>
  );
}

export default Svg12Trash;
