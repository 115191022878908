/**
 * UltamateRewardsOffers is consist of LargedealRail and manages different states
 *
 * @module views/components/UltamateRewardsOffers
 * @memberof -Common
 */
import 'react-multi-carousel/lib/styles.css';
import './UltamateRewardsOffers.scss';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import React, { useRef, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import SwiperCore, { A11y, Keyboard, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import palette_variables from 'web-styles/src/_exports.module.scss';

import Button from '@ulta/core/components/Button/Button';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import { useIntersectionObserver } from '@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver';
import { handleIntersection } from '@ulta/core/utils/intersectionProcessor/intersectionProcessor';

import LargeDealCard from '@ulta/components/LargeDealCard/LargeDealCard';

import carouselUtils, { handleInactiveSwiperSlides } from '@ulta/utils/carouselUtils/carouselUtils';
import { constants } from '@ulta/utils/constants/constants';

SwiperCore.use( [Navigation, Keyboard, A11y] );

let swiperCounter = 1;

/**
   * Represents a UltamateRewardsOffers component
   *
   * @method
   * @param {UltamateRewardsOffersProps} props - React properties passed from composition
   * @returns UltamateRewardsOffers
   */
export const UltamateRewardsOffers = function( props ){

  const [index, setIndex] = useState( 0 );
  const {
    offers,
    root,
    rootMargin,
    threshold,
    hasOffers,
    title,
    pointsLabel,
    pointsValueLabel,
    loyaltyTier,
    carouselAccessibility,
    nextAccessibility,
    previousAccessibility,
    viewAllOffersAction,
    carouselDetailsAccessibility,
    vertical,
    componentKey,
    invokeMutation,
    nextClickAction,
    previousClickAction
  } = props;
  const carouselRef = useRef( null );
  const ultaMateOffersRef = useRef();
  const [currentIndex, SetCurrentIndex] = useState( 0 );
  swiperCounter += 1;

  const offersLength = offers?.length;
  const isSingleItem = offersLength === 1;
  const isLastItem = index === ( offersLength - 1 );
  const selectedOffer = currentIndex + 1;

  const isIntersecting = useIntersectionObserver( ultaMateOffersRef, {
    root: root,
    rootMargin: rootMargin,
    threshold: threshold
  }, handleIntersection( props, carouselRef ) );

  if( !offersLength ){
    return true;
  }

  return (
    <div className='UltamateRewardsOffers'
      ref={ ultaMateOffersRef }
      key={ `${componentKey}-rewards-${offersLength}` }
    >
      <div className='UltamateRewardsOffers__wrapper'>
        <div className={
          classNames( 'UltamateRewardsOffers__content', {
            'UltamateRewardsOffers__content--vertical': vertical
          }
          ) }
        >
          <div className={
            classNames( 'UltamateRewardsOffers__header', {
              'UltamateRewardsOffers__header--singleItem': isSingleItem,
              'UltamateRewardsOffers__header--vertical': vertical
            } )
          }
          >
            <div className={
              classNames( 'UltamateRewardsOffers__titleInfo', {
                'UltamateRewardsOffers__titleInfo--vertical': vertical
              } )
            }
            >
              { title &&
                <div className='UltamateRewardsOffers__headerTitle'>
                  <Text
                    textStyle='title-6'
                    htmlTag='h2'
                  >
                    { title }
                  </Text>
                </div>
              }
              { viewAllOffersAction &&
                <div className={ classNames( 'UltamateRewardsOffers__headerLink', {
                  'UltamateRewardsOffers__headerLink--vertical': vertical
                } )
                }
                >
                  <Link_Huge
                    target='_self'
                    withHover={ true }
                    action={ viewAllOffersAction }
                  >
                    { viewAllOffersAction.label }
                  </Link_Huge>
                </div>
              }
            </div>
            <div className={ classNames( 'UltamateRewardsOffers__rewardInfo', {
              'UltamateRewardsOffers__rewardInfo--vertical': vertical
            } )
            }
            >
              <ul className='UltamateRewardsOffers__rewardList'>
                { pointsLabel &&
                  <li className='UltamateRewardsOffers__points'>
                    <Text
                      textStyle='body-3'
                      htmlTag='p'
                    >
                      { pointsLabel }
                    </Text>
                  </li>
                }
                { pointsValueLabel &&
                  <li className='UltamateRewardsOffers__value'>
                    <Text
                      textStyle='body-3'
                      htmlTag='p'
                    >
                      { pointsValueLabel }
                    </Text>
                  </li>
                }
              </ul>
              { loyaltyTier &&
                <div className='UltamateRewardsOffers__memberType'>
                  <Text
                    textStyle='body-3'
                    htmlTag='p'
                    color='neutral-600'
                  >
                    { loyaltyTier }
                  </Text>
                </div>
              }
            </div>
          </div>
          <div className={
            classNames( 'UltamateRewardsOffers__container', {
              'UltamateRewardsOffers__container--singleItem': isSingleItem,
              'UltamateRewardsOffers__container--lastItem': !isSingleItem && isLastItem,
              'UltamateRewardsOffers__container--firstItem': !isSingleItem && index === 0,
              'UltamateRewardsOffers__container--firstItem--vertical': !isSingleItem && index === 0 && vertical,
              'UltamateRewardsOffers__container--singleItem--vertical': isSingleItem && vertical
            } )
          }
          { ... ( !isSingleItem && { role: 'region' } ) }
          { ... ( !isSingleItem && { 'aria-label': carouselUtils.handleTokenizedString( carouselAccessibility, [title] ) } ) }
          >
            { /* TODO : aria-label will be localized */ }
            { carouselDetailsAccessibility && <p className='sr-only'>{ carouselUtils.handleTokenizedString( carouselDetailsAccessibility, [title] ) }</p> }
            {
              !isSingleItem &&
              <div className={
                classNames( `UltamateRewardsOffers UltamateRewardsOffers__Pagination UltamateRewardsOffers__Pagination-${swiperCounter}`, {
                  'UltamateRewardsOffers__Pagination--vertical': vertical
                } )
              }
              >
                <Button
                  action={ previousClickAction }
                  variant='navigation'
                  iconImage='CaretBack'
                  iconSize='lg'
                  className='UltamateRewardsOffers__Pagination__button UltamateRewardsOffers__Pagination__button--back'
                  ariaLabel={ previousAccessibility }
                  ariaHiddenIcon={ true }
                />
                <Text
                  textStyle='body-3'
                  htmlTag='span'
                >
                  { `${selectedOffer}/${offersLength}` }

                </Text>

                <Button
                  action={ nextClickAction }
                  variant='navigation'
                  iconImage='CaretForward'
                  iconSize='lg'
                  className={ 'UltamateRewardsOffers__Pagination__button UltamateRewardsOffers__Pagination__button--forward' }
                  ariaLabel={ nextAccessibility }
                  ariaHiddenIcon={ true }
                />
              </div> }
            <Swiper
              initialSlide={ currentIndex }
              virtualIndex={ currentIndex }
              defaultValue={ currentIndex }
              onSwiper={ ( swiper ) => handleInactiveSwiperSlides( swiper ) }
              onSlideChange={ ( swiper ) => {
                SetCurrentIndex( swiper?.realIndex );
                handleInactiveSwiperSlides( swiper );
              } }
              watchOverflow={ true }
              watchSlidesProgress={ true }
              watchSlidesVisibility={ true }
              centeredSlides={ true }
              freeMode={ false }
              preloadImages={ true }
              spaceBetween={ isSingleItem ? 0 : 16 }
              navigation={ {
                prevEl: `.UltamateRewardsOffers__Pagination-${swiperCounter} .UltamateRewardsOffers__Pagination__button--back`,
                nextEl: `.UltamateRewardsOffers__Pagination-${swiperCounter} .UltamateRewardsOffers__Pagination__button--forward`,
                disabledClass: 'UltamateRewardsOffers__Pagination__button--disabled',
                hiddenClass: 'UltamateRewardsOffers__Pagination__button--hidden'
              } }
              breakpoints={ {
                [palette_variables.breakpointLG]: {
                  slidesPerView: isSingleItem ? 1 : 1.2
                },
                [palette_variables.breakpointMD]: {
                  slidesPerView: isSingleItem ? 1 : 1.5
                },
                [palette_variables.breakpointSM]: {
                  slidesPerView: isSingleItem ? 1 : 1.100
                }
              } }
            >
              {
                !isSingleItem && offers?.map( ( product, index ) => (
                  <SwiperSlide key={ `UltamateRewardsOffers${index + 1} ` }
                    aria-label={ `deal ${index + 1} of ${offersLength}` }
                  >
                    <div
                      className={
                        classNames( 'UltamateRewardsOffers__itemclass', {
                          'UltamateRewardsOffers__itemclass--lastItem': !isSingleItem && isLastItem,
                          'UltamateRewardsOffers__itemclass--singleItem': isSingleItem,
                          'UltamateRewardsOffers__itemclass--firstItem': currentIndex === 1,
                          'UltamateRewardsOffers__itemclass--vertical': vertical
                        } ) }
                    >
                      <LargeDealCard
                        key={ index }
                        isUltamateRewards={ hasOffers }
                        item={ product.length }
                        image={ product.image }
                        headline={ product.headline }
                        description={ product.description }
                        tag={ carouselUtils.computeProductTag( product.tag, product.urgencyTag?.timerMeta?.endsInLabel ) }
                        eyebrow={ product.eyebrow }
                        action1={ product.actionGroup?.action1 }
                        action2={ product.actionGroup?.action2 }
                        action1Style={ product.actionGroup?.action1Style }
                        action2Style={ product.actionGroup?.action2Style }
                        isOfferActivated={ product.isOfferActivated }
                        noOffers={ product.noOffers }
                        activatedMessage={ product.activatedMessage }
                        activateAction={ product.activateAction }
                        invokeMutation={ invokeMutation }
                        activatedLabel={ product.activatedLabel }
                        offerId={ product.offerId }
                        vertical={ vertical }
                      />
                    </div>
                  </SwiperSlide>
                ) )
              }
              {
                isSingleItem && offers?.map( ( product, index ) => (
                  <div
                    key={ `UltamateRewardsOffers__itemclass-${index}` }
                    className={
                      classNames( 'UltamateRewardsOffers__itemclass', {
                        'UltamateRewardsOffers__itemclass--singleItem': isSingleItem,
                        'UltamateRewardsOffers__itemclass--firstItem': currentIndex === 1,
                        'UltamateRewardsOffers__itemclass--vertical': vertical
                      } ) }
                  >
                    <LargeDealCard
                      key={ index }
                      isUltamateRewards={ hasOffers }
                      item={ product.length }
                      image={ product.image }
                      headline={ product.headline }
                      description={ product.description }
                      tag={ carouselUtils.computeProductTag( product.tag, product.urgencyTag?.timerMeta?.endsInLabel ) }
                      eyebrow={ product.eyebrow }
                      action1={ product.actionGroup?.action1 }
                      action2={ product.actionGroup?.action2 }
                      action1Style={ product.actionGroup?.action1Style }
                      action2Style={ product.actionGroup?.action2Style }
                      isOfferActivated={ product.isOfferActivated }
                      noOffers={ product.noOffers }
                      activatedMessage={ product.activatedMessage }
                      activateAction={ product.activateAction }
                      invokeMutation={ invokeMutation }
                      activatedLabel={ product.activatedLabel }
                      offerId={ product.offerId }
                      vertical={ vertical }
                    />
                  </div>
                ) )
              }
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
   * Default values for passed properties
   * @type {object}
   */
export const defaultProps = {
  ...constants.INTERSECTION_OBSERVER_OPTIONS
};

/**
   * Property type definitions
   * @typedef UltamateRewardsOffersProps
   * @type {object}
   * @property {function} invokeAction - Set the invokeAction object
   * @property {array} offers - Set the reward offers object
   * @property {boolean} hasOffers - flag to set reward offers
   * @property {string} title - set the offer title.
   * @property {string} pointsLabel - sets the rewards points.
   * @property {string} pointsValueLabel - sets the rewards points value.
   * @property {string} loyaltyTier - sets the loyalty type
   * @property {string} carouselAccessibility - Value for aria label for carousel.
   * @property {string} carouselDetailsAccessibility - sets sr-only text for keyboard users.
   * @property {string} previousAccessibility - Sets the aria label for previous button.
   * @property {string} nextAccessibility - Sets the aria label for next button.
   * @property {object} viewAllOffersAction - Sets the lable and url for viewAll offers
   * @property {string} componentKey - set componentKey.
   * @property {boolean} vertical - Sets the offers variant as Bonus Offers for Dashboard
   * @property {function} invokeMutation - Sets the function for invokeMutation
   * @property {object} nextClickAction - Set the action for nextClick of arrow button
   * @property {object} previousClickAction - Set the action for previousClick of arrow button
  */

export const propTypes = {
  invokeAction: PropTypes.func,
  offers: PropTypes.array,
  hasOffers: PropTypes.bool,
  title: PropTypes.string,
  pointsLabel: PropTypes.string,
  pointsValueLabel: PropTypes.string,
  loyaltyTier: PropTypes.string,
  carouselAccessibility: PropTypes.string,
  carouselDetailsAccessibility: PropTypes.string,
  nextAccessibility: PropTypes.string,
  previousAccessibility: PropTypes.string,
  viewAllOffersAction: PropTypes.object,
  componentKey: PropTypes.string,
  vertical: PropTypes.bool,
  invokeMutation: PropTypes.func,
  nextClickAction: PropTypes.object,
  previousClickAction: PropTypes.object
};

UltamateRewardsOffers.propTypes = propTypes;
UltamateRewardsOffers.defaultProps = defaultProps;

export default UltamateRewardsOffers;