import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16ArrowBack( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <g
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M1 8h14M7.5 15l-7-7.023L7.5 1' />
      </g>
    </SvgContainer>
  );
}

export default Svg16ArrowBack;
