import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16CaretForward( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <path
        d='M4 14.75L11.5 8 4 1.25'
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg16CaretForward;
