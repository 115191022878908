/**
 * The ColumnContainer is a container for housing the amplience instnace of a grid which is defined within the headlessCMS
 *
 * @module views/components/ColumnContainer
 * @memberof -Common
 */
import React from 'react';

import classNames from 'classnames';

import AsyncComponent from '@ulta/core/components/AsyncComponent/AsyncComponent';
import GridContainer from '@ulta/core/components/GridContainer/GridContainer';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { constants } from '@ulta/core/utils/constants/constants';

const { COLUMN_CONTAINER_BREAKPOINTS } = constants;

/**
 * Represents a ColumnContainer component
 *
 * @method
 * @param {ColumnContainerProps} props - React properties passed from composition
 * @returns ColumnContainer
 */

const ColumnContainer = React.forwardRef( ( props, ref ) => {
  const { modules } = props;
  const { breakpoint } = useDeviceInflection();
  const CURRENT_BREAKPOINT = COLUMN_CONTAINER_BREAKPOINTS[breakpoint.CURRENT_BREAKPOINT];
  const gridColumns =  props[CURRENT_BREAKPOINT]?.columns;

  return (
    <div className='ColumnContainer'
      ref={ ref }
    >
      <GridContainer>
        { gridColumns?.map( ( col, colIndex ) => (
          <div
            className={ classNames(
              `GridContainer__content__startColumn-${col.startColumn}`,
               `GridContainer__content__endColumn-${col.endColumn}`,
               { [`GridContainer__fullBleedContent--bg${col.backgroundColor}`]: col.backgroundColor }
            ) }
            key={ colIndex }
          >
            { renderModules( { CURRENT_BREAKPOINT, col, modules } ) }
          </div>
        ) ) }
      </GridContainer>
    </div>
  );
} );

/**
 * renderModules function for mapping and rendering the modules
 * @method
 * @param {Object} data - Passing data object as an argument
 */
export const renderModules = ( data ) => {
  const { CURRENT_BREAKPOINT, col, modules } = data;
  return col.moduleRefs?.map( ( id, index ) => {
    const module = modules?.find( ( el ) => el.id === id );
    return (
      <AsyncComponent key={ `${CURRENT_BREAKPOINT}: ${module?.id} : ${index}` }
        { ...module }
      />
    );
  } );
};

/**
 * Property type definitions
 * @typedef ColumnContainerProps
 * @type {object}
 * @property {string} example - refactor or delete
 */
export const propTypes = {};

ColumnContainer.propTypes = propTypes;
ColumnContainer.displayName = 'ColumnContainer';

export default ColumnContainer;
