/**
 * Represents a empty state or Icon With Titles for a page
 *
 * @module views/components/IconWithTitles
 * @memberof -Common
 */
import './IconWithTitles.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '@ulta/core/components/Icon/Icon';
import Text from '@ulta/core/components/Text/Text';

/**
 * Represents a IconWithTitles component
 *
 * @method
 * @param {object} props - React properties passed from composition
 * @returns IconWithTitles
 */
export const IconWithTitles = function( props ){
  const { icon, headline, description } = props;

  return (
    <div className='IconWithTitles'>
      { icon &&
        <Icon
          className={ classNames( 'IconWithTitles__Icon', icon ) }
          name={ props.icon }
        />
      }
      <div className='IconWithTitles__Content'>
        { headline &&
          <div className='IconWithTitles__Headline'>
            <Text textStyle='title-6'
              textAlign='center'
            >
              { headline }
            </Text>
          </div>
        }
        { description &&
          <Text textStyle='body-3'
            textAlign='center'
            color='neutral-600'
          >
            { description }
          </Text>
        }
      </div>
    </div>
  );
};

/**
 * Property type definitions
 *
 * @type {object}
 * @property {string} icon - display icon for the Icon With Titles
 * @property {string} headline - display headline for Icon With Titles
 * @property {string} description - display description for Icon With Titles
 */
export const propTypes =  {
  icon: PropTypes.string,
  headline: PropTypes.string,
  description: PropTypes.string
};

/**
 * Default values for passed properties
 *
 * @type {object}
 * @property {string} icon - default props for icon
 * @property {string} headline - default props for headline
 * @property {string} description - default props for description
 */
export const defaultProps =  {
  icon: 'ShippingBoxStyled',
  headline: 'No Purchases',
  description: 'See order details after your next purchase.'
};

IconWithTitles.propTypes = propTypes;
IconWithTitles.defaultProps = defaultProps;

export default IconWithTitles;
