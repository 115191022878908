/**
 * MobileNavContainer to maintain Header Nav links in Mobile
 *
 * @module views/components/MobileNavContainer
 * @memberof -Common
 */
import './MobileNavContainer.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import { DYNAMIC_OVERLAY_CONTAINER_LOADED_EVENT } from '@ulta/core/components/DynamicOverlayComponent/DynamicOverlayComponent';
import Modules from '@ulta/core/components/Modules/Modules';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { useOverlay } from '@ulta/core/providers/OverlayProvider/OverlayProvider';
import { hasItems } from '@ulta/core/utils/array/array';
import { isFunction } from '@ulta/core/utils/types/types';

import { useTopBar } from '@ulta/modules/TopBar/TopBar';

import * as utils from './MobileNavContainer';

/**
 * Represents a MobileNavContainer component
 *
 * @method
 * @param {MobileNavContainerProps} props - React properties passed from composition
 * @returns MobileNavContainer
 */
export const MobileNavContainer = function( props ){
  const {
    showHambugerMenu,
    setShowHambugerMenu,
    menuNavigation,
    setMenuNavigation,
    showBack,
    setShowBack,
    activePrimaryLinks,
    activeNavigationLinksGroup,
    setActivePrimaryLinks,
    setActiveNavigationLinksGroup,
    setPrimaryScrollTop
  } = useTopBar();

  const ref = useRef();
  const wrapper = useRef();
  const { closeOverlay, updateCloseAction, updateBackAction, displayOverlay } = useOverlay();
  const { breakpoint } = useDeviceInflection();
  const [leftNavAnimationActive, setLeftNavAnimationActive] = useState( true );
  const [isInvisible, setIsInvisible] = useState( false );

  const isLargeDevice = breakpoint.isLargeDevice();
  const hasClickedBack = useRef( false );

  const handleBackAction = useCallback(
    utils.composeHandleBackAction(
      { displayOverlay, showHambugerMenu, hasClickedBack },
      { updateCloseAction, updateBackAction, setShowHambugerMenu, setIsInvisible, closeOverlay }
    ),
    [displayOverlay, showHambugerMenu, hasClickedBack]
  );
  useEffect( handleBackAction, [handleBackAction] );

  useEffect( () => {
    const handleOverLayLoadedEvent = ( event ) => {
      utils.dispatchMenu( { event, hasClickedBack }, { updateBackAction, setIsInvisible, closeOverlay } );
    };

    document.body.addEventListener( DYNAMIC_OVERLAY_CONTAINER_LOADED_EVENT, handleOverLayLoadedEvent );

    return () => {
      document.body.removeEventListener( DYNAMIC_OVERLAY_CONTAINER_LOADED_EVENT, handleOverLayLoadedEvent );
    };
  }, [hasClickedBack, updateBackAction, setIsInvisible, closeOverlay] );

  const handleInvisible = useCallback(
    utils.composeHandleInvisible( { displayOverlay, showHambugerMenu }, { setIsInvisible } ),
    [displayOverlay, showHambugerMenu]
  );
  useEffect( handleInvisible, [handleInvisible] );

  const handleResize = useCallback( utils.composeHandleResize( { isLargeDevice, showHambugerMenu, ref: wrapper } ), [
    isLargeDevice,
    showHambugerMenu,
    wrapper
  ] );
  useEffect( handleResize, [handleResize] );

  useEffect( () => {
    setPrimaryScrollTop( ref.current?.scrollTop );
  }, [activePrimaryLinks] );

  if( !hasItems( props.modules ) ){
    return null;
  }

  return (
    <div
      className={ classNames( 'MobileNavContainer', {
        [`MobileNavContainer--hiddenOverflowTierTwo`]: activeNavigationLinksGroup,
        [`MobileNavContainer--hiddenOverflowTierOne`]: activePrimaryLinks,
        [`MobileNavContainer--invisible`]: isInvisible,
        [`MobileNavContainer--visible`]: showHambugerMenu,
        [`MobileNavContainer--hidden`]: !showHambugerMenu
      } ) }
      ref={ ref }
    >
      <div
        className={ classNames( {
          [`MobileNavContainer__colorDropdown`]: showHambugerMenu
        } ) }
      ></div>
      {
        <div className='MobileNavContainer__topPanel'>
          { showBack && showBack !== 'Menu' ? (
            <Button
              iconImage='CaretBack'
              onClick={ () => {
                setMenuNavigation( { forward: false, backward: true } );
                setShowBack( 'Menu' );
                setActiveNavigationLinksGroup( null );
              } }
              iconSize='s'
              label={ showBack }
              className={ classNames( 'MobileNavContainer__mobileBackButton', {
                MobileNavContainer__mobileBackButtonAnimationTier2: activeNavigationLinksGroup
              } ) }
            />
          ) : (
            showBack && (
              <Button
                iconImage='CaretBack'
                onClick={ () => {
                  setMenuNavigation( { forward: false, backward: true } );
                  setShowBack( null );
                  setActivePrimaryLinks( null );
                } }
                iconSize='s'
                label={ showBack }
                className={ classNames( 'MobileNavContainer__mobileBackButton', {
                  MobileNavContainer__mobileBackButtonAnimationTier1: activePrimaryLinks
                } ) }
              />
            )
          ) }

          <Button
            variant='unstyled'
            icon
            iconImage='X'
            iconSize='lg'
            onClick={ () => {
              setShowHambugerMenu( false );
              setMenuNavigation( null );
              setLeftNavAnimationActive( true );
              setShowBack( null );
              document.body.classList.remove( 'leftNavFlyoutOpen' );
            } }
            className='MobileNavContainer__closebtn'
          />
        </div>
      }

      <div className='MobileNavContainer__wrapper'>
        <nav
          className={ classNames( 'MobileNavContainer__navigation', {
            MobileNavContainer__leftNavAnimation: showHambugerMenu && leftNavAnimationActive,
            MobileNavContainer__slideTierZeroOutForwards: activePrimaryLinks && menuNavigation?.forward,
            MobileNavContainer__slideTierOneOutForwards: activeNavigationLinksGroup && menuNavigation?.forward,
            MobileNavContainer__slideTierOneInBackwards: activePrimaryLinks && menuNavigation?.backward,
            MobileNavContainer__slideTierZeroInBackwards:
              !activePrimaryLinks && !activeNavigationLinksGroup && menuNavigation?.backward
          } ) }
          aria-label={ props.navAriaLabel }
          data-testid='leftNavReveal'
          onAnimationEnd={ ( e ) => {
            if( e.animationName === 'leftNavReveal' ){
              setLeftNavAnimationActive( false );
            }
          } }
          ref={ wrapper }
        >
          <div
            className={ classNames( 'MobileNavContainer__sidenavMenu', {
              MobileNavContainer__leftNavShow: showHambugerMenu,
              MobileNavContainer__leftNavHide: !showHambugerMenu
            } ) }
          >
            <Modules modules={ props.modules }
              isMobileContainerContent={ true }
            />
          </div>
        </nav>
      </div>
    </div>
  );
};

/**
 * Handles resizing and setting container height
 * @param {object} data
 * @returns {function} handle resize callback
 */
export const composeHandleResize = ( data ) => () => {
  const { isLargeDevice, showHambugerMenu, ref } = data || {};
  if( isLargeDevice || !showHambugerMenu ){
    return;
  }

  const mobileNavContainerHeight = () => {
    if( !ref?.current?.style ){
      return;
    }

    ref.current.style.height = `${window.innerHeight}px`;
  };

  mobileNavContainerHeight();

  global.addEventListener( 'resize', mobileNavContainerHeight );

  return () => {
    global.removeEventListener( 'resize', mobileNavContainerHeight );
  };
};

/**
 * If menu is open and overlay is open, make the menu invisible so
 * that when user closes it there's no flash of the menu behind it while that is also closing.
 * @param {object} data
 * @param {object} methods
 * Returns {function} handle close/back menu and overlay styling
 */
export const composeHandleInvisible = ( data, methods ) => () => {
  const { displayOverlay, showHambugerMenu } = data || {};
  const { setIsInvisible } = methods || {};

  if( !isFunction( setIsInvisible ) ){
    return;
  }
  if( showHambugerMenu && displayOverlay ){
    setIsInvisible( true );
    return;
  }

  setIsInvisible( false );
};

/**
 * Handles updating and removing backAction for Flyout
 * @param {object} data
 * @param {object} methods
 * Returns {function} handle back/close actions
 */
export const composeHandleBackAction = ( data, methods ) => () => {
  const { showHambugerMenu, displayOverlay, hasClickedBack } = data || {};
  const { updateCloseAction, updateBackAction, setShowHambugerMenu } = methods || {};

  // If the hamburger menu is not open we don't want to setup any actions
  if( !showHambugerMenu ){
    return;
  }
  // Clear close/back action
  if( !displayOverlay ){
    // eslint-disable-next-line no-console
    updateCloseAction();
    updateBackAction();
    return;
  }

  hasClickedBack.current = false;

  // Update close action, on X we should hide the mobile nav as well
  updateCloseAction(
    {},
    {
      onBeforeClose: () => {
        // eslint-disable-next-line no-console
        if( hasClickedBack.current ){
          return;
        }

        // Close the hamburger menu
        setShowHambugerMenu( false );
        document.body.classList.remove( 'leftNavFlyoutOpen' );
      }
    }
  );
};

export const dispatchMenu = ( data, methods ) => {
  const { event, hasClickedBack } = data;
  const { updateBackAction, setIsInvisible, closeOverlay } = methods;
  const classList = event.srcElement.classList;
  if( classList.contains( LEFTNAV_FLYOUT_OPEN ) ){
    updateBackAction( { backAction: {
      label: 'Menu'
    } }, { callback: () => {
    // Set this ref to true so that in our close action we don't hide the hamburger menu (below)
      hasClickedBack.current = true;

      // Set invisible to false before closing so that the menu re-appears instantly
      setIsInvisible( false );

      // Close the overlay
      closeOverlay();
    } } );
  }
};

export const LEFTNAV_FLYOUT_OPEN = 'leftNavFlyoutOpen';

/**
 * Property type definitions
 *
 * @typedef MobileNavContainerProps
 * @type {object}
 * @property {array} modules - sets the modules
 */
export const propTypes = {
  modules: PropTypes.array
};

MobileNavContainer.propTypes = propTypes;

export default MobileNavContainer;
