/**
 * The UserContextProvider component is used to provide the user details to any component which needs it.
 *
 * @module views/__core/UserContextProvider/UserContextProvider
 */
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

import deepmerge from 'deepmerge';

import { useRetailerVisitorId } from '@ulta/core/hooks/useRetailerVisitorId/useRetailerVisitorId';
import { useSessionAction } from '@ulta/core/hooks/useSessionAction/useSessionAction';
import { useAppConfigContext } from '@ulta/core/providers/AppConfigProvider/AppConfigProvider';
import { useLayerHostContext } from '@ulta/core/providers/LayerHostProvider/LayerHostProvider';
import { PROTECTED_PAGE_FLOW, usePageDataContext } from '@ulta/core/providers/PageDataProvider/PageDataProvider';
import * as utils from '@ulta/core/providers/UserContextProvider/UserContextProvider' ;
import { hasItems } from '@ulta/core/utils/array/array';
import { constants } from '@ulta/core/utils/constants/constants';
import { devLogger, LOG_TOPIC } from '@ulta/core/utils/devMode/devMode';
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';
import { handleReload } from '@ulta/core/utils/handleLocation/handleLocation';
import { getStorage, removeStorage, setStorage } from '@ulta/core/utils/storage/storage';
import { isFunction } from '@ulta/core/utils/types/types';

import { STORAGE_KEY } from '../../utils/storageKeys/storageKeys';

/**
 * Represents a UserContextProvider component
 *
 * The UserContextProvider is responsible for maintaining a user object based on
 * multiple data sources.
 *
 * User object acquisition flow:
 * 1. ATG profile, comes from an API request for session status
 * 2. retailerVisitorId is a cookie that is set by a third party
 * 3. Combine initial results
 * 4. Setup listener for any updates
 *    4a. Merge any incoming session data w/ current DXL user
 * 5. Incoming session action handler
 *
 * @method
 * @param {object} props - React properties passed from composition
 * @returns UserContextProvider
 */
export const UserContextProvider = function( { children } ){
  const { refetchRef, protectedPageFlow } = usePageDataContext();
  const { isDXLSessionManagement } = useAppConfigContext();
  const { incrementPageRefreshes, sessionAttempts } = useLayerHostContext();

  // DXL sends a sessionAction on any Page model and we only want to process it
  // on page load and when we need to re-authenticate during a protected page refetch
  const isInitialSessionPing = useRef( true );

  // Sometimes we need to refetch the page after the user has been resolved
  const refetchPageAfterResolved = useRef( false );

  // We use this flag to block multiple session requests from being sent and track the session flow
  const sessionUpdateRequested = useRef( false );

  // If a sign out has been requested, we need to perform some cleanup actions
  const signOutRequested = useRef( false );

  // Houses any callbacks that need to be executed after a successful login
  const postLoginCallbacks = useRef( [] );

  // 1. Listen for DXL profile
  const [dxlUser, setDxlUser] = useState( { ...utils.getUserFromStorage(), resolved: false } );
  const [cart, setCart] = useState( { itemCount: Number( dxlUser?.cartItemCount ) } );

  // 2. Listen for retailer visitor id
  const [retailerVisitorId] = useRetailerVisitorId();

  // 3. Create initial user/cart objects
  const [user, setUser] = useState( {
    ...dxlUser,
    retailerVisitorId
  } );

  // 4. Listen to and process updates
  const userResolver = useCallback(
    utils.composeUserResolver(
      {
        dxlUser,
        isDXLSessionManagement,
        isInitialSessionPing,
        protectedPageFlow,
        postLoginCallbacks,
        refetchRef,
        retailerVisitorId,
        sessionAttempts,
        user
      },
      { setUser, setCart, incrementPageRefreshes, shouldUpdateSession },
    ),
    [dxlUser, isDXLSessionManagement, user, retailerVisitorId, setUser, setCart, incrementPageRefreshes]
  );
  useEffect( userResolver, [userResolver] );

  // 4a. Merge any incoming session data w/ current DXL user
  const updateSession = useCallback( utils.composeUpdateSession( { setDxlUser, updateSessionDataInStorage } ), [setDxlUser] );

  // 5. Process session action
  const [processSessionAction] = useSessionAction(
    { user, refetchPageAfterResolved, sessionUpdateRequested, isInitialSessionPing },
    { updateSession, setDxlUser, setUser }
  );

  // Session reset handler
  const resetSession = useCallback( utils.composeResetSession( { user }, { setUser } ), [
    user,
    setUser
  ] );

  // Post login callbacks
  const addPostLoginCallback = useCallback( utils.composeAddPostLoginCallback( { postLoginCallbacks } ), [
    postLoginCallbacks
  ] );

  return (
    <UserContext.Provider
      value={ {
        addPostLoginCallback,
        cart,
        isInitialSessionPing,
        processSessionAction,
        resetSession,
        sessionUpdateRequested,
        setCart,
        signOutRequested,
        updateSession,
        user
      } }
    >
      { children }
    </UserContext.Provider>
  );
};

/**
 * Adds a callback to the postLoginCallbacks array
 *
 * @param {object} data - Arguments
 * @param {object} data.postLoginCallbacks - Array ref of callbacks to execute after login
 * @returns {function} addPostLoginCallback
 */
export const composeAddPostLoginCallback = ( data ) => ( callback ) => {
  const { postLoginCallbacks = {} } = handleEmptyObjects( data );
  postLoginCallbacks.current = postLoginCallbacks.current || [];
  postLoginCallbacks.current.push( callback );
};

/**
 * Merges incoming sessionData
 * @param {object} methods - Methods
 * @returns {function} updateSession
 */
export const composeUpdateSession = ( methods ) => ( data ) => {
  const { setDxlUser, updateSessionDataInStorage } = methods || {};
  const { sessionData } = data || {};

  if( !sessionData ){
    return;
  }
  setDxlUser( ( current ) => ( { ...current, ...sessionData } ) );
  if( sessionData.stk ){
    updateSessionDataInStorage( sessionData );
  }

};

/**
 * Reconciles user object and updates storage
 * @param {object} data - Arguments
 * @param {object} data.dxlUser - DXL session data
 * @param {object} data.user - Current user object
 * @param {object} data.retailerVisitorId - retailerVisitorId
 * @param {object} methods - Methods
 * @param {function} methods.setUser - setUser setter
 * @returns {function} - Callback that will handle reconciling the user object
 */
export const composeUserResolver = ( data, methods ) => () => {
  const {
    dxlUser = {},
    isDXLSessionManagement,
    isInitialSessionPing = {},
    protectedPageFlow = {},
    postLoginCallbacks = {},
    refetchRef = {},
    retailerVisitorId,
    sessionAttempts = {},
    user = {}
  } = handleEmptyObjects( data );

  const { incrementPageRefreshes, setUser, setCart, shouldUpdateSession } = methods || {};

  if( isDXLSessionManagement !== true || !setUser || !setCart || !shouldUpdateSession( user, dxlUser ) ){
    return;
  }

  const updatedUser = { ...dxlUser, retailerVisitorId };

  // Transform loginType/loginStatus
  // DXL's `loginStatus` is our `loginType`
  if( typeof dxlUser.loginStatus === 'string' ){
    updatedUser.loginType = dxlUser.loginStatus;
    updatedUser.loginStatus = updatedUser.loginType !== constants.LOGIN_STATUS.ANONYMOUS;
  }

  // Set a flag if the user has changed
  const isPostLogin = !isInitialSessionPing.current && user.loginType && user.loginType !== updatedUser.loginType;

  // We only want gti-based side effects when anonymous and in some sort of user session recovery flow
  const gtiChanged = !user.loginStatus && user.gti !== updatedUser.gti && sessionAttempts.current > 0;

  // Do nothing if the user has not changed
  if( utils.isUserSame( { prevUser: user, updatedUser } ) ){
    return;
  }

  const loginStatusDebug = `loginStatus: ${user.loginType} > ${updatedUser.loginType}`;
  const gtiDebug = `gti: ${user.gti?.slice( -5 )} > ${updatedUser.gti?.slice( -5 )}`;
  devLogger( {
    topic: LOG_TOPIC.Session,
    title: `[User Resolver] ${loginStatusDebug} | ${gtiDebug} | ${sessionAttempts.current}`,
    value: { user, updatedUser },
    collapsed: true
  } );

  // Determine if we need to refresh the page
  if( ( gtiChanged || isPostLogin ) && protectedPageFlow.current === PROTECTED_PAGE_FLOW.Idle ){
    refetchRef.current?.();
    incrementPageRefreshes();
  }

  // Update storage if the user has changed
  utils.updateUserInStorage( { updatedUser } );

  // When on a protected page, hard reload on user state change
  if( isPostLogin && protectedPageFlow.current === PROTECTED_PAGE_FLOW.Resolved ){
    handleReload();
  }

  // Determine if we need to run post-login callbacks
  if( isPostLogin && hasItems( postLoginCallbacks.current ) ){
    postLoginCallbacks.current.forEach( ( callback ) => isFunction( callback ) && callback( { user: updatedUser } ) );
    postLoginCallbacks.current = [];
  }

  // Update user, cart
  setUser( updatedUser );
  populateGlobalPageData( { updatedUser, cartItemCount: dxlUser.cartItemCount } );
  setCart( { itemCount: Number( dxlUser.cartItemCount ) } );
};

export const shouldUpdateSession = ( storedUser, updatedUser ) => {
  const storedDate = new Date( storedUser.lastSessionRefreshTime ).valueOf();
  const newDate = new Date( updatedUser.lastSessionRefreshTime ).valueOf();
  if( !storedDate || !newDate ){
    return true;
  }
  return storedDate <= newDate;
};

export const GLOBAL_PAGE_DATA_MODEL = {
  'order': {
    'itemCount': ''
  },
  'profile': {
    'email': '',
    'firstName': '',
    'lastName': '',
    'GTI': ''
  },
  'rewards': {
    'isCardHolder': false,
    'loyaltyId': '',
    'programId': '',
    'memberSince': '',
    'platinumMember': '',
    'platinumMemberType': '',
    'userType': '',
    'clubPoints': null
  },
  'cart': {
    'autoRemovedItems': ''
  },
  'errorPageData': {
    'error': ''
  },
  'info': {
    'saga': ''
  },
  'retailerVisitorId':''
};

/**
 * To update GlobalPageData
 * @param {object} updated User object
 */
export const populateGlobalPageData = ( data )=>{
  const { updatedUser = {}, cartItemCount = 0 } = handleEmptyObjects( data );
  const userDetails = deepmerge( GLOBAL_PAGE_DATA_MODEL, { ...global.globalPageData } );
  userDetails.order.itemCount = cartItemCount;
  userDetails.profile.email = updatedUser.email;
  userDetails.profile.firstName = updatedUser.firstName;
  userDetails.profile.lastName = updatedUser.lastName;
  userDetails.profile.GTI = updatedUser.gti;
  userDetails.rewards.loyaltyId = updatedUser.loyaltyId;
  userDetails.retailerVisitorId = updatedUser.retailerVisitorId;
  global.globalPageData = userDetails;
};
/**
 * Is user same as before
 * @param {object} data
 * @returns {boolean}
 */
export const isUserSame = ( data ) => {
  const { prevUser = {}, updatedUser = {} } = handleEmptyObjects( data );

  return Object.keys( updatedUser ).reduce( ( isSame, key ) => {
    if( key === USER_PROFILE_KEYS.SessionRefreshTime ){
      return isSame;
    }

    return isSame && prevUser[key] === updatedUser[key];
  }, true );
};

/**
 * Reset user session
 * @param {object} data - Arguments
 * @param {object} data.user - User object
 * @param {object} methods - Methods
 * @param {function} methods.setUser - User setter
 * @returns {function} - Reset callback
 */
export const composeResetSession = ( data, methods ) => () => {
  const { user = {} } = handleEmptyObjects( data );
  const { setUser } = methods || {};

  // Don't clear user if unresolved already
  if( !setUser || !user.resolved ){
    return;
  }

  devLogger( '[User] Reset user session', 0, LOG_TOPIC.Session );

  setUser( { resolved: false } );

  utils.resetUserStorage();
};

/**
 * Returns user profile from storage
 *
 * @returns {object} User profile object
 */
export const getUserFromStorage = () => {
  const user = getStorage( {
    secure: false,
    key: STORAGE_KEY.user
  } ) || {};

  return { resolved: false, cartItemCount: 0, ...user };
};

/**
 * Store user in localStorage
 * @param {object} data args
 * @param {object} data.user user object
 */
export const updateUserInStorage = ( data ) => {
  const { updatedUser = { resolved: false } } = handleEmptyObjects( data );

  setStorage( {
    secure: false,
    key: STORAGE_KEY.user,
    value: updatedUser
  } );
};

/**
 * Removes user from local storage
 */
export const resetUserStorage = () => {
  devLogger( '[User] Clearing local storage entry', 0, LOG_TOPIC.Session );
  removeStorage( { secure: false, key: STORAGE_KEY.user } );
  removeStorage( { secure: false, key: STORAGE_KEY.userLegacySessionData } );
};


/**
 * Store sessionData in localStorage
 * @param {object} data args
 * @param {object} data.sessionData user object
 */
export const updateSessionDataInStorage = ( data ) => {
  const { stk = { } } = handleEmptyObjects( data );
  const sessionData = getStorage( { secure: false, key: STORAGE_KEY.userLegacySessionData } ) || {};
  sessionData.secureToken = stk;
  setStorage( {
    secure: false,
    key: STORAGE_KEY.userLegacySessionData,
    value: sessionData
  } );
};

/**
 * @const {object} USER_PROFILE_KEYS - User profile keys
 */
export const USER_PROFILE_KEYS = {
  Resolved: 'resolved',
  Gti: 'gti',
  LoginStatus: 'loginStatus',
  LoginType: 'loginType',
  RetailerVisitorId: 'retailerVisitorId',
  // profile
  FirstName: 'firstName',
  LastName: 'lastName',
  Email: 'email',
  EmailOptIn: 'emailOptIn',
  HashedEmail: 'hashedEmail',
  DateOfBirth: 'dateOfBirth',
  // rewards
  PointsRedeemed: 'pointsRedeemed',
  PointsRedeemedValue: 'pointsRedeemedValue',
  PointsBalance: 'pointsBalance',
  RewardsMemberType: 'rewardsMemberType',
  IsRewardsMember: 'isRewardsMember',
  LoyaltyId: 'loyaltyId',
  SessionRefreshTime: 'lastSessionRefreshTime'
};

/**
 * Context provider for react reuse
 * @typedef {object} UserContext
 * @property {object} user - User object
 * @property {string} user.resolved - resolved
 * @property {string} user.gti - gti
 * @property {string} user.loginStatus - loginStatus
 * @property {string} user.loginType - loginType
 * @property {string} user.retailerVisitorId - retailerVisitorId
 * @property {string} user.firstName - firstName
 * @property {string} user.lastName - lastName
 * @property {string} user.email - email
 * @property {string} user.emailOptIn - emailOptIn
 * @property {string} user.rewardsMemberType - rewardsMemberType
 * @property {string} user.isRewardsMember - isRewardsMember
 * @property {string} user.loyaltyId - loyaltyId
 * @property {string} user.bagCount - bagCount
 * @property {string} user.lastSessionRefreshTime - session last updated timestamp
 * @property {function} resetSession - Resets user session, clears local storage
 */
export const UserContext = createContext( {
  user: {},
  cart: {
    itemCount: 0
  }
} );

/**
 * @methods
 * @returns {UserContext}
 */
export const useUserContext = () => useContext( UserContext );

UserContextProvider.displayName = 'UserContextProvider';

export default UserContextProvider;
