/**
 * The common routes component is used for routes that don&#x27;t belong to a specific workstream such as the homepage, faq&#x27;s, or marketing pages that need to be defined.
 *
 * @module views/Navigation/Routes
 */
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { useApolloClient } from '@apollo/client';

import ContentContainer from '@ulta/core/containers/ContentContainer/ContentContainer';
import RouteContainer from '@ulta/core/containers/RouteContainer/RouteContainer';
import VisualizationProvider from '@ulta/core/providers/VisualizationProvider/VisualizationProvider';
import CMS_QUERY from '@ulta/core/utils/graphql/queries/cms/cms';
import CMS_PREVIEW_QUERY from '@ulta/core/utils/graphql/queries/cms/preview';

/**
 * Represents a Routes component
 *
 * @method
 * @param { Object } props - React properties passed from composition
 * @returns Routes
 */
export const Routes = function( props ){
  const { isNative, location } = props;
  const apolloClient = useApolloClient();
  apolloClient.addResolvers( {} );

  return (
    <Switch>
      <Route
        exact
        path='/cms/preview'
        render={ renderProps => {
          return (
            <VisualizationProvider { ...renderProps } >
              <RouteContainer
                { ...renderProps }
                DXL_QUERY={ CMS_PREVIEW_QUERY }
                fetchPolicy={ 'cache-first' }
                isNative={ isNative }
              />
            </VisualizationProvider>
          );
        } }
      />
      <Route
        path='/'
        render={ renderProps => {
          return (
            <ContentContainer>
              <RouteContainer
                { ...renderProps }
                DXL_QUERY={ CMS_QUERY }
                /*
                  this fetch policy is very senstive for staging and prod usage. on server we need to be cache-first
                  to build the apollo state, however one that is complete can respect on the client the other configurations
                */
                fetchPolicy={ 'cache-first' }
                { ...location && { location } }
                isNative={ isNative }
              />
            </ContentContainer>
          );
        } }
      />
    </Switch>
  );
};

export default Routes;


