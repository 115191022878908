/**
 * NavigationLinksGroup component to handle rendering of Primary & Secondary Menu in Navigation Overlay.
 *
 * @module views/components/NavigationLinksGroup
 * @memberof -Common
 */
import './NavigationLinksGroup.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Icon from '@ulta/core/components/Icon/Icon';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { isServer } from '@ulta/core/utils/device_detection/device_detection';
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';

import { extractLastWord, extractTextWithoutLastWord, isPrimary } from '@ulta/utils/navUtils/navUtils';

import NavigationLink from '../NavigationLink/NavigationLink';
import { useTopBar } from '../TopBar/TopBar';
import * as utils from './NavigationLinksGroup';

/**
 * Represents a NavigationLinksGroup component
 *
 * @method
 * @param {NavigationLinksGroupProps} props - React properties passed from composition
 * @returns NavigationLinksGroup
 */
export const NavigationLinksGroup = React.forwardRef( ( props, _ ) => {
  const { headerAction, modules } = props;
  const { breakpoint, inflection } = useDeviceInflection();
  const isLargeDevice = breakpoint?.isLargeDevice();
  const isMobile = inflection.MOBILE;

  const {
    setShowBack,
    setMenuNavigation,
    activePrimaryLinks,
    activeNavigationLinksGroup,
    setActiveNavigationLinksGroup
  } = useTopBar();

  let textWithoutLastWord, lastWord;

  if( isMobile && headerAction?.label ){
    textWithoutLastWord = extractTextWithoutLastWord( headerAction.label );
    lastWord = extractLastWord( headerAction.label );
  }

  if( !modules && !headerAction?.label ){
    return null;
  }

  const groupIsPrimary = isPrimary( props.displayType );
  const titleTextStyle = groupIsPrimary ? ( isLargeDevice ? 'title-6' : 'title-4' ) : 'body-3';
  const titleTextColor = groupIsPrimary ?
    activeNavigationLinksGroup ?
      isLargeDevice ?
        'neutral-600' :
        'black' :
      'black' :
    'neutral-600';

  return (
    <div className='NavigationLinksGroup'>
      {
        headerAction?.label &&
        <div
          className={ classNames( 'NavigationLinksGroup__title', {
            [`NavigationLinksGroup__title--${props.displayType}`]: props.displayType
          } ) }
        >
          { /* Splitting the text to match Invision design in Mobile if there are more than one word  */ }
          { headerAction?.url &&
            <Link_Huge
              action={ headerAction }
            >
              { utils.navigationLinksGroupTitle( { titleTextStyle, titleTextColor, isMobile, textWithoutLastWord, headerAction, lastWord, groupIsPrimary } ) }
            </Link_Huge>
          }

          { !headerAction?.url &&
            utils.navigationLinksGroupTitle( { titleTextStyle, titleTextColor, isMobile, textWithoutLastWord, headerAction, lastWord, groupIsPrimary } )
          }
        </div>
      }

      { modules?.length > 0 && (
        <ul className='NavigationLinksGroup__nav'>
          { modules?.map( ( groupItem, index ) => {
            const isActive = activeNavigationLinksGroup === groupItem.action?.label;
            const activeItem = groupItem.action.label?.replaceAll( /[^A-Z0-9]/ig, '_' );
            const renderGroupOverlay = isServer() || isActive;

            // TODO: Make this a helper function with easy to follow if conditions
            const linkTextStyle = isLargeDevice ?
              groupIsPrimary ?
                'title-6' :
                'body-2' :
              groupIsPrimary ?
                'title-4' :
                activeNavigationLinksGroup ?
                  'body-2' :
                  'title-6';

            return (
              <li
                className={ classNames( `NavigationLinksGroup__item NavigationLinksGroup__secondTier`, {
                  [`NavigationLinksGroup__item--secondary`]: !groupIsPrimary,
                  [`NavigationLinksGroup__secondTier--active`]: isActive
                } ) }
                key={ index }
              >
                { /* if module consists of modules then open a right Navigation Overlay if not then it will be a link */ }
                { groupItem.modules &&
                  <>
                    <Button
                      variant='unstyled'
                      label={ groupItem.action?.label }
                      iconRight
                      fullWidth
                      iconImage='CaretForward'
                      ariaControls={ groupItem.id }
                      isExpanded={ activeNavigationLinksGroup === groupItem.action.label }
                      onClick={ () => {
                        utils.handleLeftNavSubMenu( { activeNavigationLinksGroup, groupItemLabel : groupItem.action.label }, { setActiveNavigationLinksGroup } );
                        setShowBack( activePrimaryLinks );
                        setMenuNavigation( { forward: true, backward: false } );
                      } }
                      iconSize={ groupIsPrimary ? 'lg' : 'default' }
                      className={ classNames( 
                        'NavigationLinksGroup__button', {
                        'NavigationLinksGroup__button--secondary': !groupIsPrimary,
                        'NavigationLinksGroup--inActiveMenu':
                          activeNavigationLinksGroup && activeNavigationLinksGroup !== groupItem.action.label && isLargeDevice,
                        [`NavigationLinksGroup__${activeItem}`]: activeItem
                      } ) }
                      action={ groupItem.action }
                      ariaHiddenIcon={ true }
                    />

                    { groupItem?.action?.description &&
                      <Text htmlTag='p'
                        textStyle='body-3'
                        color='neutral-600'
                      >
                        { groupItem.action?.description }
                      </Text>
                    }

                    { /*
                    Stop-gap: This div w/ forced dssplay prevents some animation lag for large 2nd tier flyouts
                    We should not be rendering the tier-2s until they are open, but there's an issue
                    with the width which would need to be dynamically set which we can address later
                     */ }
                    <div
                      style={ {
                        display: renderGroupOverlay ? 'block' : 'none'
                      } }
                    >
                      <NavigationLink
                        parentID={ groupItem.id }
                        modules={ groupItem.modules }
                        action={ groupItem.action }
                        color={ isLargeDevice && activeNavigationLinksGroup ? 'neutral-600' : 'black' }
                      />
                    </div>
                  </>
                }
                { !groupItem.modules &&
                  <NavigationLink
                    key={ `${groupItem.id}:${index}` }
                    htmlTag='p'
                    textStyle={ linkTextStyle }
                    color={ isLargeDevice && activeNavigationLinksGroup ? 'neutral-600' : 'black' }
                    isPrimary={ groupIsPrimary }
                    { ...groupItem }
                    isInline={ true }
                  />
                }
              </li>
            );
          } ) }
        </ul>
      ) }
    </div>
  );
} );

/**
 * navigationLinksGroupTitle function to render secondary navigationLinksGroup Title
 * @method
 * @param {object} data - Passing required data as an argument
 * @param {string} data.titleTextStyle - titleTextStyle
 * @param {string} data.titleTextColor - titleTextColor
 * @param {boolean} data.isMobile -  returns true or false based on inflection
 * @param {string} data.textWithoutLastWord - textWithoutLastWord
 * @param {object} data.headerAction - headerAction contains url and label
 * @param {string} data.lastWord - lastWord
 * @param {boolean} data.groupIsPrimary - groupIsPrimary returns true or false
*/
export const navigationLinksGroupTitle = ( data )=> {
  const { titleTextStyle, titleTextColor, isMobile, textWithoutLastWord, headerAction, lastWord, groupIsPrimary } = data || {};
  if( !headerAction ){
    return null;
  }

  return (
    <>
      <Text htmlTag='h2'
        textStyle={ titleTextStyle }
        color={ titleTextColor }
      >
        { isMobile ? textWithoutLastWord : headerAction?.label }

        { lastWord &&
        <>
                  &nbsp;
          <span>
            { lastWord }
            { groupIsPrimary &&
            <Icon name='ArrowForward'
              size='m'
              aria-hidden={ true }
            /> }
          </span>
        </>
        }

        { !lastWord && groupIsPrimary &&
        <Icon name='ArrowForward'
          size='m'
          aria-hidden={ true }
        />
        }
      </Text>
    </>
  );

};
/**
 * Handles toggling the active navigation links group based on the provided data and method.
 *
 * @param {Object} data - The data object containing information about the current active navigation links group and group item label.
 * @param {Object} method - The method object containing the setActiveNavigationLinksGroup function.
 * @param {string} data.activeNavigationLinksGroup - The currently active navigation links group.
 * @param {string} data.groupItemLabel - The label of the group item being toggled.
 * @param {Function} method.setActiveNavigationLinksGroup - A function to set the active navigation links group.
 */
export const handleLeftNavSubMenu = ( data, method ) => {
  const { activeNavigationLinksGroup, groupItemLabel } = handleEmptyObjects( data );
  const { setActiveNavigationLinksGroup } = handleEmptyObjects( method );

  if( activeNavigationLinksGroup && activeNavigationLinksGroup === groupItemLabel ){
    setActiveNavigationLinksGroup( null );
  }
  else {
    setActiveNavigationLinksGroup( groupItemLabel );
  }
};

/**
 * Property type definitions
 *
 * @typedef NavigationLinksGroupProps
 * @type {object}
 * @property {object} headerAction - sets the Heading
 * @property {array} modules - sets the menu
 */
export const propTypes = {
  headerAction: PropTypes.object,
  modules: PropTypes.array
};

NavigationLinksGroup.propTypes = propTypes;
NavigationLinksGroup.displayName = 'NavigationLinksGroup';

export default NavigationLinksGroup;
