import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12Print( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
      viewBox='0 0 12 12'
    >
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='W_XL_Coupon-Page_Single'
          transform='translate(-856.000000, -390.000000)'
          fill='#000000'
          fillRule='nonzero'
        >
          <g id='Group-14'
            transform='translate(383.000000, 327.000000)'
          >
            <g id='Components/Button/Tertiary/Compact/Leading-Icon/Right--'
              transform='translate(449.000000, 48.000000)'
            >
              <g id='streamline-icon-print-text@140x140'
                transform='translate(24.000000, 15.000000)'
              >
                <path d='M10.6468531,2.93706294 C11.246449,2.93706294 11.7341393,3.41618758 11.7479509,4.01246377 L11.7482517,4.03846154 L11.7482517,7.70979021 C11.7482517,8.30938607 11.2691271,8.79707637 10.6728509,8.81088801 L10.6468531,8.81118881 L9.17832168,8.81118881 C8.97555979,8.81118881 8.81118881,8.64681783 8.81118881,8.44405594 C8.81118881,8.24725765 8.9660331,8.08662517 9.16053376,8.07734644 L9.17832168,8.07692308 L10.6468531,8.07692308 C10.8436514,8.07692308 11.0042839,7.92207879 11.0135627,7.72757813 L11.013986,7.70979021 L11.013986,4.03846154 C11.013986,3.84166324 10.8591417,3.68103076 10.6646411,3.67175203 L10.6468531,3.67132867 L1.1013986,3.67132867 C0.904600303,3.67132867 0.743967826,3.82617296 0.734689097,4.02067362 L0.734265734,4.03846154 L0.734265734,7.70979021 C0.734265734,7.90658851 0.889110026,8.06722099 1.08361068,8.07649971 L1.1013986,8.07692308 L2.56993007,8.07692308 C2.77269195,8.07692308 2.93706294,8.24129406 2.93706294,8.44405594 C2.93706294,8.64085424 2.78221865,8.80148672 2.58771799,8.81076545 L2.56993007,8.81118881 L1.1013986,8.81118881 C0.501802745,8.81118881 0.0141124461,8.33206417 0.000300804021,7.73578798 L0,7.70979021 L0,4.03846154 C0,3.43886568 0.479124644,2.95117538 1.07540083,2.93736374 L1.1013986,2.93706294 L10.6468531,2.93706294 Z'
                  id='Path'
                ></path>
                <path d='M2.56993007,4.40559441 C2.77269195,4.40559441 2.93706294,4.62945203 2.93706294,4.90559441 C2.93706294,5.17361495 2.78221865,5.39238108 2.58771799,5.40501783 L2.56993007,5.40559441 L1.83566434,5.40559441 C1.63290245,5.40559441 1.46853147,5.18173678 1.46853147,4.90559441 C1.46853147,4.63757387 1.62337576,4.41880773 1.81787642,4.40617099 L1.83566434,4.40559441 L2.56993007,4.40559441 Z'
                  id='Path'
                ></path>
                <path d='M9.17832168,5.87412587 L2.56993007,5.87412587 C2.36716819,5.87412587 2.2027972,6.03849686 2.2027972,6.24125874 L2.2027972,11.3811189 C2.2027972,11.5838808 2.36716819,11.7482517 2.56993007,11.7482517 L9.17832168,11.7482517 C9.38108356,11.7482517 9.54545455,11.5838808 9.54545455,11.3811189 L9.54545455,6.24125874 C9.54545455,6.03849686 9.38108356,5.87412587 9.17832168,5.87412587 Z M8.81118881,6.60837762 L8.81118881,11.013972 L2.93706294,11.013972 L2.93706294,6.60837762 L8.81118881,6.60837762 Z'
                  id='Path'
                ></path>
                <path d='M8.44405594,0 L3.3041958,0 C2.69591015,0 2.2027972,0.49311295 2.2027972,1.1013986 L2.2027972,3.3041958 C2.2027972,3.50695769 2.36716819,3.67132867 2.56993007,3.67132867 L9.17832168,3.67132867 C9.38108356,3.67132867 9.54545455,3.50695769 9.54545455,3.3041958 L9.54545455,1.1013986 C9.54545455,0.49311295 9.0523416,0 8.44405594,0 Z M8.44405594,0.734265734 L8.46184386,0.734689097 C8.65634452,0.743967826 8.81118881,0.904600303 8.81118881,1.1013986 L8.81118881,2.93704895 L2.93706294,2.93704895 L2.93706294,1.1013986 C2.93706294,0.898636718 3.10143392,0.734265734 3.3041958,0.734265734 L8.44405594,0.734265734 Z'
                  id='Path'
                ></path>
                <path d='M7.70979021,7.34265734 C7.91255209,7.34265734 8.07692308,7.56651497 8.07692308,7.84265734 C8.07692308,8.11067788 7.92207879,8.32944402 7.72757813,8.34208076 L7.70979021,8.34265734 L4.03846154,8.34265734 C3.83569965,8.34265734 3.67132867,8.11879972 3.67132867,7.84265734 C3.67132867,7.5746368 3.82617296,7.35587067 4.02067362,7.34323392 L4.03846154,7.34265734 L7.70979021,7.34265734 Z'
                  id='Path'
                ></path>
                <path d='M6.60839161,8.81118881 C6.81115349,8.81118881 6.97552448,9.03504644 6.97552448,9.31118881 C6.97552448,9.57920935 6.82068018,9.79797549 6.62617953,9.81061223 L6.60839161,9.81118881 L4.03846154,9.81118881 C3.83569965,9.81118881 3.67132867,9.58733119 3.67132867,9.31118881 C3.67132867,9.04316827 3.82617296,8.82440214 4.02067362,8.81176539 L4.03846154,8.81118881 L6.60839161,8.81118881 Z'
                  id='Path'
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}

export default Svg12Print;