import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16Check( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <path
        d='M1 7.36L5.946 13 15 2.5'
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg16Check;
