import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16StarOutlined( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <path
        d='M7.998 1a.51.51 0 01.454.277l2.082 4.125 4.007.397a.5.5 0 01.31.855l-3.297 3.268 1.223 4.44a.506.506 0 01-.716.583l-4.063-2.012-4.056 2.01a.506.506 0 01-.716-.583l1.222-4.44-3.3-3.269a.5.5 0 01.311-.855L5.466 5.4l2.079-4.122A.51.51 0 017.998 1zm0 1.45L6.3 5.82a.933.933 0 01-.647.494l-.094.014-3.1.306 2.647 2.622a.933.933 0 01.263.822l-.02.089-.969 3.516 3.205-1.586a.933.933 0 01.74-.038l.089.038 3.209 1.589-.968-3.516a.933.933 0 01.182-.844l.061-.067 2.645-2.622-3.1-.307a.933.933 0 01-.694-.425L9.7 5.822z'
        fill='currentColor'
        fillRule='nonzero'
      />
    </SvgContainer>
  );
}

export default Svg16StarOutlined;
