import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16ConsciousBeauty( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <g id='ConsciousBeauty'>
        <path fill='#4a9462'
          d='M5.4 12.5c1.9-3.2 4.9-2.4 5.3-2.4-2.7.6-4 3.5-3.7 3a7.4 7.4 0 0 1 5.5-3h1.2l-.2.3c-1.3-.2-2.1.2-2.7 1.5a6.9 6.9 0 0 1-2.4 3c-1.6 1.2-3.4 1.2-5.3.8 1.3-.7 1.7-2 2.3-3.2'
        />
        <path fill='#a5d59e'
          d='M9.3 6c-1.4-2.8-4.1-2.2-6-2.5-.3 0-.6 0-1-.2l.2.3c1 0 1.8.4 2.1 1.5.4 1.2 1 2.1 2 2.9C7.7 9 9.2 9.2 11 9c-1-.7-1.3-2-1.7-3'
        />
        <path fill='#fac8cf'
          d='M13.4 6.4c.7-2.6-.6-4-1.3-5.6 0-.3-.2-.5-.3-.7l-.2.2c.6.7.5 1.4.1 2.4-.3 1-.7 1.7-.7 2.8 0 1.5.7 2.6 1.4 3.8 0-1.2.7-1.7 1-2.9'
        />
      </g>
    </SvgContainer>
  );
}

export default Svg16ConsciousBeauty;
