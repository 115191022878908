/**
 * component for a video player to function inline inside other promotional components
 *
 * @module views/components/InlineVideo
 * @memberof -Common
 */
import './InlineVideo.scss';

import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import { useIntersectionObserver } from '@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver';
import { handleIntersection } from '@ulta/core/utils/intersectionProcessor/intersectionProcessor';
import { getVideoUrl, VIDEO_FORMATS } from '@ulta/core/utils/media/media';

const Player = typeof ReactPlayer.default !== 'undefined' ? ReactPlayer.default : ReactPlayer;

/**
 * Represents a InlineVideo component
 *
 * @method
 * @param {InlineVideoProps} props - React properties passed from composition
 * @returns InlineVideo
 */
export const InlineVideo = function( props ){
  const {
    damStaticDomainPath,
    isCaptionsEnabled,
    name,
    root,
    rootMargin,
    threshold,
    videoAccessibility,
    isAutoPlay,
    videoUrl,
    videoProfiles
  } = props;

  const newVideoUrl = getVideoUrl( { videoProfiles, format: VIDEO_FORMATS.VP9720P, defaultUrl: props.videoUrl } );

  const wrapperRef = useRef();
  const [isPlaying, setisPlaying] = useState( !!isAutoPlay );
  const [userPause, setUserPause] = useState( false );
  const [playButton, setPlayButton] = useState( !!isAutoPlay );

  // onClick handle for the play/pause button
  const handlePlayPause = () => {
    setisPlaying( !isPlaying );
    setPlayButton( !playButton );
    setUserPause( !userPause );
  };

  // Toggles the video for autoPlay
  const videoToggle = () => {
    setisPlaying( !isPlaying );
    setPlayButton( playButton );
  };

  const userPausedVideo = ( userPause && !isPlaying );
  const { visible } = useIntersectionObserver(
    wrapperRef,
    {
      root: root,
      rootMargin: rootMargin,
      threshold: threshold
    },
    handleIntersection( props )
  );

  // useEffect to control the playing and pausing of the video when on autoPlay
  useEffect( () => {
    // Returns if it isn't autoplay and if the user pauses the video
    if( !isAutoPlay || ( userPausedVideo ) ){
      return;
    }
    // Checks if video is visible and toggles the video accordingly
    if( !wrapperRef.current || !visible ){
      videoToggle();
    }
    videoToggle();
  }, [wrapperRef, visible] );

  if( !videoUrl ){
    return null;
  }

  return (
    <div
      className={ classNames( 'InlineVideo', {
        'InlineVideo--playing' : isPlaying,
        'InlineVideo--paused' : !isPlaying
      } ) }
      ref={ wrapperRef }
    >
      <Button
        icon
        tiny
        secondary
        iconImage={ playButton ? 'Pause' : 'Video' }
        iconSize='m'
        ariaHiddenIcon={ true }
        className='InlineVideo__controlButton'
        onClick={ handlePlayPause }
        ariaLabel={ videoAccessibility }
      />
      <Player
        url={ newVideoUrl }
        width='100%'
        height='100%'
        playing={ isPlaying }
        config={ { file: {
          attributes: {
            disablePictureInPicture: true,
            controlsList: 'nodownload'
          },
          ...( isCaptionsEnabled && { tracks: [
            {
              kind: 'subtitles',
              src: `${damStaticDomainPath}${name}_vtt`,
              srcLang: 'en',
              default: false
            }
          ] } )
        } } }
        tabIndex={ -1 } // only the pause/play button should be keyboard accessible
        className='InlineVideo__playerWrapper'
        controls={ false }
        loop={ !!isAutoPlay }
        muted={ !!isAutoPlay }
        playsinline={ true }
      />
    </div>
  );
};

/**
 * Property type definitions
 * @typedef InlineVideoProps
 * @type {object}
 * @property {string} alt - video alt text
 * @property {string} damStaticDomainPath - DAM static path
 * @property {boolean} isCaptionsEnabled - Captions availability
 * @property {string} name - video name
 * @property {string} videoAccessibility - value for aria-label for video player
 * @property {boolean} isAutoPlay - sets the video to autoplay
 * @property {string} videoThumbnail - video thumbnail url information
 * @property {string} videoUrl - video url information
 */
export const propTypes =  {
  alt: PropTypes.string,
  damStaticDomainPath: PropTypes.string,
  isCaptionsEnabled: PropTypes.bool,
  name: PropTypes.string,
  videoAccessibility: PropTypes.string,
  isAutoPlay: PropTypes.bool,
  videoThumbnail: PropTypes.string,
  videoUrl: PropTypes.string.isRequired
};

/**
 * Default values for passed properties
 * @type {object}
 * @property {string} alt='' - The default value of the alt is empty string.
 * @property {string} damStaticDomainPath=false - The default value for damStaticDomainPath is empty string.
 * @property {boolean} isCaptionsEnabled=4 - The default value for isCaptionsEnabled is set to false
 * @property {string} name='' - The default value of the name is empty string.
 * @property {string} videoAccessibility='Click here to play the video' - The default value for aria-label is set to text 'Click here to play the video'.
 * @property {boolean} isAutoPlay = false - sets the default value for the video to autoplay
 * @property {string} videoThumbnail='' - The default value of the videoThumbnail is empty string.
 * @property {string} videoUrl='' - video url information
 */
export const defaultProps =  {
  alt: '',
  damStaticDomainPath: '',
  isCaptionsEnabled: false,
  name: '',
  videoAccessibility: 'Click here to play the video',
  isAutoPlay: false,
  videoThumbnail: '',
  videoUrl: ''
};

InlineVideo.propTypes = propTypes;
InlineVideo.defaultProps = defaultProps;

export default InlineVideo;
