import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24Burst( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <g>
        <path id='StarBurst'
          d='M1.25.52a.24.25 0 010-.35c.1-.08.19-.1.29-.04l.04.04 10.19 10.66V.24c0-.13.1-.24.23-.24.12 0 .2.08.23.19v10.65L22.41.17c.1-.1.25-.1.33 0 .08.08.1.2.04.3l-.04.05-10.17 10.66h11.2c.12 0 .23.1.23.25 0 .1-.08.2-.18.23H12.57l10.17 10.68c.1.1.1.25 0 .34-.07.08-.19.1-.29.04l-.03-.04-10.19-10.66v11.74c0 .13-.1.24-.23.24a.23.24 0 01-.23-.2V12.02L1.59 22.68c-.1.1-.25.1-.33 0a.24.25 0 01-.04-.3l.04-.04 10.17-10.68H.23a.23.24 0 01-.23-.23c0-.12.08-.22.18-.24h11.25L1.25.5z'
        />
      </g>
    </SvgContainer>
  );
}

export default Svg24Burst;
