import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16Night( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <path
        d='M7.678 8c0-3.222 2.187-5.992 5.322-7.223-.34-.032-.684-.05-1.035-.05C7.013.727 3 3.983 3 8c0 4.017 4.013 7.273 8.965 7.273.35 0 .696-.018 1.035-.05-3.135-1.23-5.322-4-5.322-7.223h0z'
        stroke='currentColor'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg16Night;
