import './Tag.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '@ulta/core/components/Icon/Icon';
import Text from '@ulta/core/components/Text/Text';

const Tag = props => {
  if( !props?.messageText ){
    return null;
  }

  const { messageType, messageText, compact, iconImage } = props;
  return (
    <span className={
      classNames( 'Tag', {
        [`Tag__container--${messageType}${compact ? '--compact' : ''}`]: messageType,
        [`Tag__container--${messageType}${iconImage ? '--iconImage' : ''}`]: messageType && !compact
      } )
    }
    >
      { compact &&
        <span className={ `Tag__circle Tag__circle--${messageType}` }></span>
      }
      { !compact && iconImage &&
      <Icon className='Tag__container--Icon'
        size='m'
        name={ iconImage }
        aria-hidden={ true }
      />
      }
      <Text
        textStyle='body-3'
        htmlTag='span'
      >
        { messageText }
      </Text>
    </span>
  );
};

export const defaultProps = {
  messageType: 'validate',
  compact: false
};

export const propTypes = {
  /**
   * @property { ( 'validate' | 'notify' | 'valuetag' | 'valuetagWhite' | 'white' | 'urgency' | 'alert' | 'foryou' | 'contextual' | 'error' | 'ContextualTag' ) } messageType - the type of message [validate, notify, valuetag, white, urgency, alert, foryou, contextual, ContextualTag error]
   */
  messageType: PropTypes.oneOf( [
    'validate',
    'notify',
    'valuetag',
    'valuetagWhite',
    'white',
    'urgency',
    'alert',
    'foryou',
    'contextual',
    'error',
    'ContextualTag'
  ] ),
  /**
   * @property { string | object } messageText - Sets the message text content passed as string or object
  */
  messageText: PropTypes.oneOfType( [PropTypes.string, PropTypes.object] ),
  /**
   * @property { bool } compact - the display inline messages
  */
  compact: PropTypes.bool,
  /**
   * @property { string } iconImage - It sets the icon image type
  */
  iconImage: PropTypes.string

};

Tag.propTypes = propTypes;
Tag.defaultProps = defaultProps;

export default Tag;
