import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16HamBurger( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <path d='M 0.667969 14 C 0.296875 14 0 14.296875 0 14.667969 C 0 14.976562 0.210938 15.238281 0.511719 15.3125 L 0.585938 15.328125 L 0.667969 15.332031 L 9.332031 15.332031 C 9.703125 15.332031 10 15.035156 10 14.667969 C 10 14.359375 9.789062 14.09375 9.488281 14.019531 L 9.414062 14.003906 L 9.332031 14 Z M 0.667969 7.332031 C 0.296875 7.332031 0 7.632812 0 8 C 0 8.308594 0.210938 8.574219 0.511719 8.648438 L 0.585938 8.660156 L 0.667969 8.667969 L 12.667969 8.667969 C 13.035156 8.667969 13.332031 8.367188 13.332031 8 C 13.332031 7.691406 13.121094 7.425781 12.824219 7.351562 L 12.746094 7.339844 L 12.667969 7.332031 Z M 0.667969 0.667969 C 0.296875 0.667969 0 0.964844 0 1.332031 C 0 1.640625 0.210938 1.90625 0.511719 1.980469 L 0.585938 1.996094 L 0.667969 2 L 15.332031 2 C 15.703125 2 16 1.703125 16 1.332031 C 16 1.023438 15.789062 0.761719 15.488281 0.6875 L 15.414062 0.671875 L 15.332031 0.667969 Z M 0.667969 0.667969 '
        fill='#000'
        fillRule='nonzero'
      />
    </SvgContainer>
  );
}

export default Svg16HamBurger;
