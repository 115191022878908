import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function SVG12Phone( props ){

  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
      viewBox='0 0 24 24'
      version='1.1'
    >
      <g id='phone'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='Group'
          fill='#000000'
          fillRule='nonzero'
        >
          <g id='-Icon-Color'>
            <path
              d='M2.82754389,1.14690782 L1.48164522,2.49407762 C0.0672410492,3.912677 -0.154910458,6.130265 0.949979323,7.80127464 C4.96745203,13.8484384 10.1502435,19.0316666 16.1955355,23.0485748 C17.8685955,24.1555852 20.0879028,23.9327539 21.5064876,22.5162595 L22.8528859,21.1709046 C24.0489354,19.9743933 24.0489354,18.0353736 22.8531939,16.8391702 L20.3986856,14.3867685 C19.8245683,13.8123699 19.0452649,13.4894522 18.2326572,13.4894522 L18.0302984,13.4961385 C17.291588,13.5450207 16.5928732,13.8602662 16.0662714,14.3871259 L15.733,14.719 L15.5837475,14.5940473 C13.360042,12.7035471 11.2929017,10.6362326 9.40256027,8.41233917 L9.278,8.264 L9.611569,7.93557661 C10.1887868,7.35855587 10.5116633,6.57922066 10.5116633,5.76658786 C10.5116633,4.95395505 10.1887868,4.17461984 9.61409146,3.60010882 L7.15861421,1.14673803 C5.96264658,-0.0489692733 4.0236814,-0.0489692733 2.82754389,1.14690782 Z M6.0982394,2.20768346 L8.553744,4.66108159 C8.84688485,4.95412844 9.01166333,5.35185846 9.01166333,5.76658786 C9.01166333,6.18131725 8.84688485,6.57904727 8.55360146,6.87223657 L7.73382388,7.68399973 C7.45850264,7.95663332 7.43617581,8.39410875 7.68231081,8.69335519 C9.96973577,11.4743647 12.5218027,14.0266467 15.3025788,16.3142652 C15.600744,16.5595522 16.0364119,16.5383975 16.3094098,16.2653767 L17.1270836,15.4476339 C17.4203435,15.1542305 17.8180062,14.9894522 18.2326572,14.9894522 C18.6473083,14.9894522 19.044971,15.1542305 19.3381234,15.4475265 L21.7926591,17.8999556 C22.402557,18.5100894 22.402557,19.4996775 21.7923289,20.1101414 L20.4464242,21.4550029 C19.5323133,22.3677668 18.1019787,22.5113807 17.0244674,21.7984269 C11.1466526,17.8928004 6.10639749,12.8521205 2.20029077,6.97258978 C1.48903382,5.89689805 1.63222474,4.46752013 2.54387489,3.55316598 L3.88839651,2.20737542 C4.49846608,1.59743885 5.4878619,1.59743885 6.0982394,2.20768346 Z'
            />
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}

export default SVG12Phone;
