import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16Clock( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <g fill='none'
        fillRule='evenodd'
        stroke='currentColor'
      >
        <circle cx={ 8 }
          cy={ 8 }
          r={ 7.5 }
        />
        <path strokeLinecap='round'
          strokeLinejoin='round'
          d='M8 8l2-4'
        />
      </g>
    </SvgContainer>
  );
}

export default Svg16Clock;
