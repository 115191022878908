import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';


function SVG( props ){

  return (
    <SvgContainer
      { ...props }
      width={ 50 }
      height={ 50 }
      viewBox='0 0 178 106'
    >
      <g>
        <g>
          <rect
            style={
              {
                fill:'#f5f5f5'
              }
            }
            width='31.667'
            height='19.792'
            rx='5'
            ry='5'
          />
          <path
            style={
              {
                fill:'#636566'
              }
            }
            d='M80.24,63.56c0-1.32,0-2.23-.11-3.27.28,0,2.6,0,3.32,0,1.53,0,2.57.55,2.57,1.83S84.74,64,84,64.07v0c.77,0,2.28.56,2.28,2,0,1.69-1.45,2.37-3,2.37-.67,0-2.87,0-3.15,0,.08-1,.11-2,.11-3.27ZM82.79,61a3.27,3.27,0,0,0-.62,0c0,.58-.05,1.24-.05,1.64s0,.8,0,1.2h.57a1.41,1.41,0,0,0,1.45-1.5A1.24,1.24,0,0,0,82.79,61Zm-.11,3.56h-.54c0,.33,0,.66,0,1.14,0,.74,0,1.66,0,2.07l.7,0a1.44,1.44,0,0,0,1.39-1.6C84.23,65.06,83.69,64.52,82.67,64.52Z'
          />
          <path
            style={
              {
                fill:'#636566'
              }
            }
            d='M98.81,63.56c0-1.32,0-2.23-.1-3.27.74,0,4.57,0,4.91,0a6.11,6.11,0,0,0,0,1c-1-.06-2.57-.06-2.94-.06-.06.86,0,1.63,0,2.49,1.11,0,1.92,0,2.88-.09a4,4,0,0,0,0,1c-1-.07-1.91-.11-2.88-.11,0,.5,0,1,0,1.51s0,.91,0,1.38a26.5,26.5,0,0,0,2.91-.06,6.26,6.26,0,0,0,0,1c-.46,0-4.63,0-4.91,0,.07-1,.1-2,.1-3.27Z'
          />
          <path
            style={
              {
                fill:'#636566'
              }
            }
            d='M123.2,68.44c-.41,0-1.84,0-2.2,0-.26-.8-.56-1.62-.87-2.42-.55,0-2.5,0-2.95,0a21.22,21.22,0,0,0-.86,2.42,9.88,9.88,0,0,0-1.23,0c.62-1.31,2.52-5.52,3.61-8.21a8,8,0,0,0,1,0C120.75,62.92,122.24,66.52,123.2,68.44Zm-3.37-3.12c-.37-1-.73-1.89-1.13-2.81-.43.92-.82,1.85-1.19,2.81Z'
          />
          <path
            style={
              {
                fill:'#636566'
              }
            }
            d='M133.25,65.49c0-1.28,0-1.91,0-2.77,0-1.25,0-2-.05-2.43.34,0,.7.06,1,.06s.7,0,1-.06a37.41,37.41,0,0,0-.17,4.14c0,2.88.89,3.35,2.54,3.35,2,0,2.17-2,2.17-4.24,0-1.09,0-2.16-.1-3.25a4.43,4.43,0,0,0,.65.06,4.37,4.37,0,0,0,.63-.06c-.08.7-.17,2.38-.17,4.43,0,2.74-1.28,3.88-3.74,3.88C134.94,68.6,133.25,67.71,133.25,65.49Z'
          />
          <path
            style={
              {
                fill:'#636566'
              }
            }
            d='M154.2,62.22a7,7,0,0,0,0-1c-.49,0-1.58,0-2.34.1a4.83,4.83,0,0,0,0-1c1.15,0,5.44,0,6.52,0a4.64,4.64,0,0,0,0,1c-.69-.08-1.77-.08-2.28-.08,0,.17,0,.53,0,1v3c0,1.32,0,2.23.1,3.27-.28,0-1.8,0-2.08,0,.08-1,.1-2,.1-3.27Z'
          />
          <path
            style={
              {
                fill:'#636566'
              }
            }
            d='M173.62,63.83a23,23,0,0,0,1.92-3.54,10.07,10.07,0,0,0,1.19,0c-.55.88-1.85,3.14-2.66,4.63,0,.38,0,.67,0,1.05a18.08,18.08,0,0,0,.13,2.47c-.36,0-1.8,0-2.16,0,0-.76.15-1.55.15-2.28,0-.37,0-.73,0-1.1-.87-1.57-2.32-4.1-2.71-4.77.38,0,1.89,0,2.27,0,.58,1.16,1.54,3,1.89,3.54Z'
          />
          <path
            style={
              {
                fill:'#636566'
              }
            }
            d='M48.16,21.41c-.18,4.43-.18,10-.18,15,0,9.28.12,11.38,1.86,13.65s5,3.55,9.2,3.55c4.87,0,8.72-2.45,10-6,.48-1.28.78-3.09.78-7.87,0-7.59-.06-15.22-.54-22.8a45.4,45.4,0,0,0,5.53,0l-.18,2.79c-.3,5-.48,17-.6,22.92-.12,6.88-2.16,10-6.13,12.48a20.38,20.38,0,0,1-10.1,2.51c-7.21,0-15.87-2.22-17.19-11.26a28.83,28.83,0,0,1-.12-3.09V27.47c0-3-.12-7.52-.3-10.49a64.34,64.34,0,0,0,8.12,0Z'
          />
          <path
            style={
              {
                fill:'#636566'
              }
            }
            d='M83.87,33c0-2.55,0-3.13-.18-8.3-.06-1.22-.18-6.45-.24-7.55a45,45,0,0,0,8.35,0c-.42,8.76-.6,16.08-.6,25,0,4.06.12,7.84.18,11h1.08c1.74,0,10.4,0,13.94-.53a15.12,15.12,0,0,0,0,4.3c-3.06-.12-8.23-.35-11.48-.35-3.85,0-7.09.12-11.48.35,0-.93.42-14.4.42-15.86Z'
          />
          <path
            style={
              {
                fill:'#636566'
              }
            }
            d='M123.19,49a60.19,60.19,0,0,0,.42,8,58.09,58.09,0,0,0-8.48,0c.24-4.41.6-8.89.6-13.3l-.06-16.2c0-2.15-.06-4.18-.12-6.45h-3c-.54,0-6.31.12-9.38.41a19.83,19.83,0,0,0,.13-4.64c4.75.29,11.29.29,15.92.29,5,0,10.35-.09,16.12-.38a20.63,20.63,0,0,0-.06,4.74c-3.54-.35-7.82-.41-12-.41-.06,2.09-.12,4.3-.12,6.51Z'
          />
          <path
            style={
              {
                fill:'#636566'
              }
            }
            d='M158.67,50.6l-2.51-6.13c-2.58-.11-12.46-.11-14.85,0A123.6,123.6,0,0,0,136.5,57a43.47,43.47,0,0,0-5.57,0l7-14.94c6-13.08,9.51-20.6,10.95-25.11h3.91c2.88,7.69,6.47,15.8,8.62,20.66,2.27,5,6.23,13.77,8.92,19.39-.9-.06-8-.06-9.1,0Zm-9.88-24-6.11,14.24c2.1.06,4.14.16,6.24.16,1.18,0,3,0,5.51-.16Z'
          />
          <path
            style={
              {
                fill:'#636566'
              }
            }
            d='M31.61,18.77c-9.37,6.35-14.87,14.34-14.87,24.09,0,22.15,26.75,38.52,64.88,38.52a91.65,91.65,0,0,0,41.95-9.87h-.66A133.75,133.75,0,0,1,84,76.95c-38.33,0-64.72-18-64.76-34.62,0-9.22,4.27-17.18,12.41-23Z'
          />
          <path
            style={
              {
                fill:'#636566'
              }
            }
            d='M165.59,16.94a2.39,2.39,0,1,1-2.41,2.38A2.38,2.38,0,0,1,165.59,16.94Zm0,4.43a2,2,0,1,0-2-2.05A2,2,0,0,0,165.59,21.37Zm-.94-3.44h1.08c.67,0,1,.26,1,.8a.72.72,0,0,1-.74.76l.8,1.24h-.47l-.76-1.2h-.46v1.2h-.44Zm.44,1.24h.45c.38,0,.74,0,.74-.46s-.32-.43-.61-.43h-.58Z'
          />
          <path
            style={
              {
                fill:'#ed2026'
              }
            }
            d='M156.14,84.26a12.07,12.07,0,1,0,0,15.94c-1.87-2.13,13.48-4.91,13.48-8s-15.36-5.84-13.48-8'
          />
          <path
            style={
              {
                fill:'#fba81d'
              }
            }
            d='M165.19,80.15a12,12,0,0,0-9.05,4.1,12.23,12.23,0,0,0-1.38,1.93h2.76a12.1,12.1,0,0,1,1.05,2.41h-4.88a12,12,0,0,0-.51,2.41h5.89a11.57,11.57,0,0,1,.08,1.21c0,.41,0,.81-.06,1.21h-5.91a12,12,0,0,0,.51,2.41h4.9a12.13,12.13,0,0,1-1.07,2.41h-2.76a12.28,12.28,0,0,0,1.38,1.93,12.07,12.07,0,1,0,9.06-20'
          />
        </g>
      </g>
    </SvgContainer>
  );

}

export default SVG;




