
import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function SVG( props ){

  return (
    <SvgContainer
      { ...props }
      viewBox='0 0 194 121'
    >
      <g>
        <g>
          <rect
            style={ { fill: '#62abbc' } }
            width='194'
            height='121'
            rx='5'
            ry='5'
          />
          <path
            style={ { fill: '#fff',
              fillRule: 'evenodd' } }
            d='M86.58,45,78.69,27.28H68.61V52.7L57.22,27.28H48.6L37.2,53.72h6.87l2.34-6h13l2.48,6H75V34l8.62,19.72h6l8.77-19.28V53.72h6.43V27.28H94.47ZM53,42H49L53,32.83v.29L56.92,42Z'
          />
          <path
            style={ { fill: '#fff'
            } }
            d='M145.31,40.06l4.67-5,6.82-7.74h-8.13l-7.31,8-7.3-8H108.49V53.5h24.83l7.74-8.62,7.6,8.76h8.13l-6.82-8Zm-15.48,8H115.07V42.84h14.32v-5H115.07V33h14.75l7.3,7Z'
          />

          <rect
            style={ { fill: '#fff' } }
            x='139.99'
            y='72.72'
            width='34.01'
            height='18.32'

          />
          <ellipse
            style={ {
              fill: 'none',
              stroke: '#cb2026',
              strokeMiterlimit: 10,
              strokeWidth: '4px'
            } }
            cx='157.97'
            cy='82.12'
            rx='32.68'
            ry='17.67'
          />
        </g>
      </g>
    </SvgContainer>
  );
}

export default SVG;
