/**
 * The WebpageMetaData is used to set the SEO meta data for a page requeston the client.  This contains things such as meta robots tags, canonical links, and other meta attrbitues that are passed from the components configuration
 *
 * @module views/components/WebpageMetaData
 * @memberof -Common
 */
import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * Represents a WebpageMetaData component
 *
 * @method
 * @param {WebpageMetaDataProps} props - React properties passed from composition
 * @returns WebpageMetaData
 */
export const WebpageMetaData = React.forwardRef( ( props, _ ) =>{

  const {
    metaTitle,
    metaDescription,
    canonical,
    robots,
    openGraphTitle,
    openGraphDescription,
    openGraphImage
  } = props;

  return (
    <Helmet>
      { metaTitle &&
        <title>
          { metaTitle }
        </title>
      }
      { metaDescription &&
        <meta
          name='description'
          content={ metaDescription }
        />
      }
      { canonical &&
        <link
          rel='canonical'
          href={ canonical }
        />
      }
      { robots &&
        <meta
          name='robots'
          content={ robots }
        />
      }
      { openGraphTitle &&
        <meta
          property='og:title'
          content={ openGraphTitle }
        />
      }
      { openGraphDescription &&
        <meta
          property='og:description'
          content={ openGraphDescription }
        />
      }
      { openGraphImage &&
        <meta
          property='og:image'
          content={ openGraphImage }
        />
      }
    </Helmet>
  );
} );

/**
 * Property type definitions
 * @typedef WebpageMetaDataProps
 * @type {object}
 */
export const propTypes =  {
};

/**
 * Default values for passed properties
 * @type object
 */
export const defaultProps =  {
};

WebpageMetaData.propTypes = propTypes;
WebpageMetaData.defaultProps = defaultProps;
WebpageMetaData.displayName = 'WebpageMetaData';

export default WebpageMetaData;
