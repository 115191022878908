import * as utils from './oneTrust';

/**
 * The contains the logic to render OneTrust scripts
 *
 * @module utils/oneTrust/oneTrust
 * @method
 * @param { Object } data - containing OneTrust script, domain and enable flag
 * @param { Object } methods - reserved methods object for extension
 * @returns string
**/
export const oneTrust = ( data ) => {
  const { script, domain, enabled } = data || {};
  if( enabled === 'true' && domain && script ){
    return `
    <!-- OneTrust Cookies Consent Notice start -->
    <script
      src="${ script }"
      data-document-language="true"
      type="text/javascript"
      charset="UTF-8"
      data-domain-script="${ domain }" >
    </script>
    <script type="text/javascript">
      function OptanonWrapper() { }
    </script>
    <!-- OneTrust Cookies Consent Notice end -->
    `;
  }
  else {
    return '';
  }
};

/**
 * Group Ids and definitions from OneTrust
 * https://uat.onetrust.com/cookies/categorizations?tab=Categories
 * @type object
 * @property { string } Advertising - Advertising cookies are used to provide you with a personalized experience. These cookies may be set by our advertising partners to build a profile of your interests.
 * @property { string } Required - Required cookies are used when saving your settings and preferences, when you log in and out of your account, and for other basic site functions. These cookies cannot be disabled.
 * @property { string } Functional - These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us or by third party providers whose services we have added to our pages.
 * @property { string } Targeting - These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites.
 * @property { string } SocialMedia - These cookies are set by a range of social media services that we have added to the site to enable you to share our content with your friends and networks. They are capable of tracking your browser across other sites and building up a profile of your interests. This may impact the content and messages you see on other websites you visit.
 * @property { string } Performance - These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site.
 */
export const groupTypes = {
  'Advertising': 'C0001',
  'Required': 'C0002',
  'Functional': 'C0003',
  'Targeting': 'C0004',
  'SocialMedia': 'C0005',
  'Performance': 'C0007'
};

/**
 * Represents a OneTrust Group Validation method

 * @method
 * @param {object} data - React properties passed from composition
 * @returns boolean
 */
export const isValidOneTrustGroup = ( data ) => {
  const {
    groupName,
    groupTypes = utils.groupTypes
  } = data || {};
  return ( !groupName ) ? false : Object.keys( groupTypes ).includes( groupName );
};

/**
 * Represents a OneTrust Class
 * https://my.onetrust.com/s/article/UUID-730ad441-6c4d-7877-7f85-36f1e801e8ca
 * @method
 * @param {object} data - React properties passed from composition
 * @returns string
 */
export const setOneTrustClass = ( data, methods ) => {
  const {
    groupName,
    groupTypes = utils.groupTypes
  } = data || {};
  const {
    isValidOneTrustGroup = utils.isValidOneTrustGroup
  } = methods || {};
  if( isValidOneTrustGroup( data ) && groupTypes[ groupName ] !== groupTypes.Required ){
    return `optanon-category-${ groupTypes[ groupName ] }`;
  }
  else {
    return '';
  }
};

/**
 * Represents a OneTrust data type for script tags
 * https://my.onetrust.com/s/article/UUID-730ad441-6c4d-7877-7f85-36f1e801e8ca
 * @method
 * @param {object} data - React properties passed from composition
 * @returns string
 */
export const setOneTrustType = ( data, methods ) => {
  const {
    type,
    groupName,
    groupTypes = utils.groupTypes
  } = data || {};
  const {
    isValidOneTrustGroup = utils.isValidOneTrustGroup
  } = methods || {};

  if( isValidOneTrustGroup( data ) && groupTypes[ groupName ] !== groupTypes.Required ){
    return 'text/plain';
  }
  else if( type ){
    return type;
  }
  else {
    return '';
  }
};
