import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16Bookmark( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <path
        stroke='currentColor'
        d='M2 .5h12v15l-6-5-6 5z'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg16Bookmark;
