import superagent from 'superagent';
import sgql from 'superagent-graphql';

import { getApolloState, isValidPath } from '@ulta/core/utils/apollo_client/apollo_client';
import * as utils from '@ulta/core/utils/apolloMiddleware/fetchHeaderFooter/fetchHeaderFooter' ;
import { hasItems } from '@ulta/core/utils/array/array';
import constants from '@ulta/core/utils/constants/constants';
import { isServer } from '@ulta/core/utils/device_detection/device_detection';
import { devLogger, LOG_TOPIC } from '@ulta/core/utils/devMode/devMode';
import CMS_QUERY from '@ulta/core/utils/graphql/queries/cms/cms';
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';
import { decorateParams } from '@ulta/core/utils/handleLocation/handleLocation';


const {
  TOPBAR_CONFIG_KEYS,
  HEADER_CACHE_KEYS,
  FOOTER_CONFIG_KEYS,
  FOOTER_CACHE_KEYS,
  ENABLE_FOOTER_OPTIMIZATION,
  ENABLE_HEADER_OPTIMIZATION,
  CACHE_DEFAULT_LONG_TTL
} = process.env;

/**
 * fetchHeaderFooter an async method to fetch Header and Footer modules from dxl
 * @see{@link https://www.apollographql.com/docs/react/networking/advanced-http-networking/#modifying-response-data|apollo - advanced networking}
 *
 * @param { method } response - provided by the asyncMap method from apollo
 * @param { method } methods - passed by closure from the hfnMiddleware
 * @param { method } operation - provided by ApolloLink
 * @returns ApolloLink
 */
export const fetchHeaderFooter = async( response, methods, data, operation ) => {
  const { getCacheTimeout, updateCacheTimeout, setHFN, getHFN, getCachedHFN = fetchHFN } = methods || {};
  const { isStaging, origin, config, locale } = handleEmptyObjects( data );

  const { enableGlobalHFNForDSOTF } = operation?.getContext() || {};

  const isValidPage = isValidPath( operation?.variables?.url );

  if( ( isValidPage || isStaging ) && enableGlobalHFNForDSOTF ){
    await getCachedHFN(
      { timestamp: Date.now(), timeout: getCacheTimeout(), operation, isStaging, origin, config, locale },
      { setHFN, getHFN, updateCacheTimeout }
    );
  }
  return response;
};

export let requestingHFN = false;

export const fetchHFN = async( data, methods ) => {
  const { timestamp, timeout, operation, isStaging, origin, config, locale } = handleEmptyObjects( data );

  const { setHFN, getHFN, updateCacheTimeout, updateHFN = updateHFNcache } = methods || {};

  const { stagingHost, previewDate } = operation.variables?.moduleParams || {};

  const { header, footer, footerSimple } = getHFN() || {};

  const modules  = getApolloState()?.Page?.content?.modules || [];

  const isPreviewMode = !!stagingHost || !!previewDate;
  const isMissingHFN = !header || !footer || !footerSimple;

  // When on the server, we should only make the request if the header or footer is missing
  const shouldMakeServerRequest = isServer() && ( isPreviewMode || isMissingHFN );
  const hasClientModules =
    modules?.[0]?.moduleName === constants.HFN.TopBarModuleName &&
    modules?.[2]?.moduleName === constants.HFN.FooterModuleName;

  // When on the client, as a fallback we should make the request if the header or footer is missing
  const shouldMakeClientRequest = !isServer() && !hasClientModules;

  // See apps/www/utils/cache for how we manage the HFN cache - this is a fallback and should never happen
  if( !shouldMakeClientRequest && !shouldMakeServerRequest ){
    return;
  }

  devLogger(
    `fetchHFN - calling for a new HFN [ isStaging:${isStaging}, header:${!!header}, footer:${!!footer}, footerSimple:${!!footerSimple}, timeout:${timeout}:${timestamp} ) } ]`,
    0,
    LOG_TOPIC.Cache
  );

  await updateHFN( { stagingHost, previewDate, operation, origin, config, locale }, { setHFN, updateCacheTimeout } );
};

export const buildHFNArgs = ( data ) => {
  const { path, stagingHost, previewDate, moduleParams, origin } = data || {};
  return {
    url: {
      path: decorateParams( { url: `${origin}${path}`, stagingHost, previewDate } )
    },
    moduleParams: {
      date: new Date(),
      ...( moduleParams && { ...moduleParams } )
    }
  };
};

export const superAgentGQLFetch = async( data, methods ) => {
  const { query, variables = {}, config = {}, locale = 'en-US', previewDate, stagingHost, storageKey, graphModuleName } = data || {};
  const { setHFN } = methods || {};
  let graphEndpoint = `${config.domain}/${config.uri}`;

  let response = await superagent
    .post( graphEndpoint )
    .set( {
      'X-ULTA-CLIENT-COUNTRY': 'US',
      'X-ULTA-CLIENT-LOCALE': locale,
      'X-ULTA-CLIENT-CHANNEL': 'web',
      ...( !!previewDate && { 'X-ULTA-CLIENT-PREVIEWDATETIME': previewDate } ),
      ...( !!stagingHost && { 'X-ULTA-CLIENT-STAGINGHOST': stagingHost } ),
      'X-FORWARDED-PROTO': 'https',
      'X-ULTA-GRAPH-MODULE-NAME': graphModuleName,
      'X-ULTA-GRAPH-SUB-TYPE': 'page',
      'X-ULTA-GRAPH-PAGE-URL': variables.url?.path
    } )
    .use( sgql( query, variables ) )
    .catch( ( e ) => {
      // eslint-disable-next-line no-console
      console.log( 'superAgentGQLFetch failed to fetch', e );
    } );

  if( response?.statusCode === 200 && response.body?.data?.Page?.content?.modules?.length > 0 ){
    return setHFN( { [storageKey]: response.body.data.Page.content.modules } );
  }
  else {
    // eslint-disable-next-line no-console
    console.log( '!!HFN cache request ERROR!!' );
    return false;
  }
};

/**
 * updateHFNcache used to update the cache value for header and footer
 *  @method updateHFNcache
 * @param { object } data - contains stagingHost, previewDate
 * @param { object } methods - contains setHFN, updateCacheTimeout
 */

export const updateHFNcache = async( data, methods ) => {
  if( requestingHFN ){
    return;
  }

  const HFN = [];
  const { stagingHost, previewDate, origin, config, locale } = data || {};
  const { setHFN, updateCacheTimeout } = methods || {};

  const headerVariables = utils.buildHFNArgs( { path: constants.HFN.header, stagingHost, previewDate, origin } );
  const footerVariables = utils.buildHFNArgs( { path: constants.HFN.footer, stagingHost, previewDate, origin } );
  const simplifieFooterVariables = utils.buildHFNArgs( {
    path: constants.HFN.footer,
    stagingHost,
    previewDate,
    origin,
    moduleParams: { options: { displaySimplifiedFooter: true } }
  } );

  requestingHFN = true;

  HFN.push(
    utils.superAgentGQLFetch(
      {
        query: CMS_QUERY,
        config,
        variables: headerVariables,
        stagingHost,
        previewDate,
        locale,
        storageKey: 'header',
        graphModuleName: 'TopBar'
      },
      { setHFN }
    )
  );
  HFN.push(
    utils.superAgentGQLFetch(
      {
        query: CMS_QUERY,
        config,
        variables: footerVariables,
        stagingHost,
        previewDate,
        locale,
        storageKey: 'footer',
        graphModuleName: 'Footer'
      },
      { setHFN }
    )
  );
  HFN.push(
    utils.superAgentGQLFetch(
      {
        query: CMS_QUERY,
        config,
        variables: simplifieFooterVariables,
        stagingHost,
        previewDate,
        locale,
        storageKey: 'footerSimple',
        graphModuleName: 'FooterSimple'
      },
      { setHFN }
    )
  );
  await Promise.allSettled( HFN );
  updateCacheTimeout && updateCacheTimeout();

  requestingHFN = false;

  return true;
};

/**
 * @method generateCacheKeysForHeader
 * @summary This method holds the logic to create the cache keys for the footer
 * @description We loop through the configured keys and retrieve the value from prop`s
 * and build a concatenated string with the key value pair of strings
 * and use them as the cache keys
 * @param  { Object } props
 * @returns { Object } cacheKeyValue
 */
export const generateCacheKeysForHeader = ( props ) => {
  const cacheKeyValue = {};
  let cacheKey = '';

  if( typeof props !== 'object' || props === null ){
    return;
  }

  TOPBAR_CONFIG_KEYS?.split( ',' ).forEach( ( key ) => {
    cacheKey = `${cacheKey}-${key}-${props[key]}`;
  } );

  HEADER_CACHE_KEYS?.split( ',' ).forEach( ( headerCacheKey ) => {
    cacheKeyValue[headerCacheKey] = `${headerCacheKey}-${cacheKey}`;
  } );

  return cacheKeyValue;
};

/**
 * @method generateCacheKeysForFooter
 * @summary This method holds the logic to create the cache keys for the footer
 * @description We loop through the configured keys and retrieve the value from props
 * and build a concatenated string with the key value pair of strings
 * and use them as the cache keys
 * @param  { Object } props
 * @returns { Object } cacheKeyValue
 */
export const generateCacheKeysForFooter = ( props ) => {
  const cacheKeyValue = {};
  let cacheKey = '';

  if( typeof props !== 'object' || props === null ){
    return;
  }

  FOOTER_CONFIG_KEYS?.split( ',' ).forEach( ( key ) => {
    cacheKey = `${cacheKey}-${key}-${props[key]}`;
  } );

  FOOTER_CACHE_KEYS?.split( ',' ).forEach( ( footerCacheKey ) => {
    cacheKeyValue[footerCacheKey] = `${footerCacheKey}-${cacheKey}`;
  } );

  return cacheKeyValue;
};

/**
 * @method processHeaderAndFooterInAppResponse
 * @summary This method holds the logic to process the app with the cached headers
 * @description This takes the processed app and checks if it contains header or footer
 * if the footer is present and if it is cacheable then sets it in the cache,
 * if it is not present and cacheable then retrieves the header and footer from cache and
 * appends to the app
 * @param  {Object} context
 * @param  {Object} app
 * @returns {Object} containing header, footer, appResponse
 */
export const processHeaderAndFooterInAppResponse = async ( data, methods ) => {
  const { context: { customContextData = {} } = {}, app, cacheExpiryTime } = handleEmptyObjects( data );
  const { getCacheEntry, setCacheEntry } = handleEmptyObjects( methods );
  const { headerCacheKeyValue, isHeaderCacheable, footerCacheKeyValue, isFooterCacheable } = customContextData;
  global.footer = global.footer || {};
  global.header = global.header || {};
   
  if( !app ){
    return {};
  }

  let appResponse = app;
  let header = appResponse.split( '<header' )?.[1]?.split( '</header>' )?.[0];
  let footer = appResponse.split( '<footer' )?.[1]?.split( '</footer>' )?.[0];

  header = header ? `<header${header}</header>` : header;
  footer = footer ? `<footer${footer}</footer>` : footer;

  if( isHeaderCacheable && headerCacheKeyValue?.TopBar ){
    if( header ){
      setCacheEntry( { key: headerCacheKeyValue.TopBar, value: header, ttl: CACHE_DEFAULT_LONG_TTL } );
      global.header[ headerCacheKeyValue.TopBar ] = header;
    }
    else{
      header = await getCacheEntry( { key: headerCacheKeyValue.TopBar } );
      global.header[ headerCacheKeyValue.TopBar ] = header;
      if (header) {
        appResponse = appResponse?.replace( '<main', `${header}<main` );
      }
    }
  }

  if( isFooterCacheable && footerCacheKeyValue?.Footer ){
    if( footer ){
      setCacheEntry( { key: footerCacheKeyValue.Footer, value: footer, ttl: CACHE_DEFAULT_LONG_TTL } );
      global.footer[ footerCacheKeyValue.Footer ] = footer;
    }
    else {
      footer = await getCacheEntry( { key: footerCacheKeyValue.Footer } );
      global.footer[ footerCacheKeyValue.Footer ] = footer;
      if (footer) {
        appResponse = appResponse.replace( '</main>', `</main>${footer}` );
      }
    }
  }

  return { header, footer, appResponse };
};

/**
 * @method processAppAndCollectIncludedTags
 * @summary This method holds the logic to retrieve and collect the tags from extractor (scripts , css includes and preload)
 * @description This takes the extractor, header , footer, context and then retrieves the tags, if the header and footer are
 * not null and they are cacheable then retrieve the cache keys and set the tags in the global object
 * if the header and footer are not present and available in the cache against the cacheKey then retrieve the tags and pass it
 * @param  {Object} extractor
 * @param  {String} header
 * @param  {String} footer
 * @param  {Object} context
 * @returns {Object} { styleTags ,scriptTags, scriptTags }
 */
export const processAppAndCollectIncludedTags = ( data ) => {
  const { extractor, header, footer, context: { customContextData = {} } = {} } = handleEmptyObjects( data );
  const {
    headerCacheKeyValue,
    isHeaderCacheable,
    isHeaderPresentInRequest,
    footerCacheKeyValue,
    isFooterCacheable,
    isFooterPresentInRequest
  } = customContextData;

  if( !extractor?.getStyleTags ){
    return {};
  }

  let styleTags = extractor.getStyleTags();
  let scriptTags = extractor.getScriptTags();
  let linkTags = extractor.getLinkTags();

  const hasHeader = !!header?.length;
  const hasFooter = !!footer?.length;

  if( hasHeader || hasFooter ){
    const styleTagsArray = extractor.getStyleTags().split( '\n' );
    const scriptTagsArray = extractor.getScriptTags().split( '\n' );
    const linksArray = extractor.getLinkTags().split( '\n' );
    const headerStyleTags = [];
    const headerScriptTags = [];
    const headerLinks = [];

    const footerStyleTags = [];
    const footerScriptTags = [];
    const footerLinks = [];

    for ( let i = 0; i < styleTagsArray.length; i++ ){
      const chunkName = styleTagsArray[i].split( 'data-chunk=\"ulta-modules-' )?.[1]?.split( '-' )?.[0];
      if( !chunkName?.length ){
        continue;
      }

      if( header?.includes( chunkName ) ){
        headerStyleTags.push( styleTagsArray[i] );
      }

      if( footer?.includes( chunkName ) ){
        footerStyleTags.push( styleTagsArray[i] );
      }
    }

    for ( let i = 0; i < scriptTagsArray.length; i++ ){
      const chunkName = scriptTagsArray[i].split( 'data-chunk=\"ulta-modules-' )?.[1]?.split( '-' )?.[0];
      if( !chunkName?.length ){
        continue;
      }

      if( header?.includes( chunkName ) ){
        headerScriptTags.push( scriptTagsArray[i] );
      }

      if( footer?.includes( chunkName ) ){
        footerScriptTags.push( scriptTagsArray[i] );
      }
    }

    for ( let i = 0; i < linksArray.length; i++ ){
      const chunkName = linksArray[i].split( 'data-chunk=\"ulta-modules-' )?.[1]?.split( '-' )?.[0];
      if( !chunkName?.length ){
        continue;
      }

      if( header?.includes( chunkName ) ){
        headerLinks.push( linksArray[i] );
      }

      if( footer?.includes( chunkName ) ){
        footerLinks.push( linksArray[i] );
      }
    }

    if( headerStyleTags?.length > 0 ){
      global.header[headerCacheKeyValue?.StyleTags] = headerStyleTags.join( '\n' );
    }

    if( headerLinks?.length > 0 ){
      global.header[headerCacheKeyValue?.LinkTags] = headerLinks.join( '\n' );
    }

    if( headerScriptTags?.length > 0 ){
      global.header[headerCacheKeyValue?.ScriptTags] = headerScriptTags.join( '\n' );
    }

    if( footerStyleTags?.length > 0 ){
      global.footer[footerCacheKeyValue?.StyleTags] = footerStyleTags.join( '\n' );
    }

    if( footerLinks?.length > 0 ){
      global.footer[footerCacheKeyValue?.LinkTags] = footerLinks.join( '\n' );
    }

    if( footerScriptTags?.length > 0 ){
      global.footer[footerCacheKeyValue?.ScriptTags] = footerScriptTags.join( '\n' );
    }
  }

  if( isHeaderPresentInRequest && !hasHeader && isHeaderCacheable && global.header[headerCacheKeyValue?.TopBar] ){
    styleTags = styleTags + '\n' + global.header[headerCacheKeyValue?.StyleTags];
    scriptTags = scriptTags + '\n' + global.header[headerCacheKeyValue?.ScriptTags];
    linkTags = linkTags + '\n' + global.header[headerCacheKeyValue?.LinkTags];
  }

  if( isFooterPresentInRequest && !hasFooter && isFooterCacheable && global.footer[footerCacheKeyValue?.Footer] ){
    styleTags = styleTags + '\n' + global.footer[footerCacheKeyValue?.StyleTags];
    scriptTags = scriptTags + '\n' + global.footer[footerCacheKeyValue?.ScriptTags];
    linkTags = linkTags + '\n' + global.footer[footerCacheKeyValue?.LinkTags];
  }

  return { scriptTags, styleTags, linkTags };
};

export const MODULE_CHUNK_NAMES = {
  header: 'ulta-modules-TopBar-TopBar-js',
  footer: 'ulta-modules-Footer-Footer-js'
};

/**
 * @method processPageModules
 * @summary This method holds the logic to adjust modules based on the availability header and footer from cache
 * @description This checks if the request is a page request and check if the header and footer is available in
 * cache against the corresponding cache keys if it is available in the cache then removes the modules from rendering process
 * if it is not available in cache then allows the modules to be rendered
 * it also set the corresponding keys in the request context
 * it returns the adjusted modules
 * @param  { Object } serverRequestContext
 * @param  { Object } props
 * @param  { timestamp } cacheExpiryTime
 * @param  { Boolean } isMobile
 * @returns { Object } pageModules
 */
export const processPageModules = ( data ) => {
  const { serverRequestContext = {}, props = {}, isMobile } = handleEmptyObjects( data );

  let pageModules = props.modules || [];
  const context = serverRequestContext;
  context.customContextData = context.customContextData || {};
  const webConfig = props.web || {};
  
  if( !hasItems( pageModules ) ){
    return pageModules;
  }

  const headerModule =
    pageModules?.[0]?.moduleName === constants.HFN.TopBarModuleName &&
    pageModules?.[1]?.moduleName === constants.HFN.MainWrapperModuleName;

  const footerModule =
    pageModules?.[2]?.moduleName === constants.HFN.FooterModuleName &&
    pageModules?.[1]?.moduleName === constants.HFN.MainWrapperModuleName;

  const isHeaderPresentInRequest = headerModule !== null;
  const isFooterPresentInRequest = footerModule !== null;

  if( isHeaderPresentInRequest ){
    const topBarConfig = { ...headerModule, ...webConfig, isMobile };
    const cacheKeyValue = generateCacheKeysForHeader( topBarConfig );
    const isHeaderCacheable =
      ENABLE_HEADER_OPTIMIZATION === 'true' &&
      !context.customContextData?.isNative &&
      !( props.meta?.preview?.date || props.meta?.preview?.host );

    if( isHeaderCacheable && global?.header?.[cacheKeyValue?.TopBar] ){
      /*
        * This check is to make sure that when the cache is expired only one call per cache key
        * is allowed to be refetched / re-rendered, once that is re-rendered then the cache
        * will be updated with the latest cached value.
        */
      
        pageModules = pageModules?.filter( ( item ) => item.moduleName !== constants.HFN.TopBarModuleName );
      
    }

    context.customContextData['headerCacheKeyValue'] = cacheKeyValue;
    context.customContextData['isHeaderCacheable'] = isHeaderCacheable;
    context.customContextData['isHeaderPresentInRequest'] = isHeaderPresentInRequest;
  }

  if( isFooterPresentInRequest ){
    const footerConfig = { ...footerModule, ...webConfig, isMobile };
    const cacheKeyValue = generateCacheKeysForFooter( footerConfig );
    const isFooterCacheable =
      ENABLE_FOOTER_OPTIMIZATION === 'true' &&
      !context.customContextData?.isNative &&
      !( props.meta?.preview?.date || props.meta?.preview?.host );
    
    if( isFooterCacheable && global?.footer?.[cacheKeyValue?.Footer] ){
      /*
        * This check is to make sure that when the cache is expired only one call per cache key
        * is allowed to be refetched / re-rendered, once that is re-rendered then the cache
        * will be updated with the latest cached value.
        */
      
        pageModules = pageModules?.filter( ( item ) => item.moduleName !== constants.HFN.FooterModuleName );
      
    }

    context.customContextData['footerCacheKeyValue'] = cacheKeyValue;
    context.customContextData['isFooterCacheable'] = isFooterCacheable;
    context.customContextData['isFooterPresentInRequest'] = isFooterPresentInRequest;
  }

  return pageModules;
};