import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16ZoomOut( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <g fill='none'
        fillRule='evenodd'
        stroke='currentColor'
      >
        <path strokeLinecap='round'
          strokeLinejoin='round'
          d='M3 5.5h5'
        />
        <g fillRule='nonzero'>
          <circle cx={ 5.5 }
            cy={ 5.5 }
            r={ 5 }
          />
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M15 15.5L9.24 9.25'
          />
        </g>
      </g>
    </SvgContainer>
  );
}

export default Svg16ZoomOut;
