/**
 * This is a SuggestionItem Component, which is used to display a single suggestion in the search list. This component incorporates an icon and the Link and Text components.
 *
 * @module views/Molecules/SuggestionItem
 * @memberof -Common
 */
import './SuggestionItem.scss';

import React from 'react';

import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Icon from '@ulta/core/components/Icon/Icon';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import { useAppConfigContext } from '@ulta/core/providers/AppConfigProvider/AppConfigProvider';
import { handleRedirect } from '@ulta/core/utils/handleLocation/handleLocation';
import { highlightUnmatchedText } from '@ulta/core/utils/highlightUnmatchedTextDecoration/highlightUnmatchedTextDecoration';
import { isFunction } from '@ulta/core/utils/types/types';

import { TYPEAHEAD_SEARCH_TYPE } from '@ulta/modules/SearchHeader/SearchHeader';

/**
 * Represents a SuggestionItem component
 *
 * @method
 * @param {SuggestionItemProps} props - React properties passed from composition
 * @returns SuggestionItem
 */

export const SuggestionItem = function( props ){
  const { typeaheadMinInputLength } = useAppConfigContext();
  const {
    label,
    searchString,
    action,
    index,
    onMouseEnter,
    isKeyBoardUser,
    dataCaptureHandler,
    onFocusTitle,
    handleSubmit,
    handleAddRecentSearch
  } = props;

  const searchType = searchString?.length >= typeaheadMinInputLength ? TYPEAHEAD_SEARCH_TYPE.Suggestions : TYPEAHEAD_SEARCH_TYPE.Trending;

  const hasMouseEnter = isFunction( onMouseEnter );

  if( !action?.url || !label ){
    return null;
  }

  /*
   * We disable A11y recommendations on the wrapper div because we're just storing information in localStorage
   * The Link_Huge component doesn't execute navigation if there's an onClick attached
   */
  return (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
    <div
      className='SuggestionItem'
      onClick={ () => {
        handleSubmit && handleSubmit();
      } }
      { ...( hasMouseEnter && { onMouseEnter: () => onMouseEnter( index ) } ) }
    >
      <div className='SuggestionItem__suggestion'>
        <Link_Huge secondary
          action={ action }
          onClick={ () => {
            handleAddRecentSearch( label );
            dataCaptureHandler( { searchTerm: label, searchType, targetUrl: action.url } );
            handleRedirect( { url: action.url } );
          } }
        >
          <div className='SuggestionItem__suggestion--icon'>
            <Icon name='Search'
              size='s'
              aria-hidden={ true }
            />
          </div>
          <Text htmlTag='span'
            textStyle='body-2'
            color='neutral-700'
          >
            { highlightUnmatchedText( { label, searchString } ) }
          </Text>
        </Link_Huge>
      </div>
      { isKeyBoardUser && (
        <Button
          iconImage='CaretForward'
          iconSize='s'
          variant='unstyled'
          ariaLabel={ label }
          className='SuggestionItem__arrow'
          onClick={ () => {
            onMouseEnter( index );
            onFocusTitle();
          } }
          ariaHiddenIcon={ true }
        />
      ) }
    </div>
  );
};

/**
 * Property type definitions
 * @typedef SuggestionItemProps
 * @type {object}
 * @property {string} label - Sets the label of the Suggestion
 * @property {object} action - Sets the url of the Suggestion
 * @property {number} index - Sets the index of the Suggestion
 * @property {function} onMouseEnter - Function will handle mouse enter event
 * @property {string} searchString - Sets bold a phrase on match search string
 * @property { boolean } isKeyBoardUser - Denote user is a keyboard user
 * @property { function } handleAddRecentSearch - Function will handle adding recent search to local storage
 * @property { function } dataCaptureHandler - Function will handle/capture datacapture events and store in local storage
 */
export const propTypes = {
  /** Sets the label of the Suggestion */
  label: PropTypes.string,
  /** Sets the url of the Suggestion */
  action: PropTypes.object,
  /** Sets the index of the Suggestion */
  index: PropTypes.number,
  /** Handle mouse enter event */
  onMouseEnter: PropTypes.func,
  /** Bold a phrase on match search string */
  searchString: PropTypes.string,
  /** is Keyboard user */
  isKeyBoardUser: PropTypes.bool,
  /** Handle adding recent search to local storage */
  handleAddRecentSearch: PropTypes.func,
  /** capture datacapture event and store in local storage */
  dataCaptureHandler: PropTypes.func
};

SuggestionItem.propTypes = propTypes;

export default SuggestionItem;
