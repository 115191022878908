import { NAVIGATION_MODULES } from '@ulta/modules/TopBar/TopBar';
/**
 * These utils are made for navigation related stuffs
 *
 * @module utils/navUtils
 * @memberof -Common
 */


/**
 * Find if the action type is button
 * @param  {object} action - action object
 * @return {boolean} true/false
 */
export const isButton = ( action ) => {
  return !!( action && ( action.label && action.navigationType && action.graphql ) );
};

/**
 * Find if only icon is present
 * @param  {object} props -  object
 * @return {boolean} true/false
 */
export const isOnlyIcon = ( props ) => {
  return !!( !props.action?.label && props.icon );
};

/**
 * Find if menu is primary or not
 * @param  {string} type -  string
 * @return {boolean} true/false
 */
export const isPrimary = ( type ) => {
  return ( type === NAVIGATION_MODULES.Primary );
};

/**
 * Find if navigationOverlay contains NavigationOverlayFeaturedContent
 * @param  {array} type -  modules
 * @return {boolean} true/false
 */
export const isNavigationOverlayFeaturedContent = ( modules ) => {
  return modules?.some( module=> module.moduleName === NAVIGATION_MODULES.NavigationOverlayFeaturedContent || module.moduleName === NAVIGATION_MODULES.VerticalSlot );
};

/**
 * Find if navigationOverlay contains only NavigationLinksSubGroup
 * @param  {array} type -  modules
 * @return {boolean} true/false
 */
export const isOnlyNavigationLinksSubGroup = ( data ) => {
  const { modules } = data || {};
  return modules?.every( module=> module.moduleName === NAVIGATION_MODULES.NavigationLinksSubGroup );
};


/**
 * Find if navigationOverlay contains NavigationLinksSubGroup
 * @param  {array} type -  modules
 * @return {boolean} true/false
 */
export const isRightPanel = ( data ) => {
  const { moduleName } = data || {};
  if( moduleName ){
    return moduleName !== NAVIGATION_MODULES.NavigationLinksSubGroup && moduleName !== NAVIGATION_MODULES.ImageNavigation;
  }
  return false;
};

/**
 * Calculate width for right flyout
 * @param  {string} className -  string
 * @return {string} return className based on calculation
 */
export const flyoutWidth = ( data ) => {
  const { element } = data || {};

  let columns = 1;
  let width = 0;
  let onecolumnHeight = 0;

  // getting the height of right Flyout from DOM
  let flyoutHeight = element?.clientHeight || 0;

  // getting all active NavigationLinksSubGroup from DOM
  let items = element?.children;
  // getting imageNavigation from DOM if any
  let imageNavigation = element?.querySelector( '.ImageNavigation' );

  if( imageNavigation ){
    return `brandwidth`;
  }

  // Calculating the number of columns based on rightFlyout height and all NavigationLinksSubGroup height
  if( items?.length ){
    for ( let i = 0;i < items?.length;i++ ){
      onecolumnHeight += items[i].clientHeight;
      width += onecolumnHeight;

      if( width > flyoutHeight ){
        columns++;
        width = onecolumnHeight;
      }
      onecolumnHeight = 0;
    }
  }

  return `width${ columns }`;
};

/**
 * Extracting the last word of the sentence
 * @param  {string} type -  string
 * @return {string} return lastword after extraction
 */
export const extractLastWord = ( text ) => {
  let spiltText = text.split( ' ' );
  let lastText;
  spiltText.length > 1 ? lastText = spiltText.pop() : null ;
  return lastText;
};

/**
 * Extracting the text without last word of the sentence
 * @param  {string} type -  string
 * @return {string} return text without lastword
 */
export const extractTextWithoutLastWord = ( text ) => {
  let splitText = text.split( ' ' );
  let lastText;
  splitText.length > 1 ? lastText = splitText.pop() : text;
  return splitText.join( ' ' );
};


/**
 * Filtering modules for hamburger menu of mobile
 * @param  {array} type -  modules
 * @return {array} filtered array
 */
export const mobileNavContainerModules = ( data ) => {
  const { modules } = data || {};
  const ignore = ['BagIcon'];

  return modules?.reduce( ( acc, module ) => {
    const subModules = module.modules && module.modules?.filter( sub => !ignore.includes( sub.moduleName ) );
    if(
      module.moduleName === NAVIGATION_MODULES.PrimaryBar ||
      module.moduleName === NAVIGATION_MODULES.UtilityLinks ||
      module.moduleName === NAVIGATION_MODULES.StateWrapper ||
      module.moduleName === NAVIGATION_MODULES.Avatar
    ){
      acc.push( {
        ...module,
        ...( subModules && { modules: subModules } )
      } );
    }
    return acc;
  }, []
  );
};
