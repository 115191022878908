import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12SustainablePackaging( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <g id='SustainablePackaging'>
        <path d='m6.3 9.8-.2.5-.1.7-.1-.7-.2-.5zM3 8.5l.4.4L3 9l-.2.2-.3.2.4-.5.2-.5zm5.8 0a4.4 4.4 0 0 0 .4.7l.2.3-.2-.2h-.1L9 9h-.5l.2-.2zM6 2.8a3.2 3.2 0 1 1 0 6.4 3.2 3.2 0 0 1 0-6.4zm-1.8 1a2.8 2.8 0 0 0-1 2.7c.3 0 .6-.2.8-.3V6h.2s.7.3 1 .8v1c-.2.1 0 .5.1.8a2.8 2.8 0 0 0 3.4-3.3c-.3 0-.5 0-.6.4 0 .1 0 .5-.2.7 0 .1-.2.2-.5.2h-.1c-.3 0-.5-.1-.8-.3a3 3 0 0 0-.7-.4c-.6-.1-.5-.6-.5-1l-.1-.5c-.6-.2-.9-.4-1-.6Zm0 2.8c-.2 0-.5.3-.8.3.2.7.7 1.3 1.4 1.6-.1-.2-.2-.6 0-.8v-.6c-.2-.3-.5-.5-.7-.5ZM6 3.2a2.8 2.8 0 0 0-1.4.4s0 .3.7.5c.5.1.5.7.4 1 0 .4 0 .5.2.5l.9.5.5.2h.3v-.6c.3-.7.7-.8 1-.8A3 3 0 0 0 6 3.2Zm3.8 2.5h.1l.4.2.7.1-.7.1-.5.2zm-7.6 0v.6L1.7 6H1h.5l.2-.1.5-.2zm7.3-3.2a28.5 28.5 0 0 1-.6 1l-.2-.2-.2-.2L9 3l.5-.4zm-7 0a30.2 30.2 0 0 0 1 .6l-.4.4L3 3l-.4-.5ZM6 1v.5l.1.2.2.5h-.6V2l.2-.4L6 1z'/>
      </g>
    </SvgContainer>
  );
}

export default Svg12SustainablePackaging;
