import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12Wallet( props ){

  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <defs>
        <filter id='a'>
          <feColorMatrix in='SourceGraphic'
            values='0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0'
          />
        </filter>
      </defs>
      <g fill='none'
        filter='url(#a)'
        transform='translate(.3 .5) scale(.47624)'
      >
        <g fill='#000'
          transform='translate(1 1)'
        >
          <path d='m13.5 21.9-.8-.1-9-2.5a4.5 4.5 0 0 1-3.2-4.1V3.7a3.5 3.5 0 0 1 1-2.5C2 .5 3 .2 3.9.2h13.8c2.1 0 3.8 1.6 3.8 3.7v9a3.8 3.8 0 0 1-3.8 3.7h-1.4V19a2.8 2.8 0 0 1-2.8 3zM2.3 2.6a2 2 0 0 0-.3 1v11.5A3 3 0 0 0 4 18l9.1 2.4a1.3 1.3 0 0 0 .5 0c.3 0 .6-.1.9-.4a1.3 1.3 0 0 0 .3-1V8.4a3 3 0 0 0-2.1-2.8Zm15.3 12.5c.7 0 1.3-.2 1.7-.6.4-.5.7-1 .7-1.7V9.1h-3.8v6zM20 7.6V4c0-1.3-1-2.3-2.3-2.3H4.3l9 2.6c1.5.5 2.7 1.8 3 3.4z'/>
          <circle cx='11.4'
            cy='12.5'
            r='1.1'
          />
        </g>
      </g>
    </SvgContainer>
  );
}

export default Svg12Wallet;
