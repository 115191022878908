import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg40Pinterest( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 40 }
      height={ 40 }
    >
      <path
        d='M20,0 C8.955,0 0,8.95333333 0,20 C0,28.4733333 5.27166667,35.71 12.7116667,38.6233333 C12.5366667,37.0416667 12.3783333,34.615 12.7816667,32.8883333 C13.145,31.3266667 15.1266667,22.9466667 15.1266667,22.9466667 C15.1266667,22.9466667 14.5283333,21.7483333 14.5283333,19.9766667 C14.5283333,17.1966667 16.14,15.12 18.1466667,15.12 C19.8516667,15.12 20.6766667,16.4016667 20.6766667,17.9366667 C20.6766667,19.6516667 19.585,22.2166667 19.02,24.595 C18.5483333,26.585 20.0183333,28.21 21.9816667,28.21 C25.5366667,28.21 28.2683333,24.4616667 28.2683333,19.0516667 C28.2683333,14.2633333 24.8283333,10.915 19.915,10.915 C14.225,10.915 10.885,15.1833333 10.885,19.5933333 C10.885,21.3116667 11.5466667,23.1566667 12.3733333,24.1566667 C12.5366667,24.355 12.56,24.53 12.5116667,24.7316667 L11.9566667,26.9983333 C11.8683333,27.365 11.6666667,27.4433333 11.2866667,27.2666667 C8.78833333,26.1033333 7.22666667,22.4516667 7.22666667,19.5183333 C7.22666667,13.21 11.81,7.415 20.4416667,7.415 C27.38,7.415 32.7716667,12.36 32.7716667,18.9666667 C32.7716667,25.86 28.4266667,31.4066667 22.3933333,31.4066667 C20.3666667,31.4066667 18.4616667,30.355 17.81,29.11 L16.5633333,33.865 C16.1116667,35.6033333 14.8933333,37.7816667 14.0766667,39.1083333 C15.95,39.6866667 17.9383333,40 20,40 C31.045,40 40,31.045 40,20 C40,8.95333333 31.045,0 20,0 Z'
        fill='currentColor'
        fillRule='nonzero'
      />
    </SvgContainer>
  );
}

export default Svg40Pinterest;