/**
 * React hook to Determine how a layerhost should invoke a dxl Query
 *
 * @module hooks/useLayerHostAction
 */

import { useEffect, useRef, useState } from 'react';

import { PAGE_MODULE_NAME } from '@ulta/core/components/Page/Page';
import { useAppConfigContext } from '@ulta/core/providers/AppConfigProvider/AppConfigProvider';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { useLayerHostContext } from '@ulta/core/providers/LayerHostProvider/LayerHostProvider';
import { isOverlay, useOverlay } from '@ulta/core/providers/OverlayProvider/OverlayProvider';
import { PROTECTED_PAGE_FLOW, usePageDataContext } from '@ulta/core/providers/PageDataProvider/PageDataProvider';
import { USER_PROFILE_KEYS, useUserContext } from '@ulta/core/providers/UserContextProvider/UserContextProvider';
import { actionProcessor } from '@ulta/core/utils/clientActionProcessor/clientActionProcessor';
import { DXL_NAVIGATION_TYPE } from '@ulta/core/utils/constants/action';
import { debugModuleName, devLogger, LOG_TOPIC } from '@ulta/core/utils/devMode/devMode';
import NonCachedPageQuery from '@ulta/core/utils/graphql/queries/cms/noncachedcms';
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';
import { handleRedirect } from '@ulta/core/utils/handleLocation/handleLocation';
import { queryProcessor } from '@ulta/core/utils/queryProcessor/queryProcessor';
import { getStorage } from '@ulta/core/utils/storage/storage';
import { STORAGE_KEY } from '@ulta/core/utils/storageKeys/storageKeys';

import { SIGNIN_MODULE_NAME } from '@ulta/modules/SignIn/SignIn';

import { hasItems } from '@ulta/utils/Validations/Validations';

import * as utils from './useLayerHostAction';

/**
 * Process the actions from components with invokeAction and invokeMutation
 *
 * This hook is responsible for:
 * - Processing initAction and snack bar
 * - Processing invokeAction / invokeMutation
 * - Makes sure we resolve required params from DXL actions (which is passed in a `params` array)
 *
 * @param {object} data arguments
 * @param {object} data.meta meta object from the response, we look for initAction or snackBar
 * @param {object} data.props props from the component that we use to resolve params
 * @param {function} data.invokeQuery sets the query
 * @param {function} data.invokeMutation sets the mutation
 */
export const useLayerHostAction = function( data ){
  /*
   * Prepare state and props
   */
  const { props, invokeQuery, invokeMutation, meta = {} } = handleEmptyObjects( data );
  const { initAction, loginAction, sessionAction } = meta;

  /*
   * Include providers
   */
  const { openOverlay, closeOverlay, isOverlayOpen } = useOverlay();
  const { breakpoint: { CURRENT_BREAKPOINT } = {} } = useDeviceInflection();
  const { refetchRef, protectedPageFlow, processProtectedPageAction } = usePageDataContext();
  const { user, addPostLoginCallback, isInitialSessionPing } = useUserContext();
  const { killSwitchActivated } = useLayerHostContext();
  const { stagingHost, previewDate } = useAppConfigContext();

  // TODO: Debug why `const location = useLocation()` won't work here.
  const location = global.location;

  const hasInitActionProcessed = useRef( false );
  const hasLoginActionProcessed = useRef( false );

  /*
   * Set default action state
   */
  const [mutation, setMutation] = useState( {
    graphql: `mutation{
         NotifyMe(url:"/bag/poc", moduleParams: {loginStatus:"anonymous"}) {
         content
         meta
         }
         }`
  } );

  const [query, setQuery] = useState( {
    graphql: NonCachedPageQuery,
    variables: {}
  } );

  /*
   * Listens for new queries
   */
  useEffect( () => {
    if( !invokeQuery?.graphql ){
      return;
    }

    const { graphql, variables = {} } = invokeQuery;

    const hasContentId = graphql?.match( /contentId:\s?"/ );
    const hasUrl = graphql?.match( /url:\s?{\s?path:\s?"/ ) || graphql?.match( /url:\s?"/ );
    const hasVariables = variables.contentId || variables.url || variables.url?.path;

    if( !hasContentId && !hasUrl && !hasVariables ){
      return;
    }

    if( utils.requiresParamResolution( { props, user, action: invokeQuery } ) ){
      return;
    }

    const action = utils.getRequiredClientParams( {
      user,
      props,
      location,
      action: invokeQuery,
      breakpoint: CURRENT_BREAKPOINT,
      stagingHost,
      previewDate
    } );

    actionProcessor( { action }, { openOverlay, setQuery, refetch: refetchRef.current } );
  }, [invokeQuery, user.resolved] );

  /*
   * Listens for new mutations
   */
  useEffect( () => {
    if( !invokeMutation?.graphql ){
      return;
    }

    if( utils.requiresParamResolution( { props, user, action: invokeMutation } ) ){
      return;
    }

    const action = utils.getRequiredClientParams( {
      user,
      props,
      location,
      action: invokeMutation,
      breakpoint: CURRENT_BREAKPOINT,
      isMutation: true,
      stagingHost,
      previewDate
    } );

    setMutation( queryProcessor( { action } ) );
  }, [invokeMutation, user.resolved] );

  useEffect( () => {
    // Don't reset the initAction if we are prioritizing the sessionAction
    if( !hasInitActionProcessed.current || !initAction ){
      return;
    }

    devLogger( `reset initAction | ${debugModuleName( props )}`, 0, LOG_TOPIC.InitAction );

    hasInitActionProcessed.current = false;
  }, [initAction] );

  /*
   * Listens for initAction and user changes.
   *
   * 1. Check if we have the required client paramaters
   * 2. Short circuit if we're intercepting an init action for a protected page, this needs to be broad cast to the page level
   * 3. Early return in the following scenarios:
   *    3a. We don't have an initAction
   *    3b. We've already processed the initAction
   *    3c. We're prioritizing the sessionAction
   *    3d. We're in the middle of a protected page flow
   * 4. Prepare the action for processing
   * 5. Process the initAction
   */
  useEffect( () => {
    // call utils.deriveInitActionState
    const { clientParamsResolving, isProtectedAction, shouldExecuteInitAction } = utils.deriveInitActionState( {
      hasInitActionProcessed,
      initAction,
      killSwitchActivated,
      props,
      protectedPageFlow,
      sessionAction,
      user
    } );

    // 1. If DXL has any params that are inside the user object, don't fire the query until user is resolved
    if( clientParamsResolving ){
      return;
    }

    // 2. We need to broadcast protected actions to the page level
    if(
      isProtectedAction &&
      [PROTECTED_PAGE_FLOW.Idle, PROTECTED_PAGE_FLOW.SignInOverlay].includes( protectedPageFlow.current )
    ){
      processProtectedPageAction( { initAction } );
      return;
    }

    // 3. Only process initAction if it exists and has not been processed yet, and we're not prioritizing the sessionAction
    if( !shouldExecuteInitAction ){
      return;
    }

    devLogger( `initAction | ${debugModuleName( props )}`, 0, LOG_TOPIC.InitAction );

    hasInitActionProcessed.current = true;

    // 4. Prepare the action for processing
    const action = utils.getRequiredClientParams( {
      user,
      props,
      location,
      action: initAction,
      breakpoint: CURRENT_BREAKPOINT,
      stagingHost,
      previewDate
    } );

    // 5. Reset protected page flow when we're refreshing a protected page
    checkProtectedPageFlags( { protectedPageFlow, initAction, isInitialSessionPing } );

    // 6. Process the action
    actionProcessor(
      { action },
      { setQuery, setMutation, openOverlay, closeOverlay, refetch: refetchRef.current }
    );
  }, [props, sessionAction, initAction, user.resolved] );


  // TODO: This is a temporary fix for the login action. We need to refactor this.
  useEffect( () => {
    const params = new URLSearchParams( global.location?.search );
    if( !params.get( 'source' ) || !params.get( 'originalURL' ) || !loginAction || hasLoginActionProcessed.current ){
      return;
    }

    addPostLoginCallback( utils.composePostLoginRedirect( { redirect: params.get( 'originalURL' ), stagingHost, previewDate } ) );

    hasLoginActionProcessed.current = true;

    const action = utils.getRequiredClientParams( {
      user,
      props,
      location,
      action: loginAction,
      breakpoint: CURRENT_BREAKPOINT,
      stagingHost,
      previewDate
    } );

    actionProcessor( { action }, { setQuery, openOverlay, closeOverlay, refetch: refetchRef.current } );
  }, [loginAction] );

  /*
   * Return the variables LayerHost can consume and respond to.
   */
  return [mutation, query];
};

/**
 * Handles resetting protected page flags so that we can re-authenticate the user
 * when DXL sends a full page refresh
 *
 * @param {object} data - Arguments
 * @param {object} data.initAction - The initAction
 * @param {object} data.isInitialSessionPing - The isInitialSessionPing flag from UserContextProvider
 */
export const checkProtectedPageFlags = ( data ) => {
  const { protectedPageFlow = {}, initAction = {}, isInitialSessionPing = {} } = handleEmptyObjects( data );

  if(
    protectedPageFlow.current === PROTECTED_PAGE_FLOW.Resolved &&
    initAction.navigationType === DXL_NAVIGATION_TYPE.Refresh
  ){
    // When we're on a protected page, we need to re-authenticate the user after DXL requests a full page reftech
    // 1. DXL sends an initAction.navigationType = 'refresh' in response to an update
    // 2. We need to reset the protectedPageFlow to Idle and reset an initialSessionPing flag
    // 3. We only process sessionAction at the page level once or in this situation, so we need to
    //    reset isInitialSessionPing as well
    // 4. Tell layer host to ignore the next page update so that we don't show an empty page
    protectedPageFlow.current = PROTECTED_PAGE_FLOW.ReAuthenticate;
    isInitialSessionPing.current = true;

    devLogger( `[Page] reset protectedPageFlow & isInitialSessionPing`, 1, LOG_TOPIC.InitAction );
  }
};

/**
 * Handles post login redirect
 * @param {object} data - Arguments
 * @param {object} data.redirect - The redirect URL
 * @returns {function} - The callback function
 */
export const composePostLoginRedirect = ( data ) => () => {
  const { redirect, stagingHost, previewDate } = data || {};

  if( !redirect ){
    return;
  }

  let url;
  try {
    url = new URL( data?.redirect );
  }
  catch ( value ){
    devLogger( { title: 'composePostLoginRedirect was unable to parse originURL', value }, 0, LOG_TOPIC.InitAction );
    return;
  }

  handleRedirect( { url: `${global.location.origin}${url.pathname}${url.search}`, stagingHost, previewDate } );
};

/**
 * Abstracts the complexity of determining if we should pause the initAction
 * @param {object} data - Arguments
 * @param {object} data.hasInitActionProcessed - Has initAction been processed ref
 * @param {object} data.initAction - The initAction
 * @param {object} data.props - Module props
 * @param {object} data.protectedPageFlow - The protected page flow object
 * @param {object} data.sessionAction - The sessionAction
 * @param {object} data.user - User object
 * @returns {object} - The derived state
 */
export const deriveInitActionState = ( data ) => {
  const {
    hasInitActionProcessed = {},
    killSwitchActivated = {},
    initAction,
    props = {},
    protectedPageFlow = {},
    sessionAction = {},
    user = {}
  } = handleEmptyObjects( data );

  const state = {
    clientParamsResolving: false,
    isProtectedAction: false,
    shouldExecuteInitAction: false
  };

  // 1. If DXL has any params that are inside the user object, don't fire the query until user is resolved
  if( utils.requiresParamResolution( { props, user, action: initAction } ) ){
    state.clientParamsResolving = true;
  }

  // 2. We need to broadcast protected actions to the page level
  const isProtectedOverlay = protectedPageFlow.current === PROTECTED_PAGE_FLOW.SignInOverlay;
  if( !!initAction && isProtectedOverlay && props.moduleName === SIGNIN_MODULE_NAME ){
    state.isProtectedAction = true;
  }

  // 3. Only process initAction if it exists and has not been processed yet, and we're not prioritizing the sessionAction
  const pauseInitAction = utils.shouldPauseInitAction( { protectedPageFlow, initAction, props, sessionAction } );
  state.shouldExecuteInitAction =
    !!initAction && !hasInitActionProcessed.current && !pauseInitAction && !killSwitchActivated.current;

  return state;
};

/**
 * There are some cases where we need to skip the initAction while a sessionAction is being processed
 * @param {object} data - Arguments
 * @param {object} data.protectedPageFlow - The protected page flow state
 * @param {object} data.sessionAction - The session action
 * @param {object} data.props - Module props
 * @param {object} data.initAction - The init action
 * @returns {boolean} - Whether or not we should pause the initAction
 */
export const shouldPauseInitAction = ( data ) => {
  const { protectedPageFlow = {}, sessionAction = {}, props = {}, initAction } = handleEmptyObjects( data );

  const isChildInitAction = props.moduleName !== PAGE_MODULE_NAME && !!initAction;

  // -> For children: When there is both a sessionAction and an initAction we want to process the sessionAction first
  const prioritizeSessionAction = !!sessionAction.graphql && isChildInitAction;

  const resolvedOrIdle = [PROTECTED_PAGE_FLOW.Idle, PROTECTED_PAGE_FLOW.Resolved].includes( protectedPageFlow.current );

  // -> For children: When processing a protected page flow we want to ignore the initAction until the protected page flow is resolved
  const pauseChildWhileResolvingProtected = isChildInitAction && !resolvedOrIdle;

  return prioritizeSessionAction || pauseChildWhileResolvingProtected;
};


/**
 * Ensures that we have the required user params before dispatching a request
 *
 * 1. Loops through each key of the user object
 * 2. Checks if it exists in the params array
 * 3. If it does, then we determine resolution status
 *
 * @param {object} data - Arguments
 * @param {object} data.props - Module's props
 * @param {object} data.user - User
 * @param {object} data.meta - Module's meta
 * @returns {boolean} When true we will wait until the ATG profile sync/user object is resolved
 */
export const requiresParamResolution = ( data ) => {
  const { action = {}, user = {} } = handleEmptyObjects( data );

  if( !action?.graphql || !hasItems( action.params ) || isOverlay( action.navigationType ) ){
    return false;
  }

  if( !user.resolved ){
    return true;
  }

  // Create object from params array
  const params = action.params.reduce( ( acc, curr ) => ( { ...acc, [curr]: curr } ), {} );

  // This will flip to true when a required user param is requested but is not found on the
  // resolved user model
  let requiresResolution = false;

  /*
   * Loop through each user object key and then check if it exists in params
   *
   * We need to use the user keys as the search collection because not every param will be resolved by the user
   * so if the param exists as a key on the user model, we can then determine if resolution has ocurred or not
   */
  for ( const key of Object.values( USER_PROFILE_KEYS ) ){
    // Does the user key exist in param array? If not no need to check if it's resolved yet
    if( key in params === false ){
      continue;
    }

    // DXL login status is actually login type
    if( key === USER_PROFILE_KEYS.LoginStatus && typeof user[USER_PROFILE_KEYS.LoginType] === 'undefined' ){
      requiresResolution = true;
    }
    else {
      continue;
    }

    // The param and user key match, but the user[key] is undefined we still to wait for that param to resolve
    if( typeof user[key] === 'undefined' ){
      requiresResolution = true;
      break;
    }
  }
  return requiresResolution;
};

/**
 * Method to get required client params, which includes moduleParams & set skip
 * @method getRequiredClientParams
 * @param {object} data - Arguments
 * @param {object} data.user - User
 * @param {object} data.action - DXL action
 * @param {object} data.props - Module props
 * @param {string} data.breakpoint - Current breakpoint
 * @param {boolean} data.isMutation - Is mutation, forces gti/loginStatus
 */
export const getRequiredClientParams = ( data ) => {
  const {
    user = {},
    action = {},
    location,
    props = {},
    breakpoint,
    isMutation,
    stagingHost,
    previewDate
  } = handleEmptyObjects( data );

  const forceSessionParams = isMutation || action.params?.includes( 'gti' ) || action.params?.includes( 'loginStatus' );

  if( forceSessionParams ){
    action.params = action.params || [];
    !action.params.includes( 'gti' ) && !action.params?.includes( 'loggedIn::gti' ) && action.params.push( 'gti' );
    !action.params.includes( 'loginStatus' ) && action.params.push( 'loginStatus' );
  }

  const moduleParams = utils.getModuleParams( {
    user,
    action,
    props,
    breakpoint,
    stagingHost,
    previewDate
  } );

  const variables = action.variables || {};
  variables.moduleParams = moduleParams;

  // TODO: This will all move to clientActionProcessor in a future refactor, right now the concept is
  // that we prepare client/browser related information for the action here, and then handle the Graph
  // domain in the queryProcessor.
  //
  // We can eventually move all of this to that queryProcessor and consolidate the domain logic there.
  return {
    after: action.after,
    cachePath: action.cachePath,
    clientActionParams: action.clientActionParams,
    content: action.content,
    customHeaders: action.customHeaders || [],
    dataCaptureData: action.dataCaptureData,
    graphql: action.graphql,
    method: action.method,
    isPageLoader: action.isPageLoader,
    label: action.label,
    location,
    navigationType: action.navigationType,
    pop: action.pop,
    stk: user.stk,
    url: action.url,
    fetchPolicy: action.fetchPolicy,
    variables
  };
};

/**
 * Method to get  moduleParams & set skip
 * @param {object} data - Arguments
 * @param {object} data.user - User
 * @param {object} data.action - DXL action
 * @param {object} data.props - Module props
 * @param {string} data.breakpoint - Current breakpoint
 */
export const getModuleParams = ( data ) => {
  const { user = {}, action = {}, props = {}, breakpoint, stagingHost, previewDate } = handleEmptyObjects( data );

  const { params } = action;

  let moduleParams = action.variables?.moduleParams || {};

  // handle staginghost and preview date for the module params
  moduleParams = {
    ...moduleParams,
    ...( stagingHost && { stagingHost } ),
    ...( previewDate && { previewDate } )
  };

  if( !Array.isArray( params ) || params.length === 0 ){
    return moduleParams;
  }

  /*
   * Optional params
   */
  for ( const param of params ){
    // if a param starts with loggedIn:: then we need to check if the user is logged in
    // before adding it the the moduleParams
    // add lowercase check and if there is a loggedInKey check
    if( param.startsWith( 'loggedIn::' ) && user.loginStatus ){
      const loggedInKey = param.split( '::' )[1];
      moduleParams[loggedInKey] = user[loggedInKey];
      continue;
    }

    switch ( param ){
      case 'loginStatus':
        moduleParams[param] = user.loginType;
        break;
      case 'gti':
      case 'retailerVisitorId':
        moduleParams[param] = user[param];
        break;
      case 'breakpoint':
        moduleParams[param] = breakpoint;
        break;
      case 'recentlyViewed':
        const recentlyViewed = getStorage( {
          secure: false,
          key: STORAGE_KEY.recentlyViewedSKUs
        } )?.recentlyViewed;

        if( recentlyViewed?.length ){
          moduleParams[param] = recentlyViewed;
        }

        break;
      default:
        if( props.hasOwnProperty( param ) && !moduleParams.hasOwnProperty( param ) ){
          moduleParams[param] = props[param];
        }
        else if( user.hasOwnProperty( param ) && !moduleParams.hasOwnProperty( param ) ){
          moduleParams[param] = user[param];
        }
    }
  }

  return moduleParams;
};
