/**
 * Its multipurpose messaging component using this we can create differnt types of messages like shipping banner.
 *
 * @module views/components/ValueMessaging
 * @memberof -Common
 */
import './ValueMessaging.scss';

import React from 'react';

import PropTypes from 'prop-types';

import Markdown from '@ulta/core/components/Markdown/Markdown';

import { useTopBar } from '../TopBar/TopBar';

/**
 * Represents a ValueMessaging component
 *
 * @method
 * @param {ValueMessagingProps} props - React properties passed from composition
 * @returns ValueMessaging
 */
export const ValueMessaging = React.forwardRef( ( props, _ ) => {
  const { message } = props;
  const { displayValueMessaging } = useTopBar();
  if( !displayValueMessaging || !message ){
    return null;
  }
  return (

    <div className='ValueMessaging'>
      { message &&
      <div className='ValueMessaging__message'>
        <Markdown
          bodyStyle='body-3'
          cmsMarkdownContent={ message }
        />
      </div>
      }
    </div>

  );
} );

/**
 * Property type definitions
 * @typedef ValueMessagingProps
 * @type {object}
 * @property {string} message - informative message for users
 */
export const propTypes =  {
  message: PropTypes.string.isRequired
};

ValueMessaging.propTypes = propTypes;
ValueMessaging.displayName = 'ValueMessaging';

export default ValueMessaging;
