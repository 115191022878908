import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16Edit( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
      viewBox='0 0 24 24'
    >
      <defs>
        <filter id='a'>
          <feColorMatrix in='SourceGraphic'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0'
          />
        </filter>
      </defs>
      <g fill='none'
        fillRule='evenodd'
      >
        <g filter='url(#a)'>
          <g fill='#000'
            fillRule='nonzero'
          >
            <path d='m8.953 15.419c-0.2 0-0.389-0.078-0.53-0.22-0.166-0.166-0.245-0.404-0.212-0.636l0.53-3.713c0.023-0.16 0.098-0.31 0.212-0.424l9.546-9.546c0.566-0.566 1.319-0.878 2.121-0.878s1.556 0.312 2.121 0.878c1.17 1.17 1.17 3.073 0 4.243l-9.546 9.546c-0.114 0.114-0.264 0.189-0.423 0.212l-3.713 0.531c-0.036 4e-3 -0.071 7e-3 -0.106 7e-3zm0.884-1.634l2.475-0.354 9.369-9.369c0.585-0.585 0.585-1.536 0-2.121-0.283-0.283-0.659-0.438-1.061-0.438-0.401 0-0.778 0.156-1.061 0.439l-9.369 9.369-0.353 2.474z'/>
            <path d='m2.62 24.002c-1.241 0-2.25-1.009-2.25-2.25v-15c0-1.241 1.009-2.25 2.25-2.25h7.5c0.414 0 0.75 0.336 0.75 0.75s-0.336 0.75-0.75 0.75h-7.5c-0.414 0-0.75 0.336-0.75 0.75v15c0 0.414 0.336 0.75 0.75 0.75h15c0.414 0 0.75-0.336 0.75-0.75v-7.5c0-0.414 0.336-0.75 0.75-0.75s0.75 0.336 0.75 0.75v7.5c0 1.241-1.009 2.25-2.25 2.25h-15z'/>
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}

export default Svg16Edit;