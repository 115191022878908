import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg40Youtube( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 40 }
      height={ 40 }
    >
      <path
        d='M20,0 C8.955,0 0,8.955 0,20 C0,31.045 8.955,40 20,40 C31.045,40 40,31.045 40,20 C40,8.955 31.045,0 20,0 Z M27.4016667,28.1533333 C23.8983333,28.3933333 16.095,28.3933333 12.5966667,28.1533333 C8.80333333,27.8933333 8.36166667,26.0366667 8.33333333,20 C8.36166667,13.9516667 8.80833333,12.1066667 12.5966667,11.8466667 C16.095,11.6066667 23.9,11.6066667 27.4016667,11.8466667 C31.1966667,12.1066667 31.6366667,13.9633333 31.6666667,20 C31.6366667,26.0483333 31.1916667,27.8933333 27.4016667,28.1533333 Z M16.6666667,16.0966667 L24.8616667,19.9933333 L16.6666667,23.9033333 L16.6666667,16.0966667 L16.6666667,16.0966667 Z'
        fill='currentColor'
        fillRule='nonzero'
      />
    </SvgContainer>
  );
}

export default Svg40Youtube;