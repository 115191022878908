import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function SVG( props ){

  return (
    <SvgContainer
      { ...props }
      width={ 50 }
      height={ 50 }
      viewBox='0 0 32 21'
    >
      <g fill='none'
        fillRule='evenodd'
        stroke='none'
        strokeWidth='1'
      >
        <g fillRule='nonzero'
          transform='translate(-299)'
        >
          <g transform='translate(261 .498)'>
            <g transform='translate(38.167)'>
              <g>
                <rect
                  width='31.667'
                  height='19.792'
                  x='0'
                  y='0'
                  fill='#FFF'
                  rx='1.667'
                ></rect>
                <path
                  fill='#1434CB'
                  d='M13.142 5.98L9.76 14.04H7.553L5.89 7.609c-.101-.396-.19-.541-.496-.708-.502-.272-1.33-.527-2.06-.685l.05-.234h3.552c.453 0 .86.3.962.821l.88 4.664 2.172-5.486h2.193zm8.646 5.43c.009-2.128-2.945-2.245-2.925-3.196.006-.289.282-.596.885-.675.3-.039 1.124-.069 2.058.36l.367-1.708a5.615 5.615 0 00-1.953-.358c-2.063 0-3.515 1.096-3.527 2.665-.013 1.16 1.036 1.807 1.827 2.193.814.395 1.087.649 1.084 1.002-.006.54-.65.78-1.25.789-1.05.016-1.658-.284-2.144-.51l-.378 1.766c.488.224 1.388.419 2.321.429 2.193 0 3.628-1.082 3.635-2.758m5.448 2.632h1.93l-1.685-8.06H25.7a.95.95 0 00-.889.59l-3.132 7.47h2.192l.435-1.204h2.678l.252 1.204zm-2.33-2.856l1.1-3.026.632 3.026h-1.731zM16.125 5.98l-1.726 8.06h-2.087l1.727-8.06h2.086z'
                ></path>
              </g>
              <path
                fill='silver'
                d='M30 .108c.92 0 1.667.747 1.667 1.667v16.458c0 .92-.747 1.667-1.667 1.667H1.667C.747 19.9 0 19.154 0 18.233V1.775C0 .855.746.108 1.667.108H30zm0 .417H1.667a1.25 1.25 0 00-1.245 1.13l-.005.12v16.458c0 .65.495 1.184 1.13 1.245l.12.005H30a1.25 1.25 0 001.244-1.13l.006-.12V1.775A1.25 1.25 0 0030.12.531L30 .525z'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}

export default SVG;
