import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function SVG( props ){

  return (
    <SvgContainer
      { ...props }
      width='49px'
      height='42px'
      viewBox='0 0 49 42'
      version='1.1'
    >
      <g id='Icons'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g transform='translate(-661.000000, -1929.000000)'
          id='HeartStyled_icon'
        >
          <g transform='translate(662.000000, 1930.000000)'>
            <g id='streamline-icon-love-it@140x140'
              transform='translate(3.000000, 3.000000)'
              fill='#FFCCD8'
              fillOpacity='0.25'
              fillRule='nonzero'
            >
              <path d='M22.5008845,38 L3.32587765,18.6973751 C-0.130358908,15.3641038 -0.986892889,10.269296 1.19998775,6.05222554 L1.19998775,6.05222554 C2.83116045,2.90437704 5.93056143,0.705143704 9.53006668,0.141474602 C13.1295719,-0.422194498 16.7903949,0.71841296 19.3690467,3.20701863 L22.5008845,6.22787941 L25.6327223,3.20701863 C28.2113742,0.71841296 31.8721972,-0.422194498 35.4717024,0.141474602 C39.0712076,0.705143704 42.1706086,2.90437704 43.8017813,6.05222554 L43.8017813,6.05222554 C45.9856015,10.2675002 45.1301087,15.3585737 41.6778913,18.6915843 L22.5008845,38 Z'
                id='Path'
              ></path>
            </g>
            <g id='streamline-icon-love-it@140x140'
              strokeLinecap='round'
              strokeLinejoin='round'
              stroke='#F26B1C'
            >
              <path d='M22.5008845,38 L3.32587765,18.6973751 C-0.130358908,15.3641038 -0.986892889,10.269296 1.19998775,6.05222554 L1.19998775,6.05222554 C2.83116045,2.90437704 5.93056143,0.705143704 9.53006668,0.141474602 C13.1295719,-0.422194498 16.7903949,0.71841296 19.3690467,3.20701863 L22.5008845,6.22787941 L25.6327223,3.20701863 C28.2113742,0.71841296 31.8721972,-0.422194498 35.4717024,0.141474602 C39.0712076,0.705143704 42.1706086,2.90437704 43.8017813,6.05222554 L43.8017813,6.05222554 C45.9856015,10.2675002 45.1301087,15.3585737 41.6778913,18.6915843 L22.5008845,38 Z'
                id='Path'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}

export default SVG;
