/**
   * Video player for support the video playing requirement.
   *
   * @module views/components/VideoPlayer
   * @memberof -Common
   */
import './VideoPlayer.scss';

import React, { useCallback, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';

import PropTypes from 'prop-types';

import Icon from '@ulta/core/components/Icon/Icon';
import Image from '@ulta/core/components/Image/Image';
import InlineVideo from '@ulta/core/components/InlineVideo/InlineVideo';
import { useOverlay } from '@ulta/core/providers/OverlayProvider/OverlayProvider';

import * as utils from './VideoPlayer';

const Player = typeof ReactPlayer.default !== 'undefined' ? ReactPlayer.default : ReactPlayer;

/**
 * Represents a VideoPlayer component
 *
 * @method
 * @param {VideoPlayerProps} props - React properties passed from composition
 * @returns VideoPlayer
 */

export const VideoPlayer = function( props ){
  const {
    alt,
    damStaticDomainPath,
    isAutoPlay,
    isCaptionsEnabled,
    isInlineVideo,
    name,
    videoAccessibility,
    videoUrl,
    videoThumbnail,
    videoProfiles
  } = props;

  const modalRef = useRef();
  const buttonRef = useRef();
  const { openOverlay, displayOverlay, updateContent } = useOverlay();
  const { metaData } = props;
  const modalOpen = useRef( false );

  useEffect( () => {
    if( !displayOverlay ){
      modalOpen.current = false;
    }
  }, [displayOverlay] );

  const handleKeyPress = ( event ) => {
    if( event.keyCode === 13 || event.keyCode === 32 ){
      event.preventDefault();
      onOpenVideoOverlay();
    }
  };

  useEffect( () => {
    if( !modalOpen.current ){
      return;
    }
    modalRef.current = (
      <Player
        url={ videoUrl }
        width='100%'
        height='100%'
        controls
        playing
        config={ { file: {
          attributes: {
            disablePictureInPicture: true,
            controlsList: 'nodownload'
          },
          ...( isCaptionsEnabled && { tracks: [
            {
              kind: 'subtitles',
              src: `${damStaticDomainPath}${name}_vtt`,
              srcLang: 'en',
              default: false
            }
          ] } )
        } } }
      />
    );
    updateContent( modalRef.current );

  }, [displayOverlay, modalOpen.current] );

  const onOpenVideoOverlay = useCallback(
    utils.composeOnOpenOverlay( { buttonRef, modalRef, metaData, modalOpen }, { openOverlay } ),
    []
  );

  return (
    <div className='VideoPlayer'>
      {
        !isInlineVideo &&
        <div className='VideoPlayer__thumbnail'
          onClick={ onOpenVideoOverlay }
          role='button'
          tabIndex={ 0 }
          ref={ buttonRef }
          onKeyDown={ handleKeyPress }
          aria-label={ videoAccessibility }
        >
          { /* TODO : aria-label will be localized */ }
          <Image
            src={ videoThumbnail }
            alt={ alt }
            { ...( props.metaData && { metaData: props.metaData } ) }
          />
          <div className='VideoPlayer__play'>
            <Icon
              size='m'
              name='Video'
              aria-hidden={ true }
            />
          </div>
        </div>
      }

      {
        isInlineVideo &&
        <InlineVideo
          videoUrl={ videoUrl }
          videoThumbnail={ videoThumbnail }
          alt={ alt }
          name={ name }
          damStaticDomainPath={ damStaticDomainPath }
          isCaptionsEnabled={ isCaptionsEnabled }
          videoAccessibility={ videoAccessibility }
          isAutoPlay={ isAutoPlay }
          videoProfiles={ videoProfiles }
        >
        </InlineVideo>
      }
    </div>
  );


};

/**
 * Method to handle composeOnClick
 * @param {object} data args
 * @param {object} data.buttonRef - buttonRef
 * @param {object} methods args
 * @param {function} methods.openOverlay openOverlay
 */
export const composeOnOpenOverlay = ( data, methods ) => () => {
  const { buttonRef, modalRef, metaData, modalOpen } = data || {};
  const { openOverlay } = methods || {};

  if( !openOverlay ){
    return;
  }
  modalOpen.current = true;

  openOverlay( {
    type: 'modal',
    opener: buttonRef,
    content: modalRef.current,
    isVideo: true,
    videoMeta: metaData
  } );
};

/**
 * Default values for passed properties
 * @type {object}
 * @property {string} alt='' - The default value of the alt is empty string.
 * @property {string} damStaticDomainPath=false - The default value for damStaticDomainPath is empty string.
 * @property {boolean} isAutoPlay=false - a flag to trigger autoplay on inline videos
 * @property {boolean} isCaptionsEnabled=false - The default value for isCaptionsEnabled is set to false
 * @property {boolean} isInlineVideo=false - a flag to identify inline videos
 * @property {string} name='' - The default value of the name is empty string.
 * @property {string} videoAccessibility='Click here to play the video' - The default value for aria-label is set to text 'Click here to play the video'.
 * @property {string} videoUrl='' - The default value of the videoUrl is empty string.
 * @property {string} videoThumbnail='' - The default value of the videoThumbnail is empty string.
 */
export const defaultProps = {
  alt: '',
  damStaticDomainPath: '',
  isAutoPlay: false,
  isCaptionsEnabled: false,
  isInlineVideo: false,
  name: '',
  videoAccessibility: 'Click here to play the video',
  videoUrl: '',
  videoThumbnail: ''
};

/**
 * Property type definitions
 * @typedef VideoPlayerProps
 * @type {object}
 * @property {string} alt - video alt text
 * @property {string} damStaticDomainPath - DAM static path
 * @property {boolean} isAutoPlay - a flag to trigger autoplay on inline videos
 * @property {boolean} isCaptionsEnabled - Captions availability
 * @property {boolean} isInlineVideo - a flag to identify inline videos
 * @property {string} name - video name
 * @property {string} videoAccessibility - value for aria-label for video player
 * @property {string} videoUrl - video url information
 * @property {string} videoThumbnail - video thumbnail url information
 */
export const propTypes =  {
  alt: PropTypes.string,
  damStaticDomainPath: PropTypes.string,
  isAutoPlay: PropTypes.bool,
  isCaptionsEnabled: PropTypes.bool,
  isInlineVideo: PropTypes.bool,
  name: PropTypes.string,
  videoAccessibility: PropTypes.string,
  videoUrl: PropTypes.string.isRequired,
  videoThumbnail: PropTypes.string
};

VideoPlayer.propTypes = propTypes;
VideoPlayer.defaultProps = defaultProps;

export default VideoPlayer;