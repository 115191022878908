/**
 * useInputMasker a custom hook, which accepts a value and formatter object and returns the modified value.Import useInputMasker into InputField, and revise the handleChange method&#x27;s code to conditionally call useInputMasker if the &#x27;formatter&#x27; property object exists, to format the InputField value display.
 *
 * @module utils/UseInputMasker
 */

import VMasker from 'vanilla-masker';

export const SECURE_CHARACTER = String.fromCharCode( process.env.REACT_APP_SECURE_CHARACTER );
export const INVALID_INPUT_CHARACTERS = new RegExp( `[^${process.env.REACT_APP_VALID_INPUT_STRING}${SECURE_CHARACTER}]`, 'g' );
/**
 * Returns string when it is called
 *
 * @method
 * @param { string } val - Accepts a value returns the modified value
 * @param { object } formatter - Accepts a formatter object
 * @returns { string }
 */

export const UseInputMasker = function( val, formatter ){

  const data = val;
  const numberValue = parseInt( data, 10 );
  const charLength = data.length;
  if( !formatter ){
    return data;
  }
  switch ( true ){
    case formatter.hasOwnProperty( 'pattern' ):
      return VMasker.toPattern( data, formatter.pattern );

    case formatter.hasOwnProperty( 'creditcard' ):
      if( data.match( /^3[47]/ ) ){
        return VMasker.toPattern( data, '9999 999999 99999' );
      }
      else {
        return VMasker.toPattern( data, '9999 9999 9999 9999' );
      }

    case formatter.hasOwnProperty( 'expiry' ):
      if( charLength === 2 && data.match( /^[0-9]{2}/ ) ){
        return data + '/';
      }
      else {
        return VMasker.toPattern( data, '99/99' );

      }
    case formatter.hasOwnProperty( 'dob' ):
      if( charLength === 1 && numberValue > 1 ){
        return '0' + data + '/';
      }
      else if( charLength === 2 && data.match( /^[0-9]{2}/ ) ){
        if( numberValue > 12 ){
          return 12;
        }
        else {
          return data;
        }
      }
      else if( charLength === 3 && data.charAt( 2 ) !== '/' && parseInt( data.charAt( 2 ), 10 ) > 3 ){

        return data.charAt( 0 ) + data.charAt( 1 ) + '/';

      }
      else if( charLength === 4 && parseInt( data.charAt( 3 ), 10 ) > 3 ){

        return data.slice( 0, charLength - 1 );

      }
      else if( charLength === 5 && parseInt( data.charAt( 3 ), 10 ) > 2 && parseInt( data.charAt( 4 ), 10 ) > 1 ){
        return data.slice( 0, charLength - 1 );
      }
      else {
        return VMasker.toPattern( data, '99/99' );
      }
    case formatter.hasOwnProperty( 'dobYear' ):
      if( charLength === 1 && numberValue > 1 ){
        return '0' + data + '/';
      }
      else if( charLength === 2 && data.match( /^[0-9]{2}/ ) ){
        if( numberValue > 12 ){
          return 12;
        }
        else {
          return data;
        }
      }
      else if( charLength === 3 && data.charAt( 2 ) !== '/' && parseInt( data.charAt( 2 ), 10 ) > 3 ){

        return data.charAt( 0 ) + data.charAt( 1 ) + '/';

      }
      else if( charLength === 4 && parseInt( data.charAt( 3 ), 10 ) > 3 ){

        return data.slice( 0, charLength - 1 );

      }
      else if( charLength === 5 && parseInt( data.charAt( 3 ), 10 ) > 2 && parseInt( data.charAt( 4 ), 10 ) > 1 ){

        return data.slice( 0, charLength - 1 );

      }
      else if( charLength === 6 && data.charAt( 5 ) !== '/' && parseInt( data.charAt( 5 ), 10 ) > 9 ){
        return data.charAt( 0 ) + data.charAt( 1 ) + '/' + data.charAt( 3 ) + data.charAt( 4 ) + '/';

      }
      else if( charLength >= 7 && charLength <= 10 && data.match( /^(19|20)\d{2}$/ ) ){
        return data;
      }
      else {
        return VMasker.toPattern( data, '99/99/9999' );
      }
    case formatter.hasOwnProperty( 'dobYear' ):
      if( charLength === 1 && numberValue > 1 ){
        return '0' + data + '/';
      }
      else if( charLength === 2 && data.match( /^[0-9]{2}/ ) ){
        if( numberValue > 12 ){
          return 12;
        }
        else {
          return data;
        }
      }
      else if( charLength === 3 && data.charAt( 2 ) !== '/' && parseInt( data.charAt( 2 ), 10 ) > 3 ){

        return data.charAt( 0 ) + data.charAt( 1 ) + '/';

      }
      else if( charLength === 4 && parseInt( data.charAt( 3 ), 10 ) > 3 ){

        return data.slice( 0, charLength - 1 );

      }
      else if( charLength === 5 && parseInt( data.charAt( 3 ), 10 ) > 2 && parseInt( data.charAt( 4 ), 10 ) > 1 ){

        return data.slice( 0, charLength - 1 );

      }
      else if( charLength === 6 && data.charAt( 5 ) !== '/' && parseInt( data.charAt( 5 ), 10 ) > 9 ){
        return data.charAt( 0 ) + data.charAt( 1 ) + '/' + data.charAt( 3 ) + data.charAt( 4 ) + '/';

      }
      else if( charLength >= 7 && charLength <= 10 && data.match( /^(19|20)\d{2}$/ ) ){
        return data;
      }
      else {
        return VMasker.toPattern( data, '99/99/9999' );
      }
    case formatter.hasOwnProperty( 'phone' ):
      return VMasker.toPattern( data, '(999) 999-9999' );

    case formatter.hasOwnProperty( 'giftcard' ):
      return VMasker.toPattern( data, '9999 9999 9999 9999' );

    case formatter.hasOwnProperty( 'income' ):
      return '$' + VMasker.toPattern( data, '99999999' );

    default:
      return data;
  }

};

/**
 * inputMasker is a custom hook, which accepts a value and formatter object and returns the modified value.Import inputMasker into InputField, and revise the handleChange method code to conditionally call inputMasker if the formatter property object exists, to format the InputField value display.
 *
 * Returns a string when it is called
 *
 * @method
 * @param { string } val - Accepts a value returns the modified value
 * @param { object } formatter - Accepts a formatter object
 * @returns { string }
 */

export const InputMasker = function( val, formatter ){

  if( formatter?.pattern ){
    return VMasker.toPattern( val, formatter.pattern );
  }
};

/**
 * Generates hidden content by repeating a secure character for each character in the input string.
 *
 * @param {string} content - The input string.
 * @return {string} The hidden content with the same length as the input string.
 */
export const getHiddenContent = ( content, pattern, hidden ) => {
  if( !!pattern ){
    return getMaskedContent( hidden, content, pattern );
  }
  return SECURE_CHARACTER.repeat( content.length );
};

/**
 * Generates a masked content based on a given pattern and content.
 *
 * @param {string} pattern - The pattern to be used for masking the content.
 * @param {string} content - The content to be masked.
 * @return {string} The masked content.
*/
export const getMaskedContent = ( pattern = '', content = '', validationPattern = '' ) => {
  // TODO: refactor to pass in as a constant.
  const REGEXP = new RegExp( `[${process.env.REACT_APP_VALIDATION_REGEX}]` );
  const outputArray = [];

  let contentIndex = 0;
  let patternIndex = 0;

  while ( contentIndex < content.length && patternIndex < pattern.length ){
    let key = pattern[ patternIndex ];
    let char = content[ contentIndex ];

    if( REGEXP.test( key ) ){
      if( key === 'X' && validationPattern[ patternIndex ] ){
        // if the pattern is X get the correct pattern from validation
        key = validationPattern[ patternIndex ];
      }
      if( key === '*' && validationPattern[ patternIndex ] ){
        // if the pattern is * get the valid character
        char = getValueBasedOnPattern( validationPattern[ patternIndex ], char );
      }
      // if there is a character, add to the output Array
      char && outputArray.push( getValueBasedOnPattern( key, char ) );

      // increment the content index every loop that has a valid pattern key
      contentIndex++;
    }
    // The pattern key character is not [9,A,S,X,*], use it directly in the response
    else {
      outputArray.push( key );
    }
    // Move to the next pattern character every loop
    patternIndex++;
  }

  // Join the outputArray to get the final masked content
  return outputArray.join( '' );
};

/**
 * Returns a valid character based on the provided pattern key and input value.
 *
 * @param {string} key - Pattern key specifying the type of character.
 * @param {string} value - Input value to be validated.
 * @returns {string} - Valid character based on the pattern key and input value.
 */
export const getValueBasedOnPattern = ( key, value ) => {
  let validCharacter;

  switch ( key ){
    // Case for alphabetical characters
    case 'A':
      validCharacter = /[a-zA-Z]/.test( value ) ? value : '';
      break;

    // Case for numeric characters
    case '9':
      validCharacter = /[0-9]/.test( value ) ? value : '';
      break;

    // Case for alphanumeric characters
    case 'N':
      validCharacter = /[a-zA-Z0-9]/.test( value ) ? value : '';
      break;

    // Case for masking alphanumeric characters
    case '*':
      validCharacter = SECURE_CHARACTER;
      break;

    // Default case for unrecognized pattern keys
    default:
      validCharacter = '';
  }

  // Return the valid character
  return validCharacter;
};

/**
 * A utility to format money for Annual Income
 * @param {*} i
 * @returns {string}- masked value with money
 */
export const moneyMask = ( i )=>{
  const value = i.replace( /[^\d]/g, '' );
  if( value.length === 0 ){
    return '';
  }
  else {
    return '$' + value.replace( /(\d)(?=(\d{3})+$)/g, '$1,' );
  }
};