import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16Gem( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
      viewBox='0 0 26 26'
    >
      <path d='M22.5 19.75a.75.75 0 0 1 .743.648l.007.102v1.25h1.25a.75.75 0 0 1 .102 1.493l-.102.007h-1.25v1.25a.75.75 0 0 1-1.493.102l-.007-.102v-1.25H20.5a.75.75 0 0 1-.102-1.493l.102-.007h1.25V20.5a.75.75 0 0 1 .75-.75ZM13.293.996 13.5.984h.033c.29.006.579.083.839.233l.152.098 8 5.778c.18.13.331.29.448.473l.096.17c.094.19.154.397.174.612l.008.163v7.979c0 .216-.04.427-.116.624l-.073.166a1.77 1.77 0 0 1-.406.523l-.13.104-8.001 5.778a1.747 1.747 0 0 1-.816.319l-.208.012h-.032a1.748 1.748 0 0 1-.84-.233l-.152-.098-8-5.778a1.748 1.748 0 0 1-.452-.479l-.092-.164a1.75 1.75 0 0 1-.174-.612l-.008-.163V8.51c0-.216.04-.427.116-.624l.073-.166c.103-.2.24-.376.406-.523l.13-.104 8.002-5.778c.247-.178.528-.284.817-.319ZM8.435 15.87l-2.492 1.246 6.807 4.916v-3.141L8.435 15.87Zm10.129 0-4.314 3.02v3.141l6.806-4.915-2.492-1.246ZM13.5 7.415 9.25 10.39v4.219l4.25 2.975 4.25-2.976v-4.217L13.5 7.415ZM5.249 9.213v6.574l2.501-1.251v-4.073l-2.501-1.25Zm16.5 0-2.499 1.25v4.074l2.499 1.249V9.213ZM12.75 2.967 5.943 7.883l2.492 1.246 4.315-3.021V2.967Zm1.5 0v3.142l4.314 3.02 2.492-1.246-6.806-4.916ZM3.5.75a.75.75 0 0 1 .743.648l.007.102v1.25H5.5a.75.75 0 0 1 .102 1.493L5.5 4.25H4.25V5.5a.75.75 0 0 1-1.493.102L2.75 5.5V4.25H1.5a.75.75 0 0 1-.102-1.493L1.5 2.75h1.25V1.5A.75.75 0 0 1 3.5.75Z' />
    </SvgContainer>
  );
}

export default Svg16Gem;
