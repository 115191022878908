/* eslint-disable no-console */
import * as Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';

import { isServer } from '../device_detection/device_detection';
import { isStagingEnvironment } from '../domain/domain';

/**
 * Handles url redirection
 *
 * @method
 * @param {object} data argument
 * @param {string} data.url URL to redirect to
 */
export const handleRedirect = ( data ) => {
  const { url, previewDate } = data || {};

  if( !url || isServer() ){
    return;
  }

  global.location.href = decorateParams( { url, previewDate } );
};

/**
 * Handles history url replace
 * @method
 * @param {object} data arguments
 */
export const handleReplace = ( data ) => {
  const { url, previewDate, preserveParams } = data || {};

  if( isServer() || !url ){
    return;
  }

  const decorated = decorateParams( { url, previewDate } );

  let replaceUrl = url;
  if( Link_Huge.isValidUrl( decorated ) ){
    replaceUrl = decorated;
  }

  if( !Link_Huge.isValidUrl( replaceUrl ) ){
    return;
  }

  if( preserveParams ){
    const incoming = new URL( replaceUrl );
    const existing = new URL( global.location.href );
    incoming.search = new URLSearchParams( {
      ...Object.fromEntries( new URLSearchParams( existing.search ) ),
      ...Object.fromEntries( new URLSearchParams( incoming.search ) )
    } ).toString();
    replaceUrl = incoming.href;
  }

  global.history.replaceState( null, '', replaceUrl );
};

/**
 * Handle window refresh
 * @method
 */
export const handleReload = () => {
  if( isServer() ){
    return;
  }

  global.location.reload();
};

/**
 * Centralized the business logic to decoreate url params when needed. Typically used for
 * business on staging.
 *
 * @param {object} data - Data object
 * @param {string} data.url - Url to decorate
 * @returns {string} url - Retutns the decorated url
 */
export const decorateParams = ( data ) => {
  const { url, previewDate } = data || {};

  let urlObj;
  let base = global.location?.origin || global.ssrLocation?.origin;

  // Handle when a hash is passed in
  if( url?.[0] === '#' ){
    base = global.location?.href || global.ssrLocation?.href;
  }

  try {
    urlObj = new URL( url, base );
  }
  catch ( e ){
    return url;
  }

  if( !isStagingEnvironment( urlObj.href ) ){
    return urlObj.href;
  }

  let decorated = urlObj.href;

  // For preview date we need to sanitize the colons so it's a bit more involved
  if( !!previewDate ){
    const url = new URL( decorated );
    url.searchParams.set( 'previewDate', 'ULTA_PREVIEW_DATE_PLACEHOLDER' );
    // add sanitized previewDate param
    decorated = url.href.replace( 'ULTA_PREVIEW_DATE_PLACEHOLDER', `${previewDate.replaceAll( '%3A', ':' )}` );
  }

  return decorated;
};