import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24Filters( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <g
        transform='translate(0 3)'
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <circle cx={ 15.5 }
          cy={ 3.5 }
          r={ 3.5 }
        />
        <circle cx={ 9 }
          cy={ 14.5 }
          r={ 3.5 }
        />
        <path d='M.5 14.5H5M.5 3.5h11M19 3.5h4.5M12.5 14.5h11' />
      </g>
    </SvgContainer>
  );
}

export default Svg24Filters;
