import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12StarHalf( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <path
        d='M2.712 10.917a.361.361 0 01-.122-.373l.388-1.41.483-1.762-2.355-2.334a.358.358 0 01.171-.603l.051-.008 2.862-.283L5.675 1.2a.364.364 0 01.274-.196L6.001 1v.002l.049.003a.364.364 0 01.273.195L7.81 4.146l2.862.283a.358.358 0 01.222.61L8.54 7.375l.873 3.172a.361.361 0 01-.511.416L6 9.525 3.103 10.96a.36.36 0 01-.391-.043zM6 2.041v6.817c.078 0 .156.015.23.042l.064.028 2.292 1.135-.69-2.512a.667.667 0 01.129-.602L8.07 6.9l1.889-1.873-2.215-.219a.667.667 0 01-.495-.304l-.034-.059z'
        fill='currentColor'
        fillRule='nonzero'
      />
    </SvgContainer>
  );
}

export default Svg12StarHalf;
