import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function SVG( props ){

  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
      viewBox='0 0 24 24'
    >
      <g fill='none'
        fillRule='evenodd'
        stroke='none'
        strokeWidth='1'
      >
        <path
          fill='#000'
          fillRule='nonzero'
          d='M22.563 16H1.938l-.112.008c-.483.07-.826.495-.826.992 0 .548.415 1 .938 1h20.625l.111-.008c.483-.07.826-.495.826-.992 0-.548-.415-1-.938-1zm0-10H1.938l-.112.008C1.343 6.078 1 6.503 1 7c0 .548.415 1 .938 1h20.625l.111-.008c.483-.07.826-.495.826-.992 0-.548-.415-1-.938-1z'
          transform='rotate(90 12.25 12)'
        ></path>
      </g>
    </SvgContainer>
  );
}

export default SVG;
