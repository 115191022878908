/**
 * The HTML element, or anchor element, along with its href attribute, creates a hyperlink to other web pages, files, locations within the same page, email addresses, or any other URL.
 *
 * @module views/Navigation/Anchor
 */
import './Link_Huge.scss';

import React, { forwardRef } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useLocalRef } from '@ulta/core/hooks/useLocalRef/useLocalRef';
import { useAppConfigContext } from '@ulta/core/providers/AppConfigProvider/AppConfigProvider';
import { DXL_NAVIGATION_TYPE } from '@ulta/core/utils/constants/action';
import { constants } from '@ulta/core/utils/constants/constants';
import datacapture from '@ulta/core/utils/datacapture/datacapture';
import { devLogger } from '@ulta/core/utils/devMode/devMode';
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';
import { decorateParams } from '@ulta/core/utils/handleLocation/handleLocation';
import { isFunction } from '@ulta/core/utils/types/types';
import PaletteLink from 'web-palette/dist/components/Link/Link';

/**
  * Represents a Link_Huge component
  *
  * @method
  * @param { Link_HugeProps } props - React properties passed from composition
  * @returns Link_Huge
  */
export const Link_Huge = forwardRef( ( props, ref ) => {
  const {
    action = {},
    children,
    className,
    disabled,
    href,
    id,
    onClick,
    tabIndex,
    target: incomingTarget,
    url,
    // ADA props
    ariaLabel,
    ariaCurrent,
    screenReaderText,
    title,
    // palette props (preferred)
    fullWidth,
    iconImage,
    iconPosition,
    iconsize,
    isKnockoutLink,
    size,
    textStyle,
    variant,
    // legacy props (deprecated)
    arrowLink,
    compact,
    icon,
    iconRight,
    inline,
    likeButtonCompact,
    likeButtonOutline,
    likeButtonPrimary,
    likeButtonSecondary,
    likeButtonWithHover,
    secondary,
    skipLinks,
    withArrow,
    withHover,
    withHoverBtnSytle,
  } = handleEmptyObjects( props );

  const { isNative, previewDate } = useAppConfigContext();

  const dataCaptureData = action.dataCaptureData;
  const target = action.navigationType === DXL_NAVIGATION_TYPE.NewTab ? '_blank' : incomingTarget;

  let linkUrl = url || action.url || action?.anchorId;

  // Add preview date in the staging env
  try {
    if( isValidUrl( linkUrl ) && process.env.NODE_ENV !== 'test' ){
      linkUrl = decorateParams( { url: linkUrl, previewDate } );
    }
  }
  catch ( err ){
    devLogger( { title: `Unable to format ${linkUrl} for preview as it is not an aboslute URL`, value: err } );
  }

  if( isNative && dataCaptureData && isValidUrl( linkUrl ) ){
    linkUrl = new URL( linkUrl );
    const search_params = linkUrl.searchParams;
    search_params.set( 'dcEvent', 'true' );
    linkUrl = linkUrl.toString();
  }

  const linkRef = useLocalRef( { ref } );

  const bindOnClick = linkUrl && ( dataCaptureData || onClick );
  const onClickHandler = Link_Huge.handleOnClick( {
    url: linkUrl,
    dataCapture: dataCaptureData,
    windowProxy: global,
    handleInteractionEvents: Link_Huge.handleInteractionEvents( { url: linkUrl, isNative, target } ),
    isNative,
    target
  }, {
    onClick
  } );

  const displayProps = getDisplayProps( props );
  const PalLink = PaletteLink.default || PaletteLink; // TODO: figure out why we need to find default in the PaletteLink and why import { default as Palette Link } isn't working.

  return (
    <PalLink
      linkRef={ linkRef }
      isKnockoutLink={ isKnockoutLink }
      disabled={ disabled }
      { ...displayProps }
      className={
        classNames( className, {
          'pal-c-Link--withArrow': withArrow,
          'pal-c-Link--skipLinks': skipLinks,
          'pal-c-Link--inline': inline
        } )
      }
      { ...( Number.isInteger( tabIndex ) && { tabIndex } ) }
      { ...( id && { id } ) }
      { ...( target && { target } ) }
      { ...( linkUrl && !disabled && { href: linkUrl } ) }
      { ...( title && { title } ) }
      { ...( ariaLabel && { ariaLabel } ) }
      { ...( disabled && { 'aria-disabled': disabled } ) }
      { ...( ariaCurrent && { 'aria-current': ariaCurrent } ) }
      // TODO removing for crawl deployment to site b
      { ...( bindOnClick && { onClick: onClickHandler } ) }
    >
      { children }
    </PalLink>
  );
} );

/**
  * Method to handle the click event
  * @param {object} data - arguments
  * @param {string} data.url - url
  * @param {object} data.dataCapture - data capture object
  * @param {object} data.windowProxy=window - window proxy
  * @param {object} methods - methods
  * @param {function} methods.onClick - onClick method
  */
export const handleOnClick = ( data, methods ) => ( e ) => {
  const { url, dataCapture, handleInteractionEvents, windowProxy = global, isNative, target } = data;
  const { onClick } = methods || {};

  e.preventDefault();

  if( dataCapture ){
    datacapture.processEvents( { dataCapture }, constants.DATACAPTURE_EVENT_TYPE.click, handleInteractionEvents );
  }

  if( isFunction( onClick ) ){
    onClick( e );
    return;
  }

  if( url.includes( 'http' ) && !url.includes( global.location.origin ) && !isNative ){
    windowProxy.open( url, '_blank' );
    return;
  }

  if( url.includes( global.location.origin ) && target === '_blank' ){
    windowProxy.open( url, '_blank' );
    return;
  }

  if( url.startsWith( 'tel' ) ){
    return;
  }

  setTimeout(
    () => {
      Link_Huge.handleNavigation( { url, isNative, target } );
    },
    constants.TEALIUM_LINK_EVENT_TIMEOUT
  );
};

/**
  * Method for page redirection to the url passed in param
  * @param  { String } url
  */
export const handleNavigation = function( data ){
  const { url, isNative, target } = data;
  if( url.includes( 'http' ) && !url.includes( global.location.origin ) && !isNative ){
    return;
  }
  if( url.includes( global.location.origin ) && target === '_blank' ){
    return;
  }
  global.location.href = url;
};

/**
  * call back method that needs to be triggered once the interaction
  * events are triggered to tealium.
  * @param  { String } url
  */
export const handleInteractionEvents = ( data ) => {
  const { url, isNative, target } = data;
  let linkUrl = removeDcEvent( url );
  return () => {
    Link_Huge.handleNavigation( { url: linkUrl, isNative, target } );
  };
};

/**
  * This function is used to check whether url is valid or not.
  * If url is valid it returns true else returns false.
  * @param  { String } urlString
  */
export const isValidUrl = urlString => {
  try {
    return Boolean( new URL( urlString ) );
  }
  catch ( e ){
    return false;
  }
};

/**
  * call back method that needs to be triggered once the interaction
  * events are triggered to tealium.
  * @param  { String } url
  */
export const removeDcEvent = ( url ) => {
  let linkUrl = url;
  if( isValidUrl( url ) ){
    linkUrl = new URL( url );
    const search_params = linkUrl.searchParams;
    search_params.delete( 'dcEvent' );
    linkUrl = linkUrl.toString();
  }
  return linkUrl;
};

/**
 * Function to format legacy link props to palette link props. We prefer palette props when they are present.
 * @method
 * @param {Object} event
 * @returns { Object } // object to spread into the palette link
 */
export const getDisplayProps = ( data ) => {

  const linkVariant = getLinkVariant( data );
  const linkSize = getLinkSize( data );
  const linkIconPosition = getIconPosition( data );
  const linkIconName = getIconName( data );
  const hasTextStyle = getTextStyle( data );
  const derivedIconSize = getIconSize( data );

  return(
    {
      variant: linkVariant,
      size: linkSize,
      iconName: linkIconName,
      iconPosition: linkIconPosition,
      iconsize: derivedIconSize,
      textStyle: hasTextStyle
    }
  )
};

/**
 * Function to format legacy variant props to palette link props. We prefer palette props when they are present.
 * @method
 * @param {Object} event
 * @returns { String } // string for link variant
 */
export const getLinkVariant = ( data ) => {
  const {
    variant,
    arrowLink,
    primary,
    secondary,
    tertiary,
    likeButtonPrimary,
    likeButtonSecondary,
    likeButtonOutline
  } = data;

  const noStyle = !variant && !arrowLink && !secondary && !tertiary && !likeButtonPrimary && !likeButtonSecondary && !likeButtonOutline;

  if( noStyle ){
    return 'primary'; // default style is primary
  }

  if( !!variant ){
    return variant;
  }

  if( arrowLink ){
    return 'arrow'
  }

  if( primary ){
    return 'primary';
  }

  if( secondary ){
    return 'tertiary';
  }
  
  if( tertiary ){
    return 'tertiary';
  }

  if( likeButtonPrimary ){
    return 'buttonPrimary'
  }

  if( likeButtonSecondary ){
    return 'buttonSecondary'
  }

  if( likeButtonOutline ){
    return 'buttonSecondary'
  }
};

/**
 * Function to format legacy link size props to palette link props. We prefer palette props when they are present.
 * @method
 * @param {Object} event
 * @returns { String } // string for link size
 */
export const getLinkSize = ( data ) => {
  const {
    size,
    compact,
    likeButtonCompact,
    tiny
  } = data;

  if( !size && ( !compact && !likeButtonCompact && !tiny ) ){
    return 'default'; // default size is default
  }

  if( !!size ){
    return size;
  }

  if( compact ){
    return 'compact';
  }

  if( likeButtonCompact ){
    return 'compact';
  }

  if( tiny ){
    return 'tiny';
  }
};

/**
 * Function to format legacy link size props to palette link props. We prefer palette props when they are present.
 * @method
 * @param {Object} event
 * @returns { String } // string for link size
 */
export const getTextStyle = ( data ) => {
  const {
    textStyle
  } = data;

  if( !textStyle ){
    return 'title';
  }

  if( textStyle === 'body-1' ){
    return 'body-lg';
  }

  if( textStyle === 'body-2' ){
    return 'body';
  }

  if( textStyle === 'bold-body-2' ){
    return 'body';
  }

  if( textStyle === 'body-3' ){
    return 'body-sm';
  }

  if( textStyle === 'bold-body-3' ){
    return 'body-sm';
  }

  if( textStyle === 'eyebrow' ){
    return 'eyebrow';
  }

  if( textStyle === 'subtitle-1' ){
    return 'displayBody';
  }

  if( textStyle === 'subtitle-2' ){
    return 'displayBody-sm';
  }

  if( textStyle === 'subtitle-3' ){
    return 'displayBody-sm';
  }

  if( textStyle === 'title-1' ){
    return 'display-lg';
  }

  if( textStyle === 'title-2' ){
    return 'display';
  }

  if( textStyle === 'title-3' ){
    return 'display-sm';
  }

  if( textStyle === 'title-4' ){
    return 'title-xlg';
  }

  if( textStyle === 'title-5' ){
    return 'title-lg';
  }

  if( textStyle === 'title-6' ){
    return 'title';
  }

};

/**
 * Function to format legacy iconName props to palette button props. We prefer palette props when they are present.
 * @method
 * @param {Object} event
 * @returns { String } // string for icon name
 */
export const getIconName = ( data ) => {
  const {
    iconName, // palette prop (preferred)
    iconImage
  } = data;

  if( !iconName && !iconImage ){
    return ''; // default iconName is no icon name
  }

  if( !!iconName ){
    return iconName;
  }
  if( !iconName && !!iconImage ){
    return iconImage;
  }
};

export const getIconPosition = ( data ) => {
  const {
    iconPosition, // palette prop (preferred)
    iconImage,
    icon,
    iconRight
  } = data;

  if( !iconPosition && ( !iconImage && icon ) ){
    return 'before'; // default icon position is before
  };

  if( !!iconPosition ){
    return iconPosition; // prefer palette props
  };

  // if legacy icon name exists and palette icon name does not exist, we will get the position based on the legacy boolean
  if( iconImage && !icon ){
    if( iconRight ){
      return 'after'; // format to palette accepted string
    }
    if( !iconRight ){
      return 'before'; // format to palette accepted string
    }
  };
};

/**
 * Function to format legacy icon size props to palette icon size props. We prefer palette props when they are present.
 * @method
 * @param {Object} event
 * @returns { String } // string for icon size
 */
// TODO: Move this to be shared for Link/button
export const getIconSize = ( data ) => {
  const { iconsize } = data;

  // legacy icon sizes are 's', 'm', and 'l'. Palette icon sizes are 'default', 'lg', and 'xl'. We want to convert lagacy sizes to the palette sizes.
  if( !iconsize || iconsize === 's' || iconsize === 'default'){
    return 'default';
  }
  if( iconsize === 'm' || iconsize === 'lg'){
    return 'lg';
  }
  if( iconsize === 'l' || iconsize === 'xl' ){
    return 'xl';
  }

  // return default for unsupported values
  return 'default';
};

/**
  * property type definitions
  * @typedef Link_HugeProps
  * @type {object}
  * @property {object} action - sets the action
  * @property {string} ariaCurrent - Indicates the state of a link in relation to the current page or navigation context
  * @property {string} ariaLabel - Sets the string in cases where text label is not visible on the screen
  * @property {boolean} compact - Flag to render the compact variation
  * @property {boolean} disabled - Flag to render the disabled variation and sets the aria-disabled label
  * @property {string} iconImage - Name of the Icon to render, renders the Icon
  * @property {boolean} iconRight - Flag to render the icon to the right of the label
  * @property {string} iconsize - Size of the Icon to render, it's value matches the viewports naming: s, m, l
  * @property {string} id - sets the id
  * @property {boolean} inline - Flag to render inline link which will add an extra class
  * @property {boolean} isKnockoutLink - Knockout styling for an inverted link
  * @property {boolean} secondary - Flag to render the secondary variation
  * @property {boolean} skipLinks - Flag to render the skipLinks which will add an extra class
  * @property {number} tabIndex - sets the tabIndex, typically 0 or -1, for Aria support
  * @property {( '_blank' | '_self' )} target - A string, representing where to open the linked document
  * @property {string} title - sets the text for display
  * @property {string} url - sets the url as href of anchor element to redirect
  * @property {boolean} withHover - Flag to render the withHover variation
  * @property {boolean} withArrow - Flag to render the withArrow which will add an extra class
  */
export const propTypes = {
  /** Sets the action */
  action: PropTypes.object,
  /** Indicates the state of a link in relation to the current page or navigation context */
  ariaCurrent: PropTypes.string,
  /** Sets the string in cases where text label is not visible on the screen */
  ariaLabel: PropTypes.string,
  /** Flag to render the compact variation */
  compact: PropTypes.bool,
  /** Sets the dataCaptureData that needs to be triggered on click */
  dataCaptureData: PropTypes.object,
  /** Flag to render the disabled variation */
  disabled: PropTypes.bool,
  /** Name of the Icon to render, renders the Icon */
  iconImage: PropTypes.string,
  /** Flag to render the icon to the right of the label */
  iconRight: PropTypes.bool,
  /** Size of the Icon to render, it's value matches the viewports naming: s, m, l */
  iconsize: PropTypes.string,
  /** Sets the id */
  id: PropTypes.string,
  /** Flag to render the inline link which will add an extra class  */
  inline: PropTypes.bool,
  /** Knockout variant for component rendered on a dark background */
  isKnockoutLink: PropTypes.bool,
  /** Function as the event handler */
  onClick: PropTypes.func,
  /** Flag to render the secondary variation */
  secondary: PropTypes.bool,
  /** Flag to render the SkipLinks which will add an extra class  */
  skipLinks: PropTypes.bool,
  /** sets the tabIndex, typically 0 or -1, for Aria support */
  tabIndex: PropTypes.number,
  /** A string, representing where to open the linked document */
  target: PropTypes.oneOf( ['_blank', '_self'] ),
  /** Sets the text for display */
  title: PropTypes.string,
  /** Sets the url as href of anchor element to redirect */
  url: PropTypes.string,
  /** Flag to render the withArrow which will add an extra class  */
  withArrow: PropTypes.bool,
  /** Flag to render the withHover variation */
  withHover: PropTypes.bool
};

/**
  * Default values for passed properties
  * @type object
  * @property { string } [ target='_self' ] - sets the target, typically _self or _blank, specifies where to open the linked document
  */
export const defaultProps = {
  ariaLabel: '',
  className: '',
  disabled: false,
  target: '_self'
};

Link_Huge.propTypes = propTypes;
Link_Huge.defaultProps = defaultProps;
Link_Huge.handleOnClick = handleOnClick;
Link_Huge.handleNavigation = handleNavigation;
Link_Huge.handleInteractionEvents = handleInteractionEvents;

export default Link_Huge;