/**
 * The Formatters utility component is used to manipulate string data passed into specific methods, which returns the resulting string in a predefined format.
 *
 * @module utils/Formatters
 */
import React from 'react';

import { isServer } from '@ulta/core/utils/device_detection/device_detection';

/**
 *
 * FORM INPUT FORMATTERS
 *
 */

/**
 * Returns a string with all non-alphanumeric characters removed from the passed value.
 *
 * @method removeSpecialCharacters
 * @param {string} val - Value to remove all non-alphanumeric characters from.
 * @returns {string}
 */
export const removeSpecialCharacters = ( val ) => ( val.replace( /[^a-zA-Z0-9]/g, '' ) );

/**
 * Returns an object with masked input (bullet character) of the first five digits in a SSN, array of the entered value history and actual SSN values
 *
 * @method maskFirstFiveSSN
 * @param {string} fieldValue - Input value to mask first five characters.
 * @param {boolean} ssnShowMask - Flag to mask or unmask the entry.
 * @param {array} ssnHistory - Array of entered numbers.
 * @returns {object}
 * @returns {object.ssnMask} - String representing XXX-XX-XXXX SSN number format with the first five digits masked.
 * @returns {object.ssnHistory} - Array of the digits entered so far by the user.
 * @returns {object.ssnValue} - Unformatted string value of the numbers entered.
 */
export const maskFirstFiveSSN = ( fieldValue, ssnShowMask, ssnHistory ) => {
  // may need to check for password masking character for different OS/browsers
  let _ssnHistory = ssnHistory;
  let ssnValue;
  let ssnMask;
  let passCharacter = '\u2022';
  let passCharacterRegex = '\u{2022}';
  let regex = new RegExp( `[^\\d\\${ passCharacterRegex }]`, 'g' );
  let ssnFieldValue = fieldValue.replace( regex, '' );
  let lastFourDigits = ssnFieldValue.substr( 5, 4 ).replace( new RegExp( `\\${ passCharacterRegex }`, 'g' ), '' );

  if( ssnShowMask ){
    if( ssnFieldValue.length <= 5 ){
      if( _ssnHistory.length < ssnFieldValue.length ){
        for ( let i = 0; i < ssnFieldValue.length; i++ ){
          if( /\d/g.test( ssnFieldValue[i] ) ){
            _ssnHistory.splice( i, 0, ssnFieldValue[i] );
          }
        }
      }
      else {
        for ( let i = 0; i < ssnFieldValue.length; i++ ){
          if( /\d/g.test( ssnFieldValue[i] ) ){
            _ssnHistory.splice( i, 0, ssnFieldValue[i] );
          }
        }
        _ssnHistory = _ssnHistory.slice( 0, ssnFieldValue.length );
        if( ssnFieldValue.length === 0 ){
          _ssnHistory.pop();
        }
      }
    }
    else {
      let re = /\d/g;
      if( ssnFieldValue.match( re ).length >= 5 && !ssnFieldValue.match( new RegExp( '[^\\d]', 'g' ) ) ){
        _ssnHistory = Array.from( ssnFieldValue.replace( new RegExp( `[^\\d]`, 'g' ), '' ).substr( 0, 5 ) );
      }
    }
  }
  else {
    _ssnHistory = Array.from( ssnFieldValue.substr( 0, 5 ) );
    ssnValue = ssnFieldValue;
  }

  ssnValue = _ssnHistory.toString().replace( /,/g, '' ) + lastFourDigits;

  if( ssnValue.length > 5 ){
    ssnMask = `${ Array( 4 ).join( passCharacter )} - ${ Array( 3 ).join( passCharacter ) } - ${ ssnValue.substr( 5, 4 ) }`;
  }
  else if( ssnValue.length > 3 ){
    ssnMask = `${ Array( 4 ).join( passCharacter ) } - ${ Array( ssnValue.substr( 3 ).length + 1 ).join( passCharacter ) }`;
  }
  else {
    ssnMask = `${ Array( ssnValue.length + 1 ).join( passCharacter ) }`;
  }

  return {
    ssnMask,
    ssnHistory,
    ssnValue
  };

};

/**
 *
 * URL FORMATTER
 *
 */

// for SSR, host will be initialized to undefined. the setServerHost method will be invoked from the appropriate controller to set the host value
export let host = isServer() ? undefined : ( global?.location?.hostname.search( /ir.ultabeauty.com|storead|creative/ ) !== -1 || global?.location?.hostname.search( 'ulta.com' ) === -1 ) ? 'www.ulta.com' : global.location.hostname;


/**
 * prepends the host the app is running on to the beginning of a URL
 * that starts with a single '/' or returns the original URL
 * or otherwise modified URL in special cases
 *
 * @method fullyQualifyLink
 * @param {string} host - A string that describes the hostname the app is running on
 * @param {string} url - the original URL sent into the <Anchor /> component
 * @returns {string} - Returns modified url
 */



export const fullyQualifyLink = ( host, url ) => {

  let _url = typeof url === 'string' ? url : '';
  // this is for Canada shipping
  if( _url.indexOf( 'global-ulta.com' ) !== -1 ){

    if( host === 'ulta.com' || host === 'www.ulta.com' ){
      // handle canada shipping link for 3rd party pages
      return '//global.ulta.com';
    }
    else {
      // if a URL for Canada shipping comes in we want to modify it to go
      // to the host the app is running on
      return _url.replace( /\/\/global-ulta\.com/, `//global-${host}` );
    }

  }
  else if( !_url.match( /^\/\/|^http:|^https:|^#|^tel:|^data:|^mailto:/ ) ){

    // if URL starts with '//', 'http:', 'https:', or the URL is '#'
    // we skip this step and just return the original URL at the end of this function

    // if a URL starts with a single / we should modify it to
    // prepend '//' + host

    // does the _url start with a slash?
    if( _url.charAt( 0 ) === '/' ){
      _url = _url.substring( 1 );
    }

    return `//${ host }/${ _url }`;
  }

  // return default URL if there are no special cases met
  return _url;

};

/**
 * Find and highlight relevant keywords within a block of text
 * @param  {string} mainString - The text to parse
 * @param  {string} subString - The search keyword to highlight
 * @return {object} A JSX object containing an array of alternating strings and JSX
 */
export const highlightString = ( mainString, subString ) => {
  let parse = mainString.split( subString );

  if( !subString ){
    return mainString;
  }
  return (
    <>
      {
        parse.reduce( ( prev, current, i ) => {
          if( !i ){
            return [current];
          }
          return prev.concat( <span key={ subString + current }>{ subString }</span>, current );
        }, [] )
      }
    </>
  );
};

/**
 * Find and total hours between two date
 * @param  {object} date1 - Start date
 * @param  {object} date2 - End date
 * @return {string} A number of total hours
 */
export const calculateHours = ( date2, date1 ) => {
  let diff = ( date2.getTime() - date1.getTime() ) / 1000;
  diff /= ( 60 * 60 );
  return Math.abs( Math.round( diff ) ) + ' hours';
};


/**
 * This function write the text to clipboard
 *
 * @param {String} text
 * @returns promise
 */
export const copyTextToClipboard = async( text ) => {
  return await navigator?.clipboard?.writeText( text );
};

/**
 * This function converts integer into a formatted currency value. USD only at this time.
 * @param {Number} amount integer amount to be formatted into currency
 * @returns formatted string in format of $9,999.00
 */
export const formatCurrency = function( amount ){
  if( typeof amount !== 'number' ){
    return amount;
  }
  return '$' + parseFloat( amount ).toFixed( 2 ).replace( /(\d)(?=(\d{3})+\.)/g, '$1,' );
};