import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16ShippingBoxClosed( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
      viewBox='0 0 22 22'
    >
      <path d='m11.16.02.1.03 10.3 4.12.02.01a.7.7 0 0 1 .14.08l.02.01c.09.07.16.16.2.26l.04.1v.05l.01.03.01.1V17.2c0 .24-.13.47-.34.59l-.1.05-10.29 4.11-.03.02a.61.61 0 0 1-.2.04H11a.67.67 0 0 1-.13-.01l-.06-.02h.01a.69.69 0 0 1-.06-.01h-.02l-.01-.02-10.3-4.11a.69.69 0 0 1-.42-.54L0 17.2V4.8a.7.7 0 0 1 .02-.18l.04-.1c.04-.1.11-.19.2-.26l.02-.01.07-.05.04-.02-.03.02a.7.7 0 0 1 .06-.03h.01L10.74.04a.69.69 0 0 1 .42-.03Zm-9.79 5.8v10.9l8.94 3.58V9.4L1.37 5.83Zm19.25 0L11.7 9.4v10.9l8.94-3.58V5.82Zm-1.08 9.05c.13.32 0 .69-.3.85l-.08.04-1.72.7a.69.69 0 0 1-.6-1.25l.1-.04 1.71-.68c.35-.14.75.03.9.38ZM6.36 3.28 2.54 4.81 11 8.2l3.82-1.53-8.46-3.39ZM11 1.43 8.2 2.54l8.47 3.39 2.8-1.12L11 1.43Z'
        fill='#000'
        fillRule='nonzero'
      />
    </SvgContainer>
  );
}

export default Svg16ShippingBoxClosed;
