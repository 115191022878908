import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24StarOutlined( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <path
        d='M11.998 1a.8.8 0 01.712.436l3.272 6.481 6.296.624a.787.787 0 01.489 1.343l-5.182 5.136 1.921 6.979a.795.795 0 01-1.125.914l-6.383-3.16-6.375 3.156a.795.795 0 01-1.125-.914l1.92-6.979L1.234 9.88a.787.787 0 01.489-1.343l6.296-.624 3.267-6.477A.8.8 0 0111.998 1zm0 2.279l-2.67 5.294c-.204.404-.58.688-1.017.777l-.148.022-4.873.482 4.16 4.12c.343.34.495.822.414 1.291l-.031.14-1.523 5.526 5.037-2.493c.364-.18.785-.2 1.162-.06l.14.06 5.043 2.497-1.52-5.526a1.467 1.467 0 01.284-1.325l.097-.106 4.156-4.12-4.872-.482a1.467 1.467 0 01-1.09-.668l-.075-.13z'
        fill='currentColor'
        fillRule='nonzero'
      />
    </SvgContainer>
  );
}

export default Svg24StarOutlined;
