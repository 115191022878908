/**
 * use ActionGroup when you need various combinations of actions within a larger component upto 2 actions available.
 *
 * @module views/components/ActionGroup
 * @memberof -Common
 */
import './ActionGroup.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import { isOverlay } from '@ulta/core/providers/OverlayProvider/OverlayProvider';
import { useUserContext } from '@ulta/core/providers/UserContextProvider/UserContextProvider';
import { CLIENT_ACTION_TYPES } from '@ulta/core/utils/constants/action';
import { dispatchLegacyLogin } from '@ulta/core/utils/dispatchLegacyLogin/dispatchLegacyLogin';
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';

import Link from '../Link/Link';
import * as utils from './ActionGroup';

/**
 * Represents a ActionGroup component
 *
 * @method
 * @param {ActionGroupProps} props - React properties passed from composition
 * @returns ActionGroup
 */
export const ActionGroup = function( props ){
  const { user } = handleEmptyObjects( useUserContext() );
  const {
    ariaLabel,
    action1,
    action2,
    action1Style,
    action2Style,
    centerActions,
    isOfferActivated,
    clientActionType1,
    clientActionType2
  } = handleEmptyObjects( props );

  const bothButtons = isButton( action1Style ) && isButton( action2Style );

  const clickHandler1 = utils.composeClickHandler( { user, action: action1, clientActionType: clientActionType1 } );
  const clickHandler2 = utils.composeClickHandler( { user, action: action2, clientActionType: clientActionType2 } );

  return (
    <div
      className={ classNames( 'ActionGroup', {
        ActionGroup__onlyLink: action1 && !isButton( props.action1Style ) && !isButton( props.action2Style ),
        ActionGroup__bothButton: isButton( props.action1Style ) && isButton( props.action2Style ),
        ActionGroup__oneButton: isButton( props.action1Style ) && !isButton( props.action2Style ),
        ActionGroup__isCentered: centerActions
      } ) }
    >
      { utils.getAction(
        {
          ariaLabel,
          action: action1,
          style: action1Style,
          bothButtons,
          buttonClass: 'ActionGroup__button1',
          clientActionType: clientActionType1,
          isOfferActivated
        },
        { clickHandler: clickHandler1 }
      ) }

      { utils.getAction(
        {
          ariaLabel,
          action: action2,
          style: action2Style,
          bothButtons,
          buttonClass: 'ActionGroup__button2',
          clientActionType: clientActionType2,
          isOfferActivated
        },
        { clickHandler: clickHandler2 }
      ) }
    </div>
  );
};

export const composeClickHandler = ( data ) => () => {
  const { user, action, clientActionType } = data || {};

  if( !user?.loginStatus && clientActionType === CLIENT_ACTION_TYPES.LEGACY_LOGIN ){
    dispatchLegacyLogin( global.location.href );
  }
  if( action?.signOut ){
    action?.signOut();
  }
};


export const linkFormatter = ( data ) =>{
  const { label, ariaLabel, withHover, disabled, action = {} } = handleEmptyObjects( data );

  return (
    <div className='ActionGroup__link'>
      <Link_Huge
        target='_self'
        withHover={ withHover }
        linkUrl={ action.url }
        disabled={ disabled }
        ariaLabel={ ariaLabel }
        action={ action }
      >
        { label }
      </Link_Huge>
    </div>
  );
};

export const buttonFormatter = ( data, methods ) => {
  const { ariaLabel, label, style, isOfferActivated = false, action } = handleEmptyObjects( data );
  const { clickHandler } = methods || {};

  return (
    <Button
      label={ label }
      action={ action }
      ariaLabel={ ariaLabel }
      { ...isActionStyle( style, isOfferActivated ) }
      onClick={ clickHandler }
      { ...( action.crossButtonVisibility && { crossButtonVisibility: true } ) }
    />
  );
};

export const arrowLinkFormatter = ( data ) =>{
  const { action } = data || {};
  return (
    <Link
      linkAction={ action }
      linkIcon='ArrowForward'
      linkStyle='title-6'
    />
  );
};

export const getAction = ( data, methods ) => {
  const { ariaLabel: ariaLabelProp, action = {}, style, buttonClass, bothButtons, isOfferActivated } = handleEmptyObjects( data );
  const { clickHandler } = methods || {};

  if( !action || !clickHandler ){
    return null;
  }

  const label = action?.label;
  const ariaLabel = ariaLabelProp ? `${label} ${ariaLabelProp}` : label;

  // Returns a button
  if( isOverlay( action.navigationType ) || utils.isButton( style ) ){
    return (
      <div
        className={ classNames( buttonClass, {
          ActionGroup__bothButton: bothButtons
        } ) }
      >
        { utils.buttonFormatter( { ariaLabel, label, style, isOfferActivated, action }, { clickHandler } ) }
      </div>
    );
  }

  // Returns a link
  return (
    <>
      { style === 'link' &&
        utils.linkFormatter( { ariaLabel, label, withHover: true, disabled: false, action } )
      }

      { style === 'link_disabled' &&
        utils.linkFormatter( { ariaLabel, label, withHover: false, disabled: true, action } )
      }

      { style === 'arrow_link' &&
        <div className='ActionGroup__arrowLink'>{ utils.arrowLinkFormatter( { action } ) }</div>
      }

      { style === 'arrow_link_disabled' &&
        <div className='ActionGroup__arrowLink--disabled'>{ utils.arrowLinkFormatter( { action } ) }</div>
      }
    </>
  );
};

/**
 * Function for button action
 * @function isButton
 * @param { string } actionStyle - type of message [primary, primary_disabled, secondary, secondary_disabled,tertiary,tertiary_disabled]
 */
export const isButton = ( actionStyle ) => {
  switch ( actionStyle ){
    case 'primary':
      return 'primary';
    case 'primary_disabled':
      return 'primary_disabled';
    case 'tertiary':
      return 'tertiary';
    case 'tertiary_disabled':
      return 'tertiary_disabled';
    case 'secondary':
      return 'secondary';
    case 'secondary_disabled':
      return 'secondary_disabled';
    default:
      return null;
  }
};

/**
 * Function for button action style
 * @function isActionStyle
 * @param { string } actionStyle - type of message [primary, primary_disabled, secondary, secondary_disabled,tertiary,tertiary_disabled]
 */
export const isActionStyle = ( actionStyle, isOfferActivated ) => {
  switch ( actionStyle ){
    case 'primary_disabled':
      return { disabled : true };
    case 'tertiary':
      return { tertiary : true };
    case 'tertiary_disabled':
      let secondaryDisabledProps;
      if( isOfferActivated ){
        secondaryDisabledProps = {
          secondary: true,
          disabled: true,
          iconLeft: true,
          iconImage: 'Check',
          iconSize: 's',
          className: 'isOfferActivated'
        };
      }
      else {
        secondaryDisabledProps = {
          secondary: true,
          disabled: true
        };
      }
      return secondaryDisabledProps;
    case 'secondary':
      return { secondary : true };
    case 'secondary_disabled':
      return {
        secondary: true,
        disabled: true
      };
    default:
      return null;
  }
};

/**
 * Property type definitions
 * @typedef ActionGroupProps
 * @type {object}
 * @property {object} action1 - sets the action1
 * @property {object} action2 - sets the action2
 * @property {string} action1Style - sets the action1Style
 * @property {string} action2Style - sets the action2Style
 * @property {boolean} centerActions - boolean to center the links
 * @property {string} ariaLabel - Sets the ariaLabel for the button and Link
 */
export const propTypes =  {
  action1: PropTypes.object,
  action2: PropTypes.object,
  action1Style: PropTypes.string,
  action2Style: PropTypes.string,
  centerActions: PropTypes.bool,
  ariaLabel: PropTypes.string
};

ActionGroup.propTypes = propTypes;

export default ActionGroup;
