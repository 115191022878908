/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * This Dropdown component renders the simple dropdown element
 *
 * @module views/components/DropDown
 * @memberof -Common
 */
import './DropDown.scss';

import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '@ulta/core/components/Icon/Icon';
import Text from '@ulta/core/components/Text/Text';
import UseKeyPress from '@ulta/core/hooks/useKeyPress/UseKeyPress';
import useOutsideClick from '@ulta/core/hooks/useOutsideClick/useOutsideClick';
import { useToggle } from '@ulta/core/hooks/useToggle/useToggle';
import { constants } from '@ulta/core/utils/constants/constants';
import datacapture from '@ulta/core/utils/datacapture/datacapture';

import * as utils from './DropDown';

const { ESC_KEY, UPARROW_KEY, DOWNARROW_KEY, ENTER_KEY, TAB_KEY } = constants;

/**
  * Represents a DropDown component
  *
  * @method
  * @param {DropDownProps} props - React properties passed from composition
  * @returns DropDown
  */
export const DropDown = function( props ){
  const { options, ariaLabel, id } = props;
  const itemsRef = useRef( );
  const buttonRef = useRef( );
  const ulRef = useRef( );
  const [actElm, setActElm] = useState( options.findIndex( item => item.selected ) );
  const [selectedIndex, setSelectedIndex] = useState( 0 );
  const [listOpen, setListOpen] = useToggle( false );
  const selected = options?.length > 0 && options.find( item => item.selected ) || options && options[0];
  const [selectedItem, setSelectedItem] = useState( selected );
  const hasSelectedItem = !!selectedItem?.action?.label;
  const listBoxId =  utils.getId( { id } );
  let itemLength = options?.length;

  useEffect( () => {
    if( listOpen ){
      ulRef.current?.children[actElm]?.focus();
    }
  }, [actElm] );
  useOutsideClick( { watcher: listOpen, el: itemsRef?.current }, { handleClick: () => {
    setListOpen( false );
  } } );

  UseKeyPress( [ESC_KEY, UPARROW_KEY, DOWNARROW_KEY, ENTER_KEY, TAB_KEY], itemsRef, ( ev )=>{
    utils.onKeyPress( { keyCode: ev.keyCode, actElm, listOpen, buttonRef, itemLength, options, selectedItem }, { setListOpen, setSelectedItem, setActElm, selectAction: props.selectAction, setSelectedIndex } );
  }, [TAB_KEY, ESC_KEY] );

  if( itemLength === 0 || !ariaLabel ){
    return null;
  }
  return (
    <div className='DropDown'
      ref={ itemsRef }
    >
      <button
        role='combobox'
        aria-controls={ listBoxId }
        aria-expanded={ listOpen }
        aria-haspopup={ 'listbox' }
        aria-label={ ariaLabel }
        className={ classNames( 'DropDown__header', {
          'DropDown__header--open': listOpen
        } ) }
        onClick={ () => setListOpen( true ) }
        data-testid='dropdown__header'
        ref={ buttonRef }
      >
        { hasSelectedItem &&
          (
            <div className='DropDown__title'
              id={ selectedItem.id }
              data-testid='dropdown__header__value'
            >
              <Text htmlTag='span'
                textStyle='body-2'

              >{ selectedItem.action?.label }</Text>
              <span >
                <Icon name='CaretDown'
                  size='s'
                />
              </span>
            </div>
          ) }
      </button>

      { listOpen &&
        <div className={ classNames( 'DropDown__listContainer', {
          'DropDown__listContainer--open': listOpen
        } ) }
        >
          <ul
            ref={ ulRef }
            role='listbox'
            aria-label={ ariaLabel }
            className='DropDownItem__wrapper'
            id={ listBoxId }
          >
            { options.map( ( item, index ) => (
              <li key={ index }
                id={ `option-${ index }` }
                tabIndex='-1'
                role='option'
                aria-selected={ selectedIndex === index }
                className={ actElm === index ? `DropDownItem--current` : `DropDownItem` }
                onClick={ () => {
                  setActElm( index );
                  setSelectedIndex( index );
                  utils.onItemSelection(
                    { value:item, index:index, buttonRef },
                    { setSelectedItem, selectAction: props.selectAction, setListOpen }
                  );
                } }
              >
                { item.action?.label &&
                <Text textStyle='body-3'
                  textAlign={ props.textAlign }
                >{ item.action?.label }</Text>
                }
              </li>
            ) ) }
          </ul>
        </div>
      }

    </div>
  );
};

/**
  *
  * @method onItemSelection
  * @summary Method to update range values
  * @param {object} data - value returned by inputfield
  * @param {object} methods - methods for updating state values
  *  @param {object} data arguments
  * @param {string} data.value - value returned by inputfield
  * @param {object} data.buttonRef - React button ref
*/
export const onItemSelection = ( data, methods ) => {
  const { value, buttonRef } = data;
  const { setListOpen, selectAction, setSelectedItem } = methods;
  value.selected = true;
  setListOpen( false );
  selectAction( value );
  setSelectedItem( {
    ...value
  } );
  const dataCaptureData = value.action?.dataCaptureData ;
  if( dataCaptureData ){
    triggerDatacaptureEvent( dataCaptureData );
  }

  buttonRef?.current.focus();
};

/**
  *
  * @method triggerDatacaptureEvent
  * @summary This method triggers datacapture events
  * @param {Object} datacaptureEvent dxl response
  */
export const triggerDatacaptureEvent = ( datacaptureEvent ) =>{
  if( datacaptureEvent ){
    datacapture.processEvents( { dataCapture : datacaptureEvent }, datacaptureEvent.clientEvent?.toLowerCase() );
  }
};

/**
  *
  * @method dropDownClose
  * @param {method} setListOpen - state update method
  * @param {object} buttonRef - react button ref
  */
export const dropDownClose = ( data, methods )=>{
  const { buttonRef } = data;
  const { setListOpen } = methods;

  setListOpen( false );
  buttonRef.current.focus();
};
/**
  *
  * @method onKeyPress
  * @param {object} data - variables
  * @param {object} methods - methods
  */
export const onKeyPress = ( data, method )=>{
  const { keyCode, actElm, listOpen, buttonRef, itemLength, options } = data;
  const { setListOpen, setSelectedItem, setActElm, selectAction, setSelectedIndex } = method;
  switch ( keyCode ){
    case ESC_KEY : listOpen && utils.dropDownClose( { buttonRef: buttonRef }, { setListOpen: setListOpen } ); break;
    case TAB_KEY: listOpen && utils.dropDownClose( { buttonRef: buttonRef }, { setListOpen: setListOpen } ); break;
    case UPARROW_KEY: !listOpen && setListOpen( true );setActElm( actElm === 0 ? 0 : actElm - 1 ); break;
    case DOWNARROW_KEY: !listOpen && setListOpen( true );setActElm( actElm === itemLength - 1 ? itemLength - 1 : actElm + 1 ); break;
    case ENTER_KEY:
      setSelectedIndex( actElm );
      onItemSelection(
        { value: options[actElm], buttonRef },
        { setSelectedItem, selectAction: selectAction, setListOpen }
      );

  }
};
/**
 * @const {number} DROPDOWN_INDEX - Counter for dropdown id so there are no duplicates on the page
 */
export const DROPDOWN_INDEX = { 'current': 0 };

/**
  * @method getId
  * * @param {object} data - consists id of option with Ul-- to avoid multi dropdown with id
  */
export const getId = ( data ) => {
  const { id } = data || {};
  const dropDownId = id || ++DROPDOWN_INDEX.current;
  return `DropDown--${dropDownId}`;
};
/**
  * Property type definitions
  * @typedef DropDownProps
  * @type {object}
  * @property {object.<{ label: string, value: string }>} options - Options value
  * @property {function} selectAction - method to call when item is selected.
  * @property {string} textAlign - dropdown item text alignment
  * @property {string} ariaLabel  - dropdown label for screen reader
  */
export const propTypes = {
  options: PropTypes.arrayOf( PropTypes.shape(
    {
      id: PropTypes.string.isRequired,
      action: PropTypes.shape( {
        label: PropTypes.string,
        url: PropTypes.string,
        graphql: PropTypes.string
      } ),
      selected: PropTypes.bool
    }
  ) ),
  id:PropTypes.string,
  selectAction: PropTypes.func,
  textAlign: PropTypes.string,
  ariaLabel: PropTypes.string.isRequired
};

/**
  * Default values for passed properties
  * @type {object}
  * @property {string} textAlign='left' - Set to 'left' for displaying the default text alignment
  */
export const defaultProps =  {
  textAlign: 'left'
};

DropDown.propTypes = propTypes;
DropDown.defaultProps = defaultProps;

export default DropDown;