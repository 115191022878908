import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24MessageBubble( props ){

  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
      viewBox='0 0 24 24'
      version='1.1'
    >
      <g id='message-bubble'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='Group'
          fill='#000000'
          fillRule='nonzero'
        >
          <g id='-Icon-Color'>
            <path
              d='M20.9695341,1 C22.0909294,1 23,1.90907057 23,3.03046595 L23,3.03046595 L23,16.8297491 C23,17.9511445 22.0909294,18.8602151 20.9695341,18.8602151 L20.9695341,18.8602151 L11.5264086,18.8601362 L6.18064516,22.8698925 C5.77701411,23.1726158 5.21115149,22.9193632 5.14597607,22.4415942 L5.14597607,22.4415942 L5.13978495,22.3494624 L5.13899642,18.8601362 L3.03046595,18.8602151 C1.95579538,18.8602151 1.0761245,18.0253221 1.00468434,16.9687673 L1.00468434,16.9687673 L1,16.8297491 L1,3.03046595 C1,1.90907057 1.90907057,1 3.03046595,1 L3.03046595,1 Z M20.9695341,2.30107527 L3.03046595,2.30107527 C2.6276346,2.30107527 2.30107527,2.6276346 2.30107527,3.03046595 L2.30107527,3.03046595 L2.30107527,16.8297491 C2.30107527,17.2325805 2.6276346,17.5591398 3.03046595,17.5591398 L3.03046595,17.5591398 L5.79032258,17.5591398 C6.1496046,17.5591398 6.44086022,17.8503954 6.44086022,18.2096774 L6.44086022,18.2096774 L6.44007168,21.0476774 L10.9197133,17.6892473 C11.0097977,17.621684 11.115469,17.5789532 11.2262018,17.5645634 L11.2262018,17.5645634 L11.3100358,17.5591398 L20.9695341,17.5591398 C21.3723654,17.5591398 21.6989247,17.2325805 21.6989247,16.8297491 L21.6989247,16.8297491 L21.6989247,3.03046595 C21.6989247,2.6276346 21.3723654,2.30107527 20.9695341,2.30107527 L20.9695341,2.30107527 Z'
            />
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}

export default Svg24MessageBubble;
