import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16ArrowForward( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <g
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M14.666 8h-14M8.167 15l7-7.023-7-6.977' />
      </g>
    </SvgContainer>
  );
}

export default Svg16ArrowForward;
