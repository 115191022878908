import datacapture from '@ulta/core/utils/datacapture/datacapture';

import { constants } from '@ulta/utils/constants/constants';

import { FOCUSABLE_ELEMENT_SELECTORS } from '../accessibility/accessibility';

/**
 * This method takes the nextSlide as a parameter ,
 * identifies if it is a next or previous action and triggers the action with the corresponding index
 * @param  {Object} nextClickAction
 * @param  {Object} previousClickAction
 * @param  {Object} carouselRef
 * @param  {Object} handleImpressionEvent
 * @param  {Object} productRailRef
 * @param  {Object} setIndex
 *
 */

export const handleSlideChange = ( nextClickAction, previousClickAction, carouselRef, handleImpressionEvent, productRailRef, setIndex ) => {
  return ( nextSlide )=>{
    let carouselItemIndex  =  carouselRef.current.state.currentSlide ;
    let action ;
    if( setIndex ){
      setIndex( nextSlide );
    }
    if( nextSlide > carouselRef.current.state.currentSlide ){
      carouselItemIndex = carouselItemIndex + carouselRef.current.state.slidesToShow ;
      carouselItemIndex = carouselItemIndex > carouselRef.current.state.totalItems ? carouselRef.current.state.totalItems : carouselItemIndex;
      action = nextClickAction ;
    }
    else {
      carouselItemIndex = carouselItemIndex + 1; // adding 1 as the index on rail starts with 0 and we need to start it from 1
      action = previousClickAction;
    }

    if( action ){
      // If handleImpressionEvent is passed in parameter, rail click event needs to be merged together with item level impression event.
      if( handleImpressionEvent ){
        handleImpressionEvent( productRailRef, nextSlide, { dataCapture: action.dataCaptureData }, { carouselItemIndex } );
      }
      else {
        datacapture.processEvents( { dataCapture: action.dataCaptureData }, constants.DATACAPTURE_EVENT_TYPE.click, null, { carouselItemIndex } );
      }
    }
  };
};
export const handleSwiperSlideChange = ( nextClickAction, previousClickAction, carouselRef, handleImpressionEvent, productRailRef ) => {
  return ( nextSlide )=>{
    let action = nextClickAction || previousClickAction;
    let carouselItemIndex = carouselRef?.current?.swiper?.snapIndex;

    if( action ){
      // If handleImpressionEvent is passed in parameter, rail click event needs to be merged together with item level impression event.
      if( handleImpressionEvent ){
        handleImpressionEvent( productRailRef, nextSlide, { dataCapture: action.dataCaptureData }, { carouselItemIndex } );
      }
      else {
        datacapture.processEvents( { dataCapture: action.dataCaptureData }, constants.DATACAPTURE_EVENT_TYPE.click, null, { carouselItemIndex } );
      }
    }
  };
};

/**
 * This method takes the tokenizedString, values as a parameter,
 * return the updated tokenized string
 * @param  {String} tokenizedString - gets slide %x of %totalLength from CMS
 * @param  {Array} values - [array index count starts with 1, total length of array]
 */
const handleTokenizedString = ( tokenizedString, values = [] )=>{
  if( !tokenizedString ){
    return null;
  }
  if( !values || tokenizedString && !tokenizedString.includes( '%' ) ){
    return tokenizedString;
  }
  let tokenizedValue = tokenizedString;
  values.forEach( ( val, index ) => {
    let token = `%${ index + 1 }`;
    tokenizedValue = tokenizedValue.replace( token, val );
  } );
  return tokenizedValue;
};

const computeProductTag = ( tag, label = '' ) => {
  if( !tag ){
    return null;
  }
  return label ? label.replace( '%1', tag ) : tag;
};

/**
 * This method to hide the inactive swiper slides and not to get the focus for child focusable elements
 * Add aria-hidden to true for swipers which are not in viewport
 * Add tabindex to -1 for child focusable elements of swiper slide which are not in viewport
 */
export const handleInactiveSwiperSlides = ( data ) => {
  const { slides, visibleSlides } = data || {};
  if( !slides || !visibleSlides ){
    return;
  }
  slides.forEach( ( item ) => {
    item.setAttribute( 'aria-hidden', 'true' );
    const focusableElements = item.querySelectorAll( FOCUSABLE_ELEMENT_SELECTORS.join( ', ' ) );
    focusableElements.forEach( ( item ) => manageTabIndex( item, 'set' ) );
  } );
  visibleSlides.forEach( ( item ) => {
    item.removeAttribute( 'aria-hidden' );
    const focusableElements = item.querySelectorAll( FOCUSABLE_ELEMENT_SELECTORS.join( ',' ) );
    focusableElements.forEach( ( item ) => manageTabIndex( item, 'remove' ) );
  } );
};
// Helper function to manage tabindex
export const manageTabIndex = ( buttonElement, action ) => {
  if( !buttonElement ){
    return;
  }
  if( action === 'set' ){
    buttonElement.setAttribute( 'tabindex', '-1' );
  }
  else if( action === 'remove' ){
    buttonElement.removeAttribute( 'tabindex' );
  }
};

const carouselUtils = {
  handleSlideChange,
  handleTokenizedString,
  computeProductTag
};

export default carouselUtils;