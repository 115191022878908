import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24StarFilled( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <path
        d='M11.998 1a.8.8 0 01.712.436l3.272 6.481 6.296.624a.787.787 0 01.489 1.343l-5.182 5.136 1.921 6.979a.795.795 0 01-.682.994l-.113.004a.794.794 0 01-.33-.084l-6.383-3.16-6.375 3.156a.794.794 0 01-.218.073l-.112.011a.795.795 0 01-.795-.998l1.92-6.979L1.234 9.88a.787.787 0 01.489-1.343l6.296-.624 3.267-6.477A.8.8 0 0111.998 1z'
        fill='currentColor'
        fillRule='nonzero'
      />
    </SvgContainer>
  );
}

export default Svg24StarFilled;
