/**
 * Common ReviewStarsCard component used by other modules as a reusable component which returns the review stars as fullfilled and half filled.
 *
 * @module views/components/ReviewStarsCard
 * @memberof -Common
 */
import './ReviewStarsCard.scss';

import React from 'react';

import PropTypes from 'prop-types';

import Icon from '@ulta/core/components/Icon/Icon';
import Text from '@ulta/core/components/Text/Text';

import carouselUtils from '@ulta/utils/carouselUtils/carouselUtils';
import { starIcons } from '@ulta/utils/starIcons/starIcons';

/**
  * Represents a ReviewStarsCard Component
  *
  * @method
  * @param {ReviewStarsCardProps} props - React properties passed from composition
  * @returns ReviewStarsCard
  */
const ReviewStarsCard = ( props ) => {

  const {
    max,
    size,
    rating,
    reviews,
    componentKey,
    reviewAccessibilityLabel
  } = props;
  const stars = starIcons( { rating, max } );
  const hasStars = stars.length > 0;
  const reviewsLocaleString = reviews?.toLocaleString();
  const reviewScreenReaderLabel = carouselUtils.handleTokenizedString( reviewAccessibilityLabel, [rating, reviewsLocaleString] );

  return (
    <div className='ReviewStarsCard'>
      { hasStars &&
      <>
        { stars.map( ( i, ind ) => {
          return (
            <Icon
              aria-hidden={ true }
              key={ `${componentKey}-star-${ind}` }
              name={ i }
              size={ size }
            />
          );
        } ) }
        { !!reviewsLocaleString && !!reviewScreenReaderLabel &&
          <>
            <span className='sr-only' >{ reviewScreenReaderLabel }</span>
            <Text
              htmlTag='span'
              textStyle='body-3'
              color='neutral-600'
              ariaHidden={ true }
            >
              ({ reviewsLocaleString })
            </Text>

          </>
        }
      </>
      }
    </div>

  );
};

/**
  * Property type definitions
  * @typedef ReviewStarsCardProps
  * @type {object}
  * @property {number} max - Maximum number of stars to display
  * @property {number} rating - 0 to 5 decimal number indicating the rating
  * @property {number} reviews - Number of total reviews
  * @property {string} size - Size of the star icon (`s`, `m` or `l`).
  * @property {string} reviewAccessibilityLabel - Sets review accessibility label
  * @property {number} componentKey - Sets the component key
  */
ReviewStarsCard.propTypes = {
  /** Maximum number of stars to display */
  max: PropTypes.number,
  /** 0 to 5 decimal number indicating the rating */
  rating: PropTypes.number,
  /** Number of total reviews */
  reviews: PropTypes.number,
  /** Size of the star icon (`s`, `m` or `l`). */
  size: PropTypes.oneOf( [`s`, `m`, `l`] ),
  /** Sets review accessibility label */
  reviewAccessibilityLabel: PropTypes.string,
  /** Sets the component key */
  componentKey: PropTypes.number
};

/**
  * Default values for passed properties
  * @type {object}
  * @property {number} max=5 - Default max value
  * @property {number} rating=0 - Default rating value
  * @property {number} reviews=0 - Default reviews value
  * @property {string} size='s' - Default size value
  */
ReviewStarsCard.defaultProps = {
  max: 5,
  rating: 0,
  reviews: 0,
  size: 's'
};

export default ReviewStarsCard;
