import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24UserCircle( props ){

  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <defs>
        <filter id='filter-1'>
          <feColorMatrix in='SourceGraphic'
            type='matrix'
            values='0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0'
          ></feColorMatrix>
        </filter>
      </defs>
      <g id='01-Token/Icon/Interface/User-Circle'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g filter='url(#filter-1)'
          id='single-neutral-circle'
        >
          <g>
            <path d='M12,15.75 C8.692,15.75 6,13.058 6,9.75 C6,6.442 8.692,3.75 12,3.75 C15.308,3.75 18,6.442 18,9.75 C18,13.058 15.308,15.75 12,15.75 Z M12,5.25 C9.519,5.25 7.5,7.269 7.5,9.75 C7.5,12.231 9.519,14.25 12,14.25 C14.481,14.25 16.5,12.231 16.5,9.75 C16.5,7.269 14.481,5.25 12,5.25 Z'
              id='Shape'
              fill='#000000'
              fillRule='nonzero'
            ></path>
            <path d='M12,24 C9.323,24 6.789,23.132 4.668,21.49 C4.647,21.478 4.602,21.452 4.558,21.408 C4.553,21.403 4.547,21.397 4.542,21.391 C1.655,19.094 0,15.674 0,12 C0,5.383 5.383,0 12,0 C18.617,0 24,5.383 24,12 C24,15.674 22.345,19.094 19.457,21.391 C19.452,21.397 19.447,21.402 19.442,21.407 C19.399,21.45 19.355,21.476 19.33,21.491 C17.211,23.132 14.677,24 12,24 Z M6.284,20.801 C7.99,21.914 9.956,22.5 12,22.5 C14.045,22.5 16.011,21.914 17.717,20.801 C16.113,19.479 14.101,18.755999 12.001,18.755999 C9.901,18.755 7.889,19.479 6.284,20.801 Z M12,1.5 C6.21,1.5 1.5,6.21 1.5,12 C1.5,15.023 2.794,17.875 5.062,19.874 C6.977,18.184 9.428,17.257 12,17.257 C14.573,17.257 17.023,18.184 18.938,19.873 C21.206,17.873 22.5,15.022 22.5,11.999 C22.5,6.21 17.79,1.5 12,1.5 Z'
              id='Shape'
              fill='#000000'
              fillRule='nonzero'
            ></path>
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}

export default Svg24UserCircle;
