/**
 * Header component for desktop pages accross the portal.
 *
 * @module views/components/TopBar
 * @memberof -Common
 */
import './TopBar.scss';

import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import AsyncComponent from '@ulta/core/components/AsyncComponent/AsyncComponent';
import Grid from '@ulta/core/components/GridContainer/GridContainer';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import { useKeyPressCounter } from '@ulta/core/hooks/useKeyPressCounter/useKeyPressCounter';
import { useAppConfigContext } from '@ulta/core/providers/AppConfigProvider/AppConfigProvider';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { useOverlay } from '@ulta/core/providers/OverlayProvider/OverlayProvider';
import { usePageDataContext } from '@ulta/core/providers/PageDataProvider/PageDataProvider';
import { isServer } from '@ulta/core/utils/device_detection/device_detection';

import MobileNavContainer from '@ulta/components/MobileNavContainer/MobileNavContainer';

import constants from '@ulta/utils/constants/constants';
import { mobileNavContainerModules } from '@ulta/utils/navUtils/navUtils';

import * as utils from './TopBar';

/**
 * Represents a TopBar component
 *
 * @method
 * @param {TopBarProps} props - React properties passed from composition
 * @returns TopBar
 */
export const TopBar = React.forwardRef( ( props, _ ) => {
  const { setHeaderOffset } = usePageDataContext();
  const { displayOverlay } = useOverlay();
  const { previewDate, updateAppConfig } = useAppConfigContext();

  /* showHambugerMenu - used to show or hide hamburger Menu on click of hambergur Icon */
  const [showHambugerMenu, setShowHambugerMenu] = useState( false );

  /* menuNavigation - used for mobile animation to check whether the user is navigating forwards or backwards through the menu */
  const [menuNavigation, setMenuNavigation] = useState( { forward: false, backward: false } );

  /* showBack - used for mobile navigation to check back button state */
  const [showBack, setShowBack] = useState( false );

  /* showLoadingBar - used for page loading bar animation */
  const [showLoadingBar, setShowLoadingBar] = useState( false );

  /* activePrimaryLinks - used for mobile navigation to check if one of primary links menus are active */
  const [activePrimaryLinks, setActivePrimaryLinks] = useState( null );

  /* activeNavigationLinksGroup - used for mobile navigation to check if one of navigation links group menus are active */
  const [activeNavigationLinksGroup, setActiveNavigationLinksGroup] = useState( null );

  /* keyBoardUser - used to check if user is using keyboard or mouse */
  const [keyBoardUser, setKeyBoardUser] = useState( false );

  const [primaryScrollTop, setPrimaryScrollTop] = useState( 0 );

  const ref = useRef();
  const { inflection, breakpoint } = useDeviceInflection();
  const isMobile = inflection.MOBILE;

  const {
    displayValueMessaging,
    displayUtilityNavigation,
    displayPrimaryNavigationLinks,
    displaySearchInput,
    displayUserNavigation,
    headerTitle,
    modules,
    skipLinkActions
  } = props;

  const prettyPreviewDate = getPreviewDate( { previewDate } );

  useEffect( () => {
    // Other areas of the Application need some of TopBar's config so we add it to the global app config here. For example, when Avatar loads in an overlay it is not inside a TopBar context and cannot retrieve this value.
    // TODO: Consolidate app config, ENV, and top bar config into a simpler scheme
    updateAppConfig( { displayUserNavigation } );

    return ()=> {
    };

  }, [] );

  // Activate ADA -keyBoard mode after 3 tabs detected
  useKeyPressCounter( { keyCodes: [constants.TAB_KEY], threshold: 3 }, { onThreshold: () => {
    setKeyBoardUser( true );
  } } );

  // Is fixed and hidden manages when we're scrolled down the page but want to animate up/down
  const [isFixedAndHidden, setIsFixedAndHidden] = useState( false );
  const [isFixedHeader, setIsFixedHeader] = useState( false ); /* fixedHeader - used to manage fixed header */

  // Animation/scroll
  const ticks = useRef( 0 );

  // This effect manages hiding/showing the header with animation
  // as well as setting the required offset for on-page components to
  // justify against if they are position:s ticky
  useEffect(
    utils.composeHeaderDisplayEffect(
      { ref, ticks, isFixedAndHidden, displayPrimaryNavigationLinks, displayOverlay },
      { setIsFixedHeader, setIsFixedAndHidden, setHeaderOffset }
    ),
    [
      ref,
      ticks,
      // Anytime these change we need to re-initialize our event handlers
      // so they have the current state of the header for show/hide animations
      isFixedAndHidden,
      // We need to pass in setters or we may be udpating with a previous render instance's setter
      setIsFixedHeader,
      setIsFixedAndHidden,
      setHeaderOffset,
      // We want to recalculate on resize/overlay
      breakpoint.CURRENT_BREAPOINT,
      displayOverlay
    ]
  );

  const moduleComponents = useMemo( () => {
    // We need to limit the StateWrapper->UtilityLinks call for SM & MD inflections as they are already included in the mobile modules below and this prevents duplicate graph QL calls.
    const filterModules = modules?.filter( module => module.moduleName !== 'StateWrapper' || !isMobile );
    return filterModules?.map( ( module, index ) => (
      <AsyncComponent { ...module }
        key={ index }
      />
    ) );
  }, modules );

  return (
    <TopBarContext.Provider
      value={ {
        showHambugerMenu,
        setShowHambugerMenu,
        showBack,
        setShowBack,
        menuNavigation,
        setMenuNavigation,
        displayValueMessaging,
        displayUtilityNavigation,
        displayPrimaryNavigationLinks,
        displaySearchInput,
        headerTitle,
        displayUserNavigation,
        activePrimaryLinks,
        setActivePrimaryLinks,
        activeNavigationLinksGroup,
        setActiveNavigationLinksGroup,
        keyBoardUser,
        setKeyBoardUser,
        primaryScrollTop,
        setPrimaryScrollTop
      } }
    >

      <header
        className={ classNames(
          TOP_BAR_CLASSNAME, // TOP_BAR_CLASSNAME = 'TopBar'
          { [`${TOP_BAR_CLASSNAME}__fixed`]: isFixedHeader },
          { [`${TOP_BAR_CLASSNAME}__fixedHidden`]: isFixedAndHidden && !activePrimaryLinks },
          { [`${TOP_BAR_CLASSNAME}__PageLoading`]: showLoadingBar }
        ) }
        ref={ ref }
      >
        { skipLinkActions && !isMobile &&
          skipLinkActions.map( ( skipLink, i )=>(
            <Link_Huge
              skipLinks
              likeButtonPrimary
              url={ skipLink.anchorId }
              key={ `skip-link-${i}` }
            >
              { skipLink.label }
            </Link_Huge>
          ) )
        }

        {
          prettyPreviewDate &&
          <div className='TopBar__previewDate'>
            <Text
              textStyle='body-3'
              htmlTag='p'
              textAlign='center'
            >
              Preview Date : { prettyPreviewDate }
            </Text>
          </div>
        }
        <Grid>{ moduleComponents }</Grid>
        { modules && isMobile && <MobileNavContainer modules={ mobileNavContainerModules( { modules } ) } /> }
      </header>

    </TopBarContext.Provider>
  );
} );

/**
  * Manages hiding/showing the header with animation and setting the offset
  *
  * We have 3 states of the header:
  * 1. Displayed at the top of the page when scrollY=0
  * 2. Displayed at the top with position: sticky when scrolling up
  * 3. Displayed at the top of the page but offscreen when scrolling down with translateY(-100%)
  *
  * We can alternate between 2 and 3 by adding a class based on the `isFixedAndHidden` state
  * to support the slide up/down animation
  *
  * @param {object} data - Arguments
  * @param {object} data.ref - DOM ref
  * @param {object} data.ticks - Ticks ref
  * @param {boolean} data.isFixedAndHidden - When true, the header is fixed but hidden off screen
  * @param {boolean} data.displayOverlay - Is overlay open
  * @param {boolean} data.displayPrimaryNavigationLinks - Are primary links enabled
  * @param {object} methods - Methods
  * @param {function} methods.setIsFixedHeader - Sets fixed on scroll
  * @param {function} methods.setIsFixedAndHidden - Sets fixed but hidden for animating slide up/down
  * @param {function} methods.setHeaderOffset - Sets header offset
  * @returns {function} - Teardown function for the useEffect
  */
export const composeHeaderDisplayEffect = ( data, methods ) => () => {
  const { ref = {}, ticks = {}, isFixedAndHidden, displayOverlay, displayPrimaryNavigationLinks } = data || {};
  const { setIsFixedHeader, setIsFixedAndHidden, setHeaderOffset } = methods || {};

  const animationEnabled = !displayOverlay && displayPrimaryNavigationLinks;
  if( isServer() || !setIsFixedHeader || !setIsFixedAndHidden || !setHeaderOffset || !animationEnabled ){
    return;
  }

  let prevScrollpos = global.scrollY;
  let height = ref.current?.offsetHeight;

  const scrollHandler = () => {
    // Fallback for determining the height, sometimes on inital load it's 0
    if( height < 10 ){
      height = ref.current?.offsetHeight;
    }

    const currentScrollPos = global.scrollY;
    const isScrollingUp = prevScrollpos > currentScrollPos;
    const isStationary = currentScrollPos !== 0 && prevScrollpos === currentScrollPos;

    // Fixes some issues with auto scrolling via scrollToElement, when we're jumping
    // directly to an element, previous and current are not correctly reported for the first
    // 2 ticks
    if( ticks.current < 2 ){
      ticks.current = ticks.current + 1;
      setIsFixedAndHidden( currentScrollPos > height );
      return;
    }

    if( isStationary ){
      return;
    }

    // Update previous scroll position
    prevScrollpos = currentScrollPos;

    utils.commitHeaderStateChange(
      { currentScrollPos, isFixedAndHidden, isScrollingUp, height: ref.current.offsetHeight },
      { setIsFixedHeader, setIsFixedAndHidden, setHeaderOffset }
    );
  };

  global.addEventListener( 'scroll', scrollHandler );

  return () => {
    global.removeEventListener( 'scroll', scrollHandler );
  };
};

/**
  * Commits display state changes to the header.
  *
  * @param {object} data - Arguments
  * @param {number} data.currentScrollPos - Current scroll Y
  * @param {boolean} data.isFixedAndHidden - Is header fixed and hidden
  * @param {boolean} data.isScrollingUp - Is user scroll direction upwards
  * @param {boolean} data.isBelowHeader - Is current scroll Y below the header height
  * @param {number} data.height - Current header height
  * @param {object} methods - Arguments
  * @param {function} methods.setIsFixedHeader - setIsFixedHeader setter
  * @param {function} methods.setIsFixedAndHidden - setIsFixedAndHidden setter
  * @param {function} methods.setHeaderOffset - setHeaderOffset setter from Page provider
  */
export const commitHeaderStateChange = ( data, methods ) => {
  const { currentScrollPos = 0, isFixedAndHidden, isScrollingUp, height = 0 } = data || {};
  const { setIsFixedHeader, setIsFixedAndHidden, setHeaderOffset } = methods || {};

  if( !setIsFixedHeader || !setIsFixedAndHidden || !setHeaderOffset ){
    return;
  }

  const isBelowHeader = currentScrollPos > height;

  // Scrolling up and at the top
  if( !isBelowHeader ){
    setIsFixedHeader( false );
    setIsFixedAndHidden( false );
    setHeaderOffset( 0 );
  }
  // Scrolling up in the middle of the page, setting to fixedAndHidden triggers slide up
  else if( isFixedAndHidden && isScrollingUp && isBelowHeader ){
    setIsFixedHeader( true );
    setIsFixedAndHidden( false );
    setHeaderOffset( height );
  }
  // Scrolling down, should be fixed and hidden
  else if( !isFixedAndHidden && !isScrollingUp && isBelowHeader ){
    setIsFixedAndHidden( true );
    setHeaderOffset( 0 );
  }
};

export const TOP_BAR_CLASSNAME = 'TopBar';

export const TopBarContext = createContext( {
  bagCount: 0
} );

/**
  * @const {object} NAVIGATION_MODULES - Map of modules used by TopBar/Navigation
  */
export const NAVIGATION_MODULES = {
  UtilityLinks: 'UtilityLinks',
  StateWrapper: 'StateWrapper',
  PrimaryBar: 'PrimaryBar',
  PrimaryLinks: 'PrimaryLinks',
  NavigationLinksSubGroup: 'NavigationLinksSubGroup',
  ImageNavigation: 'ImageNavigation',
  Primary: 'primary',
  NavigationOverlayFeaturedContent: 'NavigationOverlayFeaturedContent',
  VerticalSlot: 'VerticalSlot',
  Avatar: 'Avatar'
};

/**
  * Gets TopBar height
  * @returns {number} TopBar height
  */
export const getTopBarHeight = () => {
  const topBar = document.querySelector( `.${TOP_BAR_CLASSNAME}` );
  return topBar?.offsetHeight || 0;
};

/**
  * Gets TopBar Current Position
  * @returns {number} TopBar height
  */
export const getTopBarCurrentPosition = () => {
  const topBarReact = document.querySelector( `.${TOP_BAR_CLASSNAME}` )?.getBoundingClientRect();
  const topPosition = topBarReact?.height + topBarReact?.top;
  return topPosition;
};

/**
  * Gets previewDate from cookie and return formatted previewDate
  * @returns {String} Preview Date
  */
export const getPreviewDate = ( data )=>{
  const { previewDate } = data || {};
  if( !previewDate ){
    return null;
  }

  // Converting previewDate in "day-month-year hour:minute:second" formate
  const year = previewDate?.substring( 4, 8 );
  const month = previewDate?.substring( 2, 4 );
  const day = previewDate?.substring( 0, 2 );
  const hour = previewDate?.substring( 9, 11 );
  const minute = previewDate?.substring( 12, 14 );
  const second = previewDate?.substring( 15, 17 );

  if( !year || !month || !day ){
    return null;
  }

  const offsetDate = new Date( `${year}-${day}-${month}T${hour}:${minute}:${second}Z` );
  offsetDate.setMinutes( offsetDate.getMinutes() + offsetDate.getTimezoneOffset() );

  return offsetDate.toLocaleString( 'en-US', { dateStyle:'full', timeStyle:'medium' } );
};

/**
 * Property type definitions
 * @typedef TopBarProps
 * @type {object}
 * @property {array} modules - sets the modules
 * @property {boolean} displayValueMessaging - show/hide displayValueMessaging based on true/false
 * @property {boolean} displayUtilityNavigation - show/hide utilityLinks based on true/false
 * @property {boolean} displayPrimaryNavigationLinks - show/hide primaryLinks based on true/false
 * @property {boolean} displaySearchInput - show/hide search based on true/false
 * @property {boolean} headerTitle - checkout text for simplified header
 * @property {boolean} displayUserNavigation - show/hide displayUserNavigation based on true/false
 * @property {array} skipLinkActions - No of skip links to be in page and details
 */
const propTypes = {
  modules: PropTypes.array,
  displayValueMessaging: PropTypes.bool,
  displayUtilityNavigation: PropTypes.bool,
  displayPrimaryNavigationLinks: PropTypes.bool,
  displaySearchInput: PropTypes.bool,
  headerTitle: PropTypes.string,
  displayUserNavigation: PropTypes.bool,
  skipLinkActions:PropTypes.array

};

TopBar.propTypes = propTypes;
TopBar.displayName = 'TopBar';

export default TopBar;

export const useTopBar = () => useContext( TopBarContext );