import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function SVG( props ){

  return (
    <SvgContainer
      { ...props }
      width='49px'
      height='52px'
      viewBox='0 0 49 52'
      version='1.1'
    >
      <g id='Icons'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g transform='translate(-569.000000, -1923.000000)'
          id='ShippingBoxStyled_icon'
        >
          <g transform='translate(570.000000, 1924.000000)'>
            <path d='M28.242,50.6581875 C27.453777,51.0837045 26.5322518,51.1133345 25.722,50.7392135 L4.824,41.1036031 C3.71783595,40.5943927 3,39.420853 3,38.1218468 L3,16.1184299 C3,14.8595065 3.67509072,13.7145727 4.731,13.1820482 L24.324,3.30335988 C25.0748353,2.92532557 25.9369404,2.89951929 26.706,3.23205702 L46.113,11.6198671 C47.2519514,12.1114513 47.9992237,13.3025267 48,14.6275518 L48,37.9792411 C47.9993934,39.2065116 47.3571882,40.3281406 46.341,40.8767304 L28.242,50.6581875 Z'
              id='Path'
              fillOpacity='0.25'
              fill='#FFCCD8'
              fillRule='nonzero'
            ></path>
            <g id='streamline-icon-shipment-in-transit@140x140'
              strokeLinecap='round'
              strokeLinejoin='round'
              stroke='#F26B1C'
            >
              <line x1='24'
                y1='20.7770061'
                x2='23.997'
                y2='20.7770061'
                id='Path'
              ></line>
              <path d='M25.242,47.6581875 C24.453777,48.0837045 23.5322519,48.1133345 22.722,47.7392135 L1.824,38.1036032 C0.717835952,37.5943928 0,36.4208529 0,35.1218468 L0,13.1184299 C0,11.8595065 0.675090722,10.7145728 1.731,10.1820481 L21.324,0.303359883 C22.0748354,-0.0746744254 22.9369405,-0.100480708 23.706,0.232057014 L43.113,8.61986716 C44.2519515,9.11145127 44.9992237,10.3025267 45,11.6275518 L45,34.9792411 C44.9993934,36.2065116 44.3571882,37.3281405 43.3410001,37.8767304 L25.242,47.6581875 Z'
                id='Path'
              ></path>
              <line x1='24'
                y1='20.7770061'
                x2='44.436'
                y2='9.73802577'
                id='Path'
              ></line>
              <polyline id='Path'
                points='0.534000001 11.2710375 23.997 20.7770061 23.997 47.9920145'
              ></polyline>
              <line x1='33.693'
                y1='15.5394864'
                x2='11.541'
                y2='5.23622195'
                id='Path'
              ></line>
            </g>
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}

export default SVG;
