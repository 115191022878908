import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24X( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <g
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M1 1l22 22M23 1L1 23' />
      </g>
    </SvgContainer>
  );
}

export default Svg24X;
