import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12BagIcon( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <path d='M 4.734375 2.699219 L 7.265625 2.699219 C 7.304688 2.574219 7.324219 2.441406 7.324219 2.308594 C 7.324219 1.578125 6.730469 0.984375 6 0.984375 C 5.269531 0.984375 4.675781 1.578125 4.675781 2.308594 C 4.675781 2.441406 4.695312 2.574219 4.734375 2.699219 Z M 0.5 3.03125 C 0.515625 2.859375 0.648438 2.722656 0.816406 2.699219 L 0.867188 2.695312 L 3.980469 2.699219 C 3.953125 2.570312 3.941406 2.441406 3.941406 2.308594 C 3.941406 1.171875 4.863281 0.25 6 0.25 C 7.136719 0.25 8.058594 1.171875 8.058594 2.308594 C 8.058594 2.441406 8.042969 2.570312 8.019531 2.699219 L 11.136719 2.695312 L 11.183594 2.699219 C 11.351562 2.722656 11.484375 2.859375 11.5 3.03125 L 10.765625 11.398438 C 10.757812 11.59375 10.597656 11.75 10.402344 11.75 L 1.597656 11.75 C 1.398438 11.75 1.242188 11.59375 1.234375 11.398438 Z M 10 11.015625 L 10.738281 3.429688 L 1.261719 3.429688 L 2 11.015625 Z M 10 11.015625 '
        fill='#000000'
        fillRule='nonzero'
      ></path>
    </SvgContainer>
  );
}

export default Svg12BagIcon;
