import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16ArrowUp( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <g
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M8.167 1.167v14M15.167 7.667l-7.023-7-6.977 7' />
      </g>
    </SvgContainer>
  );
}

export default Svg16ArrowUp;
