import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24NoSameDayDelivery( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
      viewBox='0 0 24 24'
    >
      <svg width='25'
        height='24'
        viewBox='0 0 25 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path fillRule='evenodd'
          clipRule='evenodd'
          d='M23.8049 0.47012C24.0984 0.763615 24.0984 1.23947 23.8049 1.53296L1.75977 23.5782C1.46628 23.8717 0.990428 23.8717 0.696932 23.5782C0.403435 23.2847 0.403434 22.8089 0.696929 22.5154L22.7421 0.470123C23.0356 0.176627 23.5114 0.176625 23.8049 0.47012ZM12.251 0.693134L17.5403 2.8089C17.9256 2.96305 18.1131 3.40043 17.9589 3.78581C17.8048 4.17119 17.3674 4.35863 16.982 4.20447L12.251 2.31201L9.73995 3.3164L14.544 5.3181C14.9272 5.47774 15.1083 5.91775 14.9487 6.30088C14.7891 6.68402 14.349 6.8652 13.9659 6.70556L7.95357 4.20042C7.89033 4.17407 7.8326 4.14009 7.78099 4.09998L4.11411 5.56671L11.0678 8.83902C11.4433 9.01575 11.6045 9.46348 11.4278 9.83903C11.251 10.2146 10.8033 10.3758 10.4278 10.199L2.98182 6.69507V9.018C2.98182 9.43307 2.64534 9.76954 2.23028 9.76954C1.81521 9.76954 1.47873 9.43307 1.47873 9.018V5.00198L12.251 0.693134ZM22.7726 6.26234C23.1876 6.26234 23.5241 6.59882 23.5241 7.01389V19.0792L12.2362 22.8419L7.97887 21.2453C7.59023 21.0996 7.39332 20.6664 7.53906 20.2778C7.6848 19.8891 8.118 19.6922 8.50664 19.838L11.4994 20.9603V17.5355C11.4994 17.1204 11.8359 16.7839 12.251 16.7839C12.666 16.7839 13.0025 17.1204 13.0025 17.5355V21.002L22.021 17.9959V7.01389C22.021 6.59882 22.3575 6.26234 22.7726 6.26234ZM0.469516 10.7883C0.6178 10.4006 1.05228 10.2065 1.43995 10.3548L6.48009 12.2826C6.86776 12.4309 7.06183 12.8654 6.91354 13.2531C6.76526 13.6408 6.33078 13.8348 5.9431 13.6865L0.902972 11.7587C0.515298 11.6104 0.321233 11.1759 0.469516 10.7883ZM1.0311 14.7884C1.17938 14.4008 1.61386 14.2067 2.00154 14.355L4.52161 15.3189C4.90928 15.4672 5.10334 15.9017 4.95506 16.2893C4.80678 16.677 4.3723 16.8711 3.98462 16.7228L1.46456 15.7589C1.07688 15.6106 0.882817 15.1761 1.0311 14.7884ZM0.0995154 17.7521C0.279481 17.3781 0.728577 17.2208 1.1026 17.4007L2.11215 17.8865C2.48617 18.0665 2.64348 18.5156 2.46351 18.8896C2.28355 19.2636 1.83445 19.4209 1.46043 19.241L0.450883 18.7552C0.0768621 18.5752 -0.0804506 18.1261 0.0995154 17.7521Z'
          fill='black'
        />
      </svg>


    </SvgContainer>
  );
}

export default Svg24NoSameDayDelivery;