/**
 * This component is used to map to the ProductRail component to hightlight specific product
 *
 * @module views/components/ProductHighlighter
 * @memberof -Common
 */
import './ProductHighlighter.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Image from '@ulta/core/components/Image/Image';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';

import ProductPricing from '@ulta/modules/ProductPricing/ProductPricing';

import ReviewStarsCard from '../ReviewStarsCard/ReviewStarsCard';

/**
 * Represents a ProductHighlighter component
 *
 * @method
 * @param {ProductHighlighterProps} props - React properties passed from composition
 * @returns ProductHighlighter
 */
export const ProductHighlighter = function( props ){
  let { type, size, componentKey } = props;
  return (
    <div className='ProductHighlighter'>
      <Link_Huge
        secondary={ true }
        action={ props.action }
      >
        <div className={ classNames( 'ProductHighlighter__image', {
          [`ProductHighlighter__image__${type}__${size}`]: size && type
        } ) }
        >
          <Image
            src={ props.image?.imageUrl }
            alt={ props.productName }
          />
          { props.badge &&
            <div className='ProductHighlighter__badge'>
              <Text
                textStyle='body-3'
              >
                { props.badge }
              </Text>
            </div>
          }
          { props.variantLabel &&
            <span className='ProductHighlighter__variant'>
              <Text
                textStyle='body-3'
                color='neutral-600'
              >
                { props.variantLabel }
              </Text>
            </span>
          }
          { props.displayBookmark &&
            <div className='ProductHighlighter__favorite'>
              <Button
                icon
                iconSize={ size === 'xLarge' ? 'l' : 'm' }
                iconImage='HeartEmpty'
                likeLink
                ariaLabel={ props.ariaLabelBookmark }
              />
            </div>
          }
          { props.displayAddToBag &&
            <div className='ProductHighlighter__addToBag'>
              <Button icon
                compact
                iconImage='Bag'
                hiddenLabel={ props.addToBagLabel }
                iconSize={ size === 'xLarge' ? 'l' : 'm' }
              />
            </div>
          }
        </div>
        {
          props.spotlight &&
          <div className='ProductHighlighter__highlightedContent'>
            <div className='ProductHighlighter__verticalLine'>
              <Image
                src={ props.spotlight.graphicElement.graphicImage.imageUrl }
                width={ setImageParams( type, size ) }
                aspectRatio={ '2:1' }
              />
            </div>
            <div className='ProductHighlighter__eyebrow'>
              <Text
                htmlTag='p'
                textStyle='eyebrow'
                textAlign='center'
              >
                { props.spotlight.eyebrow }
              </Text>
            </div>
            <div className='ProductHighlighter__title'>
              <Text
                htmlTag='p'
                textStyle='title-6'
                textAlign='center'
              >
                { props.spotlight.title }
              </Text>
            </div>
            <div className='ProductHighlighter__subtitle'>
              <Text
                htmlTag='p'
                textStyle='subtitle-2'
                textAlign='center'
              >
                { props.spotlight.subtitle }
              </Text>
            </div>
          </div>
        }
        <div className='ProductHighlighter__content'>
          { props.brandName &&
            <div className='ProductHighlighter__brand'>
              <Text
                textStyle='body-3'
                color='neutral-600'
              >
                { props.brandName }
              </Text>
            </div>
          }
          { props.productName &&
            <div className='ProductHighlighter__product'>
              <Text
                textStyle='body-3'
              >
                { props.productName }
              </Text>
            </div>
          }
          { !!props.reviewCount && !!props.rating &&
            <div className='ProductHighlighter__rating'>
              <ReviewStarsCard
                reviews={ props.reviewCount }
                rating={ props.rating }
                componentKey={ componentKey }
              />
            </div>
          }
          { props.listPrice &&
            <div className='ProductHighlighter__price'>
              <ProductPricing
                listPrice={ props.listPrice }
                salePrice={ props.salePrice }
                kitPrice={ props.kitPrice }
                compact
              />
            </div>
          }
          { props.promoText &&
            <div className='ProductHighlighter__offers'>
              <div>
                <Text
                  textStyle='body-3'
                  color='magenta-500'
                >
                  { props.promoText }
                </Text>
              </div>
              { props.additionalOffersText &&
                <div>
                  <Text htmlTag='span'
                    textStyle='body-3'
                    color='magenta-500'
                  >
                    { props.additionalOffersText }
                  </Text>
                </div>
              }
            </div>
          }
        </div>
      </Link_Huge>
    </div>
  );
};

/**
 * Function to return image params as per type and size.
 *
 * @method setImageParams
 * @param {string} type - This is the type of Product Card, i.e full/regular/compact.
 * @param {string} size - This is the size of the Product Card, i.e small/xLarge.
 * @returns {string} Returns string consisting width and height as per type and size
 **/

export const setImageParams = ( type, size ) => {
  const cardTypeSize = `${type}_${size}`;

  switch ( cardTypeSize.toLocaleLowerCase() ){
    case 'compact_small':
      return 100;

    case 'full_small':
    case 'regular_xlarge':
    case 'compact_xlarge':
      return 200;

    case 'full_xlarge':
      return 300;

    case 'regular_small':
      return 133;

    default:
      return 200; // since type full and size small are the default props values
  }
};

/**
 * Function to determine if the Product Card is both 'compact' and 'small'.
 *
 * @method isCompactAndSmall
 * @param {string} type - This is the type of Product Card, i.e full/regular/compact.
 * @param {string} size - This is the size of the Product Card, i.e small/xLarge.
 * @returns {boolean} Returns true if the Product Card is both 'compact' and 'small'.
 **/

export const isCompactAndSmall = ( type, size ) => {
  return type === 'compact' && size === 'small';
};

/**
 * Property type definitions
 *
 * @typedef ProductHighlighterProps
 * @type {object}
 * @property {string} type - type
 * @property {string} size - size
 * @property {object} action - action
 * @property {string} image - Image Url
 * @property {productName} productName - Product Name
 * @property {badge} badge - Badge
 * @property {variantLabel} variantLabel - Variant Label
 * @property {displayBookmark} displayBookmark - Display Bookmark
 * @property {ariaLabelBookmark} ariaLabelBookmark - Aria Label Bookmark
 * @property {displayAddToBag} displayAddToBag - Display Add to Bag
 * @property {addToBagLabel} addToBagLabel - Add to Bag Label
 * @property {verticalLine} verticalLine - Vertical Line
 * @property {title} title - Title
 * @property {subtitle} subtitle - Subtitle
 * @property {description} description - Description
 * @property {brandName} brandName - Brand Name
 * @property {number} rating - Rating
 * @property {listPrice} listPrice - List Price
 * @property {salePrice} salePrice - Sale Price
 * @property {kitPrice} kitPrice - Kit Price
 * @property {promoText} promoText - Promo Text
 * @property {additionalOffersText} additionalOffersText - Additional Offers Text
 * @property {string} componentKey - Sets the component key
 */
export const propTypes = {
  /** This is the type of Product card going forward PC (currently one of 'full', 'regular', 'compact') */
  type: PropTypes.oneOf( [
    'full',
    'regular',
    'compact'
  ] ),
  /** This is the type of size of PC (currently one of 'small', 'xLarge') */
  size: PropTypes.oneOf( [
    'small',
    'xLarge'
  ] ),
  action: PropTypes.shape( {
    label: PropTypes.string,
    url: PropTypes.string
  } ),
  image: PropTypes.object,
  productName: PropTypes.string,
  badge: PropTypes.string,
  variantLabel: PropTypes.string,
  displayBookmark: PropTypes.bool,
  ariaLabelBookmark: PropTypes.string,
  displayAddToBag: PropTypes.bool,
  addToBagLabel: PropTypes.string,
  verticalLine: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  brandName: PropTypes.string,
  rating: PropTypes.number,
  listPrice: PropTypes.string,
  salePrice: PropTypes.string,
  kitPrice: PropTypes.string,
  promoText: PropTypes.string,
  additionalOffersText: PropTypes.string,
  componentKey: PropTypes.number
};

/**
 * Default values for passed properties
 * @type {object}
 * @property {string} type='full' - The product card type, default is full.
 * @property {string} size='small' - The product card size, default is small.
 * @property {boolean} displayBookmark=false - Flag to specify if the display bookmark is available, default is false
 * @property {boolean} displayAddToBag=false - Flag to specify if the display addToBag button is available, default is false
 */
export const defaultProps = {
  type: 'full',
  size: 'small',
  displayBookmark: false,
  displayAddToBag: false
};

ProductHighlighter.propTypes = propTypes;
ProductHighlighter.defaultProps = defaultProps;

export default ProductHighlighter;
