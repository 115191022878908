/**
  * Utilities for retrieving the application's or user's browser locale setting, and/or converting its format
  * @module utils/locale
  * @memberof -Common
  */

export const defaultLocale = 'en-US';

/**
 * Returns an application locale taking the status of SSR into consideration
 *
 * @method getApplicationLocale
 * @param {string} locale - A defined locale that can be optionally passed
 * @param {string} isServer - Boolean defining whether it's SSR running
 */
export const getApplicationLocale = function( locale, isServer ){
  if( isServer ){
    return locale || defaultLocale;
  }
  else {
    return locale || browserLocale( navigator.languages ) || defaultLocale;
  }
};

/**
 * local method that returns the first five-character locale defined in browser settings, or null
 * browser languages are user-editable and can be only two-character language codes
 *
 * NOTE: Although this method is exported, getApplicationLocale should always be used
 *
 * @method browserLocale
 * @param {array} data - The browser's navigator.languages array passed
 */
export const browserLocale = function( data ){
  for ( let i = 0; i < data.length; i++ ){
    if( data[i].length === 5 ){
      return data[i];
    }
  }
  return null;
};

/**
 * @method formatLocale
  * @summary Converts BCP 47 Language Tags (en-US) to ISO 15897 format (en_US)
  * @param {object} data - BCP 47 language code
  * @returns {string}
  */
export const formatLocale = ( data ) => {
  return data.replace( '-', '_' );
};
