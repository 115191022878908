import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24HamBurger( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <path d='M1 21a1 1 0 0 0-.234 1.97l.115.023L1 23h13a1 1 0 0 0 .234-1.97l-.115-.023L14 21H1Zm0-10a1 1 0 0 0-.234 1.97l.115.023L1 13h18a1 1 0 0 0 .234-1.97l-.115-.023L19 11H1ZM1 1a1 1 0 0 0-.234 1.97l.115.023L1 3h22a1 1 0 0 0 .234-1.97l-.115-.023L23 1H1Z'
        fill='#000'
        fillRule='nonzero'
      />
    </SvgContainer>
  );
}

export default Svg24HamBurger;
