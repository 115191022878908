import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12DotActive( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <circle
        stroke='currentColor'
        fill='currentColor'
        fillRule='nonzero'
        cx={ 6 }
        cy={ 6 }
        r={ 4 }
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg12DotActive;
