import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12Night( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <path
        d='M5.774 6c0-2.326 1.532-4.326 3.726-5.214A7.42 7.42 0 008.776.75C5.309.75 2.5 3.1 2.5 6s2.81 5.25 6.276 5.25c.245 0 .487-.013.724-.036C7.306 10.326 5.774 8.326 5.774 6h0z'
        stroke='currentColor'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg12Night;
