import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12Add( props ){
  // const { hidden = 'false', title, description, role, focusable } = props;
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <path
        d='M.5 6h11M6 .5v11'
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg12Add;
