import React from 'react';

import Text from '@ulta/core/components/Text/Text';

// set unmatched text bold and retun new string if searchString available
export const highlightUnmatchedText = ( data ) => {
  const { label, searchString, highlightColor = 'neutral-700' } = data || {};
  if( !searchString || !label ){
    return label;
  }
  const idx = label?.toLowerCase().indexOf( searchString?.toLowerCase() );

  if( idx === -1 ){
    return label;
  }

  const startStr = label.slice( 0, idx );
  const endStr = label.slice( idx + searchString.length, label.length );
  const searchedStr = label.slice( idx, idx + searchString.length );

  const boldStr = ( str ) =>{
    return (
      <Text htmlTag='span'
        textStyle='body-1'
        color={ highlightColor }
        fontWeight='bold'
      >
        { str }
      </Text>
    );
  };

  return (
    <>
      { boldStr( startStr ) }
      { searchedStr }
      { boldStr( endStr ) }
    </>
  );
};