import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16QuestionCircle( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
      viewBox='0 0 24 24'
    >
      <path
        d='M12 .25a11.75 11.75 0 1 1 0 23.5 11.75 11.75 0 0 1 0-23.5Zm0 1.5a10.25 10.25 0 1 0 0 20.5 10.25 10.25 0 0 0 0-20.5Zm0 15.15h.12a1 1 0 1 1-.24 0H12Zm3.61-7.91c.5 1.8-.4 3.7-2.11 4.45-.41.18-.7.57-.74 1.01l-.01.14V15a.75.75 0 0 1-1.5.1v-.51c0-1.03.57-1.97 1.48-2.44l.17-.08a2.25 2.25 0 1 0-3.14-2.22l-.01.16a.75.75 0 0 1-1.5 0 3.75 3.75 0 0 1 7.36-1.02Z'
        fill='currentColor'
        fillRule='nonzero'
      />
    </SvgContainer>
  );
}

export default Svg16QuestionCircle;
