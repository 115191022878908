import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16DotInactive( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <circle
        stroke='currentColor'
        transform='rotate(-180 8 8)'
        cx={ 8 }
        cy={ 8 }
        r={ 6 }
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg16DotInactive;
