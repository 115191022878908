import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16Filters( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <g
        transform='translate(0 1)'
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <circle cx={ 10.5 }
          cy={ 3 }
          r={ 2.5 }
        />
        <circle cx={ 6.5 }
          cy={ 11 }
          r={ 2.5 }
        />
        <path d='M.5 3H8M.5 11h3M9 11h6.5M13 3h2.5' />
      </g>
    </SvgContainer>
  );
}

export default Svg16Filters;
