import React from 'react';

const SvgContainer = ( props )=>{
  const { title, description, children, ...svgProps } = props;
  const titleSanitized = title?.replace( /\s/g, '_' );
  return (
    <svg
      { ...( title && { 'role':'img' } ) }
      { ...( title && { 'aria-labelledby':`${titleSanitized}-svg` } ) }
      { ...svgProps }
    >
      { title && <title id={ `${titleSanitized}-svg` }>{ title }</title> }
      { description && <desc>{ description }</desc> }
      { children }

    </svg>
  );
};
export default SvgContainer;