import * as utils from './devMode';

/**
 * Utility for printing objects that should only be displayed in dev mode.
 *
 * @example
 * // prints a string
 * devLogger( '[User] Session confirmed'  )
 *
 * @example
 * // prints an object in a group, collapsed or open
 * devLogger({ title: 'User', value: myObject, collapsed: true })
 *
 * @param {object} data - Log input
 * @param {string} data.topic - Log topic
 * @param {string} data.title - Log title
 * @param {object} data.value - Log value, should be an object
 * @param {number} level - Log level
 * @param {string} level - Log level
 */
export const devLogger = ( data, level, topic ) => {
  const hasTopic = data?.topic || topic;
  const debugTopic = hasTopic && utils.logTopic( { topic: hasTopic } );
  const shouldLog = hasTopic ? debugTopic : utils.isDevMode();

  if( !shouldLog ){
    return;
  }

  if( typeof data === 'string' ){
    const styles = [
      'background: #fdf1f3',
      'padding: 4px',
      'border-radius: 4px',
      'font-weight: bold'
    ].join( ';' );

    // eslint-disable-next-line no-console
    console.log( '%c%s', level > 0 && styles, data );
  }
  else {
    utils.objectLogger( data );
  }
};

export const debugModuleName = ( props ) => {
  const { moduleName, modules } = props || {};

  if( moduleName === 'StateWrapper' ){
    return `StateWrapper > ${modules?.[0]?.moduleName}`;
  }

  return moduleName;
};

/**
 * Logging util, crawls objects for output
 * @param {string} title Title of log section
 * @param {object} obj Object to log
 */
export const objectLogger = ( data ) => {
  if( !utils.isDevMode() ){
    return;
  }

  const { collapsed = true } = data || {};

  const guess = { ...data };
  delete guess.title;
  delete guess.collapsed;

  const title = data?.title || Object.keys( guess )?.[0];
  const value = data?.value || Object.values( guess )?.[0];

  if( !title || !value ){
    return;
  }

  if( typeof value !== 'object' || value === null ){
    // eslint-disable-next-line no-console
    console.log( `${title}:`, value );
    return;
  }

  // eslint-disable-next-line no-console
  collapsed ? console.groupCollapsed( title ) : console.group( title );

  Object.entries( value ).forEach( ( [k, v] ) => {
    if( typeof v === 'object' && v !== null ){
      utils.objectLogger( { title: k, value: v, collapsed } );
    }
    else {
      // eslint-disable-next-line no-console
      console.log( `${k}:`, v );
    }
  } );
  // eslint-disable-next-line no-console
  console.groupEnd();
};

/**
 * @returns {boolean} Allows logging / dev mode activity to be conditionally enabled
 */
export const isDevMode = () => process.env.NODE_ENV === 'development' || global.location?.search?.includes( ENABLE_LOG_PARAM );

/**
 * Checks if a topic is enabled for logging
 *
 * @param {object} data - arguments
 * @param {string} data.topic - topic to check
 * @returns {boolean}
 */
export const logTopic = ( data ) => {
  const { topic } = data || {};

  if( !topic ){
    return false;
  }

  const globalLocation = global?.location || {};
  const search = globalLocation?.search;
  const isAll = search?.includes( `log=${LOG_TOPIC.All}` ) && LOG_TOPIC_ALL.includes( topic );

  if( isAll ){
    return true;
  }

  return search?.includes( `log=${topic}` );
};

export const LOG_TOPIC = {
  All: 'all',
  DXL: 'dxl',
  RequestStack: 'requestStack',
  InitAction: 'initAction',
  Session: 'session',
  ThirdParty: 'thirdParty',
  Broadcast: 'broadcast',
  Cache:'cache'
};

export const LOG_TOPIC_ALL = [
  LOG_TOPIC.DXL,
  LOG_TOPIC.InitAction,
  LOG_TOPIC.Session,
  LOG_TOPIC.ThirdParty
];

/**
 * @const {string} ENABLE_LOG_PARAM - URL param to enable logging
 */
export const ENABLE_LOG_PARAM = 'log=';
