/**
 * Feature content for tier II navigation section
 *
 * @module views/components/NavigationOverlayFeaturedContent
 * @memberof -Common
 */
import './NavigationOverlayFeaturedContent.scss';

import React from 'react';

import PropTypes from 'prop-types';

import AsyncComponent from '@ulta/core/components/AsyncComponent/AsyncComponent';

/**
 * Represents a NavigationOverlayFeaturedContent component
 *
 * @method
 * @param {NavigationOverlayFeaturedContentProps} props - React properties passed from composition
 * @returns NavigationOverlayFeaturedContent
 */
export const NavigationOverlayFeaturedContent = React.forwardRef( ( props, _ ) => {
  return (
    <div className='NavigationOverlayFeaturedContent'
      role='region'
      aria-label='promotion'
    >
      {
        props.modules?.map( ( i, index ) => {
          return (
            <AsyncComponent
              key={ `${i.id}:${index}` }
              { ...i }
            />
          );
        } )
      }
    </div>
  );
} );

/**
   * Property type definitions
   *
   * @typedef NavigationOverlayFeaturedContentProps
   * @type {object}
   * @property {array} modules - Sets the footerItems to the Footer
   */
export const propTypes = {
  modules: PropTypes.array.isRequired
};

NavigationOverlayFeaturedContent.propTypes = propTypes;
NavigationOverlayFeaturedContent.displayName = 'NavigationOverlayFeaturedContent';

export default NavigationOverlayFeaturedContent;
