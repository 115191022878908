import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12CaretDown( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <path
        d='M11.063 3L6 8.625.937 3'
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg12CaretDown;
