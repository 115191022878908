import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16Community( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <path fillRule='evenodd'
        clipRule='evenodd'
        d='M6.23223 2.06555C6.70107 1.59671 7.33696 1.33331 8 1.33331C8.66304 1.33331 9.29893 1.59671 9.76777 2.06555C10.2366 2.53439 10.5 3.17027 10.5 3.83331C10.5 4.49635 10.2366 5.13224 9.76777 5.60108C9.29893 6.06992 8.66304 6.33331 8 6.33331C7.33696 6.33331 6.70107 6.06992 6.23223 5.60108C5.76339 5.13224 5.5 4.49635 5.5 3.83331C5.5 3.17027 5.76339 2.53439 6.23223 2.06555ZM8 2.33331C7.60218 2.33331 7.22064 2.49135 6.93934 2.77265C6.65804 3.05396 6.5 3.43549 6.5 3.83331C6.5 4.23114 6.65804 4.61267 6.93934 4.89397C7.22064 5.17528 7.60218 5.33331 8 5.33331C8.39782 5.33331 8.77936 5.17528 9.06066 4.89397C9.34197 4.61267 9.5 4.23114 9.5 3.83331C9.5 3.43549 9.34197 3.05396 9.06066 2.77265C8.77936 2.49135 8.39783 2.33331 8 2.33331Z'
        fill='black'
      />
      <path fillRule='evenodd'
        clipRule='evenodd'
        d='M2.03697 3.37028C2.38079 3.02647 2.8471 2.83331 3.33333 2.83331C3.81956 2.83331 4.28588 3.02647 4.6297 3.37028C4.97351 3.7141 5.16667 4.18042 5.16667 4.66665C5.16667 5.15288 4.97351 5.61919 4.6297 5.96301C4.28588 6.30683 3.81956 6.49998 3.33333 6.49998C2.8471 6.49998 2.38079 6.30683 2.03697 5.96301C1.69315 5.61919 1.5 5.15288 1.5 4.66665C1.5 4.18042 1.69315 3.7141 2.03697 3.37028ZM3.33333 3.83331C3.11232 3.83331 2.90036 3.92111 2.74408 4.07739C2.5878 4.23367 2.5 4.44563 2.5 4.66665C2.5 4.88766 2.5878 5.09962 2.74408 5.2559C2.90036 5.41218 3.11232 5.49998 3.33333 5.49998C3.55435 5.49998 3.76631 5.41218 3.92259 5.2559C4.07887 5.09962 4.16667 4.88766 4.16667 4.66665C4.16667 4.44563 4.07887 4.23367 3.92259 4.07739C3.76631 3.92111 3.55435 3.83331 3.33333 3.83331Z'
        fill='black'
      />
      <path fillRule='evenodd'
        clipRule='evenodd'
        d='M11.3703 3.37028C11.7142 3.02647 12.1805 2.83331 12.6667 2.83331C13.1529 2.83331 13.6193 3.02647 13.9631 3.37028C14.3069 3.7141 14.5 4.18042 14.5 4.66665C14.5 5.15288 14.3069 5.61919 13.9631 5.96301C13.6193 6.30683 13.1529 6.49998 12.6667 6.49998C12.1805 6.49998 11.7142 6.30683 11.3703 5.96301C11.0265 5.61919 10.8334 5.15288 10.8334 4.66665C10.8334 4.18042 11.0265 3.7141 11.3703 3.37028ZM12.6667 3.83331C12.4457 3.83331 12.2337 3.92111 12.0775 4.07739C11.9212 4.23367 11.8334 4.44563 11.8334 4.66665C11.8334 4.88766 11.9212 5.09962 12.0775 5.2559C12.2337 5.41218 12.4457 5.49998 12.6667 5.49998C12.8877 5.49998 13.0997 5.41218 13.256 5.2559C13.4122 5.09962 13.5 4.88766 13.5 4.66665C13.5 4.44563 13.4122 4.23367 13.256 4.07739C13.0997 3.92111 12.8877 3.83331 12.6667 3.83331Z'
        fill='black'
      />
      <path fillRule='evenodd'
        clipRule='evenodd'
        d='M3.33337 7.83331C2.93356 7.83331 2.55135 7.99024 2.27051 8.26761C1.98988 8.54478 1.83337 8.91936 1.83337 9.30862V11.9782C1.83337 12.2411 1.96479 12.4867 2.18357 12.6325C2.87983 13.0967 3.78691 13.0967 4.48318 12.6325L4.83337 12.3991V9.30862C4.83337 8.91936 4.67687 8.54478 4.39624 8.26761C4.1154 7.99024 3.73319 7.83331 3.33337 7.83331ZM1.56781 7.55612C2.03711 7.09261 2.67232 6.83331 3.33337 6.83331C3.99442 6.83331 4.62963 7.09261 5.09894 7.55612C5.56845 8.01984 5.61072 8.65011 5.61072 9.30862V12.6325C5.61072 12.7997 5.47247 13.2406 5.33337 13.3333L5.03788 13.4646C4.00571 14.1527 2.66103 14.1527 1.62887 13.4646C1.13189 13.1332 0.833374 12.5755 0.833374 11.9782V9.30862C0.833374 8.65011 1.09829 8.01984 1.56781 7.55612Z'
        fill='black'
      />
      <path fillRule='evenodd'
        clipRule='evenodd'
        d='M12.6666 7.69271C12.2401 7.69271 11.8325 7.85372 11.5329 8.13831C11.2336 8.42269 11.0666 8.80702 11.0666 9.20641V12.2857C11.0666 12.3429 11.0963 12.3963 11.1458 12.428L11.4402 12.6168C12.1828 13.0931 13.1504 13.0931 13.8931 12.6168C14.1264 12.4672 14.2666 12.2152 14.2666 11.9454V9.20641C14.2666 8.80702 14.0997 8.42269 13.8003 8.13831C13.5008 7.85372 13.0931 7.69271 12.6666 7.69271ZM10.7833 7.40831C11.2839 6.93274 11.9615 6.66669 12.6666 6.66669C13.3717 6.66669 14.0493 6.93274 14.5499 7.40831C15.0507 7.8841 15.3333 8.53076 15.3333 9.20641V11.9454C15.3333 12.5583 15.0149 13.1306 14.4848 13.4705C13.3838 14.1765 11.9495 14.1765 10.8485 13.4705L10.5541 13.2817C10.2079 13.0597 10.3333 12.7336 10.3333 12.3334L10.3333 9.33335C10.3333 8.65771 10.2825 7.8841 10.7833 7.40831Z'
        fill='black'
      />
      <path fillRule='evenodd'
        clipRule='evenodd'
        d='M6.68306 6.90889C7.10034 6.74853 7.54863 6.66669 7.99996 6.66669C8.45129 6.66669 8.89958 6.74853 9.31686 6.90889C9.73411 7.06923 10.1146 7.30596 10.4315 7.60863C10.7486 7.91152 10.996 8.27509 11.1512 8.67927C11.3027 9.07388 11.3613 9.4947 11.3209 9.91423V13.0555C11.3209 13.7132 10.9848 14.321 10.4389 14.6504C8.93018 15.561 7.06974 15.561 5.561 14.6504C5.01516 14.321 4.67905 13.7132 4.67905 13.0555V9.91422C4.63864 9.4947 4.69721 9.07388 4.8487 8.67927C5.00387 8.27509 5.25135 7.91152 5.56842 7.60863C5.88527 7.30596 6.26581 7.06923 6.68306 6.90889ZM7.99996 7.76438C7.66957 7.76438 7.34475 7.82446 7.04704 7.93886C6.74931 8.05328 6.48875 8.21811 6.27945 8.41805C6.07037 8.61777 5.91824 8.8468 5.82593 9.08724C5.73387 9.32704 5.7016 9.57708 5.72834 9.82335C5.73056 9.84385 5.73168 9.86446 5.73168 9.88509V13.0555C5.73168 13.3214 5.86758 13.5672 6.08827 13.7004C7.27084 14.4141 8.72908 14.4141 9.91165 13.7004C10.1323 13.5672 10.2682 13.3214 10.2682 13.0555V9.88509C10.2682 9.86446 10.2694 9.84385 10.2716 9.82335C10.2983 9.57708 10.266 9.32704 10.174 9.08724C10.0817 8.8468 9.92955 8.61777 9.72047 8.41805C9.51116 8.21811 9.25061 8.05328 8.95287 7.93886C8.65517 7.82446 8.33035 7.76438 7.99996 7.76438Z'
        fill='black'
      />
    </SvgContainer>
  );
}

export default Svg16Community;

