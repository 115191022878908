import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12ConsciousBeauty( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <g id='ConsciousBeauty'>
        <path fill='#4a9462'
          d='M4 9.4C5.6 7 7.9 7.6 8 7.6 6 8 5 10.2 5.4 9.8c1-1.4 2.5-2 4.1-2.2h.9l-.1.2c-1-.2-1.6.2-2 1.1a5 5 0 0 1-1.9 2.4c-1.2.8-2.6.8-4 .5 1-.5 1.3-1.6 1.8-2.4'
        />
        <path fill='#a5d59e'
          d='M7 4.5C6 2.4 4 3 2.5 2.5h-.8l.1.2c.9 0 1.4.3 1.7 1.1A4 4 0 0 0 4.9 6c1 .8 2.1 1 3.4.7-.8-.5-1-1.4-1.3-2.2'
        />
        <path fill='#fac8cf'
          d='M10 4.8c.6-2-.3-3-.9-4.2L9 0l-.1.2c.4.5.3 1 0 1.8-.2.7-.5 1.3-.5 2.1 0 1.1.5 2 1 2.9 0-.9.5-1.3.8-2.2'
        />
      </g>
    </SvgContainer>
  );
}

export default Svg12ConsciousBeauty;
