/**
 * Use the Graphic Element component to incorporate pops of brand, personality, or to draw the eye to following content.
 *
 * @module views/components/CreativeGraphicElement
 * @memberof -Common
 */
import './CreativeGraphicElement.scss';

import React from 'react';

import PropTypes from 'prop-types';

import UltaAsset from '@ulta/core/components/UltaAsset/UltaAsset';

/**
 * Represents a CreativeGraphicElement component
 *
 * @method
 * @param {CreativeGraphicElementProps} props - React properties passed from composition
 * @returns CreativeGraphicElement
 */
export const CreativeGraphicElement = function( props ){
  return (
    <div className='CreativeGraphicElement'>
      <div className='CreativeGraphicElement__wrapper'>
        <div className='CreativeGraphicElement__content'>
          { props.graphicImage &&
            <UltaAsset
              { ...( props.graphicImage ) }
            />
          }
        </div>
      </div>
    </div>
  );
};

/**
 * Property type definitions
 * @typedef CreativeGraphicElementProps
 * @type {object}
 * @property {object} graphicImage - Set the image to display
 */

export const propTypes =  {
  /** Set the image to display */
  graphicImage: PropTypes.object
};

CreativeGraphicElement.propTypes = propTypes;

export default CreativeGraphicElement;