import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16NotAvailable( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
      viewBox='0 0 16 16'
    >
      <g id='Modal'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='PDP_StoreAvailability_Search'
          transform='translate(-24.000000, -704.000000)'
          fillRule='nonzero'
        >
          <g id='Validation'
            transform='translate(24.000000, 704.000000)'
          >
            <circle id='Background'
              fill='#D4D4D4'
              cx='8'
              cy='8'
              r='8'
            />
            <g id='Check'
              transform='translate(4.000000, 4.000000)'
              fill='#000000'
            >
              <path d='M0.698842449,0.378265816 L0.724545304,0.400454696 L4,3.67580556 L7.2754547,0.400454696 C7.36494985,0.310959546 7.51005015,0.310959546 7.5995453,0.400454696 C7.68090453,0.481813923 7.68830082,0.609127826 7.62173418,0.698842449 L7.5995453,0.724545304 L4.32419444,4 L7.5995453,7.2754547 C7.68904045,7.36494985 7.68904045,7.51005015 7.5995453,7.5995453 C7.51818608,7.68090453 7.39087217,7.68830082 7.30115755,7.62173418 L7.2754547,7.5995453 L4,4.32419444 L0.724545304,7.5995453 C0.635050154,7.68904045 0.489949846,7.68904045 0.400454696,7.5995453 C0.319095469,7.51818608 0.311699175,7.39087217 0.378265816,7.30115755 L0.400454696,7.2754547 L3.67580556,4 L0.400454696,0.724545304 C0.310959546,0.635050154 0.310959546,0.489949846 0.400454696,0.400454696 C0.481813923,0.319095469 0.609127826,0.311699175 0.698842449,0.378265816 Z' />
            </g>
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}

export default Svg16NotAvailable;
