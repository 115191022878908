import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24AddressCard( props ){

  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <defs>
        <filter id='filter-1'>
          <feColorMatrix in='SourceGraphic'
            type='matrix'
            values='0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0'
          ></feColorMatrix>
        </filter>
      </defs>
      <g id='01-Token/Icon/Interface/Address-Card'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g filter='url(#filter-1)'
          id='single-neutral-id-card-4'
        >
          <g transform='translate(0.000000, 2.000000)'>
            <path d='M8.25,10 C6.389,10 4.875,8.486 4.875,6.625 C4.875,4.764 6.389,3.25 8.25,3.25 C10.111,3.25 11.625,4.764 11.625,6.625 C11.625,8.486 10.111,10 8.25,10 Z M8.25,4.75 C7.216,4.75 6.375,5.591 6.375,6.625 C6.375,7.659 7.216,8.5 8.25,8.5 C9.284,8.5 10.125,7.659 10.125,6.625 C10.125,5.591 9.284,4.75 8.25,4.75 Z'
              id='Shape'
              fill='#000000'
              fillRule='nonzero'
            ></path>
            <path d='M3.75,15.25 C3.336,15.25 3,14.914 3,14.5 C3,11.605 5.355,9.25 8.25,9.25 C11.145,9.25 13.5,11.605 13.5,14.5 C13.5,14.914 13.164,15.25 12.75,15.25 L3.75,15.25 Z M11.924,13.75 C11.572,12.038 10.032,10.75 8.25,10.75 C6.468,10.75 4.928,12.038 4.576,13.75 L11.924,13.75 Z'
              id='Shape'
              fill='#000000'
              fillRule='nonzero'
            ></path>
            <path d='M14.25,7.75 C13.836,7.75 13.5,7.414 13.5,7 C13.5,6.586 13.836,6.25 14.25,6.25 L18.75,6.25 C19.164,6.25 19.5,6.586 19.5,7 C19.5,7.414 19.164,7.75 18.75,7.75 L14.25,7.75 Z'
              id='Path'
              fill='#000000'
              fillRule='nonzero'
            ></path>
            <path d='M14.25,10.75 C13.836,10.75 13.5,10.414 13.5,10 C13.5,9.586 13.836,9.25 14.25,9.25 L20.25,9.25 C20.664,9.25 21,9.586 21,10 C21,10.414 20.664,10.75 20.25,10.75 L14.25,10.75 Z'
              id='Path'
              fill='#000000'
              fillRule='nonzero'
            ></path>
            <path d='M18.75,19.75 C18.336,19.75 18,19.414 18,19 C18,18.586 17.664,18.25 17.25,18.25 C16.836,18.25 16.5,18.586 16.5,19 C16.5,19.414 16.164,19.75 15.75,19.75 L8.25,19.75 C7.836,19.75 7.5,19.414 7.5,19 C7.5,18.586 7.164,18.25 6.75,18.25 C6.336,18.25 6,18.586 6,19 C6,19.414 5.664,19.75 5.25,19.75 L2.25,19.75 C1.009,19.75 0,18.741 0,17.5 L0,2.5 C0,1.259 1.009,0.25 2.25,0.25 L21.75,0.25 C22.991,0.25 24,1.259 24,2.5 L24,17.5 C24,18.741 22.991,19.75 21.75,19.75 L18.75,19.75 Z M21.75,18.25 C22.164,18.25 22.5,17.914 22.5,17.5 L22.5,2.5 C22.5,2.086 22.164,1.75 21.75,1.75 L2.25,1.75 C1.836,1.75 1.5,2.086 1.5,2.5 L1.5,17.5 C1.5,17.914 1.836,18.25 2.25,18.25 L4.629,18.25 C4.943,17.365 5.791,16.75 6.75,16.75 C7.709,16.75 8.558,17.365 8.871,18.25 L15.128,18.25 C15.442,17.365 16.29,16.75 17.25,16.75 C18.21,16.75 19.058,17.365 19.372,18.25 L21.75,18.25 Z'
              id='Shape'
              fill='#000000'
              fillRule='nonzero'
            ></path>
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}

export default Svg24AddressCard;
