import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16Add( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <path
        d='M.5 7.5h15M8 .5v15'
        stroke='currentColor'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg16Add;
