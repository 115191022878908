/**
 * NavigationLinksSubGroup component to handle rendering of Navigation Menu.
 *
 * @module views/components/NavigationLinksSubGroup
 * @memberof -Common
 */
import './NavigationLinksSubGroup.scss';

import React from 'react';

import PropTypes from 'prop-types';

import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { hasItems } from '@ulta/core/utils/array/array';

import Accordion from '@ulta/components/Accordion/Accordion';

import NavigationLink from '../NavigationLink/NavigationLink';

/**
  * Represents a NavigationLinksSubGroup component
  *
  * @method
  * @param {NavigationLinksSubGroupProps} props - React properties passed from composition
  * @returns NavigationLinksSubGroup
  */
export const NavigationLinksSubGroup = React.forwardRef( ( props, _ ) => {
  const { headerLinkAction, modules } = props;
  const { breakpoint } = useDeviceInflection();
  const isLargeDevice = breakpoint?.isLargeDevice();

  const hasModules = hasItems( modules );
  const isMobileHeading = headerLinkAction?.label && !isLargeDevice && !hasModules;

  if( !hasModules && !headerLinkAction?.label ){
    return null;
  }

  return (
    <div className='NavigationLinksSubGroup'>
      {
        headerLinkAction && isLargeDevice &&
        <div className='NavigationLinksSubGroup__title'>
          <Link_Huge
            inline
            target='_self'
            action={ headerLinkAction }
          >
            <Text
              htmlTag='h3'
              textStyle='title-6'
            >
              { headerLinkAction.label }
            </Text>
          </Link_Huge>
        </div>
      }
      {
        hasModules && isLargeDevice &&
        <ul className='NavigationLinksSubGroup__nav'>
          {
            modules.map( ( i, index ) => {
              // Todo : need to change the hardcoded string (All) in coming sprint once solution is finalized
              if( index === 0 && i.action?.label.startsWith( 'All ' ) ){
                return;
              }
              return (
                <li className='NavigationLinksSubGroup__item'
                  key={ index }
                >
                  <NavigationLink
                    { ...i }
                    textStyle={ 'body-3' }
                  />
                </li>

              );
            } )
          }
        </ul>
      }
      {
        isMobileHeading &&
        <div className='NavigationLinksSubGroup__title'>
          <Link_Huge
            primary
            target='_self'
            action={ headerLinkAction }
            url={ headerLinkAction.url }
          >
            <Text
              htmlTag='h3'
              textStyle='title-6'
            >
              { headerLinkAction.label }
            </Text>
          </Link_Huge>
        </div>
      }
      {
        hasModules && !isLargeDevice &&
        <Accordion label={ headerLinkAction?.label || '' }
          hasBackground
        >
          <ul>
            {
              modules?.map( ( item, index ) => (
                <li className='NavigationLinksSubGroup__mobileNavigationItem'
                  key={ index }
                >
                  <Link_Huge
                    primary
                    target='_self'
                    ariaLabel={ item.action?.label }
                    action={ item.action }
                  >
                    <Text
                      htmlTag='span'
                      textStyle='body-2'
                    >
                      { item.action?.label }
                    </Text>
                  </Link_Huge>
                </li>
              ) )
            }
          </ul>
        </Accordion>
      }

    </div>
  );
} );

/**
  * Property type definitions
  *
  * @typedef NavigationLinksSubGroupProps
  * @type {object}
  * @property {object} headerLinkAction - sets the heading
  * @property {array} modules - sets the menu
  */
export const propTypes =  {
  headerLinkAction : PropTypes.object,
  modules: PropTypes.array
};

NavigationLinksSubGroup.propTypes = propTypes;
NavigationLinksSubGroup.displayName = 'NavigationLinksSubGroup';

export default NavigationLinksSubGroup;
