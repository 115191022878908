import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16EclamationTriangle( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
      viewBox='0 0 16 16'
    >
      <g fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <g id='Path'
          transform='translate(-1338 -880)'
          stroke='currentColor'
        >
          <g transform='translate(1314 856)'>
            <g transform='translate(24 24)'>
              <g transform='translate(0 .5)'>
                <path d='m15.035 14.054c0.089585 0.12449 0.095387 0.29068 0.014703 0.42112-0.080684 0.13044-0.23198 0.19945-0.38337 0.17488h-13.333c-0.15139 0.024569-0.30269-0.044446-0.38337-0.17488-0.080684-0.13044-0.074882-0.29663 0.014703-0.42112l6.7373-13.474c0.164-0.328 0.432-0.328 0.596 0l6.7373 13.474z'/>
                <line x1='8'
                  x2='8'
                  y1='10.319'
                  y2='5.6527'
                />
                <path d='m7.994 12.167c-0.043974 8.642e-4 -0.085758 0.019355-0.11597 0.051321-0.030211 0.031966-0.046315 0.074726-0.044698 0.11868 0.0035716 0.091017 0.078246 0.16305 0.16933 0.16333h0.0033333c0.043974-8.642e-4 0.085758-0.019355 0.11597-0.051321 0.030211-0.031966 0.046315-0.074726 0.044698-0.11868-0.0035161-0.089999-0.076615-0.16164-0.16667-0.16333h-0.0033333'/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}

export default Svg16EclamationTriangle;