/**
 * this Link react component that will render a link from Amplience. This should utilize the existing link_huge
 *
 * @module views/components/Link
 * @memberof -Common
 */
import './Link.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';

/**
 * Represents a Link component
 *
 * @method
 * @param {LinkProps} props - React properties passed from composition
 * @returns Link
 */
export const Link = function( props ){
  const {
    ariaLabel,
    htmlTag,
    iconsize,
    likeButton,
    linkAction,
    linkColor,
    linkIcon,
    linkStyle,
    linkTarget,
    linkType
  } = props;

  if( !linkAction?.label ){
    return null;
  }

  return (
    <div className={ classNames( 'Link', {
      [`Link--likeButton`]: likeButton,
      [`Link--type-${linkType}`]: linkType
    }
    ) }
    >
      <div className='Link__wrapper'>
      { !likeButton &&
        <div className='Link__content'>
          <Link_Huge
            target={ linkTarget }
            arrowLink
            ariaLabel={ ariaLabel }
            action={ linkAction }
            textStyle={ linkStyle }
            iconsize={ iconsize }
          >
            { linkAction.label }
          </Link_Huge>
        </div>
      }
      { likeButton &&
      <div className='Link__content'>
        <Link_Huge
          target={ linkTarget }
          ariaLabel={ ariaLabel }
          action={ linkAction }
          textStyle={ linkStyle }
          iconsize={ iconsize }
          { ...( likeButton && { likeButtonPrimary: likeButton, likeButtonWithHover: likeButton } ) }
        >
          { linkAction.label }
        </Link_Huge>
      </div>
      }
      </div>
    </div>
  );
};

/**
  * Property type definitions
  * @typedef LinkProps
  * @type {object}
  * @property {string} ariaLabel - Sets the string in cases where text label is not visible on the screen
  * @property {string} iconsize - Sets the link icon size(s: '12px', m: '16px', l: '24px')
  * @property {string} likeButton - flag to display like like button
  * @property {object} linkAction - Sets the link action
  * @property {string} linkColor - Sets the link color
  * @property {string} linkIcon - Sets the link icon
  * @property {string} linkStyle - Sets the link style
  * @property {string} linkTarget - Sets the link target
  * @property {string} linkType - Sets the link type
*/

export const propTypes = {
  ariaLabel: PropTypes.string,
  iconsize: PropTypes.string,
  likeButton: PropTypes.bool,
  linkAction: PropTypes.object,
  linkColor: PropTypes.string,
  linkIcon: PropTypes.string,
  linkStyle: PropTypes.string,
  linkTarget: PropTypes.string,
  linkType: PropTypes.string
};

/**
 * Default values for passed properties
 * @type {object}
 * @property {string} htmlTag='span' - Set the default html tag
 * @property {string} iconsize='m' - Set the default string value for iconSize
 * @property {string} likeButton=false - Set the default value for likeButton
 * @property {string} linkColor='black' - Set the default color of the link to black
 */
export const defaultProps = {
  htmlTag: 'span',
  iconsize: 'm',
  likeButton: false,
  linkColor: 'black'
};

Link.propTypes = propTypes;
Link.defaultProps = defaultProps;

export default Link;
