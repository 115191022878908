import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16BagIcon( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <path d='M 6.3125 3.597656 L 9.6875 3.597656 C 9.738281 3.429688 9.765625 3.257812 9.765625 3.078125 C 9.765625 2.101562 8.976562 1.3125 8 1.3125 C 7.023438 1.3125 6.234375 2.101562 6.234375 3.078125 C 6.234375 3.257812 6.261719 3.429688 6.3125 3.597656 Z M 0.667969 4.042969 C 0.6875 3.8125 0.863281 3.632812 1.085938 3.601562 L 1.152344 3.597656 L 5.304688 3.597656 C 5.273438 3.425781 5.257812 3.253906 5.257812 3.078125 C 5.257812 1.5625 6.484375 0.332031 8 0.332031 C 9.515625 0.332031 10.742188 1.5625 10.742188 3.078125 C 10.742188 3.253906 10.726562 3.425781 10.691406 3.597656 L 14.847656 3.597656 L 14.914062 3.601562 C 15.136719 3.632812 15.3125 3.8125 15.332031 4.042969 L 14.355469 15.199219 C 14.34375 15.457031 14.132812 15.667969 13.867188 15.667969 L 2.132812 15.667969 C 1.867188 15.667969 1.65625 15.457031 1.644531 15.199219 Z M 13.335938 14.6875 L 14.316406 4.574219 L 1.683594 4.574219 L 2.664062 14.6875 Z M 13.335938 14.6875 '
        fill='#000000'
        fillRule='nonzero'
      ></path>
    </SvgContainer>
  );
}

export default Svg16BagIcon;
