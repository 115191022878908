import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24Bookmark( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <path
        stroke='currentColor'
        d='M3 .5h18v23l-9-7.75-9 7.75z'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg24Bookmark;
