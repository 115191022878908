import { useRef } from 'react';

/**
 * useInputId method
 * @param {string} data.name -  name of the inputField
 */
export const useInputId = ( data ) => {
  const { name } = data || {};
  const unique = process.env.NODE_ENV === 'test' ? 'test-id' : Math.random().toString( 16 ).slice( 2 );
  const id = useRef( `${name}-${unique}` );
  return [id.current];
};