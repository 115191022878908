/**
 * Snackbar component can be used to display a toast message on success or failure of an action
 *
 * @module views/components/SnackBar
 * @memberof -Common
 */
import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Icon from '@ulta/core/components/Icon/Icon';
import Text from '@ulta/core/components/Text/Text';
import { constants } from '@ulta/core/utils/constants/constants';

import * as utils from './SnackBar';
import animationVariables from './SnackBar.scss';

const { SNACKBAR_DISPLAY_DURATION } = constants;

const AnimationSlideIn = 'SlideIn';
const AnimationFadeOut = 'FadeOut';

/**
  * Represents a SnackBar component
  *
  * @method
  * @param {SnackBarProps} props - React properties passed from composition
  * @returns SnackBar
  */
export const SnackBar = function( props ){
  const animationTimer = useRef();
  const { action, bgColor, borderColor, className, handleAction, icon, message, onClose, snackBarId } = props;
  const [animationClass, setAnimationClass] = useState( AnimationSlideIn );

  useEffect( () => {
    return ( () => {
      clearTimeout( animationTimer.current );
    } );
  }, [] );

  useEffect( () => {
    handleSetTimeout( { animationTimer, message }, { setAnimationClass, onClose } );
  }, [snackBarId] );

  if( !message ){
    return null;
  }

  return (
    <div
      className={ classNames( 'SnackBar', {
        [`SnackBar--${animationClass}`]: animationClass,
        [`SnackBar__BG--${bgColor}`]: bgColor,
        [`SnackBar__Border--${borderColor}`]: borderColor,
        [className]: className
      } ) }
      key={ snackBarId }
    >
      <div className='SnackBar__Content'
        role='alert'
      >
        { icon && (
          <div className='SnackBar__IconWrapper'>
            <Icon
              size='m'
              name={ icon }
              className={ classNames( 'SnackBar__Icon', {
                [`SnackBar__Icon--${borderColor}`]: borderColor
              } ) }
            />
          </div>
        ) }
        <Text htmlTag='p'
          textStyle='body-3'
        >
          { message }
        </Text>
      </div>
      { action && (
        <div className='SnackBar__ActionWrapper'>
          <Button compact
            label={ action.label }
            onClick={ handleActionClick( { handleAction } ) }
            tertiary
            tiny
          />
        </div>
      ) }
    </div>
  );
};

/**
 * set timeout handler
 * @method
 * @param {Object} methods - Passing methods as an argument for updating the state
 */
export const handleSetTimeout = ( data, methods ) => {
  const { animationTimer, message } = data;
  const SnackbarTotalDisplayDuration = utils.getSnackbarAnimationValues( 'SnackbarTotalDisplayDuration' );
  if( !message ){
    return;
  }
  const { setAnimationClass, onClose } = methods;
  if( animationTimer.current ){
    clearTimeout( animationTimer.current );
  }
  animationTimer.current = setTimeout( () => {
    setAnimationClass( AnimationFadeOut );
    onClose && onClose();
  }, SnackbarTotalDisplayDuration );
};

/**
 * action Button handler function
 * @method
 * @param {Object} methods - Passing methods as an argument for updating the state
 */
export const handleActionClick = ( methods ) => {
  methods.handleAction && methods.handleAction();
};

/**
 * helper function used to convert ms duration values to number
 * @method
 * @param {Object} animationDuration - animation duration in ms
 */
export const convertAnimationDurationToNumber = ( animationDuration ) => {
  return Number( animationDuration?.replace( 'ms', '' ) ) || null;
};

/**
 * helper function used to get snackBar animation values
 * @method
 * @param {Object} animationKey - Passing animationKey - oneOf( 'SnackbarEntryDuration', 'SnackbarExitDuration', 'SnackbarDisplayDuration', 'SnackbarTotalDisplayDuration' )
 */
export const getSnackbarAnimationValues = ( animationKey ) => {
  // using animationVariables to access timing values based on animation animationVariables from 'web-styles/src/_animation.scss'

  switch ( animationKey ){
    case 'SnackbarEntryDuration':
      return utils.convertAnimationDurationToNumber( animationVariables.animationentryduration );
    case 'SnackbarExitDuration':
      return utils.convertAnimationDurationToNumber( animationVariables.animationexitduration );
    case 'SnackbarDisplayDuration':
      return SNACKBAR_DISPLAY_DURATION;
    case 'SnackbarTotalDisplayDuration':
      const SnackbarEntryDuration = utils.convertAnimationDurationToNumber( animationVariables.animationentryduration );
      const SnackbarDisplayDuration = SNACKBAR_DISPLAY_DURATION;
      const SnackbarTotalDisplayDuration = SnackbarDisplayDuration + SnackbarEntryDuration;
      return SnackbarTotalDisplayDuration;
    default:
      return null;
  }
};


/**
  * Property type definitions
  * @typedef SnackBarProps
  * @type {object}
  * @property {object} action - Button action - TBD
  * @property {string} type - type of the component
  * @property {string} className - className to customize style
  * @property {number} displayDuration - Specify the duration in which it's displayed
  * @property {string} icon - Icon name
  * @property {string} message - Message text
  */
export const propTypes =  {
  action: PropTypes.object, // TBD
  bgColor: PropTypes.string,
  borderColor: PropTypes.string,
  className: PropTypes.string,
  displayDuration: PropTypes.number,
  icon: PropTypes.string,
  message: PropTypes.string.isRequired
};

/**
  * Default values for passed properties
  *
  * @type {object}
  * @property {string} type='success' - type is success by default
  */
export const defaultProps =  {
  message: '',
  bgColor: 'notification-100',
  borderColor: 'notification-600'
};

SnackBar.propTypes = propTypes;
SnackBar.defaultProps = defaultProps;

export default SnackBar;
