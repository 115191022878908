/**
 * Use Image Navigation Group to visually present a curated list of destinations. For example, featured items or categories.
 *
 * @module views/components/ImageNavigation
 * @memberof -Common
 */
import './ImageNavigation.scss';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import React, { useRef, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import SwiperCore, {
  A11y,
  Keyboard, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import palette_variables from 'web-styles/src/_exports.module.scss';

import Button from '@ulta/core/components/Button/Button';
import GridContainer from '@ulta/core/components/GridContainer/GridContainer';
import Text from '@ulta/core/components/Text/Text';
import { useIntersectionObserver } from '@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { constants } from '@ulta/core/utils/constants/constants';
import { handleIntersection } from '@ulta/core/utils/intersectionProcessor/intersectionProcessor';

import ImageNavigationCard from '@ulta/components/ImageNavigationCard/ImageNavigationCard';

import carouselUtils, { handleInactiveSwiperSlides } from '@ulta/utils/carouselUtils/carouselUtils';

SwiperCore.use( [Navigation, Keyboard, A11y] );

let swiperCounter = 1;

/**
* Represents a ImageNavigation component
*
* @method
* @param {ImageNavigationProps} props - React properties passed from composition
* @returns ImageNavigation
*/
export const ImageNavigation = function( props ){
  const {
    previousAccessibility,
    fullBleedBackgroundColor,
    nextAccessibility,
    title,
    items,
    shopAllLabel,
    shopAllAction,
    outlineOption,
    railOption,
    mobileRailOption,
    backgroundColor,
    rootMargin,
    root,
    threshold,
    nextClickAction,
    previousClickAction,
    carouselAccessibility,
    carouselDetailsAccessibility } = props ;
  const { breakpoint } = useDeviceInflection();
  const isSmallDevice = breakpoint && ( breakpoint.CURRENT_BREAKPOINT === 'SM' );
  const isRailDesktop = ( railOption === 'image-rail' && !isSmallDevice );
  const isRailDropdownSmall = ( ( ( !mobileRailOption && railOption === 'image-rail' ) || mobileRailOption === 'image-rail' ) && isSmallDevice );
  const isGroupDesktop = ( railOption === 'image-group' && !isSmallDevice );
  const isGroupDropdownSmall = ( ( ( !mobileRailOption && railOption === 'image-group' ) || mobileRailOption === 'image-group' ) && isSmallDevice );
  const navRef = useRef( null );
  const [swiper, setSwiper] = useState( '' );
  const [isInit, setIsInit] = useState( true );

  swiperCounter += 1;

  const swiperInit = ( instance ) => {
    setSwiper( instance );
    setIsInit( false );
  };

  useIntersectionObserver( navRef, {
    root: root,
    rootMargin: rootMargin,
    threshold: threshold
  }, handleIntersection( props ) );

  if( !title && !items ){
    return null;
  }

  return (
    <div className={ classNames( 'ImageNavigation', {
      'ImageNavigation__withBackgroundColor': fullBleedBackgroundColor,
      'ImageNavigation--outlineOption': outlineOption
    } ) }
    >
      { ( isRailDesktop || isRailDropdownSmall ) &&
        <div className={ classNames( 'ImageNavigation__Rail',
          { 'ImageNavigation__Rail--init': isInit
          } ) }
        ref={ navRef }
        >
          <GridContainer fullBleedContentBackgroundColor={ fullBleedBackgroundColor }>
            { title &&
            <div className='ImageNavigation__Rail__header'>
              <Text
                textStyle='title-6'
                htmlTag='h2'
              >
                { title }
              </Text>
            </div>
            }
            <div className='ImageNavigation__Rail__carousel'
              role='region'
              aria-label={ carouselUtils.handleTokenizedString( carouselAccessibility, [title] ) }
            >
              { carouselDetailsAccessibility &&
                <p className='sr-only'>{ carouselUtils.handleTokenizedString( carouselDetailsAccessibility, [title] ) }</p>
              }
              <div className={ `ImageNavigation__Rail__carouselPagination ImageNavigation__Rail__carouselPagination-${swiperCounter}` }>
                <Button
                  variant='navigation'
                  iconImage='CaretBack'
                  iconSize='lg'
                  className='ImageNavigation__Rail__carouselPagination__button ImageNavigation__Rail__carouselPagination__button--back'
                  ariaLabel={ previousAccessibility }
                  ariaHiddenIcon={ true }
                  action={ previousClickAction }

                />
                <Button
                  variant='navigation'
                  iconImage='CaretForward'
                  iconSize='lg'
                  className={ 'ImageNavigation__Rail__carouselPagination__button ImageNavigation__Rail__carouselPagination__button--forward' }
                  ariaLabel={ nextAccessibility }
                  ariaHiddenIcon={ true }
                  action={ nextClickAction }
                />
              </div>
              <Swiper
                onSwiper={ ( swiper ) => {
                  swiperInit();
                  handleInactiveSwiperSlides( swiper );
                } }
                onSlideChange={ ( swiper ) => handleInactiveSwiperSlides( swiper ) }
                watchOverflow={ true }
                watchSlidesProgress={ true }
                watchSlidesVisibility={ true }
                freeMode={ {
                  minimumVelocity: 0.01,
                  momentum: true,
                  momentumBounce: true,
                  momentumBounceRatio: 1.2,
                  momentumRatio: 1.2,
                  momentumVelocityRatio: 1.2,
                  sticky: true
                } }
                speed={ 600 }
                preloadImages={ true }
                spaceBetween={ 16 }
                navigation={ {
                  prevEl: `.ImageNavigation__Rail__carouselPagination-${swiperCounter} .ImageNavigation__Rail__carouselPagination__button--back`,
                  nextEl: `.ImageNavigation__Rail__carouselPagination-${swiperCounter} .ImageNavigation__Rail__carouselPagination__button--forward`,
                  disabledClass: 'ImageNavigation__Rail__carouselPagination__button--disabled',
                  hiddenClass: 'ImageNavigation__Rail__carouselPagination__button--hidden'
                } }
                breakpoints={ {
                  [palette_variables.breakpointMD] : {
                    slidesPerView: 6,
                    slidesPerGroup: 6
                  },
                  [palette_variables.breakpointSM]: {
                    slidesPerView: 2.6,
                    slidesPerGroup: 2
                  }
                } }
              >
                { items?.length > 0 && items.map( ( item, index ) => (
                  <SwiperSlide key={ index }>
                    <div >
                      <ImageNavigationCard
                        imageMetaData={ {
                          width: '300',
                          height: '300'
                        } }
                        imageUrl={ item.imageUrl }
                        altText={ item.altText }
                        label={ item?.action?.label }
                        action={ item?.action }
                        key={ index }
                        navType={ 'brand' }
                        outlineOption={ outlineOption }
                        backgroundColor={ backgroundColor }
                      />
                    </div>
                  </SwiperSlide>
                ) )
                }
                { shopAllAction && shopAllLabel &&
                  <SwiperSlide>
                    <ImageNavigationCard
                      navType={ 'viewAll' }
                      shopAllLabel={ shopAllAction?.label }
                      action={ shopAllAction }
                      allProductsLabel={ shopAllLabel }
                      outlineOption={ false }
                    />
                  </SwiperSlide>
                }
              </Swiper>
            </div>
          </GridContainer>
        </div>
      }
      { ( isGroupDesktop || isGroupDropdownSmall ) &&
        <div className={
          classNames( 'ImageNavigation__Group', {
          } )
        }
        ref={ navRef }
        >
          <GridContainer fullBleedContentBackgroundColor={ fullBleedBackgroundColor }>
            <div className='ImageNavigation__Group__content'>
              { title &&
              <div className='ImageNavigation__Group__header'>
                <Text
                  textStyle='title-6'
                  htmlTag='h2'
                >
                  { title }
                </Text>
              </div>
              }
              <ul className='ImageNavigation__Group__list'>
                { items?.length > 0 && items.map( ( item, index ) => (
                  <li
                    key={ index }
                    className='ImageNavigation__Group__list--item'
                  >
                    <ImageNavigationCard
                      navType={ 'category' }
                      imageMetaData={ {
                        width: '300',
                        height: '300'
                      } }
                      imageUrl={ item.imageUrl }
                      altText={ item.altText }
                      label={ item?.action?.label }
                      action={ item?.action }
                      outlineOption={ outlineOption }
                      key={ index }
                      backgroundColor={ backgroundColor }
                    />
                  </li>
                ) )
                }
                { shopAllAction && shopAllLabel &&
                  <li className='ImageNavigation__Group__list--item'>
                    <ImageNavigationCard
                      navType={ 'viewAll' }
                      shopAllLabel={ shopAllAction?.label }
                      action={ shopAllAction }
                      allProductsLabel={ shopAllLabel }
                      outlineOption={ false }
                    />
                  </li>
                }
              </ul>
            </div>
          </GridContainer>
        </div>
      }
    </div>
  );
};
/**
 * Property type definitions
 * @typedef ImageNavigationProps
 * @type {object}
 * @property {boolean} ssr - Prop that decides to render on server side or not
 * @property {string} title - Sets the brand title text.
 * @property {string} fullBleedBackgroundColor - Set the full bleed background color
 * @property {array.<{image: object, action: object}>} items - List of items/images to render and display.
 * @property {string} railOption - String to swap between rail and stacked navigation
 * @property {string} mobileRailOption - String to swap between rail and stacked navigation
 * @property {boolean} outlineOption - Boolean to outline the Navigation Items
 * @property {string} shopAllLabel - Sets the all shop label.
 * @property {object} shopAllAction - Sets the shop all action.
 * @property {string} backgroundColor - Set the background color
 * @property {string} previousAccessibility - Sets the aria label for previous button.
 * @property {string} nextAccessibility - Sets the aria label for next button.
 * @property {string} carouselDetailsAccessibility - Value for aria label for carousel
 * @property {string} carouselAccessibility - Value for aria label for brand rail
 */
export const propTypes =  {
  /** The prop that decides to render on server side or not. */
  ssr: PropTypes.bool,
  /** Sets the item title text. */
  title: PropTypes.string,
  /** List of items to render and display. */
  fullBleedBackgroundColor: PropTypes.string,
  /** List of items/images to render and display. */
  items: PropTypes.arrayOf(
    PropTypes.shape( {
      imageUrl: PropTypes.string,
      action: PropTypes.object,
      altText: PropTypes.string
    } )
  ),
  /** Sets the all shop label. */
  shopAllLabel: PropTypes.string,
  /** Sets the shop all action. */
  shopAllAction: PropTypes.shape( {
    label: PropTypes.string,
    url: PropTypes.string
  } ),
  /** Sets the outline of the card */
  railOption: PropTypes.string,
  /** Sets the outline of the card */
  mobileRailOption: PropTypes.string,
  /** Sets the outline of the card */
  outlineOption: PropTypes.bool,
  /** Set the background Color */
  backgroundColor: PropTypes.string,
  /** The prop that holds the aria label for previous button. */
  previousAccessibility: PropTypes.string,
  /** The prop that holds the aria label for next button. */
  nextAccessibility: PropTypes.string,
  /** The prop that holds the action for the next button. */
  nextClickAction: PropTypes.object,
  /** The prop that holds the action for the previous button. */
  previousClickAction: PropTypes.object,
  /** The prop that holds aria label for brand rail  */
  carouselAccessibility: PropTypes.string
};

/**
 * Default values for passed properties
 * @type {object}
 * @property {string} carouselAccessibility='carousel' - The default value for aria-label is set to text 'carousel'.
 */

export const defaultProps = {
  ...constants.INTERSECTION_OBSERVER_OPTIONS,
  carouselAccessibility :'carousel'
};

ImageNavigation.propTypes = propTypes;
ImageNavigation.defaultProps = defaultProps;

export default ImageNavigation;