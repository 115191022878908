import React from 'react';

import { BackToTop } from '@ulta/core/components/BackToTop/BackToTop';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';

export const ContentContainer = function( props ){
  const { inflection } = useDeviceInflection();

  return (
    <div className='ContentContainer'>
      { props.children }
      { inflection.DESKTOP && <BackToTop /> }
    </div>
  );
};

export default ContentContainer;
