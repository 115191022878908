const gQL = `
query Page($url: JSON, $stagingHost: String, $contentId:String, $previewOptions: JSON, $moduleParams: JSON) {
  Page(url:$url, stagingHost:$stagingHost, contentId:$contentId, previewOptions:$previewOptions, moduleParams:$moduleParams) {
    content
    customResponseAttributes
    meta
  }
}
`;
export default gQL;
