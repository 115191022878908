import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16Eye( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
      viewBox='0 0 24 24'
      version='1.1'
    >
      <g
        id='Base/Icons/UI/view'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M12.352934,4.5021379 L12.0126501,4.50110669 C8.13338673,4.43566638 3.81102027,6.8725122 0.623879549,10.3806774 C-0.205062401,11.3009473 -0.205062401,12.6950527 0.621737152,13.6129543 C3.71412881,17.0189355 7.85841442,19.4231314 11.6766025,19.4974193 L12.0126202,19.4978938 C15.8966535,19.563684 20.1950291,17.1195603 23.3790796,13.6153676 C24.2080624,12.6950527 24.2080624,11.3009473 23.3812628,10.3830457 C20.2959276,6.9908101 16.132763,4.58649443 12.352934,4.5021379 Z M12.3340781,6.00194819 C15.6266115,6.07557188 19.4377389,8.2765786 22.2691668,11.3896386 C22.579571,11.7342582 22.579571,12.2617418 22.2667372,12.6090457 C19.4361846,15.7241877 15.6562421,17.9191478 12.3236933,17.9970424 L12.0126202,17.9981062 C8.58083578,18.0554358 4.65275081,15.8212687 1.73427524,12.6068478 C1.42342903,12.2617418 1.42342903,11.7342582 1.73626285,11.3869543 C4.56946045,8.26839292 8.36977024,6.07977085 11.6786287,6.00194819 L12.3340781,6.00194819 Z'
          id='Path'
          fill='#000000'
          fillRule='nonzero'
        />
        <path
          d='M12.0000002,7.49899987 C9.51487488,7.49899987 7.50022099,9.51347493 7.50000015,11.9986001 C7.49977931,14.4837253 9.51407511,16.4985582 11.9992002,16.4989999 C14.4843252,16.4994417 16.4993372,14.4853251 16.5000002,12.0002001 C16.5006368,10.8065673 16.0267528,9.66143042 15.182698,8.8171506 C14.3949136,8.02915609 13.3449633,7.56355153 12.2378408,7.50523203 L12.0000002,7.49899987 Z M12.0002001,8.99899973 C12.7960003,8.99878756 13.5592558,9.31487867 14.1218965,9.87766936 C14.6845371,10.44046 15.0004247,11.2037998 15.0000001,11.9996 C14.9995583,13.65655 13.6562169,14.9992945 11.9994669,14.9989997 C10.3427168,14.9987054 8.99985292,13.6554835 9.00000015,11.9987334 C9.00014737,10.3419833 10.34325,8.99899973 12.0002001,8.99899973 Z'
          id='Path'
          fill='#000000'
          fillRule='nonzero'
        />
      </g>
    </SvgContainer>
  );
}

export default Svg16Eye;
