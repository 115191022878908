import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12HamBurger( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <path d='M 0.5 10.5 C 0.222656 10.5 0 10.722656 0 11 C 0 11.230469 0.160156 11.429688 0.382812 11.484375 L 0.441406 11.496094 L 0.5 11.5 L 7 11.5 C 7.277344 11.5 7.5 11.277344 7.5 11 C 7.5 10.769531 7.339844 10.570312 7.117188 10.515625 L 7.058594 10.503906 L 7 10.5 Z M 0.5 5.5 C 0.222656 5.5 0 5.722656 0 6 C 0 6.230469 0.160156 6.429688 0.382812 6.484375 L 0.441406 6.496094 L 0.5 6.5 L 9.5 6.5 C 9.777344 6.5 10 6.277344 10 6 C 10 5.769531 9.839844 5.570312 9.617188 5.515625 L 9.558594 5.503906 L 9.5 5.5 Z M 0.5 0.5 C 0.222656 0.5 0 0.722656 0 1 C 0 1.230469 0.160156 1.429688 0.382812 1.484375 L 0.441406 1.496094 L 0.5 1.5 L 11.5 1.5 C 11.777344 1.5 12 1.277344 12 1 C 12 0.769531 11.839844 0.570312 11.617188 0.515625 L 11.558594 0.503906 L 11.5 0.5 Z M 0.5 0.5 '
        fill='#000'
        fillRule='nonzero'
      />
    </SvgContainer>
  );
}

export default Svg12HamBurger;