/**
 * React hook to track the visibility of a functional component based on IntersectionVisible Observer. This is used for lazy loading of the image component.
 *
 * @module utils/IntersectionObserver
 */
import { useEffect, useRef, useState } from 'react';

import { isServer } from '@ulta/core/utils/device_detection/device_detection';
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';

import * as utils from './useIntersectionObserver';

/**
 * Method to observe element intersection and return the flag the accordingly
 * handleIntersection is a call back method to handle the intersection events
 * @param  { Object } node
 * @param  { Object } options={}
 * @param  { Function } handleIntersection
 * @return { Object }
 */
export const useIntersectionObserver = function( node, options = { }, handleIntersection ){
  const [intersectionRatio, setIntersectionRatio] = useState( 0 );
  const [visible, setVisibility] = useState( false );
  const [element, setElement] = useState( null );
  const [hasIntersected, setHasIntersected] = useState( false );
  const shouldUseIntersectionObserver =
      ( typeof options.shouldUseIntersectionObserver === 'boolean' ) ?
        options.shouldUseIntersectionObserver : true;
  const observer = useRef( null );
  /*
   * We pass in a `visibleRef` because `visible` will be updated in this closure but is not going
   * to be reflected inside the intersection handler's closure.
   */
  const visibleRef = useRef( false );

  if( !shouldUseIntersectionObserver ){
    return {
      visible: true,
      hasIntersected: true,
      intersectionRatio: 0
    };
  }

  /*
   * Create a new intersection observer and pass in inserction observer options.
   */
  useEffect( () => {
    if( isServer() ){
      return;
    }

    observer.current = new IntersectionObserver(
      utils.composeIntersectionHandler( { visibleRef }, { setVisibility, setHasIntersected, setIntersectionRatio } ),
      options
    );
  }, [] );

  /*
   * We need to make sure that the stable reference to the visible state is kept up to
   * date when ever the visible property changes because the intersection observer callback
   * is in a different memory space (closure, see above)
   */
  useEffect( () => {
    if( visibleRef.current === visible ){
      return;
    }

    visibleRef.current = visible;
  }, [visible] );

  /*
   * Handle element whenever node ref value cahanges
   */
  useEffect( () => {
    setElement( node.current );
  }, [node?.current] );

  /*
   * Handle visibility change
   */
  useEffect( () => {
    utils.handleVisibilityChange( { element, observer, visible, options }, { handleIntersection } );

    return () => {
      utils.cleanObserver( { observer, element } );
    };
  }, [element, visible] );

  return { visible, hasIntersected, intersectionRatio };
};

/**
 * updates visible ref
 * refreshes observation of element
 * executes intersection callback if visible
 * @param {object} data arguments
 * @param {object} methods methods
 * @param {function} methods.handleIntersection
 * @param {boolean} visible
 */
export const handleVisibilityChange = ( data, methods ) => {
  const { element, observer, visible, options = {} } = handleEmptyObjects( data );
  const { handleIntersection } = methods || {};

  if( !element ){
    return;
  }

  utils.cleanObserver( { observer, element } );
  observer.current?.observe( element );

  if( visible && handleIntersection ){
    handleIntersection( element );
  }

  if( visible && options.triggerOnce ){
    observer.current.disconnect();
  }
};

/**
 * sets visible and hasIntersected to true once the elements is in viewport
 * visible flag indicates if the element is in viewport or not
 * hasIntersected flag indicates if the element has been in viewport atleast once
 *
 * @param {object} data arguments
 * @param {{current: boolean}} data.visibleRef is visible stable reference
 * @param {object} methods methods
 * @param {function} methods.setVisibility
 * @param {function} methods.setHasIntersected
 */
export const composeIntersectionHandler = ( data, methods ) => entries => {
  const { visibleRef } = data || {};
  const { setHasIntersected, setVisibility, setIntersectionRatio } = methods || {};

  entries.forEach( entry => {
    setIntersectionRatio( entry.intersectionRatio );

    if( entry.intersectionRatio > 0 ){
      setVisibility( true );
      setHasIntersected( true );
    }
    else if( visibleRef?.current ){
      // resets visible flag once the element is out not in the viewport
      setVisibility( false );
    }
  } );
};

/**
 * Method to clean the Observe objects created
 * @param {object} data
 * @param {object} data.observer
 * @param {object} data.element
 */
export const cleanObserver = ( data ) => {
  const { observer, element } = data || {};

  if( !element ){
    return;
  }

  observer?.current?.unobserve?.( element );
};