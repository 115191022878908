const gQL = `
query NonCachedPage( $url: JSON, $stagingHost: String, $contentId: String, $previewOptions: JSON, $moduleParams: JSON ) {
  NonCachedPage(url:$url, stagingHost:$stagingHost, contentId:$contentId, previewOptions:$previewOptions, moduleParams:$moduleParams) {
    content
    customResponseAttributes
    meta
  }
}
`;
// Added this temporary function till DXL provides the updated Query
export const gQLWithContentId = ( contentId ) =>{
  return `query NonCachedPage( $url: JSON, $stagingHost: String, $previewOptions: JSON, $moduleParams: JSON ) {
    Page: NonCachedPage(url:$url, stagingHost:$stagingHost, contentId:"${contentId}", previewOptions:$previewOptions, moduleParams:$moduleParams) {
      content
      customResponseAttributes
      meta
    }
  }
  `;
};

export default gQL;
