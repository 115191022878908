import { useEffect } from 'react';

/**
 * useOutsideClick - custom hook for adding an event listener to capture a pointer event outside an element
 * @param {object} data - node to attach the event listener and watcher state
 * @param {object} methods - callback method
*/
const useOutsideClick = ( data, methods ) => {
  const handleOutsideClick = e => {
    if( !data?.el?.contains?.( e.target ) ){
      methods?.handleClick();
    }
  };
  const watcher = typeof data?.watcher === 'undefined' ? true : data.watcher;
  useEffect( () => {
    if( watcher ){
      document.addEventListener( 'pointerdown', handleOutsideClick );
      return () => {
        document.removeEventListener( 'pointerdown', handleOutsideClick );
      };
    }
  }, [watcher] );
};

export default useOutsideClick;