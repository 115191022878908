/**
 * Common SearchProductItem component used by other modules as a reusable component which returns the ui for product search result item.
 *
 * @module views/Molecules/SearchProductItem
 * @memberof -Common
 */
import './SearchProductItem.scss';

import React from 'react';

import PropTypes from 'prop-types';

import Image from '@ulta/core/components/Image/Image';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { handleRedirect } from '@ulta/core/utils/handleLocation/handleLocation';

import { TYPEAHEAD_SEARCH_TYPE } from '@ulta/modules/SearchHeader/SearchHeader';

/**
 * Represents a SearchProductItem component
 *
 * @method
 * @param {SearchProductItemProps} props - React properties passed from composition
 * @returns SearchProductItem
 */
export const SearchProductItem = function( props ){
  const {
    brandName,
    productName,
    imageUrl,
    linkUrl,
    dataCaptureHandler,
    searchVisualIndex,
    handleAddRecentSearch
  } = props;
  const { breakpoint } = useDeviceInflection();

  // Prop check
  if( !brandName || !productName || !imageUrl ){
    return null;
  }
  const searchType = TYPEAHEAD_SEARCH_TYPE.PopularProduct;
  const searchTerm = `${brandName}:${productName}`;

  const isSearchXL = breakpoint && breakpoint.CURRENT_BREAKPOINT === 'XL';
  const isSearchL = breakpoint && breakpoint.CURRENT_BREAKPOINT === 'LG';

  return (
    <div className='SearchProductItem'>
      <Link_Huge secondary={ true }
        url={ linkUrl }
        onClick={ () => {
          dataCaptureHandler( { searchTerm, searchType, searchVisualIndex, targetUrl: linkUrl } );
          handleAddRecentSearch( productName );
          handleRedirect( { url: linkUrl } );
        } }
      >
        <div className='SearchProductItem__outerContainer SearchProductItem__outerContainer__compact__small'>
          <div className='SearchProductItem__innerContainer'>
            <div className='SearchProductItem__image'>
              { isSearchXL &&
              <Image src={ imageUrl }
                alt={ productName }
                width={ 107 }
                height={ 98 }
              />
              }
              { isSearchL &&
              <Image src={ imageUrl }
                alt={ productName }
                width={ 81 }
                height={ 74 }
              />
              }
            </div>
          </div>
        </div>
        { brandName && (
          <div className='SearchProductItem__brandName'>
            <Text textStyle='body-3'
              color='neutral-600'
            >
              { brandName }
            </Text>
          </div>
        ) }
        { productName && (
          <div className='SearchProductItem__productName'>
            <Text textStyle='body-3'>{ productName }</Text>
          </div>
        ) }
      </Link_Huge>
    </div>
  );
};

/**
 * Property type definitions
 * @typedef SearchProductItemProps
 * @type {object}
 * @property {string} linkUrl - This the the navigational url on click of PC.
 * @property {string} imageUrl - This is the product Image.
 * @property {string} brandName - This is name of brand.
 * @property {string} productName - This is name of product.
 * @property {number} searchVisualIndex - Sets the index of the product.
 * @property { function } dataCaptureHandler - Function will handle/capture datacapture events and store in local storage.
 * @property { function } handleAddRecentSearch - Function will handle adding recent search to local storage
 */

export const propTypes = {
  /** Specifies the link where navigation leads to when clicked */
  linkUrl: PropTypes.string,
  /** Specifies the source of image that is displayed */
  imageUrl: PropTypes.string,
  /** Spcifies Brand name of product being displayed */
  brandName: PropTypes.string,
  /** Spcifies name of product being displayed */
  productName: PropTypes.string,
  /** Sets the index of the product */
  searchVisualIndex: PropTypes.number,
  /** capture datacapture event and store in local storage */
  dataCaptureHandler: PropTypes.func,
  /** Handle adding recent search to local storage */
  handleAddRecentSearch: PropTypes.func
};

SearchProductItem.propTypes = propTypes;

export default SearchProductItem;
