const initLiveRegion =
  '<div id=\'globalAriaLiveRegion\' aria-live=\'polite\' aria-relevant=\'additions\' class=\'sr-only\'></div>';

export const broadcastMessage = ( data ) => {
  const { message, broadcastId, delay = 60000 } = data || {};
  broadcastMessage[broadcastId] = message;
  if( document.getElementById( 'globalAriaLiveRegion' ) === null ){
    document.getElementsByTagName( 'body' )[0].insertAdjacentHTML( 'beforeEnd', initLiveRegion );
  }

  const liveRegionElm = document.getElementById( 'globalAriaLiveRegion' );
  let broadcastIdElm = document.getElementById( broadcastId );

  let children = liveRegionElm.children;

  for ( let index = 0; index < children.length; index++ ){
    let displayStyle = children[index].id !== broadcastId ? 'none' : 'block';
    children[index].style.display = displayStyle;
  }

  if( liveRegionElm.contains( broadcastIdElm ) ){
    broadcastIdElm.innerHTML = message;
  }
  else {
    broadcastIdElm = document.createElement( 'div' );
    broadcastIdElm.innerHTML = message;
    broadcastIdElm.setAttribute( 'id', broadcastId );
    broadcastIdElm.setAttribute( 'style', 'display:block' );
    broadcastIdElm.setAttribute( 'class', 'contents' );
    liveRegionElm.appendChild( broadcastIdElm );
  }

  setTimeout( ( ( broadcastIdElm ) => () => {
    if( liveRegionElm.contains( broadcastIdElm ) ){
      liveRegionElm.removeChild( broadcastIdElm );
    }
  } )( broadcastIdElm ), delay );
};

// the below method will provide a handle to focus on an element, and this is in specific to handle ADA.
// it takes as parameters an element ref and 'delay' a vaue of which can be passed in milliseconds to control the time which needs to be elapsed
// before focus should be triggered. the delay helps to wait until any messages which screen reader has to read, is read out
export const moveScreenReaderFocus = ( elementRef, delay = 0 ) => {
  setTimeout( () => {
    if( elementRef ){
      const x = window.scrollX,
            y = window.scrollY;
      elementRef.focus();
      // scroll position is set to same position, where the window position was before the focus was triggered.
      // this will help to avoid the window scroll, for visual users
      window.scrollTo( x, y );
    }
  }, delay );
};

export const FOCUSABLE_ELEMENT_SELECTORS = [
  'a:not([disabled])',
  'button:not([disabled])',
  'input[type=text]:not([disabled])',
  '[tabindex]:not([disabled]):not([tabindex="-1"])'
];

/**
 * Adapted from:
 * https://stackoverflow.com/a/35173443
 *
 * This helper gets the next focusable element from a passed DOM noe
 *
 * E.g. Used for ADA experiences where a component closes an experience and
 * need to focus the next icon/button
 * @param {Object} data args
 * @param {Object} data.selector dom element selector we want to get next element from
 */
export const nextFocusableElement = ( data ) => {
  const { selector, autoFocus = true } = data || {};

  const el = document.querySelector( selector );

  // add all elements we want to include in our selection
  if( !document?.activeElement || !el ){
    return;
  }

  // Combines all focusable slectors with our selector, this will
  // return a node list ordered by appearence in the DOM
  const selectors = [...FOCUSABLE_ELEMENT_SELECTORS, selector].join( ',' );
  const focusableElements = document.querySelectorAll( selectors );

  // Filter displayable items and find where we are in the index
  const itemFilter = ( focusableEl ) => {
    const active = el === focusableEl;

    // Check for visibility while always include the current activeElement
    return (
      focusableEl.offsetWidth > 0 ||
      focusableEl.offsetHeight > 0 ||
      focusableEl === document.activeElement ||
      active
    );
  };

  const focusableItems = Array.prototype.filter.call( focusableElements, itemFilter );
  // Finding where we are in the index and adding 1 to get next Focusable item in the DOM
  const elIndex = focusableItems?.findIndex( index => el === index );

  if( elIndex === -1 ){
    return;
  }
  const nextIndex =  elIndex + 1;

  const nextElement = focusableItems[nextIndex] || focusableItems[0];

  if( autoFocus ){
    setTimeout( () => nextElement.focus(), 0 );
  }

  return nextElement;
};

export const descendingHtmlTag = ( htmlTag ) => {
  switch ( htmlTag ){
    case 'h1':
      return 'h2';
    case 'h2':
      return 'h3';
    case 'h3':
      return 'h4';
    case 'h4':
      return 'h5';
    case 'h5':
      return 'h6';
    default:
      return 'p';
  }
};
