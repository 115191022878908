import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12Community( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <path fillRule='evenodd'
        clipRule='evenodd'
        d='M4.67417 1.54917C5.02581 1.19754 5.50272 1 6 1C6.49728 1 6.97419 1.19754 7.32583 1.54917C7.67746 1.90081 7.875 2.37772 7.875 2.875C7.875 3.37228 7.67746 3.84919 7.32583 4.20083C6.97419 4.55246 6.49728 4.75 6 4.75C5.50272 4.75 5.02581 4.55246 4.67417 4.20083C4.32254 3.84919 4.125 3.37228 4.125 2.875C4.125 2.37772 4.32254 1.90081 4.67417 1.54917ZM6 1.75C5.70163 1.75 5.41548 1.86853 5.2045 2.0795C4.99353 2.29048 4.875 2.57663 4.875 2.875C4.875 3.17337 4.99353 3.45952 5.2045 3.6705C5.41548 3.88147 5.70163 4 6 4C6.29837 4 6.58452 3.88147 6.7955 3.6705C7.00647 3.45952 7.125 3.17337 7.125 2.875C7.125 2.57663 7.00647 2.29048 6.7955 2.0795C6.58452 1.86853 6.29837 1.75 6 1.75Z'
        fill='black'
      />
      <path fillRule='evenodd'
        clipRule='evenodd'
        d='M1.52773 2.52773C1.78559 2.26987 2.13533 2.125 2.5 2.125C2.86467 2.125 3.21441 2.26987 3.47227 2.52773C3.73013 2.78559 3.875 3.13533 3.875 3.5C3.875 3.86467 3.73013 4.21441 3.47227 4.47227C3.21441 4.73013 2.86467 4.875 2.5 4.875C2.13533 4.875 1.78559 4.73013 1.52773 4.47227C1.26987 4.21441 1.125 3.86467 1.125 3.5C1.125 3.13533 1.26987 2.78559 1.52773 2.52773ZM2.5 2.875C2.33424 2.875 2.17527 2.94085 2.05806 3.05806C1.94085 3.17527 1.875 3.33424 1.875 3.5C1.875 3.66576 1.94085 3.82473 2.05806 3.94194C2.17527 4.05915 2.33424 4.125 2.5 4.125C2.66576 4.125 2.82473 4.05915 2.94194 3.94194C3.05915 3.82473 3.125 3.66576 3.125 3.5C3.125 3.33424 3.05915 3.17527 2.94194 3.05806C2.82473 2.94085 2.66576 2.875 2.5 2.875Z'
        fill='black'
      />
      <path fillRule='evenodd'
        clipRule='evenodd'
        d='M8.52773 2.52773C8.78559 2.26987 9.13533 2.125 9.5 2.125C9.86467 2.125 10.2144 2.26987 10.4723 2.52773C10.7301 2.78559 10.875 3.13533 10.875 3.5C10.875 3.86467 10.7301 4.21441 10.4723 4.47227C10.2144 4.73013 9.86467 4.875 9.5 4.875C9.13533 4.875 8.78559 4.73013 8.52773 4.47227C8.26987 4.21441 8.125 3.86467 8.125 3.5C8.125 3.13533 8.26987 2.78559 8.52773 2.52773ZM9.5 2.875C9.33424 2.875 9.17527 2.94085 9.05806 3.05806C8.94085 3.17527 8.875 3.33424 8.875 3.5C8.875 3.66576 8.94085 3.82473 9.05806 3.94194C9.17527 4.05915 9.33424 4.125 9.5 4.125C9.66576 4.125 9.82473 4.05915 9.94194 3.94194C10.0592 3.82473 10.125 3.66576 10.125 3.5C10.125 3.33424 10.0592 3.17527 9.94194 3.05806C9.82473 2.94085 9.66576 2.875 9.5 2.875Z'
        fill='black'
      />
      <path fillRule='evenodd'
        clipRule='evenodd'
        d='M2.5 5.875C2.20014 5.875 1.91348 5.99269 1.70285 6.20072C1.49238 6.4086 1.375 6.68954 1.375 6.98148V8.98365C1.375 9.18085 1.47356 9.36501 1.63765 9.4744C2.15985 9.82254 2.84015 9.82254 3.36235 9.4744L3.625 9.29931V6.98148C3.625 6.68954 3.50762 6.4086 3.29715 6.20072C3.08652 5.99269 2.79986 5.875 2.5 5.875ZM1.17583 5.66711C1.52781 5.31948 2.00421 5.125 2.5 5.125C2.99579 5.125 3.47219 5.31948 3.82417 5.66711C4.17631 6.0149 4.20801 6.4876 4.20801 6.98148V9.4744C4.20801 9.59979 4.10432 9.93045 4 10L3.77838 10.0984C3.00426 10.6145 1.99574 10.6145 1.22162 10.0984C0.848885 9.84995 0.625 9.43162 0.625 8.98365V6.98148C0.625 6.4876 0.823691 6.0149 1.17583 5.66711Z'
        fill='black'
      />
      <path fillRule='evenodd'
        clipRule='evenodd'
        d='M9.49999 5.76952C9.18014 5.76952 8.87437 5.89027 8.6497 6.10372C8.4252 6.317 8.29999 6.60525 8.29999 6.90479V9.2143C8.29999 9.25718 8.32227 9.29722 8.35937 9.32101L8.58015 9.46259C9.13716 9.81978 9.86282 9.81978 10.4198 9.46259C10.5949 9.35035 10.7 9.1614 10.7 8.95906V6.90479C10.7 6.60525 10.5748 6.317 10.3503 6.10372C10.1256 5.89027 9.81984 5.76952 9.49999 5.76952ZM8.08754 5.55622C8.46299 5.19954 8.97115 5 9.49999 5C10.0288 5 10.537 5.19954 10.9124 5.55622C11.2881 5.91306 11.5 6.39806 11.5 6.90479V8.95906C11.5 9.41869 11.2612 9.84791 10.8636 10.1029C10.0379 10.6324 8.96212 10.6324 8.13639 10.1029L7.91561 9.96129C7.65595 9.79478 7.75 9.55017 7.75 9.25L7.74999 7C7.74999 6.49326 7.71193 5.91306 8.08754 5.55622Z'
        fill='black'
      />
      <path fillRule='evenodd'
        clipRule='evenodd'
        d='M5.01232 5.18165C5.32528 5.06138 5.6615 5 6 5C6.3385 5 6.67472 5.06138 6.98768 5.18165C7.30061 5.30191 7.58602 5.47945 7.82365 5.70646C8.06146 5.93362 8.24707 6.2063 8.36344 6.50944C8.47706 6.80539 8.52099 7.12101 8.49068 7.43565V9.79164C8.49068 10.2849 8.2386 10.7407 7.82922 10.9878C6.69766 11.6707 5.30234 11.6707 4.17078 10.9878C3.7614 10.7407 3.50932 10.2849 3.50932 9.79163V7.43565C3.47901 7.12101 3.52294 6.80539 3.63656 6.50944C3.75293 6.2063 3.93854 5.93362 4.17635 5.70646C4.41398 5.47945 4.69939 5.30191 5.01232 5.18165ZM6 5.82327C5.7522 5.82327 5.50859 5.86833 5.28531 5.95413C5.06201 6.03995 4.8666 6.16356 4.70962 6.31352C4.55281 6.46331 4.43871 6.63508 4.36948 6.81542C4.30043 6.99527 4.27623 7.18279 4.29628 7.3675C4.29795 7.38287 4.29879 7.39833 4.29879 7.41381V9.79163C4.29879 9.99106 4.40071 10.1754 4.56623 10.2753C5.45316 10.8106 6.54684 10.8106 7.43377 10.2753C7.59929 10.1754 7.70121 9.99106 7.70121 9.79164V7.41381C7.70121 7.39833 7.70205 7.38287 7.70372 7.3675C7.72377 7.18279 7.69957 6.99527 7.63052 6.81542C7.56129 6.63508 7.44719 6.46331 7.29038 6.31352C7.1334 6.16356 6.93799 6.03995 6.71469 5.95413C6.49141 5.86833 6.2478 5.82327 6 5.82327Z'
        fill='black'
      />
    </SvgContainer>
  );
}

export default Svg12Community;

