/**
 * This is the component where you can provide your error message to display by using props and it contains a warning icon to signify error this component this is the component where you can provide your error message to display by using props and it contains a warning icon to signify error . it is span element .
 *
 * @module views/Molecules/ResponseMessages
 * @memberof -Common
 */
import './ResponseMessages.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Icon from '@ulta/core/components/Icon/Icon';
import Link from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import { useToggle } from '@ulta/core/hooks/useToggle/useToggle';
/**
 * Represents a ResponseMessages component
 *
 * @method
 * @param {ResponseMessagesProps} props - React properties passed from composition
 * @returns ResponseMessages
 */
export const ResponseMessages = function( props ){

  const [hideBlock, setHide] = useToggle( false );

  return (
    <div className={ classNames( `ResponseMessages ResponseMessages--${props.messageType}`, {
      'ResponseMessages--hide': hideBlock
    } ) }
    id={ props.id }
    >
      <div className='ResponseMessages__row'>
        <div className='ResponseMessages__icon'>
          <Icon
            size='m'
            name={ getIcon( props.messageType ) }
            className={ `ResponseMessages__icon--${props.messageType}` }
          />
        </div>
        <div className={ `ResponseMessages__message ResponseMessages__message--${props.messageType}` }>
          <Text textStyle='body-2'>
            { props.message }
          </Text>
          { props.children }
        </div>

        { props.showCloseButton &&
          <div className='ResponseMessages--close'>
            <Button icon
              ariaLabel='Close'
              iconImage='X'
              onClick={ setHide }
            />
          </div>
        }
      </div>

      { props.subMessage &&
        <div className='ResponseMessages__subMessage'>
          <Text textStyle='body-3'>
            { props.subMessage }
          </Text>
        </div>
      }

      { props?.bulletList?.length &&
        <div className='ResponseMessages__bulletList'>
          <ul>
            { props.bulletList.map( ( message, index ) => (
              <li key={ index }>
                <Text
                  htmlTag='span'
                  textStyle='body-2'
                >
                  { message.text }
                </Text>
              </li>
            ) ) }
          </ul>
        </div>
      }
      { props?.linkOptions?.length &&
        <div className='ResponseMessages--linkGroup'>
          { props.linkOptions.map( ( link, index ) => (
            <div key={ index }>
              <Link compact
                url={ link.url }
              >
                <Text
                  htmlTag='span'
                  textStyle='body-2'
                >
                  { link.text }
                </Text>
              </Link>
            </div>
          ) ) }
        </div>
      }
    </div>
  );
};

/**
 * @function getIcon
 * @summary Function to get the proper icon
 *
 * @param {string} messageType - Type of message [error, warning, success, notavailable]
 * @returns {string} Return the icon name
 */
export const getIcon = ( messageType ) => {
  switch ( messageType ){
    case 'error':
      return 'ExclamationTriangle';
    case 'warning':
      return 'ExclamationCircle';
    case 'info':
      return 'ExclamationCircle';
    case 'success':
      return 'Check';
    default:
      return 'ExclamationCircle';
  }
};

/**
 * Property type definitions
 * @typedef ResponseMessagesProps
 * @type {object}
 * @property {string} messageType - The type of message can be any one of ( 'error' | 'warning' | 'success' | 'info' )
 * @property {string} message - Detail of message can be passesd as string
 * @property {boolean} showCloseButton - To display the close button
 * @property {array.<{text:String}>} bulletList - this is array of information displayed in bullet list form in response message
 * @property {array.<{ url: String, text: String }>} linkOptions - this is array of link options in response message
 */
export const propTypes = {
  /** String to show type of response message */
  messageType: PropTypes.oneOf( [
    'error',
    'warning',
    'success',
    'info'
  ] ),
  /** String to show response message */
  message: PropTypes.string,
  /** Boolean to show close button */
  showCloseButton: PropTypes.bool,
  /** Array of prop objects, where each object is associated with a url and text in the Link */
  linkOptions: PropTypes.arrayOf( PropTypes.shape( {
    url: PropTypes.string,
    text: PropTypes.string
  } ) ),
  /** Array of objects, where each object is associated with the text for the bullet list */
  bulletList: PropTypes.arrayOf( PropTypes.shape( {
    text: PropTypes.string
  } ) )
};

/**
 * Default values for passed properties
 * @type {object}
 * @property {string} messageType='info' - Message type can be error/warning/info/success
 */
export const defaultProps = {
  messageType: 'info'
};

ResponseMessages.propTypes = propTypes;
ResponseMessages.defaultProps = defaultProps;

export default ResponseMessages;