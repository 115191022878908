import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24Add( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <path
        d='M.5 12h23M12 .5v23'
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg24Add;
