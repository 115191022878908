import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg40Facebook( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 40 }
      height={ 40 }
    >
      <path
        d='M20,0 C8.955,0 0,8.955 0,20 C0,31.045 8.955,40 20,40 C31.045,40 40,31.045 40,20 C40,8.955 31.045,0 20,0 Z M25,13.3333333 L22.75,13.3333333 C21.8533333,13.3333333 21.6666667,13.7016667 21.6666667,14.63 L21.6666667,16.6666667 L25,16.6666667 L24.6516667,20 L21.6666667,20 L21.6666667,31.6666667 L16.6666667,31.6666667 L16.6666667,20 L13.3333333,20 L13.3333333,16.6666667 L16.6666667,16.6666667 L16.6666667,12.82 C16.6666667,9.87166667 18.2183333,8.33333333 21.715,8.33333333 L25,8.33333333 L25,13.3333333 Z'
        fill='currentColor'
        fillRule='nonzero'
      />
    </SvgContainer>
  );
}

export default Svg40Facebook;