import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24PrintIcon( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
      viewBox='0 0 24 24'
    >
      <g>
        <path
          d='M 5.25 17.25 L 2.25 17.25 C 1.421875 17.25 0.75 16.578125 0.75 15.75 L 0.75 8.25 C 0.75 7.421875 1.421875 6.75 2.25 6.75 L 21.75 6.75 C 22.578125 6.75 23.25 7.421875 23.25 8.25 L 23.25 15.75 C 23.25 16.578125 22.578125 17.25 21.75 17.25 L 18.75 17.25 '
          stroke='currentColor'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M 3.75 9.75 L 5.25 9.75 '
          stroke='currentColor'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M 5.25 12.75 L 18.75 12.75 L 18.75 23.25 L 5.25 23.25 Z M 5.25 12.75 '
          stroke='currentColor'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M 18.75 6.75 L 5.25 6.75 L 5.25 2.25 C 5.25 1.421875 5.921875 0.75 6.75 0.75 L 17.25 0.75 C 18.078125 0.75 18.75 1.421875 18.75 2.25 Z M 18.75 6.75 '
          stroke='currentColor'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M 8.25 15.75 L 15.75 15.75 '
          stroke='currentColor'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M 8.25 18.75 L 13.5 18.75 '
          stroke='currentColor'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </SvgContainer>
  );
}
export default Svg24PrintIcon;
