import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24Bookmarks( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <g
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
        stroke='currentColor'
      >
        <path d='M2 4.5h16v19l-8-6.375L2 23.5z' />
        <path d='M10 4.5v-4h12V15l-4.017-3.452' />
      </g>
    </SvgContainer>
  );
}

export default Svg24Bookmarks;
