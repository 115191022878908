import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24Trash( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <defs>
        <filter id='a'>
          <feColorMatrix in='SourceGraphic'
            values='0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0'
          />
        </filter>
      </defs>
      <g fill='none'
        fillRule='evenodd'
      >
        <g filter='url(#a)'>
          <g fill='#000'
            fillRule='nonzero'
          >
            <path d='m6.631 23.25c-1.161 0-2.146-0.906-2.242-2.064l-1.329-15.936h-1.56c-0.414 0-0.75-0.336-0.75-0.75s0.336-0.75 0.75-0.75h6v-0.75c0-1.241 1.009-2.25 2.25-2.25h4.5c1.241 0 2.25 1.009 2.25 2.25v0.75h6c0.414 0 0.75 0.336 0.75 0.75s-0.336 0.75-0.75 0.75h-1.56l-1.328 15.937c-0.096 1.157-1.081 2.063-2.242 2.063h-10.739zm-0.748-2.188c0.032 0.386 0.36 0.688 0.748 0.688h10.739c0.387 0 0.716-0.302 0.747-0.688l1.318-15.812h-14.87l1.318 15.812zm9.117-17.312v-0.75c0-0.414-0.336-0.75-0.75-0.75h-4.5c-0.414 0-0.75 0.336-0.75 0.75v0.75h6z'/>
            <path id='b'
              d='m9.75 18c-0.414 0-0.75-0.336-0.75-0.75v-7.5c0-0.414 0.336-0.75 0.75-0.75s0.75 0.336 0.75 0.75v7.5c0 0.414-0.336 0.75-0.75 0.75z'
            />
            <path d='m14.25 18c-0.414 0-0.75-0.336-0.75-0.75v-7.5c0-0.414 0.336-0.75 0.75-0.75s0.75 0.336 0.75 0.75v7.5c0 0.414-0.336 0.75-0.75 0.75z'/>
          </g>
        </g>
      </g>

    </SvgContainer>
  );
}

export default Svg24Trash;
