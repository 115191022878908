import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24Night( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <path
        d='M10.484 12c0-5.004 3.5-9.305 8.516-11.217a18.009 18.009 0 00-1.655-.077C9.42.706 3 5.762 3 12s6.421 11.294 14.345 11.294c.56 0 1.112-.027 1.655-.076-5.016-1.913-8.516-6.214-8.516-11.218h0z'
        stroke='currentColor'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg24Night;
