/**
 * This is a SuggestionList Component.This Component is used to render the Suggestion List. This list will display the Suggested Items with empty as well with searched parameters.
 *
 * @module views/Organisms/SuggestionList
 * @memberof -Common
 */
import './SuggestionList.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Text from '@ulta/core/components/Text/Text';
import { useAppConfigContext } from '@ulta/core/providers/AppConfigProvider/AppConfigProvider';
import { hasItems } from '@ulta/core/utils/array/array';

import SuggestionItem from '../SuggestionItem/SuggestionItem';

/**
 * Represents a SuggestionList component
 *
 * @method
 * @param {SuggestionListProps} props - React properties passed from composition
 * @returns SuggestionList
 */
export const SuggestionList = function( props ){
  const { typeaheadMinInputLength } = useAppConfigContext();
  const {
    suggestions,
    trendingLabel,
    suggestionsLabel,
    searchString,
    onMouseEnter,
    isKeyBoardUser,
    dataCaptureHandler,
    onFocusTitle,
    handleSubmit,
    maxTrendingToDisplay,
    handleAddRecentSearch,
    componentKey
  } = props;

  const hasSuggestions = hasItems( suggestions );
  const suggestionItems = hasSuggestions ? suggestions : [];
  const shouldDisplayTrending = searchString?.length < typeaheadMinInputLength || !searchString;
  const maxSuggestions = shouldDisplayTrending ? suggestionItems.slice( 0, maxTrendingToDisplay ) : suggestionItems;
  const headerLabel = shouldDisplayTrending ? trendingLabel : suggestionsLabel;

  return (
    <div className='SuggestionList'>
      { hasSuggestions &&
        <Text textStyle='body-2'
          htmlTag='h2'
        >
          { headerLabel }
        </Text>
      }
      <ul className={ classNames( 'SuggestionList__suggestions', {
        'SuggestionList__suggestions--hasSuggestions': hasSuggestions
      } ) }
      >
        { maxSuggestions.map( ( suggestion, index ) => (
          <li key={ `${componentKey}-suggestion-${index}` }>
            <SuggestionItem
              label={ suggestion.label }
              searchString={ searchString }
              action={ suggestion.linkAction }
              index={ index }
              onMouseEnter={ onMouseEnter }
              isKeyBoardUser={ isKeyBoardUser }
              dataCaptureHandler={ dataCaptureHandler }
              onFocusTitle={ onFocusTitle }
              handleSubmit={ handleSubmit }
              handleAddRecentSearch={ handleAddRecentSearch }
            />
          </li>
        ) ) }
      </ul>
    </div>
  );
};

/**
 * property type definitions
 * @typedef SuggestionListProps
 * @type object
 * @property { array } suggestions - This is array of suggestions
 * @property { string } suggestionsLabel - This is suggestion label for current suggestions
 * @property { string } trendingLabel - This is trending label for current trending
 * @property { string } searchString - This is searchString value i.e input
 * @property { function } onMouseEnter - Function will handle mouse enter event
 * @property { boolean } isKeyBoardUser - Denote user is a keyboard user
 * @property { function } dataCaptureHandler - Function will handle/capture datacapture events and store in local storage
 * @property { function } handleAddRecentSearch - Function will handle adding recent search to local storage
 * @property { number } maxTrendingToDisplay - Max number of trending sugggestions to display
 * @property {string} componentKey - provided by LayerHost, timestamp of the last time the component was updated
 */
export const propTypes = {
  /** Determines the list of the Suggestion */
  suggestions: PropTypes.arrayOf(
    PropTypes.shape( {
      label: PropTypes.string.isRequired,
      linkAction: PropTypes.object.isRequired
    } )
  ),
  /** Sets the suggestionsLabel for the suggestion */
  suggestionsLabel: PropTypes.string,
  /** Sets the trendingLabel for the trending */
  trendingLabel: PropTypes.string,
  /** Sets the searchString for the input */
  searchString: PropTypes.string,
  /** Handle mouse enter event */
  onMouseEnter: PropTypes.func,
  /** is Keyboard user */
  isKeyBoardUser: PropTypes.bool,
  /** capture datacapture event and store in local storage */
  dataCaptureHandler: PropTypes.func,
  /** Max number of trending sugggestions to display */
  maxTrendingToDisplay: PropTypes.number,
  /** Handle adding recent search to local storage */
  handleAddRecentSearch: PropTypes.func,
  /** provided by LayerHost, timestamp of the last time the component was updated */
  componentKey: PropTypes.string
};

SuggestionList.propTypes = propTypes;

export default SuggestionList;
