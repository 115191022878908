/**
 * The InflectionProvider component is used to provide localized content to views via DXL commands that are relative to the locale that is passed into the query.
 *
 * @module core/providers/InflectionProvider/InflectionProvider
 */
import React, { createContext, useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { getCurrentBreakpoint, getDeviceInflection, isMobileDisplay } from '../../utils/device_detection/device_detection';


/**
 * updateInflection compares the current inflection with the new inflection to makes ure that we are only
 * updating the internal state value of the inflection the first time that there is a delta between the values
 * @type object
 * @property { String } currentInflection - current inflection value of the provider
 * @property { String } newInflection - the incoming inflection
 * @property { function } setInflection - aliased method to set an interanl inflection state value
 */
export const updateInflection = function( currentInflection, setInflection, currentBreakpoint, setBreakpoint ){
  const newInflection = getDeviceInflection( isMobileDisplay() );
  const newBreakpoint = getCurrentBreakpoint();
  const inflection = currentInflection !== newInflection ? newInflection : currentInflection;
  const breakpoint = currentBreakpoint !== newBreakpoint ? newBreakpoint : currentBreakpoint;

  if( currentInflection !== newInflection ){
    setInflection( inflection.toUpperCase() );
  }

  if( currentBreakpoint !== newBreakpoint ){
    setBreakpoint( breakpoint.toUpperCase() );
  }
};

/**
 * getInflectionResponse takes the incoming inflection and creates an object with the inflection points as flags
 * @type object
 * @property { String } inflection - current inflection value of the provider
 * returns { Object } - active inflection map
 */
export const getInflectionResponse = function( inflection = '' ){
  return {
    MOBILE: inflection.toUpperCase() === 'MOBILE',
    DESKTOP: inflection.toUpperCase() === 'DESKTOP',
    TYPE: inflection.toUpperCase() === 'MOBILE' ? 'mobile' : 'desktop'
  };
};

/**
 * sets the CURRENT breakpoint of the application
 * @param { String } breakpoint - the incoming breakpoint to be set
 * @returns { Object } - breakpoint configuration object
 */
export const getBreakpointResponse = function( inputBreakpoint = '' ){
  const breakpoint = inputBreakpoint.toUpperCase();
  return {
    BREAKPOINTS: initialState.BREAKPOINTS,
    CURRENT_BREAKPOINT: breakpoint,
    isSmallDevice: () => {
      return breakpoint === 'SM';
    },
    isLargeDevice: () => {
      return breakpoint === 'LG' || breakpoint === 'XL';
    }
  };
};
/**
 * method to handle the screen resize events.
 * @type object
 * @property { String } inflection - current inflection value of the provider
 * @property { function } setInflection - aliased method to set an interanl inflection state value
 * @property { function } updateInflection - aliased method to call the updateInflection method
 * returns { Object } - active inflection map
 */
export const handleResize = ( inflection, setInflection, breakpoint, setBreakpoint, updateInflection ) => {
  return () => {
    updateInflection( inflection, setInflection, breakpoint, setBreakpoint );
  };
};

/**
 * Represents a InflectionProvider component
 *
 * @method
 * @param { Object } props - React properties passed from composition
 * @returns InflectionProvider
 */
export const InflectionProvider = function( { children, baseInflection } ){
  const [inflection, setInflection] = useState( baseInflection || getDeviceInflection( isMobileDisplay() ) );
  const [breakpoint, setBreakpoint] = useState( getCurrentBreakpoint( baseInflection ) );

  useEffect( () => {
    const resizeHandler = handleResize( inflection, setInflection, breakpoint, setBreakpoint, updateInflection );
    global.addEventListener( 'resize', resizeHandler );

    return () => {
      global.removeEventListener( 'resize', resizeHandler );
    };
  }, [inflection, breakpoint] );

  return (
    <InflectionContext.Provider
      value={ {
        inflection: getInflectionResponse( inflection ),
        breakpoint: getBreakpointResponse( breakpoint )
      } }
    >
      { children }
    </InflectionContext.Provider>
  );
};

/**
 * property type definitions
 * @type object
 * @property { string } inflection - label which determins the display mlde of the application
 */
export const propTypes = {
  inflection: PropTypes.string
};

/**
 * property type definitions
 * @type object
 * @property { string } [inflection = 'Desktop'] - label which determins the display mlde of the application
 *
 */
export const defaultProps = {
  inflection: 'Desktop'
};

InflectionProvider.propTypes = propTypes;
InflectionProvider.defaultProps = defaultProps;

export default InflectionProvider;

/**
 * The initialState of the provide
 * @type object
 * @property { boolean } MOBILE - flag for MOBILE inflection point
 * @property { boolean } DEKTOP - flag for DESKTOP inflection point
 * @property { array } BREAKPOINTS - breakpoint inflection points based on design system
 * @property { string } CURRENT_BREAKPOINT - the current breakpoint detected by the application
 */
export const initialState = {
  MOBILE: false,
  DESKTOP: false,
  BREAKPOINTS: ['SM', 'MD', 'LG', 'XL'],
  CURRENT_BREAKPOINT: ''
};

/**
 * Context provider for react reuse
 * @type object
 */
export const InflectionContext = createContext( initialState );

/**
 * contxt provider
 * @type object
 */
export const useDeviceInflection = () => useContext( InflectionContext );
