import { useEffect } from 'react';

/**
 * auto scroll the page to the div section
 */
export const usePageScroll = () => {
  useEffect( () => {
    const targetId = ( window.location.href ).split( '#' )[1];
    if( targetId ){
      setTimeout( () => {
        scrollToSection();
      }, 0 );
    }
    // Function to scroll to the specified section by target div ID
    const scrollToSection = ( ) => {
      const targetSection = document.getElementById( targetId );
      if( targetSection ){
        targetSection.scrollIntoView( );
      }
    };
  }, [] );
};