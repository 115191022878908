/**
 * The InputFieldUnderline component is used for textual user data input on forms. The type of field can be one of &#x27;text&#x27;, &#x27;number&#x27;, &#x27;email&#x27;, &#x27;tel&#x27; or &#x27;date&#x27;. It also accepts several properties to set values on tag attributes, enhance usability, and for ADA compliance.
 *
 * @module views/Atoms/InputFieldUnderline
 * @memberof -Common
 */
import './InputFieldUnderline.scss';

import React, { forwardRef, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Icon from '@ulta/core/components/Icon/Icon';
import ResponseMessages from '@ulta/core/components/ResponseMessages/ResponseMessages';
import Text from '@ulta/core/components/Text/Text';
import { UseInputMasker } from '@ulta/core/hooks/useInputMasker/useInputMasker';
import { useLocalRef } from '@ulta/core/hooks/useLocalRef/useLocalRef';
import { getGeoLocation } from '@ulta/core/utils/getGeoLocation/getGeoLocation';

import * as utils from './InputFieldUnderline';

/**
 * Represents a InputFieldUnderline component
 *
 * @method
 * @param {InputFieldUnderlineProps} props - React properties passed from composition
 * @param {Object} ref - React forwardRef passed to the InputFieldUnderline for parent control if needed
 * @returns InputFieldUnderline
 */
export const InputFieldUnderline = forwardRef( ( props, ref ) => {
  const [field, meta, helpers] = useField( props );
  const {
    ariaDescribedBy,
    ariaLabel,
    autoCapitalize,
    autoComplete,
    autoCorrect,
    clearValueText,
    currentLocationText,
    disabled,
    displayCheck,
    displayGoButton,
    formatter,
    handleBlur,
    handleFocus,
    handleLocationSubmit,
    handleResetVal,
    handleSubmit,
    helpText,
    label,
    leftIcon,
    maxLength,
    name,
    onChange,
    onKeyUp,
    placeholder,
    required,
    showClearButton,
    showLocationButton,
    showValidationMessage,
    spellCheck,
    submitText,
    tabIndex,
    type,
    warningMessage,
    autoFocus
  } = props;
  const inputEl = useLocalRef( { ref } );
  const [isActive, setIsActive] = useState( false );
  const [isWarning, setIsWarning] = useState( false );
  const [isFocused, setIsFocused] = useState( false );
  const clearButtonRef = useRef();

  useEffect( () => {
    if( showValidationMessage ){
      if( warningMessage && !isActive ){
        setIsWarning( true );
      }
    }
  }, [warningMessage, showValidationMessage, isActive] );

  const isActiveClass = isActive || ( !isActive && field.value !== '' );
  const displayCheckIcon = field.value && !meta.error && !isFocused && displayCheck;
  const isError = !isFocused && meta.touched && meta.error;

  const showWarningMessage = !isFocused && meta.touched && !!warningMessage;
  const showErrorMessage = !isFocused && meta.touched && meta.error;

  const messages = {
    warning: showWarningMessage,
    error: !showWarningMessage && showErrorMessage,
    info: !showWarningMessage && !showErrorMessage && !!helpText
  };

  if( !name ){
    // eslint-disable-next-line no-console
    console.error( '[InputFieldUnderline] Attempted to render an input field without a "name" prop.' );
    return null;
  }
  return (
    <div
      className={
        classNames( 'InputFieldUnderline', {
          'InputFieldUnderline--error': isError,
          'InputFieldUnderline--disable': disabled
        } )
      }
    >
      {
        label && (
          <div className={ 'InputFieldUnderline__label' }>
            <label htmlFor={ field.name }
              className='InputFieldUnderline__label--text'
            >
              { label }
            </label>
          </div>
        )
      }

      <div className='InputFieldUnderline__wrapper'>
        <div
          className={
            classNames( 'InputFieldUnderline__content', {
              'InputFieldUnderline__content--active': isActiveClass,
              'InputFieldUnderline__content--focused': isFocused,
              'InputFieldUnderline--error': isError
            } )
          }
        >
          <div className='InputFieldUnderline__formControls'>
            { leftIcon && (
              <div className='InputFieldUnderline__leftIcon'>
                <Icon name={ leftIcon }
                  size='m'
                  aria-hidden='true'
                />
              </div>
            ) }

            <input
              { ...field }
              className={
                classNames(
                  'InputFieldUnderline__input',
                  {
                    'InputFieldUnderline--capitalize': autoCapitalize
                  },
                  {
                    'InputFieldUnderline__input--active': isActiveClass
                  }
                )
              }
              placeholder={ placeholder }
              ref={ inputEl }
              id={ field.name }
              autoComplete={ autoComplete }
              autoCorrect={ autoCorrect }
              spellCheck={ spellCheck }
              tabIndex={ tabIndex }
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={ ( autoFocus && { autoFocus } ) }
              onFocus={ () => utils.handleFocus( { setIsActive, setIsFocused, handleFocus } ) }
              onBlur={ event => utils.handleBlur( { event, field }, { handleBlur, setIsFocused } ) }
              onChange={ event => utils.handleChange( { event, formatter, field, isWarning }, { onChange, setIsActive, setIsWarning, helpers } ) }
              onKeyUp={ event => utils.captureKeyUp( { event, displayGoButton, field }, { handleSubmit, onKeyUp } ) }
              type={ type }
              aria-label={ placeholder }
              { ...( maxLength && { maxLength: maxLength } ) }
              required={ required }
              aria-describedby={ ariaDescribedBy }
              { ...( ariaLabel && { 'aria-labelledby': field?.name } ) }
            />

            <div className='InputFieldUnderline__actions' >
              { showClearButton && field.value && (
                <Button
                  variant='secondary'
                  type='button'
                  ariaLabel={ clearValueText }
                  ariaHiddenIcon={ true }
                  iconImage={ 'X' }
                  icon={ true }
                  iconSize={ 'lg' }
                  onClick={ () => utils.handleResetVal( { inputEl }, { handleResetVal, helpers } ) }
                  ref={ clearButtonRef }
                />
              ) }

              { displayGoButton && field.value && (
                <div className='InputFieldUnderline__ArrowForward'>
                  <Button
                    type='submit'
                    ariaLabel={ submitText }
                    ariaHiddenIcon={ true }
                    iconImage={ 'ArrowForward' }
                    icon={ true }
                    iconSize={ 'lg' }
                    onClick={ event => utils.onSubmit( { event }, { handleSubmit } ) }
                  />
                </div>
              ) }

              { displayCheckIcon && (
                <Icon className={ 'InputFieldUnderline__Action--valid' }
                  size={ 's' }
                  name={ 'Check' }
                  title={ 'Check' }
                />
              ) }
            </div>
          </div>
        </div>

        { showLocationButton && (
          <div className='InputFieldUnderline__Location'>
            <Button
              type='submit'
              ariaLabel={ currentLocationText }
              ariaHiddenIcon={ true }
              iconImage={ 'LocationPin' }
              icon={ true }
              iconSize={ 'm' }
              variant='tertiary'
              onClick={ () => utils.handleLocationSubmit( { handleLocationSubmit } ) }
            />
          </div>
        ) }
      </div>

      { messages.warning && (
        <ResponseMessages messageType='warning'
          inline={ true }
          message={ warningMessage }
        />
      ) }

      { messages.error && (
        <ResponseMessages messageType='error'
          inline={ true }
          message={ meta.error }
        />
      ) }

      { messages.info && (
        <div className='InputFieldUnderline__helpText'>
          <Text textStyle='body-3'
            htmlTag='span'
            color='neutral-600'
          >
            { helpText }
          </Text>
        </div>
      ) }
    </div>
  );
} );

/**
 * Handles onFocus for input
 * @param {object} methods - Methods arguments
 * @param {object} methods.handleFocus - onFocus Callback
 * @param {function} methods.setIsActive - Set active value
 * @param {function} methods.setIsFocused - Set focus for input field
 */
export const handleFocus = ( methods ) => {
  const { setIsActive, setIsFocused, handleFocus } = methods || {};
  if( !setIsActive || !setIsFocused ){
    return;
  }
  setIsActive( true );
  setIsFocused( true );

  handleFocus && handleFocus();
};

/**
 * Handles onChange for input
 * @param {object} data - Data arguments
 * @param {object} data.event - Input event
 * @param {object} data.field - Get value from props for different condition
 * @param {boolean} data.isWarning - Warning for input value
 * @property {object} data.formatter - Object with the 'pattern' key and input's format value
 * @param {object} methods - Methods arguments
 * @param {function} methods.setIsActive - Set active value
 * @param {function} methods.setIsWarning - Set warning for input value
 * @param {function} methods.helpers - Set value of helper
 * @param {function} methods.onChange - onChange callback
 */
export const handleChange = ( data, methods ) => {
  const { event, formatter, field, isWarning } = data || {};
  const { onChange, setIsActive, setIsWarning, helpers } = methods || {};
  if( !event || !field || !setIsActive || !setIsWarning || !helpers ){
    return;
  }
  setIsActive( true );

  if( isWarning ){
    setIsWarning( false );
  }

  if( formatter ){
    const maskedValue = UseInputMasker( event.target.value, formatter );
    helpers.setValue = maskedValue;
    event.target.value = maskedValue;
  }

  field.onChange( event );
  onChange && onChange( event.target.value );
};

/**
 * Handles onBlur for input
 * @param {object} data - Data arguments
 * @param {object} data.event - Input event
 * @param {object} data.field - Get value from props for different condition
 * @param {object} methods - Methods arguments
 * @param {function} methods.setIsFocused - Set focus value
 * @param {function} methods.handleBlur - onBlur callback
 */
export const handleBlur = ( data, methods ) => {
  const { event, field } = data || {};
  const { handleBlur, setIsFocused } = methods || {};
  if( !event || !field || !setIsFocused ){
    return;
  }
  setIsFocused( false );
  field.onBlur( event );

  handleBlur && handleBlur( event );
};

/**
 * Handles handleResetVal for input
 * @param {object} data - Data arguments
 * @param {object} data.inputEl - Get input value
 * @param {object} methods - Methods arguments
 * @param {function} methods.helpers - Set value of helper
 * @param {object} methods.handleResetVal - onClick callback for clear input
 */
export const handleResetVal = ( data, methods ) => {
  const { inputEl } = data || {};
  const { handleResetVal, helpers } = methods || {};
  if( !inputEl || !helpers ){
    return;
  }
  helpers.setValue( '' );
  handleResetVal && handleResetVal();

  // Sets focus back to input, we need to queue our side-effect after onBlur
  setTimeout( () => {
    inputEl.current?.focus();
  }, 0 );
};

/**
 * Handle onSubmit
 * @param {object} data - Data arguments
 * @param {object} data.event - Input event
 * @param {object} methods - Method argument
 * @param {object} methods.handleSubmit - onSubmit callback
 */
export const onSubmit = ( data, methods ) => {
  const { event } = data || {};
  const { handleSubmit } = methods || {};
  if( !event || !handleSubmit ){
    return;
  }

  handleSubmit( event );
};

/**
 * HandleLocationSubmit to submit location
 * @param {object} methods - Method argument
 * @param {object} methods.handleLocationSubmit - handleLocationSubmit callback
 */
export const handleLocationSubmit = ( methods ) => {
  const { handleLocationSubmit } = methods || {};
  if( !handleLocationSubmit ){
    return;
  }
  getGeoLocation().then( ( res ) => {
    handleLocationSubmit( res?.coords );
  } );
};

/**
 * Handles onKeyUp for input captureKeyUp
 * @param {object} data - Data arguments
 * @param {object} data.event - Input event
 * @param {object} data.field - Get value from props for different condition
 * @param {object} data.displayGoButton - Display 'Go' button (submit)
 * @param {object} methods - Method argument
 * @param {function} methods.handleSubmit - handleSubmit callback
 * @param {function} methods.onKeyUp - onKeyUp callback
 */
export const captureKeyUp = ( data, methods ) => {
  const { event, displayGoButton, field } = data || {};
  const { handleSubmit, onKeyUp } = methods || {} ;
  if( !event || !field ){
    return;
  }

  if( handleSubmit && event?.key === 'Enter' && displayGoButton ){
    handleSubmit( event );
    event.preventDefault();
  }
  else if( onKeyUp ){
    onKeyUp( field.value );
  }
};

/**
 * Property type definitions
 * @typedef InputFieldUnderlineProps
 * @type {object}
 * @property {string} type - Specifies the HTML5 input field type (currently one of 'text', 'number', 'email', 'tel', 'date')
 * @property {string} label - The value used for the input field's label tag above the input field
 * @property {number} tabIndex - The tabindex attribute value for the input field
 * @property {string} placeholder - The input field placeholder attribute value
 * @property {number} maxLength - Sets the maximum length attribute value for the input field
 * @property {string} autoComplete - Sets the input field's autocomplete attribute value
 * @property {string} autoCorrect - Sets the input field's autocorrect attribute value
 * @property {string} autoCapitalize - Sets the input field's autocapitalize attribute value
 * @property {boolean} spellCheck - Sets the spellcheck attribute, which defines whether the input may be checked for spelling errors.
 * @property {object} formatter - Object with the 'pattern' key and input's format value
 * @property {boolean} showValidationMessage - Flag to show or hide the ResponseMessage component
 * @property {string} warningMessage - Field-level warning messaging (used with the ResponseMessage component)
 * @property {string} helpText - Help text displayed below the input field
 * @property {string} clearValueText - AriaLabel text for the input field's 'Clear' button (reset)
 * @property {string} currentLocationText - AriaLabel text for current location
 * @property {string} submitText - AriaLabel text for the input field's 'Submit' button
 * @property {boolean} disabled - Flag to disable input field
 * @property {boolean} required - Flag to mark a field as required
 * @property {boolean} displayCheck - Flag to display check icon
 * @property {boolean} displayGoButton - Flag to display RightArrow
 * @property {boolean} showLocationButton - Flag to display RightArrow
 * @property {string} leftIcon - Sets the left icon inside Input field example Search
 * @property {function} handleSubmit - Function will handle submit
 * @property {function} handleLocationSubmit - Function will handle click of location icon
 * @property {function} onKeyUp - Function will handle key press
 * @property {string} ariaDescribedBy - Sets aria-describedby for ADA
 * @property {function} handleResetVal - Function will reset value
 * @property {function} onChange - Function will change value
 * @property {boolean} autoFocus - Flag to focus the input by default
 */
export const propTypes = {
  /** Specifies the HTML5 input field type (currently one of 'text', 'number', 'email', 'tel', 'date', 'search') to render */
  type: PropTypes.oneOf( ['text', 'number', 'email', 'tel', 'date', 'search'] ),
  /** Label of the input to render over the inputbox */
  label: PropTypes.string,
  /** The tabindex attribute value for the input field */
  tabIndex: PropTypes.number,
  /** The input field placeholder attribute value */
  placeholder: PropTypes.string,
  /** Sets the maximum length attribute value for the input field  */
  maxLength: PropTypes.number,
  /** Sets the input field's autocomplete attribute valuet*/
  autoComplete: PropTypes.string,
  /** Sets the input field's autocorrect attribute value */
  autoCorrect: PropTypes.string,
  /** Sets the input field's autocapitalize attribute value */
  autoCapitalize: PropTypes.string,
  /** Flag to sets the spellcheck attribute, which defines whether the input may be checked for spelling errors. */
  spellCheck: PropTypes.bool,
  /** The object with the 'pattern' key and input's format value if needed of the input  */
  formatter: PropTypes.object,
  /** Flag to show or hide the ResponseMessage component */
  showValidationMessage: PropTypes.bool,
  /** Field-level warning messaging (used with the ResponseMessage component) */
  warningMessage: PropTypes.string,
  /** Help text displayed below the input field */
  helpText: PropTypes.string,
  /** AriaLabel text for the input field's 'Clear' button (reset) */
  clearValueText: PropTypes.string,
  /** AriaLabel text for current location */
  currentLocationText: PropTypes.string,
  /** Flag to disable input field  */
  disabled: PropTypes.bool,
  /** Flag to mark a field as required */
  required: PropTypes.bool,
  /** Flag to display check icon */
  displayCheck: PropTypes.bool,
  /** Flag to display right arrow button */
  displayGoButton: PropTypes.bool,
  /** Flag to display Location button */
  showLocationButton: PropTypes.bool,
  /** Flag to display left icon in input field */
  leftIcon: PropTypes.string,
  /** Sets AriaLabel text for the input field's 'Submit' button */
  submitText: PropTypes.string,
  /** Funtion that handles submit button click */
  handleSubmit: PropTypes.func,
  /** Funtion that handles location button click */
  handleLocationSubmit: PropTypes.func,
  /** Function that handles key press */
  onKeyUp: PropTypes.func,
  /** Sets aria-describedby for ADA*/
  ariaDescribedBy: PropTypes.string,
  /** Function will handle reset value */
  handleResetVal: PropTypes.func,
  /** Function will handle changle value */
  onChange: PropTypes.func,
  /** Flag to focus the input by default */
  autoFocus: PropTypes.bool
};

/**
 * Default values for passed properties
 * @type {object}
 * @property {string} type='text' - The input field type, defaults to 'text'
 * @property {string} autoComplete='on' - The input field autocomplete attribute, default is 'on'
 * @property {string} autoCorrect='on' - The input field autocorrect attribute, default is 'on'
 * @property {boolean} spellCheck=true - Flag to trigger spellcheck in the field, default is 'true'
 * @property {boolean} showValidationMessage=true - Flag to show or hide the ResponseMessage component, default is 'true'
 * @property {string} clearValueText='' - Tooltip text to display for an input field's reset button, default is empty string
 * @property {string} submitText='' - Tooltip text to display for an input field's submit button, default is empty string
 * @property {boolean} displayGoButton=false - Display RightArrow if set to true, default value is false
 * @property {boolean} showLocationButton=false - Display Location icon if set to true, default value is false
 * @property {boolean} showClearButton=false - Display clearValue button if set to true, default value is true
 * @property {boolean} autoFocus=false - Flag to focus the inputfield by default on mount, default is false
 */
export const defaultProps = {
  type: 'text',
  autoComplete: 'on',
  autoCorrect: 'on',
  spellCheck: true,
  showValidationMessage: true,
  clearValueText: '',
  submitText: '',
  displayCheck: false,
  displayGoButton: false,
  showLocationButton: false,
  showClearButton: true,
  autoFocus: false
};

InputFieldUnderline.propTypes = propTypes;
InputFieldUnderline.defaultProps = defaultProps;
InputFieldUnderline.displayName = 'InputFieldUnderline';

export default InputFieldUnderline;
