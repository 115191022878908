import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16EyeSlashed( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
      viewBox='0 0 24 24'
      version='1.1'
    >
      <g
        id='Base/Icons/UI/view-off'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M21.0106011,2.45895951 C21.3094095,2.17210343 21.7841844,2.18179272 22.0710405,2.48060113 C22.3318187,2.75224514 22.3475182,3.16931598 22.1237183,3.45842126 L22.0493989,3.54104049 L3.29939887,21.5410405 C3.00059046,21.8278966 2.52581558,21.8182073 2.23895951,21.5193989 C1.97818126,21.2477549 1.96248182,20.830684 2.18628175,20.5415787 L2.26060113,20.4589595 L21.0106011,2.45895951 Z'
          id='Path'
          fill='#000000'
          fillRule='nonzero'
        />
        <path
          d='M19.1436238,8.43862955 C19.3873859,8.10373721 19.8564781,8.02986161 20.1913704,8.27362377 C21.3486056,9.11595461 22.4158491,10.0754309 23.3749179,11.1354545 C24.2072682,12.0509044 24.2072682,13.4490956 23.3761984,14.3631335 C20.2058361,17.86787 15.9002655,20.3166992 12.0035772,20.2499915 C10.9094285,20.2552102 9.82099813,20.0919456 8.77653382,19.765935 C8.38113386,19.642518 8.16064808,19.2219338 8.28406504,18.8265338 C8.407482,18.4311339 8.82806622,18.2106481 9.22346618,18.334065 C10.1209608,18.6142018 11.056235,18.7544929 12.012803,18.7501093 C15.4224084,18.8083221 19.3565427,16.5707504 22.2650821,13.3554545 C22.5772134,13.0121608 22.5772134,12.4878392 22.2638489,12.1431858 C21.3727662,11.1583078 20.3824503,10.2679912 19.3086296,9.48637623 C18.9737372,9.24261406 18.8998616,8.77352189 19.1436238,8.43862955 Z'
          id='Path'
          fill='#000000'
          fillRule='nonzero'
        />
        <path
          d='M12.0078483,5.25004107 C13.8981585,5.26982311 15.7573458,5.73370677 17.4353815,6.60425857 C17.8030603,6.79500748 17.9464903,7.24770262 17.7557414,7.61538146 C17.5649925,7.98306029 17.1122974,8.12649034 16.7446185,7.93574143 C15.2752938,7.17346721 13.6473487,6.76728053 11.986877,6.74988518 C8.62834404,6.69111086 4.64471319,8.93488695 1.73491791,12.1445455 C1.42278656,12.4878392 1.42278656,13.0121608 1.7389918,13.3599718 C3.10562778,14.8877624 4.70751312,16.1874665 6.4841168,17.2099718 C6.84311744,17.4165908 6.96664717,17.8751162 6.76002817,18.2341168 C6.55340917,18.5931174 6.09488385,18.7166472 5.7358832,18.5100282 C3.82084288,17.4078471 2.0941353,16.0068673 0.625082092,14.3645455 C-0.207268168,13.4490956 -0.207268168,12.0509044 0.624349485,11.1362615 C3.7969883,7.63667133 8.15401494,5.18258043 12.0078483,5.25004107 Z'
          id='Path'
          fill='#000000'
          fillRule='nonzero'
        />
        <path
          d='M8.9375505,9.50036511 C10.590791,7.91325425 13.1601214,7.78516828 14.9630136,9.19998439 C15.2888702,9.45569974 15.345731,9.92715697 15.0900156,10.2530136 C14.8343003,10.5788702 14.362843,10.635731 14.0369864,10.3800156 C12.8219938,9.42655258 11.0904886,9.51287138 9.97634825,10.5824461 C8.86220793,11.6520208 8.70531673,13.3785626 9.60841498,14.6314442 C9.85062268,14.9674625 9.77457414,15.4362073 9.43855582,15.678415 C9.1025375,15.9206227 8.63379271,15.8445741 8.39158502,15.5085558 C7.05150374,13.6494412 7.28431003,11.087476 8.9375505,9.50036511 Z'
          id='Path'
          fill='#000000'
          fillRule='nonzero'
        />
        <path
          d='M15.75,12 C16.1642136,12 16.5,12.3357864 16.5,12.75 C16.5,13.9434742 16.0258942,15.0880668 15.1819805,15.9319805 C14.3380668,16.7758942 13.1934742,17.25 12,17.25 C11.5857864,17.25 11.25,16.9142136 11.25,16.5 C11.25,16.0857864 11.5857864,15.75 12,15.75 C12.7956495,15.75 13.5587112,15.4339295 14.1213203,14.8713203 C14.6839295,14.3087112 15,13.5456495 15,12.75 C15,12.3357864 15.3357864,12 15.75,12 Z'
          id='Path'
          fill='#000000'
          fillRule='nonzero'
        />
      </g>
    </SvgContainer>
  );
}

export default Svg16EyeSlashed;
