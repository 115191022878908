import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12Search( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <g fill='none'
        fillRule='evenodd'
        stroke='currentColor'
      >
        <circle cx={ 4 }
          cy={ 4 }
          r={ 3.5 }
        />
        <path strokeLinecap='round'
          strokeLinejoin='round'
          d='M11.5 11.5L7 7'
        />
      </g>
    </SvgContainer>
  );
}

export default Svg12Search;
