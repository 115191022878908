
import merge from 'deepmerge';

import { constants } from '../constants/constants';
import intersectionProcessor from '../intersectionProcessor/intersectionProcessor';


/**
 * This method holds the logic to aggregrate the item level impression event
 * with the rail impression and interaction events before triggering it
 * @param  {Object} data
 * @param  {carouselRef} carouselRef
 */


export const handleImpressionEvent = ( data, carouselRef, customButtonVisibilityLength ) =>{

  return ( elem, nextSlide = 0, actionDataCapture, additionalEventAttributes = {} )=>{

    let slidesToShow =  carouselRef.current?.state?.slidesToShow;
    let carouselItemStartIndex = nextSlide;
    // customButtonVisibilityLength will be 3 if rail have a product highlighter
    if( customButtonVisibilityLength === constants.PRODUCT_HIGHLIGHTER_VISIBILITY_LENGTH ){
      if( nextSlide === 0 ){
        // slidesToShow will be customButtonVisibilityLength(3) if rail have a product highlighter
        slidesToShow = customButtonVisibilityLength;
      }
      else {
        // carouselItemStartIndex should start from nextSlide - 1, since only 3(customButtonVisibilityLength) products will be displayed on intial load
        carouselItemStartIndex = nextSlide - 1;
      }
    }

    const carouselItemEndIndex = carouselItemStartIndex + slidesToShow ;
    let dataCapture = {} ;
    let mergedItemLevelImpressionEvent = {} ;
    const productItems = data.items;
    const eventTriggeredItemIndexList = elem?.eventTriggeredItemIndexList || [] ;

    if( !data?.dataCapture && !actionDataCapture?.dataCapture ){
      return null;
    }

    productItems?.filter( ( item, index ) =>{

      if( index >= carouselItemStartIndex && index < carouselItemEndIndex ){
        if( !eventTriggeredItemIndexList.includes( index ) && item.dataCapture ){
          mergedItemLevelImpressionEvent = merge( mergedItemLevelImpressionEvent, item.dataCapture );
          eventTriggeredItemIndexList.push( index );
        }
      }
    } );
    if( !actionDataCapture && !elem?.railImpressionEventTriggered ){
      // On initial load, merge product item impression events with product rail impression
      // impression event needs to triggered only once
      dataCapture = merge( mergedItemLevelImpressionEvent, { ...data?.dataCapture } );
    }
    else {
      // on rail click action, merge product item impression events with rail click action
      dataCapture = merge( mergedItemLevelImpressionEvent, { ...actionDataCapture?.dataCapture } );
    }


    if( Object.keys( dataCapture ).length !== 0 && data?.dataCapture?.clientEvent ){
      intersectionProcessor.processIntersection( { dataCapture }, elem, eventTriggeredItemIndexList, additionalEventAttributes );
    }
  };
};

export const handleSwiperImpressionEvent = ( data, carouselRef, isHighlighter ) =>{

  return ( elem, nextSlide = 0, actionDataCapture, additionalEventAttributes = {} )=>{
    let visibleItems = carouselRef?.current?.swiper?.visibleSlidesIndexes;
    let dataCapture = {} ;
    let mergedItemLevelImpressionEvent = {} ;
    const productItems = data.items;
    const eventTriggeredItemIndexList = elem?.eventTriggeredItemIndexList || [] ;

    if( !data?.dataCapture && !actionDataCapture?.dataCapture ){
      return null;
    }
    if( isHighlighter ){
      if( !actionDataCapture ){
        visibleItems = visibleItems.slice( 0, visibleItems.length - 1 );
      }
      else {
        visibleItems = visibleItems.map( visibleItem => visibleItem - 1 );
      }
    }

    productItems?.filter( ( item, index ) => {
      if( visibleItems.includes( index ) ){
        if( !eventTriggeredItemIndexList.includes( index ) && item.dataCapture ){
          mergedItemLevelImpressionEvent = merge( mergedItemLevelImpressionEvent, item.dataCapture );
          eventTriggeredItemIndexList.push( index );
        }
      }
    } );

    if( !actionDataCapture && !elem?.railImpressionEventTriggered ){
      // On initial load, merge product item impression events with product rail impression
      // impression event needs to triggered only once
      dataCapture = merge( mergedItemLevelImpressionEvent, { ...data?.dataCapture } );
    }
    else {
      // on rail click action, merge product item impression events with rail click action
      dataCapture = merge( mergedItemLevelImpressionEvent, { ...actionDataCapture?.dataCapture } );
    }

    if( Object.keys( dataCapture ).length !== 0 && data?.dataCapture?.clientEvent ){
      intersectionProcessor.processIntersection( { dataCapture }, elem, eventTriggeredItemIndexList, additionalEventAttributes );
    }
  };
};


const datacaptureAggregator = {
  handleImpressionEvent,
  handleSwiperImpressionEvent
};

export default datacaptureAggregator;