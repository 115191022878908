import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12Notification( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <circle fill='#6694FF'
        fillRule='nonzero'
        cx={ 6 }
        cy={ 6 }
        r={ 6 }
      />
    </SvgContainer>
  );
}

export default Svg12Notification;
