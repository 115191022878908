import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24Menu( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <g
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
        stroke='currentColor'
      >
        <path d='M5 12h18.5M10 22.5h13.5M.5 2h23' />
      </g>
    </SvgContainer>
  );
}

export default Svg24Menu;
