/**
 * The Preview provider component is used to context related to the preview component functioanlity
 * *
 * @module core/providers/VisualizationProvider/VisualizationProvider
 */
import 'react-sliding-pane/dist/react-sliding-pane.css';
import './VisualizationProvider.scss';

import React, { createContext, useContext, useState } from 'react';
import SlidingPane from 'react-sliding-pane';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import Button from '@ulta/core/components/Button/Button';
import Icon from '@ulta/core/components/Icon/Icon';
import InputField from '@ulta/core/components/InputField_New/InputField_New';

/**
 * Represents a VisualizationProvider component
 *
 * @method
 * @param { Object } props - React properties passed from composition
 * @returns VisualizationProvider
 */
export const VisualizationProvider = function( { children, optionsopen, options = initialState } ){

  const [isPanelOpen, setPanelState] = useState( optionsopen );
  const [previewOptions, setPreviewOptions] = useState( options );
  const [visualzedComponentName, setVisualizedComponentName] = useState( '' );
  const SlidingPaneComponent = SlidingPane.default || SlidingPane;

  return (
    <VisualizationContext.Provider value={ { previewOptions, setPreviewOptions, setVisualizedComponentName } }>
      { previewOptions &&
        <div className='VisualizationProvider'>
          <div className='VisualizationProvider__OptionsButton'>
            <Button
              icon
              iconImage='Edit'
              iconSize='s'
              hiddenLabel='Edit'
              onMouseDown={ () => setPanelState( true ) }
            />
          </div>
          <SlidingPaneComponent
            className='VisualizationProvider__OptionsPane'
            overClassName='VisualizationProvider__OptionsPaneOverlay'
            shouldCloseOnEsc
            closeIcon={
              <Icon
                size='s'
                name='X'
              />
            }
            isOpen={ isPanelOpen }
            from='bottom'
            width='100%'
            title='Visualization Options'
            subtitle={ `Change the values of the inputs below to see how it impacts the visual sate of the ${ visualzedComponentName } component.` }
            onRequestClose={ () => {
              setPanelState( false );
            } }
          >
            <div className='VisualizationProvider__input'>
              <Formik
                initialValues={ {
                  ...previewOptions
                } }
                validationSchema={ getValidationSchema( previewOptions ) }
                onSubmit={
                  ( values, actions ) => {
                    setPreviewOptions( values );
                    setPanelState( false );
                  }
                }
              >
                <Form>
                  { renderPreviewOptions( previewOptions ) }
                  <div className='VisualizationProvider__OptionsPane__ApplyButton'>
                    <Button
                      compact
                      type='submit'
                      label='Apply Options'
                    />
                  </div>
                </Form>
              </Formik>
            </div>
          </SlidingPaneComponent>
        </div>
      }
      { children }
    </VisualizationContext.Provider>
  );
};


/**
 * feilterPrevieoptions weeds out any preview options that don't have a valid value
 *
 * @type method
 * @param { Object } previewOptions - a preview options object which is return from the DXL query
 * @param { function } callback - a callback method for the invoker to call when filter criteria is met
 */
export const filterPreviewOptions = ( previewOptions, callback )=> {
  return Object.keys( previewOptions ).map( ( key, index ) => {
    if( previewOptions[ key ] ){
      return callback( key, index );
    }
  } );
};


/**
 *  returns a list of InputField components based on a previewOptions
 *  object which is defined outside of the application
 *
 *
 * @type method
 * @param { String } previewOptions - the previewoptions configuration object
 */

export const renderPreviewOptions = previewOptions => {
  return filterPreviewOptions( previewOptions, ( key, index ) => {
    return (
      <InputField
        required
        showValidationMessage
        key={ `${key}_${index}` }
        name={ key }
        label={ key }
        placeholder={ previewOptions[ key ] }
        autoComplete={ key }
      />
    );
  } );
};

/**
 *
 * createFormValidationSchema creates a dynamic validation schema
 * based on the defined preview options
 * @type method
 * @param { Object } previewOptions - Thew preview options object
 * returns { Object } - Yup validation Schema
 */
export const getValidationSchema = function( previewOptions ){
  let schemaShape = {};

  filterPreviewOptions( previewOptions, ( key, index ) => {
    const entrySchema = {};
    // only force a field to be required if it was passed into previewOptions with a value from the graph query response
    entrySchema[ key ] = Yup.string().required( 'Required' );
    schemaShape = { ...schemaShape, ...entrySchema };
  } );
  return Yup.object().shape( schemaShape );
};

/**
 * setPreviewOptions sets the productID
 * @type method
 * @param { Object } previewOptions - The new preview options object
 * returns { Object } - the new preview options object
 */
// export const setPreviewOptions = function( previewOptions ){

//   return previewOptions;
// }

export const defaultProps =  {
  isPanelOpen: false
};

VisualizationProvider.defaultProps = defaultProps;

export default VisualizationProvider;

/**
 * The initialState of the provide
 * @type object
 */
export const initialState = {};


/**
 * Context provider for react reuse
 * @type object
 */
export const VisualizationContext = createContext( initialState );


/**
 * contxt provider
 * @type object
 */
export const useVisualizationContext = ( ) => useContext( VisualizationContext );
