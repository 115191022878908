/**
 * DXL is now going to send a Loader component with various props that we can use to instantiate different loaders:
 *
 * @module views/components/Loader
 * @memberof -Common
 */
import './Loader.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import LoaderCircular from '@ulta/core/components/LoaderCircular/LoaderCircular';
import LoaderGhostCard from '@ulta/core/components/LoaderGhostCard/LoaderGhostCard';
/**
 * Represents a Loader component
 *
 * @method
 * @param { LoaderProps} props - React properties passed from composition
 * @returns Loader
 */
export const Loader = function( props ){

  const { displayType, size, color } = props;

  let loaderComponent = null;

  switch ( displayType ){
    case LOADER_TYPE.GhostCard:
      loaderComponent = (
        <LoaderGhostCard color={ color }/>
      );
      break;
    case LOADER_TYPE.Circular:
      loaderComponent = (
        <LoaderCircular color={ color }/>
      );
      break;
    case LOADER_TYPE.Linear:
    default:
      return null;
  }

  const sizeClass = !!size && displayType === LOADER_TYPE.Linear ? '' : `Loader__size-${size}`;

  return (
    <div className={ classNames( 'Loader', { [sizeClass]: sizeClass } ) }>
      { loaderComponent }
    </div>
  );
};

/**
 * @const {object} LOADER_TYPE - Loader type string from DXL
 * @default
 */
export const LOADER_TYPE = {
  Linear : 'linear',
  Circular : 'circular',
  GhostCard: 'ghostCard'
};

/**
 * @const {string} LOADER_CHILD_CLASS - CSS class that should decorate child loader implementation wrappers
 */
export const LOADER_CHILD_CLASS = 'Loader__child';
export const LOADER_FIXED_CLASS = 'Loader__fixed';

/**
 * Property type definitions
 * @typedef LoaderProps
 * @type {object}
 * @property {( 'linear' | 'circular' | 'ghostCard' )} [displayType='circular'] - sets the loader based on the display type
 * @property {( 'S' | 'M' | 'L' | 'XL' )} [size='S'] - sets the size of loader
 */
export const propTypes =  {
  displayType: PropTypes.oneOf( [
    'linear',
    'circular',
    'ghostCard'
  ] ),
  size: PropTypes.oneOf( [
    'S',
    'M',
    'L',
    'XL'
  ] )
};

/**
 * Default values for passed properties
 *
 * @type {object}
 * @property {string} displayType - defaults to circular loader
 * @property {string} size - defaults to S size
 */
export const defaultProps =  {
  displayType: 'circular',
  size: 'S'
};

Loader.propTypes = propTypes;
Loader.defaultProps = defaultProps;

export default Loader;
