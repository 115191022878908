/**
 * @method isInvalidPath
 * @param {array} invalidPaths - list of known invalid paths
 * @returns boolean - returns true if path is not part of the restricted ( invalidPath ) list
 */
export const isInValidPath = ( invalidPaths ) => {
  if( typeof invalidPaths !== 'object' || invalidPaths === null ){
    return () => true;
  }
  /*
   * Given an array of known invalid paths return a boolean if path doesn't match
   */
  return ( data ) => {
    if( typeof data !== 'string' ){
      return true;
    }

    return invalidPaths.some( restricted => {
      return data.includes( restricted );
    } );
  };
};