import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12CaretForward( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <path
        d='M3 11.063L8.625 6 3 .937'
        stroke='#303030'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg12CaretForward;
