import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24Bag( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <g
        transform='translate(2)'
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M10.598 5.568a3.255 3.255 0 00.343-1.461A3.273 3.273 0 104.65 5.37M11 20.984H.032L.987 5.711H14.35l.65 6.833' />
        <circle cx={ 15.463 }
          cy={ 18 }
          r={ 5 }
        />
        <path d='M15.463 16v4M17.463 18h-4' />
      </g>
    </SvgContainer>
  );
}

export default Svg24Bag;
