/**
 * This utility is used to build the Star Rating of a particular product
 *
 * @module utils/starIcons
 * @memberof -Common
 */

/**
  * A method for choosing the correct star based on a rating
  * @method getRoundedStar
  * @param {number} rating - Number rating (like 3.5)
  * @param {object} limits - lower limits of ratings
  * @returns {string}
  */
export const getRoundedStar = ( data ) => {
  const { rating, limits = {
    'StarOutlined': 0,
    'StarHalf': .401,
    'StarFilled': .701
  } } = data;
  return !!( rating % 1 ) && Object.entries( limits )
    .reduce( ( a, c ) => {
      const [key, val] = c;
      return ( rating % 1 ) > val ? key : a;
    }, [] );
};

/**
  * A method to return an array of star svg names
  * @method starIcons
  * @param {number} rating - Number rating
  * @param {number} max - Number of stars in rating
  * @returns {array}
  */
export const starIcons = ( data ) => {
  const { rating = 0, max = 5 } = data;
  let arr = [];
  if( rating < 0 || rating > max ){
    return arr;
  }
  arr.push( ...new Array( Math.floor( rating ) ).fill( 'StarFilled' ) );
  if( getRoundedStar( { rating } ) ){
    arr.push( getRoundedStar( { rating } ) );
  }
  arr.push( ...new Array( Math.floor( max - rating ) ).fill( 'StarOutlined' ) );
  return arr;
};