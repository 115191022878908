import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12ArrowDown( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <g
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M6 11.5V.5M11 6.5l-5.06 5-5.027-5' />
      </g>
    </SvgContainer>
  );
}

export default Svg12ArrowDown;
