/**
 * This is a SearchProductList Component. This component is used to Render the List of the Searched Products. Intially it will populate the Popular Products.
 *
 * @module views/Molecules/SearchProductList
 * @memberof -Common
 */
import './SearchProductList.scss';

import React from 'react';

import PropTypes from 'prop-types';

import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import { useAppConfigContext } from '@ulta/core/providers/AppConfigProvider/AppConfigProvider';

import SearchProductItem from '../SearchProductItem/SearchProductItem';

/**
 * Represents a SearchProductList component
 *
 * @method
 * @param {SearchProductListProps} props - React properties passed from composition
 * @returns SearchProductList
 */
export const SearchProductList = function( props ){
  const { typeaheadMinInputLength } = useAppConfigContext();
  const {
    viewAllAction,
    searchString,
    popularProductsLabel,
    topResultsLabel,
    resultsCountText,
    products,
    dataCaptureHandler,
    focusableTitleRef,
    handleAddRecentSearch
  } = props;

  // Prop check
  if( !popularProductsLabel || !topResultsLabel || !products ){
    return null;
  }

  const titleLabel = searchString?.length >= typeaheadMinInputLength ? topResultsLabel : popularProductsLabel;
  const productsWithLimit = products?.slice( 0, 4 );
  const resultsCountLabel = `${productsWithLimit.length} ${resultsCountText}`;

  return (
    <div className='SearchProductList'>
      <div className='SearchProductList__Heading'
        aria-live='assertive'
      >
        <div className='SearchProductList__Title'
          tabIndex='-1'
          ref={ focusableTitleRef }
        >
          <Text
            textStyle='body-2'
            htmlTag='h3'
          >
            { titleLabel } <span className='sr-only'>{ resultsCountLabel }</span>
          </Text>
        </div>
        { viewAllAction && (
          <Link_Huge
            title={ viewAllAction.label }
            url={ viewAllAction.url }
          >
            { viewAllAction.label }
          </Link_Huge>
        ) }
      </div>
      <ul className='SearchProductList__Items'>
        { productsWithLimit.map( ( product, index ) => {
          return (
            <li className='SearchProductList__Item'
              key={ `${index}- ${product.productName}` }
            >
              { product.variantImage &&
                <SearchProductItem
                  brandName={ product.brandName }
                  productName={ product.productName }
                  imageUrl={ product.variantImage.imageUrl }
                  linkUrl={ product.linkAction.url }
                  dataCaptureHandler={ dataCaptureHandler }
                  searchVisualIndex={ index }
                  handleAddRecentSearch={ handleAddRecentSearch }
                />
              }
            </li>
          );
        } )
        }
      </ul>
    </div>
  );
};

/**
 * Property type definitions
 * @typedef SearchProductListProps
 * @type {object}
 * @property {array} products - This is array of products
 * @property {object} viewAllAction - This holds the detaila about view all link like text and url
 * @property {string} popularProductsLabel - This is popularProductsLabel for current products
 * @property {string} topResultsLabel - This is topResultsLabel for current top products
 * @property {string} resultsCountText - This is resultsCountText for current top products ada label
 * @property {string} searchString - This is searchString value i.e input
 * @property { function } dataCaptureHandler - Function will handle/capture datacapture events and store in local storage
 * @property { function } handleAddRecentSearch - Function will handle adding recent search to local storage
 */
export const propTypes = {
  /** Products that are being displayed */
  products: PropTypes.arrayOf(
    PropTypes.shape( {
      brandName: PropTypes.string,
      productName: PropTypes.string,
      variantImage: PropTypes.shape( {
        imageUrl: PropTypes.string,
        name: PropTypes.string
      } )
    } )
  ),
  /** Sets the popularProductsLabel for the top products */
  popularProductsLabel: PropTypes.string,
  /** Sets the topResultsLabel for the top products */
  topResultsLabel: PropTypes.string,
  /** Sets the resultsCountText for the top products ada label */
  resultsCountText: PropTypes.string,
  /** Sets the searchString for the input */
  searchString: PropTypes.string,
  /** Sets the details for view all link */
  viewAllAction: PropTypes.shape( {
    label: PropTypes.string,
    url: PropTypes.string
  } ),
  /** capture datacapture event and store in local storage */
  dataCaptureHandler: PropTypes.func,
  /** Handle adding recent search to local storage */
  handleAddRecentSearch: PropTypes.func
};

SearchProductList.propTypes = propTypes;

export default SearchProductList;
