import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg12DotInactive( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 12 }
      height={ 12 }
    >
      <circle
        stroke='currentColor'
        transform='rotate(-180 6 6)'
        cx={ 6 }
        cy={ 6 }
        r={ 4 }
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg12DotInactive;
