import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function SVG( props ){

  return (
    <SvgContainer
      { ...props }
      viewBox='0 0 194 121'
    >
      <g
        id='Layer_2'
        data-name='Layer 2'
      >
        <g
          id='Layer_1-2'
          data-name='Layer 1'
        >
          <rect
            style={
              {
                fill:'#ccc'
              }
            }
            width='194'
            height='121'
            rx='5'
            ry='5'
          />
          <rect
            style={
              {
                fill:'#999a9d'
              }
            }
            y='19.47'
            width='194'
            height='25.32'
          />
          <rect
            style={
              {
                fill:'#999a9d'
              }
            }
            x='20'
            y='76.46'
            width='114'
            height='18.32'
          />
          <rect
            style={
              {
                fill:'#fff'
              }
            }
            x='149.99'
            y='76.46'
            width='24.01'
            height='18.32'
          />
        </g>
      </g>
    </SvgContainer>
  );

}

export default SVG;


