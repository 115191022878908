import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';

import JSscriptTag from '../../components/JSscriptTag/JSscriptTag';
import { isServer } from '../../utils/device_detection/device_detection';
import { devLogger, LOG_TOPIC } from '../../utils/devMode/devMode';
import { handleEmptyObjects } from '../../utils/handleEmptyObjects/handleEmptyObjects';
import { useAppConfigContext } from '../AppConfigProvider/AppConfigProvider';
import { useUserContext } from '../UserContextProvider/UserContextProvider';
import * as utils from './ResourceLoaderProvider';

export const ResourceLoaderProvider = React.forwardRef( ( props, _ ) => {
  const { user } = useUserContext();
  const { resourceQueue } = useAppConfigContext();
  const { children } = props;

  // Dictionaries
  const queued = useRef( {} );
  const resolved = useRef( {} );

  // State helpers
  const scriptsLoaded = useRef( false );
  const [canLoad, setCanLoad] = useState( user.resolved );

  // Script callback array
  const pageScripts = useRef( [] );

  // Debounce pointers
  const resolvedTimer = useRef();
  const fallbackTimer = useRef();

  // Callbacks
  // ---------
  // During SSR: On the server this will add resources that we need to wait for before firing scripts
  // On the client: We can also add to the queue on the fly if it's not too late
  const queueResource = useCallback( utils.composeQueueResource( { queued, resourceQueue } ), [] );

  // Queue a JSscriptTag for loading after conditions are met (used in JSscriptTag component)
  const queueScript = useCallback( utils.composeQueueScript( { pageScripts, scriptsLoaded } ), [] );

  // Check what has been queued, make sure we have what we need then fire scripts
  const resourceLoaded = useCallback( utils.composeResourceLoaded( { resolved, resolvedTimer, queued }, { setCanLoad } ), [setCanLoad] );

  // Cycles through the queued scripts and fires them
  const loadScripts = useCallback( utils.composeLoadScripts( { fallbackTimer, scriptsLoaded, pageScripts } ), [] );

  // Fire scripts
  // ------------
  useWatchForFire( { canLoad }, { loadScripts } );

  // On mount
  // --------
  const [globalScripts] = useGlobalScripts( { queued, fallbackTimer }, { loadScripts } );

  return (
    <ResourceLoaderContext.Provider
      value={ {
        queueResource,
        queueScript,
        resourceLoaded
      } }
    >
      { children }
      { globalScripts.map( ( script, i ) => (
        <JSscriptTag key={ `script-${i}` }
          { ...script }
        />
      ) ) }
    </ResourceLoaderContext.Provider>
  );
} );

/**
 * useGlobalScripts method
 * @method
 * @param {object} data - Arguments
 * @param {object} data.queued - data.queued
 * @param {object} data.fallbackTimer - fallbackTimer
 * @param {object} methods - Methods
 * @param {function} methods.loadScripts - loadScripts method
 */
export const useGlobalScripts = ( data, methods ) => {
  const { queued = {}, fallbackTimer = {} } = handleEmptyObjects( data );
  const { loadScripts } = methods || {};

  const [globalScripts, setGlobalScripts] = useState( [] );

  useEffect( () => {
    // Grab values we figured out during SSR
    queued.current = global.__RESOURCE_QUEUE__ || {};
    setGlobalScripts( global.__GLOBAL_SCRIPTS__ || [] );

    devLogger( {
      topic: LOG_TOPIC.RESOURCE_LOADER,
      title: '[ResourceLoader] Queued resources',
      value: { globalScripts, queued: queued.current }
    } );

    // Set a fallback/max wait before we fire any scripts we have
    fallbackTimer.current = setTimeout( ()=>{
      devLogger( `[ResourceLoader] Max wait of ${MAX_WAIT}ms reached, firing scripts`, 1 );
      loadScripts();
    }, MAX_WAIT );

    return () => {
      clearTimeout( fallbackTimer.current );
    };
  }, [] );

  return [globalScripts];
};

/**
 * composeLoadScripts method
 * @method
 * @param {object} data - Arguments
 * @param {object} data.scriptsLoaded - data.scriptsLoaded
 * @param {object} data.fallbackTimer - fallbackTimer
 * @param {object} data.pageScripts - pageScripts
 */
export const composeLoadScripts = ( data ) => () => {
  const { fallbackTimer = {}, scriptsLoaded = {}, pageScripts = {} } = handleEmptyObjects( data );

  clearTimeout( fallbackTimer.current );

  if( scriptsLoaded.current || !pageScripts.current ){
    return;
  }

  const scripts = Object.values( pageScripts.current );

  devLogger( {
    topic: LOG_TOPIC.RESOURCE_LOADER,
    title: '[ResourceLoader] Firing page scripts',
    value: { pageScripts: scripts.map( s => s.tagId ) }
  } );


  scriptsLoaded.current = true;
  scripts.forEach( data => {
    const { tagId, callback } = data || {};

    devLogger( {
      topic: LOG_TOPIC.RESOURCE_LOADER,
      title: `[ResourceLoader] Firing script ${tagId}`
    } );

    callback();
  } );
};

/**
 * composeResourceLoaded method
 * @method
 * @param {object} data - Arguments
 * @param {object} data.resolved - data.resolved
 * @param {object} data.resolvedTimer - resolvedTimer
 * @param {object} data.queued - queued
 * @param {object} methods - Methods
 * @param {function} methods.setCanLoad - setCanLoad method
 */
export const composeResourceLoaded = ( data, methods ) => ( payload ) => {
  const { resolved = {}, resolvedTimer = {}, queued = {} } = handleEmptyObjects( data );
  const { setCanLoad } = methods || {};
  const { category } = payload || {};

  if( !category || !resolved.current || resolved.current[category] ){
    return;
  }

  resolved.current[category] = true;

  clearTimeout( resolvedTimer.current );
  resolvedTimer.current = setTimeout( () => {
    let canFire = true;
    for ( const q of Object.keys( queued.current ) ){
      if( !resolved.current[q] ){
        canFire = false;
        break;
      }
    }

    // eslint-disable-next-line no-console
    devLogger( {
      title: '[ResourceProvider] Checking resolved against queue',
      value: { resolved: resolved.current, queued: queued.current, canFire }
    } );

    if( canFire ){
      setCanLoad( true );
    }
  }, 50 );
};

/**
 * composeQueueScript method
 * @method
 * @param {object} data - Arguments
 * @param {object} data.pageScripts - data.pageScripts
 * @param {object} payload - params
 * @param {string} payload.tagId - setCanLoad method
 * @param {function} payload.callback - callback function
 */
export const composeQueueScript = ( data ) => ( payload ) => {
  const { pageScripts = {}, scriptsLoaded = {} } = handleEmptyObjects( data );
  const { callback, tagId } = payload || {};

  if( !callback || !tagId || !pageScripts.current ){
    return;
  }

  // Execute immediately if scripts have already fired
  if( scriptsLoaded.current ){
    callback();
    return;
  }
  pageScripts.current[tagId] = payload;
};

/**
 * composeQueueResource method
 * @method
 * @param {object} data - Arguments
 * @param {object} data.queued - data.queued
 * @param {object} data.resourceQueue - resourceQueue
 * @param {object} payload - params
 * @param {object} payload.category - category
 */
export const composeQueueResource = ( data ) => ( payload ) => {
  const { queued = {}, resourceQueue = {} } = handleEmptyObjects( data );
  const { category } = payload || {};

  // If this is called on the server, we want to bubble it up to static controller via
  // appconfig so that we can pass it to the client after SSR
  if( isServer() ){
    resourceQueue[category] = true;
    return;
  }

  // TODO - add to bag/priority graph call
  queued.current[category] = true;
};

/**
 * useWatchForFire method
 * @method
 * @param {object} data - Arguments
 * @param {boolean} data.canLoad - data.canLoad returns true / false
 * @param {object} methods - Arguments
 * @param {function} methods.loadScripts - loadScripts
 */
export const useWatchForFire = ( data, methods ) => {
  const { user } = useUserContext();
  const { canLoad } = data || {};
  const { loadScripts } = methods || {};


  useEffect( () => {
    if( !canLoad || !user.resolved ){
      return;
    }

    loadScripts();
  }, [canLoad, user.resolved] );
};

/**
 * @const {object} RESOURCE_CATEGORY - resource category
 */
export const RESOURCE_CATEGORY = {
  PriorityImages: 'priorityImages',
  PriorityGraphQL: 'atbGraph',
  JSscriptTag: 'script'
};

/**
 * @const {number} MAX_WAIT - max wait time before we fire scripts
 */
export const MAX_WAIT = global.__RESOURCE_LOADER_MAXWAIT__ || 800;

/**
 * Context provider for react reuse
 * @type object
 */
export const ResourceLoaderContext = createContext( {} );

/**
 * contxt provider
 * @type object
 */
export const useResourceLoaderContext = ( ) => useContext( ResourceLoaderContext );

ResourceLoaderProvider.displayName = 'ResourceLoaderProvider';

export default ResourceLoaderProvider;