import HttpAgent, { HttpsAgent } from 'agentkeepalive';

const {
  GRAPHQL_AGENT_KEEPALIVE = true,
  GRAPHQL_AGENT_MAXSOCKETS = 150,
  GRAPHQL_AGENT_MAXFREESOCKETS = 75,
  GRAPHQL_AGENT_SOCKET_TIMEOUT = 20000,
  GRAPHQL_AGENT_FREE_SOCKET_TIMEOUT = 10000
} = process.env;

const graphQlAgentParams = {
  keepAlive: Boolean( GRAPHQL_AGENT_KEEPALIVE ),
  maxSockets: Number( GRAPHQL_AGENT_MAXSOCKETS ),
  maxFreeSockets: Number( GRAPHQL_AGENT_MAXFREESOCKETS ),
  timeout: Number( GRAPHQL_AGENT_SOCKET_TIMEOUT ),
  freeSocketTimeout: Number( GRAPHQL_AGENT_FREE_SOCKET_TIMEOUT )
};

const Agent = process.env.NODE_ENV === 'development' ? HttpsAgent : HttpAgent;
export const graphQlAgent = new Agent( graphQlAgentParams );
