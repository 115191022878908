/**
 * Legacy: Dispatch an event to display the login form
 *
 * @module utils/dispatchLegacyLogin
 */

/**
 * dispatch custom event to open the login modal
 * @param { string } successPath
 */
export const dispatchLegacyLogin = ( successPath = '' ) => {
  const legacyLogin = new CustomEvent( LEGACY_LOGIN_EVENT, {
    ...( successPath && { detail: { data: { redirectPage: true, successPath } } } )
  } );

  document.dispatchEvent( legacyLogin );
};

/**
 * @const {string} LEGACY_LOGIN_EVENT - Legacy login event name
 */
export const LEGACY_LOGIN_EVENT = 'openLoginModal';