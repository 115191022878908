import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24Vegan( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <g id='Vegan'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path d='M1,0.999919512 C1,0.999919512 2.87670732,1.54643171 3.93807317,2.20187073 C5.89982927,3.41348049 8.49717073,5.39213902 10.4420244,8.32672439 C10.9598293,9.10826098 11.3649512,9.92387073 11.6823415,10.7510171 C12.683878,9.24589512 14.1581463,7.9452122 14.9992439,7.3327 C18.8006829,4.56391951 22.0306585,5.30065122 22.1661463,5.33311463 L22.1661463,5.33311463 L22.9581463,5.52413902 L22.4497317,6.16133415 C22.4285366,6.18762683 20.3275366,8.84828537 19.2200244,12.636578 C17.1761707,19.6282854 11.9455366,22.7458463 11.7199024,22.8655049 L11.7199024,22.8655049 L11.2047805,22.6717976 C11.1173171,22.6387976 2.45602439,19.2736024 2.70821951,10.2922366 C2.80829268,6.72984634 2.23092683,4.5019439 1.84109756,3.17791951 C1.51270732,2.06235854 1,0.999919512 1,0.999919512 Z M21.0953902,6.26489512 C19.9975366,6.24772439 17.918,6.49026098 15.6101463,8.17111463 C14.7529512,8.79543171 12.9596829,10.3890902 12.111878,12.0688707 C12.3836585,13.0653098 12.5422195,14.0612122 12.6197561,15.0203585 C13.3771463,13.7977488 14.6727317,12.0669927 16.758439,10.2503829 C16.758439,10.2503829 13.8742927,14.6573585 12.6696585,17.2324317 C12.6127805,18.9382366 12.3570976,20.3859439 12.1510488,21.3018951 C13.7015122,20.0980659 16.8091463,17.1865537 18.2243902,12.3454805 C19.0467073,9.53296829 20.3591951,7.35013902 21.0953902,6.26489512 Z M2.6754878,2.65313902 C3.26573171,4.0584561 3.85382927,6.45109024 3.74517073,10.3214805 C3.57131707,16.5166268 7.9045122,19.7613585 10.2000244,21.0411146 C10.037439,19.5966268 9.58778049,16.6360171 8.42687805,13.5640659 C8.18778192,12.9315264 7.81724281,12.0541146 7.43782759,11.1834754 L7.24794774,10.7498853 C6.61633226,9.3142327 6.01680488,8.01389512 6.01680488,8.01389512 C6.01680488,8.01389512 8.44431707,10.6466512 9.40158537,13.1771878 C10.5536341,16.2223098 11.0244878,19.1316756 11.2090732,20.7044073 C11.692,18.2774317 12.2929756,12.9974317 9.57704878,8.89979756 C7.57692683,5.88204146 4.53904878,3.76011463 2.6754878,2.65313902 Z'
          id='Icon-Color'
          fill='#000000'
          fillRule='nonzero'
        ></path>
      </g>
    </SvgContainer>
  );
}

export default Svg24Vegan;
