/**
 * Footer Section Items is main component for this POC
 *
 * @module views/components/FooterNavLinkGroup
 * @memberof -Common
 */
import './FooterNavLinkGroup.scss';

import React from 'react';

import PropTypes from 'prop-types';

import AsyncComponent from '@ulta/core/components/AsyncComponent/AsyncComponent';
import Text from '@ulta/core/components/Text/Text';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';

import Accordion from '@ulta/components/Accordion/Accordion';

/**
   * Represents a FooterNavLinkGroup component
   *
   * @method
   * @param {FooterNavLinkGroupProps} props - React properties passed from composition
   * @returns FooterNavLinkGroup
   */
export const FooterNavLinkGroup = React.forwardRef( ( props, _ ) =>{
  let { eyebrow, modules, displayAccordion } = props;
  const { inflection, breakpoint } = useDeviceInflection();
  const isLargeDevice = breakpoint.isLargeDevice();
  const hasRole = modules?.length <= 1 && !displayAccordion;

  return (
    <div className='FooterNavLinkGroup'>
      { displayAccordion === false && eyebrow?.text &&
      <div className='FooterNavLinkGroup__title'>
        <Text
          textStyle={ eyebrow?.textStyle }
          color={ eyebrow?.color }
          htmlTag={ eyebrow?.htmlTag }
          fontWeight='bold'
        >
          { eyebrow.text }
        </Text>
      </div> }
      { displayAccordion && eyebrow?.text && inflection.DESKTOP &&
      <div className='FooterNavLinkGroup__title'>
        <Text
          textStyle={ eyebrow?.textStyle }
          color={ eyebrow?.color }
          htmlTag={ eyebrow?.htmlTag }
          fontWeight='bold'
        >
          { eyebrow.text }
        </Text>
      </div> }
      {
        inflection.MOBILE && displayAccordion && eyebrow?.text &&
        <Accordion label={ eyebrow.text }
          labelTag='h3'
        >
          {
            modules?.map( ( columnItem, index ) => {
              return (

                <AsyncComponent
                  key={ `${columnItem.id}:${index}` }
                  { ...columnItem }
                />

              );
            }
            )
          }
        </Accordion>
      }
      {

        inflection.MOBILE && !displayAccordion &&
        <ul role='presentation'>
          {
            modules?.map( ( module, index ) => {
              return (
                <li key={ index }>
                  <AsyncComponent
                    { ...module }
                  />
                </li>
              );
            }
            )
          }
        </ul>
      }
      {

        isLargeDevice &&
        <ul { ...( hasRole && { role:'presentation' } ) }>
          {
            modules?.map( ( module, index ) => {
              return (
                <li key={ index }>
                  <AsyncComponent
                    { ...module }
                  />
                </li>
              );
            }
            )
          }
        </ul>
      }

    </div>

  );
} );

/**
   * Property type definitions
   * @typedef FooterNavLinkGroupProps
   * @type {object}
   * @property {string} eyebrow - sets the categories Heading
   * @property {array} modules - set the category list
   * @property {bool} displayAccordion - show and hide Heading based on value
   */
export const propTypes =  {
  eyebrow: PropTypes.object,
  modules: PropTypes.array,
  displayAccordion: PropTypes.bool
};

FooterNavLinkGroup.propTypes = propTypes;
FooterNavLinkGroup.displayName = 'FooterNavLinkGroup';

export default FooterNavLinkGroup;
