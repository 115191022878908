/**
 * Checks if a number is a non-infante number
 * @param {number} v value to check against
 * @returns {boolean} if the number is safe to use
 */
export const isFunction = ( v ) => {
  return typeof v === 'function';
};


/**
 * Checks if a number is a non-infante number
 * @param {number} v value to check against
 * @returns {boolean} if the number is safe to use
 */
export const isSafeNumber = ( v ) => {
  return Number( v ) === v && isFinite( v );
};