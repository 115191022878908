import { isServer } from '../device_detection/device_detection';

/**
 * Utility wrapper around session and local storage that
 * enforces the use of an object as a value.
 *
 * Wraps getters/setters in a try/catch and returns default
 * values so components can fail gracefully and error the way they
 * need to.
 *
 * Reserved object keys are _error, which the getter will attempt to
 * provide with some feedback in case of device related errors.
 *
 * We don't allow non-object values to be stored to maintain the
 * conventions around helper method signatures, destructuring and
 * failing gracefully.
 */

/**
 * Sets an object in local or session storage. Value must be an object.
 *
 * @param {object} data args
 * @param {boolean} data.secure use session storage
 * @param {string} data.key key to store object against
 * @param {object} data.value object to store
 * @param {number} data.ttl time to live in milliseconds. If ttl is set to -1, the value will not expire.
 * @returns {boolean} success indicator
 */
export const setStorage = ( data ) => {
  const { secure = false, key, value, ttl = -1 } = data || {};

  if( isServer() ){
    return false;
  }

  if( typeof key !== 'string' ){
    // eslint-disable-next-line no-console
    console.error( '[Storage->set] key must be a string' );
    return false;
  }

  if( typeof value !== 'object' || value === null ){
    // eslint-disable-next-line no-console
    console.error( '[Storage->set] value must be a non-null object' );
    return false;
  }

  const updatedValue = { ...value, ttl, createdOn: Date.now() };
  const seralizedValues = JSON.stringify( updatedValue );

  try {
    if( secure ){
      global.sessionStorage.setItem( key, seralizedValues );
    }
    else {
      global.localStorage.setItem( key, seralizedValues );
    }

    return true;
  }
  catch ( e ){
    // eslint-disable-next-line no-console
    console.error( `[Storage->set], ${e}` );
    return false;
  }
};

/**
 * Get value from local or session storage
 * @param {object} data args
 * @param {boolean} data.secure use sessionStorage
 * @param {string} data.key key to lookup
 * @returns {object} stored object
 */
export const getStorage = ( data ) => {
  const { secure = false, key } = data || {};

  if( isServer() ){
    return {};
  }

  if( typeof key !== 'string' ){
    // eslint-disable-next-line no-console
    console.error( '[Storage->get] key must be a string' );
    return {
      _error: new Error( 'Storage key must be a string' )
    };
  }

  try {
    const value = secure ?
      global.sessionStorage.getItem( key ) :
      global.localStorage.getItem( key );

    if(
      typeof value === 'string' &&
      value.charAt( 0 ) === '{' &&
      value.charAt( value.length - 1 ) === '}'
    ){
      const parsedValue = JSON.parse( value );
      const { ttl, createdOn, ...rest } = parsedValue;
      const isExpired = ttl > 0 && Date.now() >= createdOn + ttl;
      if( isExpired ){
        removeStorage( { key, secure } );
        return false;
      }

      return rest;
    }
    else {
      return value;
    }
  }
  catch ( e ){
    // eslint-disable-next-line no-console
    console.error( `[Storage->get], ${e}` );
    return {
      _error: e
    };
  }
};

/**
 * Removes an object in local or session storage.
 *
 * @param {object} data args
 * @param {boolean} data.secure use session storage
 * @param {string} data.key key to remove
 * @returns {boolean} success indicator
 */
export const removeStorage = ( data ) => {
  const { secure = false, key } = data || {};

  if( isServer() ){
    return false;
  }

  if( typeof key !== 'string' ){
    // eslint-disable-next-line no-console
    console.error( '[Storage->remove] key must be a string' );
    return false;
  }

  try {
    if( secure ){
      global.sessionStorage.removeItem( key );
    }
    else {
      global.localStorage.removeItem( key );
    }

    return true;
  }
  catch ( e ){
    // eslint-disable-next-line no-console
    console.error( `[Storage->remove], ${e}` );
    return false;
  }
};
