import React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function SVG( props ){

  return (
    <SvgContainer
      { ...props }
      width={ 50 }
      height={ 50 }
      viewBox='0 0 194 121'
      className={ `${props.className || ''} UltamateRewardsCreditCard` }
    >
      <g>
        <g>
          <rect
            style={
              {
                fill:'#f47d3a'
              }
            }
            width='194'
            height='121'
            rx='5'
            ry='5'
          />
          <path
            style={
              {
                fill:'#fff'
              }
            }
            d='M82.66,73.12c0-1.15,0-1.94-.09-2.84.24,0,2.26,0,2.89,0,1.33,0,2.23.47,2.23,1.59s-1.12,1.59-1.8,1.7v0c.67,0,2,.49,2,1.73,0,1.47-1.26,2.05-2.57,2.05-.58,0-2.5,0-2.74,0,.07-.91.09-1.7.09-2.84Zm2.21-2.26a2.89,2.89,0,0,0-.54,0c0,.51,0,1.08,0,1.43s0,.7,0,1h.49a1.23,1.23,0,0,0,1.26-1.3A1.08,1.08,0,0,0,84.88,70.86ZM84.78,74h-.47c0,.29,0,.58,0,1,0,.65,0,1.44,0,1.8h.61a1.25,1.25,0,0,0,1.21-1.39C86.13,74.43,85.66,74,84.78,74Z'
          />
          <path
            style={
              {
                fill:'#fff'
              }
            }
            d='M98.8,73.12c0-1.15,0-1.94-.09-2.84.65,0,4,0,4.27,0a5.25,5.25,0,0,0,0,.89c-.84-.05-2.23-.05-2.56-.05-.05.75,0,1.42,0,2.16,1,0,1.67,0,2.51-.08a3.47,3.47,0,0,0,0,.88c-.84-.06-1.66-.09-2.51-.09,0,.43,0,.87,0,1.31s0,.79,0,1.2a22.94,22.94,0,0,0,2.53-.05,5.38,5.38,0,0,0,0,.91c-.4,0-4,0-4.27,0,.06-.91.09-1.7.09-2.84Z'
          />
          <path
            style={
              {
                fill:'#fff'
              }
            }
            d='M120,77.36c-.35,0-1.6,0-1.91,0-.23-.7-.48-1.41-.76-2.1h-2.56a18.61,18.61,0,0,0-.75,2.1,8.82,8.82,0,0,0-1.07,0c.54-1.14,2.19-4.8,3.14-7.14a6.67,6.67,0,0,0,.84,0C117.87,72.57,119.17,75.69,120,77.36Zm-2.93-2.71c-.32-.83-.63-1.64-1-2.44-.37.8-.72,1.61-1,2.44Z'
          />
          <path
            style={
              {
                fill:'#fff'
              }
            }
            d='M128.75,74.8c0-1.11,0-1.66,0-2.41,0-1.09,0-1.77,0-2.11.3,0,.61.05.9.05s.61,0,.9-.05a32.58,32.58,0,0,0-.15,3.6c0,2.5.77,2.92,2.21,2.92,1.76,0,1.88-1.78,1.88-3.69,0-.95,0-1.88-.09-2.83a3.83,3.83,0,0,0,.56.05,3.78,3.78,0,0,0,.55-.05c-.07.61-.14,2.07-.14,3.85,0,2.38-1.12,3.37-3.25,3.37C130.21,77.5,128.75,76.72,128.75,74.8Z'
          />
          <path
            style={
              {
                fill:'#fff'
              }
            }
            d='M147,72a6.12,6.12,0,0,0,0-.86c-.43,0-1.37,0-2,.08a4.22,4.22,0,0,0,0-.9c1,0,4.73,0,5.67,0a4.09,4.09,0,0,0,0,.9c-.6-.07-1.54-.08-2-.08,0,.15,0,.46,0,.85v2.57c0,1.15,0,1.94.09,2.84-.24,0-1.57,0-1.81,0,.07-.91.09-1.7.09-2.84Z'
          />
          <path
            style={
              {
                fill:'#fff'
              }
            }
            d='M163.84,73.36a20.07,20.07,0,0,0,1.67-3.08,8.73,8.73,0,0,0,1,0c-.48.77-1.61,2.73-2.31,4,0,.33,0,.58,0,.91a15.81,15.81,0,0,0,.11,2.15c-.31,0-1.57,0-1.88,0,0-.66.13-1.35.13-2,0-.32,0-.64,0-1-.76-1.37-2-3.57-2.36-4.15.33,0,1.64,0,2,0,.51,1,1.33,2.61,1.64,3.08Z'
          />
          <path
            style={
              {
                fill:'#fff'
              }
            }
            d='M54.78,36.48c-.16,3.85-.16,8.72-.16,13.08,0,8.06.1,9.89,1.62,11.87s4.34,3.09,8,3.09c4.23,0,7.58-2.13,8.73-5.22.42-1.11.68-2.69.68-6.84,0-6.59,0-13.23-.47-19.82a39.47,39.47,0,0,0,4.81,0l-.16,2.43c-.26,4.36-.42,14.8-.52,19.93-.1,6-1.88,8.72-5.33,10.85A17.71,17.71,0,0,1,63.19,68c-6.27,0-13.8-1.93-14.94-9.79a25.16,25.16,0,0,1-.11-2.69V41.75c0-2.58-.11-6.54-.26-9.12a55.62,55.62,0,0,0,7.06,0Z'
          />
          <path
            style={
              {
                fill:'#fff'
              }
            }
            d='M85.82,46.55c0-2.22,0-2.72-.15-7.21-.05-1.06-.16-5.6-.21-6.56a39.21,39.21,0,0,0,7.26,0c-.36,7.62-.52,14-.52,21.76,0,3.53.1,6.82.16,9.59h.94c1.51,0,9,0,12.12-.46a13.17,13.17,0,0,0,0,3.74c-2.66-.11-7.15-.31-10-.31-3.34,0-6.17.1-10,.31,0-.81.36-12.52.36-13.79Z'
          />
          <path
            style={
              {
                fill:'#fff'
              }
            }
            d='M120,60.48a52.31,52.31,0,0,0,.37,6.92,50.21,50.21,0,0,0-7.37,0c.21-3.84.52-7.73.52-11.56l-.05-14.09c0-1.87-.05-3.64-.11-5.61h-2.61c-.47,0-5.48.11-8.15.36a17.23,17.23,0,0,0,.11-4c4.13.25,9.81.25,13.84.25,4.34,0,9-.08,14-.33a17.92,17.92,0,0,0-.05,4.12c-3.08-.3-6.79-.36-10.4-.36-.05,1.82-.11,3.74-.11,5.66Z'
          />
          <path
            style={
              {
                fill:'#fff'
              }
            }
            d='M150.84,61.85l-2.19-5.33c-2.24-.1-10.83-.1-12.91,0a107.56,107.56,0,0,0-4.17,10.92,37.83,37.83,0,0,0-4.84,0l6-13C138,43.09,141,36.55,142.29,32.63h3.4c2.5,6.69,5.62,13.73,7.5,18,2,4.38,5.41,12,7.76,16.85-.78,0-7,0-7.91,0ZM142.25,41,136.94,53.4c1.82,0,3.6.14,5.42.14,1,0,2.58,0,4.79-.14Z'
          />
          <path
            style={
              {
                fill:'#fff'
              }
            }
            d='M40.38,34.19c-8.14,5.52-12.92,12.46-12.92,20.95,0,19.25,23.25,33.48,56.4,33.48A79.68,79.68,0,0,0,120.32,80h-.57a116.27,116.27,0,0,1-33.86,4.71c-33.32,0-56.26-15.64-56.29-30.09,0-8,3.71-14.94,10.79-20Z'
          />
          <path
            style={
              {
                fill:'#fff'
              }
            }
            d='M156.86,32.59a2.08,2.08,0,1,1-2.1,2.07A2.07,2.07,0,0,1,156.86,32.59Zm0,3.85a1.77,1.77,0,1,0-1.72-1.78A1.72,1.72,0,0,0,156.86,36.44Zm-.81-3H157c.58,0,.85.23.85.7a.63.63,0,0,1-.64.66l.7,1.07h-.41l-.66-1h-.4v1H156Zm.38,1.08h.39c.33,0,.64,0,.64-.4s-.28-.37-.53-.37h-.5Z'
          />
        </g>
      </g>
    </SvgContainer>
  );
}

export default SVG;
