/**
 * This is a Search Desktop Component.
 *
 * This Component is used to render the SearchL or SearchXL based on the device inflection.
 *
 * @module views/components/SearchDesktop
 * @memberof -Common
 */
import './SearchDesktop.scss';

import React from 'react';

import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';

import SearchL from '../SearchL/SearchL';
import SearchXl from '../SearchXl/SearchXl';

/**
 * Represents a SearchDesktop component
 *
 * @method
 * @returns SearchDesktop
*/
export const SearchDesktop = function(){
  const { breakpoint } = useDeviceInflection();
  return (
    <div className='SearchDesktop'>
      { breakpoint.CURRENT_BREAKPOINT === 'XL' && <SearchXl /> }
      { breakpoint.CURRENT_BREAKPOINT === 'LG' && <SearchL /> }
    </div>
  );
};

export default React.memo( SearchDesktop );
