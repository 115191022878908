/**
 * The route container is a wrapper around an Async page request which is build through the amplience CMS
 *
 * @module core/containers/RouteContainer/RouteContainer
 */

import React, { useCallback, useEffect, useRef } from 'react';

import { gql, useQuery } from '@apollo/client';

import AsyncComponent from '@ulta/core/components/AsyncComponent/AsyncComponent';
import StaticError from '@ulta/core/components/StaticError/StaticError';
import { onResponseDataCapture } from '@ulta/core/hooks/useDXLQuery/useDXLQuery';
import { useAppConfigContext } from '@ulta/core/providers/AppConfigProvider/AppConfigProvider';
import { usePageDataContext } from '@ulta/core/providers/PageDataProvider/PageDataProvider';
import { useServerRequestContext } from '@ulta/core/providers/ServerRequestContextProvider/ServerRequestContextProvider';
import { useUserContext } from '@ulta/core/providers/UserContextProvider/UserContextProvider';
import { useVisualizationContext } from '@ulta/core/providers/VisualizationProvider/VisualizationProvider';
import { isApolloStateMismatch } from '@ulta/core/utils/apollo_client/apollo_client';
import { DXL_NAVIGATION_TYPE } from '@ulta/core/utils/constants/action';
import { isServer } from '@ulta/core/utils/device_detection/device_detection';
import { getUrlParams } from '@ulta/core/utils/domain/domain';
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';
import { handleRedirect } from '@ulta/core/utils/handleLocation/handleLocation';
import { queryProcessor } from '@ulta/core/utils/queryProcessor/queryProcessor';
import { isFunction } from '@ulta/core/utils/types/types';

import * as utils from './RouteContainer';

/**
 * Represents a CMS RouteContainer
 *
 * @method
 * @returns CMSPage
 */
export const RouteContainer = function( props ){
  // Get props / provider data
  const { location, fetchPolicy, DXL_QUERY, isNative } = props;
  const { previewOptions, setPreviewOptions, setVisualizedComponentName } = useVisualizationContext();
  const { setPageData, loading: contextLoading, data: contextData, error: contextError, refetchRef } = usePageDataContext();
  const { isATG = false, isStaging, enableGetForQueries, allowRootQuerySkip } = useAppConfigContext();
  const { user } = useUserContext();
  const { contentId } = getUrlParams( props.location.search );

  // Manages if a refresh was requested but we need to wait for user resolution due to a session update/invalidate
  const refetchAfterResolved = useRef( null );

  // After fetching we want to replace the page content, override lets response handler know that this
  // was an explicit request to update page data and not a re-render for some other reason.
  const override = useRef( );

  useEffect( () => {
    override.current = isApolloStateMismatch();
  }, [] );

  // Prepare page query
  let loading, data, error;

  // "location" comes from React Router and doesn't include the hostname so we derive it here. Required
  // for queryProcessor to determine staging preview headers
  const serverRequestContext = useServerRequestContext();
  const { hostname } = isServer() ? new URL( serverRequestContext.request.href ) : global.location;

  const { graphql, config, context } = queryProcessor( {
    action:{
      graphql: DXL_QUERY,
      customHeaders: [
        { key: 'X-ULTA-GRAPH-TYPE', value: 'query' },
        { key: 'X-ULTA-GRAPH-MODULE-NAME', value: 'RouteContainer' },
        { key: 'X-ULTA-GRAPH-SUB-TYPE', value: 'page' }
      ],
      location: { ...location, hostname }
    },
    config: {
      ...( fetchPolicy && { fetchPolicy } ),
      // Prevents caching of the next request during a refetch
      nextFetchPolicy: 'no-cache',
      // Required to get the `loading` variable to be reactive
      notifyOnNetworkStatusChange: true,
      variables: {
        ...( contentId && { contentId } ),
        ...( !contentId && { url: { path : location.pathname } } ),
        ...( previewOptions && { previewOptions } ),
        isNative,
        moduleParams:{
          ...getUrlParams( location.search )
        }
      }
    }
  } );

  context.fetchOptions = { method: enableGetForQueries ? 'GET' : 'POST' };

  // Skips the query on the client. This is unset when we manually invoke refetch.
  // - We need to do this to handle when Akamai caches a full page response and the __APOLLO_STATE__
  //   cache key for the page has different module params than what may be in the url.
  // - We saw this while handling performance for email campaign links, RouteContainer was re-requesting
  //   the page when the url had different params than the cached page's module params.
  // - We don't want to do this on header/footer endpoints because there's currently a bug
  //   where the html is not being cached correctly. When skip is true Apollo will not even
  //   attempt to fetch the data from the cache.
  const shouldSkip = useRef( utils.getShouldSkip( { allowRootQuerySkip } ) );

  let {
    loading: dxlLoading,
    data: dxlData,
    error: dxlError,
    refetch
  } = useQuery( gql`${graphql}`, { ...config, context, skip: shouldSkip.current } );

  // fires data capture triggerEvent when DXL send dataCaptureEventList inside meta
  useEffect( () => {
    const { dataCaptureEventList } = dxlData?.Page?.meta || contextData?.Page?.meta || {};
    if( dataCaptureEventList ){
      onResponseDataCapture( dataCaptureEventList );
    }
  }, [] );

  // Assign refetch handle to PageDataContext, used to refetch this top-level query
  // when DXL sends action.navigationType = 'Refresh'
  useEffect(
    utils.composeRefreshProxy( { isATG, override, refetchRef, refetchAfterResolved, user, shouldSkip }, { refetch } ),
    [refetchAfterResolved, refetchRef, override, refetch, user.resolved]
  );

  // DXL Response handler
  const reponseHandler = useCallback(
    utils.composeResponseHandler(
      { dxlError, override, contextData, contextError, dxlData, dxlLoading },
      { setPageData }
    ),
    [dxlError, contextData, dxlData, dxlLoading, setPageData]
  );
  useEffect( reponseHandler, [reponseHandler] );

  // Resolves data/loading/error state during SSR and client side requests
  if( isServer() ){
    data = dxlData;
    error = dxlError;
    loading = dxlLoading;
  }
  else {
    data = contextData || dxlData;
    error = contextError || dxlError;
    loading = contextLoading || dxlLoading;
  }

  // Manages CMS previews
  useEffect( () => {
    // only set the preview options after the first load of the view after we receive  the options from the service response
    if( previewOptions && !Object.keys( previewOptions ).length && data?.Page?.previewOptions ){
      // one we get data check for previewOptions and set them in provider if we received them
      setPreviewOptions( data.Page.previewOptions );
      // set the component name for the preview provider
      setVisualizedComponentName( data.Page?.content?.moduleName );
    }
  }, [data] );

  return (
    <div className='RouteContainer'>
      {
        <ComponentRenderer data={ data }
          error={ error }
          loading={ loading }
          isStaging={ isStaging }
        /> }
    </div>
  );
};

/**
 * Gets skip state for the root query
 * @param {object} data - arguments
 * @returns {boolean} - Should skip root query on the client
 */
export const getShouldSkip = ( data ) => {
  return data?.allowRootQuerySkip && !isServer();
};

/**
 * Proxies the refetch function so that we can override page data safely using the override ref
 * @param {object} data - Arguments
 * @param {object} data.refetchAfterResolved - When ref is true we're waiting for user resolution to refetch
 * @param {object} data.refetchRef - Manages the proxy reference
 * @param {object} data.override - When override.current is true the response handler knows to override the page data
 * @param {object} methods - Methods
 * @param {object} methods.refetch - The Apollo page-level refetch call that is proxied
 */
export const composeRefreshProxy = ( data, methods ) => () => {
  const {
    isATG,
    override = {},
    refetchRef = {},
    refetchAfterResolved = {},
    user = {},
    shouldSkip = {}
  } = handleEmptyObjects( data );

  const { refetch } = methods || {};

  // When user has resolved and we're waiting to refetch
  if( !isATG && refetchAfterResolved.current && user.resolved ){
    // Reset the flag to false
    refetchAfterResolved.current = false;
    // Let response handler know explicitly to accept incoming data
    override.current = true;
    // Allow the query to be executed
    shouldSkip.current = false;

    // Execute refetch
    // TODO: We will move away from global.location and stick to the `location` prop
    // which is provided by react router
    refetch( {
      url: {
        path: global.location.pathname
      },
      moduleParams: { ...getUrlParams( global.location.search ) }
    } );
  }

  // If the isATG flag is disabled we set a no-op proxy. This is used typically when loading
  // the app from /header or /footer route.
  if( isATG || !isFunction( refetch ) ){
    refetchRef.current = () => {
      override.current = false;
    };

    return;
  }

  // This is a ref from the PageDataProvider that is used to refresh the original page query
  // after DXL sends a navigationType=refresh
  refetchRef.current = () => {
    // If DXL sends both a request to refresh and update the session in the same response, we need to wait
    // for `user.resolved` to be true again (after ATG -> DSOTF sync). We set this flag to true and exit early
    // and wait for user resolution. One of this helpers dependencies is `user.resolved` so we will perform
    // the refetch above when that happens.
    if( !user.resolved ){
      refetchAfterResolved.current = true;
      return;
    }

    // Let response handler know explicitly to accept incoming data
    override.current = true;

    // Allow the query to be executed
    shouldSkip.current = false;

    // Execute refetch
    // TODO: We will move away from global.location and stick to the `location` prop
    // which is provided by react router
    refetch( {
      url: {
        path: global.location.pathname
      },
      moduleParams: { ...getUrlParams( global.location.search ) }
    } );
  };
};

/**
 * Handles DXL response for RouteContainer
 * -> This should be wrapped LayerHost in the future so this is a stop gap and doesn't
 *    support initAction's at the page level
 * @param {object} data
 * @param {object} methods
 */
export const composeResponseHandler = ( data, methods ) => () => {
  const { dxlError, contextData, contextError, dxlData, dxlLoading, override } = data || {};
  const { setPageData } = methods || {};

  if( !setPageData ){
    return;
  }

  const { url, navigationType } = dxlData?.Page?.meta?.initAction || {};

  if( !dxlError && !isServer() && url && navigationType === DXL_NAVIGATION_TYPE.Redirect ){
    handleRedirect( { url } );
  }

  const hasNewData = ( override.current || !contextData && !contextError ) && ( dxlData || dxlError );

  if( hasNewData ){
    override.current = false;
    setPageData( { loading: dxlLoading, data: dxlData, error: dxlError } );
  }
};


/**
 * Route container renderer
 * @param {object} data args
 * @param {object} data.data DXL response
 * @param {object} data.key page render key
 * @returns {object} Rendered page or error
 */
export const ComponentRenderer = ( props ) => {
  const { data: payload, loading, error, isStaging } = props || {};

  const contentItem = payload?.Page?.content;

  if( !contentItem && !loading ){
    printDXLError( error );
    return ( <StaticError isStaging={ isStaging }/> );
  }

  if( loading && !contentItem ){
    return null;
  }

  try {
    return (
      <AsyncComponent
        meta={ payload.Page.meta }
        { ...contentItem }
      />
    );
  }
  catch ( e ){
    const errMessage = `An error occured when trying to render the ${ contentItem.moduleName } component from the schema definition: ${ e.message}`;
    !isServer() && console.error( errMessage ); // eslint-disable-line

    return (
      <StaticError isStaging={ isStaging }/>
    );
  }
};

/**
 * Print DXL Error
 * @param {object} error object
 */
export const printDXLError = ( error )=>{
  if( error ){
    !isServer() && console.error( error.message ); // eslint-disable-line
  }
};

export default RouteContainer;
