import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24CaretUp( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <path
        d='M22.125 18L12 6.75 1.875 18'
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg24CaretUp;
