import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg16Video( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 16 }
      height={ 16 }
    >
      <path
        stroke='currentColor'
        d='M15 8L2 15V1z'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg16Video;
