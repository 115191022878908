import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24SameDayDelivery( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
      viewBox='0 0 24 24'
    >
      <g id='-----SDD'
        stroke='#000000'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
      >
        <g id='Base/Icons/UI/shipping-box-closed-Copy-2'
          transform='translate(0.750199, 0.750000)'
          strokeWidth='1.5'
        >
          <g id='Group-15'>
            <polygon id='Stroke-1'
              strokeLinejoin='round'
              points='11.8403 0 1.1863 4.261 11.8403 8.523 22.4943 4.261'
            ></polygon>
            <polyline id='Stroke-3'
              strokeLinejoin='round'
              points='7.7423 19.8924 11.8403 21.3074 11.8403 8.5234 1.1863 4.2614 1.1863 7.1684'
            ></polyline>
            <polygon id='Stroke-5'
              strokeLinejoin='round'
              points='22.494 4.2615 22.494 17.0465 11.84 21.3075 11.84 8.5235'
            ></polygon>
            <line x1='17.6994'
              y1='6.1792'
              x2='7.0454'
              y2='1.9172'
              id='Stroke-7'
              strokeLinejoin='round'
            ></line>
            <line x1='0'
              y1='16.6149'
              x2='5.192'
              y2='18.7129'
              id='Stroke-9'
            ></line>
            <line x1='0'
              y1='9.4379'
              x2='5.192'
              y2='11.5359'
              id='Stroke-11'
            ></line>
            <line x1='1.0842'
              y1='13.4598'
              x2='4.9782'
              y2='15.0328'
              id='Stroke-13'
            ></line>
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}

export default Svg24SameDayDelivery;