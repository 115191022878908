
/**
 * useSubscription
 * looks at subscriptions that have been set and fires a callback method based on the existence
 *
 * @param { object } data
 * @param { object } methods
 * @returns boolean
 */
export const useSubscription = ( data, methods ) => {
  const { val = {}, subscriptions = {} } = data || {};
  const { dispatchSubscriptionUpdate } = methods || {};
  // // map over the subscriptions
  let didChange = false;
  Object.keys( subscriptions ).map( sub => {
    // if the subscription key has changed from the last value then we need to rigger the subcription method
    if( val?.[sub] !== subscriptions[sub]?.lastVal ){
      didChange = true;
      subscriptions[sub].callback( { [sub]: val[sub] } )
      dispatchSubscriptionUpdate( { type: sub, callback: subscriptions[sub].callback, lastVal: val[sub] } )
      // we need to updat the sbuscription with the last val from here
    }
  } )
  return didChange;
}