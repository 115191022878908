/**
 * Image Navigation Cards are used when you need to present a list of destinations for guests. Can use for brand navigation and other unique destinations that may not be tied directly to our category catalog.
 *
 * @module views/components/ImageNavigationCard
 * @memberof -Common
 */
import './ImageNavigationCard.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import UltaAsset from '@ulta/core/components/UltaAsset/UltaAsset';

/**
 * Represents a ImageNavigationCard component
 *
 * @method
 * @param {ImageNavigationCardProps} props - React properties passed from composition
 * @returns ImageNavigationCard
 */
export const ImageNavigationCard = function( props ){
  const {
    action,
    altText,
    allProductsLabel,
    backgroundColor,
    imageUrl,
    imageMetaData,
    label,
    navType,
    shopAllLabel,
    svgOption,
    outlineOption
  } = props ;
  return (
    <div className={
      classNames( 'ImageNavigationCard', {
        'ImageNavigationCard__noLink': !label && !shopAllLabel && !action
      } )
    }
    >
      <Link_Huge
        action={ action }
      >
        <div className={
          classNames( 'ImageNavigationCard__NavType', {
            [`ImageNavigationCard__NavType--${navType}`]: navType,
            'ImageNavigationCard--outlineOption': outlineOption,
            'ImageNavigationCard__ImageType--svgOption': svgOption,
            'ImageNavigationCard__NavType--noLink': !label && !shopAllLabel && !action,
            'ImageNavigationCard__NavType--hiddenLink': !label && !shopAllLabel,
            ...backgroundColor ? { ['ImageNavigationCard__backgroundColor--' + backgroundColor]: true } : {}
          } )
        }
        >
          { imageUrl ? (
            <div className='ImageNavigationCard__Image'>
              <UltaAsset
                aspectRatio='1:1'
                imageUrl={ imageUrl }
                altText={ altText }
                { ...( imageMetaData && { metaData: imageMetaData } ) }
              />
            </div>
          ) :
            `${allProductsLabel}`
          }
        </div>
        <Text
          textStyle='body-2'
          htmlTag='span'
          textAlign='center'
        >
          { shopAllLabel ? shopAllLabel : label }
        </Text>
      </Link_Huge>
    </div>
  );
};

/**
 * Property type definitions
 * @typedef ImageNavigationCardProps
 * @type {object}
 * @property {string} navType - Sets the card/nav type (currently one of 'category', 'viewAll', 'brand')
 * @property {string} label - Sets the label text
 * @property {string} imageUrl - Sets the image location
 * @property {string} altText - Sets the alt Text for image
 * @property {string} shopAllLabel - Sets the shop all label
 * @property {string} description - Sets the description
 * @property {boolean} outlineOption - Sets the outline of the card
 * @property {boolean} backgroundImage - Sets the backgroundImage
 * @property {string} allProductsLabel - Sets the all products label
 */
export const propTypes = {
  navType: PropTypes.oneOf( ['category', 'viewAll', 'brand'] ),
  label: PropTypes.string,
  imageUrl: PropTypes.string,
  altText: PropTypes.string,
  shopAllLabel: PropTypes.string,
  description: PropTypes.string,
  allProductsLabel: PropTypes.string,
  dataCaptureData: PropTypes.object,
  action: PropTypes.object

};

ImageNavigationCard.propTypes = propTypes;

export default ImageNavigationCard;
