import * as React from 'react';

import SvgContainer from '../SvgContainer/SvgContainer';

function Svg24Check( props ){
  return (
    <SvgContainer
      { ...props }
      width={ 24 }
      height={ 24 }
    >
      <path
        d='M1 11.333L8.773 20 23 3'
        fillRule='nonzero'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgContainer>
  );
}

export default Svg24Check;
